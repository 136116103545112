import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { t } from 'i18next';
import {
  RowLabel,
  UploadButton,
  UploadDocumentsContainer,
  UploadDocumentsRow,
  UploadDocumentsRowContainer,
  CheckmarkIcon,
  FileNameContainer,
  FileUploadLabel, FileInputField,
} from './css';
import UploadDocumentsRenderModes from './constants';

const ATTACHED_DOCUMENTS_STORE_KEY = 'attachedDocuments';

const UploadDocumentsReviewRow = ({
  rowLabel = '',
  ...rest
}) => {
  const { input } = rest;

  return (
    <UploadDocumentsRow>
      <RowLabel field={rowLabel}/>
      <FileNameContainer>
        {input.value.name && (
          <>
            <FileUploadLabel>{input.value.name}</FileUploadLabel>
            <CheckmarkIcon/>
          </>
        )}
      </FileNameContainer>
    </UploadDocumentsRow>
  );
};

UploadDocumentsReviewRow.propTypes = {
  rowLabel: PropTypes.string
};

// eslint-disable-next-line
const UploadImages = ({
  numberOfPlaceholders = 0,
  storeKey = ATTACHED_DOCUMENTS_STORE_KEY,
  renderMode = UploadDocumentsRenderModes.INPUT,
  fieldCounter,
  name
}) => {
  const renderPlaceholders = (numberOfPlaceholdersLocal) => {
    const placeholderElements = [];
    let rowLabel = '';
    let fieldName = '';
    for (let i = 0; i < numberOfPlaceholdersLocal; i++) {
      rowLabel = `${t('Image')} ${i + 1}`;
      if (name) {
        fieldName = `${name}.attachedDocument_${i + 1}-${fieldCounter}`;
      } else {
        fieldName = `${storeKey ? storeKey : ATTACHED_DOCUMENTS_STORE_KEY}.attachedDocument_${i + 1}-${fieldCounter}`;
      }

      placeholderElements.push(
        <UploadDocumentsRowContainer key={fieldName}>

          {renderMode === UploadDocumentsRenderModes.INPUT && (
            <UploadDocumentsRow>
              <RowLabel field={rowLabel}/>
              <UploadButton
                component={FileInputField}
                attachButton={t('AttachFile')}
                name={fieldName}
                className="inline squeeze"
              />
            </UploadDocumentsRow>
          )}

          {renderMode === UploadDocumentsRenderModes.REVIEW && (
            <Field
              name={fieldName}
              component={UploadDocumentsReviewRow}
              rowLabel={rowLabel}
            />
          )}

        </UploadDocumentsRowContainer>
      );
    }

    return placeholderElements;
  };

  return (
    <UploadDocumentsContainer>
      {renderPlaceholders(numberOfPlaceholders)}
    </UploadDocumentsContainer>
  );
};

UploadImages.propTypes = {
  numberOfPlaceholders: PropTypes.bool,
  storeKey: PropTypes.string,
  renderMode: PropTypes.string,
  fieldCounter: PropTypes.number,
  name: PropTypes.string,
};

export default UploadImages;
