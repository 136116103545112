import { t } from 'i18next';
import { get24HourTime } from '../../utils/dateTime';

const validateTime = (formData) => {
  const error = {};

  const start = get24HourTime(
    {
      hours: formData.readyTimeHour,
      minutes: formData.readyTimeMinutes,
      amPm: formData.readyAMPM,
    }
  );

  if (!start) {
    error.readyTimeError = t('InvalidReadyTime');
  }

  const end = get24HourTime(
    {
      hours: formData.closingTimeHour,
      minutes: formData.closingTimeMinutes,
      amPm: formData.closingAMPM,
    },
  );

  if (!end) {
    error.closingTimeError = t('InvalidClosingTime');
  }

  return error;
};
export default validateTime;
