import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import Modal from 'react-modal';
import {
  getShipmentDocument,
  getShipmentDocuments,
  getShipmentTrackingDetailed,
} from '../../services/shipments';
import { putReduxServiceLevelQuotes } from '../CreateQuoteForm/actions';
import {
  AddressBlock,
  PageTitle,
  CategoryTitle,
  TextProperty,
  QuestionsBlockContainer,
  TextButton,
  SignedDocument,
  StyledMDBDropdown,
  ShippingDetailsPostScript,
} from './css';
import { AddressBookText } from '../ReactComponent/AddressBookEntry';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';
import {
  ModalTitle,
  ModalBody,
  ButtonDiv,
  LastActions,
} from '../CreateShipment/css';
import {
  H2, H3, P, H4
} from '../../theme/typography';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import { BOLcall, getAccessorials } from '../CreateShipment/api';
import { Span } from '../../theme/typographySitecore';
import { IconButton } from '../ReactComponent/Icon';
import DownloadBlack from '../../assets/icons/DownloadBlack.svg';
import DownloadOrange from '../../assets/icons/DownloadOrange.svg';
import DuplicateShipmentWhite from '../../assets/icons/DuplicateShipmentWhite.svg';
import CancelWhite from '../../assets/icons/CancelWhite.svg';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import {
  // eslint-disable-next-line no-unused-vars
  formatMoney, downloadFile, scrollToRef, checkProtectedStatus
} from '../../utils/func';
import GreyBoxWithBorder from '../_styledComponents/GreyBoxWithBorder';
import ShipmentDetailsWithErrors from '../ReactComponent/ShipmentDetails/ShipmentDetailsWithErrors';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import SecondaryButton from '../ReactComponent/SecondaryButton';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import { routes } from '../../utils/constants';
import { AddressTypes, Divisions, ShipmentStatuses } from '../../utils/enums';
import { getHandlingDetails, filterDetailsData } from '../ViewShipmentTracking/func';
import { getDeliveryDate } from '../../utils/dateTime';
import QuestionsBlock from '../ReactComponent/QuestionsBlock';

class ViewShipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      division: '',
      probillNumber: '',
      scrollToDocs: false,
      historyData: [],
      documents: [],
      modalIsOpen: false,
      errorMessage: '',
      detailedTrackingData: null
    };
    this.signedDocsRef = React.createRef();
  }

  componentDidMount() {
    const { isAuthenticated, accountNumbers } = this.props;
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      this.setState({
        division: urlParams.get('division'),
        probillNumber: urlParams.get('probillNumber'),
        scrollToDocs: urlParams.get('scrollToDocs'),
      }, () => {
        getAccessorials({
          isAuthenticated, division: this.state.division || this.props.division || Divisions.Freight.name, updateState: this.updateState, billTo: ''
        }).then(() => {
          this.getShipmentDetails();
          const { division, probillNumber, scrollToDocs } = this.state;
          getShipmentDocuments(division, probillNumber, accountNumbers).then((res) => {
            if (res.status === 200) {
              this.setState({ documents: res.data });
              if (scrollToDocs) {
                scrollToRef(this.signedDocsRef);
              };
            }
          });
        });
      });
    }
  }

  updateState = (name, value) => this.setState({ [name]: value });

  renderDetailsData = (detailsData) => {
    const historyData = filterDetailsData(detailsData);
    this.setState({ historyData, historyDataError: false });
  };

  renderDetailsDataError = () => {
    this.setState({ historyDataError: true, historyData: [] });
  };

  getShipmentDetails = () => {
    const { division, probillNumber } = this.state;
    const { accountNumbers } = this.props;
    getShipmentTrackingDetailed({ division, probillNumber, accountNumbers }).then((res) => {
      if (res.status === 200) {
        const { data } = res;
        this.setState({ data, detailedTrackingData: res.data }, () => {
          if (data.trackingItems) {
            this.renderDetailsData(data.trackingItems);
          } else {
            this.renderDetailsDataError();
          }
        });
      }
    }).catch((err) => {
      let errMsg = t('ShipmentNotFound');
      if (({ ...err }).response.status === 403) {
        errMsg = t('NotAuthorizedForProbill');
      }
      this.handleError(errMsg);
    });
  };

  handleError = (err) => {
    this.setState({ errorMessage: err ? err : t('ShipmentNotFound') });
    this.openModal();
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false, errorMessage: '' });
  };

  downloadDocument = (division, probillNumber, element) => () => {
    getShipmentDocument(division, probillNumber, element).then((res) => {
      if (res.status === 200) {
        downloadFile({
          filename: res.data.fileName,
          dataAsBase64: res.data.fileDataAsBase64,
          mimeType: res.data.mimeType
        });
      }
    });
  };

  downloadAllDocuments = (division, probillNumber, documents) => () =>
    documents.forEach((doc) => this.downloadDocument(division, probillNumber, doc)());

  openBOL = () => {
    const { userEmail, userId } = this.props;
    const { probillNumber, division } = this.state;
    BOLcall({
      probillNumber, division, emailAddress: userEmail, userId
    });
  };

  cancelShipment() {
    const { probillNumber } = this.state;
    const errorMessage = t(`Unable to delete Shipment ${probillNumber}.`);
    this.setState({ modalIsOpen: true, errorMessage });
  }

  renderModal = () => {
    const { errorMessage, modalIsOpen } = this.state;
    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <ModalBody className="justify-content-center">
            <ModalTitle>{t('Error')}</ModalTitle>
            <p>{errorMessage}</p>
            <p>{t('PleaseContactSupportHotline')}</p>
            <ButtonDiv className="row">
              <div className="col-sm-12">
                <SecondaryButton
                  onClick={(e) => this.closeModal(e)}
                  className="active"
                  name="acceptErrorMessage"
                >
                  {t('Accept')}
                  <span className="icon" />
                </SecondaryButton>
              </div>
            </ButtonDiv>
          </ModalBody>
        </Modal>
      </>
    );
  };

  renderHeader = () => {
    const { probillNumber, division, data: { status } } = this.state;
    return (
      <>
        <div className="row mt-5">
          <div className="col-8">
            <PageTitle>{t(`Shipment - #${probillNumber}`)}</PageTitle>
            <P>
              {t('YourShipmentSummary')}{' '}
              <strong>
                {t('YourShipmentSummaryNote')}
              </strong>
            </P>
          </div>
          <LastActions className="float-right">
            <Column>
              {status && status !== ShipmentStatuses.NotYetPickedUp && <>
                <Row>
                  <Column>
                    <PrimaryButton disabled={status !== ShipmentStatuses.NotYetPickedUp} className="active" onClick={() => this.openBOL()}>
                      {t('DownloadShippingDocuments')}
                    </PrimaryButton>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <StyledMDBDropdown>
                      <MDBDropdownToggle>
                        <Row>
                          <Column $tablet={10} $mobile={10}>
                            {t('MoreOptions')}
                          </Column>
                          <span className="icon icon-more-options" />
                        </Row>
                      </MDBDropdownToggle>
                      <StyledMDBDropdownMenu className="text-white bg-dark">
                        <DropdownItem onClick={() => {
                          window.location.href = `${routes.createShipment}?recreate=true&division=${division}&probillNumber=${probillNumber}`;
                          return window.location.href;
                        }}>
                          <DropdownItemIcon iconSrc={DuplicateShipmentWhite} />
                          {t('DuplicateShipment')}
                        </DropdownItem>
                        <DropdownItem onClick={() => this.cancelShipment()}>
                          <DropdownItemIcon iconSrc={CancelWhite} />
                          {t('CancelShipment')}
                        </DropdownItem>
                      </StyledMDBDropdownMenu>
                    </StyledMDBDropdown>
                  </Column>
                </Row>
              </>}
            </Column>
          </LastActions>
        </div>
      </>
    );
  };

  renderDocumentsDetails = () => {
    const { probillNumber, division, documents } = this.state;
    return (
      <>
        <CategoryTitle ref={this.signedDocsRef}>
          {t('SignedDocuments')}
          <TextButton onClick={this.downloadAllDocuments(division, probillNumber, documents)}>
            <Span field={t('DownloadAll')} />
          </TextButton>
        </CategoryTitle>
        <GreyBoxWithBorder $isBorder={false}>
          {documents.map((element) => <SignedDocument key={element.documentId}>
            <Span field={element.type} />
            <IconButton
              onClick={this.downloadDocument(division, element.probillNumber, element)}
              iconSrc={DownloadBlack}
              iconHoverSrc={DownloadOrange} />
          </SignedDocument>)}
        </GreyBoxWithBorder>
      </>
    );
  };

  renderShipmentDetails = () => {
    const { probillNumber, data } = this.state;
    const {
      beyondProbillNumber,
      referenceNumber,
      totalPieces,
      totalPiecesDelivered,
      totalWeight,
      deliveryDate,
      deliveryDateType,
      transferPartnerName,
      transferDate,
      statusCode,
      receivedBy,
      additionalInfo
    } = data;
    const handlingDetails = getHandlingDetails({ statusCode, receivedBy });
    return (
      <>
        <CategoryTitle>{t('Shipping Details')}</CategoryTitle>
        <GreyBoxWithBorder>
          <Row>
            <Column $tablet={4} $mobile={12}>
              {probillNumber &&
                <TextProperty>
                  <P>{t('ProNumber')}</P>
                  <H3>{probillNumber}</H3>
                </TextProperty>}
              {beyondProbillNumber &&
                <TextProperty>
                  <P>{t('BeyondProNumber')} </P>
                  <H3>{beyondProbillNumber}</H3>
                </TextProperty>}
              {referenceNumber &&
                <TextProperty>
                  <P>{t('ReferenceNumber')}</P>
                  <H3>{referenceNumber}</H3>
                </TextProperty>}
            </Column>
            <Column $tablet={4} $mobile={12}>
              {totalPieces &&
                <TextProperty>
                  <P>{t('TotalPieces')}</P>
                  <H3>{`${totalPiecesDelivered} / ${totalPieces} ${t('Pieces')}`}</H3>
                </TextProperty>}
              {totalWeight &&
                <TextProperty>
                  <P>{t('TotalWeight')}</P>
                  <H3>{`${totalWeight.value} ${totalWeight.unitOfMeasure}`}</H3>
                </TextProperty>}
              {transferPartnerName &&
                <TextProperty>
                  <P>{t('TransferredToPartner')}</P>
                  <H3>{transferPartnerName}</H3>
                  {transferDate &&
                    <H4>{transferDate}</H4>}
                </TextProperty>}
            </Column>
            <Column $tablet={4} $mobile={12}>
              {deliveryDate &&
                <TextProperty>
                  <P>{t('DeliveryDate')}</P>
                  <H4>{deliveryDate ? getDeliveryDate({ deliveryDate, deliveryDateType }) : ''}</H4>
                </TextProperty>}
              {additionalInfo &&
                <TextProperty>
                  <P>{t('AdditionalInformation')} </P>
                  <H3>{additionalInfo}</H3>
                </TextProperty>}
              {handlingDetails && handlingDetails.label && handlingDetails.value &&
                <TextProperty>
                  <P>{handlingDetails.label}</P>
                  <H3>{handlingDetails.value}</H3>
                </TextProperty>}
            </Column>
          </Row>
        </GreyBoxWithBorder>
      </>);
  };

  renderAppointmentDetails = () => {
    const { detailedTrackingData } = this.state;
    const { isAuthenticated } = this.props;

    if (!detailedTrackingData) {
      return null;
    }

    const {
      deliveryDate,
      deliveryDateType
    } = detailedTrackingData;

    return (
    <>
      {isAuthenticated && deliveryDateType === 'AppointmentDelivery' &&
      <>
        <CategoryTitle>{t('AppointmentDetailsHeader')}</CategoryTitle>
        <GreyBoxWithBorder>
          <Row>
            <Column $tablet={4} $mobile={6}>
              {deliveryDate &&
                <TextProperty>
                  <P>{t('Appointment')}</P>
                </TextProperty>}
            </Column>

            <Column $tablet={4} $mobile={6}>
              {deliveryDate &&
                <TextProperty>
                    <H4>{deliveryDate ? `${t('On')} ${getDeliveryDate({ deliveryDate, deliveryDateType })}` : ''}</H4>
                </TextProperty>}
            </Column>
          </Row>
        </GreyBoxWithBorder>
      </>}
    </>
    );
  };

  renderShipperAndConsignee = () => {
    const { data } = this.state;
    const {
      to = {},
      from = {}
    } = data;

    const shipper = {
      shipperCompanyName: from.companyName,
      shipperContactName: from.contactName,
      shipperAddress: [
        from.address1,
        from.address2,
        from.city,
        from.provinceCode,
        from.postalCode,
        from.countryCode
      ].filter((x) => typeof x === 'string' && x.length > 0).join(', ')
    };

    const consignee = {
      consigneeCompanyName: to.companyName,
      consigneeContactName: to.contactName,
      consigneeAddress: [
        to.address1,
        to.address2,
        to.city,
        to.provinceCode,
        to.postalCode,
        to.countryCode
      ].filter((x) => typeof x === 'string' && x.length > 0).join(', ')
    };

    return (
      <>
        <CategoryTitle>{t('ShipperAndConsignee')}</CategoryTitle>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <AddressBlock className="with-bg">
              <H3>
                {t('ShipperDetails')}
              </H3>
              <AddressBookText contactType={AddressTypes.SHIPPER} formData={shipper} />
            </AddressBlock>
          </Column>
          <Column $tablet={6} $mobile={12}>
            <AddressBlock className="with-bg">
              <H3>
                {t('ConsigneeDetails')}
              </H3>
              <AddressBookText contactType={AddressTypes.CONSIGNEE} formData={consignee} />
            </AddressBlock>
          </Column>
        </Row>
      </>);
  };

  renderBillto = () => {
    const { data } = this.state;
    const {
      billTo = {},
    } = data;

    const objbBillTo = {
      oCompanyName: billTo.companyName,
      oAddress: [
        billTo.address1,
        billTo.address2,
        billTo.city,
        billTo.provinceCode,
        billTo.postalCode,
        billTo.countryCode
      ].filter((x) => typeof x === 'string' && x.length > 0).join(', ')
    };

    return (
      <>
        <CategoryTitle>{t('BillTo')}</CategoryTitle>
        <GreyBoxWithBorder>
          <Row>
            <Column $tablet={6} $mobile={12}>
              {objbBillTo.oCompanyName &&
                <TextProperty>
                  <P>{t('Name')}</P>
                  <H3>{objbBillTo.oCompanyName}</H3>
                </TextProperty>}
              {objbBillTo.oAddress &&
                <TextProperty>
                  <P>{t('Address')}</P>
                  <H3>{objbBillTo.oAddress}</H3>
                </TextProperty>}
            </Column>

          </Row>
        </GreyBoxWithBorder>
      </>);
  };

  renderPriceCharges = () => {
    const {
      // eslint-disable-next-line no-unused-vars
      fields, billingAccounts, division, userId
    } = this.props;
    const { data } = this.state;
    const {
      totalAmount = {},
      charges = [{}],
    } = data;

    // checkProtectedStatus(division, userId, billingAccounts);
    let protectedAccount = billingAccounts && billingAccounts.some((item) => item.protected === true);
    if (protectedAccount) {
      protectedAccount = true;
    }
    return (
      <>
        <GreyBoxWithBorder>
          <Row>
            <Column $tablet={12} $mobile={12}>
              {totalAmount && charges &&
                <TextProperty>
                  <P className="title">
                    {t('Price')}
                  </P>
                  {charges.map((charge, index) => (
                    <H3
                    className="justified"
                    key={index}>
                      <span>{charge.description}</span> <span>{charge.amount && formatMoney(charge.amount.value, protectedAccount)}</span>
                    </H3>
                  ))}
                  <hr />
                  <H2 className="justified">
                    <span>{t('Total')}</span> <span>{formatMoney(totalAmount.value, protectedAccount)}</span>
                  </H2>
                </TextProperty>}
            </Column>
          </Row>
        </GreyBoxWithBorder>
        <ShippingDetailsPostScript field={fields.shippingDetailsPostScript} />
      </>
    );
  };

  renderTrackingDetails = () => {
    const { isAuthenticated, division } = this.props;
    const { historyDataError, historyData, data } = this.state;

    if (!data.probillNumber) {
      return null;
    }

    let basicData = {};
    if (data) {
      basicData = {
        from: {
          street: data.from.address1,
          city: data.from.city,
          provinceCode: data.from.provinceCode,
          countryCode: data.from.countryCode,
        },
        to: {
          street: data.to.address1,
          city: data.to.city,
          provinceCode: data.to.provinceCode,
          countryCode: data.to.countryCode,
        }
      };
    }

    return (
      <>
        <CategoryTitle>{t('Tracking')}</CategoryTitle>
        <ShipmentDetailsWithErrors
          showError={historyDataError}
          basicData={basicData}
          detailsData={historyData}
          division={division}
          isAuthenticated={isAuthenticated}
        />
      </>
    );
  };

  renderFooter = () => (
      <QuestionsBlockContainer>
        <QuestionsBlock />
      </QuestionsBlockContainer>
  );

  render() {
    const { division } = this.props;
    return (
      <>
        {this.renderModal()}
        {this.renderHeader()}
        {this.renderDocumentsDetails()}
        {this.renderShipmentDetails()}
        {(division === 'Freight') ? this.renderAppointmentDetails() : null}
        {this.renderTrackingDetails()}
        {this.renderShipperAndConsignee()}
        {this.renderBillto()}
        {this.renderPriceCharges()}
        {this.renderFooter()}
      </>
    );
  }
}

const mstp = (state) => ({
  serviceLevelQuotes: state.user.serviceLevelQuotes,
  isAuthenticated: state.profile.isAuthenticated,
  accountNumbers: state.profile.accountNumbers,
  division: state.profile.division,
  userId: state.profile.userId,
  userEmail: state.profile.email,
  billingAccounts: state.profile.billingAccounts
});

const mdtp = (dispatch) => ({
  putReduxServiceLevelQuotes: (serviceLevelQuotes) =>
    dispatch(putReduxServiceLevelQuotes(serviceLevelQuotes))
});

export default connect(
  mstp,
  mdtp
)(ViewShipment);
