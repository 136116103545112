import styled from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';

export const TitleContainer = styled.div`
    display: flex;
    max-width: 532px;
    margin-bottom: 24px;
`;

export const SubtitleContainer = styled.div`
    display: flex;
    max-width: 532px;
`;

export const StyledForm = styled.form`
    max-width: 532px;
    display: flex;
    flex-direction: column;
`;

export const CheckBoxGroup = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 532px;
    margin: 24px 0;
    max-height: 380px;
    overflow-y: scroll;
`;

export const CheckboxLabel = styled.label`
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 16px;
    cursor: pointer;
`;

export const CheckboxInput = styled.input`

    margin-right: 8px;

    // ------------------------
    // Hide default checkbox...
    // ------------------------

    -webkit-appearance: none;

    // ---------------------
    // Style new checkbox...
    // ---------------------

    position: relative;
    height: 21px;
    width: 21px;
    border: 1.5px solid ${colors.BLACK};
    background-color: ${colors.WHITE};
    border-radius: 2px;

    &:after {
        content: "";
        position: absolute;
        display: none;
    }

    &:checked {
        background-color: ${({ disabled }) => (disabled ? colors.COOL_GREY : colors.BRAND_ORANGE)};

        &:after {
            display: block;
            left: 5px;
            top: 1px;
            width: 7px;
            height: 11px;
            border: solid ${colors.WHITE};
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 532px;

    ${MediaQuery.medium`
      flex-wrap: nowrap;
    `}
    &.center {
        justify-content: center;
        margin: 36px auto;
    }
`;

export const PrimaryButton = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 16px 22px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    order: 0;
    height: 52px;

    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`    
    margin-left: 16px;
    margin-bottom: 0;
    order: 1;
`}
    &.submit, &.active {
        background-color: ${colors.BLACK};

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};
        }
    }
`;

export const SecondaryButton = styled.button`
    background-color: ${colors.WHITE};
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    padding: 16px 30px;
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    margin-left: 0;
    width: 100%;
    order: 1;
    cursor: pointer;
    height: 52px;

    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`
    margin-left: 0;
    order: 0;
`}
    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }
`;
