export const Divisions = {
  Freight: {
    name: 'Freight',
    code: 3,
  },
  Sameday: {
    name: 'Sameday',
    code: 4,
  },
  SCTN: {
    name: 'SCTN',
    code: null,
  },
  SelectaService: {
    name: 'Select a Service',
    code: null,
  },
};

export const UserProfile = {
  PROFILE_INFORMATION: 'Profile Information',
  ACCOUNT_PRIVILEAGES: 'Account Privileges',
  USER_PREFERENCES: 'User Preferences',

};

export const ShipmentStatuses = {
  Expired: 'Expired',
  Finalized: 'Finalized',
  UnderReview: 'Under Review',
  Delivered: 'Delivered',
  NotYetPickedUp: 'Not yet picked up',
  USQuote: 'US Quote',
};

export const PickupStatuses = {
  RequestReceived: 'Pick Up request Received',
  AssignedDriver: 'Assigned To Driver',
  EnRouteToShipper: 'Enroute to Shipper',
  ArrivalToShipper: 'Arrival to Shipper',
  PickedUp: 'Picked Up',
  PickUpException: 'Pick Up Exception',
  Draft: 'Draft',
};

export const QuoteStatuses = {
  Expired: 'Expired',
  Finalized: 'Finalized',
  UnderReview: 'Under Review',
  USQuote: 'US Quote',
};

export const UnitsOfMeasure = {
  Metric: 'Metric',
  Imperial: 'Imperial',
  Inches: 'Inches',
  Feet: 'Feet',
  Centimeters: 'Centimeters',
  Meters: 'Meters',
};

export const QuoteTypes = {
  USQuote: 'US Quote',
  Domestic: 'Domestic Quote',
};

export const DeliveryTypes = {
  Commercial: 'Commercial',
  Residential: 'Residential',
};

export const BillingAccountType = {
  Protected: 'Protected Billing Account',
  Billing: 'Billing Account',
};

export const Locales = {
  English: 'en',
  French: 'fr-CA',
};

export const Languages = {
  EN: 'EN',
  FR: 'FR',
};

export const Currencies = {
  USD: 'USD',
  CAD: 'CAD',
};

export const CountryCodes = {
  US: 'US',
  CANADA: 'CA',
};
export const CountryNames = {
  CANADA: 'CANADA',
  US: 'USA',
};
export const AddressTypes = {
  SHIPPER: 'shipper',
  CONSIGNEE: 'consignee',
  SHARED: 'shared',
  OTHER: 'other',
};

export const ImportContactTypes = {
  ALL: 'All',
  SHIPPER: 'Shipper (SHP)',
  CONSIGNEE: 'Consignee (CON)',
  SHARED: 'Shared (SHR – both SHP & CON)',
  OTHER: 'Other (OTH - 3rd Party etc.)',
};

export const CompanyActions = {
  DISABLECOMPANY: 'Disable Company',
};

export const ShipmentTypes = {
  US_SHIPMENT: 'USShipment',
};

export const PaymentMethods = {
  Prepaid: 'Prepaid',
  CreditCard: 'Credit Card',
  Collect: 'Collect',
  ThirdParty: 'Third Party',
};
export const AMPM = {
  AM: 'AM',
  PM: 'PM',
};
export const SamedayServiceType = {
  Commercial: 'C',
  Residential: 'H',
};

export const CommerceServiceType = {
  Commercial: 'C',
  Residential: 'R',
};

export const DeliveryDateTypes = {
  Unknown: 'Unknown',
  RequestedPickup: 'RequestedPickup',
  AppointmentPickup: 'AppointmentPickup',
  ActualPickup: 'ActualPickup',
  EstimatedDelivery: 'EstimatedDelivery',
  AppointmentDelivery: 'AppointmentDelivery',
  ActualDelivery: 'ActualDelivery',
};

export const PackagingGroups = {
  I: {
    name: 'I',
    value: 'I',
  },
  II: {
    name: 'II',
    value: 'II',
  },
  III: {
    name: 'III',
    value: 'III',
  },
  NotApplicable: {
    name: 'N/A',
    value: 'n_a',
  },
};

export const PrefillNumberTypes = {
  quote: 'quote',
  shipment: 'shipment',
  none: 'none',
};
export const PrefillClaimTypes = {
  damage: 'damage',
  other: 'other',
};
export const BillToMethods = {
  enterBillTo: 'EnterBillTo',
  pickBillTo: 'PickBillTo',
};

export const SamedayShipmentType = {
  Regular: 'Regular',
  Exchange: 'Exchange',
  Return: 'Return',
};
export const shipmentPickupServiceTypes = {
  Freight: [
    'ShipInCanada',
    'ShipCanadaToUS',
    'ShipUSToCanada',
    'RequestPickup',
  ],
  Sameday: [
    'ShipInCanada',
    'RequestPickup',
  ],
};
export const IsYesOrNo = {
  Yes: 'Yes',
  No: 'No',
};
export const LanguagesPreference = {
  English: 'English',
  French: 'French',
};
