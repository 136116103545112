import styled from 'styled-components';
import { H1 } from '../../theme/typographySitecore';
import Row from '../ReactComponent/Row';

export const HeaderContainer = styled(Row)`
    margin: 45px 0;
`;

export const HeaderTitle = styled(H1)`
    margin-bottom: 3rem;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 72px 0;
    flex-wrap: wrap;
`;
