import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../utils/constants';

export const getTariffDiscountByDivision = (division, data) => axios.post(
  `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.tariffs}/${division}/discount`,
  data
);

export const validateTariffZone = ({
  accountNumber,
  fromPostalCode,
  toPostalCode,
  originZone,
  destinationZone
}) => axios.post(
  `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.tariffs}/validate`,
  {
    accountNumber,
    fromPostalCode,
    toPostalCode,
    originZone,
    destinationZone
  }
);
