import React, { Component } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { H2, H3 } from '../../../theme/typographySitecore';
import {
  TitleContainer,
  SubtitleContainer,
  StyledForm,
  ButtonGroup,
  PrimaryButton,
  SecondaryButton,
  CheckboxInput,
  CheckBoxGroup,
  CheckboxLabel,
} from './css';
import SideBar from '../SideBar';
import { ListLabel } from '../RenderField/css';

class CustomizeTableView extends Component {
  constructor(props) {
    super(props);

    const { columns } = this.props;

    this.state = {
      renderedColumns: [],
      columns,
      defaultColumns: []
    };
  }

  componentDidMount() {
    this.setDefaultColumns();
    this.setState((prevState) => ({ renderedColumns: prevState.defaultColumns }));
  }

  setDefaultColumns = () => {
    const defaultColumns = [];
    this.state.columns.forEach((each) => {
      if (each.default === true) {
        defaultColumns.push(each);
      }
    });
    this.setState({ defaultColumns });
  };

  resetSelectedColumns = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const cols = this.state.columns;
    cols.forEach((each) => {
      if (each.default === true) {
        each.selected = true;
      } else {
        each.selected = false;
      }
    });
    this.setState({ columns: cols });
  };

  resetView = () => {
    this.setState((prevState) => ({
      renderedColumns: prevState.defaultColumns
    }), () => {
      this.props.updateTable(this.state.renderedColumns);
      this.props.hideView();
      this.resetSelectedColumns();
    });
  };

  updateTable = () => {
    this.setState((prevState) => ({
      renderedColumns: prevState.columns.filter((each) => each.selected === true)
    }), () => {
      this.props.updateTable(this.state.renderedColumns);
      this.props.hideView();
    });
  };

  onChange = (e) => {
    const { id } = e.target;
    // eslint-disable-next-line react/destructuring-assignment
    const { columns } = this.state;
    columns.forEach((each) => {
      if (each.id === Number(id)) {
        each.selected = !each.selected;
      }
    });
    this.setState({ columns });
  };

  onClose = () => {
    this.props.hideView();
  };

  renderCheckboxGroup = (options) => (
      <CheckBox options={options} handleChange={this.onChange}/>
  );

  render() {
    return (
      <SideBar close={this.onClose}>
        <TitleContainer>
          <H2 field={t('CustomTableViewTitle')} />
        </TitleContainer>
        <SubtitleContainer>
          <H3 field={t('CustomTableViewSubtitle')} />
        </SubtitleContainer>
        <StyledForm>
          {this.renderCheckboxGroup(this.state.columns)}
          <ButtonGroup>
            <PrimaryButton onClick={this.updateTable} className="active">{t('UpdateTable')}</PrimaryButton>
            <SecondaryButton onClick={this.resetView}>{t('ResetView')}</SecondaryButton>
          </ButtonGroup>
        </StyledForm>
      </SideBar>
    );
  };
}

CustomizeTableView.propTypes = {
  columns: PropTypes.array.isRequired,
  updateTable: PropTypes.func.isRequired,
  hideView: PropTypes.func
};
export default CustomizeTableView;

// eslint-disable-next-line react/no-multi-comp
const CheckBox = ({ options, handleChange }) => (
    <CheckBoxGroup>
      {options.map((option) => {
        if (option.label === '') {
          return null;
        }
        return (
            <CheckboxLabel key={option.id}>
              <CheckboxInput
                id={option.id}
                name={option.id}
                onChange={handleChange}
                value={option}
                checked={option.selected}
                disabled={option.disabled}
                type="checkbox" />
              <ListLabel>{t(option.label)}</ListLabel>
            </CheckboxLabel>
        );
      })}
    </CheckBoxGroup>
);
