import Modal from 'react-modal';
import styled from 'styled-components';
import close from '../../../assets/icons/Close.svg';
import { colors, MediaQuery } from '../../../theme/style-helpers';

export const SideMenuModal = styled(Modal)`
  width: 100%;

  ${MediaQuery.medium`
      width: 500px;
  `}
`;

export const ModalContainer = styled.div`
  line-height: 150%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;  
  
  ${MediaQuery.medium`
    padding: 0 20px;
  `}
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 25px 0;
  font-size: 3em;
`;

export const ModalContent = styled.div`
  width: 100%;
  font-size: 13px;
  text-align: center;
`;

export const ModalFooter = styled.div`
  text-align: center;
`;

export const ModalCloseButton = styled.button`
  align-self: flex-end;
  border: none;
  background: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  padding: 5px 5px 5px 10px;
  transition: background-color 0.4s;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #EEE;
  }
`;

export const CloseIcon = styled.img`
  content: url(${close});
`;

export const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    position: 'absolute',
    height: '100%',
    top: '0',
    left: 'auto',
    right: '0',
    bottom: 'auto',
    border: 'none',
    borderRadius: '0px',
    background: `${colors.WHITE}`,
    padding: '15px',
    overflowY: 'scroll',
  },
};
