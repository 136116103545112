import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { anchor } from '../../utils/func';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { P } from '../../theme/typography';
import utils from '../../utils/utils';

import {
  SectionTitle,
  Section,
  CarouselItem,
  HeadingLine,
} from './css';
import mockDataNewsCarousel from '../../mockups/newsCarousel';

const NewsCarousel = ({ fields }) => {
  const carouselItem = (key, item) => {
    const {
      item: {
        description,
        heading,
        heroImage,
        url,
      },
    } = item;

    return <CarouselItem
      key={key}
      $backgroundImageSrc={heroImage && heroImage.jss && heroImage.jss.value && heroImage.jss.value.src ? heroImage.jss.value.src : null}>
      <div className="content">
        {heading && heading.value
          && <>
            <span className="heading">{heading.value}</span>
            <HeadingLine/>
          </>}
        {description && description.value && <P className="text">{description.value.substring(0, 100)}</P>}
        <Link to={url} className="readMore">
          {t('ReadMore')}
          <span className="icon"></span>
        </Link>
      </div>
    </CarouselItem>;
  };

  const localFields = utils.isDisconnected ? mockDataNewsCarousel : fields;

  const {
    data: {
      datasourceItem,
      drsearch
    }
  } = localFields;
  if (!localFields || !localFields.data) {
    const errors = localFields != null ? localFields.errors : null;
    console.error('news carousel errors', errors);
    return null;
  }

  if (!drsearch) {
    return null;
  }

  const { results: { items } } = drsearch;

  if (!items || items.length === 0) {
    return null;
  }

  const heading = datasourceItem ? datasourceItem.heading : null;

  const id = datasourceItem && datasourceItem.anchor && datasourceItem.anchor.value ? anchor(datasourceItem.anchor.value) : '';

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return <Section id={id} className="static-section">
    {heading && heading.value
      && <SectionTitle>
        {heading.value}
      </SectionTitle>}
    <Slider className="carousel-items" {...settings}>
      {items && (items.map((item, key) => carouselItem(key, item)))}
    </Slider>
  </Section>;
};

NewsCarousel.propTypes = {
  fields: PropTypes.object
};

export default NewsCarousel;
