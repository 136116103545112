import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@sitecore-jss/sitecore-jss-react';

const LinkType = { External: 'external', Internal: 'internal' };

class NavigationLink extends PureComponent {
  render() {
    const { fields, contextItemId } = this.props;

    if (!(fields && fields.link && fields.link.jss && fields.link.jss.value && fields.link.jss.value.href && fields.link.jss.value.text)) {
      return null;
    }

    const id = fields.link.targetItem ? fields.link.targetItem.id : null;

    const className = `nav-link${contextItemId === id ? ' active' : ''}`;

    if (fields.link.jss && fields.link.jss.value && fields.link.jss.value.linktype === LinkType.External) {
      fields.link.jss.value.target = '_blank';
    }

    return (
            <li className="nav-item">
                <Link field={fields.link.jss} className={className} to="#" />
            </li>
    );
  }
}

NavigationLink.propTypes = {
  fields: PropTypes.shape({
    link: PropTypes.shape({
      jss: PropTypes.shape({
        value: PropTypes.shape({
          href: PropTypes.string,
          text: PropTypes.string,
          linktype: PropTypes.string,
          target: PropTypes.string,
        }),
      }),
      targetItem: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    targetItem: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  contextItemId: PropTypes.string,
};

export default NavigationLink;
