import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { t } from 'i18next';
import { Beforeunload } from 'react-beforeunload';
import FormProgressBar from '../ReactComponent/FormProgressBar';
import {
  H1,
  H3,
  P,
  PLight
} from '../../theme/typography';
import Tooltip from '../ReactComponent/Tooltip';
import { digitOnly } from '../../utils/mask';
import {
  PrimaryButton,
  SecondaryButton,
  ButtonGroup,
  FormContainer,
  SuccessMessageBox
} from './css';
import {
  Step1,
  Step2,
  Step3,
  Step4
} from './steps';
import { Divisions } from '../../utils/enums';
import { scrollToTop, runningOnBrowser } from '../../utils/func';
import { endpoints } from '../../utils/constants';

class SubmitClaimForm extends PureComponent {
  constructor(props) {
    super(props);
    const { fields } = props;
    this.steps = [
      fields.step1Title.value,
      fields.step2Title.value,
      fields.step3Title.value,
      fields.step4Title.value];
    this.successImgSrc = fields.SuccessIcon.value.src;
    this.successImgAltText = fields.SuccessIcon.value.alt;
    this.state = {
      currentStep: 0,
      apiResponse: '',
      totalClaimValue: 0,
    };
  }

  updateTotalClaimValue = (value) => {
    this.setState({ totalClaimValue: value });
  };

  nextStep = (e) => {
    e.preventDefault();
    const { valid } = this.props;
    if (valid) {
      this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
        setTimeout(() => scrollToTop(), 200);
      });
    }
  };

  prevStep = (e) => {
    e.preventDefault();
    scrollToTop();
    this.setState((prevState) => ({ currentStep: prevState.currentStep - 1 }));
  };

  editStepOne = () => {
    scrollToTop();
    this.setState({ currentStep: 0 });
  };

  editStepTwo = () => {
    scrollToTop();
    this.setState({ currentStep: 1 });
  };

  editStepThree = () => {
    scrollToTop();
    this.setState({ currentStep: 2 });
  };

  checkUploadFiles = (uploadedFilesArr) => {
    const uploadedFiles = [];
    uploadedFilesArr.map((fileValues) => {
      if (fileValues) {
        uploadedFiles.push({
          fileName: fileValues.name,
          fileDataAsBase64: fileValues.file
        });
      }
      return null;
    });
    return uploadedFiles;
  };

  checkUploadFilesItems = (uploadedFilesArr) => {
    const uploadedFiles = [];
    if (uploadedFilesArr) {
      Object.values(uploadedFilesArr).map((fileValues) => {
        if (fileValues) {
          uploadedFiles.push({
            fileName: fileValues.name,
            fileDataAsBase64: fileValues.file
          });
        }
        return null;
      });
    }
    return uploadedFiles;
  };

  handleFreightSubmit = () => {
    const {
      formData: {
        ReferenceNumber,
        ClaimantName,
        ClaimantAddress,
        ContactName,
        PhoneNumber,
        Extension,
        EmailAddress,
        BillOfPayment,
        ShipperName,
        ShipperTelephoneNumber,
        ShipperExtension,
        ConsigneeName,
        ConsigneeTelephoneNumber,
        ConsigneeExtension,
        TypeOfClaim,
        AdditionalDamageInfo,
        DamageExplanation,
        QualityControl,
        Currency,
        PartsInformation,
        // files
        ProBillNumber,
        PaidFreightBill,
        ProofOfDelivery,
        CopyOfClaimantsCostInvoice,
        CopyOfRepairBill,
        SupportingDocumentation,
        PicturesOfAnyDamage,
        PicturesOfTrailerOrContainer,
        ClaimForm,
        PackingSlip,
        PartsInvoice
      },
      divisionCode
    } = this.props;
    let division = '';
    switch (divisionCode) {
      case Divisions.Freight.code:
        division = Divisions.Freight.name;
        break;
      case Divisions.Sameday.code:
        division = Divisions.Sameday.name;
        break;
      default:
        division = Divisions.Freight.name;
        break;
    }

    const items = PartsInformation.map((each) => {
      const {
        PartDescription,
        ClaimAmount,
        PartFileUpload
      } = each;
      // let itemObject = {
      //   amount: parseFloat(ClaimAmount),
      //   description: PartDescription
      // }
      // if (PartFileUpload) {
      //   itemObject.file = []
      //   itemObject.file.push(Object.values(this.checkUploadFilesItems(PartFileUpload))[0])
      // }
      // return itemObject;
      return {
        amount: parseFloat(ClaimAmount),
        description: PartDescription,
        file: this.checkUploadFilesItems(PartFileUpload),
      };
    });

    const AttachmentFiles = [
      ProBillNumber,
      PaidFreightBill,
      ProofOfDelivery,
      CopyOfClaimantsCostInvoice,
      CopyOfRepairBill,
      SupportingDocumentation,
      PicturesOfAnyDamage,
      PicturesOfTrailerOrContainer,
      ClaimForm,
      PackingSlip,
      PartsInvoice
    ];

    const formValueToAPI = {
      accountNumber: ReferenceNumber,
      companyName: ClaimantName,
      address: ClaimantAddress,
      contactName: ContactName,
      contactPhoneNumber: digitOnly(PhoneNumber),
      contactPhoneNumberExt: Extension,
      contactEmail: EmailAddress,
      proBillNumber: BillOfPayment,
      shipperName: ShipperName,
      shipperPhoneNumber: digitOnly(ShipperTelephoneNumber),
      shipperPhoneNumberExt: ShipperExtension,
      consigneeName: ConsigneeName,
      consigneePhoneNumber: digitOnly(ConsigneeTelephoneNumber),
      consigneePhoneNumberExt: ConsigneeExtension,
      claimType: TypeOfClaim,
      isDamageRepairable: AdditionalDamageInfo === 'Yes',
      claimExplanation: DamageExplanation,
      isTestingCompleted: QualityControl === 'Yes',
      items: [...items],
      uploadFiles: this.checkUploadFiles(AttachmentFiles),
      currencyCode: Currency
    };
    axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.submitClaims}/${division}`, formValueToAPI)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ apiResponse: 'success' });
        }
      }).catch((e) => {
        console.error(e);
      });
  };

  handleSubmit = () => {
    const {
      formData: {
        DealerName,
        DealerCode,
        DealerCompleteAddress,
        CompanyName,
        ContactName,
        PhoneNumber,
        EmailAddress,
        BillOfPayment,
        TypeOfClaim,
        ClaimValue,
        Currency,
        ContactedCustomerService,
        DateContactCustomerService,
        DescriptionOfTheClaim,
        PartsInformation,
        ProBillNumber,
        PaidFreightBill,
        ProofOfDelivery,
        CopyOfClaimantsCostInvoice,
        CopyOfRepairBill,
        SupportingDocumentation,
        PicturesOfAnyDamage,
        PicturesOfTrailerOrContainer,
        ClaimForm,
        PackingSlip,
        PartsInvoice
      }
    } = this.props;

    const parts = PartsInformation.map((each) => {
      const {
        PartNumber,
        PartDescription,
        PartFileUpload,
        PartDeliveryDate
      } = each;

      return {
        partNumber: PartNumber,
        description: PartDescription,
        file: this.checkUploadFilesItems(PartFileUpload),
        deliveryDate: PartDeliveryDate
      };
    });

    const AttachmentFiles = [
      ProBillNumber,
      PaidFreightBill,
      ProofOfDelivery,
      CopyOfClaimantsCostInvoice,
      CopyOfRepairBill,
      SupportingDocumentation,
      PicturesOfAnyDamage,
      PicturesOfTrailerOrContainer,
      ClaimForm,
      PackingSlip,
      PartsInvoice
    ];

    const formValueToAPI = {
      dealerName: DealerName,
      dealerCode: DealerCode,
      dealerAddress: DealerCompleteAddress,
      companyName: CompanyName,
      contactName: ContactName,
      contactPhoneNumber: digitOnly(PhoneNumber),
      contactEmail: EmailAddress,
      proBillNumber: BillOfPayment,
      claimType: TypeOfClaim,
      claimAmount: parseFloat(ClaimValue),
      claimAmountCurrencyCode: Currency,
      hasContactedCustomerService: ContactedCustomerService === 'Yes',
      contactedOnDate: DateContactCustomerService,
      claimDescription: DescriptionOfTheClaim,
      parts: [
        ...parts
      ],
      uploadFiles: this.checkUploadFiles(AttachmentFiles)
    };
    axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.submitClaims}/dedicated`, formValueToAPI)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ apiResponse: 'success' });
        }
      }).catch((e) => {
        console.error(e);
      });
  };

  render() {
    const {
      formData,
      valid,
      handleSubmit,
      submitSucceeded,
      fields
    } = this.props;

    const {
      currentStep,
      apiResponse,
      totalClaimValue
    } = this.state;

    const isFreight = fields.freightForm && fields.freightForm.value;
    let onlyOnlineForm = true;
    if (runningOnBrowser()) {
      if (window.location.href.includes('dedicated')) {
        onlyOnlineForm = false;
      }
    }
    return (
      <>
        <Beforeunload onBeforeunload={() => 'You\'ll lose your data!'}/>
        <FormProgressBar current={currentStep} steps={this.steps}/>
        <FormContainer>
          {submitSucceeded && apiResponse === 'success' ? (
            <SuccessMessageBox>
              <img className="success-image" src={this.successImgSrc} alt={this.successImgAltText}/>
              <H1 className="center">{t('ClaimSuccessHeader')}</H1>
              <P className="center">{t('ClaimSuccessText')}</P>
              <ButtonGroup className="center">
                <SecondaryButton
                  onClick={() => {
                    window.location.href = fields.manageClaimsLink.value.href;
                  }}
                  className="active min-width">
                  <span className="icon-left"></span>
                  {fields.manageClaimsLink.value.text}
                </SecondaryButton>
              </ButtonGroup>
            </SuccessMessageBox>
          ) : (

            <form onSubmit={isFreight ? handleSubmit(this.handleFreightSubmit) : handleSubmit(this.handleSubmit)}>
              <Step1
                step={currentStep}
                fields={fields}
                isFreight={isFreight}/>
              <Step2
                formData={formData}
                step={currentStep}
                fields={fields}
                isFreight={isFreight}
                updateTotalClaimValue={this.updateTotalClaimValue}/>
              <Step3
                formData={formData}
                step={currentStep}
                fields={fields}
                isFreight={isFreight}
              />
              <Step4
                formData={formData}
                step={currentStep}
                editStepOne={this.editStepOne}
                editStepTwo={this.editStepTwo}
                editStepThree={this.editStepThree}
                fields={fields}
                isFreight={isFreight}
                totalClaimValue={totalClaimValue}
              />
              {currentStep === 3 && valid ? (
                <ButtonGroup>
                  <PrimaryButton type="submit" className="submit">
                    <span className="icon"></span>
                    {t('Submit')}
                  </PrimaryButton>
                </ButtonGroup>) : (
                <ButtonGroup>
                  <SecondaryButton
                    type="button"
                    onClick={this.prevStep}
                    className={`${valid ? 'active' : ''} ${currentStep === 0 ? 'hidden' : ''}`}>
                    <span className="icon-left"></span>
                    {t('Back')}
                  </SecondaryButton>
                  <PrimaryButton type="button" onClick={this.nextStep} className={`${valid ? 'active' : ''}`}>
                    <span className="icon"></span>
                    {t('Next')}
                  </PrimaryButton>
                </ButtonGroup>)}
              {(currentStep === 1 && onlyOnlineForm) ? (
                <div style={{ marginTop: '-30px' }}>
                  <Tooltip tip={t('MaximumLiabilityClaim')} marginLeft/>
                  <H3>{t('MaximumLiabilityClaim')}</H3>
                  <PLight style={{
                    marginTop: '8px',
                    marginBottom: '20px'
                  }}>
                    <small>{t('DetailedDescriptionOfClaimMaximumLiability')}</small>
                  </PLight>
                </div>) : (
                <PLight></PLight>)}
            </form>)}
        </FormContainer>
      </>
    );
  }
}

SubmitClaimForm.propTypes = {
  formData: PropTypes.object,
  fields: PropTypes.shape({
    step1Title: PropTypes.shape({
      value: PropTypes.string,
    }),
    step2Title: PropTypes.shape({
      value: PropTypes.string,
    }),
    step3Title: PropTypes.shape({
      value: PropTypes.string,
    }),
    step4Title: PropTypes.shape({
      value: PropTypes.string,
    }),
    SuccessIcon: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
    }),
    freightForm: PropTypes.shape({
      value: PropTypes.string,
    }),
    manageClaimsLink: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
  }),
  valid: PropTypes.bool,
  divisionCode: PropTypes.string,
  handleSubmit: PropTypes.func,
  submitSucceeded: PropTypes.bool,
};

SubmitClaimForm = reduxForm({
  form: 'SubmitClaimForm', // a unique identifier for this form
  initialValues: {
    PartsInformation: [{
      PartDescription: '',
      PartNumber: ''
    }],
    TotalClaimValue: '0'
  }
})(SubmitClaimForm);

const mstp = (state) => ({
  formData: getFormValues('SubmitClaimForm')(state),
  divisionCode: state.profile.divisionCode
});

export default connect(mstp)(SubmitClaimForm);
