import React from 'react';
import PropTypes from 'prop-types';
import { MenuContainer } from './css';
import NavigationSection from './navigationSection';
import NavigationLink from './navigationLink';

class SecondaryNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false,
      key: '',
    };
  }

  onToggleSection = (key) => {
    if (this.state.key === key) {
      this.setState({
        navExpanded: false,
        key: ''
      });
    } else {
      this.setState({
        navExpanded: true,
        key
      });
    }
  };

  render() {
    const { children } = this.props.fields.data.item;
    if (!children || children.length === 0) {
      return null;
    }

    const { id } = this.props.fields.data.contextItem;

    const startLevel = 1;
    const className = `nav-menu${this.state.navExpanded ? ' open' : ''}`;

    return (
      <MenuContainer $isDisplayed={this.props.isDisplayed}>
      <ul className={className}>
          {children.map((c, key) => {
            if (c.template.name === 'Navigation-Section') {
              const newSectionOpen = (this.state.navExpanded && this.state.key === key);
              return <NavigationSection
                  key={key}
                  id={key}
                  section={c}
                  level={startLevel}
                  onToggle={this.onToggleSection}
                  open={newSectionOpen}
                  contextItemId={id}/>;
            }
            if (c.template.name === 'Navigation-Link') {
              return <NavigationLink key={key} fields={c} level={startLevel} contextItemId={id} />;
            }
            return null;
          }
          )}
        </ul>
      </MenuContainer>
    );
  };
}

SecondaryNavigation.propTypes = {
  isDisplayed: PropTypes.bool,
  fields: PropTypes.shape({
    data: PropTypes.shape({
      item: PropTypes.shape({
        children: PropTypes.arrayOf(PropTypes.object),
      }),
      contextItem: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
};

export default SecondaryNavigation;
