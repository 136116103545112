import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Row from '../Row';
import Column from '../Column';
import { H3, P } from '../../../theme/typography';
import GreyBoxWithBorder from '../../_styledComponents/GreyBoxWithBorder';
import { formatMoney } from '../../../utils/func';

const TariffSurchargeSummaryBlock = ({ tariffSurcharge, tablet, mobile }) => (
    <Column $tablet={tablet} $mobile={mobile}>
      <GreyBoxWithBorder>
        <Row>
          <Column>
            <P>{t('FuelSurchargeLTL')}</P>
            <H3>{tariffSurcharge.fuelSurchargePercentage && `${tariffSurcharge.fuelSurchargePercentage}%`}</H3>
          </Column>
          <Column>
            <P>{t('DiscountLTL')}</P>
            <H3>{`${tariffSurcharge.discountPercentage}%`}</H3>
          </Column>
          <Column>
            <P>{t('Minimum Rate')}</P>
            <H3>{tariffSurcharge.minimumRateAmount && `${formatMoney(tariffSurcharge.minimumRateAmount.value)} USD`}</H3>
          </Column>
        </Row>
      </GreyBoxWithBorder>
    </Column>
);

TariffSurchargeSummaryBlock.propTypes = {
  tariffSurcharge: PropTypes.shape({
    fuelSurchargePercentage: PropTypes.number,
    discountPercentage: PropTypes.number,
    minimumRateAmount: PropTypes.shape({
      value: PropTypes.number,
    }),
  }),
  tablet: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default TariffSurchargeSummaryBlock;
