import styled from 'styled-components';

export const Item = styled.div`
  background-color: white;
    padding: 32px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 100%;
    margin: 24px 0;
`;

export const CubeContainer = styled.div`
  display: flex;
`;
