import React from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import {
  reduxForm, Field, change, getFormValues
} from 'redux-form';
import Modal from 'react-modal';
import {
  MDBDropdown,
} from 'mdbreact';
import { saveAs } from 'file-saver';
import {
  getInvoicesByDivision,
  getInvoiceDocument,
  getInvoiceReports,
} from '../../services/invoices';
import QuestionsBlock from '../ReactComponent/QuestionsBlock';
import { isRequired, dateRangeValidate } from '../../utils/validator';
import SortableTable from '../ReactComponent/SortableTable';
import { H2 } from '../../theme/typography';
import Filter from '../../assets/icons/Filter.svg';
import FilterOrange from '../../assets/icons/FilterOrange.svg';
import Actions from '../../assets/icons/Actions.svg';
import DownloadWhite from '../../assets/icons/DownloadWhite.svg';
import PrimaryBtn from '../ReactComponent/PrimaryButton';
import SecondaryBtn from '../ReactComponent/SecondaryButton';
import {
  SecondaryButton,
  ButtonGroup,
  FilterContainer,
  FilterIcon,
  Icon,
  CategoryTabs,
  Category,
  PageDescription,
  PageTitle,
  SmallDesc,
  CategoryTitle,
  CommonPrimaryButton,
  DateField,
  StyledForm,
  ModalTitle,
  ModalBody,
  ButtonDiv
} from './css';
import { putReduxAllRecentInvoices, putReduxAllHistoricalInvoices, putReduxInvoice } from './actions';
import { Icon as CommonIcon } from '../ReactComponent/Icon';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import SearchWithFilter from '../ReactComponent/SearchWithFilter';
import RenderField from '../ReactComponent/RenderField';
import SideBar from '../ReactComponent/SideBar';
import {
  formatDate, formatMoney, formatDisplayDate, checkBillToAccountProtectedStatus, getCurrentLanguage, downloadFile
} from '../../utils/func';
import DownloadReportModal from '../ReactComponent/DownloadReportModal';
import NoDataDecorator from '../ReactComponent/SortableTable/NoDataDecorator';
import { billingAccountTextFormatter } from '../../utils/form';
import ColumnTypes from '../ReactComponent/SortableTable/constants';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';
import { routes } from '../../utils/constants';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import Card from '../ReactComponent/Card';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';

export const isBeforeMay2020 = (values, allValues, props) => {
  // eslint-disable-next-line no-underscore-dangle
  const dateLimit = (props._reduxForm && props._reduxForm.fields) ? props._reduxForm.fields.dateLimit.value : props.fields.dateLimit.value;
  // eslint-disable-next-line no-underscore-dangle
  const dateLimitErrorMsg = (props._reduxForm && props._reduxForm.fields)
    // eslint-disable-next-line no-underscore-dangle
    ? props._reduxForm.fields.dateLimitErrorMsg.value
    : props.fields.dateLimitErrorMsg.value;
  if (values) {
    const toDate = new Date(values);
    const may2020 = new Date(dateLimit);
    if (toDate >= may2020) {
      return dateLimitErrorMsg;
    }
  }
  return null;
};
const formName = 'ManageInvoices';
const Dropdown = ({
  invoiceNumber, invoiceType, transactionSource, getSingleInvoice
}) => (
  <MDBDropdown className="dropdown">
    <MDBDropdownToggle className="border-0">
      <CommonIcon iconSrc={Actions} />
    </MDBDropdownToggle>
    <StyledMDBDropdownMenu className="text-white bg-dark" basic>
      <DropdownItem onClick={() => getSingleInvoice(invoiceNumber, invoiceType, transactionSource)}>
        <DropdownItemIcon iconSrc={DownloadWhite} />
        {t('Download')}
      </DropdownItem>
    </StyledMDBDropdownMenu>
  </MDBDropdown>
);
const reportEndpoints = ['RecentReport', 'HistoricalReport'];
const columns = [
  {
    id: 1,
    label: '',
    field: 'actions',
    sort: false,
    selected: true,
    default: true
  },
  {
    id: 2,
    label: 'InvoiceNumber',
    field: 'invoiceNumber',
    sort: true,
    selected: true,
    default: true,
    disabled: true
  },
  {
    id: 3,
    label: 'ShipmentNumber',
    field: 'shipmentNumber',
    sort: true,
    selected: true,
    default: true,
    disabled: true
  },
  {
    id: 4,
    label: 'BillingAccount',
    field: 'billingAccount',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 5,
    label: 'InvoiceDate',
    field: 'invoiceDate',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 6,
    label: 'DueDate',
    field: 'dueDate',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 7,
    label: 'InvoiceAmount',
    field: 'invoiceAmount',
    sort: true,
    selected: false,
    default: false,
    type: ColumnTypes.Currency
  },
  {
    id: 8,
    label: 'BalanceDue',
    field: 'balanceDue',
    sort: true,
    selected: false,
    default: false,
    type: ColumnTypes.Currency
  }
];

const historicalColumns = [
  {
    id: 1,
    label: '',
    field: 'actions',
    sort: false,
    selected: true,
    default: true
  },
  {
    id: 2,
    label: 'InvoiceNumber',
    field: 'invoiceNumber',
    sort: true,
    selected: true,
    default: true,
    disabled: true
  },
  {
    id: 3,
    label: 'BillingAccount',
    field: 'billingAccount',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 4,
    label: 'InvoiceDate',
    field: 'invoiceDate',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 5,
    label: 'DueDate',
    field: 'dueDate',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 6,
    label: 'InvoiceAmount',
    field: 'invoiceAmount',
    sort: true,
    selected: false,
    default: false,
    type: ColumnTypes.Currency
  },
  {
    id: 7,
    label: 'BalanceDue',
    field: 'balanceDue',
    sort: true,
    selected: false,
    default: false,
    type: ColumnTypes.Currency
  }
];

const NUMBER_OF_DAYS = 60;
const initialValues = {
  filterFromDate: formatDate(
    new Date(new Date().getTime() - NUMBER_OF_DAYS * 24 * 60 * 60 * 1000)
  ),
  filterToDate: formatDate(new Date())
};

// eslint-disable-next-line react/no-multi-comp
class ManageInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filterOpen: false,
      customizeOpen: false,
      dataPerPage: 10,
      activeTab: 0,
      statusList: [],
      // eslint-disable-next-line react/no-unused-state
      totalPages: [],
      prevFromDate: initialValues.filterFromDate,
      prevToDate: initialValues.filterToDate,
      // eslint-disable-next-line react/destructuring-assignment
      prevAccountNumbers: this.props.accountNumbers,
      prevFromDateRecent: initialValues.filterFromDate,
      prevToDateRecent: initialValues.filterToDate,
      // eslint-disable-next-line react/destructuring-assignment
      prevAccountNumbersRecent: this.props.accountNumbers,
      prevFromDateHistorical: initialValues.filterFromDate,
      prevToDateHistorical: initialValues.filterToDate,
      // eslint-disable-next-line react/destructuring-assignment
      prevAccountNumbersHistorical: this.props.accountNumbers,
      displayCustomizeTableView: false,
      modalAction: null,
      modalIsOpen: false,
      modalShipmentNumber: null,
      downloadOpen: false,
      historicalVisited: false,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      accountNumbers
    } = this.props;
    dispatch(change(formName, 'filterAccountNumbers', accountNumbers));
    setTimeout(() => { this.fetchAllInvoices(); }, 200);
    checkBillToAccountProtectedStatus(this.props.dispatch, this.props.division, this.props.userId, this.props.billingAccounts);
  }

  // TODO: Needs error handling
  /**
   * Fetch invoices for the given filterFromDate, filterToDate date range and filterAccountNumbers collection.
   * Parameter values are defaulted if not supplied.
   * @param params {filterFromDate = "YYYY-MM-DD", filterToDate = "YYYY-MM-DD", filterAccountNumbers = []}.
   */
  fetchAllInvoices = async (params) => {
    const {
      division,
      accountNumbers
    } = this.props;
    const { activeTab } = this.state;
    const historical = activeTab === 3;
    // Derive params...
    params = params || {
      filterFromDate: initialValues.filterFromDate,
      filterToDate: initialValues.filterToDate,
      filterAccountNumbers: accountNumbers
    };

    const fromDate = params.filterFromDate;
    const toDate = params.filterToDate;
    const acctNumbers = params.filterAccountNumbers;

    getInvoicesByDivision(division, acctNumbers, fromDate, toDate, historical)
      .then((response) => {
        if (response.status === 200) {
          const convertedData = this.convertDataFormat(response.data);
          this.setState({ data: convertedData }, () => {
            this.getTotalPages();
          });
          if (historical) {
            // eslint-disable-next-line no-shadow
            const { putReduxAllHistoricalInvoices } = this.props;
            putReduxAllHistoricalInvoices(convertedData);
            const { historicalVisited } = this.state;
            if (!historicalVisited) {
              this.setState({ historicalVisited: true });
            };
          } else {
            // eslint-disable-next-line no-shadow
            const { putReduxAllRecentInvoices } = this.props;
            putReduxAllRecentInvoices(convertedData);
          }
          this.changeTab(activeTab);
        }
      })
      .catch((error) => console.error('error', error));
  };

  convertDataFormat = (data) => {
    // data tempering here
    const { billingAccounts } = this.props;

    const sortedData = data.sort((a, b) => Date.parse(b.invoiceDate) - Date.parse(a.invoiceDate));

    return sortedData.map((each) => ({
      invoiceNumber: each.invoiceNumber,
      billingAccount: billingAccountTextFormatter(
        billingAccounts.find(({ accountNumber }) => accountNumber === each.accountNumber)
      ) || `${each.customerName}${each.accountNumber ? `${` - ${each.accountNumber}`}` : ''}`,
      shipmentNumber: each.shipmentNumber,
      invoiceDate: each.invoiceDate && formatDisplayDate(each.invoiceDate),
      dueDate: each.dueDate && formatDisplayDate(each.dueDate),
      invoiceAmount: `${each.invoiceAmount && formatMoney(each.invoiceAmount.value)} ${each.invoiceAmount.currencyCode}`,
      balanceDue: `${each.balanceDue && formatMoney(each.balanceDue.value)} ${each.balanceDue && each.balanceDue.currencyCode}`,
      invoiceType: each.invoiceType,
      actions: (
        <Dropdown
          invoiceNumber={each.invoiceId || each.invoiceNumber}
          invoiceType={each.invoiceType}
          transactionSource={each.transactionSource}
          getSingleInvoice={(invoiceNumber, invoiceType, transactionSource) =>
            this.getSingleInvoice(invoiceNumber, invoiceType, transactionSource)}
        />
      )
    }
    ));
  };

  getSingleInvoice = (invoiceNumber, invoiceType, transactionSource) => {
    const { division } = this.props;
    const { activeTab } = this.state;
    const language = getCurrentLanguage();
    const historical = activeTab === 3;
    if (transactionSource === 'CONVERSION') {
      this.setState({ modalIsOpen: true });
    } else {
      getInvoiceDocument(division, invoiceNumber, invoiceType, historical, language).then((res) => {
        if (res.status === 200) {
          const data = atob(res.data.fileDataAsBase64);
          const byteNumbers = new Array(data.length);
          for (let i = 0; i < data.length; i++) {
            byteNumbers[i] = data.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          saveAs(
            new Blob([byteArray], { type: res.data.mimeType }),
            res.data.fileName
          );
        }
      });
    }
  };

  getTotalPages = () => {
    const {
      dataPerPage,
      data
    } = this.state;
    const totalPages = [];
    if (data) {
      for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
        totalPages.push(i);
      }
    }
    this.setState({ totalPages });
  };

  getTabRelativeData = (activeTab) => {
    const {
      allRecentInvoices,
      allHistoricalInvoices
    } = this.props;
    const currentDate = new Date();
    let filteredData = [];
    switch (activeTab) {
      case 1:
        filteredData = allRecentInvoices.filter((invoice) => {
          const currentInvoiceBalanceDueVal = invoice.balanceDue.replace(/[^0-9.-]+/g, '');
          return new Date(invoice.dueDate.replace(/-/g, '/')) < currentDate && currentInvoiceBalanceDueVal > 0;
        });
        break;
      case 2:
        // Paid Invoices
        filteredData = allRecentInvoices.filter((invoice) => {
          const currentInvoiceBalanceDueVal = invoice.balanceDue.replace(/[^0-9.-]+/g, '');
          return currentInvoiceBalanceDueVal <= 0;
        });
        break;
      case 3:
        filteredData = allHistoricalInvoices;
        break;
      case 0:
      default:
        filteredData = allRecentInvoices;
        break;
    }
    return filteredData;
  };

  getSuggestions = (value) => {
    const { activeTab } = this.state;
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    const allData = this.getTabRelativeData(activeTab);
    const filteredData =
      inputLength === 0
        ? allData
        : allData.filter(
          (item) =>
            (item.invoiceNumber && item.invoiceNumber.toString().slice(0, inputLength).toLowerCase() === inputValue.toLowerCase()) ||
            (item.shipmentNumber && item.shipmentNumber.toString().slice(0, inputLength).toLowerCase() === inputValue.toLowerCase())
        );
    this.setState({ data: filteredData }, () => this.getTotalPages());
  };

  changeTab = (tabIndex) => {
    const { dispatch } = this.props;
    const {
      activeTab,
      prevFromDateHistorical,
      prevToDateHistorical,
      prevAccountNumbersHistorical,
      prevToDateRecent,
      prevFromDateRecent,
      prevAccountNumbersRecent,
    } = this.state;
    const historical = activeTab === 3;
    const filteredData = this.getTabRelativeData(tabIndex);
    dispatch(change(formName, 'searchByKey', ''));
    if ((historical && tabIndex !== 3) || (tabIndex === 3 && !historical)) {
      if (tabIndex === 3) {
        this.setState({
          prevFromDate: prevFromDateHistorical,
          prevToDate: prevToDateHistorical,
          prevAccountNumbers: prevAccountNumbersHistorical
        },
        () => {
          dispatch(change(formName, 'filterFromDate', this.state.prevFromDate));
          dispatch(change(formName, 'filterToDate', this.state.prevToDate));
          dispatch(change(formName, 'filterAccountNumbers', this.state.prevAccountNumbers));
        });
      } else {
        this.setState({
          prevFromDate: prevFromDateRecent,
          prevToDate: prevToDateRecent,
          prevAccountNumbers: prevAccountNumbersRecent
        },
        () => {
          dispatch(change(formName, 'filterFromDate', this.state.prevFromDate));
          dispatch(change(formName, 'filterToDate', this.state.prevToDate));
          dispatch(change(formName, 'filterAccountNumbers', this.state.prevAccountNumbers));
        });
      }
    }
    this.setState({
      activeTab: tabIndex,
      data: filteredData
    }, () => this.getTotalPages());
  };

  handleSubmitFilter = () => {
    const {
      dispatch,
      formData: {
        filterFromDate,
        filterToDate,
        filterAccountNumbers
      }
    } = this.props;
    const {
      prevFromDate,
      prevToDate,
      prevAccountNumbers,
      activeTab,
      historicalVisited
    } = this.state;
    const historical = activeTab === 3;
    dispatch(change(formName, 'searchByKey', ''));

    if (
      filterFromDate !== prevFromDate ||
      filterToDate !== prevToDate ||
      filterAccountNumbers !== prevAccountNumbers ||
      (historical && !historicalVisited)
    ) {
      if (historical) {
        this.setState({
          prevFromDateHistorical: filterFromDate,
          prevToDateHistorical: filterToDate,
          prevAccountNumbersHistorical: filterAccountNumbers
        });
      } else {
        this.setState({
          prevFromDateRecent: filterFromDate,
          prevToDateRecent: filterToDate,
          prevAccountNumbersRecent: filterAccountNumbers
        });
      }
      this.fetchAllInvoices({
        filterFromDate,
        filterToDate,
        filterAccountNumbers,
      });
    }

    this.toggleFilter();
  };

  resetFilters = () => {
    const { dispatch } = this.props;
    dispatch(change(formName, 'searchByKey', ''));
    dispatch(
      change(formName, 'filterFromDate', initialValues.filterFromDate)
    );
    dispatch(
      change(formName, 'filterToDate', initialValues.filterToDate)
    );
    dispatch(
      change(
        formName,
        'filterAccountNumbers',
        this.props.accountNumbers
      )
    );

    this.fetchAllInvoices();
  };

  toggleFilter = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    const { filterOpen } = this.state;
    this.setState({ filterOpen: !filterOpen });
  };

  handleDownloadReport = () => {
    this.setState({ downloadOpen: true });
  };

  closeDownloadReport = () => {
    this.setState({ downloadOpen: false });
  };

  handleDownloadSubmit = async ({
    filterFromDate,
    filterToDate,
    filterAccountNumbers,
    reportEndpoint
  }) => {
    const { division } = this.props;
    const language = getCurrentLanguage();
    await getInvoiceReports(
      division,
      filterAccountNumbers,
      filterFromDate,
      filterToDate,
      language,
      reportEndpoint === 'HistoricalReport'
    ).then((res) => {
      if (res.status === 200) {
        const {
          fileName,
          mimeType,
          fileDataAsBase64
        } = res.data;
        const fileType = fileName.includes('.csv') ? '' : '.csv';
        downloadFile({
          dataAsBase64: fileDataAsBase64,
          mimeType,
          filename: fileName + fileType
        });
      }
    });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleModalButtons = (event) => {
    event.preventDefault();

    const {
      target: { name }
    } = event;

    if (name === 'okayButton') {
      this.closeModal();
    }
  };

  handleFilterFromDate = (date) => {
    const { dispatch } = this.props;
    const updatedFilterToDate = formatDate(new Date(date).getTime() + (NUMBER_OF_DAYS * 24 * 60 * 60 * 1000));
    dispatch(change(formName, 'filterToDate', updatedFilterToDate));
  };

  renderHeader = ({
    heading,
    description
  }) => (
      <Row style={{ margin: '45px 0' }}>
        <Column $tablet={8} $mobile={12}>
          <div>
            <H2>{heading}</H2>
            <PageDescription field={description}></PageDescription>
          </div>
        </Column>
        <Column $tablet={4} $mobile={12} style={{ textAlign: 'end' }}>
          <PrimaryBtn
          className="active"
          iconRightArrow
          style={{ margin: '16px 0' }}
          onClick={() => {
            window.location.href = routes.contactUs;
            return window.location.href;
          }}>
            {t('ContactSupport')}
          </PrimaryBtn>
          <SecondaryBtn className="active" iconRightArrow onClick={() => this.handleDownloadReport()}>
            {t('DownloadReport')}
          </SecondaryBtn>
        </Column>
      </Row>
  );

  render() {
    const {
      rendering: {
        fields: {
          heading,
          description,
          invoiceDownloadWarning,
          referenceInvoiceNumber,
          okay
        }
      },
      accountNumbers,
      invalid,
      pristine
    } = this.props;

    const {
      activeTab,
      data,
      filterOpen,
      downloadOpen,
      modalIsOpen
    } = this.state;
    const historical = activeTab === 3;
    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <ModalBody className="justify-content-center">
            <ModalTitle></ModalTitle>
             <p>{invoiceDownloadWarning.value} <a
             className="link"
             href="mailto: invreql@dayandrossinc.ca">{t('invreql@dayandrossinc.ca')}</a> {referenceInvoiceNumber.value}
           </p>
            <ButtonDiv className="row">
              <div className="col-sm-12">
                  <SecondaryButton
                    onClick={(e) => this.handleModalButtons(e)}
                    className="active"
                    name="okayButton"
                  >
                    {okay.value}
                    <span className="icon" />
                  </SecondaryButton>
              </div>
            </ButtonDiv>
          </ModalBody>
        </Modal>
        {filterOpen && (
          <SideBar close={this.toggleFilter}>
            <StyledForm>
              <PageTitle>{t('ApplyFilters')}</PageTitle>
              <SmallDesc>{t('ToInvoices')}</SmallDesc>
              <CategoryTitle marginBottom="30px">{t('FilterByInvoiceDate')}</CategoryTitle>
              <DateField
                name="filterFromDate"
                type="date"
                component={RenderField}
                className="col"
                label={t('FromDate')}
                validate={[isRequired, dateRangeValidate]}
                onChange={(date) => this.handleFilterFromDate(date)}
              />
              <DateField
                name="filterToDate"
                type="date"
                component={RenderField}
                className="col"
                label={t('ToDate')}
                validate={[isRequired, dateRangeValidate].concat(historical ? isBeforeMay2020 : [])}
              />
              <CategoryTitle>{t('FilterByBillingAccount')}</CategoryTitle>
              <Field
                name="filterAccountNumbers"
                type="checkboxes"
                component={RenderField}
                multiple
                containerMaxHeight="120px"
                data={accountNumbers}
              />
              <ButtonGroup>
                <SecondaryButton
                  type="button"
                  onClick={() => this.resetFilters()}
                >
                  <span className="icon" />
                  {t('ResetFilters')}
                </SecondaryButton>
                <CommonPrimaryButton
                  type="button"
                  onClick={() => this.handleSubmitFilter()}
                  disabled={invalid || pristine}
                >
                  {t('ApplyFilters')}
                </CommonPrimaryButton>
              </ButtonGroup>
            </StyledForm>
          </SideBar>
        )}
        {downloadOpen && <DownloadReportModal
          onClose={this.closeDownloadReport}
          onDownload={this.handleDownloadSubmit}
          title={t('DownloadInvoicesReportTitle')}
          description={t('DownloadInvoicesReportDescription')}
          reportEndpoints={reportEndpoints}
          accountNumbers={accountNumbers}
          reportEndpoint={historical ? reportEndpoints[1] : reportEndpoints[0]}
        />}

        {this.renderHeader({
          heading: heading.value,
          description
        })}
        <FilterContainer>
          <CategoryTabs className="row">
            <Category
              className={`col ${activeTab === 0 && 'active'}`}
              onClick={() => this.changeTab(0)}
            >
              {t('Invoices')}
            </Category>
            <Category
              className={`col ${activeTab === 1 && 'active'}`}
              onClick={() => this.changeTab(1)}
            >
              {t('OutstandingInvoices')}
            </Category>
            <Category
              className={`col ${activeTab === 2 && 'active'}`}
              onClick={() => this.changeTab(2)}
            >
              {t('PaidInvoices')}
            </Category>
            <Category
              className={`col ${activeTab === 3 && 'active'}`}
              onClick={() => this.changeTab(3)}
            >
              {t('HistoricalPaidInvoices')}
            </Category>
          </CategoryTabs>
          <div className="row justify-content-md-center">
            <div className="col-md-9">
              <div className="col">
                <SearchWithFilter
                  label={activeTab === 3 ? t('SearchInvoicesText') : t('SearchInvoicesOrProbillText')}
                  getSuggestions={this.getSuggestions}
                />
                <div className="row justify-content-between">
                  <FilterIcon className="col-md-3" onClick={this.toggleFilter}>
                    <Icon
                      src={filterOpen ? Filter : FilterOrange}
                      alt="Filter"
                    />
                    {t('ApplyFilters')}
                  </FilterIcon>
                </div>
              </div>
            </div>
          </div>
        </FilterContainer>
        {historical && !data ?
          <div style={{ paddingBottom: '100px' }}>
            <Row>
              <Column $tablet={6} $mobile={12} className="mx-auto">
                <Card
                  desc={t('ManageInvoicesUseFilterDesc')}
                  noHover
                />
              </Column>
            </Row>
          </div> :
          <NoDataDecorator component={SortableTable} data={data} columns={historical ? historicalColumns : columns} />}
        <QuestionsBlock link={routes.contactUs} />
      </>
    );
  }
}

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  division: state.profile.division,
  accountNumbers: state.profile.accountNumbers,
  billingAccounts: state.profile.billingAccounts,
  userId: state.profile.userId,
  allRecentInvoices: state.user.invoices,
  allHistoricalInvoices: state.user.historicalInvoices
});

/*
incoming changes
allInvoices: state.user.invoices,
  isAuthenticated: state.profile.isAuthenticated,
*/
const mdtp = (dispatch) => ({
  putReduxAllRecentInvoices: (allRecentInvoices) => dispatch(putReduxAllRecentInvoices(allRecentInvoices)),
  putReduxAllHistoricalInvoices: (allHistoricalInvoices) => dispatch(putReduxAllHistoricalInvoices(allHistoricalInvoices)),
  putReduxInvoiceByNumber: (invoiceNumber) =>
    dispatch(putReduxInvoice(invoiceNumber))
});

ManageInvoices = reduxForm({
  form: formName, // a unique identifier for this form
  initialValues
})(ManageInvoices);

export default connect(
  mstp,
  mdtp
)(ManageInvoices);
