/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Field, change, getFormValues, getFormAsyncErrors
} from 'redux-form';
import { t } from 'i18next';
import { connect } from 'react-redux';
import {
  getCompanyContacts,
  getContactInfo,
  createContact,
  updateContactById,
  getContactPermissionInfo
} from '../../../services/contacts';
import RenderField from '../RenderField';
import AddressComplete from '../AddressComplete';
import Row from '../Row';
import Column from '../Column';
import { phoneNumber, digitOnly } from '../../../utils/mask';
import {
  isRequired, isPhone, isEmail, maxChar50, maxChar25, maxChar6, isFaxNumber
} from '../../../utils/validator';
import { capitalizeFirstLetter, littleCaseFirstLetter } from '../../../utils/func';
import {
  TextProperty,
  LineButton,
  EditButtonGroup,
  Icon,
  SuccessMsg
} from './css';
import Edit from '../../../assets/icons/Edit.svg';
import Plus from '../../../assets/icons/Plus.svg';
import PlusGrey from '../../../assets/icons/PlusGrey.svg';
import { formApiToData, formDataToApi } from '../../../services/contacts/helpers';
import putReduxAllCompanies from '../../ManageCompanies/actions';
import putReduxAllContacts from '../../ManageContacts/actions';
import { getCompaniesList } from '../../../services/companies';
import { AddressTypes, Divisions, DeliveryTypes } from '../../../utils/enums';

const AddressBookEntryBase = (props) => {
  const {
    formName,
    USQuote,
    userAddressType,
    changeFormValue,
    division,
    userId,
    contactList,
    companiesList,
    isAuthenticated,
    formData,
    formAsyncErrors,
    hideNickname,
    defaultContactId,
    isPhoneNumberRequired = true,
    validator,
    dispatch,
    noAddressSuggestion,
    isAddressRequired = false,
    emailOptional,
    companyNameOptional,
    contactNameOptional
  } = props;
  const contactType = props.contactType.toLowerCase();
  const [state, setState] = useState({
    contactSelected: false,
    contactId: '',
    contacts: [],
  });
  let { contactId } = state;
  const { contactSelected } = state;
  const fetchAllContacts = (companies) => {
    if (companies) {
      return Promise.all(companies.map((comp) => getCompanyContacts(comp.companyId))).then((contactRequests) => {
        let allContacts = [];
        contactRequests.forEach((contactRequest) => {
          if (!contactRequest.data || contactRequest.data.length === 0) {
            return;
          }

          allContacts = allContacts.concat(contactRequest.data);
        });
        setState({ ...state, contacts: allContacts });
        return allContacts;
      });
    }
    return undefined;
  };

  const initialCalls = () => {
    getCompaniesList(division, userId).then(
      (cl) => {
        // eslint-disable-next-line no-shadow
        const { putReduxAllCompanies, putReduxAllContacts } = props;
        putReduxAllCompanies(cl.data);
        fetchAllContacts(cl.data).then((res) => {
          const formedContactList = res;
          putReduxAllContacts(formedContactList);
        });
      }
    );
  };
  if (isAuthenticated && !hideNickname) {
    useEffect(() => {
      if (contactType.toLowerCase() === AddressTypes.SHIPPER.toLowerCase()) {
        initialCalls();
      }
    }, []);
    // passing an empty array as second argument triggers the callback in useEffect
    // only after the initial render thus replicating `componentDidMount` lifecycle behaviour
    useEffect(() => {
      if (formData[`${contactType}RowVersion`] && !contactSelected) {
        setState({ ...state, contactSelected: true });
      } else if (contactSelected && !formData[`${contactType}RowVersion`]) {
        setState({ ...state, contactSelected: false });
      }
    });
    useEffect(() => {
      if (contactList && contactList.length > 0 && typeof window !== 'undefined') {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get(contactType)) {
          selectedNickname(urlParams.get(contactType));
        } else if (defaultContactId !== undefined && !formData[`${contactType}Nickname`] && !USQuote) {
          selectedNickname(defaultContactId);
        }
      }
    }, [contactList]);
  }
  // eslint-disable-next-line no-shadow
  const selectedNickname = (contactId, currentContactList) => {
    const selectedContact = contactTypeFilteredList(currentContactList).find((c) => String(c.contactId) === String(contactId));
    if (selectedContact) {
      getContactInfo(selectedContact.contactId).then((res) => {
        const formedData = formApiToData(res.data);
        Object.keys(formedData).forEach((key) => {
          changeFormValue({
            formName,
            fieldName: `${contactType}${capitalizeFirstLetter(key)}`,
            newVal: formedData[key]
          });
        });
        setState({ ...state, contactId: selectedContact.contactId });
      });
    } else {
      clearNickname(contactId);
    }
  };
  const clearNickname = (nickname) => {
    Object.keys(formData).forEach((key) => {
      if (key.startsWith(contactType)) {
        changeFormValue({
          formName,
          fieldName: key,
          newVal: null
        });
      }
    });
    changeFormValue({
      formName,
      fieldName: `${contactType}Nickname`,
      newVal: { name: nickname }
    });
    setState({ ...state, contactSelected: false, contactId: '' });
  };
  const pushContactInfo = () => {
    // eslint-disable-next-line no-shadow
    const { formData, putReduxAllContacts, userId } = props;
    // eslint-disable-next-line no-shadow
    const { contactSelected } = state;
    /*
    purpose:parse formData for all objects with keys starting with contact Type ('shipper'/'consignee')
    returns: true/false map of which keys to operate on
    */
    const contactObjectMap = Object.keys(formData).map((f) => (
      // eslint-disable-next-line no-sequences
      userId,
      division,
      f.startsWith(contactType)
    ));
    // match index of true/false map with object values array
    const contactObjectData = contactObjectMap.map((f, index) => {
      // parse object keys into right case
      const keyName = littleCaseFirstLetter(Object.keys(formData)[index].substring(contactType.length));

      // returns object values for contact type associated values, otherwise returns false
      return f && (
        {
          [keyName]:
            Object.values(formData)[index]
        }
      );
    })
      // parse out false
      .filter((f) => f)
      // rebuild object
      .reduce((curr, accum) => ({ ...curr, ...accum }), {});

    // add companyId and contact Type information
    contactObjectData.companyId = companiesList[0].companyId;
    contactObjectData.contactType = contactType;
    contactObjectData.userId = userId;
    contactObjectData.division = division;

    const formedData = formDataToApi(contactObjectData);
    if (!contactId && contactType === 'shipper') {
      contactId = formData.shipperNickname ? formData.shipperNickname.contactId : '';
    } else if (!contactId && contactType === 'consignee') {
      contactId = formData.consigneeNickname ? formData.consigneeNickname.contactId : '';
    }
    if (contactSelected) {
      getContactPermissionInfo(division, userId).then((res) => {
        if (res.data.message === true) {
          // eslint-disable-next-line no-shadow
          updateContactById(formedData, contactId).then((res) => {
            if (res.status === 204) {
              // eslint-disable-next-line no-shadow
              fetchAllContacts(companiesList).then((res) => {
                const formedContactList = res;
                putReduxAllContacts(formedContactList);
                changeFormValue({
                  formName,
                  fieldName: `${contactType}NicknameSuccess`,
                  newVal: (t('Contact Successfully Updated')),
                });
                setTimeout(() => changeFormValue({
                  formName,
                  fieldName: `${contactType}NicknameSuccess`,
                  newVal: ''
                }), 3000);
                selectedNickname(contactId, formedContactList);
              });
            };
          });
        } else {
          props.openContactPermissionModal();
        };
      });
    } else {
      getContactPermissionInfo(division, userId).then((res) => {
        if (res.data.message === true) {
          // eslint-disable-next-line no-shadow
          createContact(formedData).then((res) => {
            // eslint-disable-next-line no-shadow
            const { contactId } = res.data;
            if (res.status === 201) {
              setState({ ...state, contactSelected: true });
              // eslint-disable-next-line no-shadow
              fetchAllContacts(companiesList).then((res) => {
                const formedContactList = res;
                putReduxAllContacts(formedContactList);
                changeFormValue({
                  formName,
                  fieldName: `${contactType}NicknameSuccess`,
                  newVal: (t('Contact Successfully Added')),
                });
                setTimeout(() => changeFormValue({
                  formName,
                  fieldName: `${contactType}NicknameSuccess`,
                  newVal: ''
                }), 3000);
                selectedNickname(contactId, formedContactList);
              });
            }
          });
        } else {
          props.openContactPermissionModal();
        };
      });
    };
  };

  const contactTypeFilteredList = (currentContactList) => {
    if (contactType === 'billing') {
      return contactList;
    }
    return (currentContactList ? currentContactList : contactList).filter((c) =>
      [contactType.toLowerCase(), AddressTypes.SHARED, AddressTypes.OTHER].includes(c.contactType.toLowerCase())
    );
  };

  const handleAddToList = (newNickname) => {
    changeFormValue({
      formName,
      fieldName: `${contactType}Nickname`,
      newVal: { name: newNickname }
    });
  };
  const isValidSubForm = () => {
    if (
      formData[`${contactType}Nickname`] &&
      ((!USQuote || (USQuote && requiredMatch)) && formData[`${contactType}ContactName`]) &&
      (((!USQuote && formData && !(formData.division === Divisions.Sameday.name && formData.deliveryType.name === DeliveryTypes.Residential))
        || (USQuote && requiredMatch)) && formData[`${contactType}CompanyName`]) &&
      formData[`${contactType}Address`] &&
      (formData[`${contactType}Email`]) && !isEmail(formData[`${contactType}Email`]) &&
      formData[`${contactType}PhoneNumber`] && !isPhone(formData[`${contactType}PhoneNumber`])
    ) {
      return true;
    }
    return false;
  };
  const requiredMatch = contactType === userAddressType;
  const phoneValidators = isPhoneNumberRequired ? [isPhone, maxChar50, isRequired] : [isPhone, maxChar50];
  const addressValidator = () => validator(formData, dispatch, { asyncErrors: formAsyncErrors }, `${contactType}Address`);

  const handleAddressChange = (event, val) => {
    dispatch(change(formName, contactType === 'shipper' ? 'usFromAddress' : 'usToAddress', val.replace(/^\s+|\s+$/g, '')));
    props.isNonServiceableLocation();
  };

  return (
    <>
      {!hideNickname && !USQuote && isAuthenticated &&
        <Field
          name={`${contactType}Nickname`}
          type="combobox"
          component={RenderField}
          data={contactList && contactTypeFilteredList().map((c) => ({ name: c.nickname, id: c.contactId }))}
          label={t('Nickname')}
          onChange={(nickname) => nickname && selectedNickname(nickname.id)}
          onBlur={(event, fieldValue, prevFieldValue) =>
            fieldValue !== undefined &&
            prevFieldValue !== undefined &&
            fieldValue !== prevFieldValue &&
            selectedNickname(fieldValue)}
          onReset={() => clearNickname()}
          handleAddToList={(newNickname) => handleAddToList(newNickname)}
          allowCreate
          textField={(item) => item.name}
        />}
      <Field
        name={`${contactType}ContactName`}
        type="text"
        required={!contactNameOptional && (!USQuote || (USQuote && requiredMatch))}
        component={RenderField}
        label={t('ContactName')}
        validate={[maxChar50].concat(!contactNameOptional && (!USQuote || (USQuote && requiredMatch)) ? isRequired : [])}
      />
      <Field
        name={`${contactType}CompanyName`}
        type="text"
        component={RenderField}
        label={t('CompanyName')}
        validate={[maxChar50].concat(!companyNameOptional && (!USQuote && formData) ? isRequired : [])}
        required={!companyNameOptional && (!USQuote && formData)}
      />
      {noAddressSuggestion ?
        (<Field
          name={`${contactType}Address`}
          type="address"
          component={RenderField}
          label={t('FullAddress')}
          validate={[maxChar50, isRequired]}
          required
        />
        ) : (
          USQuote ?

            <Field
              name={`${contactType}Address`}
              type="text"
              component={RenderField}
              label={t('PostalZipCode')}
              validate={[maxChar50, isRequired]}
              required
              onChange={(event, val) => handleAddressChange(event, val)}
            />
            : <AddressComplete
              fieldName={`${contactType}Address`}
              formName={formName}
              validator={addressValidator}
              required={isAddressRequired}
              shipmentsFlow
              tooltipRequired
            />
        )}
      {!USQuote && <Field
        name={`${contactType}AddressAdditional`}
        type="text"
        component={RenderField}
        validate={[maxChar25]}
        label={t('AddressAdditional')}
      />}
      <Field
        name={`${contactType}Email`}
        type="email"
        component={RenderField}
        label={t('EmailAddress')}
        validate={[isEmail, maxChar50].concat(
          !emailOptional && ((contactType !== AddressTypes.CONSIGNEE && !USQuote) || (!USQuote && formData && formData.sendConsigneeInfo))
            ? isRequired
            : [])}
        required={!emailOptional && ((contactType !== AddressTypes.CONSIGNEE && !USQuote) || (!USQuote && formData && formData.sendConsigneeInfo))}
      />
      {USQuote ? (
        <>
          <Field
            name={`${contactType}PhoneNumber`}
            component={RenderField}
            label={t('Telephone')}
            validate={[isPhone, maxChar50]}
            normalize={phoneNumber}
          />
          <Field
            name={`${contactType}FaxNumber`}
            component={RenderField}
            label={t('Fax')}
            validate={[isFaxNumber, maxChar50]}
            normalize={phoneNumber}
          />
        </>
      ) : (<Row>
        <Column $tablet={8} $mobile={8}>
          <Field
            name={`${contactType}PhoneNumber`}
            type="single"
            component={RenderField}
            label={t('PhoneNumber')}
            validate={phoneValidators}
            normalize={phoneNumber}
            required={isPhoneNumberRequired}
          />
        </Column>
        <Column $tablet={4} $mobile={4}>
          <Field
            name={`${contactType}PhoneNumberExt`}
            type="single"
            component={RenderField}
            label={t('Extension')}
            validate={[maxChar6]}
            normalize={digitOnly}
          />
        </Column>
      </Row>)}
      {!hideNickname && !USQuote && isAuthenticated && <EditButtonGroup>
        {formData[`${contactType}NicknameSuccess`] ?
          <SuccessMsg>{formData[`${contactType}NicknameSuccess`]}</SuccessMsg> :
          <LineButton name={`${contactSelected ? 'editContact' : 'addContact'}`} type="button" onClick={() => pushContactInfo()}>
            <Icon className="icon" src={contactSelected ? Edit : (!isValidSubForm() ? PlusGrey : Plus)} alt={`${contactSelected ? 'edit' : 'add'}`} />
            {contactSelected ? t('UpdateContact') : t('AddressBookAddControl')}
          </LineButton>}
      </EditButtonGroup>}
    </>
  );
};
const mstp = (state) => ({
  formData: getFormValues(Object.keys(state.form)[0])(state),
  formAsyncErrors: getFormAsyncErrors(Object.keys(state.form)[0])(state),
  isAuthenticated: state.profile.isAuthenticated,
  division: state.profile.division,
  userId: state.profile.userId,
  companiesList: state.user.allCompanies,
  contactList: state.user.allContacts,
});
const mdtp = (dispatch) => ({
  putReduxAllContacts: (allContacts) => dispatch(putReduxAllContacts(allContacts)),
  putReduxAllCompanies: (allCompanies) => dispatch(putReduxAllCompanies(allCompanies)),
  changeFormValue: (load) => {
    const { formName, fieldName, newVal } = load;
    dispatch(change(formName, fieldName, newVal));
  },
  dispatch
});
export const AddressBookEntry = connect(mstp, mdtp)(AddressBookEntryBase);

// eslint-disable-next-line react/no-multi-comp
export const AddressBookText = ({ formData = {}, contactType }) => (
    <>
      {
        formData[`${contactType}ContactName`] &&
        <TextProperty>
          <div className="title">
            {t('ContactName')}
          </div>
          {formData[`${contactType}ContactName`]}
        </TextProperty>
      }
      {
        formData[`${contactType}CompanyName`] &&
        <TextProperty>
          <div className="title">
            {t('CompanyName')}
          </div>
          {formData[`${contactType}CompanyName`]}
        </TextProperty>
      }
      {
        formData[`${contactType}Address`] &&
        <TextProperty>
          <div className="title">
            {t('Address')}
          </div>
          {formData[`${contactType}Address`]}
          {formData[`${contactType}AddressAdditional`] && ','}
          {formData[`${contactType}AddressAdditional`]}
        </TextProperty>
      }

      {
        formData[`${contactType}Email`] &&
        <TextProperty>
          <div className="title">
            {t('Email')}
          </div>
          {formData[`${contactType}Email`]}
        </TextProperty>

      }

      {
        formData[`${contactType}PhoneNumber`] &&
        <TextProperty>
          <div className="title">
            {t('PhoneNumber')}
          </div>
          {formData[`${contactType}PhoneNumber`]}
          {' '}
          {formData[`${contactType}PhoneNumberExt`]}
        </TextProperty>
      }
    </>
);
