import { isNullOrUndefined } from './object-utils';

export const isNumeric = (value) => (value - parseFloat(value) + 1) >= 0;

export const isInt = (value) => isNumeric(value) && !value.toString().includes('.');

export const isIntInRange = (value, min, max) => {
  if (!isInt(value)) {
    return false;
  }

  if (parseInt(value, 10) < min || parseInt(value, 10) > max) {
    return false;
  }

  return true;
};

export const padNumberWithZeros = (number, totalDigits) => (isNullOrUndefined(number)
  ? null
  : (parseInt(number, 10)).toLocaleString('en-US', { minimumIntegerDigits: totalDigits, useGrouping: false }));
