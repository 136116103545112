import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import {
  Button, ButtonsContainer, LearnMoreButton, Popup, Text,
} from './css';
import COOKIE_ACKNOWLEDGEMENT_STORAGE_KEY from './constants';
import { Locales } from '../../../utils/enums';

const CookiePopup = () => {
  useEffect(() => {
    const hasBeenAcknowledged = typeof window !== 'undefined' ? window.localStorage.getItem(COOKIE_ACKNOWLEDGEMENT_STORAGE_KEY) : false;
    setIsOpen(!hasBeenAcknowledged);
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleLearnMore = () => {
    // let locale = getCurrentLocale();
    // if (locale.toLowerCase() === Locales.English.toLowerCase()) {
    //   locale = ""; // Since page "PRIVACY_POLICY_URL/en" does not exist...
    // }
    // window.open(PRIVACY_POLICY_URL + locale);
    const locale = window.location.pathname.toLowerCase().includes(`/${Locales.French.toLowerCase()}/`)
      ? 'https://dayross.com/fr-CA/privacy-policy' : 'https://dayross.com/en/privacy-policy';
    window.open(locale, '_blank');
  };

  const handleClick = () => {
    if (isOpen) {
      window.localStorage.setItem(COOKIE_ACKNOWLEDGEMENT_STORAGE_KEY, true);
      setIsOpen(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Popup>
      {/* TODO Define text value in "global" sitecore datasource */}
      <Text field={t('CookiePopup')}/>
      <ButtonsContainer>
        <LearnMoreButton onClick={handleLearnMore}>
          {t('LearnMore')}
        </LearnMoreButton>
        <Button onClick={handleClick}>
          {t('Okay')}
        </Button>
      </ButtonsContainer>
    </Popup>
  );
};

export default CookiePopup;
