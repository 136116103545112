const mockDataNewsCarousel = {
  data: {
    contextItem: {
      path: '/sitecore/content/internet/home/static-page3',
      id: '15F3CD38755C525F916C8C4DFE010EE8'
    },
    datasourceItem: {
      // eslint-disable-next-line max-len
      path: '/sitecore/content/internet/home/static-page3/Page Components/static-page3-jss-staticpage-sections-BCF9D0F7-C09A-5113-8959-E8CE2B49CC98-0-NewsCarousel-6',
      id: '067B43655BBC51AB8CDAE11006E31F88',
      heading: { value: 'News' },
      anchor: { value: 'news' },
    },
    search: {
      results: {
        items: [{
          item: {
            id: '15F3CD38755C525F916C8C4DFE010EE8',
            name: 'static-page3',
            path: '/sitecore/content/internet/home/static-page3',
            url: '/static-page3',
            pageTitle: { value: 'Static Page Sample 3' },
            heading: { value: 'Static Page Heading' },
            // eslint-disable-next-line max-len
            description: { value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tincidunt venenatis ullamcorper. Sed imperdiet congue risus, ut aliquam enim vehicula vel. Quisque aliquam bibendum lacus, sed dictum diam fringilla ultrices. In id dui orci. Nam rhoncus leo in tincidunt pellentesque. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean est mauris, placerat at tortor ac, accumsan venenatis ex. Nam pulvinar libero ut blandit tristique. Aliquam dictum lobortis lacinia. Duis finibus congue ligula in vehicula. Curabitur ac fermentum mi. Suspendisse in massa non massa interdum tincidunt nec id felis. Suspendisse dictum urna sed sagittis blandit.\n' },
            heroImage: {
              jss: {
                value: {
                  // eslint-disable-next-line max-len
                  src: 'http://internet.dev.local/-/media/internet/data/media/img/staticpage/staticpage-sample.ashx?h=334&w=1450&hash=0B3CD3B7CD74EB76DBEFEBDE7E9DA175',
                  alt: 'hero',
                  width: '1450',
                  height: '334',
                },
              },
            },
          },
        }],
      },
    },
  },
};
export default mockDataNewsCarousel;
