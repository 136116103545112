import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import ArrowDown from '../../assets/icons/ArrowDown.svg';

export const NavigationContainer = styled.header`
    justify-content: flex-end;
    background-color: ${colors.WHITE};
    margin-top: 2px;
`;

export const NavigationInner = styled.nav`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
    width: 100%;
    max-width: 1204px;
    margin: 0 auto;
`;

export const NavigationMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const MenuContainer = styled.div`
    ${({ $isDisplayed }) => ($isDisplayed ? 'display: block;' : 'display: none;')}
        // Show MenuContainer by default, if desktop view...
    ${MediaQuery.small`
       ${({ $isDisplayed }) => ($isDisplayed ? 'display: none;' : 'display: block;')}     
     `}
    .nav-menu {
        display: flex;
        width: 100%;
        max-width: 1204px;
        margin: auto;
        list-style: none;
        padding: 0;
        flex-direction: column;

        &.open {
            background-color: ${colors.WHITE};

            ${MediaQuery.small`
              height:310px;
            `}
        }

        ${MediaQuery.small`
            flex-direction: row;
        `}
        .nav-link {
            color: ${colors.BLACK};
            line-height: 2rem;
            display: inline-block;
                /* &:focus{
                border:1px solid ${colors.BRAND_ORANGE};
            }  */
        }

        > .nav-item {
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.6rem;
            padding: 1rem 1.5rem;

            > .nav-link {

                border-bottom: 2px solid transparent;

                &.active {
                    border-bottom-color: ${colors.BRAND_ORANGE};
                }

                &:hover {
                    border-bottom-color: ${colors.SKY_GREY};
                }
            }

            ${MediaQuery.small`
                padding: 2rem 2rem;

                &:first-of-type{
                    padding-left:0;
                }
            `}
            .icon {
                position: relative;
                right: 0;
                top: 2px;
                margin: 0;
                height: 1.4rem;
                width: 24px;
                background-size: cover;
                display: inline-block;
                background: url(${ArrowDown}) center;
            }

            .nav-menu-l2 {
                display: none;
                list-style: none;
                flex-direction: column;
                flex-wrap: wrap;
                padding: 0;
                margin-top: 10px;
                max-width: 320px;
                width: 100%;
                border: 0 solid red;

                ${MediaQuery.small`      
                  position: absolute;
                  height: 260px;
                `}
                .nav-item {
                    padding: 0 20px 0 0;
                    width: 49%;

                    .nav-link {
                        border-left: 2px solid transparent;

                        &.active {
                            border-left-color: ${colors.BRAND_ORANGE};
                        }

                        &:hover {
                            border-left-color: ${colors.SKY_GREY};
                        }
                    }
                }
            }

            .nav-menu-l3 {
                margin-left: 20px;
                list-style: none;
                padding: 0;
            }

            &.open {
                .nav-menu-l2 {
                    display: flex;
                }

                .icon {
                    transform: rotateX(180deg);
                }
            }
        }
    }
`;
