import { PUT_REDUX_ALL_RECENT_INVOICES, PUT_REDUX_ALL_HISTORICAL_INVOICES, PUT_REDUX_INVOICE } from '../../reducers/constants';

export const putReduxAllRecentInvoices = (allRecentInvoices) => ({
  type: PUT_REDUX_ALL_RECENT_INVOICES,
  allRecentInvoices,
});

export const putReduxAllHistoricalInvoices = (allHistoricalInvoices) => ({
  type: PUT_REDUX_ALL_HISTORICAL_INVOICES,
  allHistoricalInvoices,
});

export const putReduxInvoice = (invoice) => ({
  type: PUT_REDUX_INVOICE,
  invoice,
});
