import React from 'react';
import { Text, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import { H2 } from '../../theme/typography';

import {
  TileGroup,
  ButtonGroup,
  Icon,
} from './css';

const Tile = (props) => {
  const {
    fields,
    toggleInstructions,
    id
  } = props;

  const primaryLink = fields['primary link'];
  const hasPrimary = primaryLink && primaryLink.value && primaryLink.value.href;

  const secondaryLink = fields['secondary link'];
  const hasSecondary = secondaryLink && secondaryLink.value && secondaryLink.value.href;

  const readMoreLink = fields['read more link'];
  const hasReadMore = readMoreLink && readMoreLink.value && readMoreLink.value.text;

  const onClick = () => toggleInstructions(id);

  return (
    <TileGroup>
      <Icon className="icon rectangle"></Icon>
      <H2><Text field={fields.heading}/></H2>
      <RichText tag="p" field={fields.description}/>
      <ButtonGroup>
        {hasSecondary
          && <Link field={secondaryLink} className="secondaryButton">
            <span className="icon"></span>
            {secondaryLink.value.text}
          </Link>}
        {hasPrimary
          && <Link field={primaryLink} className="primaryButton submit">
            <span className="icon"></span>
            {primaryLink.value.text}
          </Link>}
      </ButtonGroup>
      {hasReadMore
        && <ButtonGroup>
          {/* eslint-disable-next-line */}
          <a href="javascript:void(0)" className="secondaryButton no-border" onClick={onClick}>
            <span className="icon"></span>
            {readMoreLink.value.text}
          </a>
        </ButtonGroup>}
    </TileGroup>
  );
};

export default Tile;
