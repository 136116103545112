import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import {
  reduxForm,
  Field,
  getFormValues,
  change
} from 'redux-form';
import RenderField from '../RenderField';
import { isRequired, dateRangeValidate } from '../../../utils/validator';
import { formatDate } from '../../../utils/func';
import AlertModal from '../Modal/AlertModal';
import {
  PrimaryButton,
  SecondaryButton,
  ButtonGroup,
  CategoryTitle,
  PageTitle,
  SmallDesc,
  DownloadModalBody,
  DateFields
} from './css';
import { isBeforeMay2020 } from '../../ManageInvoices';

const initialValues = {
  filterFromDate: formatDate(new Date((new Date()).getTime() - (60 * 24 * 60 * 60 * 1000))), // from date is 60 days from today
  filterToDate: formatDate(new Date()),
}; // to date is today
class DownloadReportModal extends React.Component {
  constructor(props) {
    super(props);
    props.dispatch(change('DownloadReportModal', 'filterAccountNumbers', props.accountNumbers));
    props.dispatch(change('DownloadReportModal', 'reportEndpoint', props.reportEndpoint));
  }

  handleDownloadSubmit = (e) => {
    e.preventDefault();
    const { valid } = this.props;
    if (!valid) {
      return;
    }

    const {
      formData: {
        filterFromDate,
        filterToDate,
        filterAccountNumbers,
        reportEndpoint
      },
      onDownload
    } = this.props;

    const accountNumbers = filterAccountNumbers && filterAccountNumbers.filter((n) => n !== '');
    if (!accountNumbers || accountNumbers.length === 0) {
      return;
    }

    onDownload({
      filterFromDate,
      filterToDate,
      filterAccountNumbers: accountNumbers,
      reportEndpoint
    });
  };

  content = () => {
    const {
      accountNumbers,
      reportEndpoints,
      formData: { reportEndpoint }
    } = this.props;
    return <DownloadModalBody>
      <form>
        {reportEndpoints && <>
          <SmallDesc className="text-center">{t('ReportEndpointsDescription')}</SmallDesc>
          <Field
            name="reportEndpoint"
            type="checkboxes"
            data={reportEndpoints}
            component={RenderField}
            marginBottom="16px"
          />
        </>}
        <CategoryTitle>{t('DownloadShipmentReportDateRange')}</CategoryTitle>
        <DateFields>
          <Field
            name="filterFromDate"
            type="date"
            component={RenderField}
            className="col"
            label={t('FromDate')}
            validate={[isRequired, dateRangeValidate]}
          />
          <Field
            name="filterToDate"
            type="date"
            component={RenderField}
            className="col"
            label={t('ToDate')}
            validate={[isRequired, dateRangeValidate].concat(reportEndpoint === 'HistoricalReport' ? isBeforeMay2020 : [])}
          />
        </DateFields>
        <CategoryTitle>{t('FilterByBillingAccount')}</CategoryTitle>
        <Field
          name="filterAccountNumbers"
          type="checkboxes"
          component={RenderField}
          multiple
          marginBottom="30px"
          data={accountNumbers}
        />
      </form>
    </DownloadModalBody>;
  };

  render() {
    const {
      onClose,
      title,
      description
    } = this.props;
    const header = () => (
      <>
        <PageTitle className="text-center">{title}</PageTitle>
        <SmallDesc className="text-center">{description}</SmallDesc>
      </>
    );

    const actions = () => (
      <ButtonGroup>
        <SecondaryButton type="button" onClick={onClose}>
          <span className="icon"></span>
          {t('Cancel')}
        </SecondaryButton>
        <PrimaryButton className="submit" onClick={(e) => this.handleDownloadSubmit(e)}>
          <span className="icon"></span>
          {t('Download')}
        </PrimaryButton>
      </ButtonGroup>
    );

    return <AlertModal
      isOpen
      shrinkwrapContent
      onClose={onClose}
      header={header}
      content={this.content}
      actions={actions}
    />;
  }
}

const mstp = (state) => ({
  formData: getFormValues('DownloadReportModal')(state),
});

DownloadReportModal.propTypes = {
  dispatch: PropTypes.func,
  accountNumbers: PropTypes.array,
  reportEndpoint: PropTypes.string,
  valid: PropTypes.bool,
  formData: PropTypes.shape({
    filterFromDate: PropTypes.string,
    filterToDate: PropTypes.string,
    filterAccountNumbers: PropTypes.array,
    reportEndpoint: PropTypes.string,
  }),
  onDownload: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  reportEndpoints: PropTypes.array
};

DownloadReportModal = reduxForm({
  form: 'DownloadReportModal', // a unique identifier for this form
  initialValues,
})(DownloadReportModal);

export default connect(mstp)(DownloadReportModal);
