import { t } from 'i18next';
import { Divisions } from '../../utils/enums';

export const strategies = {
  shipment: 'ShipmentNumber',
  reference: 'ReferenceNumber',
  order: 'OrderNumber',
};

export const formName = 'TrackShipmentForm';

export const initialValues = {
  division: { name: t('SelectaService'), value: Divisions.SelectaService.name },
  shipmentNumbers: '',
  referenceNumber: '',
  orderNumbers: '',
};

export const validnumberregex = /[\n,]/gi;
export const columns = {
  shipment: [
    {
      id: 1,
      label: 'Action',
      field: 'actions',
      sort: false,
    },
    {
      id: 2,
      label: 'BillOfPayment',
      field: 'probillNumber',
      sort: true,
    },
    {
      id: 3,
      label: 'ReferenceNumber',
      field: 'referenceNumber',
      sort: true,
    },
    {
      id: 4,
      label: 'DeliveryDate',
      field: 'deliveryDate',
      sort: true,
    },
    {
      id: 5,
      label: 'Status',
      field: 'status',
      sort: true,
    },
    {
      id: 6,
      label: 'Expand',
      field: 'expand',
      sort: false,
    },
  ],

  reference: [
    {
      id: 1,
      field: 'actions',
      sort: false,
    },
    {
      id: 2,
      label: 'BillOfPayment',
      field: 'probillNumber',
      sort: true,
    },
    {
      id: 3,
      label: 'ReferenceNumber',
      field: 'referenceNumber',
      sort: true,
    },
    {
      id: 4,
      label: 'DeliveryDate',
      field: 'deliveryDate',
      sort: true,
    },
    {
      id: 5,
      label: 'Status',
      field: 'status',
      sort: true,
    },
  ],
  order: [
    {
      id: 1,
      field: 'actions',
      sort: false,
    },
    {
      id: 2,
      label: 'OrderNumber',
      field: 'orderNumber',
      sort: true,
    },
    {
      id: 3,
      label: 'PickupDate',
      field: 'pickupDate',
      sort: true,
    },
    {
      id: 4,
      label: 'lblPickupAddress',
      field: 'pickupAddress',
      sort: true,
    },
    {
      id: 5,
      label: 'Status',
      field: 'status',
      sort: true,
    },
  ]
};
