import styled from 'styled-components';

export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;

export const FormContainer = styled.div`
    width: 100%;
    padding: 72px 0;
    margin: 0 auto;
`;
export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 72px;
    margin-top: 50px;
`;
export const PageTitle = styled.div`
    font-weight: 300;
    font-size: 4.8rem;
    margin-bottom: 32px;

    &.justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;
export const TextProperty = styled.div`
    font-size: 1.4rem;
    margin-bottom: 82px;
    font-weight: 500;

    .title {
        margin-bottom: 10px;
    }

    .text {
        font-weight: 800;
        font-size: 1.6rem;
    }

    .justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    hr {
        border-color: #000;
        margin: 1.5rem 0 3rem;
    }
`;
