import React, { useState, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Field, FieldArray, change } from 'redux-form';
import { t } from 'i18next';
import Delete from '../../assets/icons/RemoveOrange.svg';
import RenderField from '../ReactComponent/RenderField';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import Plus from '../../assets/icons/Plus.svg';
import {
  isRequired,
  maxChar7,
  maxChar50,
  isValidCanadaUSPostalCode
} from '../../utils/validator';
import { Divisions } from '../../utils/enums';
import { arrayLengthSafe } from '../../utils/arrays';

import {
  PageTitle,
  ContentBoxLightGrey,
  MeasurementTab,
  FileUploadLabel,
  FileUploadContainer,
  FileUpload,
  ItemContainer,
  LineButton,
  Icon,
} from './css';
import { P, H2, H3 } from '../../theme/typography';
import { getServiceLocation } from './api';
import { capitalize } from '../../utils/func';

const renderMeasurementList = ({
  fields,
  dispatch,
  division,
  currentTransitTimeList
}) => {
  const [state, setState] = useState({
    added: true,
    measurementList: []
  });

  const { measurementList } = state;

  const handleLocationBlur = (event, index) => {
    if (event.target.value && !isValidCanadaUSPostalCode(event.target.value)) {
      getServiceLocation(division, event.target.value).then((locationInformation) => {
        if (arrayLengthSafe(locationInformation)) {
          const locationItems = locationInformation.filter((c) => c.cityName).map((eachLoc) => ({
            cityName: eachLoc.cityName,
            countryCode: eachLoc.countryCode,
            provinceCode: eachLoc.provinceCode
          }));
          const { measurementList: measurementListLocal } = state;
          measurementListLocal[index] = {
            cityOptions: locationItems
          };
          setState({
            ...state,
            measurementList
          });
        }
        dispatch(change('TransitTimeCalculatorForm', `measurementList[${index}].itemDestinationCity`, ''));
        dispatch(change('TransitTimeCalculatorForm', `measurementList[${index}].itemDestinationState`, ''));
        dispatch(change('TransitTimeCalculatorForm', `measurementList[${index}].itemDestinationCountryCode`, ''));
        setItemDestinationCity(index);
      });
    }
  };

  const setItemDestinationCity = (index) => {
    const { measurementList: measurementListLocal } = state;

    if (arrayLengthSafe(measurementListLocal[index].cityOptions) === 1) {
      const cityCodeValue = measurementListLocal[index].cityOptions && measurementListLocal[index].cityOptions[0].cityName;
      const provinceCodeValue = measurementListLocal[index].cityOptions && measurementListLocal[index].cityOptions[0].provinceCode;
      const countryCodeValue = measurementListLocal[index].cityOptions && measurementListLocal[index].cityOptions[0].countryCode;
      dispatch(change('TransitTimeCalculatorForm', `measurementList[${index}].itemDestinationCity`, cityCodeValue));
      dispatch(change('TransitTimeCalculatorForm', `measurementList[${index}].itemDestinationState`, provinceCodeValue));
      dispatch(change('TransitTimeCalculatorForm', `measurementList[${index}].itemDestinationCountryCode`, countryCodeValue));
    }
  };

  const handleCityChange = (event, index) => {
    const { measurementList: measurementListLocal } = state;
    if (event && measurementListLocal[index]) {
      const provinceCodeValue = measurementListLocal[index].cityOptions
        && measurementListLocal[index].cityOptions.filter((m) => m.cityName === event)[0].provinceCode;
      const countryCodeValue = measurementListLocal[index].cityOptions
        && measurementListLocal[index].cityOptions.filter((m) => m.cityName === event)[0].countryCode;
      dispatch(change('TransitTimeCalculatorForm', `measurementList[${index}].itemDestinationState`, provinceCodeValue));
      dispatch(change('TransitTimeCalculatorForm', `measurementList[${index}].itemDestinationCountryCode`, countryCodeValue));
    }
  };

  // const provinceCodeValue = (index) =>  this.state.measurementList[index].cityOptions
  // && this.state.measurementList[index].cityOptions.filter((m) =>
  // m.cityName === formData.measurementList[index].itemDestinationCountryCode)[0].provinceCode;

  const deleteTransitTimeList = (currentMeasurementList, index) => {
    if (currentMeasurementList.length > 1) {
      fields.splice(index, 1);
    }
  };

  const setAddedTrue = () => setState({
    ...state,
    added: true
  });

  return (
    <>
      {fields.map((item, index) =>
        (
          <ContentBoxLightGrey className="edit-item-box" key={index}>
            <Row>
              <Column $desktop={10}>
                <H3>
                  {t('Location')}
                  {index ? ` ${Number(index + 1)}` : ` ${index + 1}`}
                </H3>
              </Column>
              {currentTransitTimeList && currentTransitTimeList.length > 1 && <Column $desktop={2}>
                <LineButton
                  name="deleteTransitTime"
                  type="button"
                  onClick={() => deleteTransitTimeList(fields, index)}>
                  <Icon className="icon" src={Delete} alt="delete"/>
                  {t('Delete')}
                </LineButton>
              </Column>}
            </Row>
            <Row>
              <Column $tablet={12} $mobile={12}>
                <Row>
                  <Column $tablet={6} $mobile={12}>
                    <Field
                      name={`${item}.itemDestinationPostal`}
                      component={RenderField}
                      label={t('PostalZipCode')}
                      onChange={(event) => handleLocationBlur(event, index)}
                      validate={[isRequired, maxChar7, isValidCanadaUSPostalCode]}
                      required
                      normalize={capitalize}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column $tablet={6} $mobile={12}>
                    <Field
                      name={`${item}.itemDestinationCity`}
                      type="dropdown"
                      label={t('City')}
                      data={(measurementList[index]
                        && currentTransitTimeList[index].itemDestinationPostal
                        && measurementList[index].cityOptions.length > 0)
                        ? measurementList[index].cityOptions.map((c) => c.cityName) : ['']}
                      component={RenderField}
                      validate={[isRequired]}
                      required
                      onChange={(event) => handleCityChange(event, index)}
                    />
                  </Column>
                  <Column $tablet={6} $mobile={12}>
                    <Field
                      name={`${item}.itemDestinationState`}
                      component={RenderField}
                      label={t('ProvinceState')}
                      disabled
                      required
                      validate={[isRequired, maxChar50]}
                    />
                  </Column>
                </Row>
              </Column>
            </Row>

          </ContentBoxLightGrey>
        )
      )}
      <FileUploadContainer className="with-dot">
        <FileUpload onClick={() => {
          fields.push({});
          setAddedTrue();
        }}>
          <img src={Plus} alt="add more part file"/>
          <FileUploadLabel>{t('AddAnotherDestination')}</FileUploadLabel>
        </FileUpload>
      </FileUploadContainer>
    </>
  );
};

class TransitTimeCalculatorImpl extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      originCityOptions: [],
      divisionName: null
    };
  }

  handlePostalBlur(event, dispatch) {
    const { formData } = this.props;

    if (event.target.value) {
      getServiceLocation(formData.division, event.target.value)
        .then((locationInformation) => {
          if (arrayLengthSafe(locationInformation)) {
            const locationItems = locationInformation.filter((c) => c.cityName).map((eachLoc) => ({
              cityName: eachLoc.cityName,
              countryCode: eachLoc.countryCode,
              provinceCode: eachLoc.provinceCode
            }));

            this.setState({
              originCityOptions: locationItems,
            });
            this.clearOriginCityValues(dispatch);
            this.setOriginCityVal(dispatch);
          }
        });
    }
  }

  setOriginCityVal(dispatch) {
    const { originCityOptions } = this.state;

    if (arrayLengthSafe(originCityOptions) === 1) {
      const cityCodeValue = originCityOptions[0].cityName;
      const provinceCodeValue = originCityOptions[0].provinceCode;
      const countryCodeValue = originCityOptions[0].countryCode;
      dispatch(change('TransitTimeCalculatorForm', 'originCity', cityCodeValue));
      dispatch(change('TransitTimeCalculatorForm', 'originProvince', provinceCodeValue));
      dispatch(change('TransitTimeCalculatorForm', 'originCountryCode', countryCodeValue));
    }
  }

  clearOriginCityValues(dispatch) {
    dispatch(change('TransitTimeCalculatorForm', 'originCity', ''));
    dispatch(change('TransitTimeCalculatorForm', 'originProvince', ''));
    dispatch(change('TransitTimeCalculatorForm', 'originCountryCode', ''));
  }

  handleDivisonChange(event, dispatch, clear) {
    let divisionddlValue;

    switch (event) { // To get around passing an object array in RenderField.data, :( , since RenderField has a bug in this case...
      case t('LtlTruckload'):
        divisionddlValue = Divisions.Freight.name;
        break;
      case t('CommerceSolutions'):
        divisionddlValue = Divisions.Sameday.name;
        break;
      // eslint-disable-next-line default-case
      default:
    }
    this.setState({
      divisionName: divisionddlValue
    });
    dispatch(change('TransitTimeCalculatorForm', 'division', divisionddlValue));
    clear();
  }

  handleCityChange(event, dispatch) {
    const { originCityOptions } = this.state;

    if (event && originCityOptions) {
      const provinceCodeValue = originCityOptions
        && originCityOptions.filter((m) => m.cityName === event)[0].provinceCode;
      const countryCodeValue = originCityOptions
        && originCityOptions.filter((m) => m.cityName === event)[0].countryCode;
      dispatch(change('TransitTimeCalculatorForm', 'originProvince', provinceCodeValue));
      dispatch(change('TransitTimeCalculatorForm', 'originCountryCode', countryCodeValue));
    }
  }

  render() {
    const {
      originCityOptions,
      divisionName
    } = this.state;
    const {
      formData = {},
      dispatch,
      fields,
      isAuthenticated,
      division,
      clear
    } = this.props;
    const {
      measurementList,
      originPostalCode
    } = formData;
    const disabled = (measurementList && measurementList.filter((m) => m.completed).length > 0);

    return (
      <>
        <PageTitle className="justified">
          {fields.heading && <Text field={fields.heading}/>}
        </PageTitle>
        <Row>
          <Column $desktop={10}>
            {fields.description && <P><RichText tag="P" field={fields.description}/></P>}
          </Column>
        </Row>
        {!isAuthenticated &&
          <Row>
            <Column>
              <Field
                name="divisonName"
                type="dropdown"
                component={RenderField}
                label={t('SelectService')}
                data={[
                  t('LtlTruckload'), // Freight
                  t('CommerceSolutions') // Sameday
                ]}
                required
                textField="text"
                valueField="value"
                validate={[isRequired]}
                onChange={(event) => this.handleDivisonChange(event, dispatch, clear)}
              />
            </Column>
          </Row>}
        <Row>
          <Column $desktop={10}>
            <H2>{fields.originHeading && <Text field={fields.originHeading}/>}</H2>
          </Column>
        </Row>
        <Row>
          <Column $mobile={12} $tablet={6} $desktop={6}>
            <MeasurementTab>
              <Field
                name="originPostalCode"
                type="text"
                label={t('PostalZipCode')}
                onBlur={(event) => this.handlePostalBlur(event, dispatch)}
                component={RenderField}
                required
                validate={[isRequired, maxChar7, isValidCanadaUSPostalCode]}
                className={disabled ? 'disabled-dropdown' : ''}
                normalize={capitalize}
              />
            </MeasurementTab>
          </Column>
          <Column $mobile={12} $tablet={6} $desktop={6}>
            <MeasurementTab>
              <Field
                name="pickUpDate"
                type="date"
                label={t('PickupDate')}
                component={RenderField}
                className="alignDate"
              />
            </MeasurementTab>
          </Column>
        </Row>
        <Row>
          <Column $mobile={12} $tablet={6} $desktop={6}>
            <MeasurementTab>
              <Field
                name="originCity"
                label={t('City')}
                type="dropdown"
                data={(originPostalCode && originCityOptions.length > 0) ? originCityOptions.map((c) => c.cityName) : ['']}
                component={RenderField}
                required
                validate={[isRequired]}
                onChange={(event) => this.handleCityChange(event, dispatch)}
              />
            </MeasurementTab>
          </Column>
          <Column $mobile={12} $tablet={6} $desktop={6}>
            <MeasurementTab>
              <Field
                name="originProvince"
                type="Text"
                label={t('ProvinceState')}
                component={RenderField}
                disabled
              />
            </MeasurementTab>
          </Column>
        </Row>
        <Row>
          <Column $desktop={12}>
            <H2>{fields.destinationHeading && <Text field={fields.destinationHeading}/>}</H2>
          </Column>
        </Row>
        <ItemContainer>
          <FieldArray
            name="measurementList"
            dispatch={dispatch}
            component={renderMeasurementList}
            division={isAuthenticated ? division : divisionName}
            currentTransitTimeList={measurementList}
          />
        </ItemContainer>
      </>
    );
  }
}

TransitTimeCalculatorImpl.propTypes = {
  formData: PropTypes.shape({
    division: PropTypes.string
  }),
  dispatch: PropTypes.func,
  fields: PropTypes.shape({
    heading: PropTypes.object,
    description: PropTypes.object,
    originHeading: PropTypes.object,
    destinationHeading: PropTypes.object,
  }),
  isAuthenticated: PropTypes.bool,
  division: PropTypes.string,
  clear: PropTypes.func,
};

export default TransitTimeCalculatorImpl;
