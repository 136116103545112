import React from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { MDBDropdown } from 'mdbreact';
import {
  getAllCompaniesList,
  getSearchedCompaniesList
} from '../../services/companies';
import { routes } from '../../utils/constants';
import SortableTable from '../ReactComponent/SortableTable';
import { H2 } from '../../theme/typography';
import Actions from '../../assets/icons/Actions.svg';
import ViewWhite from '../../assets/icons/ViewWhite.svg';
import EditWhite from '../../assets/icons/EditWhite.svg';
import CheckmarkCircledOrange from '../../assets/icons/CheckmarkCircledOrange.svg';
import CloseGray from '../../assets/icons/CloseGray.svg';
import { Icon as CommonIcon } from '../ReactComponent/Icon';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import {
  PrimaryButton,
  ButtonGroup,
  TitleGroup,
  FilterContainer
} from './css';
import putReduxAllCompanies from './actions';
import { homeUrlNoTrailingSlash, runningOnBrowser } from '../../utils/func';
import NoDataDecorator from '../ReactComponent/SortableTable/NoDataDecorator';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';
import CustomizeTableView from '../ReactComponent/CustomizeTableView';
import RenderField from '../ReactComponent/RenderField';
import { anyOneRequiredInGroup } from '../../utils/validator';

const formName = 'ManageCompanies';

const Dropdown = ({ companyId, userEmail }) => (
  <MDBDropdown className="dropdown" >
    <MDBDropdownToggle className="border-0">
      <CommonIcon iconSrc={Actions} />
    </MDBDropdownToggle>
    <StyledMDBDropdownMenu className="text-white bg-dark" basic>
      <DropdownItem onClick={() => {
        window.location.href = (`/create-company?companyId=${companyId}`);
        return window.location.href;
      }}>
        <DropdownItemIcon iconSrc={ViewWhite} />
        {t('ViewEditCompany')}
      </DropdownItem>
      <DropdownItem onClick={() => {
        window.location.href = (`create-user?userEmail=${userEmail}`);
        return window.location.href;
      }}>
        <DropdownItemIcon iconSrc={EditWhite} />
        {t('EditUser')}
      </DropdownItem>
    </StyledMDBDropdownMenu>
  </MDBDropdown>
);

const initialValues = {};

// eslint-disable-next-line react/no-multi-comp
class ManageCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataPerPage: 10,
      totalPages: [],
      displayCustomizeTableView: false,
      customizeOpen: false,
      addCompanyFlag: false,
      columns: [
        {
          id: 1,
          label: '',
          field: 'actions',
          sort: false,
          selected: true,
          default: true,
        },
        {
          id: 2,
          label: t('CompanyName'),
          field: 'companyName',
          sort: true,
          selected: true,
          default: true,
          disabled: true
        },
        {
          id: 3,
          label: t('Description'),
          field: 'description',
          sort: true,
          selected: true,
          default: true
        },
        {
          id: 4,
          label: t('Admin'),
          field: 'isAdmin',
          sort: true,
          selected: true,
          default: true
        },
        {
          id: 5,
          label: t('UserEmailId'),
          field: 'userEmail',
          sort: true,
          selected: true,
          default: true
        },
        {
          id: 6,
          label: t('Status'),
          field: 'status',
          sort: true,
          selected: true,
          default: true
        },
        {
          id: 7,
          label: t('AccountNumbers'),
          field: 'accountNumber',
          sort: true,
          selected: false,
          default: false
        }
      ],
    };
  }

  fetchAllCompanies = () => {
    const { division } = this.props;
    getAllCompaniesList(division).then((res) => {
      if (res.status === 200) {
        // eslint-disable-next-line no-shadow
        const { putReduxAllCompanies } = this.props;
        const convertedData = this.convertDataFormat(res.data);
        putReduxAllCompanies(convertedData);
        this.setState({ data: convertedData }, () => this.getTotalPages());
      }
    });
  };

  convertDataFormat = (data) => data.map((each) => ({
    companyId: each.companyId,
    companyName: each.companyName,
    description: each.description,
    isAdmin: each.isAdmin ? <CommonIcon iconSrc={CheckmarkCircledOrange} /> : <CommonIcon iconSrc={CloseGray} />,
    userEmail: each.userEmail,
    status: each.isActive ? t('Enabled') : t('Disabled'),
    accountNumber: each.accountNumber,
    actions: <Dropdown
        companyId={each.companyId}
        userEmail={each.userEmail}
      />
  }));

  getTotalPages = () => {
    const { dataPerPage, data } = this.state;
    const totalPages = [];
    for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
      totalPages.push(i);
    }
    this.setState({ totalPages });
  };

  handleCreateCompany = (e) => {
    e.preventDefault();
    const createCompanyUrl = `${homeUrlNoTrailingSlash()}${routes.createCompany}`;
    window.location.href = createCompanyUrl;
    localStorage.setItem('addCompanydata', JSON.stringify(this.state.data));
  };

  toggleCustomizeTableView = () => {
    if (runningOnBrowser()) {
      window.scrollTo(0, 0);
    }
    this.setState((prevState) => ({ displayCustomizeTableView: !prevState.displayCustomizeTableView }));
  };

  updateTable = (newColumns) => {
    this.setState({ columns: newColumns });
  };

  handleSearchCompanies = () => {
    const { formData } = this.props;
    const { division } = this.props;
    const formDatatoAPI = {
      division,
      userEmail: formData.searchByUserEmail ? formData.searchByUserEmail : '',
      accountNumber: formData.searchByAccountNumber ? formData.searchByAccountNumber : '',
      companyName: formData.searchByCompany ? formData.searchByCompany : ''
    };
    getSearchedCompaniesList(division, formDatatoAPI).then((res) => {
      this.setState({ addCompanyFlag: false });
      if (res.status === 200) {
        // eslint-disable-next-line no-shadow
        const { putReduxAllCompanies } = this.props;
        const convertedData = this.convertDataFormat(res.data);
        putReduxAllCompanies(convertedData);
        this.setState({ data: convertedData }, () => this.getTotalPages());
        if (this.state.data.length !== 0) this.setState({ addCompanyFlag: true });
      }
    });
  };

  render() {
    const { data, columns } = this.state;
    const { formData } = this.props;
    const searchByUserEmail = formData && formData.searchByUserEmail ? formData.searchByUserEmail : '';
    const searchByAccountNumber = formData && formData.searchByAccountNumber ? formData.searchByAccountNumber : '';
    const searchByCompany = formData && formData.searchByCompany ? formData.searchByCompany : '';
    const {
      rendering: {
        fields: {
          heading
        }
      }, fields
    } = this.props;

    return (
      <>
        <TitleGroup>
          <div className="col">
            <H2>{heading.value}</H2>
          </div>
          <ButtonGroup className="col">
            <PrimaryButton className="active min-width" onClick={(e) => this.handleCreateCompany(e)}>
              {t('AddCompany')}
            </PrimaryButton>
          </ButtonGroup>
        </TitleGroup>
        <FilterContainer>
          {/* <div className='row justify-content-md-center' >
            <div className='col-md-9'>
              <div className='col'>
                <SearchWithFilter label={t('SearchCompanies')} getSuggestions={this.getSuggestions} />
                <div className='row justify-content-between'>
                  <FilterIcon className='col-md-3' onClick={this.toggleCustomizeTableView}>
                    <Icon src={Grid} alt="Customize" className={customizeOpen ? 'active' : ''} />
                    {t('CustomizeView')}
                  </FilterIcon>
                </div>
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-md-10">
              <div className="col-md-5 float-left">
                <div>
                  <Field
                    name="searchByUserEmail"
                    type="text"
                    component={RenderField}
                    validate={[anyOneRequiredInGroup]}
                    label={fields.SearchByUserEmail && fields.SearchByUserEmail.value}
                  />
                </div>
                <div>
                  <Field
                    name="searchByCompany"
                    type="text"
                    component={RenderField}
                    validate={[anyOneRequiredInGroup]}
                    label={fields.SearchByCompany && fields.SearchByCompany.value}
                  />
                </div>
              </div>
              <div className="col-md-5 float-right">
                <div>
                  <Field
                    name="searchByAccountNumber"
                    type="text"
                    component={RenderField}
                    validate={[anyOneRequiredInGroup]}
                    label={fields.SearchByAccountNumber && fields.SearchByAccountNumber.value}
                  />
                </div>
                <div className="float-left">
                  <ButtonGroup className="col">
                    <PrimaryButton
                      disabled={searchByUserEmail !== '' || searchByCompany !== '' || searchByAccountNumber !== '' ? false : true}
                      className="active min-width"
                      onClick={() => this.handleSearchCompanies()}
                    >
                      {t('Search')}
                    </PrimaryButton>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </div>

        </FilterContainer>
        <NoDataDecorator component={SortableTable} data={data} columns={columns} />
        {
          this.state.displayCustomizeTableView && (
            <CustomizeTableView
              columns={columns}
              updateTable={this.updateTable}
              hideView={this.toggleCustomizeTableView}
            />
          )
        }
      </>
    );
  }
}

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  division: state.profile.division,
  userId: state.profile.userId,
  allCompanies: state.user.allCompanies
});

const mdtp = (dispatch) => ({
  putReduxAllCompanies: (allCompanies) => dispatch(putReduxAllCompanies(allCompanies)),
});

ManageCompanies = reduxForm({
  form: formName, // a unique identifier for this form
  initialValues,
})(ManageCompanies);

export default connect(mstp, mdtp)(ManageCompanies);
