import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  getFormValues,
  reduxForm,
  reset,
  change
} from 'redux-form';
import { t } from 'i18next';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import RenderField from '../ReactComponent/RenderField';
import {
  isEmail,
  isPhone,
  isRequired,
  maxChar50,
  maxChar255
} from '../../utils/validator';
import { P } from '../../theme/typographySitecore';
import {
  ButtonContainer, FieldContainer,
  HeaderContainer,
  HeaderTitle,
  ReferenceNumberContainer,
  ReferenceNumberSummary,
  ResetButton
} from './css';
import PrimaryButton, { styleTypes } from '../ReactComponent/PrimaryButton';
import ResetOrange from '../../assets/icons/ResetOrange.svg';
import { phoneNumber } from '../../utils/mask';
import ResetFormModal from './ResetFormModal';
import SuccessModal from '../ReactComponent/SuccessModal';
import postFeedback from '../../services/feedbackService';
import { getUserById } from '../../services/users';

const formName = 'SubmitFeedbackForm';
const SubmitFeedbackFormFields = {
  fullName: 'fullName',
  companyName: 'companyName',
  email: 'email',
  phoneNumber: 'phoneNumber',
  referenceNumber: 'referenceNumber',
  feedbackMessage: 'feedbackMessage',
  reasonForContacting: 'reasonForContacting'
};

class SubmitFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetFormModalIsOpen: false,
      successModalIsOpen: false,
    };
  }

  setResetFormModalIsOpen = (isOpen) => {
    this.setState({ resetFormModalIsOpen: isOpen });
  };

  setSuccessModalIsOpen = (isOpen) => {
    this.setState({ successModalIsOpen: isOpen });
  };

  resetForm = () => {
    const { dispatch } = this.props;
    dispatch(reset(formName));
  };

  handleSubmit = () => {
    const { formData } = this.props;
    postFeedback(formData)
      .then((res) => {
        if (res.status === 200) {
          this.setSuccessModalIsOpen(true);
        }
      });
  };

  componentDidMount() {
    const {
      isAuthenticated,
      dispatch,
      division,
      userId
    } = this.props;

    if (isAuthenticated) {
      getUserById(division, userId)
        .then((response) => {
          if (response.status === 200) {
            dispatch(change(formName, SubmitFeedbackFormFields.fullName, response.data.profileDetails.fullName));
            dispatch(change(formName, SubmitFeedbackFormFields.email, response.data.userEmail));
          }
        });
    }
  }

  renderHeader = () => {
    const { fields } = this.props;
    return (
      <HeaderContainer>
        <Column $desktop={8}>
          <HeaderTitle field={fields.headerTitle}/>
          <P field={fields.headerSummary}/>
        </Column>
      </HeaderContainer>
    );
  };

  renderForm = ({ isFormValid }) => {
    const { fields } = this.props;
    return (
      <form>

        <Row>
          <FieldContainer $desktop={6}>
            <Field
              name={SubmitFeedbackFormFields.fullName}
              type="text"
              component={RenderField}
              label={t('FullName')}
              required
              validate={[isRequired, maxChar50]}
            />
          </FieldContainer>
          <FieldContainer $desktop={6}>
            <Field
              name={SubmitFeedbackFormFields.companyName}
              type="text"
              component={RenderField}
              label={t('CompanyName')}
              validate={[maxChar50]}
            />
          </FieldContainer>
        </Row>

        <Row>
          <FieldContainer $desktop={6}>
            <Field
              name={SubmitFeedbackFormFields.email}
              type="text"
              component={RenderField}
              label={t('Email')}
              required
              validate={[isRequired, isEmail, maxChar50]}
            />
          </FieldContainer>
          <FieldContainer $desktop={6}>
            <Field
              name={SubmitFeedbackFormFields.phoneNumber}
              type="text"
              component={RenderField}
              label={t('PhoneNumber')}
              required
              validate={[isRequired, isPhone]}
              normalize={phoneNumber}
            />
          </FieldContainer>
        </Row>

        <ReferenceNumberContainer>
          <FieldContainer $desktop={6}>
            <ReferenceNumberSummary field={fields.referenceNumberSummary}/>
          </FieldContainer>
          <FieldContainer $desktop={6}>
            <Field
              name={SubmitFeedbackFormFields.referenceNumber}
              type="text"
              component={RenderField}
              label={t('ReferenceNumber')}
              validate={[maxChar50]}
            />
          </FieldContainer>
        </ReferenceNumberContainer>

        <Row>
          <FieldContainer $desktop={6}>
            <Field
              name={SubmitFeedbackFormFields.feedbackMessage}
              type="textarea"
              component={RenderField}
              label={t('FeedbackMessage')}
              required
              validate={[isRequired, maxChar255]}
            />
          </FieldContainer>
          <FieldContainer $desktop={6}>
            <Field
              name={SubmitFeedbackFormFields.reasonForContacting}
              type="dropdown"
              component={RenderField}
              label={t('ReasonForContacting')}
              data={[
                t('SharePositive'),
                t('SuggestImprovements'),
                t('QuestionOrAssistance')
              ]}
              required
              validate={[isRequired]}
            />
          </FieldContainer>
        </Row>

        <ButtonContainer>
          <ResetButton
            type="button"
            smallWidth
            styleType={styleTypes.SECONDARY}
            iconSrc={ResetOrange}
            onClick={() => this.setResetFormModalIsOpen(true)}>
            {t('Reset')}
          </ResetButton>
          <PrimaryButton
            type="button"
            smallWidth
            iconRightArrow
            disabled={!isFormValid}
            onClick={() => this.handleSubmit()}>
            {t('Submit')}
          </PrimaryButton>
        </ButtonContainer>

      </form>
    );
  };

  render() {
    const {
      fields,
      pristine,
      invalid
    } = this.props;
    const {
      resetFormModalIsOpen,
      successModalIsOpen
    } = this.state;
    return (
      <>
        <ResetFormModal
          isOpen={resetFormModalIsOpen}
          onClose={() => this.setResetFormModalIsOpen(false)}
          onReset={() => this.resetForm()}
          parentFields={fields}
        />
        <SuccessModal
          isOpen={successModalIsOpen}
          onClose={() => {
            this.resetForm();
            this.setSuccessModalIsOpen(false);
          }}
          parentFields={fields}
        />
        {this.renderHeader()}
        {this.renderForm({ isFormValid: !(pristine || invalid) })}
      </>
    );
  }
}

SubmitFeedback.propTypes = {
  dispatch: PropTypes.func,
  formData: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  division: PropTypes.string,
  userId: PropTypes.string,
  fields: PropTypes.shape({
    headerTitle: PropTypes.string,
    headerSummary: PropTypes.string,
    referenceNumberSummary: PropTypes.string,
  }),
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
};

SubmitFeedback = reduxForm({
  form: formName
})(SubmitFeedback);

const mapStateToProps = (state) => ({
  formData: getFormValues(formName)(state),
  isAuthenticated: state.profile.isAuthenticated,
  division: state.profile.division,
  userId: state.profile.userId
});

export default connect(mapStateToProps, {})(SubmitFeedback);
