import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import axios from 'axios';
import AlertModal from '../Modal/AlertModal';
import { ContentContainer, LoadingGif } from './css';
import { H2, P } from '../../../theme/typographySitecore';
import { routes } from '../../../utils/constants';
import { runningOnBrowser, getSitecoreStateAuthToken } from '../../../utils/func';

const ROUTE_WHITELIST = [
  routes.viewUserDashboard,
];

/**
 * LoadingIndicator component
 * Displays a modal w/ a loading indicator, for each axios request made within the scope of the application. The
 * modal is closed whenever all in flight requests respond.
 *
 * Usage example: <LoadingIndicator/>
 */
const LoadingIndicator = (props) => {
  const { authToken } = props;
  axios.interceptors.request.use((config) => {
    if (!config.headers || !config.headers.Authorization) {
      const securityLevel = 'Bearer';
      const authorization = authToken;
      config.headers.Authorization = `${securityLevel} ${authorization}`;
    }

    return config;
  });
  const { isLoading } = useLoadingModal();
  const header = () => (
    <LoadingGif />
  );

  const content = () => (
    <ContentContainer>
      <H2 field={t('LoadingIndicatorHeader')} />
      <P field={t('LoadingIndicatorBody')} />
    </ContentContainer>
  );

  return (
    <AlertModal
      isOpen={isLoading}
      header={header}
      content={content}
      isCloseButton={false}
      shrinkwrapContent
      maximizeZIndex
    />
  );
};

LoadingIndicator.propTypes = {
  authToken: PropTypes.string
};

/**
 * Loading indicator model for a modal
 *
 * @returns {isLoading: boolean}
 * isLoading - is true when requests are in flight, false when in flight requests return...
 */
const useLoadingModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const requestCounter = useRef(0);
  // If path is whitelisted, dont initiate interceptors
  if (runningOnBrowser() && !ROUTE_WHITELIST.some((route) => window.location.pathname.includes(route))) {
    useEffect(() => {
      // Request handler...
      axios.interceptors.request.use((config) => {
        config.headers['cache-control'] = 'no-cache,  no-store';
        config.headers.Pragma = 'no-cache';
        config.headers.Expires = '14400000';

        setIsLoading(true);

        // Increment request count...
        requestCounter.current += 1;

        return config;
      }, (error) => Promise.reject(error));

      // Response handler...
      axios.interceptors.response.use((response) => {
        // Decrement request count...
        requestCounter.current -= 1;

        // In no requests are currently in flight, hide the loading indicator...
        if (requestCounter.current <= 0) {
          setIsLoading(false);
        }

        return response;
      }, (error) => {
        // Decrement request count...
        requestCounter.current -= 1;

        // In no requests are currently in flight, hide the loading indicator...
        if (requestCounter.current <= 0) {
          setIsLoading(false);
        }

        return Promise.reject(error);
      });
    }, []);
  }

  return {
    isLoading,
  };
};

const mstp = (state) => ({
  authToken: (state.profile.generalSettings && state.profile.generalSettings.service_apiKey) || getSitecoreStateAuthToken(),
});
export default connect(mstp)(LoadingIndicator);
