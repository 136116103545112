import styled from 'styled-components';
import LoadingIndicator from '../../../assets/image/LoadingIndicator.gif';

export const LoadingGif = styled.img.attrs(() => ({ src: LoadingIndicator }))`
  height: 80px;
  width: 160px;
`;

export const ContentContainer = styled.div`
  width: 250px;
  margin: auto;
`;
