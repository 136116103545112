import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import {
  ErrorContainer,
  ErrorHeader,
  ErrorIcon,
  ErrorIconContainer,
  ErrorMessageContainer
} from './css';
import { P } from '../../../../theme/typographySitecore';

const NoDataDecorator = ({
  data,
  component: Component,
  ...rest
}) => {
  if (!data) return null;

  if (!data.length) {
    return (
      <ErrorContainer>
        <ErrorIconContainer>
          <ErrorIcon/>
        </ErrorIconContainer>
        <ErrorMessageContainer>
          <ErrorHeader field={t('NoResultsFound')}/>
          <P field={t('ModifySearchTerms')}/>
        </ErrorMessageContainer>
      </ErrorContainer>
    );
  }

  return (
    <Component data={data} {...rest}/>
  );
};

NoDataDecorator.propTypes = {
  data: PropTypes.array,
  component: PropTypes.elementType
};

export default NoDataDecorator;
