export const isNullOrWhiteSpace = (value) => ((value === undefined || value === null || value.trim)
  && value.trim().length === 0);

export const stringNotEmpty = (value) => !isNullOrWhiteSpace(value);

export const startsWith = (value, subValue) => {
  if (isNullOrWhiteSpace(value) || isNullOrWhiteSpace(subValue)) {
    return false;
  }

  return value.length >= subValue.length
    && value.substring(0, subValue.length) === subValue;
};

export function formatString(value, ...args) {
  if (!value || isNullOrWhiteSpace(args)) {
    return value;
  }

  let result = value;
  Object.keys(args).forEach((i) => {
    const param = new RegExp(`{[${i}]}`, 'g');
    result = result.replace(param, args[i]);
  });

  return result;
}
