import React from 'react';
import {
  InformationPage, IntroIcon
} from '../css';
import basicInformationIcon from '../../../assets/auth/basicInformationIcon.svg';
import creditApplicationIcon from '../../../assets/auth/creditApplicationIcon.svg';
import accountActivationIcon from '../../../assets/auth/accountActivationIcon.svg';
import {
  H1, H2, H3, P
} from '../../../theme/typographySitecore';
import Button from './common';
import Column from '../../ReactComponent/Column';
import Row from '../../ReactComponent/Row';
import Step0List from './Step0List';

const Step0 = ({
  fields,
  onNext
}) => {
  const {
    startSectionHeading,
    beginApplicationButton,
    welcomeText,
    doYouHaveAccountText,
    basicInformation,
    documentsYouNeed,
    contactInformationText,
    contactDocumentsList,
    creditApplication,
    creditApplicationText,
    creditDocumentsList,
    accountActivation,
    pendingAccountActivationText,
    emailAccountActivationText,
    startUsingAccountText,
  } = fields;

  return (
    <InformationPage>
      <Row>
        <Column>
          <H1 field={startSectionHeading}/>
        </Column>
      </Row>
      <Button text={beginApplicationButton} onClick={onNext}/>
      <P center field={welcomeText}/>
      <P center field={doYouHaveAccountText}/>
      <IntroIcon src={basicInformationIcon}/>
      <H2 field={basicInformation}/>
      <P center field={contactInformationText}/>
      <H3 field={documentsYouNeed}/>
      <Step0List data={contactDocumentsList}/>
      <IntroIcon src={creditApplicationIcon}/>
      <H2 field={creditApplication}/>
      <P center field={creditApplicationText}/>
      <H3 field={documentsYouNeed}/>
      <Step0List data={creditDocumentsList}/>
      <IntroIcon src={accountActivationIcon}/>
      <H2 field={accountActivation}/>
      <P center field={pendingAccountActivationText}/>
      <P center field={emailAccountActivationText}/>
      <P center field={startUsingAccountText}/>
      <Button text={beginApplicationButton} onClick={onNext}/>
    </InformationPage>
  );
};

export default Step0;
