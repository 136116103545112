import React from 'react';
import { connect } from 'react-redux';
import logOut from './actions';

// eslint-disable-next-line react/prefer-stateless-function
class LogoutImpl extends React.Component {
  render() {
    this.props.logOut();
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
});

const Logout = connect(null, mapDispatchToProps)(LogoutImpl);

export default Logout;
