import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { t } from 'i18next';
import FieldFileInput from '../ReactComponent/FileUploadButton';
import { phoneNumber, digitOnly, currencyOnly } from '../../utils/mask';
import Edit from '../../assets/icons/Edit.svg';
import Plus from '../../assets/icons/Plus.svg';
import Image from '../../assets/icons/Image.svg';
import {
  isRequired, maxLength, isPhone, isEmail, maxChar25,
} from '../../utils/validator';
import Tooltip from '../ReactComponent/Tooltip';
import {
  Icon,
  FormSummary,
  ContentBoxLightGrey,
  SummaryText,
  EditButton,
  FileName,
  FileUploadLabel,
  FileUploadContainer,
  FileUpload,
} from './css';
import {
  H1, H2, H3, P, PLight,
} from '../../theme/typography';
import RenderField from '../ReactComponent/RenderField';
import UploadImages from '../ReactComponent/UploadImages';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import { formatMoney } from '../../utils/func';

const maxChar6 = maxLength(6);
const maxChar50 = maxLength(50);
const maxChar255 = maxLength(255);

let inCaseDamage;
const handleCheckboxOnChange = () => {
  const checkedValue = document.querySelector('#claimTypeOption input:checked')
    .nextElementSibling.nextElementSibling.innerText.toLocaleLowerCase();
  return checkedValue === 'damage' || checkedValue === 'Dommage'.toLowerCase();
};

const renderFreightRepeatable = ({ fields }) => (
  <>
    {fields.map((part, index) => <div key={index}>
        <Row>
          <Column $tablet={8} $mobile={8}>
            <Field
              name={`${part}.PartDescription`}
              component={RenderField}
              label={t('PartDescription')}
              required
              validate={[maxChar255, isRequired]}
            />
          </Column>
          <Column $tablet={4} $mobile={4}>
            <Field
              name={`${part}.ClaimAmount`}
              component={RenderField}
              label={t('ClaimAmount')}
              required
              validate={[maxChar50, isRequired]}
              normalize={currencyOnly}
            />
          </Column>
          <Column $tablet={12} $mobile={12}>
            <UploadImages numberOfPlaceholders={4} name={`${part}.PartFileUpload`} fieldCounter={index}/>
          </Column>
        </Row>
      </div>,
    )}
    <FileUploadContainer className="with-dot">
      <FileUpload onClick={() => fields.push({})}>
        <img src={Plus} alt="add more part file"/>
        <FileUploadLabel>{t('FileUploadClaimLabel')}</FileUploadLabel>
      </FileUpload>
    </FileUploadContainer>
  </>
);

const renderRepeatable = ({ fields }) => (
  <>
    {fields.map((part, index) => <div key={index}>
        <Row>
          <Column $tablet={8} $mobile={8}>
            <Field
              name={`${part}.PartDescription`}
              component={RenderField}
              label={t('PartDescription')}
              required
              validate={[maxChar50, isRequired]}
            />
          </Column>
          <Column $tablet={4} $mobile={4}>
            <Field
              name={`${part}.PartNumber`}
              component={RenderField}
              label={t('PartNumber')}
              required
              validate={[maxChar50, isRequired]}
            />
          </Column>
          <Column $tablet={12} $mobile={12}>
            <UploadImages numberOfPlaceholders={4} name={`${part}.PartFileUpload`} fieldCounter={index}/>
          </Column>
          <Column $tablet={12} $mobile={12}>
            <Field
              name={`${part}.PartDeliveryDate`}
              type="date"
              component={RenderField}
              label={t('DateOfDelivery')}
              required
              validate={[isRequired]}
              asteriskStyle={{ top: '10px' }}
            />
          </Column>
        </Row>
      </div>,
    )}
    <FileUploadContainer className="with-dot">
      <FileUpload onClick={() => fields.push({})}>
        <img src={Plus} alt="add more part file"/>
        <FileUploadLabel>{t('FileUploadLabel')}</FileUploadLabel>
      </FileUpload>
    </FileUploadContainer>
  </>
);

export const Step1 = ({
  step,
  fields,
  isFreight
}) => {
  if (step === 0) {
    return (
      <>
        <FormSummary>

          {fields.step1_heading && <H1><Text field={fields.step1_heading}/></H1>}
          {fields.step1_description && <P><RichText field={fields.step1_description}/></P>}
        </FormSummary>
        {!isFreight ? <>
            <Field
              name="DealerName"
              type="text"
              component={RenderField}
              required
              label={t('DealerName')}
              validate={[isRequired, maxChar50]}
            />
            <Field
              name="DealerCode"
              type="text"
              component={RenderField}
              label={t('DealerCode')}
              required
              validate={[isRequired, maxChar50]}
            />
            <Tooltip tip={t('TipAddressDetails')} dedicatedClaimFlow/>
            <Field
              name="DealerCompleteAddress"
              type="address"
              component={RenderField}
              label={t('FullAddress')}
              validate={[maxChar50, isRequired]}
              required
            />
            <Field
              name="CompanyName"
              type="text"
              component={RenderField}
              label={t('CompanyName')}
              required
              validate={[isRequired, maxChar50]}
            />
            <Field
              name="ContactName"
              type="text"
              component={RenderField}
              label={t('ContactName')}
              required
              validate={[isRequired, maxChar50]}
            />
          </>
          : <>
            <Field
              name="ReferenceNumber"
              type="text"
              component={RenderField}
              label={t('CustomerReferenceNumber')}
              required
              validate={[isRequired, maxChar50]}
            />
            <Tooltip tip={t('TipReferenceNumber')}/>
            <Field
              name="ClaimantName"
              type="text"
              component={RenderField}
              label={t('CompanyName')}
              required
              validate={[isRequired, maxChar50]}
            />
            <Tooltip tip={t('TipAddressDetails')}/>
            <Field
              name="ClaimantAddress"
              type="address"
              component={RenderField}
              label={t('FullAddress')}
              validate={[maxChar50, isRequired]}
              required
            />
            <Field
              name="ContactName"
              type="text"
              component={RenderField}
              label={t('ContactName')}
              required
              validate={[isRequired, maxChar50]}
            />
          </>}
        <Row>
          <Column $tablet={8} $mobile={8}>
            <Field
              name="PhoneNumber"
              component={RenderField}
              label={t('PhoneNumber')}
              required
              validate={[isRequired, isPhone, maxChar50]}
              normalize={phoneNumber}
            />
          </Column>
          <Column $tablet={4} $mobile={4}>
            <Field
              name="Extension"
              component={RenderField}
              label={t('Extension')}
              validate={[maxChar6]}
              normalize={digitOnly}
            />
          </Column>
        </Row>
        <Field
          name="EmailAddress"
          type="email"
          component={RenderField}
          label={t('EmailAddress')}
          required
          validate={[isRequired, isEmail, maxChar50]}
        />
      </>
    );
  }

  return null;
};

Step1.propTypes = {
  step: PropTypes.number,
  fields: PropTypes.shape({
    step1_heading: PropTypes.string,
    step1_description: PropTypes.string,
  }),
  isFreight: PropTypes.bool
};

// eslint-disable-next-line
export const Step2 = ({
  formData =
  { PartsInformation: [{ ClaimAmount: '0' }] },
  step,
  fields,
  isFreight,
  updateTotalClaimValue,
}) => {
  if (step === 1) {
    let result = 0;
    formData.PartsInformation.forEach((each) => {
      if (each.ClaimAmount) {
        result += Number(each.ClaimAmount);
      }
    });
    updateTotalClaimValue(result);
    return (
      <>
        <FormSummary>
          {fields.step2_heading && <H1><Text field={fields.step2_heading}/></H1>}
          {fields.step2_description && <P><RichText field={fields.step2_description}/></P>}
        </FormSummary>
        <Field
          name="BillOfPayment"
          type="text"
          component={RenderField}
          label={t('BillOfPayment')}
          required
          validate={[isRequired, maxChar25]}
        />
        <Tooltip tip={t('TipProBill')}/>
        {!isFreight // dedicated
          ? <>
            <H3>{t('TypeOfClaim')}</H3>
            <Field
              name="TypeOfClaim"
              type="checkboxes"
              component={RenderField}
              data={[t('Damage'), t('Shortage')]}
              required
              validate={[isRequired]}
            />
            <H2>{t('ClaimValue')}</H2>
            <Row>
              <Column $tablet={8} $mobile={8}>
                <Field
                  name="ClaimValue"
                  component={RenderField}
                  label={t('ClaimAmount')}
                  required
                  validate={[isRequired, maxChar50]}
                  normalize={currencyOnly}
                />
              </Column>
              <Column $tablet={4} $mobile={4}>
                <Field
                  name="Currency"
                  type="dropdown"
                  component={RenderField}
                  data={['USD', 'CAD']}
                  label={t('Currency')}
                  validate={[isRequired]}
                  required
                />
              </Column>
            </Row>
            <H3>{t('ContactHeading')}</H3>
            <Field
              name="ContactedCustomerService"
              type="checkboxes"
              component={RenderField}
              data={[t('Yes'), t('No')]}
              validate={[isRequired]}
              required
            />
            {
              formData.ContactedCustomerService === 'Yes' && (
                <Field
                  name="DateContactCustomerService"
                  type="date"
                  component={RenderField}
                  label={t('DateContacted')}
                  validate={[isRequired]}
                  required
                />
              )
            }
            <Field
              name="DescriptionOfTheClaim"
              type="textarea"
              component={RenderField}
              label={t('ClaimDescription')}
              validate={[maxChar255]}
            />
            <H2 className="pad-t-5 mar-t-5">{t('PartsInformation')}</H2>
            <FieldArray name="PartsInformation" component={renderRepeatable}/>
          </> // Freight/same-day
          : <>
            <Field
              name="ShipperName"
              type="text"
              component={RenderField}
              label={t('ShipperName')}
              validate={[maxChar50, isRequired]}
              required
            />
            <Row>
              <Column $tablet={8} $mobile={8}>
                <Field
                  name="ShipperTelephoneNumber"
                  component={RenderField}
                  label={t('ShipperPhoneNumber')}
                  validate={[isRequired, isPhone, maxChar50]}
                  required
                  normalize={phoneNumber}
                />
              </Column>
              <Column $tablet={4} $mobile={4}>
                <Field
                  name="ShipperExtension"
                  component={RenderField}
                  label={t('Extension')}
                  validate={[maxChar6]}
                  normalize={digitOnly}
                />
              </Column>
            </Row>
            <Field
              name="ConsigneeName"
              type="text"
              component={RenderField}
              label={t('ConsigneeName')}
              validate={[maxChar50, isRequired]}
              required
            />
            <Row>
              <Column $tablet={8} $mobile={8}>
                <Field
                  name="ConsigneeTelephoneNumber"
                  component={RenderField}
                  label={t('ConsigneePhoneNumber')}
                  required
                  validate={[isRequired, isPhone, maxChar50]}
                  normalize={phoneNumber}
                />
              </Column>
              <Column $tablet={4} $mobile={4}>
                <Field
                  name="ConsigneeExtension"
                  component={RenderField}
                  label={t('Extension')}
                  validate={[maxChar6]}
                  normalize={digitOnly}
                />
              </Column>
            </Row>
            <Row>
              <Column $tablet={12} $mobile={12} id="claimTypeOption" onChange={(e) => handleCheckboxOnChange(e)}>
                <Field
                  name="TypeOfClaim"
                  type="checkboxes"
                  component={RenderField}
                  required
                  data={[t('Damage'), t('Shortage'), t('Loss'), t('Incident'), t('Overage'), t('Refused')]}
                  validate={[isRequired]}
                  label={t('ClaimIsFor')}
                />
              </Column>
            </Row>
            {inCaseDamage ? <>
                <Tooltip tip={t('TipSubmitClaimReason')} marginLeft/>
                <Field
                  name="AdditionalDamageInfo"
                  type="checkboxes"
                  component={RenderField}
                  data={[t('Yes'), t('No')]}
                  required
                  label={t('AdditionalDamageInfo')}
                  validate={[isRequired]}
                />
                <Tooltip tip={t('TipDamageClaim')} marginLeft/>
              </> :
              <>
              </>}
            <Field
              name="DamageExplanation"
              type="textarea"
              component={RenderField}
              label={t('DamageExplanation')}
              validate={[maxChar255, isRequired]}
              required
            />
            <Field
              name="QualityControl"
              type="checkboxes"
              component={RenderField}
              data={[t('Yes'), t('No')]}
              validate={[isRequired]}
              required
              label={t('QualityControl')}
            />
            <Tooltip tip={t('TipQualityAssurance')} marginLeft/>
            <H3>{t('DetailedDescriptionOfClaim')}</H3>
            <PLight style={{ marginBottom: '32px' }}>{t('DetailedDescriptionOfClaimParagraph')}</PLight>
            <H2>{t('PartsInformation')}</H2>
            <FieldArray name="PartsInformation" component={renderFreightRepeatable}/>
            <Row className="justify-content-end">
              <Column $tablet={3} $mobile={6}>
                <Field
                  name="Currency"
                  type="dropdown"
                  component={RenderField}
                  data={['USD', 'CAD']}
                  label={t('Currency')}
                  validate={[isRequired]}
                  required
                />
              </Column>
              <Column $tablet={4} $mobile={6}>
                <Field
                  name="TotalClaimValue"
                  component={RenderField}
                  label={t('TotalClaimAmount')}
                  normalize={currencyOnly}
                  meta={{
                    active: true,
                    touched: true
                  }}
                  disabled
                  input={{ value: result.toString() }}
                />
              </Column>
            </Row>
          </>}
      </>
    );
  }

  return null;
};

Step2.propTypes = {
  formData: PropTypes.object,
  step: PropTypes.number,
  fields: PropTypes.shape({
    step2_heading: PropTypes.string,
    step2_description: PropTypes.string,
  }),
  isFreight: PropTypes.bool,
  updateTotalClaimValue: PropTypes.func,
};

// eslint-disable-next-line
export const Step3 = ({
  formData = {},
  step,
  fields
}) => {
  const { PartsInformation } = formData;

  if (step === 2) {
    return (
      <>
        <FormSummary>
          {fields.step3_heading && <H1><Text field={fields.step3_heading}/></H1>}
        </FormSummary>
        {PartsInformation && (
          <ContentBoxLightGrey>
            <H2 className="center-text-mobile">{t('PhotosUploaded')}</H2>
            {PartsInformation.map((each, key) => (
              <React.Fragment key={key}>
                <PLight>{t('PartDescription')}</PLight>
                <SummaryText className="no-margin-bottom">{each.PartDescription}</SummaryText>
                {each.PartFileUpload
                  && (Object.keys(each.PartFileUpload).map((item, i) => (
                  <FileName key={i}>
                    <img src={Image} alt={each.PartFileUpload[item].name}/>
                    {each.PartFileUpload[item].name}
                  </FileName>)))}
              </React.Fragment>
            ))}
          </ContentBoxLightGrey>
        )}

        {fields.step3_description && <P><RichText field={fields.step3_description}/></P>}
        <ContentBoxLightGrey>
          <div className="title-box">
            <Field
              component={FieldFileInput}
              attachButton={t('AttachDocument')}
              label={t('BillOfLading')}
              name="ProBillNumber"
            />
            <Field
              component={FieldFileInput}
              attachButton={t('AttachDocument')}
              label={t('FreightBill')}
              name="PaidFreightBill"
            />
            <Field
              component={FieldFileInput}
              attachButton={t('AttachDocument')}
              label={t('ProofOfDelivery')}
              name="ProofOfDelivery"
            />
            <Field
              component={FieldFileInput}
              attachButton={t('AttachDocument')}
              label={t('CopyOfClaimantsCostInvoice')}
              name="CopyOfClaimantsCostInvoice"
            />
            <Field
              component={FieldFileInput}
              attachButton={t('AttachDocument')}
              label={t('CopyOfRepairBill')}
              name="CopyOfRepairBill"
            />
            <Field
              component={FieldFileInput}
              attachButton={t('AttachDocument')}
              label={t('SupportingDocumentation')}
              name="SupportingDocumentation"
            />
            <Field
              component={FieldFileInput}
              attachButton={t('AttachDocument')}
              label={t('PicturesOfDamage')}
              name="PicturesOfAnyDamage"
            />
            <Field
              component={FieldFileInput}
              attachButton={t('AttachDocument')}
              label={t('PicturesOfTrailerOrContainer')}
              name="PicturesOfTrailerOrContainer"
            />
            <Field
              component={FieldFileInput}
              attachButton={t('AttachDocument')}
              label={t('ClaimForm')}
              name="ClaimForm"
            />
            <Field
              component={FieldFileInput}
              attachButton={t('AttachDocument')}
              label={t('PackingSlip')}
              name="PackingSlip"
            />
            <Field
              component={FieldFileInput}
              attachButton={t('AttachDocument')}
              label={t('PartsInvoice')}
              name="PartsInvoice"
            />
          </div>
        </ContentBoxLightGrey>
      </>
    );
  }

  return null;
};

Step3.propTypes = {
  formData: PropTypes.object,
  step: PropTypes.number,
  fields: PropTypes.shape({
    step3_heading: PropTypes.string,
    step3_description: PropTypes.string
  }),
};

// eslint-disable-next-line
export const Step4 = ({
  formData = {},
  step,
  editStepOne,
  editStepTwo,
  editStepThree,
  fields,
  isFreight,
  totalClaimValue,
}) => {
  const {
    DealerName,
    DealerCode,
    DealerCompleteAddress,
    CompanyName,
    ContactName,
    PhoneNumber,
    EmailAddress,
    BillOfPayment,
    TypeOfClaim,
    ClaimValue,
    ContactedCustomerService,
    DateContactCustomerService,
    DescriptionOfTheClaim,
    PartsInformation,
    ProBillNumber,
    PaidFreightBill,
    ProofOfDelivery,
    CopyOfClaimantsCostInvoice,
    CopyOfRepairBill,
    SupportingDocumentation,
    PicturesOfAnyDamage,
    PicturesOfTrailerOrContainer,
    ClaimForm,
    PartsInvoice,
    PackingSlip,
    ReferenceNumber,
    ClaimantName,
    ClaimantAddress,
    Extension,
    ShipperName,
    ShipperTelephoneNumber,
    ShipperExtension,
    ConsigneeName,
    ConsigneeTelephoneNumber,
    ConsigneeExtension,
    DamageExplanation,
    Currency,
    QualityControl,
    AdditionalDamageInfo,
  } = formData;

  if (step === 3) {
    return (
      <>
        <FormSummary>
          {fields.step4_heading && <H1><Text field={fields.step4_heading}/></H1>}
          {fields.step4_description && <P><RichText field={fields.step4_description}/></P>}
        </FormSummary>
        <ContentBoxLightGrey>
          <H2 className="center-text-mobile">
            {t('YourInformation')}
          </H2>
          <EditButton onClick={editStepOne}>
            {t('Edit')}
            {' '}
            <Icon className="icon" src={Edit} alt="edit"/>
          </EditButton>
          {!isFreight
            ? <>
              <PLight>{t('DealerName')}</PLight>
              <SummaryText>{DealerName}</SummaryText>
              <PLight>{t('DealerCode')}</PLight>
              <SummaryText>{DealerCode}</SummaryText>
              <PLight>{t('DealerCompleteAddress')}</PLight>
              <SummaryText>{DealerCompleteAddress}</SummaryText>
              <PLight>{t('CompanyName')}</PLight>
              <SummaryText>{CompanyName}</SummaryText>
              <PLight>{t('ContactName')}</PLight>
              <SummaryText>{ContactName}</SummaryText>
            </>
            : <>
              <PLight>{t('CustomerReferenceNumber')}</PLight>
              <SummaryText>{ReferenceNumber}</SummaryText>
              <PLight>{t('ClaimantName')}</PLight>
              <SummaryText>{ClaimantName}</SummaryText>
              <PLight>{t('ClaimantAddress')}</PLight>
              <SummaryText>{ClaimantAddress}</SummaryText>
              <PLight>{t('ContactName')}</PLight>
              <SummaryText>{ContactName}</SummaryText>
            </>}
          <PLight>{t('PhoneNumber')}</PLight>
          <SummaryText>{PhoneNumber}</SummaryText>
          {Extension && <>
            <PLight>{t('Extension')}</PLight>
            <SummaryText>{Extension}</SummaryText>
          </>}
          <PLight>{t('EmailAddress')}</PLight>
          <SummaryText>{EmailAddress}</SummaryText>
        </ContentBoxLightGrey>
        <ContentBoxLightGrey>
          <H2 className="center-text-mobile">
            {t('ClaimDetails')}
          </H2>
          <EditButton onClick={editStepTwo}>
            {t('Edit')}
            {' '}
            <Icon className="icon" src={Edit} alt="edit"/>
          </EditButton>
          <PLight>{t('BillOfPayment')}</PLight>
          <SummaryText>{BillOfPayment}</SummaryText>
          {
            !isFreight
              ? <>
                <PLight>{t('TypeOfClaim')}</PLight>
                <SummaryText>{TypeOfClaim}</SummaryText>
                <H2>{t('ClaimValue')}</H2>
                <PLight>{t('ClaimAmountValue')}</PLight>
                <SummaryText>{ClaimValue}</SummaryText>
                <H3>{t('ContactHeading')}</H3>
                <SummaryText>{ContactedCustomerService}</SummaryText>
                {
                  ContactedCustomerService === 'Yes' && (
                    <>
                      <PLight>{t('DateContacted')}</PLight>
                      <SummaryText>{DateContactCustomerService}</SummaryText>
                    </>
                  )
                }
                <PLight>{t('ClaimDescription')}</PLight>
                <SummaryText>{DescriptionOfTheClaim}</SummaryText>
                <H2>{t('PartsInformation')}</H2>
                {PartsInformation && PartsInformation.map((each, key) => (
                  <React.Fragment key={key}>
                    <PLight>{t('PartDescription')}</PLight>
                    <SummaryText className="no-margin-bottom">{each.PartDescription}</SummaryText>
                    {each.PartFileUpload
                      && (Object.keys(each.PartFileUpload).map((item, i) => <FileName key={i}>
                        <img src={Image} alt={each.PartFileUpload[item].name}/>
                        {' '}
                        {each.PartFileUpload[item].name}
                      </FileName>))}
                  </React.Fragment>))}
              </>
              : <>
                <PLight>{t('ShipperName')}</PLight>
                <SummaryText>{ShipperName}</SummaryText>
                <PLight>{t('ShipperTelephoneNumber')}</PLight>
                <SummaryText>{ShipperTelephoneNumber}</SummaryText>
                {
                  ShipperExtension && <>
                    <PLight>{t('ShipperExtension')}</PLight>
                    <SummaryText>{ShipperExtension}</SummaryText>
                  </>
                }
                <PLight>{t('ConsigneeName')}</PLight>
                <SummaryText>{ConsigneeName}</SummaryText>
                <PLight>{t('ConsigneeTelephoneNumber')}</PLight>
                <SummaryText>{ConsigneeTelephoneNumber}</SummaryText>
                {
                  ConsigneeExtension && <>
                    <PLight>{t('ConsigneeExtension')}</PLight>
                    <SummaryText>{ConsigneeExtension}</SummaryText>
                  </>
                }
                <PLight>{t('ClaimIsFor')}</PLight>
                <Field
                  name="TypeOfClaimStep4"
                  type="checkboxes"
                  component={RenderField}
                  disabled
                  data={[t('Damage'), t('Shortage'), t('Loss'), t('Delay'), t('Overage'), t('Refused')]}
                  input={{ value: TypeOfClaim }}
                />
                <PLight>{t('AdditionalDamageInfo')}</PLight>
                <Field
                  name="AdditionalDamageInfoStep4"
                  type="checkboxes"
                  component={RenderField}
                  data={[t('Yes'), t('No')]}
                  disabled
                  input={{ value: AdditionalDamageInfo }}
                />
                <PLight>{t('DamageExplanation')}</PLight>
                <SummaryText>{DamageExplanation}</SummaryText>
                <PLight>{t('QualityControl')}</PLight>
                <Field
                  name="QualityControlStep4"
                  type="checkboxes"
                  component={RenderField}
                  data={[t('Yes'), t('No')]}
                  disabled
                  input={{ value: QualityControl }}
                />
                <H3 style={{ marginBottom: '32px' }}>{t('DetailedDescriptionOfClaim')}</H3>
                {PartsInformation && PartsInformation.map((each, key) => (
                  <React.Fragment key={key}>
                    <PLight>{t('PartDescription')}</PLight>
                    <SummaryText className="">{each.PartDescription}</SummaryText>
                    <PLight>{t('ClaimAmount')}</PLight>
                    <SummaryText className="no-margin-bottom">{formatMoney(each.ClaimAmount)}</SummaryText>
                    {each.PartFileUpload
                      && (Object.keys(each.PartFileUpload).map((item, i) => <FileName key={i}>
                            <img src={Image} alt={each.PartFileUpload[item].name}/>
                            {' '}
                            {each.PartFileUpload[item].name}
                          </FileName>
                      )
                      )}
                  </React.Fragment>
                ))}
                <PLight>{t('Currency')}</PLight>
                <SummaryText className="">{Currency}</SummaryText>
                <PLight>{t('TotalClaimAmount')}</PLight>
                <SummaryText className="">{formatMoney(totalClaimValue)}</SummaryText>

              </>
          }
        </ContentBoxLightGrey>
        <ContentBoxLightGrey>
          <H2 className="center-text-mobile">
            {t('DocumentsAttached')}
          </H2>
          <EditButton onClick={editStepThree}>
            {t('Edit')}
            <Icon className="icon" src={Edit} alt="edit"/>
          </EditButton>
          {ProBillNumber && (
            <FileUploadContainer className="with-background">
              <P>{t('BillOfLading')}</P>
              <FileName className="inline">
                <img src={Image} alt="ProBillNumber"/>
                {' '}
                {ProBillNumber.name}
              </FileName>
            </FileUploadContainer>
          )}
          {PaidFreightBill && (
            <FileUploadContainer className="with-background">
              <P>{t('CopyOfRepairBill')}</P>
              <FileName className="inline">
                <img src={Image} alt="PaidFreightBill"/>
                {' '}
                {PaidFreightBill.name}
              </FileName>
            </FileUploadContainer>
          )}
          {ProofOfDelivery && (
            <FileUploadContainer className="with-background">
              <P>{t('ProofOfDelivery')}</P>
              <FileName className="inline">
                <img src={Image} alt="ProofOfDelivery"/>
                {' '}
                {ProofOfDelivery.name}
              </FileName>
            </FileUploadContainer>
          )}
          {CopyOfClaimantsCostInvoice && (
            <FileUploadContainer className="with-background">
              <P>{t('CopyOfClaimantsCostInvoice')}</P>
              <FileName className="inline">
                <img src={Image} alt="CopyOfClaimantsCostInvoice"/>
                {' '}
                {CopyOfClaimantsCostInvoice.name}
              </FileName>
            </FileUploadContainer>
          )}
          {CopyOfRepairBill && (
            <FileUploadContainer className="with-background">
              <P>{t('CopyOfRepairBill')}</P>
              <FileName className="inline">
                <img src={Image} alt="CopyOfRepairBill"/>
                {' '}
                {CopyOfRepairBill.name}
              </FileName>
            </FileUploadContainer>
          )}
          {SupportingDocumentation && (
            <FileUploadContainer className="with-background">
              <P>{t('SupportingDocumentation')}</P>
              <FileName className="inline">
                <img src={Image} alt="SupportingDocumentation"/>
                {' '}
                {SupportingDocumentation.name}
              </FileName>
            </FileUploadContainer>
          )}
          {PicturesOfAnyDamage && (
            <FileUploadContainer className="with-background">
              <P>{t('PicturesOfDamage')}</P>
              <FileName className="inline">
                <img src={Image} alt="PicturesOfAnyDamage"/>
                {' '}
                {PicturesOfAnyDamage.name}
              </FileName>
            </FileUploadContainer>
          )}
          {PicturesOfTrailerOrContainer && (
            <FileUploadContainer className="with-background">
              <P>{t('PicturesOfTrailerOrContainer')}</P>
              <FileName className="inline">
                <img src={Image} alt="PicturesOfTrailerOrContainer"/>
                {' '}
                {PicturesOfTrailerOrContainer.name}
              </FileName>
            </FileUploadContainer>
          )}
          {ClaimForm && (
            <FileUploadContainer className="with-background">
              <P>{t('ClaimForm')}</P>
              <FileName className="inline">
                <img src={Image} alt="ClaimForm"/>
                {' '}
                {ClaimForm.name}
              </FileName>
            </FileUploadContainer>
          )}
          {PackingSlip && (
            <FileUploadContainer className="with-background">
              <P>{t('PackingSlip')}</P>
              <FileName className="inline">
                <img src={Image} alt="PackingSlip"/>
                {' '}
                {PackingSlip.name}
              </FileName>
            </FileUploadContainer>
          )}
          {PartsInvoice && (
            <FileUploadContainer className="with-background">
              <P>{t('PartsInvoice')}</P>
              <FileName className="inline">
                <img src={Image} alt="PartsInvoice"/>
                {' '}
                {PartsInvoice.name}
              </FileName>
            </FileUploadContainer>
          )}
        </ContentBoxLightGrey>
      </>
    );
  }

  return null;
};

Step4.propTypes = {
  formData: PropTypes.object,
  step: PropTypes.number,
  editStepOne: PropTypes.func,
  editStepTwo: PropTypes.func,
  editStepThree: PropTypes.func,
  fields: PropTypes.shape({
    step4_heading: PropTypes.string,
    step4_description: PropTypes.string,
  }),
  isFreight: PropTypes.bool,
  totalClaimValue: PropTypes.number,
};
