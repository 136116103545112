import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import {
  CardContainer, CardImageItem, NewsHeader, DateLine, ReadMoreLine, NewsDescription,
} from './css';
import arrowRight from '../../assets/icons/ArrowRightOrange.svg';

const NewsCard = ({
  title,
  imageSource,
  date,
  description,
  url,
}) => <CardContainer>
  <CardImageItem backgroundImageSrc={imageSource}/>
  <NewsHeader>{title}</NewsHeader>
  <DateLine>{date}</DateLine>
  <NewsDescription>{description}</NewsDescription>
  <ReadMoreLine>
    <a href={url} rel="noopener noreferrer" target="_blank">
      {t('Read More')}
      <img alt="right" src={arrowRight}/>
    </a>
  </ReadMoreLine>
</CardContainer>;

NewsCard.propTypes = {
  title: PropTypes.string,
  imageSource: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
};
export default NewsCard;
