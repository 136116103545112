import React from 'react';
import PropTypes from 'prop-types';
import { ResultCategoryContainer } from '../css';

const ResultCategory = ({
  prefix,
  category,
  count
}) => (
  <ResultCategoryContainer>
    <span className="count">{count}</span>
    <span className="category">
<strong>{prefix}</strong>
      {' '}
      {category}
</span>
  </ResultCategoryContainer>
);

ResultCategory.propTypes = {
  prefix: PropTypes.string,
  category: PropTypes.string,
  count: PropTypes.number
};

export default ResultCategory;
