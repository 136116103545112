import styled, { css } from 'styled-components';
import arrowDown from '../../../assets/icons/ArrowDown.svg';

export const AccordionPageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 48px 0;
`;

export const AccordionTitleShadow = styled.div`
    z-index: 2;
    position: absolute;
    top: 62px;
    width: 96%;
    height: 22px;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
`;

export const AccordionPageTitle = styled.div`
    z-index: 3;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    display: flex;
    padding: 32px 32px 24px 32px;
    background-color: #f9f9f9;
    border-bottom: none;
    border-radius: 6px;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }

    border-bottom: solid 6px #63666a;
    transition: border-bottom 0.3s;
    ${({ $isActive }) => $isActive
            && css`
                border-bottom: solid 6px #ff5f14;
            `}
`;

export const AccordionPageContent = styled.div`
    background: #f9f9f9;
    width: 100vw;
    top: -40px;
    position: relative;
    overflow: hidden;
    padding: 0 calc((100vw - 100%) / 2);
    box-shadow: inset -2px -7px 64px rgba(0, 0, 0, 0.05);
`;

export const AccordionPageContentWrapper = styled.div`
    padding: 60px 24px 24px 24px;
`;

export const Arrow = styled.img.attrs(() => ({ src: arrowDown }))`
    transform-origin: center;
    transition: transform 0.2s;
    ${({ $isActive }) => $isActive
            && css`
                transform: rotate(180deg);
            `}
`;
