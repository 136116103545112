import styled from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';
import PrimaryButton, { styleTypes } from '../PrimaryButton';
import closeIcon from '../../../assets/icons/Close.svg';
import { H2, P } from '../../../theme/typographySitecore';

const WINDOW_HEIGHT = '250px';

export const WindowContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;  
  flex-direction: column;  
  height: ${WINDOW_HEIGHT};
  width: 90%;
  margin: 0 5%;
  z-index: 1;  
  
  ${MediaQuery.medium`
    width: auto;
    margin: auto; 
  `}
`;

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  background: ${colors.BLACK};
  color: ${colors.WHITE};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border: 1px solid ${colors.WHITE};
  border-bottom: unset;
`;

export const HeaderText = styled(H2)`
  margin-bottom: 0;
`;

export const HeaderSubText = styled(P)`
  margin-bottom: 0;
`;

export const CloseIcon = styled.img.attrs(() => ({ src: closeIcon }))`
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;  
  align-items: center;  
  height: 220px;
  padding: 32px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);  
  background: ${colors.LIGHT_GREY};
`;

export const Button = styled(PrimaryButton).attrs(() => ({
  styleType: styleTypes.SECONDARY,
  iconRightArrow: true
}))`
`;

export const FreightButton = styled(Button)`
  margin-bottom: 8px;
`;
