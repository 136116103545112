import styled from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';
import { P } from '../../../theme/typographySitecore';
import PrimaryButton, { styleTypes } from '../PrimaryButton';

export const Popup = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 32px;
    background-color: ${colors.WHITE};
    border: 1px solid ${colors.COOL_GREY};
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    ${MediaQuery.medium`
    flex-direction: row;       
  `}

    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
`;

export const Text = styled(P)`
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    ${MediaQuery.medium`
    flex-direction: row;
    margin-left: 32px;
  `}
`;

export const Button = styled(PrimaryButton).attrs(() => ({
  smallWidth: true,
  iconRightArrow: true
}))`
`;

export const LearnMoreButton = styled(Button).attrs(() => ({ styleType: styleTypes.SECONDARY }))`
    margin: 16px 0;

    ${MediaQuery.medium`
    margin: 0;
    margin-right: 8px;  
  `}
`;
