import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';

export const PrimaryButton = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 16px 56px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    order: 0;
    height: 52px;

    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        margin-bottom: 0;
        order: 1;
    `}

    &.submit, &.active {
        background-color: ${colors.BLACK};

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};
        }

        &:disabled {
            background-color: ${colors.DARK_GREY};
            color: ${colors.COOL_GREY};
            border-color: ${colors.COOL_GREY};
            cursor: default;
        }
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
`;

export const TitleGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 45px 0;
`;

export const FilterContainer = styled.div`
    width: 100%;
    background: ${colors.WHITE};
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 40px;
`;

export const FilterIcon = styled.div`
    font-size: 16px;
    line-height: 20px;
    margin-top: -28px;
    cursor: pointer;
`;

export const Icon = styled.img`
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    width: 16px;
    height: 20px;
`;

export const PageDescription = styled.div`
    font-size: 18px;
    line-height: 24px;
`;
