import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NavigationLink from './navigationLink';

class NavigationGroup extends PureComponent {
  render() {
    const { level, contextItemId, section: { children } = {} } = this.props;

    const nextLevel = level + 1;
    const subitemsClass = `nav-menu-l${nextLevel}`;

    return (
            <li className="group">
                <ul className={subitemsClass}>
                    {children.map((c, key) =>
                        <NavigationLink key={key} fields={c} level={nextLevel} contextItemId={contextItemId} />,
                    )}
                </ul>
            </li>
    );
  }
}

NavigationGroup.propTypes = {
  level: PropTypes.number,
  contextItemId: PropTypes.string,
  section: PropTypes.object,
};

export default NavigationGroup;
