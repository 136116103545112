import styled from 'styled-components';
import { colors, MediaQuery, styleHelpers } from '../../../theme/style-helpers';

export const PrimaryButton = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 16px 56px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    order: 0;
    height:52px;
    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        margin-bottom: 0;
        order: 1;
    `}

    &.submit, &.active {
        background-color: ${colors.BLACK};

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};
        }
    }
`;
export const SecondaryButton = styled.button`
    background-color: ${colors.WHITE};
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    padding: 16px 56px;
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    margin-left: 0;
    width: 100%;
    order: 1;
    cursor: pointer;
    height:52px;
    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        order: 0;
    `}

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }
`;
export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
`;

export const CategoryTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.04em;
    margin: 12px 0;
    text-align:left;   
`;

export const PageTitle = styled.div`
    font-size: 24px;
    line-height: 29px;
    margin-bottom:16px;
`;
export const SmallDesc = styled.div`
    font-size: 12px;
    line-height: 16px;
`;
export const ErrorIcon = styled.img`
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    height: 38px;
    display:block;
    width:100%
`;

export const DownloadModalBody = styled.div`
    font-size:1.5rem;
    text-align:left;
    max-height: 350px;
    overflow-y: auto;
    ${MediaQuery.medium`
        width: 785px;
        padding-right: 95px;
    `}

    .checkbox-container {
      margin-bottom: 0;
    }

    .col {
        vertical-align:top;
        margin-bottom:10px;
        ${styleHelpers.mobile`
            margin: 60px 0;
        `}
        ${MediaQuery.medium`
            display: inline-block;
            width:40%;            
            padding-left: 0;
            margin-top:10px !important;
        `}       
    }
`;

export const DateFields = styled.div`
    margin: 30px 0;
`;
