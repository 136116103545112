import { t } from 'i18next';
import { ShipmentHistoryStates } from '../ViewUserDashboard/constants';

export const filterDetailsData = (detailsData) => detailsData.filter((element) => element.status !== ShipmentHistoryStates.DoNotShow);

export const getHandlingDetails = ({
  statusCode,
  receivedBy
}) => {
  let handlingDetails = {
    label: null,
    value: receivedBy,
  };

  switch (statusCode) {
    case 'PU':
    case 'AB':
    case 'MA':
    case 'LH':
    case 'P':
      handlingDetails.label = t('ReceivedBy');
      break;

    case 'CR':
      handlingDetails.label = t('RejectedBy');
      break;

    case 'CQ':
      handlingDetails.label = t('RequestedBy');
      break;

    case 'V':
    case 'T':
    case 'EX':
      handlingDetails = null;
      break;
    // eslint-disable-next-line default-case
    default:
  }

  return handlingDetails;
};
