import styled, { css } from 'styled-components';
import { colors } from '../../theme/style-helpers';

const GreyBoxWithBorder = styled.div`
    background: ${colors.LIGHT_GREY};
    padding: 32px;
    ${({ $isBorder = true }) => $isBorder && css`border: 1px solid ${colors.BLACK};`}
    box-sizing: border-box;
    border-radius: 6px;
    margin: 16px 0;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
`;

export default GreyBoxWithBorder;
