import React from 'react';
import { t } from 'i18next';
import MeasurementItemCard from '../MeasurementItemCard';
import { AddressBookText } from '../AddressBookEntry';
import CheckMarkSolidOrange from '../../../assets/icons/CheckMarkSolidOrange.svg';
import Edit from '../../../assets/icons/Edit.svg';
import {
  AddressBlock,
  AddedItemCard,
  CategoryTitle,
  Icon,
  TextProperty,
  EditButtonGroup,
  LineButton
} from './css';
import { H3, P } from '../../../theme/typography';
import { AddressTypes } from '../../../utils/enums';
import GreyBoxWithBorder from '../../_styledComponents/GreyBoxWithBorder';
import { formatMoney } from '../../../utils/func';

const getTotalWeight = (list = []) => list.map((item) => item.itemWeight).reduce((accum, curr) => Number(accum) + Number(curr), 0);

const TotalWeight = ({ formData }) => {
  const { measurementList, measurementType = {} } = formData;

  return (
    <GreyBoxWithBorder className="col-sm-5">
      <P>{t('TotalWeightOfItemsInShipment')}</P>
      <H3>{measurementList && getTotalWeight(measurementList)} {measurementType.value === 'Metric' ? 'kg' : t('lbs')}</H3>
    </GreyBoxWithBorder>
  );
};

// eslint-disable-next-line react/no-multi-comp
const TotalNumber = ({ formData }) => {
  const { measurementList } = formData;
  return (
    <GreyBoxWithBorder className="col-sm-5">
      <P>{t('TotalNumberOfPiecesInShipment')}</P>
      <H3>{measurementList
      && measurementList.length > 0 && measurementList.map((x) => x.itemCount).reduce((total, item) => +total + parseInt(item, 10))}</H3>
    </GreyBoxWithBorder>
  );
};

// eslint-disable-next-line react/no-multi-comp
export const AccessorialsText = ({ formData = {} }) => {
  const { accessorials } = formData;

  return (
    <AddedItemCard>
      {accessorials && Object.keys(accessorials).filter((key) => accessorials[key].selected.length > 1).map((filteredKey) => (
        <div key={accessorials[filteredKey].selected[1].code}>
          <H3>
            <Icon className="icon" src={CheckMarkSolidOrange} alt="checkmark" />
            {t(accessorials[filteredKey].selected[1].name)}
          </H3>
          <P>{t(`accessorialDesc${accessorials[filteredKey].selected[1].code}`)}</P>
          {(accessorials[filteredKey].value || accessorials[filteredKey].currency)
          && <H3>{formatMoney(accessorials[filteredKey].value)} {accessorials[filteredKey].currency} </H3>}
        </div>
      ))}
    </AddedItemCard>
  );
};

// eslint-disable-next-line react/no-multi-comp
const SummaryForm = ({
  formData = {}, quoteData, editStepOne, editStepTwo, editStepThree, USQuote, accessorialsViewMode, quote, hideDangerousItem
}) => {
  const {
    accessorials, paymentMethod, billTo, measurementList, measurementType = {}
  } = formData;
  let accessorialsToDisplay = null;
  if (accessorialsViewMode) {
    accessorialsToDisplay = (
      <AddedItemCard>
        {accessorialsViewMode.map((accessorial) => (
          <div key={accessorial.code}>
            <H3>
              <Icon
                className="icon"
                src={CheckMarkSolidOrange}
                alt="checkmark"
              />
              {t(accessorial.description)}
            </H3>
            <P>{t(`accessorialDesc${accessorial.code}`)}</P>
          </div>
        ))}
      </AddedItemCard>
    );
  } else if (accessorials) {
    accessorialsToDisplay = <AccessorialsText formData={formData} />;
  }

  return (
    <>
      <CategoryTitle>
        {t('AddressDetails')}
        {editStepOne && (
          <LineButton onClick={editStepOne}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step one" />
          </LineButton>
        )}
      </CategoryTitle>
      <div className="row">
        <div className="col-sm-6">
          <AddressBlock className="with-bg">
            <H3>
              {t('ShipperDetails')}
            </H3>
            <AddressBookText contactType={AddressTypes.SHIPPER} formData={formData} />
          </AddressBlock>
        </div>
        <div className="col-sm-6">
          <AddressBlock className="with-bg">
            <H3>
              {t('ConsigneeDetailsToAddress')}
            </H3>
            <AddressBookText contactType={AddressTypes.CONSIGNEE} formData={formData} />
          </AddressBlock>
        </div>
        <div className="col-sm-6">
          <AddressBlock className="with-bg">
            <H3>
              {t('BillingInformation')}
            </H3>
            <TextProperty>
              <P className="title">{t('PaymentMethod')}</P>
              {paymentMethod && <P> {(paymentMethod && paymentMethod.name) || paymentMethod} </P>}
            </TextProperty>
            {billTo && <TextProperty>
              <P className="title">{t('Billing Account')}</P>
              <P> {billTo} </P>
            </TextProperty>}
          </AddressBlock>
        </div>
      </div>
      <CategoryTitle>
        {t('Measurements')}
        {editStepTwo && (
          <LineButton onClick={editStepTwo}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step two" />
          </LineButton>
        )}
      </CategoryTitle>
      <MeasurementItemCard
        measurementList={measurementList}
        measurementType={measurementType.value}
        USQuote={USQuote}
        quoteData={quoteData}
        hideButtons
        hideCube
        showDensity
        quote={quote}
        hideDangerousItem={hideDangerousItem}
      />

      <div className="container">
        <div className="row" style={{ justifyContent: !USQuote && 'space-evenly' }}>
          <TotalWeight className="col-6" formData={formData} />
          {!USQuote && <TotalNumber className="col-6" formData={formData} />}
        </div>
      </div>

      <CategoryTitle>
        <div>
          {t('AccessorialServices')}
          <P style={{ marginTop: '10px' }}>{t('AdditionalChargesMayApply')}</P>
        </div>
        {editStepThree && (
          <LineButton onClick={editStepThree}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step three" />
          </LineButton>
        )}
      </CategoryTitle>
      <EditButtonGroup>
      </EditButtonGroup>

      {accessorialsToDisplay}
    </>
  );
};
export default SummaryForm;
