/* eslint-disable no-undef */
const initCOM = (gslbBaseURL) => {
  window.embedded_svc.settings.displayHelpButton = false; // Or false
  window.embedded_svc.settings.language = ''; // For example, enter 'en' or 'en-US'

  window.embedded_svc.settings.defaultMinimizedText = 'Chat With Us'; // (Defaults to Chat with an Expert)
  window.embedded_svc.settings.extraPrechatFormDetails = [
    {
      label: 'Team',
      value: 'Commerce',
      transcriptFields: ['Team__c'],
      displayToAgent: true
    },
    {
      label: 'First Name',
      transcriptFields: ['FirstName__c'],

    }, {
      label: 'Last Name',
      transcriptFields: ['LastName__c']
    }, {
      label: 'Email',
      transcriptFields: ['Email__c']
    }

  ];

  window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
  window.embedded_svc.settings.entryFeature = 'LiveAgent';

  function addA11yHooks() {
    window.embedded_svc.addEventHandler('afterDestroy', () => {
      window.location.reload(false);
    });
  }

  addA11yHooks();

  window.embedded_svc.init(
    process.env.REACT_APP_SALES_FORCE_LINK,
    process.env.REACT_APP_LIVE_AGENT_SETUP_FLOW,
    gslbBaseURL,
    process.env.REACT_APP_SALES_FORCE_CODE,
    'Commerce_Chat_Emb_Service',
    {
      baseLiveAgentContentURL: process.env.REACT_APP_BASE_LIVE_AGENT_CONTENT_URL,
      deploymentId: process.env.REACT_APP_COMMERCE_CHAT_DEPLOYMENT_ID,
      buttonId: process.env.REACT_APP_COMMERCE_CHAT_BUTTON_ID,
      baseLiveAgentURL: process.env.REACT_APP_BASE_LIVE_AGENT_URL,
      eswLiveAgentDevName: process.env.REACT_APP_COMMERCE_CHAT_ESW_LIVE_AGENT_DEV_NAME,
      isOfflineSupportEnabled: false
    }
  );
};

export default initCOM;
