export function isNullOrEmpty(value) {
  if (!value) {
    return true;
  }

  if (!Array.isArray(value)) {
    throw new Error("object isn't an array");
  }

  return value.length === 0;
}

export const arrayLengthSafe = (array) => (isNullOrEmpty(array) ? 0 : array.length);
