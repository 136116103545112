import styled from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';

export const AddressBlock = styled.div`
    &:nth-child(1) {
        ${MediaQuery.medium`
            padding-right: 76px;
        `}
    }
    &:nth-child(2) {
        ${MediaQuery.medium`
            padding-left: 76px;
        `}
    }

    &.with-bg {
        background-color: ${colors.LIGHT_GREY};
        padding: 32px;
        box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        margin-bottom: 32px;
    }
`;
export const AddedItemCard = styled.div`
    background-color: ${colors.LIGHT_GREY};
    padding: 32px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 100%;
    margin: 40px 0;
    position: relative;
    flex-wrap: wrap;

    .row {
        width: 100%;
    }

    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        flex-wrap: wrap;

        p {
            margin: 0;
        }

        > :nth-child(even) {
            background-color: ${colors.WHITE};
        }
    }

    h2, h3 {
        color: ${colors.DARK_GREY};
    }
`;
export const CategoryTitle = styled.div`
    font-size: 2.4rem;
    margin-top: 64px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`;
export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;

export const TextProperty = styled.div`
    font-size:1.4rem;
    margin-bottom: 32px;
    font-weight: 500;

    .title {
        margin-bottom: 10px;
    }

    .justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    hr {
        border-color: #000;
        margin: 1.5rem 0 3rem;
    }
`;

export const EditButtonGroup = styled.div`
    text-align: right;
`;

export const LineButton = styled.a`
    display: inline-flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    cursor: pointer;
    color: ${colors.BLACK};
    text-decoration: none;
    padding: 8px 17px;
`;
