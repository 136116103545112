import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import userReducer from './user';
import profileReducer from './profile';
import accountSideMenuReducer from './accountSideMenu';
import chatWindowReducer from './chatWindow';
import homePageReducer from './homePage';

const reducers = combineReducers({
  user: userReducer,
  form: formReducer,
  profile: profileReducer,
  accountSideMenu: accountSideMenuReducer,
  chatWindow: chatWindowReducer,
  homePage: homePageReducer,
});

export default reducers;
