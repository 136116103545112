const mockSecNav = {
  data: {
    contextItem: {
      path: '/sitecore/content/internet/home/create-pickup',
      id: '2E0E44C62DBA5DF2A4DB92D1797B9A4D'
    },
    item: {
      id: 'A3AE51B64AAE5F13A8180D8E3A266AF3',
      path: '/sitecore/content/internet/Content/SecondaryNavigation',
      children: [{
        path: '/sitecore/content/internet/Content/SecondaryNavigation/Dashboard',
        name: 'Dashboard',
        id: '85AF8F9ED3D65CEEB141898DE6DD3411',
        template: { name: 'Navigation-Link' },
        link: {
          jss: {
            value: {
              href: '/view-user-dashboard',
              text: 'Dashboard',
              url: '/view-user-dashboard',
              linktype: 'internal'
            }
          },
          targetItem: null
        }
      }, {
        path: '/sitecore/content/internet/Content/SecondaryNavigation/Shipments',
        name: 'Shipments',
        id: '123177F2E5FE5BA58745068790B2D0A6',
        template: { name: 'Navigation-Section' },
        title: { value: 'Shipments' },
        children: [{
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Shipments/CreatePickup',
          name: 'CreatePickup',
          id: '70D6ED5358F15B57BF04313609C8C089',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/create-pickup',
                text: 'Create Pickup',
                linktype: 'internal',
                id: '{2E0E44C6-2DBA-5DF2-A4DB-92D1797B9A4D}'
              }
            },
            targetItem: { id: '2E0E44C62DBA5DF2A4DB92D1797B9A4D' }
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Shipments/CreateShipment',
          name: 'CreateShipment',
          id: '5BA1BF0E9C9351CC8F6CD2E308B72A14',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/create-shipment',
                text: 'Create Shipment',
                linktype: 'internal',
                id: '{6A7E48E4-255D-5974-AC87-C0422E219137}'
              }
            },
            targetItem: { id: '6A7E48E4255D5974AC87C0422E219137' }
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Shipments/ManagePickups',
          name: 'ManagePickups',
          id: '64D4176D59AD5F7D95B48DAEFDAB51AD',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/manage-pickups',
                text: 'Manage Pickups',
                linktype: 'internal',
                id: '{71785066-0F0F-52AC-919E-F45BEECF9BC3}'
              }
            },
            targetItem: { id: '717850660F0F52AC919EF45BEECF9BC3' }
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Shipments/ManageShipments',
          name: 'ManageShipments',
          id: '912AC3A4B479512D9B51E5C2B79AE7F4',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/manage-shipments',
                text: 'Manage Shipments',
                linktype: 'internal',
                id: '{05499339-270B-53F6-8D68-CAC4F5221B9E}'
              }
            },
            targetItem: { id: '05499339270B53F68D68CAC4F5221B9E' }
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Shipments/TrackShipments',
          name: 'TrackShipments',
          id: '47C6A4A6834251EC8A626DE5CD38AAFB',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/track-shipments',
                text: 'Track Shipments',
                url: '/track-shipments',
                linktype: 'internal'
              }
            },
            targetItem: null
          }
        }]
      }, {
        path: '/sitecore/content/internet/Content/SecondaryNavigation/Quotations',
        name: 'Quotations',
        id: '03B49FFCD09151D880EE56AC1CA4BFD1',
        template: { name: 'Navigation-Section' },
        title: { value: 'Quotations' },
        children: [{
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Quotations/CreateQuote',
          name: 'CreateQuote',
          id: '2F1B19E40CD6571FA7394A4C2A6429E8',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/create-quote',
                text: 'Create Quote',
                linktype: 'internal',
                id: '{573D9549-05E6-5956-AF90-FEFD157A00B4}'
              }
            },
            targetItem: { id: '573D954905E65956AF90FEFD157A00B4' }
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Quotations/ManageQuotes',
          name: 'ManageQuotes',
          id: '5B77857DB5EC50359DB9810B05423A50',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '',
                text: 'Manage Quotes',
                linktype: 'internal',
                id: '{3E160D78-3599-566D-91E5-87C52559447A}'
              }
            },
            targetItem: null
          }
        }]
      }, {
        path: '/sitecore/content/internet/Content/SecondaryNavigation/Invoices',
        name: 'Invoices',
        id: 'A68D7B1AAC2D51F2A0DC6499CA0038BD',
        template: { name: 'Navigation-Section' },
        title: { value: 'Invoices' },
        children: [{
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Invoices/ManageInvoices',
          name: 'ManageInvoices',
          id: '44C0E49C2DF55270AF24A26F6F088713',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/manage-invoices',
                text: 'Manage Invoices',
                url: '/manage-invoices',
                linktype: 'internal'
              }
            },
            targetItem: null
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Invoices/SubmitClaim',
          name: 'SubmitClaim',
          id: '53361EB65CF4511EA6730A2161B53A71',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/manage-claims',
                text: 'Submit Claim',
                linktype: 'internal',
                id: '{4216DAE8-ECE5-5E74-8D2A-831EFB400A55}'
              }
            },
            targetItem: { id: '4216DAE8ECE55E748D2A831EFB400A55' }
          }
        }]
      }, {
        path: '/sitecore/content/internet/Content/SecondaryNavigation/OtherTools',
        name: 'OtherTools',
        id: '3D25D6F74FD95ADDAC017077833647CE',
        template: { name: 'Navigation-Section' },
        title: { value: 'Other Tools' },
        children: [{
          path: '/sitecore/content/internet/Content/SecondaryNavigation/OtherTools/VolumeDensityCalculator',
          name: 'VolumeDensityCalculator',
          id: '9D387C100C085F60BC8DE7494ECF2746',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/volume-density-calculator',
                text: 'Volume Density Calculator',
                linktype: 'internal',
                id: '{2DB8D672-334F-5C6E-9E7A-0FA999B71DB1}'
              }
            },
            targetItem: { id: '2DB8D672334F5C6E9E7A0FA999B71DB1' }
          }
        }]
      }, {
        path: '/sitecore/content/internet/Content/SecondaryNavigation/Admin',
        name: 'Admin',
        id: '95FAE524E52854FDA122FF19D257AB8B',
        template: { name: 'Navigation-Section' },
        title: { value: 'Admin' },
        children: [{
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Admin/ManageAddressBook',
          name: 'ManageAddressBook',
          id: '1AE2906449465660B534E40363BE5649',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/manage-contacts',
                text: 'Manage Contacts',
                url: '/manage-contacts',
                linktype: 'internal'
              }
            },
            targetItem: null
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Admin/ManageCompanies',
          name: 'ManageCompanies',
          id: '8364DCDEABB65996B5B8DF22EFEE6815',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/manage-companies',
                text: 'Manage Companies',
                linktype: 'internal',
                id: '{186DF497-9F5D-5B2F-B5BD-7198720390CC}'
              }
            },
            targetItem: { id: '186DF4979F5D5B2FB5BD7198720390CC' }
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Admin/ManageCompany',
          name: 'ManageCompany',
          id: '6DF0877BB8745A9DA015F2281A0087CC',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/manage-company',
                text: 'Manage Company',
                url: '/manage-company',
                linktype: 'internal'
              }
            },
            targetItem: null
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Admin/ManageUsers',
          name: 'ManageUsers',
          id: 'DD89F103024751E59965E8AFEFAF99CC',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/manage-users',
                text: 'Manage Users',
                url: '/manage-users',
                linktype: 'internal'
              }
            },
            targetItem: null
          }
        }]
      }, {
        path: '/sitecore/content/internet/Content/SecondaryNavigation/Support',
        name: 'Support',
        id: '6B663A9BC0045A03A255BED1502AAED2',
        template: { name: 'Navigation-Section' },
        title: { value: 'Support' },
        children: [{
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Support/DriverResources',
          name: 'DriverResources',
          id: '547787C4F70F509C977188F21D36C185',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: 'http://www.microsoft.com',
                text: 'Driver Resources',
                url: 'www.microsoft.com',
                linktype: 'external'
              }
            },
            targetItem: null
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Support/Resources',
          name: 'Resources',
          id: 'D8B86951E23D59F796D65160A0C441F7',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: 'http://www.microsoft.com',
                text: 'Resources',
                url: 'www.microsoft.com',
                linktype: 'external'
              }
            },
            targetItem: null
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Support/ResourcesGroup',
          name: 'ResourcesGroup',
          id: 'B28DFA18CF8154ECAA7DC1FD237B7B25',
          template: { name: 'Navigation-Group' },
          children: [{
            path: '/sitecore/content/internet/Content/SecondaryNavigation/Support/ResourcesGroup/Certificates',
            name: 'Certificates',
            id: 'B9F4EB3D53F25836BA3185239FCEC115',
            template: { name: 'Navigation-Link' },
            link: {
              jss: {
                value: {
                  href: '/test components',
                  text: 'Certificates',
                  url: '/test components',
                  linktype: 'internal'
                }
              },
              targetItem: null
            }
          }, {
            path: '/sitecore/content/internet/Content/SecondaryNavigation/Support/ResourcesGroup/Forms',
            name: 'Forms',
            id: '07B92119FC8C5C0CB4E3C9497BFB9756',
            template: { name: 'Navigation-Link' },
            link: {
              jss: {
                value: {
                  href: 'http://www.microsoft.com',
                  text: 'Forms',
                  url: 'www.microsoft.com',
                  linktype: 'external'
                }
              },
              targetItem: null
            }
          }, {
            path: '/sitecore/content/internet/Content/SecondaryNavigation/Support/ResourcesGroup/Guides',
            name: 'Guides',
            id: 'CFF5695C4F16591684BD4AD71D9CA942',
            template: { name: 'Navigation-Link' },
            link: {
              jss: {
                value: {
                  href: 'http://www.microsoft.com',
                  text: 'Guides',
                  url: 'www.microsoft.com',
                  linktype: 'external'
                }
              },
              targetItem: null
            }
          }]
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Support/ServiceUpdates',
          name: 'ServiceUpdates',
          id: 'DE690FD00ABA59D59C329E2F9BA77968',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: 'http://www.microsoft.com',
                text: 'Service Updates',
                url: 'www.microsoft.com',
                linktype: 'external'
              }
            },
            targetItem: null
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Support/SubmitClaim',
          name: 'SubmitClaim',
          id: '06AEA9DE7A965BF6B075B96A696DCA36',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: '/manage-claims',
                text: 'Submit Claim',
                linktype: 'internal',
                id: '{4216DAE8-ECE5-5E74-8D2A-831EFB400A55}'
              }
            },
            targetItem: { id: '4216DAE8ECE55E748D2A831EFB400A55' }
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Support/Technology',
          name: 'Technology',
          id: '89A0940081CF50DD84A3C77CCD478C55',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: 'http://www.microsoft.com',
                text: 'Technology',
                url: 'www.microsoft.com',
                linktype: 'external'
              }
            },
            targetItem: null
          }
        }, {
          path: '/sitecore/content/internet/Content/SecondaryNavigation/Support/Tutorials',
          name: 'Tutorials',
          id: '8A0E9F1E6D315850B5B53C14C1E79BA6',
          template: { name: 'Navigation-Link' },
          link: {
            jss: {
              value: {
                href: 'http://www.microsoft.com',
                text: 'Tutorials',
                url: 'www.microsoft.com',
                linktype: 'external'
              }
            },
            targetItem: null
          }
        }]
      }, {
        path: '/sitecore/content/internet/Content/SecondaryNavigation/Logout',
        name: 'Logout',
        id: '88F539D5607A502FBA754D16C42B12EF',
        template: { name: 'Navigation-Link' },
        link: {
          jss: {
            value: {
              href: '/logout',
              text: 'Logout',
              linktype: 'internal',
              id: '{CC7583F1-F0BA-5A82-BF4C-BEE0FF5E28D6}'
            }
          },
          targetItem: { id: 'CC7583F1F0BA5A82BF4CBEE0FF5E28D6' }
        }
      }]
    }
  }
};
export default mockSecNav;

