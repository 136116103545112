import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';

const submitApplicationForm = (data) => {
  // TODO: change to loop which will check if value field exists and substitute
  //  (will check utilities folder later)
  if (data.thirdPartyInvoices) data.thirdPartyInvoices = data.thirdPartyInvoices.value;
  if (data.gstExempt) data.gstExempt = data.gstExempt.value;
  if (data.isBillingAccount) data.isBillingAccount = data.isBillingAccount.value;
  data.typeOfShipping = (data.typeOfShipping) ? data.typeOfShipping.filter((shipping) => shipping) : '';
  data.typeOfService = (data.typeOfService) ? data.typeOfService.filter((service) => service) : '';
  data.ownerInformation = data.ownerInformation.filter((ownerInfo) => ownerInfo);
  data.ownerName = data.ownerInformation[0] && data.ownerInformation[0].ownerName;
  data.ownerTitle = data.ownerInformation[0] && data.ownerInformation[0].ownerTitle;
  data.ownerName1 = data.ownerInformation[1] && data.ownerInformation[1].ownerName;
  data.ownerTitle1 = data.ownerInformation[1] && data.ownerInformation[1].ownerTitle;
  data.ownerName2 = data.ownerInformation[2] && data.ownerInformation[2].ownerName;
  data.ownerTitle2 = data.ownerInformation[2] && data.ownerInformation[2].ownerTitle;
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.createAccountApi}`, data);
};
export default submitApplicationForm;
