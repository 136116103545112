import React, { Fragment } from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import {
  reduxForm, Field, change, getFormValues
} from 'redux-form';
import {
  MDBDropdown
} from 'mdbreact';
import Modal from 'react-modal';
import { routes } from '../../utils/constants';
import Column from '../ReactComponent/Column';
import RenderField from '../ReactComponent/RenderField';
import Row from '../ReactComponent/Row';
import SearchWithFilter from '../ReactComponent/SearchWithFilter';
import SortableTable from '../ReactComponent/SortableTable';
import SideBar from '../ReactComponent/SideBar';
import { H2 } from '../../theme/typography';
import Actions from '../../assets/icons/Actions.svg';
import Filter from '../../assets/icons/Filter.svg';
import FilterOrange from '../../assets/icons/FilterOrange.svg';
import EditWhite from '../../assets/icons/EditWhite.svg';
import DeleteWhite from '../../assets/icons/DeleteWhite.svg';
import CreateAShipmentToWhite from '../../assets/icons/CreateAShipmentToWhite.svg';
import CreateAShipmentFromWhite from '../../assets/icons/CreateAShipmentFromWhite.svg';
import CreateAQuoteToWhite from '../../assets/icons/CreateAQuoteToWhite.svg';
import CreateAQuoteFromWhite from '../../assets/icons/CreateAQuoteFromWhite.svg';
import RequestPickupWhite from '../../assets/icons/RequestPickupWhite.svg';
import CreateAShipmentToGrey from '../../assets/icons/CreateAShipmentToGrey.svg';
import CreateAShipmentFromGrey from '../../assets/icons/CreateAShipmentFromGrey.svg';
import CreateAQuoteToGrey from '../../assets/icons/CreateAQuoteToGrey.svg';
import CreateAQuoteFromGrey from '../../assets/icons/CreateAQuoteFromGrey.svg';
import RequestPickupGrey from '../../assets/icons/RequestPickupGrey.svg';
import {
  FilterContainer,
  Icon,
  PageDescription,
  ModalTitle,
  Close,
  ModalBody,
  ButtonDiv,
  FilterIcon,
  PageTitle,
  CategoryTitle,
  ButtonGroup,
  SuccessDeleteMessage
} from './css';
import { Icon as CommonIcon } from '../ReactComponent/Icon';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import putReduxAllContacts from './actions';
import {
  homeUrlNoTrailingSlash,
  openInNewTab
} from '../../utils/func';
import { getCompaniesList } from '../../services/companies';
import { getCompanyContacts, deleteContactByIds } from '../../services/contacts';
import putReduxAllCompanies from '../ManageCompanies/actions';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import SecondaryButton from '../ReactComponent/SecondaryButton';
import NoDataDecorator from '../ReactComponent/SortableTable/NoDataDecorator';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import { AddressTypes } from '../../utils/enums';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';

const formName = 'ManageContacts';

const Dropdown = ({
  // eslint-disable-next-line no-unused-vars
  openModal, contactId, contactNickname, contactType
}) => (
  <>
    <MDBDropdown className="dropdown">
      <MDBDropdownToggle className="border-0">
        <CommonIcon iconSrc={Actions} />
      </MDBDropdownToggle>
      <StyledMDBDropdownMenu className="text-white bg-dark" basic>
        <DropdownItem onClick={() => {
          window.location.href = (`${routes.createContact}?contactId=${contactId}`);
          return window.location.href;
        }}>
          <DropdownItemIcon iconSrc={EditWhite} />
          {t('EditContact')}
        </DropdownItem>
        <DropdownItem
        disabled={contactType === AddressTypes.SHIPPER}
        onClick={() => {
          window.location.href = (`${routes.createQuote}?${AddressTypes.CONSIGNEE}=${contactId}`);
          return window.location.href;
        }}>
          <DropdownItemIcon iconSrc={contactType === AddressTypes.SHIPPER ? CreateAQuoteToGrey : CreateAQuoteToWhite} />
          {t('RequestAQuote')} {`(${t('To')})`}
        </DropdownItem>
        <DropdownItem
        disabled={contactType === AddressTypes.CONSIGNEE}
        onClick={() => {
          window.location.href = (`${routes.createQuote}?${AddressTypes.SHIPPER}=${contactId}`);
          return window.location.href;
        }}>
          <DropdownItemIcon iconSrc={contactType === AddressTypes.CONSIGNEE ? CreateAQuoteFromGrey : CreateAQuoteFromWhite} />
          {t('RequestAQuote')} {`(${t('From')})`}
        </DropdownItem>
        <DropdownItem
        disabled={contactType === AddressTypes.SHIPPER}
        onClick={() => {
          window.location.href = (`${routes.createShipment}?${AddressTypes.CONSIGNEE}=${contactId}`);
          return window.location.href;
        }}>
          <DropdownItemIcon iconSrc={contactType === AddressTypes.SHIPPER ? CreateAShipmentToGrey : CreateAShipmentToWhite} />
          {t('RequestAShipment')} {`(${t('To')})`}
        </DropdownItem>
        <DropdownItem
        disabled={contactType === AddressTypes.CONSIGNEE}
        onClick={() => {
          window.location.href = (`${routes.createShipment}?${AddressTypes.SHIPPER}=${contactId}`);
          return window.location.href;
        }}>
          <DropdownItemIcon iconSrc={contactType === AddressTypes.CONSIGNEE ? CreateAShipmentFromGrey : CreateAShipmentFromWhite} />
          {t('RequestAShipment')} {`(${t('From')})`}
        </DropdownItem>
        <DropdownItem
        disabled={contactType === AddressTypes.CONSIGNEE}
        onClick={() => {
          window.location.href = (`${routes.createPickup}?${AddressTypes.SHIPPER}=${contactId}`);
          return window.location.href;
        }}>
          <DropdownItemIcon iconSrc={contactType === AddressTypes.CONSIGNEE ? RequestPickupGrey : RequestPickupWhite} />
          {t('RequestAPickup')} {`(${t('From')})`}
        </DropdownItem>
        <DropdownItem onClick={() => openModal(contactId)}>
          <DropdownItemIcon iconSrc={DeleteWhite} />
          {t('DeleteContact')}
        </DropdownItem>
      </StyledMDBDropdownMenu>
    </MDBDropdown>
  </>
);

// eslint-disable-next-line react/no-multi-comp
export const ModalContent = ({ closeModal, handleDelete }) => (
  <>
    <ModalTitle>{t('DeleteContact')}</ModalTitle>
    {t('DeleteContactPrompt')}
    <ButtonDiv>
      <SecondaryButton
        onClick={() => closeModal()}
        className="active min-width"
      >
        {t('Cancel')}
      </SecondaryButton>
      <PrimaryButton
        onClick={() => handleDelete()}
        className="active min-width"
      >
        {t('RemoveContact')}
      </PrimaryButton>
    </ButtonDiv>
  </>
);

// eslint-disable-next-line react/no-multi-comp
class ManageContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          id: 1,
          field: 'check',
          sort: false,
          selected: true,
          default: true
        },
        {
          id: 2,
          label: 'Action',
          field: 'actions',
          sort: false,
          selected: true,
          default: true
        },
        {
          id: 3,
          label: 'Nickname',
          field: 'nickname',
          sort: true,
          selected: true,
          default: true
        },
        {
          id: 4,
          label: 'FullName',
          field: 'fullName',
          sort: true,
          selected: true,
          default: true
        },
        {
          id: 5,
          label: 'Company',
          field: 'company',
          sort: true,
          selected: true,
          default: true
        },
        {
          id: 6,
          label: 'lblContactType',
          field: 'contactType',
          sort: true,
          selected: true,
          default: true
        },
        {
          id: 7,
          label: 'MoreDetails',
          field: 'moreDetails',
          sort: false,
          selected: false,
          default: false
        }
      ],
      companies: [],
      companyNames: [],
      modalIsOpen: false,
      filterOpen: false,
      deleteSuccess: false,
      success: 0,
      fail: 0,
      contactTypes: []
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.fetchCompaniesAssociatedToUser();
    }, 200);
  }

  fetchCompaniesAssociatedToUser = async () => {
    // eslint-disable-next-line no-shadow
    const { division, userId, putReduxAllCompanies } = this.props;
    const { data: companies } = await getCompaniesList(division, userId);
    if (companies) {
      putReduxAllCompanies(companies);
      const companyNames = companies.map((company) => company.companyName);
      this.setState({ companies, companyNames });
      this.handleCompanySelect(companies[0].companyName);
    }
  };

  getContactTypes = () => {
    const { allContacts, dispatch } = this.props;
    const uniqueContactTypes = allContacts.map((c) => c.contactType).filter((value, index, self) => (self.indexOf(value) === index));
    this.setState({ contactTypes: uniqueContactTypes });
    dispatch(change(formName, 'filterContactType', uniqueContactTypes));
  };

  handleCompanySelect = async (newValue) => {
    // eslint-disable-next-line no-shadow
    const { dispatch, putReduxAllContacts } = this.props;
    const { companies } = this.state;

    dispatch(change(formName, 'selectedCompany', newValue));

    if (companies) {
      const selectedCompany = companies.filter(
        (company) => company.companyName === newValue
      );
      const { companyId } = selectedCompany[0];
      const { companyName } = selectedCompany[0];
      const { data: companyContacts } = await getCompanyContacts(companyId);

      if (companyContacts) {
        const convertedContactData = this.convertDataFormat(
          companyContacts,
          companyName
        );
        putReduxAllContacts(convertedContactData);
        this.setState({ data: convertedContactData });
        this.getContactTypes();
      }
    }
  };

  handleImportExportContact = (e) => {
    e.preventDefault();
    const createImportExportURL = `${homeUrlNoTrailingSlash()}${routes.importExport}`;
    openInNewTab(createImportExportURL);
  };

  handleCreateContact = (e) => {
    e.preventDefault();
    const createContactURL = `${homeUrlNoTrailingSlash()}${routes.createContact}`;
    openInNewTab(createContactURL);
  };

  renderHeader = ({ heading, description }) => (
      <Row style={{ margin: '45px 0' }}>
        <Column $tablet={8} $mobile={12}>
          <div>
            <H2>{heading}</H2>
            <PageDescription>{description}</PageDescription>
          </div>
        </Column>
        <Column $tablet={4} $mobile={12} style={{ textAlign: 'end' }}>
          <PrimaryButton
            className="active"
            iconRightArrow
            style={{ margin: '16px 0' }}
            onClick={(e) => this.handleImportExportContact(e)}>
            {t('btnImportExport')}
          </PrimaryButton>
          <PrimaryButton
            className="active"
            iconRightArrow
            style={{ margin: '16px 0' }}
            onClick={(e) => this.handleCreateContact(e)}>
            {t('AddNewContact')}
          </PrimaryButton>
        </Column>
      </Row>
  );

  handleDelete = async (contactId) => {
    const { formData: { checked, selectedCompany }, allContacts, dispatch } = this.props;
    this.openModal(contactId);
    const contactIds = contactId || (checked ? allContacts.filter((c, index) => {
      const checkbox = checked[index];
      return checkbox && checkbox[1];
    }).map((c) => c.contactId) : []);
    deleteContactByIds(contactIds)
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          this.handleCompanySelect(selectedCompany);
          dispatch(change(formName, 'checked', []));
          dispatch(change(formName, 'checkAll', ['']));
          const successCount = data.filter((c) => c.statusCode === 200).length;
          const failCount = data.length - successCount;
          this.setState({ deleteSuccess: true, success: successCount, fail: failCount }, () => {
            setTimeout(() => { this.setState({ deleteSuccess: false }); }, 10000);
          });
        }
      });
    this.closeModal();
  };

  convertDataFormat = (companyContacts, companyName) => companyContacts.map((each, index) => ({
    check: (
        <Field
          name={`checked[${index}]`}
          type="checkboxes"
          multiple
          data={[' ']}
          component={RenderField}
        />
    ),
    actions: (
        <Dropdown
        openModal={(contactId) => this.openModal(contactId)}
        index={index}
        contactType={each.contactType.toLowerCase()}
        contactId={each.contactId}
        contactNickname={each.nickname} />
    ),
    nickname: each.nickname || 'N/A',
    fullName: each.contactName || 'N/A',
    company: each.companyName || companyName || 'N/A',
    contactType: each.contactType || 'N/A',
    moreDetails: <>
        <Row>
          {each.email}
        </Row>
        <Row>
          {`
          ${each.address1}${each.address2 ? (` ${each.address2}`) : ''}, ${each.city}, ${each.provinceCode} ${each.postalCode}, ${each.countryCode}
          `}
        </Row>
      </>,
    contactId: each.contactId,
    email: each.email,
    address1: each.address1,
    address2: each.address2,
    city: each.city,
    provinceCode: each.provinceCode,
    postalCode: each.postalCode,
    countryCode: each.countryCode
  }));

  openModal = (contactId) => {
    this.setState({
      modalIsOpen: true,
      contactToDelete: contactId
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      contactToDelete: null
    });
  };

  toggleFilter = () => {
    const { filterOpen } = this.state;
    this.setState({ filterOpen: !filterOpen });
  };

  applyFrontFilters = () => {
    const { dispatch, allContacts, formData: { filterContactType = [] } } = this.props;
    dispatch(change(formName, 'searchByKey', ''));
    let filteredData = [];
    if (filterContactType) {
      filterContactType.forEach((type) => {
        filteredData = filteredData.concat(allContacts.filter((s) => s.contactType === type));
        filteredData = filteredData.filter((value, index, self) => (self.indexOf(value) === index));
      });
    }
    this.setState({ data: filteredData });
  };

  handleSubmitFilter = () => {
    this.applyFrontFilters();
    this.toggleFilter();
  };

  resetFilters = () => {
    const { dispatch } = this.props;
    const { contactTypes } = this.state;
    dispatch(change(formName, 'filterContactType', contactTypes));
    this.setState({ data: [] }, () => this.applyFrontFilters());
  };

  getSuggestions = (value) => {
    const { allContacts } = this.props;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const filteredData =
      inputLength === 0
        ? allContacts
        : allContacts.filter(
          (item) => {
            const serializedrow =
            `${item.nickname} ${item.fullName} ${item.company} ${item.contactType} ${item.email} ${item.address1} ${item.address2
              ? item.address2 : ''} ${item.city} ${item.provinceCode} ${item.postalCode} ${item.countryCode}`.toLowerCase();
            return serializedrow.indexOf(inputValue) > -1;
          });
    this.setState({ data: filteredData });
  };

  render() {
    const {
      rendering: {
        fields: { heading, description }
      },
      formData,
      dispatch
    } = this.props;
    const {
      data,
      modalIsOpen,
      companyNames = [],
      filterOpen,
      deleteSuccess,
      success,
      fail,
      contactToDelete,
      columns,
      contactTypes,
    } = this.state;
    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          style={ModalBoxStyle}
          contentLabel="Modal">
          <Close onClick={() => this.closeModal()} />
          <ModalBody className="justify-content-center">
            <ModalContent
              closeModal={this.closeModal}
              handleDelete={() => this.handleDelete(contactToDelete)}
            />
          </ModalBody>
        </Modal>
        {this.renderHeader({
          heading: heading.value,
          description: description.value
        })}

        <FilterContainer>
          <div className="row justify-content-md-center">
            <Column $tablet={9}>
              <Field
                name="selectedCompany"
                type="dropdown"
                label={t('Companies')}
                data={[...companyNames]}
                component={RenderField}
                onChange={(event, newValue) =>
                  this.handleCompanySelect(event, newValue)}
              />
              <SearchWithFilter
                label={t('SearchUserText')}
                getSuggestions={this.getSuggestions}
              />
            </Column>
          </div>
          <Row>
            <FilterIcon className="col-md-3 offset-1" onClick={this.toggleFilter}>
              <Icon src={filterOpen ? Filter : FilterOrange} alt="Filter" />
              {t('ApplyFilters')}
            </FilterIcon>
          </Row>
          {deleteSuccess &&
            <Row className="justify-content-md-center">
              <Column $tablet={9}>
                <SuccessDeleteMessage>
                  {success} {t('SuccessDeleteMsg')}, {fail} {t('FailDeleteMsg')}
                </SuccessDeleteMessage>
              </Column>
            </Row>}
        </FilterContainer>
        {filterOpen && <SideBar close={this.toggleFilter}>
          <form>
            <PageTitle>{t('ApplyFilters')}</PageTitle>
            <>
              <CategoryTitle>{t('FilterByContactType')}</CategoryTitle>
              <Field
                name="filterContactType"
                type="checkboxes"
                component={RenderField}
                multiple
                data={contactTypes}
              />
            </>
            <ButtonGroup>
              <SecondaryButton type="button" onClick={() => this.resetFilters()}>
                <span className="icon"></span>
                {t('ResetFilters')}
              </SecondaryButton>
              <PrimaryButton type="button" className="submit" onClick={() => this.handleSubmitFilter()}>
                <span className="icon"></span>
                {t('ApplyFilters')}
              </PrimaryButton>
            </ButtonGroup>
          </form>
        </SideBar>}
        {formData && formData.checked && formData.checked.filter((c) => c[1]).length !== 0 &&
          <Row>
            <Column $tablet={4} $mobile={12} className="offset-md-8">
              <SecondaryButton
                className="active"
                iconRightArrow
                style={{ margin: '16px 0' }}
                onClick={() => this.openModal()}
              >
                {t('RemoveContacts')}
              </SecondaryButton>
            </Column>
          </Row>}
        <NoDataDecorator
          component={SortableTable}
          data={data}
          columns={columns}
          formName={formName}
          dispatch={dispatch}
          className="mb-5"
        />
      </>
    );
  }
}

ManageContacts = reduxForm({
  form: formName // a unique identifier for this form
})(ManageContacts);

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  division: state.profile.division,
  userId: state.profile.userId,
  allContacts: state.user.allContacts,
  selectedCompany:
    state.form.formName &&
    state.form.formName.values &&
    state.form.formName.values.selectedCompany
});

const mdtp = (dispatch) => ({
  putReduxAllContacts: (contactsList) => dispatch(putReduxAllContacts(contactsList)),
  putReduxAllCompanies: (companies) => dispatch(putReduxAllCompanies(companies))
});

export default connect(
  mstp,
  mdtp
)(ManageContacts);
