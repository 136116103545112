import React from 'react';
import { t } from 'i18next';
import AlertModal from '../../ReactComponent/Modal/AlertModal';
import { P } from '../../../theme/typographySitecore';
import {
  HeaderContainer, SecondButton, OkayButton, ButtonGroup,
} from './css';

const ForgotPasswordErrorModal = ({
  isOpen,
  onClose,
  parentFields,
  openSetForgotPasswordModal,
}) => {
  const header = () => (
    <HeaderContainer>
      <div>{parentFields.forgotPasswordErrorModalHeader.value}</div>
    </HeaderContainer>
  );

  const content = () => (
    <P field={parentFields.forgotPasswordErrorModalSummary}/>
  );

  const actions = () => (
    <ButtonGroup>
      <SecondButton
        type="button"
        smallWidth
        onClick={openSetForgotPasswordModal}
      >
        {t('GoToForgotPassword')}
      </SecondButton>
      <OkayButton className="active" style={{ marginLeft: '5px' }} onClick={onClose}>
        {t('Okay')}
      </OkayButton>
    </ButtonGroup>
  );

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      content={content}
      actions={actions}
    />
  );
};
export default ForgotPasswordErrorModal;
