import React, { PureComponent } from 'react';
import { t } from 'i18next';
import { FileUploadLabel, FileUploadContainer, FileUpload } from './css';
import Plus from '../../../assets/icons/Plus.svg';
import RemoveOrange from '../../../assets/icons/RemoveOrange.svg';
import { P } from '../../../theme/typography';
import fileTypes from '../../../utils/acceptedFileTypes';
import AlertModal from '../Modal/AlertModal';
import { ActionButton } from '../Modal/css';
import { runningOnBrowser } from '../../../utils/func';

const MB_MULTIPLIER = 1024 * 1024;
const MAX_FILE_SIZE = 21;
const TotalFileSize = [];

export default class FieldFileInput extends PureComponent {
  constructor(props) {
    super(props);
    this.uploadField = React.createRef();
    this.state = {
      file: '',
      warning: ''
    };
  }

  getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    };
    reader.onerror = (error) => {
      console.error('Error: ', error);
    };
  };

  onFileChange = (e) => {
    const { input: { onChange } } = this.props;
    const { name } = e.target.files[0];
    const file = e.target.files[0];
    TotalFileSize.push(file.size);
    const CalculatedFileSize = TotalFileSize.reduce((prevFileSize, currentFileSize) =>
      prevFileSize + currentFileSize, 0);
    if (CalculatedFileSize <= MAX_FILE_SIZE * MB_MULTIPLIER) {
      this.getBase64(file, async (result) => {
        await this.setState({ file: result.split('base64,')[1] });
        onChange({
          name,
          file: this.state.file
        });
      });
      e.target.value = '';
    } else {
      TotalFileSize.pop();
      if (runningOnBrowser()) {
        if (window.location.href.includes('dedicated')) {
          this.setState({ warning: t('DedicatedMaxFileSizeReached', { size: `${MAX_FILE_SIZE}` }) });
        } else {
          this.setState({ warning: t('MaxFileSizeReached', { size: `${MAX_FILE_SIZE}` }) });
        }
      }
    }
  };

  triggerClick = () => {
    this.uploadField.current.click();
  };

  removeFile = (e) => {
    const { input: { onChange } } = this.props;
    TotalFileSize.pop(e.size);
    onChange('');
  };

  closeWarningModal = () => this.setState({ warning: '' });

  render() {
    const { warning } = this.state;
    const {
      label,
      attachButton,
      input: { value },
      className
    } = this.props;
    return (
      <FileUploadContainer className={className}>
        {label && (
          <P className="label">{label}</P>
        )}
        <FileUpload>
          <FileUploadLabel onClick={this.triggerClick}>{value ? value.name : attachButton}</FileUploadLabel>
          <input
            ref={this.uploadField}
            type="file"
            onChange={this.onFileChange}
            className="hidden-button"
            accept={fileTypes}
          />
          {value ? (
            <img src={RemoveOrange} onClick={this.removeFile} alt="remove part file" />
          ) : (
            <img src={Plus} onClick={this.triggerClick} alt="Upload part file" />
          )}
        </FileUpload>
        {warning && <AlertModal
          isOpen={!!warning}
          onClose={this.closeWarningModal}
          header={() => t('Warning')}
          content={() => <div style={{
            textAlign: 'left',
            lineHeight: '1.4em',
            marginBottom: '12px',
            letterSpacing: '0.005em'
          }}>
            {warning}
          </div>}
          actions={() => <ActionButton className="active" iconRightArrow onClick={this.closeWarningModal}>{t('Ok')}</ActionButton>}
          maximizeZIndex
        />}
      </FileUploadContainer>
    );
  }
}
