import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { t } from 'i18next';
import PasswordGroup, { PASSWORD_GROUP_FORM_FIELDS } from '../ReactComponent/PasswordGroup';
import Column from '../ReactComponent/Column';
import { P } from '../../theme/typographySitecore';
import { ButtonContainer, HeaderContainer, HeaderTitle } from './css';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import { passwordReset, validatePasswordResetToken } from '../../services/users';
import InvalidTokenModal from './InvalidTokenModal';
import PasswordResetSuccessModal from './PasswordResetSuccessModal';

const formName = 'PasswordResetForm';
const URL_PARAMS = {
  token: 'token',
  email: 'email'
};

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordResetToken: null,
      userEmail: null,
      invalidTokenModalIsOpen: false,
      passwordResetSuccessModalIsOpen: false,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get(URL_PARAMS.token);
    const userEmail = urlParams.get(URL_PARAMS.email);

    if (token && userEmail) {
      validatePasswordResetToken({
        token,
        userEmail
      })
        .then((res) => {
          if (res.data === false) { // Invalid token...
            this.setState({
              invalidTokenModalIsOpen: true
            });
            return;
          }

          // Valid token...
          this.setState({
            passwordResetToken: token,
            userEmail
          });
        })
        .catch(() => {
          this.setState({
            invalidTokenModalIsOpen: true
          });
        });
    }
  }

  handleSubmit = () => {
    const { formData } = this.props;
    const {
      passwordResetToken,
      userEmail
    } = this.state;
    const newPassword = formData[PASSWORD_GROUP_FORM_FIELDS.password];

    passwordReset({
      token: passwordResetToken,
      userEmail,
      newPassword
    })
      .then(() => {
        this.setState({
          passwordResetSuccessModalIsOpen: true
        });
      })
      .catch(() => {
        this.setState({
          invalidTokenModalIsOpen: true
        });
      });
  };

  renderHeader = () => {
    const { fields } = this.props;
    return (
      <HeaderContainer>
        <Column $desktop={8}>
          <HeaderTitle field={fields.headerTitle}/>
          <P field={fields.headerSummary}/>
        </Column>
      </HeaderContainer>
    );
  };

  renderForm = () => {
    const {
      fields,
      pristine,
      invalid
    } = this.props;

    return (
      <form>
        <PasswordGroup passwordImage={fields.passwordImage}/>

        <ButtonContainer>
          <PrimaryButton
            type="button"
            smallWidth
            iconRightArrow
            disabled={pristine || invalid}
            onClick={() => this.handleSubmit()}
          >
            {t('Submit')}
          </PrimaryButton>
        </ButtonContainer>
      </form>
    );
  };

  render() {
    const { fields } = this.props;
    const {
      invalidTokenModalIsOpen,
      passwordResetSuccessModalIsOpen
    } = this.state;

    return (
      <>
        <InvalidTokenModal
          isOpen={invalidTokenModalIsOpen}
          parentFields={fields}
        />
        <PasswordResetSuccessModal
          isOpen={passwordResetSuccessModalIsOpen}
          parentFields={fields}
        />
        {this.renderHeader()}
        {this.renderForm()}
      </>
    );
  }
}

PasswordReset.propTypes = {
  formData: PropTypes.object,
  fields: PropTypes.object,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool
};

PasswordReset = reduxForm({
  form: formName
})(PasswordReset);

const mapStateToProps = (state) => ({
  formData: getFormValues(formName)(state)
});

export default connect(mapStateToProps, {})(PasswordReset);
