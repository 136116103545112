/**
 * Google Analytics
 *
 * @link: https://www.npmjs.com/package/react-ga
 *
 * To turn analytics on:
 *  - define REACT_APP_GOOGLE_ANALYTICS env variable with analytics key for node.js where build
 *  command is invoked
 *    @example: REACT_APP_GOOGLE_ANALYTICS=UA-XXXXXXXX-X
 *  - or for local dev just add in a root folder .env.development file with
 *  REACT_APP_GOOGLE_ANALYTICS=UA-XXXXXXXX-X
 *
 * To use analytics, read react-ga docs and use: ReactGA.pageview, ReactGA.event, ReactGA.modalview
 *
 * @example:
 * ReactGA.event({
 *      category: 'This is my category',
 *      action: 'This is my action'
 *  });
 */

import ReactGA from 'react-ga';

/**
 * Initialize Google Analytics
 * @param {string} key - google API key
 */
const initGoogleAnalytics = (key) => {
  // eslint-disable-next-line no-undef
  const keyValue = key === 'googleAnalyticsKey' ? process.env.REACT_APP_GOOGLE_ANALYTICS : key;
  if (keyValue) {
    ReactGA.initialize(keyValue);
    if (window) ReactGA.pageview(window.location.pathname + window.location.search);
  }
};
export default initGoogleAnalytics;
