import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import AlertModal from '../../ReactComponent/Modal/AlertModal';
import { P } from '../../../theme/typographySitecore';
import RenderField from '../../ReactComponent/RenderField';
import { isEmail, isRequired } from '../../../utils/validator';
import {
  ButtonContainer,
  CancelButton,
  HeaderContainer,
  HeaderSummary
} from './css';
import { requestPasswordReset } from '../../../services/users';
import PrimaryButton from '../../ReactComponent/PrimaryButton';

const formName = 'forgotPasswordModalForm';
const ForgotPasswordModalFormFields = {
  UserEmail: 'userEmail'
};

const ForgotPasswordModal = ({
  isOpen,
  onClose,
  onSuccess,
  parentFields,
  onFailure
}) => {
  const handleSubmit = (userEmail) => {
    requestPasswordReset(userEmail)
      .then((res) => {
        if (res.status === 200) {
          onSuccess();
        }
      }).catch(() => {
        onFailure();
      });
  };

  const header = () => (
    <HeaderContainer>
      <div>{t('ForgotPassword')}</div>
      <HeaderSummary field={parentFields.forgotPasswordModalSummary}/>
    </HeaderContainer>
  );

  const footer = () => (
    <P field={parentFields.logInSupportLinkText}/>
  );

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      content={() => <ForgotPasswordModalForm onClose={onClose} handleSubmit={handleSubmit}/>}
      footer={footer}
    />
  );
};
export default ForgotPasswordModal;

// eslint-disable-next-line
let ForgotPasswordModalForm = ({
  invalid,
  pristine,
  onClose,
  handleSubmit,
  userEmail
}) => (
  <form>

    <Field
      name={ForgotPasswordModalFormFields.UserEmail}
      type="text"
      component={RenderField}
      label={t('EmailId')}
      required
      validate={[isRequired, isEmail]}
    />

    <ButtonContainer>
      <CancelButton
        type="button"
        smallWidth
        iconRightArrow
        onClick={onClose}>
        {t('Cancel')}
      </CancelButton>
      <PrimaryButton
        type="button"
        smallWidth
        iconRightArrow
        disabled={invalid || pristine}
        onClick={() => handleSubmit(userEmail)}>
        {t('Submit')}
      </PrimaryButton>
    </ButtonContainer>

  </form>
);

ForgotPasswordModalForm = reduxForm({
  form: formName,
})(ForgotPasswordModalForm);

const selector = formValueSelector(formName);

ForgotPasswordModalForm = connect(
  (state) => {
    const userEmail = selector(state, ForgotPasswordModalFormFields.UserEmail);
    return {
      userEmail,
    };
  },
)(ForgotPasswordModalForm);
