import { format } from 'date-fns';
import { fr, enCA } from 'date-fns/locale';
import { t } from 'i18next';
import { DeliveryDateTypes, Languages, AMPM } from './enums';
import { formatDisplayDate } from './func';
import { isIntInRange, padNumberWithZeros } from './numbers';

export const MAX_DATE_RANGE_DAYS = 60;

const MILLS_IN_A_DAY = 1000 * 3600 * 24; // 1000 mills * 3600 seconds * 24 hours

/**
 * Get difference in days, given two date strings, as an absolute number.
 * @param fromDateString
 * @param toDateString
 * @returns {number} positive number
 */

export const getAbsoluteDifferenceInDays = ({
  fromDateString,
  toDateString,
}) => Math.abs(getDifferenceInDays({
  fromDateString,
  toDateString
}));

/**
 * Where a valid range is a range where fromDate IS NOT after toDate / the difference
 * between fromDate and toDate is a positive number, true if date range is valid, false
 * otherwise.
 * @param fromDateString
 * @param toDateString
 * @returns {boolean}
 */
export const isValidDateRange = ({
  fromDateString,
  toDateString
}) => (getDifferenceInDays({
  fromDateString,
  toDateString,
}) >= 0);

/**
 * Get date string w/ potential decorator, given date string, and deliveryDateType string.
 * @param deliveryDate string
 * @param deliveryDateType string
 */
export const getDeliveryDate = ({
  deliveryDate = '',
  deliveryDateType
}) => {
  let newDeliveryDate = '';

  if (!deliveryDate) {
    return deliveryDate;
  }

  newDeliveryDate = formatDisplayDate(deliveryDate);

  if (!deliveryDateType) {
    return newDeliveryDate;
  }

  // Append appropriate decorator...
  const deliveryDateObject = new Date(deliveryDate);
  const formattedHours = addLeadingZeroToSecondsHoursOrMinutes(deliveryDateObject.getHours());
  const formattedMinutes = addLeadingZeroToSecondsHoursOrMinutes(deliveryDateObject.getMinutes());
  const deliveryTime = `${formattedHours}:${formattedMinutes}`;
  switch (deliveryDateType) {
    case DeliveryDateTypes.EstimatedDelivery:
      return `${newDeliveryDate} (${t('Estimated')})`;
    default:
      return `${newDeliveryDate} (${deliveryTime})`;
  }
};

/**
 * Get difference in days, of toDate - fromDate. Result can be negative.
 * @param fromDateString
 * @param toDateString
 * @returns {number} positive or negative number
 */
const getDifferenceInDays = ({
  fromDateString,
  toDateString
}) => {
  // Parse strings into Dates...
  const fromDate = new Date(fromDateString);
  const toDate = new Date(toDateString);

  // Find difference in mills...
  const timeDiff = toDate.getTime() - fromDate.getTime();

  // Convert mills into days...
  const daysDiff = timeDiff / MILLS_IN_A_DAY;

  return daysDiff;
};

/**
 * Given a string that represents seconds, minutes, or hours, return
 * that string with a leading zero, if it's a single digit.
 * @param secondsHoursOrMinutes string
 */
const addLeadingZeroToSecondsHoursOrMinutes = (secondsHoursOrMinutes) => (`0${secondsHoursOrMinutes}`).slice(-2);

export const getMomentByLanguage = (language, date) => {
  if (!language) {
    return format(date || new Date());
  }

  const localization = language === Languages.FR ? fr : enCA;
  return format(date || new Date(), 'MMMM d, yyyy', { locale: localization });
};

export const get24HourTime = ({
  hours,
  minutes,
  amPm
}) => {
  if (!isIntInRange(hours, 1, 12) || !isIntInRange(minutes, 0, 59) || !amPm) {
    return null;
  }

  return {
    hours: parseInt(hours, 10) + (amPm === AMPM.AM ? 0 : 12),
    minutes: parseInt(minutes, 10),
  };
};

export const get12HourTime = (isoDate) => {
  if (!isoDate) {
    return null;
  }

  const date = new Date(isoDate);
  const hours24 = date.getHours();
  const minutes = date.getMinutes();

  const amPm = hours24 > 11
    ? AMPM.PM
    : AMPM.AM;
  let hours = hours24 % 12;
  if (hours === 0) {
    hours = 12;
  }

  return new Time12Hours({
    hours,
    minutes,
    amPm,
  });
};

export const setDateTimeBy24HourTime = ({
  isoDate,
  hours,
  minutes
}) => {
  const date = new Date(isoDate);
  date.setHours(hours);
  date.setMinutes(minutes);
  const cleanDate = removeTimeZoneFromDate(date);
  return cleanDate;
};

export const toISOStringTillSeconds = (date) => {
  const isoString = date.toISOString();
  const indexOfPeriod = isoString.indexOf('.');
  if (indexOfPeriod < 0) return isoString;
  return isoString.substr(0, indexOfPeriod);
};

export const removeTimeZoneFromDate = (date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return (new Date(date.getTime() - userTimezoneOffset));
};

export const isValid12HourTime = (hours, minutes, amPm) => (get24HourTime({
  hours,
  minutes,
  amPm
}) !== null);

export const isIsoDateTime = (input) => {
  const iso = /^(\d{4})(?:-?W(\d+)(?:-?(\d+)D?)?|(?:-(\d+))?-(\d+))(?:[T ](\d+):(\d+)(?::(\d+)(?:\.(\d+))?)?)?(?:Z(-?\d*))?$/;
  return iso.test(input);
};

export function Time12Hours({
  hours,
  minutes,
  amPm
}) {
  Object.assign(this, {
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
    amPm
  });
  this.toTimeString = () => `${padNumberWithZeros(hours, 2)}:${padNumberWithZeros(minutes, 2)} ${amPm}`;
}

export const getEmpty12HoursTime = () => new Time12Hours(0, 0, AMPM.AM);

export const parse12HourTime = (time) => {
  if (!time) {
    return null;
  }

  const timeDayParts = time.split(' ');

  if (timeDayParts.length !== 2) {
    throw new Error('invalid time');
  }

  const amPm = timeDayParts[1].toUpperCase();
  if (!Object.values(AMPM).includes(amPm)) {
    throw new Error('invalid time');
  }

  const timeParts = timeDayParts[0].split(':');
  if (timeParts.length !== 2) {
    throw new Error('invalid time');
  }

  const hours = timeParts[0];
  if (!isIntInRange(hours, 1, 12)) {
    throw new Error('invalid time');
  }

  const minutes = timeParts[1];
  if (!isIntInRange(minutes, 0, 59)) {
    throw new Error('invalid time');
  }

  return new Time12Hours({
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
    amPm
  });
};
