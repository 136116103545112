/* eslint-disable */
import React from 'react';
import axios from 'axios';
import c from '../../temp/config';
import utils from '../../utils/utils';
import { endpoints } from '../../utils/constants';
import getLayoutResources from "../../services/layoutService";
import { getCurrentLocale } from "../../utils/func";

const STYLE = {
    wordbreak: {
        wordBreak: "break-word",
    },
};


class ShowProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customProperties: [],
            email: '',
        }
    }

    componentDidMount() {


        if (!utils.isDisconnected) {
            getLayoutResources({ sitecoreApiKey: c.sitecoreApiKey, locale: getCurrentLocale() })
                .then(route => {
                    if (route.status === 200 && route.data && route.data.sitecore.context.profileData) {
                        this.setState({
                            email: route.data.sitecore.context.profileData.basic_info.email,
                            customProperties: route.data.sitecore.context.profileData.customProperties
                        });
                    }
                });
        }
    }

    render() {

        return (utils.isDisconnected ? null : <div>
            <h3>Profile Info:</h3>
            <div>
                Email {this.state.email}
            </div>
            <div style={STYLE.wordbreak}>
                Custom Properties: {this.state.customProperties.map(function (item, i) {
                    return <div><strong>{item.key}</strong> {item.value}</div>
                })}
            </div>
        </div>);
    }
}

export default ShowProfile;