import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import { Selector } from './css';
import RenderField from '../../RenderField';
import { list } from './utils';

const UnitSelector = ({
  formName, dispatch, fieldName = 'unitType', disabled = false,
}) => {
  useEffect(() => {
    dispatch(change(formName, 'unitType', list[0]));
  }, []);
  return (
    <Selector disabled={disabled}>
      <Field
        name={fieldName}
        type="checkboxes"
        data={list}
        disabled={disabled}
        component={RenderField}
      />
    </Selector>
  );
};

UnitSelector.propTypes = {
  formName: PropTypes.string,
  dispatch: PropTypes.func,
  fieldName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default connect()(UnitSelector);
