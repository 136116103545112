import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';

export const fetchAllQuotesService = (url) => axios.get(axios.get(url));

export const getQuotesByDivision = ({
  division,
  numberOfDaysFromToday,
  accountNumbers
}) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.quotes}/${division}`, {
    params: {
      numberOfDaysFromToday,
    },
    headers: {
      accountNumbers,
    }
  });

export const postQuoteServiceLevels = (data) =>
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.serviceLevel}`, data);

export const postQuote = (data) =>
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.quotes}`, data);

export const getQuoteByDivisionAndQuoteNumber = (division, quoteNumber, USQuote) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.quotes}/${division}/${quoteNumber}${USQuote ? '/us' : ''}`);

export const getQuoteDocument = ({
  division,
  quoteNumber,
  language,
  USQuote,
}) =>
  axios.get(
    `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.quotes}/${division}/${quoteNumber}${USQuote ? '/us' : ''}/document`,
    {
      params: {
        language: language || '',
      }
    });

export const postQuoteToUSRates = (data) =>
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.quotes}/us-rate`, data);

export const postQuoteToUS = () =>
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.quotes}/us`);

export const apiGetQuotes = (division, accountNumbers, fromDate, toDate) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.quotes}/${division}`, {
    params: {
      fromDate,
      toDate,
    },
    headers: {
      accountNumbers,
    }
  });

export const apiGetSameDayQuotes = (division, accountNumbers, fromDate, toDate) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.quotes}/${division}`, {
    params: {
      fromDate,
      toDate,
    },
    headers: {
      accountNumbers,
    }
  });

export const apiGetFilteredQuotes = (division, accountNumbers, fromDate, toDate, quoteType) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.quotes}/${division}`, {
    params: {
      fromDate,
      toDate,
      quoteType,
    },
    headers: {
      accountNumbers,
    }
  });
