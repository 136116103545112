import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues, change } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import TransitTimeCalculatorImpl from './TransitTimeCalculatorImpl';
import { measurementValidate } from '../../utils/validator';
import ResetOrange from '../../assets/icons/ResetOrange.svg';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';
import { Divisions } from '../../utils/enums';
import {
  FormContainer,
  SecondaryButton,
  ButtonGroup,
  Icon,
  PageTitle,
  PrimaryButton,
} from './css';

import { fetchTransitTimeDetails } from './api';

import TransitTimeItemCard from './TransitTimeItemCard';

const FORM_FIELDS = {
  division: 'division'
};

const initialValues = {
  division: Divisions.Freight.name,
  measurementList: [{}]
};

class TransitTimeCalculatorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      completed: false
    };
  }

  componentDidMount() {
    const { isAuthenticated, dispatch, division } = this.props;
    if (isAuthenticated) {
      dispatch(change('TransitTimeCalculatorForm', FORM_FIELDS.division, division));
    }
  }

  scrollToTop = () => window.scrollTo(0, 0);

  reset = () => {
    const { dispatch } = this.props;
    this.scrollToTop();
    dispatch(change('TransitTimeCalculatorForm', 'divisonName', ''));
    dispatch(change('TransitTimeCalculatorForm', 'measurementList', [{}]));
    dispatch(change('TransitTimeCalculatorForm', 'originPostalCode', ''));
    dispatch(change('TransitTimeCalculatorForm', 'pickUpDate', ''));
    dispatch(change('TransitTimeCalculatorForm', 'originCity', ''));
    dispatch(change('TransitTimeCalculatorForm', 'originProvince', ''));
    this.setState({
      completed: false,
    });
  };

  updateState = (value) => this.setState({ data: { name: [value] } });

  handleCalulate = (e) => {
    const {
      valid,
      division,
      formData
    } = this.props;

    if (valid) {
      e.preventDefault();
      fetchTransitTimeDetails(formData, division).then((response) => {
        this.setState((prevState) => ({
          completed: true,
          data: {
            ...prevState,
            data: [response.data],
          },
        }));
      }, (error) => {
        console.error(error);
      });
    }
  };

  render() {
    const {
      formData,
      valid,
      dispatch,
      fields,
      division,
      isAuthenticated
    } = this.props;
    const {
      data,
      completed
    } = this.state;

    return (
      <>
        <FormContainer>
          <form>
            <TransitTimeCalculatorImpl
              formData={formData}
              dispatch={dispatch}
              fields={fields}
              division={division}
              isAuthenticated={isAuthenticated}
              clear={this.reset}/>
          </form>
          {completed ?
            <div>
              <PageTitle className="justified">
                {t('TransitTimeResults')}
              </PageTitle>
              <TransitTimeItemCard
                formName="TransitTimeCalculatorForm"
                transitTimeList={data}
                dispatch={dispatch}
                fieldValues={fields}
                division={division ? division : formData[FORM_FIELDS.division]}
              />
            </div>
            : ''}
        </FormContainer>
        <ButtonGroup>
          <SecondaryButton onClick={this.reset}>
            <Icon className="icon-right" src={ResetOrange} alt="back"/>
            {t('Reset')}
          </SecondaryButton>
          <PrimaryButton
            type="button"
            onClick={this.handleCalulate}
            className={`${valid ? 'active' : ''}`}>
            {t('Calculate')}
            <Icon className="icon-right" src={ArrowRightOrange} alt="next"/>
          </PrimaryButton>
        </ButtonGroup>

      </>
    );
  }
}

TransitTimeCalculatorForm.propTypes = {
  formData: PropTypes.object,
  dispatch: PropTypes.func,
  fields: PropTypes.object,
  valid: PropTypes.bool,
  division: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};

TransitTimeCalculatorForm = reduxForm({
  form: 'TransitTimeCalculatorForm',
  initialValues,
  validate: measurementValidate,
})(TransitTimeCalculatorForm);

const mstp = (state) => ({
  formData: getFormValues('TransitTimeCalculatorForm')(state),
  isAuthenticated: state.profile.isAuthenticated,
  division: state.profile.division,
  completed: Boolean,
});

export default connect(mstp)(TransitTimeCalculatorForm);
