import React from 'react';
import { t } from 'i18next';
import SummaryForm from '../../ReactComponent/FormSummaryBlock';
import Card from '../../ReactComponent/Card';
import TariffSurchargeSummaryBlock from '../../ReactComponent/TariffSurchargeSummaryBlock';
import { getMonthDayYear, formatMoney } from '../../../utils/func';
import {
  PageTitle,
  CategoryTitle,
  TextProperty
} from '../css';
import { H2, H3, P } from '../../../theme/typography';
import GreyBoxWithBorder from '../../_styledComponents/GreyBoxWithBorder';

const Step4 = ({
  formData = {},
  serviceLevelCode,
  step,
  editStepOne,
  editStepTwo,
  editStepThree,
  serviceLevelQuotes,
  putReduxServiceLevelCode,
  USQuote,
  quoteData = {},
  parentFields,
  billingAccounts
}) => {
  const {
    netCharge,
    fuelSurcharge,
    discountPercentage,
    minimumRate,
    minimumCharge,
    undiscountedCharge,
    discountAmount,
    customsProcessingFee,
    accessorialCharge,
    totalCharge,
    expectedDeliveryDate
  } = quoteData;
  const selectedServiceLevelInfo = serviceLevelQuotes.find((slq) => slq.serviceLevelCode === serviceLevelCode);

  const serviceLevelQuotesDataCopy = [...serviceLevelQuotes];
  const noSigData =
  serviceLevelQuotesDataCopy && serviceLevelQuotesDataCopy.length > 0
    ? serviceLevelQuotesDataCopy.filter((data) => data.serviceLevelCode === 'NS')
    : [];
  const serviceLevelQuotesData =
  serviceLevelQuotesDataCopy && serviceLevelQuotesDataCopy.length > 0
    ? serviceLevelQuotesDataCopy.filter((data) => data.serviceLevelCode !== 'NS')
    : [];
  if (noSigData && noSigData.length > 0) {
    serviceLevelQuotesData.unshift(noSigData[0]);
  }

  const protectedAccount = billingAccounts && billingAccounts.some((a) => a.accountNumber === formData.billTo && a.protected);
  if (step === 3) {
    return (
      <>
        <PageTitle>{parentFields.step4PageTitle && parentFields.step4PageTitle.value}</PageTitle>
        <CategoryTitle>{t('Summary')}</CategoryTitle>
        {USQuote ?
          <div className="row">
            <div className="col-sm-4">
              <GreyBoxWithBorder>
                <TextProperty>
                  <P className="title">{t('Service Level')}</P>
                  <H3>{t('General')}</H3>
                </TextProperty>
              </GreyBoxWithBorder>
              <GreyBoxWithBorder>
                <TextProperty>
                  <P className="title">
                    {t('Expected Delivery Date')}<br />
                    <span className="small">({t('ShippedTodayText')})</span>
                  </P>
                  <H3>{getMonthDayYear(expectedDeliveryDate)}</H3>
                </TextProperty>
              </GreyBoxWithBorder>
            </div>
            <div className="col-sm-8">
              <TariffSurchargeSummaryBlock tariffSurcharge={formData.tariffSurcharge} tablet={12} mobile={12} />
              <GreyBoxWithBorder>
                {(minimumCharge && totalCharge) ? (
                  <TextProperty>
                    <P className="title">
                      {t('Price')}
                    </P>
                    {undiscountedCharge ?
                      <H3 className="justified">
                        <span>{t('Undiscounted Charge')}</span> <span>{formatMoney(undiscountedCharge, protectedAccount)}</span>
                      </H3>
                      : <H3 className="justified"><span>{t('Minimum Charge')}</span> <span>{formatMoney(minimumCharge, protectedAccount)}</span></H3>}
                    <H3 className="justified">
                      <span>{`${discountPercentage}% ${t('Discount')}`}</span> <span>{formatMoney(discountAmount, protectedAccount)}</span>
                    </H3>
                    {netCharge ?
                    <H3 className="justified"><span>{t('Net Charge')}</span> <span>{formatMoney(netCharge, protectedAccount)}</span></H3>
                      : <H3 className="justified"><span>{t('Minimum Rate')}</span> <span>{formatMoney(minimumRate, protectedAccount)}</span></H3>}
                    <H3 className="justified"><span>{t('Fuel Surcharge')}</span> <span>{formatMoney(fuelSurcharge, protectedAccount)}</span></H3>
                    <H3 className="justified">
                      <span>{t('Customs Processing Fee')}</span> <span>{formatMoney(customsProcessingFee, protectedAccount)}</span>
                    </H3>
                    <H3 className="justified">
                      <span>{t('Additional Charges')}</span> <span>{formatMoney(accessorialCharge, protectedAccount)}</span>
                    </H3>
                    <hr />
                    <H2 className="justified">
                      <span>{t('Total')}</span> <span>{formatMoney(totalCharge.value || totalCharge, protectedAccount)}</span>
                    </H2>
                  </TextProperty>
                ) : (
                  <TextProperty>
                    <H3>{t('QuoteNotificationEmail')}</H3>
                  </TextProperty>

                )}
              </GreyBoxWithBorder>
            </div>
          </div> :
          <>
            <div className="row">
              {serviceLevelQuotesData && serviceLevelQuotesData.map((service) => {
                // eslint-disable-next-line no-shadow
                const renderServiceLevelDescription = (serviceLevelCode) => {
                  if (serviceLevelCode === 'GL') { return t('GeneralLTLDescription'); }
                  if (serviceLevelCode === 'EG') { return t('GroundServiceDesciption'); }
                  if (serviceLevelCode === 'AM') { return t('AMServiceDescription'); }
                  if (serviceLevelCode === 'D9') { return t('9amGuaranteedDescription'); }
                  if (serviceLevelCode === 'H1') { return t('Homeway1Descrition'); }
                  if (serviceLevelCode === 'H2') { return t('Homeway2Descrition'); }
                  if (serviceLevelCode === 'H3') { return t('Homeway3Descrition'); }
                  if (serviceLevelCode === 'H4') { return t('Homeway4Descrition'); }
                  if (serviceLevelCode === 'H5') { return t('Homeway5Descrition'); }
                  if (serviceLevelCode === 'H6') { return t('Homeway6Descrition'); }
                  if (serviceLevelCode === 'NSR') { return t('NoSignatureRequired'); }
                  if (serviceLevelCode === 'UL') { return t('UrgentLetter'); }
                  if (serviceLevelCode === 'UP') { return t('UrgentPac'); }
                  return null;
                };
                return (
                <article key={service.description} onClick={() => putReduxServiceLevelCode(service.serviceLevelCode)} className="col-sm-4">
                  <Card
                    title={service.description}
                    callOut={formatMoney(service.totalAmount.value, protectedAccount)}
                    desc={renderServiceLevelDescription(service.serviceLevelCode)}
                    active={serviceLevelCode === service.serviceLevelCode ? true : false}
                  />
                </article>
                );
              })}
            </div>
            <div className="row">
              <div className="col-sm-4">
                <GreyBoxWithBorder>
                  <TextProperty>
                    <P className="title">{t('Service Level')}</P>
                    <H3>{selectedServiceLevelInfo && t(selectedServiceLevelInfo.description)}</H3>
                  </TextProperty>
                </GreyBoxWithBorder>
                <GreyBoxWithBorder>
                  <TextProperty>
                    <P className="title">
                      {t('Expected Delivery Date')}<br />
                      <span className="small">({t('ShippedTodayText')})</span>
                    </P>
                    <H3>{selectedServiceLevelInfo && getMonthDayYear(selectedServiceLevelInfo.expectedDeliveryDate)}</H3>
                  </TextProperty>
                </GreyBoxWithBorder>
              </div>
              <div className="col-sm-8">
                <GreyBoxWithBorder>
                  {selectedServiceLevelInfo && (!selectedServiceLevelInfo.totalAmount || selectedServiceLevelInfo.totalAmount.value === 0) ? (
                    <TextProperty>
                      <H3>{t('DomesticQuoteNotificationEmail')}</H3>
                    </TextProperty>
                  ) : (
                      <TextProperty>
                        <P className="title">
                          {t('Price')}
                        </P>
                        {selectedServiceLevelInfo && selectedServiceLevelInfo.charges.map((charge, index) => (
                          <H3
                          className="justified"
                          key={index}>
                            <span>{t(charge.description)}</span> <span>{formatMoney(charge.amount.value, protectedAccount)}</span>
                          </H3>
                        ))}
                        <hr />
                        {selectedServiceLevelInfo &&
                        <H2 className="justified">
                          <span>{t('Total')}</span> <span>{formatMoney(selectedServiceLevelInfo.totalAmount.value, protectedAccount)}</span>
                        </H2>}
                      </TextProperty>
                  )}
                </GreyBoxWithBorder>
              </div>
            </div>
          </>}
        <SummaryForm
        formData={formData}
        quoteData={quoteData}
        editStepOne={editStepOne}
        editStepTwo={editStepTwo}
        editStepThree={editStepThree}
        USQuote={USQuote}
        quote
        hideDangerousItem />
      </>
    );
  }
  return null;
};

export default Step4;
