import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import SecondaryButton from '../ReactComponent/SecondaryButton';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import Column from '../ReactComponent/Column';
import { P } from '../../theme/typography';
import { P as SitecoreP } from '../../theme/typographySitecore';

export const LoginPageContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;

    ${MediaQuery.medium`
    display: flex;
    flex-direction: row;
    
    // Ensure content container edges line up with nav container edges...
    max-width: 1204px;
    margin: 0 auto;
  `}
`;

const CustomColumn = styled(Column)`
    ${MediaQuery.medium`
    flex-basis: 0 !important;
    flex-grow: 1 !important;
  `}
`;

export const ContentContainer = styled(CustomColumn)`
    margin-top: 70px;
`;

export const PageImageContainer = styled(CustomColumn)`
    padding: 0 !important;
    display: none;

    ${MediaQuery.medium`
    display: flex;
  `}
`;

export const PageImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const HeaderContainer = styled.div`
    h1, p {
        margin-bottom: 30px;
    }
`;

export const RedP = styled(SitecoreP)`
    color: ${colors.RED_ERROR}
`;

export const LoginErrorContainer = styled.div`
    padding: 5px 0;
    ${MediaQuery.medium`
    padding: 30px 0;
  `}
`;

export const LoginErrorLabel = styled(P)`
    color: ${colors.RED}
`;

export const Form = styled.form`
    margin: 90px 0;
`;

export const FieldContainer = styled(Column)`
    flex-basis: unset !important;

    ${MediaQuery.medium`
    flex-basis: 0 !important;
  `}
`;

export const ForgotPasswordContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-bottom: 50px;
`;

export const ForgotPasswordSubContainer = styled.div`
    cursor: pointer;
`;

export const FormButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    ${MediaQuery.medium`      
    flex-direction: row;   
  `}
`;

export const CreateAccountButton = styled(SecondaryButton)`
    width: 100%;

    ${MediaQuery.medium`      
      width: 265px;  
    `}
`;

export const CreateAccountButtonContainer = styled.div`
    margin-bottom: 16px;

    ${MediaQuery.medium`
    margin-right: 32px;   
  `}
`;

export const LoginButton = styled(PrimaryButton)`
    width: 100%;

    ${MediaQuery.medium`
      width: 265px;
    `}
`;

export const LinkButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 75px;
`;

export const LinkButton = styled(SecondaryButton)`
    border: none;
    padding-left: 0;
    width: auto;
`;

export const FooterContainer = styled.div`
    margin-bottom: 30px;
`;
