import styled from 'styled-components';
import { colors } from '../../theme/style-helpers';

export const SectionTitle = styled.div`
    margin-bottom: 26px;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;

    &.justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;
export const SectionSubTitle = styled.div`
    font-size: 1.2rem;
    margin-bottom: 16px;
    color: ${colors.BRAND_ORANGE}
`;

export const Section = styled.div`
    &.text-align-left {
        text-align: left;
    }

    &.text-align-right {
        text-align: right;
    }

    &.text-align-middle {
        width: 70%;
        margin-left: 15%;
        text-align: center;
    }
`;
