import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Row from '../Row';
import Column from '../Column';
import { H3, P } from '../../../theme/typography';
import { formatValuesto2Decimals, getTotalVolume, getTotalDensity } from '../../../utils/func';
import GreyBoxWithBorder from '../../_styledComponents/GreyBoxWithBorder';

const TotalDensityBlock = ({ measurementList, measurementType }) => {
  const completedMeasurements = measurementList ? measurementList.filter((m) => m.completed) : [];
  const anyMeasurements = completedMeasurements.length > 0;

  const totalVolume = anyMeasurements ? formatValuesto2Decimals(getTotalVolume(measurementType, completedMeasurements)) : null;
  const totalDensity = anyMeasurements ? formatValuesto2Decimals(getTotalDensity(measurementType, completedMeasurements)) : null;

  return (
  <Row>
    <Column $tablet={4} $mobile={12}>
      <GreyBoxWithBorder>
        <P>{t('TotalVolumeOfItemsInShipment')}</P>
        {anyMeasurements && <H3>
{totalVolume}
{' '}
cubic ft.
</H3>}
      </GreyBoxWithBorder>
    </Column>
    <Column $tablet={4} $mobile={12}>
      <GreyBoxWithBorder>
        <P>{t('TotalDensityOfItemsInShipment')}</P>
        {anyMeasurements && <H3>
{totalDensity}
{' '}
lbs/cubic ft.
</H3>}
      </GreyBoxWithBorder>
    </Column>
  </Row>
  );
};

TotalDensityBlock.propTypes = {
  measurementList: PropTypes.array,
  measurementType: PropTypes.string
};

export default TotalDensityBlock;
