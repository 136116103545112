import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import RenderField from '../RenderField';
import { isRequired } from '../../../utils/validator';
import AlertModal from '../Modal/AlertModal';

import {
  PrimaryButton,
  ButtonGroup,
  PageTitle,
  SmallDesc,
  AddressPickerModalBody
} from './css';
import { H3 } from '../../../theme/typography';
import Row from '../Row';
import Column from '../Column';

const addressPickerOptionsRenderer = ({ item }) => (
  <>
    <span className="checkmark"/>
    <Row style={{ minWidth: '250px' }}>
      <Column $tablet={10} $mobile={10}>
        <H3>{item.cityName}</H3>
      </Column>
      <Column $tablet={2} $mobile={2}>
        {item.provinceCode}
      </Column>
    </Row>
  </>
);

class AddressPickerModal extends React.Component {
  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { valid, formData: { addressOption }, handleSubmit } = this.props;

    if (!valid) {
      return;
    }

    handleSubmit(addressOption);
  };

  content = () => {
    const { Options } = this.props;
    return <AddressPickerModalBody>
      <form>
        <Row className="justify-content-md-center">
          <Column $tablet={8} $mobile={12}>
            <Row>
              <Column $tablet={6} $mobile={6}>
                {t('City')}
              </Column>
              <Column $tablet={6} $mobile={6}>
                {t('ProvinceState')}
              </Column>
            </Row>
          </Column>
        </Row>
        <Row className="justify-content-md-center">
          <Column $tablet={8} $mobile={12}>
            <Field
              name="addressOption"
              type="checkboxes"
              component={RenderField}
              data={Options}
              validate={[isRequired]}
              className="radio-container"
              renderer={addressPickerOptionsRenderer}
            />
          </Column>
        </Row>
      </form>
    </AddressPickerModalBody>;
  };

  render() {
    const {
      onClose,
      Options
    } = this.props;
    const header = () => (
      <>
        <PageTitle className="text-center">{`${t('AddressPickerTitle')} ${Options[0].postalCode}`}</PageTitle>
        <SmallDesc className="text-center">{t('AddressPickerDesc')}</SmallDesc>
      </>
    );

    const actions = () => (
      <ButtonGroup>
        <PrimaryButton className="submit" onClick={(e) => this.handleSubmit(e)}>
          <span className="icon"></span>
          {t('Okay')}
        </PrimaryButton>
      </ButtonGroup>
    );

    return <AlertModal
      isOpen
      shrinkwrapContent
      onClose={onClose}
      header={header}
      content={this.content}
      actions={actions}
    />;
  }
}

AddressPickerModal.propTypes = {
  onClose: PropTypes.func,
  Options: PropTypes.array,
  valid: PropTypes.bool,
  formData: PropTypes.shape({
    addressOption: PropTypes.object,
  }),
  handleSubmit: PropTypes.func
};

const mstp = (state) => ({
  formData: getFormValues('AddressPickerModal')(state),
});

AddressPickerModal = reduxForm({
  form: 'AddressPickerModal', // a unique identifier for this form
})(AddressPickerModal);

export default connect(mstp)(AddressPickerModal);
