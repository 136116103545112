import React, { useState, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Field, FieldArray, change } from 'redux-form';
import { t } from 'i18next';
import Cube from '../ReactComponent/Cube';
import PlusGrey from '../../assets/icons/PlusGrey.svg';
import RenderField from '../ReactComponent/RenderField';
import TotalDensityBlock from '../ReactComponent/TotalDensityBlock';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import MeasurementItemCard from '../ReactComponent/MeasurementItemCard';
import Plus from '../../assets/icons/Plus.svg';
import { maxChar50, isRequired } from '../../utils/validator';
import { numbersAndTwoDecimals } from '../../utils/mask';
import { UnitsOfMeasure } from '../../utils/enums';

import {
  AddItemContainer,
  AddItem,
  AddItemLabel,
  PageTitle,
  InputUnit,
  ContentBoxLightGrey,
  MeasurementTab,
  FileUploadLabel,
  FileUploadContainer,
  FileUpload,
  ItemContainer
} from './css';
import { P, H3 } from '../../theme/typography';
import {
  getTotalVolume,
  getTotalDensity,
  getSizeMeasurementAbbreviationString,
  getWeightMeasurementAbbreviationString,
  NavToPageAndShowAddNewLineButton
} from '../../utils/func';

const renderMeasurementList = ({
  fields,
  measurementType,
  dispatch,
  currentMeasurementList,
  formSyncErrors
}) => {
  const addItem = (index) => {
    dispatch(change('VolumeDensityCalculatorForm', `measurementList[${index}].completed`, true));

    const currentItem = currentMeasurementList[index];
    const itemVolume = getTotalVolume(measurementType, [currentItem]);
    dispatch(change('VolumeDensityCalculatorForm', `measurementList[${index}].itemVolume`, itemVolume));

    const itemDensity = getTotalDensity(measurementType, [currentItem]);
    dispatch(change('VolumeDensityCalculatorForm', `measurementList[${index}].itemDensity`, itemDensity));

    setAddedTrue();
  };

  const [state, setState] = useState({
    added: false
  });

  const setAddedTrue = () => setState({
    ...state,
    added: true
  });

  const setAddedFalse = () => setState({
    ...state,
    added: false
  });

  const weightMeasurement = getWeightMeasurementAbbreviationString(measurementType);
  const sizeMeasurement = getSizeMeasurementAbbreviationString(measurementType);
  NavToPageAndShowAddNewLineButton(currentMeasurementList, state.added, setAddedTrue);
  return (
    <>
      {fields.map((item, index) => {
        const fieldValue = fields.get(index);
        const {
          completed,
          itemWidth,
          itemHeight,
          itemLength
        } = fieldValue;
        const disableAddItem = !!(formSyncErrors.measurementList && formSyncErrors.measurementList[index]);
        if (completed) {
          return (
            <MeasurementItemCard
              key={index}
              formName="VolumeDensityCalculatorForm"
              measurementList={[fieldValue]}
              dispatch={dispatch}
              measurementType={measurementType}
              order={index}
              currentMeasurementList={currentMeasurementList}
              hideTotalPieces
              hideDescription
              hideDangerousItem
              showVolume
              showDensity
            />
          );
        }

        return (
          <ContentBoxLightGrey className="edit-item-box" key={index}>
            <Row>
              <Column $tablet={6} $mobile={12}>
                <Row>
                  <Column $mobile={10}>
                    <Field
                      name={`${item}.itemWeight`}
                      component={RenderField}
                      label={t('TotalWeight')}
                      normalize={numbersAndTwoDecimals}
                      required
                      validate={[maxChar50, isRequired]}
                    />
                  </Column>
                  <Column $mobile={2}>
                    <InputUnit>{weightMeasurement}</InputUnit>
                  </Column>
                </Row>
                <Row>
                  <Column $mobile={10}>
                    <Field
                      name={`${item}.itemLength`}
                      component={RenderField}
                      label={t('ItemLength')}
                      normalize={numbersAndTwoDecimals}
                      required
                      validate={[maxChar50, isRequired]}
                    />
                  </Column>
                  <Column $mobile={2}>
                    <InputUnit>{sizeMeasurement}</InputUnit>
                  </Column>
                </Row>
                <Row>
                  <Column $mobile={10}>
                    <Field
                      name={`${item}.itemWidth`}
                      component={RenderField}
                      label={t('ItemWidth')}
                      normalize={numbersAndTwoDecimals}
                      required
                      validate={[maxChar50, isRequired]}
                    />
                  </Column>
                  <Column $mobile={2}>
                    <InputUnit>{sizeMeasurement}</InputUnit>
                  </Column>
                </Row>
                <Row>
                  <Column $mobile={10}>
                    <Field
                      name={`${item}.itemHeight`}
                      component={RenderField}
                      label={t('ItemHeight')}
                      normalize={numbersAndTwoDecimals}
                      required
                      validate={[maxChar50, isRequired]}
                    />
                  </Column>
                  <Column $mobile={2}>
                    <InputUnit>{sizeMeasurement}</InputUnit>
                  </Column>
                </Row>
              </Column>
              <Column $tablet={6} $mobile={12}>
                <Cube
                  width={itemWidth}
                  height={itemHeight}
                  length={itemLength}
                  measurementType={measurementType}
                />
              </Column>
            </Row>
            <AddItemContainer>
              <AddItem disabled={disableAddItem} className={disableAddItem ? '' : 'active'} onClick={() => addItem(index)}>
                <AddItemLabel>{t('SaveItem')}</AddItemLabel>
                <img src={disableAddItem ? PlusGrey : Plus} alt="add item" />
              </AddItem>
            </AddItemContainer>
          </ContentBoxLightGrey>
        );
      })}
      {state.added && (
        <FileUploadContainer className="with-dot">
          <FileUpload onClick={() => {
            fields.push({});
            setAddedFalse();
          }}>
            <img src={Plus} alt="add more part file" />
            <FileUploadLabel>{t('AddAnotherItem')}</FileUploadLabel>
          </FileUpload>
        </FileUploadContainer>
      )}
    </>
  );
};

class VolumeDensityCalculatorImpl extends PureComponent {
  render() {
    const {
      formData = {},
      dispatch,
      fields,
      formSyncErrors
    } = this.props;
    const {
      measurementList,
      measurementType,
      editingItemIndex
    } = formData;

    const measurementTypeValue = measurementType ? measurementType.value : '';
    const disabled = (measurementList && measurementList.filter((m) => m.completed).length > 0);

    return (
      <>
        <PageTitle className="justified">
          {fields.heading && <Text field={fields.heading}/>}
        </PageTitle>
        <Row>
          <Column $desktop={8}>
            {fields.description && <P><RichText tag="P" field={fields.description}/></P>}
          </Column>
        </Row>
        <Row>
          <Column $tablet={6} $desktop={3}>
            <MeasurementTab>
              <H3>{t('SelectUnit')}</H3>
              <Field
                name="measurementType"
                type="dropdown"
                data={[
                  {
                    name: t('Inches'),
                    value: UnitsOfMeasure.Inches
                  },
                  {
                    name: t('Feet'),
                    value: UnitsOfMeasure.Feet
                  },
                  {
                    name: t('Centimeters'),
                    value: UnitsOfMeasure.Centimeters
                  },
                  {
                    name: t('Meters'),
                    value: UnitsOfMeasure.Meters
                  }
                ]}
                textField="name"
                valueField="value"
                component={RenderField}
                className={disabled ? 'disabled-dropdown' : ''}
              />
            </MeasurementTab>
          </Column>
        </Row>
        <ItemContainer>
          <FieldArray
            name="measurementList"
            dispatch={dispatch}
            measurementType={measurementTypeValue}
            component={renderMeasurementList}
            editingItemIndex={editingItemIndex}
            currentMeasurementList={measurementList}
            formSyncErrors={formSyncErrors}
          />
        </ItemContainer>
        <TotalDensityBlock
          measurementList={measurementList}
          measurementType={measurementTypeValue}
        />
      </>
    );
  }
}

VolumeDensityCalculatorImpl.propTypes = {
  formData: PropTypes.object,
  dispatch: PropTypes.func,
  fields: PropTypes.object,
  formSyncErrors: PropTypes.object,
};

export default VolumeDensityCalculatorImpl;
