import React from 'react';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import FormProgressBar from '../ReactComponent/FormProgressBar';
import { ProgressBarDecorator, Page } from './css';
import { scrollToTop } from '../../utils/func';
import { FORM_NAME, services, steps } from './constants';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import SignupBanner from '../ReactComponent/SignupBanner';
import { list as unitsList } from '../ReactComponent/Measurement/Units/utils';
import QuestionsBlock from '../ReactComponent/QuestionsBlock';

class CreateSctnQuote extends React.Component {
  constructor() {
    super();
    this.state = {
      currentStep: 0
    };
  }

  moveToStep = (step, e) => {
    if (e) {
      e.preventDefault();
    }
    scrollToTop();
    this.setState({ currentStep: step }, () => {
      setTimeout(() => scrollToTop(), 200);
    });
  };

  cancel = (e) => {
    this.moveToStep(0, e);
    this.props.dispatch(reset(FORM_NAME));
  };

  next = (e) => this.moveToStep(this.state.currentStep + 1, e);

  back = (e) => this.moveToStep(this.state.currentStep - 1, e);

  finish = () => {
    scrollToTop();
    this.setState(() => ({ currentStep: steps.length - 1 }));
  };

  render() {
    const {
      fields,
      isAuthenticated,
      userEmail
    } = this.props;
    const { currentStep } = this.state;

    const initialValues = {
      service: services(fields)[0],
      unitType: unitsList[0],
      additionalServices: [],
      containers: [],
      confirmationEmail: isAuthenticated ? userEmail : undefined
    };

    return (
      <Page>
        <ProgressBarDecorator>
          <FormProgressBar current={currentStep} steps={steps(t)} />
        </ProgressBarDecorator>
        <SignupBanner loggedIn={isAuthenticated} />

        {
          {
            0: (
              <Step1
                fields={fields}
                onNext={this.next}
                finish={this.finish}
                initialValues={initialValues}
              />
            ),
            1: <Step2 fields={fields} onNext={this.next} onBack={this.back} />,
            2: <Step3 fields={fields} onNext={this.next} onBack={this.back} />,
            3: (
              <Step4
                fields={fields}
                onNext={this.next}
                onBack={this.back}
                moveToStep={this.moveToStep}
              />
            ),
            4: <Step5 fields={fields} />
          }[currentStep]
        }
        <QuestionsBlock/>
      </Page>
    );
  }
}

export default connect((state) => ({
  isAuthenticated: state.profile.isAuthenticated,
  userEmail: state.profile.email
}))(CreateSctnQuote);
