import { MDBDropdown } from 'mdbreact';
import styled from 'styled-components';
import { MediaQuery } from '../../theme/style-helpers';

export const PageTitle = styled.div`
    font-weight: 300;
    font-size: 4.8rem;
    margin-bottom: 32px;

    &.justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;
export const CategoryTitle = styled.div`
    font-size: 2.4rem;
    margin-top: 64px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`;
export const TextProperty = styled.div`
    font-size: 1.4rem;
    margin-bottom: 32px;
    font-weight: 500;

    .title {
        margin-bottom: 10px;
    }

    .justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    hr {
        border-color: #000;
        margin: 1.5rem 0 3rem;
    }
`;
export const Terms = styled.div`
    margin-bottom: 72px;
`;

export const StyledMDBDropdown = styled(MDBDropdown)`
    ${MediaQuery.medium`    
    width: 300px !important;
  `}
`;
