import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { H1, P } from '../../theme/typography';
import Tile from './tile';
import InstructionsTile from './instructionsTile';

import {
  FormSummary,
} from './css';
import Column from '../ReactComponent/Column';
import Row from '../ReactComponent/Row';

class ManageClaimsImpl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedTileId: '',
    };
  }

  toggleInstructionsHandler = (id) => { this.setState({ expandedTileId: id }); };

  render() {
    const {
      fields,
      isAuthenticated
    } = this.props;
    const ctas = fields['cta tiles'];
    const firstCTA = ctas && ctas.length > 0 ? ctas[0] : null;

    return (
      <FormSummary>
        {fields.heading && <H1><Text field={fields.heading}/></H1>}
        {fields.description && <P><RichText field={fields.description}/></P>}
        {
          isAuthenticated ?
            (
              firstCTA && <InstructionsTile fields={firstCTA.fields}/>
            ) :
            (
              <>
                <Row>
                  <Column>
                    <Row>
                      {ctas && (ctas.map((tile, key) => <>
                          <Column $tablet={6} $mobile={12}>
                            <Tile key={key} id={key} fields={tile.fields} toggleInstructions={this.toggleInstructionsHandler}/>
                          </Column>
                        </>
                      ))}
                    </Row>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    {ctas && ctas.map((tile, key) =>
                      this.state.expandedTileId === key && (
                        <InstructionsTile id={key} fields={tile.fields} toggleInstructions={this.toggleInstructionsHandler}/>
                      ))}
                  </Column>
                </Row>
              </>
            )
        }
      </FormSummary>
    );
  }
}

const mstp = (state) => ({
  isAuthenticated: state.profile.isAuthenticated
});

const ManageClaims = connect(mstp)(ManageClaimsImpl);

export default ManageClaims;
