import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@sitecore-jss/sitecore-jss-react';

const RoleBasedButton = (props) => {
  const { fields } = props;

  return (
    (fields && fields.link)
      ? <Link field={fields.link} className="btn btn-primary btn-lg btn-block col-md-3"/> : null
  );
};

RoleBasedButton.propTypes = {
  fields: PropTypes.shape({
    link: PropTypes.string
  }),
};

export default RoleBasedButton;
