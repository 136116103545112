import React from 'react';
import { t } from 'i18next';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import {
  PageTitle,
  CategoryTitle,
  TextProperty,
  Terms,
  PrimaryButton,
  ButtonGroup,
} from '../css';
import { H3, P } from '../../../theme/typography';
import SummaryForm from './SummaryForm';
import GreyBoxWithBorder from '../../_styledComponents/GreyBoxWithBorder';
import { routes } from '../../../utils/constants';

const renderBottomNav = (isAuthenticated) => {
  const userRedirector = isAuthenticated ? routes.viewUserDashboard : '/';

  return (
    <ButtonGroup>
      <PrimaryButton
      className="submit"
      onClick={() => {
        if (window && typeof window !== 'undefined') {
          window.location.href = userRedirector;
        }
      }}>
        <span className="icon" alt="exit"/>
        {t('Exit')}
      </PrimaryButton>
    </ButtonGroup>
  );
};

const Step6 = ({
  formData = {},
  quoteData,
  parentFields = {},
  // openBOL,
  authenticated,
}) => {
  const { probillNumber } = quoteData;
  const { accessorials } = formData;

  return (
    <>
      <div className="row mt-5">
        <div className="col-8">
          <PageTitle>{parentFields.yourPickupHeading && parentFields.yourPickupHeading.value}</PageTitle>
          <P>{parentFields.yourPickupSummary.value}</P>
        </div>
      </div>

      <CategoryTitle>{parentFields.shippingDetailsTitle && parentFields.shippingDetailsTitle.value}</CategoryTitle>
      <GreyBoxWithBorder>
        <Row>
          <Column $tablet={4} $mobile={12}>
            <TextProperty>
              <P className="title">{t('Status')}</P>
              <H3>{parentFields.statusH3 && parentFields.statusH3.value}</H3>
            </TextProperty>
          </Column>
          <Column $tablet={4} $mobile={12}>
            <TextProperty>
              <P className="title">{t('PickupOrderNumber')}</P>
              <H3>{probillNumber ? probillNumber.orderNumber : ''}</H3>
            </TextProperty>
          </Column>
        </Row>
      </GreyBoxWithBorder>
      <P>{parentFields.pickupNotes.value}</P>
      <SummaryForm formData={formData} accessorialsList={accessorials} parentFields={parentFields} hideCube/>
      <Terms>
        <CategoryTitle>{t('TermsConditions')}</CategoryTitle>
        <P>{t('TermsConditionsSummary')}</P>
      </Terms>
      {renderBottomNav(authenticated)}
    </>
  );
};

export default Step6;
