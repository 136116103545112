import { createGlobalStyle } from 'styled-components';
import { colors } from './style-helpers';

/* stylelint-disable */
/* eslint no-unused-expressions: 0 */
const GlobalStyles = createGlobalStyle`
    body {
        background-color: ${colors.WHITE};
        height:100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Montserrat', sans-serif !important;
        padding: 0;
        margin: 0;
    }
    html {
        height:100%;
        font-size: 62.5%;
    }
    img {
        max-width: 100%;
    }
`;

export default GlobalStyles;
