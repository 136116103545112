import { PUT_REDUX_AUTH_CONTENT } from '../../reducers/constants';
import { routes } from '../../utils/constants';
import c from '../../temp/config';
import { switchDivision as switchDivisionService } from '../../services/accountService';
import logOut from '../../components/Logout/actions';
import { getCurrentLocale } from '../../utils/func';
import getLayoutResources from '../../services/layoutService';

export const setAuthContent = (authContent) => ({
  type: PUT_REDUX_AUTH_CONTENT,
  authContent,
});

/**
 * This thunk invokes a service to switch the user's division in the server (switchDivision),
 * followed by the invocation of service that fetches the user details from the server
 * (fetchAuthContent).
 * @param userId
 * @param divisionCode
 * @param onAfterSwitch (optional) invoked after switch division, before fetch user details.
 * @returns {Function}
 */
export const switchDivision = ({
  userId,
  divisionCode,
  token,
  onAfterSwitch,
  onSwitchFail,
  isAuthenticated
}) => (dispatch) => {
  switchDivisionService({
    userId,
    divisionCode,
    token
  })
    .then(() => {
      if (onAfterSwitch) {
        onAfterSwitch();
      }

      dispatch(
        fetchAuthContent({
          isFrontEndAuth: isAuthenticated,
          navToDashboard: true,
          invokeLayout: true
        })
      );
    })
    .catch(() => {
      if (onSwitchFail) {
        onSwitchFail();
      }
    });
};

export const fetchAuthContent = ({
  isFrontEndAuth,
  navToDashboard,
  language,
  invokeLayout
}) => {
  const switchToLang = language || getCurrentLocale();
  return (dispatch) => {
    if (invokeLayout === true) {
      getLayoutResources({
        sitecoreApiKey: c.sitecoreApiKey,
        locale: switchToLang
      }).then((route) => {
        if (route.status === 200
            && route.data
            && route.data.sitecore
            && route.data.sitecore.context.profileData
            && route.data.sitecore.context.profileData.basic_info) {
          const {
            division,
            divisionCode,
            userId,
            isAuthenticated,
            email,
            isForceChangePassword
          } = route.data.sitecore.context.profileData.basic_info;
          const {
            billingAccounts,
            // eslint-disable-next-line camelcase
            app_settings
          } = route.data.sitecore.context.profileData;
          const accountNumbers = (billingAccounts) ? billingAccounts.map((a) => a.accountNumber) : [];
          const authContent = {
            division,
            divisionCode,
            accountNumbers,
            isAuthenticated,
            userId,
            email,
            // eslint-disable-next-line camelcase
            generalSettings: app_settings,
            billingAccounts,
            isForceChangePassword: (isForceChangePassword === 'True'),
          };
          dispatch(setAuthContent(authContent));

          if (navToDashboard && isAuthenticated) {
            window.location.href = routes.viewUserDashboard;
          }
        } else if (isFrontEndAuth) {
          dispatch(logOut({ isSessionTimeout: true }));
        }
      });
    } else {
      const route = JSON.parse(document.getElementById('__JSS_STATE__').innerHTML);
      if (route !== null
        && route
        && route.sitecore
        && route.sitecore.context.profileData
        && route.sitecore.context.profileData.basic_info
        && route.sitecore.context.profileData.basic_info.isAuthenticated) {
        const {
          division,
          divisionCode,
          userId,
          isAuthenticated,
          email,
          isForceChangePassword
        } = route.sitecore.context.profileData.basic_info;
        const {
          billingAccounts,
          // eslint-disable-next-line camelcase
          app_settings
        } = route.sitecore.context.profileData;
        const accountNumbers = (billingAccounts) ? billingAccounts.map((a) => a.accountNumber) : [];
        const authContent = {
          division,
          divisionCode,
          accountNumbers,
          isAuthenticated,
          userId,
          email,
          // eslint-disable-next-line camelcase
          generalSettings: app_settings,
          billingAccounts,
          isForceChangePassword: (isForceChangePassword === 'True'),
        };
        dispatch(setAuthContent(authContent));

        if (navToDashboard && isAuthenticated) {
          window.location.href = routes.viewUserDashboard;
        }
      } else if (isFrontEndAuth) {
        dispatch(logOut({ isSessionTimeout: true }));
      }
    }
  };
};
