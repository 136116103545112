import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Row from '../Row';
import Column from '../Column';
import {
  ShipmentDetailsDiv,
  AddressHeaderDiv,
  HistoryDiv,
  HistoryEntryDiv,
} from './css';
import NoDataDecorator from '../SortableTable/NoDataDecorator';
import { Icon } from '../Icon';
import AddressToIcon from '../../../assets/icons/AddressTo.svg';
import GoogleMap from '../GoogleMap';

const AddressHeader = ({
  title,
  icon
}) =>
  <AddressHeaderDiv>
    <span className="title">
      <Icon iconSrc={icon}></Icon>
      &nbsp;
      {title}
    </span>
  </AddressHeaderDiv>;

AddressHeader.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
};

// eslint-disable-next-line
const HistoryEntry = ({
  itemId,
  data,
  onClickEntry
}) => {
  const {
    companyname,
    address,
    selected
  } = data;
  const AddressArray = address.replace(/['"]+/g, '').split(',');
  return <HistoryEntryDiv className={`${selected ? 'selected' : ''}`} onClick={() => onClickEntry(data)}>
    <Row>
      <Column $desktop={1} className="heading">
        <span className="number">{parseInt(itemId, 10) + 1}</span>
      </Column>
      <Column $desktop={11} className="heading">
        <span className="status">{companyname && companyname}</span>
        <div>
          <div className="address">{AddressArray[0] && AddressArray[0]}</div>
          <div className="address">
            {AddressArray[1] && AddressArray[1]}
            ,&nbsp;
            {AddressArray[2] && AddressArray[2]}
          </div>
          <div className="address">{AddressArray[3] && AddressArray[3]}</div>
        </div>
      </Column>
    </Row>
  </HistoryEntryDiv>;
};

HistoryEntry.propTypes = {
  itemId: PropTypes.number,
  data: PropTypes.shape({
    companyname: PropTypes.string,
    address: PropTypes.string,
    selected: PropTypes.bool,
  }),
  onClickEntry: PropTypes.func,
};

// eslint-disable-next-line
class TerminalLocationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedDetailsData: [],
      mapBoundary: [],
    };
    this.historyEntries = React.createRef();
  }

  initState = (detailsData, divison) => {
    const { fields } = this.props;

    const provinceName = fields && fields.province !== 'All' ? fields.province : '';
    const countryName = fields && fields.countries !== 'All' ? fields.countries : '';
    const divisionName = divison && divison !== 'All' ? divison : '';

    if (detailsData.length > 0) {
      const locationDetailsData = detailsData.filter((eachItem) => eachItem.state.indexOf(provinceName) !== -1
        && eachItem.country.indexOf(countryName) !== -1 && eachItem.division.indexOf(divisionName) !== -1);
      const mapBoundary = locationDetailsData.map((each, index) => ({
        lat: parseFloat(each.lat),
        lng: parseFloat(each.long),
        ishighlighted: true,
        originalIndex: index,
        id: each.id,
        formName: 'TerminalLocationForm'
      })
      );

      this.setState({
        mappedDetailsData: locationDetailsData,
        mapBoundary
      });
    }
  };

  componentDidMount() {
    const {
      detailsData,
      divisionData
    } = this.props;
    this.initState(detailsData, divisionData);
  }

  componentDidUpdate(prevProps) {
    const {
      detailsData,
      divisionData,
      fields
    } = this.props;

    const differentDetailsData = prevProps.detailsData !== detailsData ||
      prevProps.fields.province !== fields.province ||
      prevProps.fields.countries !== fields.countries ||
      prevProps.divisionData !== divisionData;

    if (differentDetailsData) {
      this.initState(detailsData, divisionData);
    }
  }

  selectItemInState = (id) => {
    const {
      mappedDetailsData,
      mapBoundary
    } = this.state;

    const newDetailsData = mappedDetailsData.map((each) => {
      each.selected = each.id === id;
      return each;
    });

    const newBoundaryData = mapBoundary.map((each) => {
      each.selected = each.id === id;
      return each;
    });

    this.setState({
      mappedDetailsData: newDetailsData,
      mapBoundary: newBoundaryData
    });
  };

  onClickHistoryEntry = (selectedItem) => {
    const { id } = selectedItem;
    const { mapBoundary } = this.state;

    const currentSelectedItem = mapBoundary.find((each) => each.selected);
    if (currentSelectedItem == null || currentSelectedItem.id !== id) {
      this.selectItemInState(id);
    }
  };

  historyElementScrollTo = (index) => {
    const rootElement = this.historyEntries.current;

    if (!rootElement) {
      return;
    }

    const historyElement = rootElement.children[index];
    if (historyElement) {
      const firstElement = rootElement.children[0];
      const top = historyElement.offsetTop - firstElement.offsetTop;

      rootElement.scrollTo({
        top,
        behavior: 'smooth'
      });
    }
  };

  onClickMapMarker = (marker) => {
    const {
      id,
      originalIndex
    } = marker;
    this.selectItemInState(id);
    this.historyElementScrollTo(originalIndex);
  };

  render() {
    const {
      error,
      mapid
    } = this.props;
    const {
      mapBoundary,
      mappedDetailsData
    } = this.state;
    const hasData = mappedDetailsData && mappedDetailsData.length > 0;
    return <>
      {error && <div>{error}</div>}
      {!error && hasData &&

        <ShipmentDetailsDiv>
          <Column $desktop={6} className="column left">
            <AddressHeader title={`${t('Showing all')} ${mappedDetailsData.length} ${t('locations')}`} icon={AddressToIcon}/>
            <HistoryDiv ref={this.historyEntries}>
              {
                mappedDetailsData.map((each, index) => <HistoryEntry key={index} itemId={index} data={each} onClickEntry={this.onClickHistoryEntry}/>)
              }
            </HistoryDiv>
          </Column>
          <Column $desktop={6} className="column right">
            <GoogleMap mapid={mapid} markers={mapBoundary} onClickMarker={this.onClickMapMarker}/>
          </Column>
        </ShipmentDetailsDiv>}
      {!error && !hasData && <div>
        <NoDataDecorator component="" data={mappedDetailsData} columns=""/>
      </div>}
    </>;
  }
}

TerminalLocationDetails.propTypes = {
  fields: PropTypes.shape({
    province: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.string),
  }),
  detailsData: PropTypes.object,
  divisionData: PropTypes.object,
  error: PropTypes.string,
  mapid: PropTypes.string,
};

export default TerminalLocationDetails;
