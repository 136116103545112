import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  getFormValues,
  change,
  getFormSyncErrors
} from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import VolumeDensityCalculatorImpl from './VolumeDensityCalculatorImpl';
import { measurementValidate } from '../../utils/validator';
import ResetOrange from '../../assets/icons/ResetOrange.svg';
import { UnitsOfMeasure, DeliveryTypes, Divisions } from '../../utils/enums';
import {
  FormContainer,
  SecondaryButton,
  ButtonGroup,
  Icon
} from './css';
import QuestionsBlock from '../ReactComponent/QuestionsBlock';

const initialValues = {
  division: Divisions.Freight.name,
  deliveryType: DeliveryTypes.Commercial,
  measurementType: {
    name: t('Inches'),
    value: UnitsOfMeasure.Inches
  },
  measurementList: [{}]
};

class VolumeDensityCalculatorForm extends React.Component {
  componentDidMount() {
    const { isAuthenticated, division, dispatch } = this.props;

    if (isAuthenticated) {
      dispatch(change('VolumeDensityCalculatorForm', 'division', division));
    }
  }

  scrollToTop = () => window.scrollTo(0, 0);

  reset = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { dispatch } = this.props;
    dispatch(change('VolumeDensityCalculatorForm', 'measurementList', [{}]));
  };

  render() {
    const {
      formData,
      dispatch,
      fields,
      formSyncErrors
    } = this.props;

    return (
      <>
        <FormContainer>
          <form>
            <VolumeDensityCalculatorImpl formData={formData} dispatch={dispatch} fields={fields} formSyncErrors={formSyncErrors}/>
          </form>
          <QuestionsBlock/>
        </FormContainer>
        <ButtonGroup>
          <SecondaryButton onClick={this.reset}>
            <Icon className="icon-left" src={ResetOrange} alt="back"/>
            {t('Reset')}
          </SecondaryButton>
        </ButtonGroup>
      </>
    );
  }
}

VolumeDensityCalculatorForm.propTypes = {
  isAuthenticated: PropTypes.bool,
  division: PropTypes.string,
  dispatch: PropTypes.func,
  formData: PropTypes.object,
  fields: PropTypes.object,
  formSyncErrors: PropTypes.object
};

VolumeDensityCalculatorForm = reduxForm({
  form: 'VolumeDensityCalculatorForm',
  initialValues,
  validate: measurementValidate,
})(VolumeDensityCalculatorForm);

const mstp = (state) => ({
  formData: getFormValues('VolumeDensityCalculatorForm')(state),
  formSyncErrors: getFormSyncErrors('VolumeDensityCalculatorForm')(state),
  division: state.profile.division,
});

export default connect(mstp)(VolumeDensityCalculatorForm);
