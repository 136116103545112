export const types = Object.freeze({
  EDITABLE: 'editable',
  READ_ONLY: 'readOnly',
});

export const equipmentSizes = [
  '20 Ft Container',
  '48 Ft Trailer',
  '40 Ft Standard Container',
  '53 Ft Trailer',
  '40 Ft High Cube Container',
  'Straight Truck',
  '45 Ft Container',
  'Other',
];
