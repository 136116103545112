import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  change,
  getFormValues
} from 'redux-form';
import axios from 'axios';
import { t } from 'i18next';
import {
  getShipmentTrackingDetailed
} from '../../services/shipments';
import {
  CategoryTitle,
  TextProperty,
  HeaderContainer,
  HeaderTitle,
  QuestionsBlockContainer,
} from './css';
import { H3, H4, P } from '../../theme/typographySitecore';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import QuestionsBlock from '../ReactComponent/QuestionsBlock';
import GreyBoxWithBorder from '../_styledComponents/GreyBoxWithBorder';
import ShipmentDetailsWithErrors from '../ReactComponent/ShipmentDetails/ShipmentDetailsWithErrors';
import { getDeliveryDate } from '../../utils/dateTime';
import { filterDetailsData, getHandlingDetails } from './func';
import { Divisions, LanguagesPreference } from '../../utils/enums';
import RenderField from '../ReactComponent/RenderField';
import SecondaryButton from '../ReactComponent/SecondaryButton';
import { isEmail } from '../../utils/validator';

const formName = 'ViewShipmentTracking';

const initialValues = {
  sendmeUpdatesByEmail: false,
  sendmeUpdatesBySMS: false,
  customerLanguagePreference: { name: LanguagesPreference.English }
};

class ViewShipmentTracking extends React.Component {
  constructor(props) {
    super(props);

    const { customerLanguagePreference } = this.props;

    this.state = {
      detailedTrackingData: null,
      wasShipmentTrackingDetailedServiceError: false,
      renderPostalCodeFields: false,
      renderEmailLanguageField: false,
      renderCustomerMobileField: false,
      errorMsg: false,
      shipperZipCodeValue: '',
      consigneeZipCodeValue: '',
      customerEmailId: '',
      customerLanguagePreference,
      customerMobileNumber: '',
      consigneeEmailId: '',
      billToEmailId: '',
      consigneeMobileNumber: '',
      billToMobileNumber: '',
      successMessage: false,
      division: '',
      errorMessage: false,
      inValidEmail: false,
      inValidBillToEmail: false,
      inValidConsigneeEmail: false
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const probill = urlParams.get('probill');
    const division = probill ? Divisions.Freight.name : urlParams.get('division');
    const probillNumber = urlParams.get('probillNumber') || probill;

    setTimeout(() => getShipmentTrackingDetailed({
      division,
      probillNumber
    })
      .then((res) => {
        if (res.status === 200 || res.status === 404) {
          this.setState({
            detailedTrackingData: res.data,
            division
          });
        } else {
          this.setState({ wasShipmentTrackingDetailedServiceError: true });
        }
      })
      .catch(() => {
        this.setState({ wasShipmentTrackingDetailedServiceError: true });
      }), 200);
  }

  renderHeader = () => {
    const { fields } = this.props;
    return (
      <HeaderContainer>
        <Column $desktop={8}>
          <HeaderTitle field={fields.headerTitle}/>
          <P field={fields.headerSummary}/>
        </Column>
      </HeaderContainer>
    );
  };

  renderShippingDetails = () => {
    const { detailedTrackingData } = this.state;

    if (!detailedTrackingData) {
      return null;
    }

    const {
      probillNumber,
      beyondProbillNumber,
      referenceNumber,
      totalPieces,
      totalPiecesDelivered,
      totalWeight,
      transferPartnerName,
      transferDate,
      deliveryDate,
      deliveryDateType,
      receivedBy,
      statusCode,
      additionalInfo
    } = detailedTrackingData;
    const { fields } = this.props;
    const handlingDetails = getHandlingDetails({
      statusCode,
      receivedBy
    });

    return (
      <>
        <CategoryTitle field={fields.shippingDetailsHeader}/>
        <GreyBoxWithBorder>
          <Row>

            <Column $tablet={4} $mobile={12}>
              {probillNumber &&
                <TextProperty>
                  <P field={t('ProNumber')}/>
                  <H3 field={probillNumber}/>
                </TextProperty>}
              {beyondProbillNumber &&
                <TextProperty>
                  <P field={t('BeyondProNumber')}/>
                  <H3 field={beyondProbillNumber}/>
                </TextProperty>}
              {referenceNumber &&
                <TextProperty>
                  <P field={t('ReferenceNumber')}/>
                  <H3 field={referenceNumber}/>
                </TextProperty>}
            </Column>

            <Column $tablet={4} $mobile={12}>
              {totalPieces && totalPiecesDelivered &&
                <TextProperty>
                  <P field={t('TotalPieces')}/>
                  <H3 field={`${totalPiecesDelivered} / ${totalPieces} ${t('Pieces')}`}/>
                </TextProperty>}
              {totalWeight &&
                <TextProperty>
                  <P field={t('TotalWeight')}/>
                  <H3 field={`${totalWeight.value} ${totalWeight.unitOfMeasure}`}/>
                </TextProperty>}
              {transferPartnerName &&
                <TextProperty>
                  <P field={t('TransferredToPartner')}/>
                  <H3 field={transferPartnerName}/>
                  {transferDate &&
                    <H4 field={transferDate}/>}
                </TextProperty>}
            </Column>

            <Column $tablet={4} $mobile={12}>
              {deliveryDate &&
                <TextProperty>
                  <P field={t('DeliveryDate')}/>
                  <H4 field={deliveryDate ? getDeliveryDate({
                    deliveryDate,
                    deliveryDateType
                  }) : ''}/>
                </TextProperty>}
              {additionalInfo &&
                <TextProperty>
                  <P field={t('AdditionalInformation')}/>
                  <H3 field={additionalInfo}/>
                </TextProperty>}
              {handlingDetails && handlingDetails.label && handlingDetails.value &&
                <TextProperty>
                  <P field={handlingDetails.label}/>
                  <H3 field={handlingDetails.value}/>
                </TextProperty>}
            </Column>

          </Row>
        </GreyBoxWithBorder>
      </>
    );
  };

  renderAppointmentDetails = () => {
    const { detailedTrackingData } = this.state;
    const { isAuthenticated } = this.props;
    if (!detailedTrackingData) {
      return null;
    }

    const {
      deliveryDate,
      deliveryDateType
    } = detailedTrackingData;

    const { fields } = this.props;

    return (
      <>
        {isAuthenticated && deliveryDateType === 'AppointmentDelivery' &&
          <>
            <CategoryTitle field={fields.appointmentDetailsHeader}/>
            <GreyBoxWithBorder>
              <Row>
                <Column $tablet={4} $mobile={6}>
                  {deliveryDate &&
                    <TextProperty>
                      <P field={fields.appointment}/>
                    </TextProperty>}
                </Column>

                <Column $tablet={4} $mobile={6}>
                  {deliveryDate &&
                    <TextProperty>
                      <H4 field={deliveryDate ? `${t('On')} ${getDeliveryDate({
                        deliveryDate,
                        deliveryDateType
                      })}` : ''}/>
                    </TextProperty>}
                </Column>
              </Row>
            </GreyBoxWithBorder>
          </>}
      </>
    );
  };

  renderTrackingDetails = () => {
    const {
      fields,
      isAuthenticated
    } = this.props;
    const {
      detailedTrackingData,
      wasShipmentTrackingDetailedServiceError,
      division
    } = this.state;

    if (!detailedTrackingData) {
      return null;
    }

    let basicData = {};
    if (detailedTrackingData) {
      basicData = {
        from: detailedTrackingData.from ? {
          street: detailedTrackingData.from.address1,
          city: detailedTrackingData.from.city,
          provinceCode: detailedTrackingData.from.provinceCode,
          countryCode: detailedTrackingData.from.countryCode,
        } : {},
        to: detailedTrackingData.to ? {
          street: detailedTrackingData.to.address1,
          city: detailedTrackingData.to.city,
          provinceCode: detailedTrackingData.to.provinceCode,
          countryCode: detailedTrackingData.to.countryCode,
        } : {}
      };
    }
    return (
      <>
        <CategoryTitle field={fields.trackingDetailsHeader}/>
        <ShipmentDetailsWithErrors
          showError={wasShipmentTrackingDetailedServiceError}
          basicData={basicData}
          detailsData={filterDetailsData(detailedTrackingData.trackingItems)}
          division={division}
          isAuthenticated={isAuthenticated}
        />
      </>
    );
  };

  renderPostalCodeField = (e) => {
    if (e.target.defaultValue === 'false') {
      this.setState({
        renderPostalCodeFields: true,
        renderEmailLanguageField: false

      });
    } else {
      this.setState({
        renderPostalCodeFields: false,
        renderEmailLanguageField: false
      });
    }
  };

  renderMobileNumberFields = (e) => {
    if (e.target.defaultValue === 'false') {
      this.setState({ renderCustomerMobileField: true });
    } else {
      this.setState({ renderCustomerMobileField: false });
    }
  };

  handleShipperZipCodeValue = (e) => {
    const shipperZipCodeValue = e.target.value.toUpperCase().replace(/ +/g, '');
    this.setState({
      shipperZipCodeValue
    });
  };

  handleConsigneeZipCodeValue = (e) => {
    const consigneeZipCodeValue = e.target.value.toUpperCase().replace(/ +/g, '');
    this.setState({
      consigneeZipCodeValue
    });
  };

  validatePostalCode = () => {
    const {
      shipperZipCodeValue,
      consigneeZipCodeValue,
      detailedTrackingData
    } = this.state;
    const {
      customerLanguagePreference,
      dispatch
    } = this.props;
    if ((shipperZipCodeValue === detailedTrackingData.from.postalCode)
      || (consigneeZipCodeValue === detailedTrackingData.to.postalCode)) {
      this.setState({
        renderEmailLanguageField: true,
        renderPostalCodeFields: false,
        errorMsg: false
      });
    } else {
      this.setState({
        renderEmailLanguageField: false,
        renderPostalCodeFields: true,
        errorMsg: true
      });
    }
    if (customerLanguagePreference === 'FR') {
      dispatch(change(formName, 'customerLanguagePreference', { name: LanguagesPreference.French }));
    } else {
      dispatch(change(formName, 'customerLanguagePreference', { name: LanguagesPreference.English }));
    }
  };

  handleCustomerEmail = (e) => {
    this.setState({
      customerEmailId: e.target.value
    });

    // eslint-disable-next-line
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    if (e.target.value !== '' && re.test(e.target.value) === false) {
      this.setState({ inValidEmail: true });
    } else {
      this.setState({ inValidEmail: false });
    }
  };

  handleBillToEmailAddress = (e) => {
    this.setState({
      billToEmailId: e.target.value
    });

    // eslint-disable-next-line
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    if (e.target.value !== '' && re.test(e.target.value) === false) {
      this.setState({ inValidBillToEmail: true });
    } else {
      this.setState({ inValidBillToEmail: false });
    }
  };

  handleConsigneeEmailAddress = (e) => {
    this.setState({
      consigneeEmailId: e.target.value
    });

    // eslint-disable-next-line
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    if (e.target.value !== '' && re.test(e.target.value) === false) {
      this.setState({ inValidConsigneeEmail: true });
    } else {
      this.setState({ inValidConsigneeEmail: false });
    }
  };

  handleLanguagePreference = (e) => {
    this.setState({
      customerLanguagePreference: e
    });
  };

  handleCustomerMobileNumber = (e) => {
    this.setState({
      customerMobileNumber: e.target.value
    });
  };

  handleBillToMobileNumber = (e) => {
    this.setState({
      billToMobileNumber: e.target.value
    });
  };

  handleConsigneeMobileNumber = (e) => {
    this.setState({
      consigneeMobileNumber: e.target.value
    });
  };

  handleRegister = () => {
    const {
      division
    } = this.state;
    const {
      detailedTrackingData,
      customerEmailId,
      consigneeEmailId,
      billToEmailId,
      customerLanguagePreference,
      customerMobileNumber,
      billToMobileNumber,
      consigneeMobileNumber
    } = this.state;
    let probill;
    if (detailedTrackingData) {
      probill = detailedTrackingData.probillNumber ? detailedTrackingData.probillNumber : '';
    }
    const languagePref = (customerLanguagePreference === 'EN' || customerLanguagePreference.name === 'English')
      ? { name: LanguagesPreference.English } : { name: LanguagesPreference.French };
    const customerFormValueToApi = {
      customerLanguagePreference: languagePref,
      emailAddresses: {
        customerEmailId,
        billToEmailId,
        consigneeEmailId
      },
      mobileNumbers: {
        customerMobileNumber,
        billToMobileNumber,
        consigneeMobileNumber
      },
      probillNumber: probill,
      division
    };
    return axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}TrackShipments`, customerFormValueToApi)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.setState({
            successMessage: true
          });
        }
        // else{
        //   this.setState({
        //     errorMessage: true
        //   })
        // }
      }).catch(() => {
        this.setState({
          errorMessage: true,
          successMessage: false
        });
      });
  };

  showSuccessMessage = () => (
    <>
      <GreyBoxWithBorder>
        <Row>
          <Column>
            <P field={t('SuccessMessage')}/>
          </Column>
        </Row>
      </GreyBoxWithBorder>
    </>
  );

  showErrorMessage = () => (
    <>
      <GreyBoxWithBorder>
        <Row>
          <Column>
            <P field={t('ErrorMessage')}/>
          </Column>
        </Row>
      </GreyBoxWithBorder>
    </>
  );

  renderSendEmailStatusUpdate = () => {
    const {
      inValidEmail,
      inValidConsigneeEmail,
      inValidBillToEmail,
      renderPostalCodeFields,
      renderEmailLanguageField,
      successMessage,
      errorMsg,
      errorMessage
    } = this.state;
    const inValidPostalTextStyle = {
      color: '#CE0505',
      fontSize: '14px',
      marginBottom: '10px',
      marginLeft: '15px'
    };
    const registerButtonStyle = {
      color: 'grey',
      border: '1px solid grey'
    };

    const { customerEmailId, billToEmailId, consigneeEmailId } = this.state;

    return (
      <>
        <CategoryTitle field={t('RegisterForStatusUpdatesforThisShipment')}/>
        {(!successMessage && !errorMessage) ?
          <GreyBoxWithBorder>
            <Row>
              <Column $tablet={4} $mobile={4}>
                <Field
                  name="sendmeUpdatesByEmail"
                  type="singleCheckbox"
                  component={RenderField}
                  label={t('SendMeUpdatesByEmail')}
                  onChange={(e) => this.renderPostalCodeField(e)}
                />
              </Column>
              <Column $tablet={8} $mobile={8}>
                {renderEmailLanguageField &&
                  <Field
                    name="customerLanguagePreference"
                    type="checkboxes"
                    component={RenderField}
                    label={t('LanguagePreference')}
                    required
                    data={[
                      { name: LanguagesPreference.English },
                      { name: LanguagesPreference.French }
                    ]}
                    onChange={(e) => this.handleLanguagePreference(e)}

                  />}
              </Column>
            </Row>

            {renderPostalCodeFields &&
              <Row>
                <Column $tablet={8} $mobile={8} style={{ height: '90px' }}>
                  <Field
                    name="shipperZipCodeValue"
                    type="text"
                    component={RenderField}
                    label={t('ShipperZipCode')}
                    onChange={(e) => this.handleShipperZipCodeValue(e)}
                  />
                </Column>
                <Column
                  $tablet={12}
                  $mobile={12}
                  style={{
                    textAlign: 'center',
                    paddingRight: '460px'
                  }}>
                  <H3 field={t('OR')}/>
                </Column>
                <Column $tablet={8} $mobile={8}>
                  <Field
                    name="consigneeZipCodeValue"
                    type="text"
                    component={RenderField}
                    label={t('ConsigneeZipCode')}
                    onChange={(e) => this.handleConsigneeZipCodeValue(e)}
                  />
                </Column>
                {(errorMsg) ? <span style={inValidPostalTextStyle}>{t('IncorrectPostalCodeError')}</span> : ''}
                <Column $tablet={12} $mobile={12}>
                  <SecondaryButton type="submit" onClick={this.validatePostalCode}>
                    {t('Validate')}
                  </SecondaryButton>

                </Column>
              </Row>}

            {renderEmailLanguageField &&
              <Row>
                <Column $tablet={4} $mobile={4}>
                  <Field
                    name="customerEmailAddress"
                    type="text"
                    component={RenderField}
                    label={t('Email1')}
                    validate={[isEmail]}
                    onChange={(e) => this.handleCustomerEmail(e)}
                  />
                </Column>
                <Column $tablet={4} $mobile={4}>
                  <Field
                    name="billToPartyEmailAddress"
                    type="text"
                    component={RenderField}
                    label={t('Email2')}
                    validate={[isEmail]}
                    onChange={(e) => this.handleBillToEmailAddress(e)}
                  />
                </Column>

                <Column $tablet={4} $mobile={4}>
                  <Field
                    name="consigneePartyEmailAddress"
                    type="text"
                    component={RenderField}
                    label={t('Email3')}
                    validate={[isEmail]}
                    onChange={(e) => this.handleConsigneeEmailAddress(e)}
                  />
                </Column>
              </Row>}

            {renderEmailLanguageField &&
              (!(customerEmailId || billToEmailId || consigneeEmailId)
                || (inValidEmail || inValidConsigneeEmail || inValidBillToEmail) ?
                  <SecondaryButton
                    type="submit"
                    disabled={!(customerEmailId || billToEmailId || consigneeEmailId)
                      || (inValidEmail || inValidConsigneeEmail || inValidBillToEmail)}
                    style={registerButtonStyle}
                    onClick={this.handleRegister}>
                    {t('Register')}
                  </SecondaryButton>
                :
                  <SecondaryButton
                    type="submit"
                    onClick={this.handleRegister}>
                    {t('Register')}
                  </SecondaryButton>
              )}
          </GreyBoxWithBorder> : ''}
      </>
    );
  };

  renderFooter = () => (
    <QuestionsBlockContainer>
      <QuestionsBlock/>
    </QuestionsBlockContainer>
  );

  render() {
    const {
      detailedTrackingData,
      successMessage,
      errorMessage,
      division
    } = this.state;
    let statusData;
    if (detailedTrackingData) {
      statusData = detailedTrackingData.status ? detailedTrackingData.status : '';
    }
    return (
      <>
        {this.renderHeader()}
        {this.renderShippingDetails()}
        {(division === 'Freight') ? this.renderAppointmentDetails() : ''}
        {this.renderTrackingDetails()}
        {(statusData === 'Delivered' || statusData === 'Cancelled' || statusData === 'Livré'
          || statusData === 'Annulé') ? '' : this.renderSendEmailStatusUpdate()}
        {successMessage ? this.showSuccessMessage() : ''}
        {errorMessage ? this.showErrorMessage() : ''}
        {this.renderFooter()}
      </>
    );
  }
}

ViewShipmentTracking.propTypes = {
  customerLanguagePreference: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  dispatch: PropTypes.func,
  fields: PropTypes.shape({
    headerTitle: PropTypes.object,
    headerSummary: PropTypes.object,
    shippingDetailsHeader: PropTypes.object,
    appointmentDetailsHeader: PropTypes.object,
    appointment: PropTypes.object,
    trackingDetailsHeader: PropTypes.object
  }),
};

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  isAuthenticated: state.profile.isAuthenticated,
  customerLanguagePreference: state.profile.currentLanguage,
});

ViewShipmentTracking = reduxForm({
  form: formName, // a unique identifier for this form
  initialValues
})(ViewShipmentTracking);

export default connect(mstp)(ViewShipmentTracking);
