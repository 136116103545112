import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Card from '../Card';
import Row from '../Row';
import Column from '../Column';

const QuestionsBlock = ({ link }) => (
  <Row>
    <Column $tablet={6} $mobile={12} className="mx-auto">
      <Card
        title={t('QuestionsBlockTitle')}
        desc={t('QuestionsBlockDescription')}
        link={link || t('LearnMoreLink')}
        linkText={t('LearnMore')}
        linkToNewTab
        noHover
      />
    </Column>
  </Row>
);

QuestionsBlock.propTypes = {
  link: PropTypes.string
};

export default QuestionsBlock;
