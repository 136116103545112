import React from 'react';
import { P, H1 } from '../../theme/typographySitecore';

import CenteredMessage from './css';

const ErrorGeneral = ({ fields }) => <>
  {fields.heading && fields.heading.value && <H1 field={fields.heading}/>}
  <CenteredMessage>
    <P field={fields.errorMessage}/>
  </CenteredMessage>
</>;

export default ErrorGeneral;

