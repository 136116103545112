import { TOGGLE_ACCOUNT_SIDE_MENU, TOGGLE_ACCOUNT_SIDE_MENU_ERROR_MODAL } from '../components/ReactComponent/AccountSideMenu/actions';

const initialState = {
  isOpen: false,
  isModelOpen: false,
};

const accountSideMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ACCOUNT_SIDE_MENU:
      return {
        ...state,
        isOpen: !state.isOpen
      };
    case TOGGLE_ACCOUNT_SIDE_MENU_ERROR_MODAL:
      return {
        ...state,
        isModelOpen: !state.isModelOpen
      };
    default:
      return state;
  }
};

export default accountSideMenuReducer;
