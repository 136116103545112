import React from 'react';
import NavigationLink from './navigationLink';
import NavigationGroup from './navigationGroup';

class NavigationSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  toggleNavItem = (onToggle) => {
    const newstate = this.state.expanded;

    this.setState({ expanded: !newstate });
    onToggle(!newstate);
  };

  isActiveLink = (contextItemId, c) => c.link && c.link.targetItem && c.link.targetItem.id === contextItemId;

  isActive = (contextItemId, children) => {
    const sublinks = children.filter((c) => c.template.name === 'Navigation-Link');
    const activeSublinks = sublinks.filter((c) => this.isActiveLink(contextItemId, c));
    if (activeSublinks && activeSublinks.length > 0) {
      return true;
    }

    const groups = children.filter((c) => c.template.name === 'Navigation-Group');
    const activeGroupSublinks = groups.filter((c) => {
      const activeLinks = c.children.filter((l) => this.isActiveLink(contextItemId, l));
      return activeLinks && activeLinks.length > 0;
    });
    return activeGroupSublinks && activeGroupSublinks.length > 0;
  };

  render() {
    const { children, title } = this.props.section;
    const {
      level, onToggle, id, open, contextItemId
    } = this.props;

    const nextLevel = level + 1;
    const subitemsClass = `nav-menu-l${nextLevel}`;
    const currentItemClass = `nav-item${open ? ' open' : ''}`;

    const className = `nav-link${this.isActive(contextItemId, children) ? ' active' : ''}`;

    return (
            <li className={currentItemClass} >
                <a className={className} onClick={() => onToggle(id)}>
                    <span className="title">{title.value}</span> <span className="icon"></span>
                </a>
                <ul className={subitemsClass}>
                    {
                        children.map((c, key) => {
                          if (c.template.name === 'Navigation-Group') {
                            return <NavigationGroup key={key} section={c} level={nextLevel} contextItemId={contextItemId} />;
                          } if (c.template.name === 'Navigation-Link') {
                            return <NavigationLink key={key} fields={c} level={nextLevel} contextItemId={contextItemId}/>;
                          }
                          return null;
                        }
                        )
}
                </ul>
            </li>
    );
  }
};

export default NavigationSection;
