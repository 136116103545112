import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  getFormValues,
  change,
  getFormSyncErrors
} from 'redux-form';
import Modal from 'react-modal';
import { t } from 'i18next';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowLeftOrange from '../../assets/icons/ArrowLeftOrange.svg';
import putReduxPermissionList from './actions';
import {
  getAllPermissions,
  createCompany,
  updateCompany,
  deleteCompany,
  getCompanyInfo
} from '../../services/companies';
import { formDataToApi, formApiToData } from '../../services/companies/helpers';
import {
  Icon,
  PrimaryButton,
  SecondaryButton,
  ButtonGroup,
  FormContainer,
} from './css';
import CompanyForm from './components/CompanyForm';
import { homeUrlNoTrailingSlash, openInNewTab } from '../../utils/func';
import { routes } from '../../utils/constants';
import LeavePageModal from '../ReactComponent/LeavePageModal';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import {
  ModalTitle,
  Close,
  ModalBody,
  ButtonDiv,
} from '../ManageUsers/css';

const formName = 'CreateCompanyForm';
const initialValues = {
  accountList: [{}]
};

const ModalContent = ({ successAction, goToManage }) => (
  <>
    <ModalTitle>{t('Success')}</ModalTitle>
    <p>{successAction === 'create' ? t('NewCompanyCreated') : t('CompanyUpdated')}</p>
    <p>{t('You will now be taken to Manage Companies')}</p>
    <ButtonDiv>
      <SecondaryButton
        onClick={() => goToManage()}
        className="active min-width"
      >
        {t('Accept')}
      </SecondaryButton>
    </ButtonDiv>
  </>);

// eslint-disable-next-line react/no-multi-comp
class CreateCompanyForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      permissionList: [],
      companyID: '',
      modalIsOpen: false,
      successModalIsOpen: false,
      successAction: '',
      viewEditCompanyData: []
    };
  }

  componentDidMount() {
    this.fetchAllPermissions();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('companyId')) {
      this.setState({ companyID: urlParams.get('companyId') });
      this.handleCompanyId(
        urlParams.get('companyId')
      );
    }
  }

  handleCompanyId(companyId) {
    getCompanyInfo(companyId)
      .then((response) => {
        if (response.data) {
          const { data } = response;
          this.setState({ viewEditCompanyData: response.data });
          const { permissionList } = this.state;
          const formedData = formApiToData(data, permissionList);
          this.dispatchFormValues(formedData);
        }
      });
  }

  dispatchFormValues = (data) => {
    const { dispatch } = this.props;
    Object.keys(data).map((field) => dispatch(change(formName, field, data[field])));
  };

  setShippingPermission = (data) => {
    const { dispatch } = this.props;

    const shippingPerm = data.filter((p) => p.permissionName === 'Shipping').map((sp) =>
      ({
        [sp.permissionName]: {
          selected: ['',
            {
              name: sp.permissionName,
              desc: sp.description,
              code: sp.permissionId
            }]
        }
      }));

    if (shippingPerm[0]) {
      dispatch(change(formName, 'permissions', { ...shippingPerm[0] }));
    }
  };

  fetchAllPermissions = () => {
    const { division } = this.props;
    getAllPermissions(division).then((res) => {
      if (res.status === 200) {
        // eslint-disable-next-line no-shadow
        const { putReduxPermissionList } = this.props;
        putReduxPermissionList(res.data);
        this.setShippingPermission(res.data);
        this.setState({ permissionList: res.data });
      }
    });
  };

  goToManage = (e) => {
    e?.preventDefault();
    const manageCompanyUrl = `${homeUrlNoTrailingSlash()}${routes.manageCompany}`;
    window.location.href = manageCompanyUrl;
  };

  updateState = (name, value) => this.setState({ [name]: value });

  scrollToTop = () => window.scrollTo(0, 0);

  deleteCompany = () => {
    const { companyID } = this.state;
    deleteCompany(companyID).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        this.goToManage();
      }
    });
  };

  handleSubmit = () => {
    const {
      formData,
      isAuthenticated,
      division
    } = this.props;
    const { companyID } = this.state;
    if (isAuthenticated) {
      const changedData = formDataToApi(formData, division);
      if (formData.rowVersion) {
        updateCompany(changedData, companyID).then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 204) {
            this.openModal('update');
          }
        });
      } else {
        createCompany(changedData).then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 204) {
            this.openModal('create');
          }
        });
      }
    }
  };

  openModal = (successAction) => {
    this.setState({
      successModalIsOpen: true,
      successAction
    });
  };

  closeModal = () => {
    this.setState({ successModalIsOpen: false });
  };

  handleCreateUser = (e) => {
    e?.preventDefault();
    if (this.state.companyID) {
      const createUserURL = `${homeUrlNoTrailingSlash()}${routes.createUser}?page=${'manage-companies'}`;
      localStorage.setItem('view/editCompanyData', JSON.stringify(this.state.viewEditCompanyData));
      openInNewTab(createUserURL);
    } else {
      const createUserURL = `${homeUrlNoTrailingSlash()}${routes.createUser}?page=${'manage-companies'}`;
      openInNewTab(createUserURL);
    }
  };

  render() {
    const {
      formData,
      handleSubmit,
      dispatch,
      fields,
      formSyncErrors
    } = this.props;
    const {
      permissionList,
      modalIsOpen,
      successModalIsOpen,
      successAction
    } = this.state;
    return (
      <>
        <Modal
          isOpen={successModalIsOpen}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <Close onClick={() => this.closeModal()} />
          <ModalBody className="justify-content-center">
            <ModalContent
              goToManage={this.goToManage}
              successAction={successAction}
            />
          </ModalBody>
        </Modal>
        <LeavePageModal isOpen={modalIsOpen} onClose={() => this.setState({ modalIsOpen: false })} onContinue={() => this.goToManage()} />
        <FormContainer>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <PrimaryButton type="submit" className="submit float-right white-arrow">
              <span className="icon icon-right-arrow"></span>
              {t('SaveChanges')}
            </PrimaryButton>
            <PrimaryButton
              iconRightArrow
              className="add-new active float-right white-arrow "
              onClick={(e) => this.handleCreateUser(e)}
            >
              {t('AddNewUser')}
              <span className="icon icon-right-arrow"></span>
            </PrimaryButton>
            <CompanyForm
              formData={formData}
              dispatch={dispatch}
              formName={formName}
              permissionList={permissionList}
              deleteCompany={this.deleteCompany}
              parentFields={fields}
              error={formSyncErrors}
            />
            <ButtonGroup>
              <SecondaryButton type="button" className="active" onClick={() => this.setState({ modalIsOpen: true })}>
                <Icon className="icon-left" src={ArrowLeftOrange} alt="back" />
                {t('Cancel')}
              </SecondaryButton>
              <PrimaryButton type="submit" className="submit">
                <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                {t('SaveChanges')}
              </PrimaryButton>
            </ButtonGroup>
          </form>
        </FormContainer>
      </>
    );
  }
}

CreateCompanyForm = reduxForm({
  form: formName, // a unique identifier for this form
  initialValues,
})(CreateCompanyForm);

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  formSyncErrors: getFormSyncErrors(formName)(state),
  isAuthenticated: state.profile.isAuthenticated,
  division: state.profile.division,
  email: state.profile.email,
});

const mdtp = (dispatch) => ({
  putReduxPermissionList: (permissionList) => dispatch(putReduxPermissionList(permissionList)),
});

export default connect(mstp, mdtp)(CreateCompanyForm);
