import styled, { css } from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';
import ArrowRightBlack from '../../assets/icons/ArrowRightBlack.svg';

export const PageTitle = styled.div`
    font-weight: 300;
    font-size: 4.8rem;
    margin-bottom: 32px;

    &.justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const Tile = styled.div`
    padding: 10px;
    background: ${colors.WHITE};
    border: 1px solid ${colors.COOL_GREY};
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    margin: 20px 0;

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    &.active {
        border-color: ${colors.BLACK};
    }

    &.static {
        &:hover {
            border-color: ${colors.COOL_GREY};
        }
    }

    .margin-20 {
        margin: 20px 0;
    }

    .bold-message {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 21px;
        margin-top: 20px;
    }

    .quote-info {
        & > span {
            display: block;

            &:first-of-type {
                font-weight: 600;
            }

            margin: 5px 0;
        }
    }

    .invoice-sum {

        ${MediaQuery.medium`
            border-left: 1px solid ${colors.COOL_GREY}
        `}
        & > span {
            width: 100%;
            display: inline-block;
            margin: 5px 0;

            ${MediaQuery.medium`
                text-align:right;
            `}
            &:first-of-type {
                font-size: 4rem;
                color: ${colors.BRAND_ORANGE};

                .currency {
                    font-size: 2rem;
                }
            }

            &:last-of-type {
                font-size: 1.4rem;
            }
        }
    }

    .quote-topright {
        font-size: 1.5rem;
    }

    .pickup-summary-block {
        text-align: center;
        border-bottom: 1px solid ${colors.COOL_GREY};
        padding-bottom: 20px;
    }
`;

export const InlineBlockBase = styled.div`
    display: block;
    width: 100%;

    &.right {
        text-align: right;
    }

    &.center {
        text-align: center;
    }

    .primaryButton {
        display: inline-block;

        background-color: ${colors.DARK_GREY};
        font-style: normal;
        position: relative;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: normal;
        text-align: center;
        color: ${colors.COOL_GREY};
        padding: 16px 56px;
        border: 1px solid ${colors.DARK_GREY};
        border-radius: 3px;
        margin-left: 0;
        margin-bottom: 10px;
        width: 100%;
        cursor: pointer;
        order: 0;

        &.min-width {
            min-width: 300px;
        }

        ${MediaQuery.medium`
            width: auto;
            margin: 0 10px;
            margin-bottom: 0;
            order: 1;
        `}
        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
            margin: 0;
            height: 24px;
            width: 24px;
            background: url(${ArrowRightWhite});
            background-size: cover;
        }

        &.submit, &.active {
            background-color: ${colors.BLACK};

            .icon {
                background: url(${ArrowRightOrange});
            }

            &:hover {
                background-color: ${colors.BRAND_ORANGE};
                color: ${colors.BLACK};
                border-color: ${colors.BRAND_ORANGE};
                text-decoration: none;

                .icon {
                    background: url(${ArrowRightBlack});
                }
            }
        }
    }

    .secondaryButton {
        display: inline-block;
        background-color: ${colors.WHITE};
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: normal;
        text-align: center;
        color: ${colors.BLACK};
        padding: 16px 56px;
        border: 1px solid ${colors.BLACK};
        border-radius: 3px;
        margin-left: 0;
        margin-bottom: 10px;
        width: 100%;
        order: 1;
        cursor: pointer;

        &.min-width {
            min-width: 300px;
        }


        ${MediaQuery.medium`
            width: auto;
            margin: 0 10px;
            order: 0;
        `}
        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
            margin: 0;
            height: 24px;
            width: 24px;
            background: url(${ArrowRightOrange});
            background-size: cover;
        }

        &.no-border {
            border: none;
            border-radius: initial;
        }

        &:hover {
            border-color: ${colors.BRAND_ORANGE};
            text-decoration: none;
            color: ${colors.BLACK};
        }

        &.hidden {
            display: none;
        }

        &.report-link {
            padding: 10px 0;
            margin: 0;
            text-align: left;
            font-size: 1.4rem;
            line-height: 1.7rem;
            background-color: transparent;
            width: auto;

            .icon {
                right: -24px;
            }

            &:disabled {
                color: ${colors.DISABLED2};
                cursor: default;
            }
        }
    }

    &.margin-20 {
        margin: 20px 0;
    }

    &.margin-10 {
        margin: 10px 0;
    }

    .icon-20 {
        height: 20px;
    }
`;

export const InlineBlock = styled(InlineBlockBase)`
    ${({ width, usesamewidthformobile }) => {
    if (width && usesamewidthformobile) {
      return css`
        display: inline-block;
        width: ${width}%;
        `;
    };
    if (width) {
      if (MediaQuery.medium) {
        return MediaQuery.medium(css`
            display: inline-block;
            width: ${width}%;
            `);
      }
    }
    return null;
  }}
`;

export const CenteredMessage = styled.div`
    width: 100%;
    height: 300px;
    text-align: center;
    vertical-align: middle;
    padding-top: 120px;

    &.padding-40 {
        padding-top: 40px;
    }

    &.padding-70 {
        padding-top: 70px;
    }

    .icon-100 {
        width: 100px;
        height: 100px;
    }
`;

export const ResultCategoryContainer = styled.div`
    & > span {
        width: 100%;
        display: inline-block;
        margin: 5px 0;
    }

    .category {
        font-size: 1.4rem;
    }

    .count {
        font-size: 4rem;
        color: ${colors.BRAND_ORANGE};
    }
`;

export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;

export const TutorialsTileBase = styled.div`
    padding: 20px;
    min-height: 250px;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-size: cover;
    border: 1px solid white;
    border-radius: 6px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    margin: 20px 0;

    font-size: 1.4rem;

    .view-more {
        text-align: right;
    }

    .content-text {
        min-height: 110px;
        line-height: 2rem;
    }

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    .primaryButton {
        display: inline-block;

        background-color: ${colors.DARK_GREY};
        font-style: normal;
        position: relative;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: normal;
        text-align: center;
        color: ${colors.COOL_GREY};
        padding: 16px 51px;
        border: 1px solid ${colors.DARK_GREY};
        border-radius: 3px;
        margin-left: 0;
        margin-bottom: 10px;
        width: 100%;
        cursor: pointer;
        order: 0;

        &.min-width {
            min-width: 300px;
        }

        ${MediaQuery.medium`
            width: auto;
            margin: 0 10px;
            margin-bottom: 0;
            order: 1;
        `}
        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
            margin: 0;
            height: 24px;
            width: 24px;
            background: url(${ArrowRightWhite});
            background-size: cover;
        }

        &.submit, &.active {
            background-color: ${colors.BLACK};

            .icon {
                background: url(${ArrowRightOrange});
            }

            &:hover {
                background-color: ${colors.BRAND_ORANGE};
                color: ${colors.BLACK};
                border-color: ${colors.BRAND_ORANGE};
                text-decoration: none;

                .icon {
                    background: url(${ArrowRightBlack});
                }
            }
        }
    }
`;

export const TutorialsTile = styled(TutorialsTileBase)`
    ${({ $backgroundImageSrc }) => ($backgroundImageSrc
    ? css`
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), url('${$backgroundImageSrc}');
                color: ${colors.WHITE};

                h2 {
                    color: ${colors.WHITE};
                }
            `
    : null)
}`;

export const QuickReportsTile = styled.div`
    margin: 20px 0;
    padding: 20px;
    background-color: ${colors.LIGHT_GREY};
    min-height: 350px;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;

    font-size: 1.4rem;

    .content-text {
        line-height: 2rem;
        min-height: 80px;
    }

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

`;

export const ExpandButton = styled.button`
    width: 100%;
    background-color: transparent;
    border: none;
    text-align: center;

    &.open {
        > img {
            transform: rotate(180deg);
        }
    }
`;
