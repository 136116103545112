import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import logOut from '../../Logout/actions';

class AutoLogout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timerid: -1,
    };
    this.getSessionTimeout = this.getSessionTimeout.bind(this);
  }

  componentDidMount() {
    this.setState({ timerid: this.getSessionTimeout() });
  }

  componentWillUnmount() {
    clearTimeout(this.state.timerid);
  }

  getSessionTimeout() {
    if (!this.props.isAuthenticated) {
      return;
    }

    const locationURL = typeof window !== 'undefined' ? window.location.pathname !== '/' : false;

    if (locationURL) {
      const sessionTimeOut = `${t('Timeout')}`;
      const timerid = setTimeout(() => {
        this.logout();
      }, 1000 * 60 * sessionTimeOut);
      // eslint-disable-next-line consistent-return
      return timerid;
    }
  }

  logout = () => {
    sessionStorage.clear();
    this.props.logOut();
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.profile.isAuthenticated
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut({ isSessionTimeout: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogout);
