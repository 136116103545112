import styled from 'styled-components';
import Column from '../ReactComponent/Column';
import { MediaQuery } from '../../theme/style-helpers';

export const PageContainer = styled.div`
    padding-bottom: 36px !important;
    padding-top: 36px !important;
    ${MediaQuery.medium`     
        padding-left:156px!important;
        padding-right:156px!important;
    `}
`;

export const PageTitle = styled.div`
    font-weight: 400;
    font-size: 3.2rem;
    margin-bottom: 32px;
    ${MediaQuery.small`     
        font-size: 4.8rem;
        font-weight: 300;
    `}
`;

export const UpdateDetailsContainer = styled(Column)``;

export const UpdateDateLine = styled.div`
    font-weight: 500;
    font-size: 2.4rem;
    margin-bottom: 36px;
    ${MediaQuery.small`     
        font-size: 2.6rem;
        font-weight: 300;
    `}
`;

export const UpdateDescription = styled.div`
    font-weight: normal;
    font-size: 1.8rem;
    margin-bottom: 36px;
`;

export const CurrentRateLine = styled.div`
    font-weight: 600;
    font-size: 2.4rem;
    margin-bottom: 36px;
`;

export const ContactPhoneNumber = styled.div`
    font-weight: 600;
    font-size: 2.0rem;
`;

export const ConverterContainer = styled.div`
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    background-color: #f9f9f9;
    margin-bottom: 54px;
`;

export const ConverterTitle = styled.div`
    background-color: black;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    padding: 10px;
    width: 100%;
`;

export const EqualsContainer = styled.div`
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 58px;
`;

export const EqualsSign = styled.div`
    border-top: 3px solid black;
    border-bottom: 3px solid black;
    height: 12px;
    width: 20px;
    display: inline-block;
`;

export const ConverterControlsContainer = styled.div`
    padding: 20px;
`;
