import axios from 'axios';
import { endpoints } from '../utils/constants';

const getLayoutResources = ({ sitecoreApiKey, locale }) => {
  const localeParam = locale || 'en'; // "en" as default locale...

  const url = `${endpoints.sitecore.layoutService}?item=/&sc_apikey=${sitecoreApiKey}&sc_lang=${localeParam}&tracking=true`;
  return axios({
    url,
    method: 'get',
    data: null,
    withCredentials: true,
  });
};
export default getLayoutResources;
