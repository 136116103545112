import React from 'react';
import { Field } from 'redux-form';
import { t } from 'i18next';
import {
  RowLabel,
  UploadButton,
  UploadDocumentsContainer,
  UploadDocumentsRow,
  UploadDocumentsRowContainer,
  CheckmarkIcon,
  FileNameContainer,
  FileUploadLabel, FileInputField,
} from './css';
import UploadDocumentsRenderModes from './constants';

const ATTACHED_DOCUMENTS_STORE_KEY = 'attachedDocuments';

const UploadDocumentsReviewRow = ({ rowLabel = '', ...rest }) => {
  const { input } = rest;

  return (
    <UploadDocumentsRow>
      <RowLabel field={rowLabel}/>
      <FileNameContainer>
        {input.value.name && (
          <>
            <FileUploadLabel>{input.value.name}</FileUploadLabel>
            <CheckmarkIcon/>
          </>
        )}
      </FileNameContainer>
    </UploadDocumentsRow>
  );
};

// eslint-disable-next-line react/no-multi-comp
const UploadDocuments = ({
  numberOfPlaceholders = 0,
  storeKey = ATTACHED_DOCUMENTS_STORE_KEY,
  renderMode = UploadDocumentsRenderModes.INPUT
}) => {
  // eslint-disable-next-line no-shadow
  const renderPlaceholders = (numberOfPlaceholders) => {
    const placeholderElements = [];
    let rowLabel = '';
    let fieldName = '';

    for (let i = 1; i < numberOfPlaceholders; i++) {
      rowLabel = `${t('Document')} ${i}`;
      fieldName = `${storeKey ? storeKey : ATTACHED_DOCUMENTS_STORE_KEY}.attachedDocument_${i}`;

      placeholderElements.push(
        <UploadDocumentsRowContainer key={i}>
            { renderMode === UploadDocumentsRenderModes.INPUT && (
              <UploadDocumentsRow>
                <RowLabel field={rowLabel}/>
                <UploadButton
                  component={FileInputField}
                  attachButton={t('AttachDocument')}
                  name={fieldName}
                  className="inline squeeze"
                />
              </UploadDocumentsRow>
            )}

            {renderMode === UploadDocumentsRenderModes.REVIEW && (
              <Field
                name={fieldName}
                component={UploadDocumentsReviewRow}
                rowLabel={rowLabel}
              />
            )}

        </UploadDocumentsRowContainer>
      );
    }
    return placeholderElements;
  };

  return (
    <UploadDocumentsContainer>
      {renderPlaceholders(numberOfPlaceholders)}
    </UploadDocumentsContainer>
  );
};

export default UploadDocuments;
