import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';
import ArrowRightBlack from '../../assets/icons/ArrowRightBlack.svg';
import ArrowLeftOrange from '../../assets/icons/ArrowLeftOrange.svg';

export const LoginBanner = styled.div`
    height: 168px;
    top: 372px;
    background: red;
`;
export const FieldGroup = styled.div`
    margin-bottom: 58px;
`;
export const ErrorMessage = styled.span`
    font-style: normal;
    padding: 10px 6px;
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    color: ${colors.ERROR};
`;
export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;

export const FormContainer = styled.div`
    width: 100%;
    padding: 72px 0;
    margin: 0 auto;
`;

export const AddressBlock = styled.div`
    &:nth-child(1) {
        ${MediaQuery.medium`
            padding-right: 76px;
        `}
    }

    &:nth-child(2) {
        ${MediaQuery.medium`
            padding-left: 76px;
        `}
    }

    &.with-bg {
        background-color: ${colors.LIGHT_GREY};
        padding: 32px;
        box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        margin-bottom: 32px;
    }
`;
export const FormSummary = styled.summary`
    margin: 32px 0 72px;
`;

export const PrimaryButton = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 16px 56px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    order: 0;
    float: none;

    &.min-width {
        min-width: 300px;
    }

    &.add-new {
        top: 65px;
        left: 245px;
    }

    &.white-arrow {
        .icon {
            background: url(${ArrowRightWhite}) !important;
        }

        &:hover {
            .icon {
                background: url(${ArrowRightBlack}) !important;
            }
        }
    }

    &.icon {
        height: 24px;
        width: 24px;
        margin-left: 10px;
        background-size: cover;
    }

    &.icon-right-arrow {
        margin-left: 10px;
    }

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        margin-bottom: 0;
        order: 1;

        &.float-right{
            float: right;
        }
    `}
    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowRightWhite});
        background-size: cover;
    }

    &.submit, &.active {
        background-color: ${colors.BLACK};

        .icon {
            background: url(${ArrowRightOrange});
        }

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};

            .icon {
                background: url(${ArrowRightBlack});
            }
        }
    }
`;
export const SecondaryButton = styled.button`
    background-color: ${colors.WHITE};
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    padding: 16px 56px;
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    margin-left: 0;
    width: 100%;
    order: 1;
    cursor: pointer;

    &.min-width {
        min-width: 300px;
    }


    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        order: 0;
    `}
    .icon-left {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowLeftOrange});
        background-size: cover;
    }

    .icon-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowRightOrange});
        background-size: cover;
    }

    &.no-border {
        border: none;
    }

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    &.hidden {
        display: none;
    }

`;
export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 72px;
    margin-top: 50px;
`;

export const SuccessMessageBox = styled.div`
    text-align: center;
    margin: 32px auto 100px;

    .success-image {
        margin-bottom: 32px;
    }
`;
export const AddItemContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
    padding: 18px 8px;
    margin-bottom: 40px;

    &.with-background {
        background-color: ${colors.WHITE};
        border-bottom: 1px solid ${colors.COOL_GREY};

        p {
            margin: 0;
        }
    }
`;
export const AddItem = styled.button`
    background-color: ${colors.WHITE};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.DARK_GREY};
    padding: 17px 34px;
    border: 1px solid ${colors.COOL_GREY};
    border-radius: 3px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    order: 0;

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        margin-bottom: 0;
        order: 1;
    `}
    &.active {
        background-color: ${colors.BLACK};

        label {
            color: ${colors.WHITE};
        }

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};

        }
    }

    .hidden-button {
        display: none;
    }
`;

export const AddItemLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: ${colors.DARK_GREY};
    margin-bottom: 0;
    margin-left: 8px;
    cursor: pointer;
`;

export const AddedItemCard = styled.div`
    background-color: ${colors.LIGHT_GREY};
    padding: 32px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 100%;
    margin: 40px 0;
    position: relative;
    flex-wrap: wrap;

    .row {
        width: 100%;
    }

    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        flex-wrap: wrap;

        p {
            margin: 0;
        }

        > :nth-child(even) {
            background-color: ${colors.WHITE};
        }
    }

    h2, h3 {
        color: ${colors.DARK_GREY};
    }
`;

export const HelpBox = styled.div`
    margin: 10px;
    background: grey;
`;

export const PageTitle = styled.div`
    font-weight: 300;
    font-size: 4.8rem;
    margin-bottom: 32px;

    &.justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;
export const CategoryTitle = styled.div`
    font-size: 2.4rem;
    margin-top: 64px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`;
export const CardTitle = styled.div`
    font-size: 2.4rem;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`;

export const DivisionRow = styled.div`
    margin-bottom: 80px;

    .delivery-type {
        .rw-list {
            margin: 0;
        }

        .rw-select-list-label {
            margin-right: 3rem;
            font-size: 1.4rem;
            line-height: 2.1rem;
            display: inline-flex;
            align-items: center;
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            user-select: none;
            flex-wrap: wrap;

            .rw-select-list-input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark {
                    background-color: ${colors.BRAND_ORANGE};

                    &:after {
                        display: block;
                        left: 5px;
                        top: 1px;
                        width: 7px;
                        height: 11px;
                        border: solid ${colors.WHITE};
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 21px;
                width: 21px;
                border: 1.5px solid ${colors.BLACK};
                background-color: ${colors.WHITE};
                border-radius: 2px;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
            }
        }
    }
`;

export const PaymentMethod = styled.div`
    margin: 56px 0;
`;

export const InputUnit = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-top: 45px;
`;

export const MeasurementItemProperty = styled.div`
    height: 56px;
    font-size: 14px;
    line-height: 17px;
`;

export const TextProperty = styled.div`
    font-size: 1.4rem;
    margin-bottom: 32px;
    font-weight: 500;

    .title {
        margin-bottom: 10px;
    }

    .text {
        font-weight: 800;
        font-size: 1.6rem;
    }

    .justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    hr {
        border-color: #000;
        margin: 1.5rem 0 3rem;
    }
`;

export const ContentBoxLightGrey = styled.div`
    background-color: ${colors.LIGHT_GREY};
    padding: 32px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 100%;
    margin: 40px 0;
    position: relative;
    flex-wrap: wrap;

    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        flex-wrap: wrap;

        p {
            margin: 0;
        }

        > :nth-child(even) {
            background-color: ${colors.WHITE};
        }
    }

    h2, h3 {
        color: ${colors.DARK_GREY};
    }
`;

export const EditButtonGroup = styled.div`
    text-align: right;
`;

export const LineButton = styled.a`
    display: inline-flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    cursor: pointer;
    color: ${colors.BLACK};
    text-decoration: none;
    padding: 8px 17px;
`;

export const MeasurementTab = styled.div`
    font-weight: 500;
    font-size: 1.6rem;

    .rw-list-option {

        .rw-select-list-label {
            background-color: ${colors.COOL_GREY};
            border: 1px solid ${colors.BLACK};
            padding: 16px 41px;
            line-height: 1;
            box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.2);
            margin: 0;
            cursor: pointer;

            .rw-select-list-input {
                display: none;
            }
        }

        &[aria-checked="true"] {
            .rw-select-list-label {
                background-color: ${colors.WHITE};
                box-shadow: none;
            }
        }
    }
`;

export const CheckBoxColumns = styled.div`
    .rw-list-option {
        width: ${({ column }) => `${100 / column}%`};
    }

    .rw-list {
        margin: 0;
    }

    .rw-select-list-label {
        margin-right: 3rem;
        font-size: 1.4rem;
        line-height: 2.1rem;
        display: inline-flex;
        align-items: center;
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        user-select: none;
        flex-wrap: wrap;

        .rw-select-list-input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .checkmark {
                background-color: ${colors.BRAND_ORANGE};

                &:after {
                    display: block;
                    left: 5px;
                    top: 1px;
                    width: 7px;
                    height: 11px;
                    border: solid ${colors.WHITE};
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 21px;
            width: 21px;
            border: 1.5px solid ${colors.BLACK};
            background-color: ${colors.WHITE};
            border-radius: 2px;

            &:after {
                content: "";
                position: absolute;
                display: none;
            }
        }
    }
`;

export const Terms = styled.div`
    margin-bottom: 72px;
`;

export const CheckBox = styled.div`
    .rw-list {
        margin: 0;
    }

    .rw-select-list-label {
        margin-right: 3rem;
        font-size: 1.4rem;
        line-height: 2.1rem;
        display: inline-flex;
        align-items: center;
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        user-select: none;
        flex-wrap: wrap;

        .rw-select-list-input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .checkmark {
                background-color: ${colors.BRAND_ORANGE};

                &:after {
                    display: block;
                    left: 5px;
                    top: 1px;
                    width: 7px;
                    height: 11px;
                    border: solid ${colors.WHITE};
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 21px;
            width: 21px;
            border: 1.5px solid ${colors.BLACK};
            background-color: ${colors.WHITE};
            border-radius: 2px;

            &:after {
                content: "";
                position: absolute;
                display: none;
            }
        }
    }
`;
export const ItemContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const FileUploadContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding: 18px 8px;
    order: 99;

    &.with-dot {
        margin-bottom: 57px;
        padding: 0;

        &:after {
            content: '';
            width: calc(100% - 200px);
            background-image: linear-gradient(to right, ${colors.DARK_GREY} 33%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 6px 2px;
            background-repeat: repeat-x;
            height: 2px;
        }
    }

    &.with-background {
        background-color: ${colors.WHITE};
        border-bottom: 1px solid ${colors.COOL_GREY};

        p {
            margin: 0;
        }
    }
`;

export const FileUpload = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    .hidden-button {
        display: none;
    }
`;

export const FileUploadLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: ${colors.DARK_GREY};
    margin-bottom: 0;
    margin-left: 8px;
`;

export const FullRow = styled.span`
    width: 100%;
    .row {
        width: 100%
    }
`;
