import thunk from 'redux-thunk';
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import reducers from './reducers';

const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['profile'],
};

const pReducer = persistReducer(persistConfig, reducers);

export default function configureStore(preloadedState) {
  const store = createStore(
    pReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunk)),
  );
  const persistor = persistStore(store);

  return {
    store,
    persistor
  };
}
