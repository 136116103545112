import styled from 'styled-components';
import Background from '../../../assets/image/Background.jpeg';
import ArrowRightWhite from '../../../assets/icons/ArrowRightWhite.svg';
import ArrowRightOrange from '../../../assets/icons/ArrowRightOrange.svg';
import ArrowRightBlack from '../../../assets/icons/ArrowRightBlack.svg';
import { colors, MediaQuery } from '../../../theme/style-helpers';

export const BannerSection = styled.section`
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), url(${Background});
    background-size: cover;
    background-position: center center;
    padding: 24px 32px;
    margin-top: 32px;

    .signup__copy {
        display: none;
    }

    ${MediaQuery.large`
        margin-top: 0;

        .signup__copy {
            display: block;
        }
    `}
`;

export const PrimaryButton = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 16px 56px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;

    &.min-width {
        min-width: 200px;
    }

    ${MediaQuery.large`
        width: auto;
    `}

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowRightWhite});
        background-size: cover;
    }

    &.submit, &.active {
        background-color: ${colors.BLACK};

        .icon {
            background: url(${ArrowRightOrange});
        }

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};

            .icon {
                background: url(${ArrowRightBlack});
            }
        }
    }
`;
export const SecondaryButton = styled.button`
    background-color: ${colors.WHITE};
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    padding: 16px 56px;
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    margin-left: 0;
    width: 100%;
    order: 1;
    cursor: pointer;

    &.min-width {
        min-width: 200px;
    }


    ${MediaQuery.large`
        width: auto;
    `}

    .icon-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowRightOrange});
        background-size: cover;
    }

    &.no-border {
        border: none;
    }

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    &.hidden {
        display: none;
    }

`;
