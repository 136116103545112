import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import { P } from '../../theme/typographySitecore';
import Row from '../ReactComponent/Row';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import Column from '../ReactComponent/Column';

const TitleSize = {
  ForMobile: '2.8rem',
  NonMobile: '4.8rem'
};
const MAX_CONTENT_WIDTH = '1214px';

export const ContactUsPageContainer = styled.div.attrs(() => ({ className: 'row' }))`
    padding-left: 0;
    text-align: center;
    width: 100%;
    margin: 0 !important;
`;

export const ContactusBgContainer = styled.div`
    background-image: url(${(props) => props.$img});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 234px;
    width: 100vw;
    display: block;
    margin: 0;
`;

export const HeaderRow = styled.div.attrs(() => ({
  className: 'row justify-content-center no-gutters'
}))`
    width: 100%;
    padding: 0;
`;

export const HeaderContainer = styled.div.attrs(() => ({
  className: 'container-fluid'
}))`
    max-width: ${MAX_CONTENT_WIDTH};
    padding: 0;
    margin-top: 32px;
`;

export const HeaderTitleContainer = styled.div`
    text-align: left;
    padding: 0;
    margin-bottom: 10px;
    margin-left: 0;
`;

const TitleBase = styled.div`
    font-weight: 300;
    font-size: ${TitleSize.ForMobile};
    ${MediaQuery.medium`
    font-size: ${TitleSize.NonMobile};

  `}
`;

export const HeaderTitle = styled(TitleBase)`
    height: 80px;
`;

export const HeaderDescription = styled.div`
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 20px;
    color: ${colors.BLACK};
    text-decoration: none;
`;

export const ContactDetailsContainer = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 25px;
    ${MediaQuery.medium`
      align-items:flex-end;
      margin-right:5px;
    `}
`;

export const ContactDetailsRow = styled.div.attrs(() => ({ className: 'row justify-content-end no-gutters' }))`
    direction: rtl;

    ${MediaQuery.medium`
      direction:ltr;
    `}
    width: 170px;

`;

export const PhoneContainer = styled(Row)`
    vertical-align: top;
    margin-top: 10px;
    direction: rtl;
    ${MediaQuery.medium`
      direction:ltr;
    `}
`;

export const PhoneText = styled(Column)`
    text-align: left;
    width: 100px;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 20px;
    color: ${colors.BLACK};
    text-decoration: none;

    ${MediaQuery.medium`
      text-align: right;
    `}
`;

export const PhoneIconContainer = styled(Column)`
    text-align: right;
`;

export const AddressContainer = styled(Row)`
    height: 80px;
    direction: rtl;
    ${MediaQuery.medium`
      direction:ltr;
    `}
`;

export const AddressText = styled(Column)`
    text-align: left;
    width: 100px;
    ${MediaQuery.medium`
      text-align: right;
    `}
`;

export const AddressTextContainer = styled.div`
    margin-bottom: 10px;
`;

export const AddressIcon = styled(Column)`
    text-align: right;
    padding: 0;
    margin-left: 0;
`;

export const AddressLine = styled.div`
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 20px;
    color: ${colors.BLACK};
    text-decoration: none;
`;

export const ReasonContainer = styled.div`
    background-image: url(${(props) => props.img});
    background-position: center;
    background-repeat: no-repeat;
    height: 390px;
    width: 100vw;
    display: block;
    margin: 0;
`;

export const ReasonActionsContainer = styled.div`
    text-align: right;
`;

export const AlignedFluidContainer = styled.div.attrs(() => ({
  className: 'container-fluid',
}))`
    max-width: ${MAX_CONTENT_WIDTH};
    padding: 0;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const ReasonOverlayContainer = styled(AlignedFluidContainer)`
    padding: 0 !important;
    ${MediaQuery.small`
      padding:15px!important;
    `}
`;

export const ReasonOverlay = styled.div`
    height: calc(100% - 100px);
    width: 100%;
    padding: 15px;
    background: rgba(255, 255, 255, 0.85);

    ${MediaQuery.small`
      max-width:380px;
    `}

    ${MediaQuery.medium`
      padding:45px;
    `}
`;

export const ReasonTitle = styled.div`
    text-align: left;
    font-size: 2.2rem;
    margin-top: 24px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`;

export const ReasonPrefix = styled.div`
    text-align: left;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: ${colors.BLACK};
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 24px;
    display: inline-block;
`;

export const FieldContainer = styled(Column)`
    flex-basis: unset !important;
`;

export const ReferenceNumberContainer = styled(Row)`
    padding-top: 34px;
    margin-bottom: 34px;
    background-color: ${colors.LIGHT_GREY};
`;

export const ReferenceNumberSummary = styled(P)`
    padding-top: 24px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 72px;
    flex-wrap: wrap;
`;

export const ResetButton = styled(PrimaryButton)`
    margin-bottom: 16px;

    ${MediaQuery.medium`
      margin-bottom: 0;
      margin-right: 16px;
    `}
`;

export const GetInTouchContactsContainer = styled(Column)`
    text-align: left;
    width: 50%;
`;

export const GetInTouchContactsTitle = styled.div`
    font-size: 2.0rem;
    font-weight: 500;
    ${MediaQuery.medium`
      font-size: 2.4rem;
    `}
`;

export const EmptyLine = styled.div`
    height: 32px;
`;

export const GetInTouchPane = styled(AlignedFluidContainer)`
    max-width: 1004px;
`;

export const GetInTouchContactsIcon = styled(Column)`
    width: 50px;
`;

export const GetInTouchContactsText = styled(Column)`
    text-align: left;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: ${colors.BLACK};
`;

export const GetInTouchContactLine = styled(Row)`
    margin-top: 20px;
    margin-bottom: 20px;
    width: 400px;
`;

export const ChatLinkContainer = styled(GetInTouchContactLine)`
    cursor: pointer;
`;

export const GetInTouchFieldsContainer = styled(Column)`
    text-align: left;
    width: 50%;
`;

export const GetInTouchContainer = styled(Row)`
    width: 100%;
`;

export const GetInTouchTitle = styled(Column)`
    font-size: ${TitleSize.ForMobile};

    ${MediaQuery.medium`
      font-size: ${TitleSize.NonMobile};
    `}

    font-weight: 300;
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const ContactUsFormFieldContainer = styled(Column)`
    height: 100px;
`;

export const ContactUsActionButton = styled(PrimaryButton)`
    margin-bottom: 18px;
    margin-left: 0;
    ${MediaQuery.medium`
      margin-left:10px;
    `}
`;

export const FormActionButtonsContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    padding: 10px;
    ${MediaQuery.medium`
    flex-direction: row;
    justify-content: flex-end
  `}
`;

export const SubmitSuccess = styled.div`
    color: black;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
`;
