import React from 'react';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { Div } from '../../theme/typographySitecore';
import {
  SectionTitle, Section, LeftColumn, RightColumn, ButtonGroup
} from './css';
import { anchor } from '../../utils/func';

const MediaContent = ({ fields }) => {
  const { primaryLink } = fields;
  const hasPrimary = primaryLink && primaryLink.value && primaryLink.value.href;
  const { secondaryLink } = fields;
  const hasSecondary = secondaryLink && secondaryLink.value && secondaryLink.value.href;

  return (<>
    {fields.heading && fields.heading.value &&
      <SectionTitle>
        <Text field={fields.heading}/>
      </SectionTitle>}
    {fields.content && <Div field={fields.content}/>}
    {(hasPrimary || hasSecondary) &&
      <ButtonGroup className="right">
          {hasSecondary &&
              <Link field={secondaryLink} className="secondaryButton">
                  <span className="icon"></span>{secondaryLink.value.text}
              </Link>}
          {hasPrimary &&
              <Link field={primaryLink} className="primaryButton submit">
                  <span className="icon"></span>{primaryLink.value.text}
              </Link>}
      </ButtonGroup>}
  </>);
};

// eslint-disable-next-line react/no-multi-comp
const ArticleSectionTwoColumns = ({ fields }) => {
  const swapContentTwo = fields.swapContentTwo && fields.swapContentTwo.value;
  const id = fields.anchor && fields.anchor.value ? anchor(fields.anchor.value) : '';
  return (
    <Section id={id} className="static-section">
      <LeftColumn className={swapContentTwo ? 'flex-40' : 'flex-60'}>
        {swapContentTwo ?
          (
            fields.contentTwo && <Div field={fields.contentTwo}/>
          ) :
          <MediaContent fields={fields}/>}
      </LeftColumn>
      <RightColumn className={swapContentTwo ? 'flex-60' : 'flex-40'}>
        {swapContentTwo ?
          <MediaContent fields={fields}/> :
          (
            fields.contentTwo && <Div field={fields.contentTwo}/>
          )}
      </RightColumn>
    </Section>
  );
};

export default ArticleSectionTwoColumns;
