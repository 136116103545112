import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';

export const getCompanyContacts = (companyID) =>
  axios.get(`${t('ApiEndPoint')}${t('AddressApiVirtualFolder')}${endpoints.address.contacts}?companyId=${companyID}`);
export const createContact = (data) =>
  axios.post(`${t('ApiEndPoint')}${t('AddressApiVirtualFolder')}${endpoints.address.contacts}`, data);
export const updateContactById = (data, contactID) =>
  axios.put(`${t('ApiEndPoint')}${t('AddressApiVirtualFolder')}${endpoints.address.contacts}/${contactID}`, data);
export const deleteContactByIds = (contactIDs) =>
  axios.delete(`${t('ApiEndPoint')}${t('AddressApiVirtualFolder')}${endpoints.address.contacts}?contactIds=${contactIDs}`);
export const getContactInfo = (contactID) =>
  axios.get(`${t('ApiEndPoint')}${t('AddressApiVirtualFolder')}${endpoints.address.contacts}/${contactID}`);
export const getContactPermissionInfo = (division, userId) =>
  axios.get(`${t('ApiEndPoint')}${t('AddressApiVirtualFolder')}${endpoints.address.contacts}/${division}/${userId}`);
