import styled from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';

export const ShipmentDetailsDiv = styled.div`
    .column {
        display:inline-block;
        vertical-align:top;
        height:100%;

        &.left {
            width:100%;

            ${MediaQuery.medium`
                width:50%;
                margin-right:20px;
            `}        

        }

        &.right {
             ${MediaQuery.medium`
                display:inline-block;
                width: calc(50% - 20px);
            `} 
        }
    }
    
    ${MediaQuery.medium`
        height:440px;
    `} 
`;

export const AddressHeaderDiv = styled.div`

    .addressdetails{
        padding-left:10px;
    }

    span {
        display:inline-block;
        width:100%;
    }

    .title {
        > div {
            display: inline-block;
            margin: 0;
            position: relative;
            bottom: -5px;
        }
    }
`;

export const HistoryDiv = styled.div`
    margin-top: 20px;
    overflow-y: auto;
    padding-right:10px;
    width: 100%;
    ${MediaQuery.medium`
        height: 500px; 
    `} 
`;

export const HistoryEntryDiv = styled.div`
    width: 100% !important;
    margin: 10px 0;
    border-left: solid ${colors.SEA_BLUE} 5px;
    padding:  0 5px 20px 5px;


    &.highlighted {
        border-left-color: ${colors.BRAND_ORANGE};
    }

    &.selected {
        border-left: 5px solid ${colors.BRAND_ORANGE};
        border-right: 1px solid ${colors.BRAND_ORANGE};
        border-top: 1px solid ${colors.BRAND_ORANGE};
        border-bottom: 1px solid ${colors.BRAND_ORANGE};
    }

    .number{
        display:inline-block;
        width:70%;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.7rem;
        text-align: center;
    }

    .status{
        display:inline-block;
        width:70%;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.7rem;
    }

    .date{
        display:inline-block;
        width:30%;
        text-align:right;
        font-size: 1.3rem;
        line-height: 1.7rem;
    }

    .address{
        font-size: 1.2rem;
        line-height: 1.3rem;
    }

    .heading, .location {
        padding:2px 0 2px 10px;
    }
`;
