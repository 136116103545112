import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { P } from '../../../../theme/typographySitecore';
import ShipmentDetails from '../index';
import GreyBoxWithBorder from '../../../_styledComponents/GreyBoxWithBorder';

const ShipmentDetailsWithErrors = ({
  showError,
  basicData,
  detailsData,
  division,
  isAuthenticated
}) => {
  if (showError) {
    return (
      <GreyBoxWithBorder>
        <P field={t('ShipmentDetailsError')}/>
      </GreyBoxWithBorder>
    );
  }

  if (!detailsData || detailsData.length <= 0) {
    return (
      <GreyBoxWithBorder>
        <P field={t('ShipmentDetailsNoData')}/>
      </GreyBoxWithBorder>
    );
  }

  return (
    <GreyBoxWithBorder>
      <ShipmentDetails
        key="0"
        mapid="google-map-0"
        basicData={basicData}
        division={division}
        detailsData={detailsData}
        isAuthenticated={isAuthenticated}/>
    </GreyBoxWithBorder>
  );
};

ShipmentDetailsWithErrors.propTypes = {
  showError: PropTypes.bool,
  basicData: PropTypes.object,
  detailsData: PropTypes.array,
  division: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};

export default ShipmentDetailsWithErrors;
