import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrowIcon,
  ButtonContainer,
  ButtonDescription,
  ButtonTitle, IconContainer,
  TextContainer,
} from './css';

const LargeButton = ({
  title,
  description,
  onClick
}) => (
  <ButtonContainer onClick={onClick}>
    <TextContainer>
      <ButtonTitle field={title}/>
      <ButtonDescription field={description}/>
    </TextContainer>
    <IconContainer>
      <ArrowIcon/>
    </IconContainer>
  </ButtonContainer>
);

LargeButton.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};

export default LargeButton;
