import styled from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';

export const PrimaryButton = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 16px 56px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    order: 0;
    height: 52px;

    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        margin-bottom: 0;
        order: 1;
    `}
    &.submit, &.active {
        background-color: ${colors.BLACK};

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};
        }
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
`;

export const PageTitle = styled.div`
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;
`;
export const SmallDesc = styled.div`
    font-size: 12px;
    line-height: 16px;
`;
export const ErrorIcon = styled.img`
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    height: 38px;
    display: block;
    width: 100%;
`;

export const AddressPickerModalBody = styled.div`
    font-size: 1.5rem;
    text-align: left;

    .checkbox-container {
        margin-bottom: 0;
    }

    .col {
        vertical-align: top;
        margin-bottom: 10px;
        ${MediaQuery.medium`
            display: inline-block;
            width:40%;            
            padding-left: 0;
            margin-top:10px !important;
        `}
    }
`;
