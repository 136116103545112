import React from 'react';
import { t } from 'i18next';
// import { toDate } from 'date-fns';
import { getMonthDayYear, formatMoney } from '../../../utils/func';
import TariffSurchargeSummaryBlock from '../../ReactComponent/TariffSurchargeSummaryBlock';
import {
  PageTitle,
  CategoryTitle,
  TextProperty,
  Terms,
  PrimaryButton,
  ButtonGroup
} from '../css';
import { H2, H3, P } from '../../../theme/typography';
import SummaryForm from '../../ReactComponent/FormSummaryBlock';
import GreyBoxWithBorder from '../../_styledComponents/GreyBoxWithBorder';
import { routes } from '../../../utils/constants';

const renderBottomNav = (isAuthenticated) => {
  const userRedirector = isAuthenticated ? routes.viewUserDashboard : '/';
  return (
    <ButtonGroup>
      <PrimaryButton
        className="submit"
        onClick={() => {
          if (window && typeof window !== 'undefined') {
            window.location.href = userRedirector;
          } else {
            window.location.href = '';
          }
          return window.location.href;
        }}>
        <span className="icon" alt="exit" />
        {t('Exit')}
      </PrimaryButton>
    </ButtonGroup>
  );
};
const sixDigitQuote = (quoteNumber) => {
  const quoteValue = quoteNumber.toString().split('');
  const quoteLength = quoteValue.length;
  for (let i = 0; i < 6 - quoteLength; i++) {
    quoteValue.unshift('0');
  }
  return quoteValue.join('');
};
const Step5 = ({
  formData = {}, serviceLevelQuotes, chosenServiceLevelCode, quoteData = {}, USQuote, authenticated, parentFields, billingAccounts
}) => {
  // eslint-disable-next-line no-shadow
  const service = serviceLevelQuotes.filter((service) => service.serviceLevelCode === chosenServiceLevelCode)[0];
  const expectedDeliveryDate = USQuote ? quoteData.expectedDeliveryDate : service.expectedDeliveryDate;
  const description = service ? service.description : '';
  let totalAmount = service ? service.totalAmount : 0;
  const getCharges = () => {
    if (quoteData && quoteData.charges) {
      return quoteData.charges;
    }
    if (service) {
      return service.charges;
    }
    return [];
  };
  const charges = getCharges();
  const {
    netCharge,
    // quoteNumber,
    expiryDate,
    fuelSurcharge,
    discountPercentage,
    minimumRate,
    undiscountedCharge,
    minimumCharge,
    discountAmount,
    customsProcessingFee,
    accessorialCharge,
    totalCharge,
  } = quoteData;
  const protectedAccount = billingAccounts && billingAccounts.some((a) => a.accountNumber === formData.billTo && a.protected);
  if (quoteData.totalAmount) { totalAmount = quoteData.totalAmount; }
  quoteData.quoteNumber = sixDigitQuote(quoteData.quoteNumber);

  return (
    <>
      <PageTitle>{t('Your Quote')} - <strong>{quoteData.quoteNumber}</strong></PageTitle>
      <p>{parentFields.yourQuoteHeading && parentFields.yourQuoteHeading.value}</p>
      <CategoryTitle>{t('Summary')}</CategoryTitle>
      {USQuote ? <div className="row">
        <div className="col-sm-4">
          <GreyBoxWithBorder>
            <TextProperty>
              <P className="title">{t('Service Level')}</P>
              <H3>{t('General')}</H3>
            </TextProperty>
          </GreyBoxWithBorder>
          <GreyBoxWithBorder>
            <TextProperty>
              <P className="title">
                {t('Expected Delivery Date')}<br />
                <span className="small">({t('ShippedTodayText')})</span>
              </P>
              <H3>{getMonthDayYear(expectedDeliveryDate)}</H3>
            </TextProperty>
          </GreyBoxWithBorder>
        </div>
        <div className="col-sm-8">
          <TariffSurchargeSummaryBlock tariffSurcharge={formData.tariffSurcharge} tablet={12} mobile={12} />
          <GreyBoxWithBorder>
            {(minimumCharge && totalCharge) ? (
                <TextProperty>
                    <P className="title">
                      {t('Price')}
                    </P>
                    {undiscountedCharge ?
                      <H3 className="justified">
                        <span>{t('Undiscounted Charge')}</span> <span>{formatMoney(undiscountedCharge, protectedAccount)}</span>
                      </H3>
                      : <H3 className="justified"><span>{t('Minimum Charge')}</span> <span>{formatMoney(minimumCharge, protectedAccount)}</span></H3>}
                    <H3 className="justified">
                      <span>{`${discountPercentage}% ${t('Discount')}`}</span> <span>{formatMoney(discountAmount, protectedAccount)}</span>
                    </H3>
                    {netCharge ?
                    <H3 className="justified"><span>{t('Net Charge')}</span> <span>{formatMoney(netCharge, protectedAccount)}</span></H3>
                      : <H3 className="justified"><span>{t('Minimum Rate')}</span> <span>{formatMoney(minimumRate, protectedAccount)}</span></H3>}
                    <H3 className="justified"><span>{t('Fuel Surcharge')}</span> <span>{formatMoney(fuelSurcharge, protectedAccount)}</span></H3>
                    <H3 className="justified">
                      <span>{t('Customs Processing Fee')}</span> <span>{formatMoney(customsProcessingFee, protectedAccount)}</span>
                    </H3>
                    <H3 className="justified">
                      <span>{t('Additional Charges')}</span> <span>{formatMoney(accessorialCharge, protectedAccount)}</span>
                    </H3>
                    <hr />
                    <H2 className="justified">
                      <span>{t('Total')}</span> <span>{formatMoney(totalCharge.value || totalCharge, protectedAccount)}</span>
                    </H2>
                    <P className="text-right">{t('ThisQuoteWillExpireOn')}
                      <span className="pl-2 font-weight-normal">{getMonthDayYear(new Date(new Date().getTime() + (86400000 * 30)))}</span>
                    </P>
                </TextProperty>
            ) : (
                <TextProperty>
                  <H3>{t('QuoteNotificationEmail')}</H3>
                </TextProperty>
            )}
          </GreyBoxWithBorder>
        </div>
      </div> :
        <div className="row">
          <div className="col-sm-4">
            <GreyBoxWithBorder>
              <TextProperty>
                <P className="title">{t('Service Level')}</P>
                <H3>{description}</H3>
              </TextProperty>
            </GreyBoxWithBorder>
            <GreyBoxWithBorder>
              <TextProperty>
                <P className="title">
                  {t('Expected Delivery Date')}<br />
                  <span className="small">({t('ShippedTodayText')})</span>
                </P>
                <H3>{getMonthDayYear(expectedDeliveryDate)}</H3>
              </TextProperty>
            </GreyBoxWithBorder>
          </div>
          <div className="col-sm-8">
            <GreyBoxWithBorder>
              {!totalAmount || totalAmount.value === 0 ? (
                <TextProperty>
                  <H3>{t('DomesticQuoteNotificationEmail')}</H3>
                </TextProperty>
              ) : (
                  <TextProperty>
                    <P className="title">
                      {t('Price')}
                    </P>
                    {charges.map((charge, index) => (
                      <H3
                      className="justified"
                      key={index}>
                        <span>{charge.description}</span> <span>{formatMoney(charge.amount.value, protectedAccount)}</span>
                      </H3>
                    ))}
                    <hr />
                    <H2 className="justified"><span>{t('Total')}</span> <span>{formatMoney(totalAmount.value, protectedAccount)}</span></H2>
                    <P className="text-right">{t('ThisQuoteWillExpireOn')}{getMonthDayYear(expiryDate)}</P>
                  </TextProperty>
              )}
            </GreyBoxWithBorder>
          </div>
        </div>}
      <SummaryForm formData={formData} USQuote={USQuote} quote hideDangerousItem />
      <Terms>
        <CategoryTitle>{t('Additional Information')}</CategoryTitle>
        <P>{parentFields.AdditionalInformationDesc && parentFields.AdditionalInformationDesc.value}</P>
        <P><a href="#">{t('ReadMore')}</a></P>
      </Terms>
      <Terms>
        <CategoryTitle>{t('TermsConditions')}</CategoryTitle>
        <P>{t('TermsConditionsSummary')}</P>
      </Terms>
      {renderBottomNav(authenticated)}
    </>
  );
};

export default Step5;
