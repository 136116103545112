import React from 'react';
import { t } from 'i18next';
import { AddressBookText } from '../../ReactComponent/AddressBookEntry';
import MeasurementItemCard from '../../ReactComponent/MeasurementItemCard';
import TotalWeightBlock from '../../ReactComponent/TotalWeightBlock';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import Card from '../../ReactComponent/Card';
import { AccessorialsText } from '../../ReactComponent/FormSummaryBlock';
import Edit from '../../../assets/icons/Edit.svg';
import {
  AddressBlock,
  ContentBoxLightGrey,
  PageTitle,
  CategoryTitle,
  BlockTitle,
  Icon,
  TextProperty,
  LineButton, UploadedDocumentsSummary
} from '../css';
import { H2, H3, P } from '../../../theme/typography';
import { AddressTypes, PaymentMethods, BillToMethods } from '../../../utils/enums';
import { formatMoney, getMonthDayYear } from '../../../utils/func';
import UploadDocuments from '../../ReactComponent/UploadDocuments';
import UploadDocumentsRenderModes from '../../ReactComponent/UploadDocuments/constants';
import GreyBoxWithBorder from '../../_styledComponents/GreyBoxWithBorder';

export const SummaryForm = ({
  formData = {},
  editStepOne,
  editStepTwo,
  editStepThree,
  editStepFour,
  hideCube,
  showDensity,
  parentFields,
}) => {
  const {
    paymentMethod: { name },
    measurementList,
    measurementType,
    pickupDate,
    accessByTrailer,
    referenceNumber,
    poNumber,
    specialInstructions,
    closingTime,
    readyTime,
    regularScheduledPickup,
    emergencyPhone,
    erapReference,
    erapPhone,
    billTo,
    billToMethod,
    thirdPartyAccountNumber,
    gstNumber,
    USShipment,
  } = formData;
  return (
    <>
      <CategoryTitle>
        {parentFields.step5CategoryTitle1 && parentFields.step5CategoryTitle1.value}
        {editStepFour && (
          <LineButton onClick={editStepFour}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step one" />
          </LineButton>
        )}
      </CategoryTitle>
      <ContentBoxLightGrey>
        <Row>
          <Column $tablet={6} $mobile={12}>
            {!regularScheduledPickup.value && <TextProperty>
              <P className="title">{t('PickupDate')}</P>
              <P>{getMonthDayYear(pickupDate)}</P>
            </TextProperty>}
            <TextProperty>
              <P className="title">{t('AccessibleByTrailer')}</P>
              <P>{accessByTrailer.name}</P>
            </TextProperty>
          </Column>
          {!regularScheduledPickup.value &&
            <Column $tablet={6} $mobile={12}>
              {readyTime && <TextProperty>
                <P className="title">{t('ReadyTime')}</P>
                <P>{readyTime.hours}:{readyTime.minutes} {readyTime.amOrPm}</P>
              </TextProperty>}
              {closingTime && <TextProperty>
                <P className="title">{t('ClosingTime')}</P>
                <P>{closingTime.hours}:{closingTime.minutes} {closingTime.amOrPm}</P>
              </TextProperty>}
            </Column>}
          {specialInstructions && <Column $tablet={6} $mobile={12}>
            <TextProperty>
              <P className="title">Special instruction to shipment</P>
              <P>{specialInstructions}</P>
            </TextProperty>
          </Column>}
        </Row>
      </ContentBoxLightGrey>

      {/* --- Uploaded Documents --- */}
      <CategoryTitle>
        {parentFields.uploadedDocumentsHeader.value}
        {editStepFour && (
          <LineButton onClick={editStepFour}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step four" />
          </LineButton>
        )}
      </CategoryTitle>
      <UploadedDocumentsSummary field={parentFields.uploadedDocumentsSummary} />
      <UploadDocuments numberOfPlaceholders={4} renderMode={UploadDocumentsRenderModes.REVIEW} />

      <CategoryTitle>
        {parentFields.step5CategoryTitle2 && parentFields.step5CategoryTitle2.value}
        {editStepOne && (
          <LineButton onClick={editStepOne}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step one" />
          </LineButton>
        )}
      </CategoryTitle>
      <Row>
        <Column $tablet={6} $mobile={12}>
          <AddressBlock className="with-bg">
            <H3>
              {t('ShipperDetails')}
            </H3>
            <AddressBookText contactType={AddressTypes.SHIPPER} formData={formData} />
          </AddressBlock>
        </Column>
        <Column $tablet={6} $mobile={12}>
          <AddressBlock className="with-bg">
            <H3>
              {t('ConsigneeDetails')}
            </H3>
            <AddressBookText contactType={AddressTypes.CONSIGNEE} formData={formData} />
          </AddressBlock>
        </Column>
        <Column $tablet={12} $mobile={12}>
          <AddressBlock className="with-bg">
            <H3>
              {t('BillingInformation')}
            </H3>
            <TextProperty>
              <P className="title">{t('PaymentMethod')}</P>
              <P>{t(name)}</P>
              {name === PaymentMethods.ThirdParty &&
                <Row>
                  <Column $tablet={6} $mobile={12}>
                    {thirdPartyAccountNumber && billToMethod === BillToMethods.enterBillTo && <>
                      <P className="title">{t('Bill To')}</P>
                      <P>{thirdPartyAccountNumber}</P>
                    </>}
                    {billTo && <>
                      <P className="title">{t('Bill To')}</P>
                      <P>{billTo}</P>
                    </>}
                    <AddressBookText contactType="billing" formData={formData} />
                  </Column>
                  <Column $tablet={6} $mobile={12}>

                    <P className="title">{t('GstNumber')}</P>
                    <P>{gstNumber}</P>
                  </Column>
                </Row>}
            </TextProperty>
            {billTo && name !== PaymentMethods.ThirdParty && <TextProperty>
                <P className="title">{t('BillTo')}</P>
                <P>{billTo}</P>
              </TextProperty>}
          </AddressBlock>
        </Column>
      </Row>
      <CategoryTitle>
        {parentFields.step5CategoryTitle3 && parentFields.step5CategoryTitle3.value}
        {editStepTwo && (
          <LineButton onClick={editStepTwo}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step two" />
          </LineButton>
        )}
      </CategoryTitle>
      <MeasurementItemCard
        formName="CreateShipmentForm"
        measurementList={measurementList}
        measurementType={measurementType.value}
        hideButtons
        hideCube={hideCube}
        showDensity={showDensity}
        USShipment={USShipment}
      />
      <TotalWeightBlock
        measurementList={measurementList}
        measurementType={measurementType.value}
        totalNumber
      />
      {emergencyPhone && <ContentBoxLightGrey>
        <BlockTitle>
          {t('Additional Information')}
        </BlockTitle>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <TextProperty>
              <P className="title">{t('EmergencyNumber')}</P>
              <P>{emergencyPhone ? emergencyPhone : 'N/A'}</P>
            </TextProperty>
            <TextProperty>
              <P className="title">{t('ERAPReferenceNumber')}</P>
              <P>{erapReference ? erapReference : 'N/A'}</P>
            </TextProperty>
            <TextProperty>
              <P className="title">{t('ERAPPhoneNumber')}</P>
              <P>{erapPhone ? erapPhone : 'N/A'}</P>
            </TextProperty>
            <TextProperty>
              <P className="title">{t('ReferenceNumber')}</P>
              <P>{referenceNumber}</P>
            </TextProperty>
            <TextProperty>
              <P className="title">{t('PurchaseOrderReferenceNumber')}</P>
              <P>{poNumber}</P>
            </TextProperty>
          </Column>
        </Row>
      </ContentBoxLightGrey>}
      <CategoryTitle>
        <div>
          {parentFields.step5CategoryTitle4 && parentFields.step5CategoryTitle4.value}
          <P style={{ marginTop: '10px' }}>{parentFields.step5CategoryTitle4Paragraph && parentFields.step5CategoryTitle4Paragraph.value}</P>
        </div>
        {editStepThree && (
          <LineButton onClick={editStepThree}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step three" />
          </LineButton>
        )}
      </CategoryTitle>
      <AccessorialsText formData={formData} />
    </>
  );
};

// eslint-disable-next-line react/no-multi-comp
const Step5 = ({
  formData = {},
  serviceLevelCode,
  step,
  editStepOne,
  editStepTwo,
  editStepThree,
  serviceLevelQuotes,
  putReduxServiceLevelCode,
  editStepFour,
  parentFields,
  billingAccounts
}) => {
  const selectedServiceLevelInfo = serviceLevelQuotes.find((slq) => slq.serviceLevelCode === serviceLevelCode);
  const serviceLevelQuotesDataCopy = [...serviceLevelQuotes];
  const noSigData = serviceLevelQuotesDataCopy.filter((data) => data.serviceLevelCode === 'NS');
  const serviceLevelQuotesData = serviceLevelQuotesDataCopy.filter((data) => data.serviceLevelCode !== 'NS');
  if (noSigData && noSigData.length > 0) {
    serviceLevelQuotesData.unshift(noSigData[0]);
  }

  let protectedAccount = billingAccounts && billingAccounts.some((a) => a.accountNumber === formData.billTo && a.protected);
  if (formData && formData.division === 'Sameday' && formData.paymentMethod) {
    if (formData.paymentMethod.name === 'Collect'
    || ((formData.paymentMethod.name === 'Third Party' && protectedAccount) || formData.billToMethod === 'EnterBillTo')) {
      protectedAccount = true;
    }
  }

  if (step === 4) {
    return (
      <>
        <PageTitle>{parentFields.step5PageTitle && parentFields.step5PageTitle.value}</PageTitle>
        <CategoryTitle>{t('Summary')}</CategoryTitle>
        <Row>
          {serviceLevelQuotesData && serviceLevelQuotesData.map((service) => {
            // eslint-disable-next-line no-shadow
            const renderServiceLevelDescription = (serviceLevelCode) => {
              if (serviceLevelCode === 'GL') { return t('GeneralLTLDescription'); }
              if (serviceLevelCode === 'EG') { return t('GroundServiceDesciption'); }
              if (serviceLevelCode === 'AM') { return t('AMServiceDescription'); }
              if (serviceLevelCode === 'D9') { return t('9amGuaranteedDescription'); }
              if (serviceLevelCode === 'H1') { return t('Homeway1Descrition'); }
              if (serviceLevelCode === 'H2') { return t('Homeway2Descrition'); }
              if (serviceLevelCode === 'H3') { return t('Homeway3Descrition'); }
              if (serviceLevelCode === 'H4') { return t('Homeway4Descrition'); }
              if (serviceLevelCode === 'H5') { return t('Homeway5Descrition'); }
              if (serviceLevelCode === 'H6') { return t('Homeway6Descrition'); }
              if (serviceLevelCode === 'NSR') { return t('NoSignatureRequired'); }
              if (serviceLevelCode === 'UL') { return t('UrgentLetter'); }
              if (serviceLevelCode === 'UP') { return t('UrgentPac'); }
              return undefined;
            };
            return (
            <Column $tablet={4} $mobile={12} key={service.description} onClick={() => putReduxServiceLevelCode(service.serviceLevelCode)}>
              <Card
                title={service.description}
                callOut={formatMoney(service.totalAmount.value, protectedAccount)}
                desc={renderServiceLevelDescription(service.serviceLevelCode)}
                active={serviceLevelCode === service.serviceLevelCode ? true : false}
              />
            </Column>
            );
          })}
        </Row>
        <CategoryTitle>{parentFields.step6CategoryTitle2 && parentFields.step6CategoryTitle2.value}</CategoryTitle>
        <Row>
          <Column $tablet={4} $mobile={12}>
            <GreyBoxWithBorder>
              <TextProperty>
                <P className="title">{parentFields.createShipmentServiceLevel && parentFields.createShipmentServiceLevel.value}</P>
                <H3>{selectedServiceLevelInfo && selectedServiceLevelInfo.description}</H3>
              </TextProperty>
            </GreyBoxWithBorder>
            <GreyBoxWithBorder>
              <Row>
                <TextProperty>
                  <P className="title">{parentFields.createShipmentExpectedDeliveryDate && parentFields.createShipmentExpectedDeliveryDate.value}</P>
                  <H3>{selectedServiceLevelInfo && getMonthDayYear(selectedServiceLevelInfo.expectedDeliveryDate)}</H3>
                </TextProperty>
              </Row>
            </GreyBoxWithBorder>
          </Column>
          <Column $tablet={8} $mobile={12}>
            <GreyBoxWithBorder>
              {selectedServiceLevelInfo && selectedServiceLevelInfo.totalAmount.value === 0 ? (
                <TextProperty>
                  <H3>{parentFields.rateConfirmationH3 && parentFields.rateConfirmationH3.value}</H3>
                </TextProperty>
              ) : (
                  <TextProperty>
                    <P className="title">
                      {parentFields.createShipmentPriceTitle && parentFields.createShipmentPriceTitle.value}
                    </P>
                    {selectedServiceLevelInfo && selectedServiceLevelInfo.charges.map((charge) => (
                      <H3
                      className="justified"
                      key={charge.description}>
                        <span>{charge.description}</span> <span>{formatMoney(charge.amount.value, protectedAccount)}</span>
                      </H3>
                    ))}
                    <hr />
                    <H2 className="justified">
                      {/* eslint-disable-next-line max-len */}
                      <span>{parentFields.createShipmentTotal && parentFields.createShipmentTotal.value}</span> <span>{selectedServiceLevelInfo && formatMoney(selectedServiceLevelInfo.totalAmount.value, protectedAccount)}</span>
                    </H2>
                  </TextProperty>
              )}
            </GreyBoxWithBorder>
          </Column>
        </Row>
        <SummaryForm
          formData={formData}
          editStepOne={editStepOne}
          editStepTwo={editStepTwo}
          editStepThree={editStepThree}
          editStepFour={editStepFour}
          showDensity
          parentFields={parentFields}
        />
      </>
    );
  }

  return null;
};

export default Step5;
