import styled, { css } from 'styled-components';
import PrimaryButton, { styleTypes } from '../PrimaryButton';
import { colors, MediaQuery } from '../../../theme/style-helpers';
import Column from '../Column';
import Row from '../Row';

export const CardContainer = styled(Row)`
    flex-wrap: unset !important;
`;

export const BorderedColumn = styled(Column)`
    flex: unset !important;
    padding: 16px 0;
    border-right: 1px solid ${colors.COOL_GREY};
    ${({ $hideBorderRight }) => $hideBorderRight && css`
        border-right: none;
    `} ${MediaQuery.medium`
    border-bottom: none;
    padding: unset;
  `}
`;

export const LogOutButtonContainer = styled.div`
    display: flex;
    margin-top: 24px;
`;

export const ExternalLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 48px;
`;

export const Button = styled(PrimaryButton)
  .attrs(() => ({
    styleType: styleTypes.SECONDARY,
    iconRightArrow: true,
    autoWidth: true,
  }))`
    width: auto;
    border: none;
    padding: 0;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 100%;
        text-align: left;
    }
`;

export const ErrorIcon = styled.img`
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    height: 38px;
    display: block;
    width: 100%;
`;

export const ModalTitle = styled.div`
    font-size: 2.5rem;
    padding-top: 34px;
    padding-bottom: 25px;
`;

export const Close = styled.div`
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #333;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
`;

export const ModalBody = styled.div`
    padding: 50px;
    font-size: 1.5rem;
    text-align: center;
`;

export const ButtonDiv = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 0;
`;

export const ActionButton = styled(PrimaryButton)`
    width: 340px;
`;
