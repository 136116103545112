import React, { useState, useEffect } from 'react';
import { Field, FieldArray, change } from 'redux-form';
import { t } from 'i18next';
import PlusGrey from '../../../assets/icons/PlusGrey.svg';
import CloseOrange from '../../../assets/icons/Close.svg';
import RenderField from '../../ReactComponent/RenderField';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import Plus from '../../../assets/icons/Plus.svg';
import Delete from '../../../assets/icons/RemoveOrange.svg';
import Edit from '../../../assets/icons/Edit.svg';
import PasswordGroup from '../../ReactComponent/PasswordGroup';
import { isRequired, maxChar6, isEmail } from '../../../utils/validator';
import { digitOnly } from '../../../utils/mask';
import {
  AddItemContainer,
  AddItem,
  AddItemLabel,
  PageTitle,
  ContentBoxLightGrey,
  CategoryTitle,
  CardTitle,
  FileUploadLabel,
  FileUploadContainer,
  FileUpload,
  ItemContainer,
  Icon,
  PrimaryButton,
  EditButtonGroup,
  LineButton,
  TextProperty,
  FullRow,
} from '../css';
import { P } from '../../../theme/typography';
import { BillingAccountType } from '../../../utils/enums';
import Tooltip from '../../ReactComponent/Tooltip';

const renderAccountList = ({
  fields,
  dispatch,
  formName,
  currentAccountList,
  error
}) => {
  const disableAddItem = error.accountList ? true : false;
  const addItem = (index) => {
    dispatch(change(formName, `accountList[${index}].completed`, true));
  };

  const removeItem = (index) => {
    const newAccountlist = [...currentAccountList];
    newAccountlist.splice(index, 1);
    if (newAccountlist.length === 0) {
      newAccountlist.push({});
    }

    dispatch(change(formName, 'accountList', newAccountlist));
  };

  const [state, setState] = useState({
    showAddLineButton: false,
    hideRemoveButton: true
  });

  useEffect(() => {
    const hideRemoveButton = currentAccountList.length <= 1;
    const showAddLineButton = !(currentAccountList.some((a) => !a.completed));
    setState({
      ...state,
      hideRemoveButton,
      showAddLineButton
    });
  }, [currentAccountList]);
  // eslint-disable-next-line no-shadow
  const editAccount = (index, dispatch) => dispatch(change(formName, `accountList[${index}].completed`, false));

  // eslint-disable-next-line no-shadow
  const deleteAccount = (currentAccountList, index, dispatch) => {
    const newlist = [...currentAccountList];
    newlist.splice(index, 1);
    if (newlist.length === 0) {
      newlist.push({});
    }

    dispatch(change(formName, 'accountList', newlist));
  };

  return (
    <>
      {fields.map((item, index) => {
        const fieldValue = fields.get(index);
        const {
          completed,
          accountNumber,
          accountType,
        } = fieldValue;
        if (completed) {
          return (
            <ContentBoxLightGrey key={index}>
              <EditButtonGroup>
                <LineButton onClick={() => deleteAccount(currentAccountList, index, dispatch)}>
                  <Icon className="icon" src={Delete} alt="delete" />
                  {t('Delete')}
                </LineButton>
                {state.showAddLineButton && <LineButton onClick={() => editAccount(index, dispatch)}>
                  <Icon className="icon" src={Edit} alt="edit" />
                  {t('Edit')}
                </LineButton>}
              </EditButtonGroup>
              <CardTitle>
                {t('Account')}
                {` ${index + 1}`}
              </CardTitle>
              <Row>
                <Column>
                  <TextProperty>
                    <P className="title">{t('BillingAccountNumber')}</P>
                    <P className="text">{accountNumber}</P>
                  </TextProperty>
                </Column>
                <Column>
                  <TextProperty>
                    <P className="title">{t('AccountType')}</P>
                    <P className="text">{t(accountType)}</P>
                  </TextProperty>
                </Column>
              </Row>
            </ContentBoxLightGrey>
          );
        }

        return (
          <ContentBoxLightGrey className="edit-item-box" key={index}>
            <Row className="justify-content-md-center">
              <Column $tablet={5} $mobile={12}>
                <Field
                  name={`${item}.accountNumber`}
                  type="text"
                  required
                  component={RenderField}
                  label={t('AccountNumber')}
                  normalize={digitOnly}
                  validate={[isRequired, maxChar6]}
                />
              </Column>
              <Column $tablet={5} $mobile={12}>
                <Field
                  name={`${item}.accountType`}
                  type="checkboxes"
                  required
                  data={[BillingAccountType.Billing, BillingAccountType.Protected]}
                  component={RenderField}
                  label={t('AccountType')}
                  validate={[isRequired]}
                />
              </Column>
              <Tooltip tip={t('TipAccountType')} />
            </Row>
            <AddItemContainer>
              {state.hideRemoveButton ? undefined : <AddItem onClick={() => removeItem(index)}>
                <AddItemLabel>{t('RemoveItem')}</AddItemLabel>
                <img src={CloseOrange} alt="Remove item" />
              </AddItem>}
              <AddItem className={disableAddItem ? '' : 'active'} disabled={disableAddItem} onClick={() => addItem(index)}>
                <AddItemLabel>{t('SaveItem')}</AddItemLabel>
                <img src={disableAddItem ? PlusGrey : Plus} alt="add item" />
              </AddItem>
            </AddItemContainer>
          </ContentBoxLightGrey>
        );
      })}
      {state.showAddLineButton && (
        <FileUploadContainer className="with-dot">
          <FileUpload onClick={() => {
            fields.push({});
          }}>
            <img src={Plus} alt="add more part file" />
            <FileUploadLabel>{t('AddAnotherItem')}</FileUploadLabel>
          </FileUpload>
        </FileUploadContainer>
      )}
    </>
  );
};

const CompanyForm = ({
  formData = {},
  formName,
  dispatch,
  permissionList,
  deleteCompany,
  parentFields,
  error
}) => {
  const {
    accountList = [],
    rowVersion,
    companyName
  } = formData;
  const {
    passwordImage
  } = parentFields;
  const update = rowVersion ? true : false;
  return (
    <>
      <PageTitle className="justified">
        {parentFields.pageHeading && parentFields.pageHeading.value}
      </PageTitle>
      <CategoryTitle>
        {parentFields.categoryTitle && parentFields.categoryTitle.value}
      </CategoryTitle>
      <Row className="justify-content-md-center">
        <Column $tablet={10} $mobile={12}>
          {update ?
            <TextProperty>
              <P className="title">{t('CompanyName')}</P>
              <P className="text">{companyName}</P>
            </TextProperty> :
            <Field
              name="companyName"
              type="text"
              required
              component={RenderField}
              label={t('CompanyName')}
              validate={[isRequired]}
            />}
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={10} $mobile={12}>
          <Field
            name="description"
            type="text"
            required
            component={RenderField}
            label={t('Description')}
            validate={[isRequired]}
          />
        </Column>
      </Row>
      {!update && <>
        <Row className="justify-content-md-center">
          <Column $tablet={10} $mobile={12}>
            <Field
              name="email"
              type="text"
              required
              component={RenderField}
              label={t('Email')}
              validate={[isRequired, isEmail]}
            />
          </Column>
        </Row>
        <Row className="justify-content-md-center">
          <Column $tablet={10} $mobile={12}>
            <PasswordGroup passwordImage={passwordImage} />
          </Column>
        </Row>
        <TextProperty>
          {parentFields.checkboxText && parentFields.checkboxText.value}
        </TextProperty>
        <Row>
          <Column>
            <Field
              name="changePassword"
              type="checkboxes"
              data={['Force User To Reset Password']}
              component={RenderField}
              multiple
            />
          </Column>
        </Row>
      </>}
      <ItemContainer>
        <FieldArray
          name="accountList"
          dispatch={dispatch}
          component={renderAccountList}
          formName={formName}
          currentAccountList={accountList}
          error={error}
        />
      </ItemContainer>
      <CategoryTitle>
        {parentFields.categoryTitle2 && parentFields.categoryTitle2.value}
      </CategoryTitle>
      <Row>
        {permissionList.map((permission, index) => (
            <Column $tablet={4} $mobile={12} key={index}>
              <FullRow>
                <Field
                  name={`permissions.${permission.permissionName}.selected`}
                  type="checkboxes"
                  data={[{
                    name: permission.permissionName,
                    desc: permission.description,
                    code: permission.permissionId
                  }]}
                  component={RenderField}
                  multiple
                  value={permission.code}
                  disabled={permission.permissionName === 'Shipping'}
                />
              </FullRow>
            </Column>)
        )}
      </Row>
      <CategoryTitle>
        {t('DisableCompany')}
      </CategoryTitle>
      <TextProperty>
        {parentFields.disableCompanyCopy && parentFields.disableCompanyCopy.value}
      </TextProperty>
      <Row>
        <Column>
          <Field
            name="disabled"
            type="checkboxes"
            data={[t('DisableCompany')]}
            component={RenderField}
            multiple
          />
        </Column>
      </Row>
      <CategoryTitle>
        {t('DeleteCompany')}
      </CategoryTitle>
      <TextProperty>
        {parentFields.deleteCompanyCopy && parentFields.deleteCompanyCopy.value}
      </TextProperty>
      <Row>
        <PrimaryButton type="button" className={`${update && 'active'}`} disabled={!update} onClick={() => deleteCompany()}>
          {t('DeleteCompany')}
        </PrimaryButton>
      </Row>
    </>
  );
};

export default CompanyForm;
