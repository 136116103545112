import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { OffsetContainer } from '../css';
import PrimaryButton from '../../ReactComponent/PrimaryButton';

const Button = ({
  text,
  children,
  ...rest
}) => (
  <OffsetContainer>
    <PrimaryButton
      type="button"
      iconRightArrow
      autoWidth
      {...rest}
    >
      {text ? <Text field={text}/> : children}
    </PrimaryButton>
  </OffsetContainer>
);
export default Button;
