import React, { useState } from 'react';
import { t } from 'i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import DatePicker from 'react-datepicker';
import DropdownList from 'react-widgets/lib/DropdownList';
import './calendar.scss';
import SelectList from 'react-widgets/lib/SelectList';
import Error from '../../../assets/icons/Error.svg';
import Close from '../../../assets/icons/Close.svg';
import Search from '../../../assets/icons/Search.svg';
import Calendar from '../../../assets/icons/Calendar.svg';
import {
  StyledInput,
  ErrorMessage,
  Icon,
  FieldGroup,
  RequiredAsterisk,
  StyledTextarea,
  Label,
  DropDown,
  CheckBoxContainer,
  CheckBox,
  ResponsiveTable,
  TableContainer,
  BlockTitle,
  PasswordHiddenButton,
  CrossLine,
  ResetOnClick,
  SearchIcon,
  DateContainer,
  DateInputContainer,
  TextFieldIcon,
  ListLabel,
  ListLabelAsterisk,
  VerticalFieldGroup,
  SmallDesc
} from './css';
import { H3, P } from '../../../theme/typography';
import Row from '../Row';
import Column from '../Column';
import { isNullOrUndefined } from '../../../utils/object-utils';

const DATE_FORMAT_PATTERN = 'yyyy-MM-dd';
const MONTH_YEAR_FORMAT_PATTERN = 'yyyy-MM';

// eslint-disable-next-line react/display-name
const DateInput = React.forwardRef(({
  input, value, onClick, onBlur, onFocus
}, ref) => {
  const { name } = input;
  return <DateInputContainer onClick={onClick}>
    <StyledInput ref={ref} name={name} value={value} onBlur={onBlur} onFocus={onFocus} placeholder="yyyy-mm-dd" onChange={() => {}} />
    <TextFieldIcon src={Calendar} />
  </DateInputContainer>;
});

// eslint-disable-next-line react/no-multi-comp, react/display-name
const MonthYearInput = React.forwardRef(({
  input,
  value,
  onClick,
  onBlur,
  onFocus
}, ref) => {
  const { name } = input;
  return <DateInputContainer onClick={onClick}>
    <StyledInput ref={ref} name={name} value={value} onBlur={onBlur} onFocus={onFocus} placeholder="yyyy-mm" onChange={() => {}} />
    <TextFieldIcon src={Calendar} />
  </DateInputContainer>;
});

const styledRadioRender = ({ item }) => (
  <>
    <span className="checkmark" />
    <P>{t(item.name ? item.name : item)}</P>
  </>
);
const styledChkbxWithDescRender = ({ item }) => (
  <>
    <span className="checkmark" />
    <H3>{t(item.name ? item.name : item)}</H3>
    {item.desc && (
      <Row>
        <Column $tablet={12} $mobile={12}>
          {t(item.desc)}
        </Column>
      </Row>
    )}
  </>
);

// eslint-disable-next-line react/no-multi-comp
const RenderField = ({
  input,
  data,
  textField,
  valueField,
  label,
  subLabel,
  type,
  required,
  className,
  meta: { touched, error, active },
  multiple,
  renderer,
  disabled,
  id,
  columns,
  selectedValue,
  onClick,
  defaultValue,
  disabledList, // disable specific checkboxes
  allowCreate,
  handleAddToList,
  onReset,
  asteriskStyle,
  containerMaxHeight,
  showErrorAlways,
  readOnly,
  marginBottom,
  description,
  clearable
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const [touchedComplex, setTouchedComplex] = useState(false);
  const isFocusOrHasValue = active || !isNullOrUndefined(input.value);

  switch (type) {
    case 'date':
      return (
        <FieldGroup className={className}>
          <Label className={isFocusOrHasValue ? 'active' : ''}>{label}</Label>
          {required && (<RequiredAsterisk style={asteriskStyle}>*</RequiredAsterisk>)}
          <DateContainer>
            <DatePicker
              onBlur={input.onBlur}
              selected={input.value ? parse(input.value, DATE_FORMAT_PATTERN, new Date()) : null}
/*
this logic is here in order not to refactor each date field separately for now
as anyway later this part will be refactored with date format
+ we need to split apart this monolith (the whole file) into separate components!!
in sake of further maintainability
*/
              onChange={
                (date) => input.onChange(format(date, DATE_FORMAT_PATTERN))
              }
              customInput={<DateInput input={input} />}
              dateFormat={DATE_FORMAT_PATTERN}
              showYearDropdown
            />
          </DateContainer>
          {error && (
            <ErrorMessage>
              <Icon src={Error} alt={error} />
              {error}
            </ErrorMessage>
          )}
        </FieldGroup>
      );

    case 'monthyear':
      return (
          <FieldGroup className={className}>
            <Label className={isFocusOrHasValue ? 'active' : ''}>{label}</Label>
            {required && (<RequiredAsterisk style={asteriskStyle}>*</RequiredAsterisk>)}
            <DateContainer>
              <DatePicker
                onBlur={input.onBlur}
                selected={input.value ? parse(input.value, MONTH_YEAR_FORMAT_PATTERN, new Date()) : null}
                onChange={
                  (date) => input.onChange(format(date, MONTH_YEAR_FORMAT_PATTERN))
                }
                customInput={<MonthYearInput input={input} />}
                dateFormat={MONTH_YEAR_FORMAT_PATTERN}
                showYearDropdown
              />
            </DateContainer>
            {error && (
              <ErrorMessage>
                <Icon src={Error} alt={error} />
                {error}
              </ErrorMessage>
            )}
          </FieldGroup>
      );
    case 'textarea':
      return (
        <FieldGroup style={{ margin: '24px 0 34px' }} className={className}>
          <Label className={isFocusOrHasValue ? 'active' : ''}>{label}</Label>
          {required && (<RequiredAsterisk>*</RequiredAsterisk>)}
          <StyledTextarea {...input}>
          </StyledTextarea>
          {(touched || showErrorAlways) &&
            (error && (
              <ErrorMessage>
                <Icon src={Error} alt={error} />
                {error}
              </ErrorMessage>
            ))}
        </FieldGroup>
      );
    case 'single':
      return (
        <FieldGroup className={`${className} single`}>
          <span className="relative">
            <Label className={isFocusOrHasValue ? 'active' : ''}>{label}</Label>
            {required && (<RequiredAsterisk>*</RequiredAsterisk>)}
            <StyledInput {...input} type="text" disabled={disabled}/>
            {(touched || showErrorAlways) &&
              (error && (
                <ErrorMessage>
                  <Icon src={Error} alt={error} />
                  {error}
                </ErrorMessage>
              ))}
          </span>
        </FieldGroup>
      );
    case 'combobox':
      return (
        <DropDown className={className}>
          <div className="relative">
            <Label className={isFocusOrHasValue ? 'active' : ''}>{label}</Label>
            {required && (<RequiredAsterisk>*</RequiredAsterisk>)}
            <DropdownList
              {...input}
              data={data}
              textField={textField}
              valueField={valueField}
              onChange={input.onChange}
              filter
              allowCreate={allowCreate}
              onCreate={handleAddToList}
            />
            {input.value && <ResetOnClick onClick={onReset}><Icon src={Close} alt={Close} /></ResetOnClick>}
            {(touched || showErrorAlways) &&
              (error && (
                <ErrorMessage>
                  <Icon src={Error} alt={error} />
                  {error}
                </ErrorMessage>
              ))}
          </div>
        </DropDown>
      );
    case 'dropdown':
      return (
        <DropDown className={className}>
          <div className="relative">
            <Label className={isFocusOrHasValue || defaultValue ? 'active' : ''}>{label}</Label>
            {description && <SmallDesc><RichText field={description} /></SmallDesc>}
            {required && (<RequiredAsterisk>*</RequiredAsterisk>)}
            <DropdownList
              {...input}
              defaultValue={defaultValue}
              data={data}
              textField={textField}
              valueField={valueField}
              onChange={input.onChange}
              disabled={disabled}
            />
            {clearable && input.value && <ResetOnClick onClick={onReset}><Icon src={Close} alt={Close} /></ResetOnClick>}
            {(touched || showErrorAlways) &&
              (error && (
                <ErrorMessage>
                  <Icon src={Error} alt={error} />
                  {error}
                </ErrorMessage>
              ))}
          </div>
        </DropDown>
      );
    case 'checkboxes':
      return (
        <VerticalFieldGroup className={className} marginBottom={marginBottom}>
          {label && (
            <ListLabel>
              {label}
              {required && <ListLabelAsterisk> *</ListLabelAsterisk>}
            </ListLabel>
          )}
          <CheckBoxContainer
            className={`${className} ${input.name !== 'measurementType' && (!className || !className.includes('radio-container')) &&
              'checkbox-container'}`}
            disabled={disabled}
            $containerMaxHeight={containerMaxHeight}
          >
            <SelectList
              {...input}
              multiple={multiple ? true : false}
              disabled={disabledList ? disabledList : disabled}
              className={disabled ? 'checkbox-container-disabled' : null}
              itemComponent={renderer ? renderer : styledChkbxWithDescRender}
              onBlur={() => {
                if (input) {
                  if (input.blur) {
                    input.blur();
                  }
                }
              }}
              onChange={(_) => {
                setTouchedComplex(true);
                input.onChange(_);
              }}
              data={data}
            />
          </CheckBoxContainer>
          {(touchedComplex || touched || showErrorAlways) &&
            (error && (
              <ErrorMessage>
                <Icon src={Error} alt={error} />
                {error}
              </ErrorMessage>
            ))}
        </VerticalFieldGroup>
      );

    case 'radio':
      return (
        <VerticalFieldGroup >
          <CheckBoxContainer className={`radio-container ${className}`}>
            <BlockTitle>
              {label}
              {required && <span className="asterisk"> *</span>}
              <P>{subLabel}</P>
            </BlockTitle>
            <SelectList
              {...input}
              multiple={multiple ? true : false}
              disabled={disabledList ? disabledList : disabled}
              itemComponent={renderer ? renderer : styledRadioRender}
              onBlur={() => input.onBlur()}
              data={data}
              defaultValue={defaultValue}
            />
          </CheckBoxContainer>
          {(touched || showErrorAlways) &&
            (error && (
              <ErrorMessage>
                <Icon src={Error} alt={error} />
                {error}
              </ErrorMessage>
            ))}

        </VerticalFieldGroup>
      );
    case 'tableRowSelect':
      return (
        <TableContainer>
          <ResponsiveTable>
            <table>
              <thead>
                <tr className="row">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td className="col"></td>
                  {columns.map((c, index) => (<td className="col" key={index}>
                      {t(c)}
                    </td>))}
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                    <>
                      <tr className="row" onClick={() => onClick(item)}>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <td className="col">
                          <input type="radio" checked={item === selectedValue}></input>
                        </td>
                        {Object.keys(item).map((c, index) => (
                            <td className="col" key={index}>
                              {((item[c] && (item[c].value || item[c].value === 0)) ? item[c].value : item[c])}
                            </td>
                        ))}
                      </tr>
                    </>
                ))}
              </tbody>
            </table>
          </ResponsiveTable>

          {(touched || showErrorAlways) &&
            (error && (
              <ErrorMessage>
                <Icon src={Error} alt={error} />
                {error}
              </ErrorMessage>
            ))}
        </TableContainer>
      );
    case 'singleCheckbox':
      return (
        <CheckBox>
          {required && <RequiredAsterisk>*</RequiredAsterisk>}
          <StyledInput
            {...input}
            type="checkbox"
            className="hidden-check"
            data={data}
            checked={input.value}
          />
          <span className="checkmark" />
          {label}
        </CheckBox>
      );
    case 'address':
      return (
        <FieldGroup className={className}>
          <Label className={isFocusOrHasValue ? 'active' : ''}>{label}</Label>
          {required && <RequiredAsterisk>*</RequiredAsterisk>}
          <StyledInput {...input} type={type} id={id} autoComplete="off" />
          {(touched || showErrorAlways) &&
            (error && (
              <ErrorMessage>
                <Icon src={Error} alt={error} />
                {error}
              </ErrorMessage>
            ))}
        </FieldGroup>
      );
    case 'password':
      return (
        <FieldGroup className={className}>
          <Label className={isFocusOrHasValue ? 'active' : ''}>{label}</Label>
          {required && <RequiredAsterisk>*</RequiredAsterisk>}
          <StyledInput {...input} type={isHidden ? 'password' : 'text'} />
          <PasswordHiddenButton
            type="button"
            onClick={() => setIsHidden(!isHidden)}
          >
            {!isHidden && <CrossLine />}
          </PasswordHiddenButton>
          {(touched || showErrorAlways) &&
            (error && error !== 'externalMessage' && (
              <ErrorMessage>
                <Icon src={Error} alt={error} />
                {error}
              </ErrorMessage>
            ))}
        </FieldGroup>
      );
    case 'search':
      return (
        <FieldGroup className={className}>
          <Label className={isFocusOrHasValue ? 'active' : ''}>{label}</Label>
          {required && <RequiredAsterisk>*</RequiredAsterisk>}
          <StyledInput {...input} />
          <SearchIcon src={Search} />
          {(touched || showErrorAlways) &&
            (error && (
              <ErrorMessage>
                <Icon src={Error} alt={error} />
                {error}
              </ErrorMessage>
            ))}
        </FieldGroup>
      );
    default:
      return (
        <FieldGroup className={className}>
          <Label className={isFocusOrHasValue ? 'active' : ''}>
            {label}
          </Label>
          {required && <RequiredAsterisk>*</RequiredAsterisk>}
          <StyledInput
            {...input}
            readOnly={readOnly}
            id={id}
            disabled={disabled}
            type={type}
          />
          {(touched || showErrorAlways) &&
            (error && (
              <ErrorMessage>
                <Icon src={Error} alt={error} />
                {error}
              </ErrorMessage>
            ))}
        </FieldGroup>
      );
  }
};

export default RenderField;
