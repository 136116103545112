import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Column from '../../Column';
import { LENGTH, WEIGHT } from '../Units/utils';
import { LabelWithUnits } from '../common/MeasurementComponents';
import Row from '../../Row';

const ContainerCard = ({
  container,
  unitType,
  isContainerData,
}) => (
    <Row>
      {isContainerData && (
        <>
          <Column $tablet={3} $mobile={6}>
            <LabelWithUnits
              label={t('Volume')}
              value={container.volume}
              unitsLabel={LENGTH}
              unitType={unitType}
              unitScale={1}
              unitsSup={3}
            />
            <LabelWithUnits
              label={t('Weight')}
              value={container.weight}
              unitsLabel={WEIGHT}
              unitType={unitType}
            />
          </Column>
          <Column $tablet={3} $mobile={6}>
            <LabelWithUnits label={t('Origin')} value={container.origin} />
            <LabelWithUnits label={t('Destination')} value={container.destination} />
          </Column>
          {container.equipmentSize && (
            <Column $tablet={3} $mobile={6}>
              <LabelWithUnits
                label={t('EquipmentSize')}
                value={container.equipmentSize}
              />
            </Column>
          )}
        </>
      )}
    </Row>
);

ContainerCard.propTypes = {
  container: PropTypes.shape({
    volume: PropTypes.number,
    weight: PropTypes.number,
    origin: PropTypes.string,
    destination: PropTypes.string,
    equipmentSize: PropTypes.string,
  }),
  unitType: PropTypes.string,
  isContainerData: PropTypes.bool,
};
export default ContainerCard;
