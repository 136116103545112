import styled from 'styled-components';
import { P } from '../../../theme/typographySitecore';

export const MainFooter = styled.footer`
    background-color: #000;
    padding: 30px 0;

    .pointer {
        cursor: pointer;
    }

    @media all and (max-width: 768px) {
        text-align: center;
        .footer-column {
            border-top: 1px solid #63666A;
            padding: 16px 32px;
            max-width: 320px;
            margin: 0 auto;
        }

        .navbar-brand {
            padding-bottom: 16px;
            margin: 0;
        }
    }

    .nav {
        display: flex;
        flex-wrap: wrap;

        .nav-link {
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.4rem;
            color: #fff;
            text-decoration: none;
            display: block;
            padding: .5rem 1rem;
        }

        .social-media {
            display: inline-block;
            padding-right: 0;
        }

        &.flex-column {
            flex-direction: column;
        }
    }

    .contact-box {
        margin-left: auto;

        .nav-link {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: end;

            @media all and (max-width: 768px) {
                justify-content: center;
            }

            .contact-icon {
                margin-left: 10px;
            }
        }
    }
`;

export const Link = styled.div`
    cursor: pointer;
`;

export const MarginlessP = styled(P)`
    margin: 0 !important;
`;
