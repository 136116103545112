export const Stages = {
  Loading: 'Loading',
  DataLoaded: 'DataLoaded',
  NoResults: 'NoResults',
  Error: 'Error',
};

export const NUMBER_OF_DAYS = 7;
export const NUMBER_OF_DAYS_FOR_INVOICES = 60;
export const NUMBER_OF_SAMPLE_DATA = 3;

export const ShipmentCategories = {
  OnRoute: 'On-Route',
  ShipmentsCompleted: 'Completed',
  ShipmentsDelayed: 'Delayed',
  ShipmentsInProgress: 'InProgress',
};

export const ShipmentStates = {
  PickedUpPending: 'Picked up Pending',
  PickedUp: 'Pickup',
  InTransitArrival: 'In Transit Arrival',
  OutForDelivery: 'Out for Delivery',
  Delivered: 'Delivered',
  ServiceDelay: 'Service Delay',
  DeliveryDelay: 'Delivery Delay',
  NotYetPickedUp: 'Not yet picked up',
  TransferPointArrival: 'Transfer Point Arrival',
  DepartedOrigin: 'Departed Origin',
  TransferPointUpdate: 'Transfer Point Update',
  ArrivalatDestination: 'Arrival at Destination',
  OnDock: 'On Dock',
  InTransit: 'In Transit',
  Transfer: 'Transfer',
};

export const shipmentTableColumns = [
  {
    id: 1,
    label: 'Action',
    field: 'actions',
    sort: false,
  },
  {
    id: 2,
    label: 'ShipmentNumber',
    field: 'probillNumber',
    sort: false,
  },
  {
    id: 3,
    label: 'DeliveryDate',
    field: 'deliveryDate',
    sort: false,
  },
  {
    id: 4,
    label: 'Status',
    field: 'status',
    sort: false,
  },
  {
    id: 5,
    label: 'Expand',
    field: 'expand',
    sort: false,
  },
  {
    id: 6,
    label: '',
    field: 'separateRow',
    sort: false,
  },
];

export const quoteTableColumns = [
  {
    id: 1,
    label: 'Action',
    field: 'actions',
    sort: false,
  },
  {
    id: 2,
    label: 'QuoteNumber',
    field: 'quoteNumber',
    sort: false,
  },
  {
    id: 3,
    label: 'FromTo',
    field: 'fromTo',
    sort: false,
  },
];

export const InvoicesCategories = {
  Outstanding: 'Outstanding',
  Overdue: 'Overdue',
};

export const PickupStates = {
  PickupCompleted: 'Pickup completed',
  OrderFailed: 'Order failed',
  PickupCancelled: 'Pickup cancelled',
  FreightArrivedAtOriginTerminal: 'Freight arrived at origin terminal',
  ProbillOnOutBoundDock: 'Probill on outbound dock',
  ProbillAssignedToLinehaul: 'Probill assigned to linehaul',
  LinehaulInTransit: 'Linehaul in transit',
  PendingCustomsClearance: 'Pending customs clearance',
  CustomsClearanceRejected: 'Customs clearance rejected',
  OrderClearedCustoms: 'Order cleared customs',
  LinehaulArriveAtInboundDock: 'Linehaul arrive at inbound dock',
  DeliveryAppointmentSecured: 'Delivery appointment secured',
  ProbillOnInboundDock: 'Probill on inbound dock',
  DeliveryAssignedToDriver: 'Delivery assigned to driver',
  DeliveryMissed: 'Delivery missed',
  DeliveryRescheduled: 'Delivery rescheduled',
  DriverEnrouteToDelivery: 'Driver enroute to delivery',
  DriverArrivedInYard: 'Driver arrived in yard',
  DeliveryConfirmed: 'Delivery confirmed',
  DeliveryAttempted: 'Delivery attempted',
  DeliveredWithException: 'Delivered with exception',
  DroppedAtCustomer: 'Dropped at Customer',
  SentToTerminal: 'Sent to Terminal',
  CustomerRequestForCancel: 'Customer Request for Cancel',
  PickupConfirmed: 'Pickup Confirmed',
  TransferConfirmed: 'Transfer Confirmed',
  OrderCanceled: 'Order canceled',
  PickupCancelled2: 'Pickup Cancelled',
  OrderReceived: 'Order received',
  OrderAccepted: 'Order accepted',
  PickupAppointmentSecured: 'Pickup appointment secured',
  PickupAssignedToDriver: 'Pickup assigned to driver',
  PickupMissed: 'Pickup missed',
  DriverEnroute: 'Driver enroute',
  DriverDelayed: 'Driver delayed',
  PickupAttempted: 'Pickup attempted',
  PickupRescheduled: 'Pickup rescheduled',
  OrderSubmitted: 'Order Submitted',
  OrderUpdated: 'Order Updated',
  OrderUnassignedFromRoute: 'Order unassigned from route',
  PickupPendingDispatch: 'Pickup Pending Dispatch',
  LoadCannotBeBrokered: 'Load Cannot be Brokered',
  NoAvailability: 'No Availability',
  PickupPendingAssignment: 'Pickup Pending Assignment',
  AssetApprovalRequested: 'Asset Approval Requested',
  McCain: 'McCain',
  PickupAssignedToInterliner: 'Pickup Assigned to Interliner',
  PickupAssignedToTerminal: 'Pickup Assigned to Terminal',
  PickupDeclinedFromTheQueue: 'Pickup Declined from the Queue',
  PickupReleasedFromTheQueue: 'Pickup Released from the Queue',
  PickupOnHold: 'Pickup On Hold',
  TerminalTruckAssigned: 'Terminal Truck Assigned',
  PickupAssigned: 'Pickup Assigned',
  Linehaul: 'Linehaul',
  Agent: 'Agent',
  HiredTruck: 'Hired Truck',
  RegularNoFreight: 'Regular - No Freight',
  Rescheduled: 'Rescheduled',
  PickupRequestProcessed: 'Pickup Request Processed',
  DispatchedToRouteWithETA: 'Dispatched to route with ETA',
  PickupRequestReceived: 'Pick up Request Received',
  EnRoute: 'En route',
  ArrivalAtShipper: 'Arrival at Shipper',
  PickedUp: 'Picked Up',
  PlannedArrivalTime: 'Planned Arrival Time',
};

export const PickupCategories = {
  PendingPickups: 'Pending Pickups',
  PickedUp: 'Picked Up',
  Completed: 'Completed',
};

export const CategoriesStatuses = {
  [PickupCategories.PendingPickups]: [
    PickupStates.OrderReceived,
    PickupStates.OrderAccepted,
    PickupStates.PickupAppointmentSecured,
    PickupStates.PickupAssignedToDriver,
    PickupStates.PickupMissed,
    PickupStates.DriverEnroute,
    PickupStates.DriverDelayed,
    PickupStates.PickupAttempted,
    PickupStates.PickupRescheduled,
    PickupStates.PickupAssigned,
    PickupStates.OrderSubmitted,
    PickupStates.OrderUpdated,
    PickupStates.OrderUnassignedFromRoute,
    PickupStates.PickupPendingDispatch,
    PickupStates.LoadCannotBeBrokered,
    PickupStates.NoAvailability,
    PickupStates.PickupPendingAssignment,
    PickupStates.AssetApprovalRequested,
    PickupStates.McCain,
    PickupStates.PickupAssignedToInterliner,
    PickupStates.PickupAssignedToTerminal,
    PickupStates.PickupDeclinedFromTheQueue,
    PickupStates.PickupReleasedFromTheQueue,
    PickupStates.PickupOnHold,
    PickupStates.TerminalTruckAssigned,
    PickupStates.PickupAssigned,
    PickupStates.Linehaul,
    PickupStates.Agent,
    PickupStates.HiredTruck,
    PickupStates.RegularNoFreight,
    PickupStates.Rescheduled,
    PickupStates.PickupRequestProcessed,
    PickupStates.DriverArrivedInYard,
    PickupStates.DispatchedToRouteWithETA,
  ],
  [PickupCategories.Completed]: [
    PickupStates.PickupCompleted,
    PickupStates.OrderFailed,
    PickupStates.PickupCancelled,
    PickupStates.PickupCancelled2,
    PickupStates.FreightArrivedAtOriginTerminal,
    PickupStates.ProbillOnOutBoundDock,
    PickupStates.ProbillAssignedToLinehaul,
    PickupStates.LinehaulInTransit,
    PickupStates.PendingCustomsClearance,
    PickupStates.CustomsClearanceRejected,
    PickupStates.OrderClearedCustoms,
    PickupStates.LinehaulArriveAtInboundDock,
    PickupStates.DeliveryAppointmentSecured,
    PickupStates.ProbillOnInboundDock,
    PickupStates.DeliveryAssignedToDriver,
    PickupStates.DeliveryMissed,
    PickupStates.DeliveryRescheduled,
    PickupStates.DriverEnrouteToDelivery,
    PickupStates.DeliveryConfirmed,
    PickupStates.DeliveryAttempted,
    PickupStates.DeliveredWithException,
    PickupStates.DroppedAtCustomer,
    PickupStates.SentToTerminal,
    PickupStates.CustomerRequestForCancel,
    PickupStates.PickupConfirmed,
    PickupStates.TransferConfirmed,
    PickupStates.OrderCanceled,
  ],
};

export const CurrencyCodes = {
  CAD: 'CAD',
  USD: 'USD',
};

export const ShipmentHistoryStates = {
  DoNotShow: 'Do Not Show',
  Delivered: 'Delivered',
};

export const ShipmentHistoryStateOrder = [ShipmentHistoryStates.Delivered];
