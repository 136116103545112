import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { t } from 'i18next';
import { ActionsContainer, ContentBoxLightGrey, FormContainer } from '../css';
import { Button, FinalStep } from './common';
import { FORM_NAME } from '../constants';
import { getMonthDayYear, homeUrl } from '../../../utils/func';
import {
  H1, H2, H3, P,
} from '../../../theme/typographySitecore';

let Step5 = (props) => {
  const { fields } = props;
  const navigateHome = (e) => {
    e.preventDefault();
    window.location.href = homeUrl();
  };

  return (
    <FormContainer>
      <form>
        <H1 field={t('SctnQuoteYourQuote')} verticalOffset={40}/>
        <H2 field={t('Summary')} verticalOffset={20}/>
        <ContentBoxLightGrey border>
          <H3 field={t('SctnQuoteThankYou')} verticalOffset={20}/>
          <P field={fields.finalMessage}/>
          <P
            field={t('SctnQuoteDateCreated', { date: getMonthDayYear(new Date()) })}
          />
        </ContentBoxLightGrey>
        <FinalStep {...props} />
        <ActionsContainer>
          <Button type="submit" iconRightArrow onClick={navigateHome}>
            {t('Exit')}
          </Button>
        </ActionsContainer>
      </form>
    </FormContainer>
  );
};

Step5 = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: FORM_NAME,
})(Step5);

export default Step5 = connect((state) => {
  const data = getFormValues(FORM_NAME)(state);
  return {
    data,
  };
})(Step5);
