import React, { useState, useEffect } from 'react';
import { Field, change } from 'redux-form';
import { t } from 'i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Card from '../../ReactComponent/Card';
import RenderField from '../../ReactComponent/RenderField';
import { AddressBookEntry } from '../../ReactComponent/AddressBookEntry';
import TariffSurchargeSummaryBlock from '../../ReactComponent/TariffSurchargeSummaryBlock';
import {
  isEmail,
  isRequired,
  maxChar50,
  maxChar6
} from '../../../utils/validator';
import { digitOnly } from '../../../utils/mask';
import {
  PageTitle,
  CategoryTitle,
  CanRateTool,
  OverShipmentText,
  PaymentMethod,
  AddressBlock,
  CheckBoxColumns,
  TooltipContainer,
  MarginlessH2,
  FontSize12
} from '../css';
import {
  QuoteTypes,
  DeliveryTypes,
  AddressTypes,
  Divisions,
  PaymentMethods
} from '../../../utils/enums';
import { routes } from '../../../utils/constants';
import Column from '../../ReactComponent/Column';
import Row from '../../ReactComponent/Row';
import { billingAccountTextFormatter, billingAccountToNumber } from '../../../utils/form';
import Tooltip from '../../ReactComponent/Tooltip';
import { postalValidateCall } from '../index';
import { arrayLengthSafe } from '../../../utils/arrays';
import { CardDescription } from '../../CreateSctnQuote/css';

const Step1 = ({
  formName,
  step,
  formData = {},
  authenticated,
  dispatch,
  changeBillTo,
  tariffOptions,
  USQuote,
  openModal,
  billingAccounts,
  parentFields = {},
  isLoggedIn,
  addressFieldsRef,
  isProtectedAccount,
  isNonServiceableLocation,
  openContactPermissionModal,
  fromContactId,
  toContactId
}) => {
  const [isAuth, setState] = useState(false);
  useEffect(() => {
    setState(authenticated);
  }, []);
  const {
    division, quoteType, userAddressType, tariffSurcharge, billTo, paymentMethod
  } = formData;
  const changeDivisionToFreight = () => {
    if (division !== Divisions.Freight.name) {
      openModal(Divisions.Freight.name);
    }
  };

  const changeDivisionToSameday = () => {
    if (division !== Divisions.Sameday.name) {
      openModal(Divisions.Sameday.name);
    }
  };

  // eslint-disable-next-line no-shadow
  const cards = (parentFields) => [
    {
      prefix: 'LTL',
      clickHandler: changeDivisionToFreight,
      selectedButtonIsSelected: division === Divisions.Freight.name,
      thumb: parentFields.truckloadCardBannerImage.value.src,
    },
    {
      prefix: 'CommerceSolutions',
      clickHandler: changeDivisionToSameday,
      selectedButtonIsSelected: division === Divisions.Sameday.name,
      thumb: parentFields.commerceSolutionsCardBannerImage.value.src,
    },
    {
      prefix: 'Logistics',
      clickHandler: () => {
        window.location.href = routes.createSctnQuote;
        return window.location.href;
      },
      thumb: parentFields.logisticsCardBannerImage.value.src,
      isExternalLink: true
    }
  ];

  const paymentMethodOptions = () => {
    const options = [
      { name: isAuth ? PaymentMethods.Prepaid : PaymentMethods.CreditCard, desc: isAuth ? 'PrepaidDesc' : 'CreditCardDesc' },
    ];
    if (quoteType && quoteType.name !== QuoteTypes.Domestic) {
      options.append([
        { name: PaymentMethods.Collect, desc: 'CollectDesc' },
        { name: PaymentMethods.ThirdParty, desc: 'ThirdPartyDesc' },
      ]);
    }
    return options;
  };

  const changeTariff = (item) => dispatch(change(formName, 'tariffSurcharge', item));
  const hasSingularAccount = isLoggedIn && arrayLengthSafe(billingAccounts) === 1;

  if (hasSingularAccount && formData.billTo !== billingAccounts[0].accountNumber) {
    dispatch(change(formName, 'billTo', billingAccounts[0].accountNumber));
    changeBillTo(billingAccounts[0].accountNumber);
  }

  if (step === 0) {
    return (
      <>
        <PageTitle>
          {parentFields.pageTitleAddressDetails && parentFields.pageTitleAddressDetails.value}
        </PageTitle>
        {USQuote && <>
           <CanRateTool>
  {t('CrossBorderRules')} <a href="https://dayross.com/guides" rel="noopener noreferrer" target="_blank" className="link">{t('Guides')}</a>
           <p>
           <a className="link" href="/-/media/internet/data/media/files/canrate/dayr505">{t('CanRateLink')}</a>
           </p>
       </CanRateTool>
        </>}
        {!isAuth && (
          <>
            <TooltipContainer>
              <MarginlessH2 field={parentFields.pickAService} />
              <Tooltip tip={t('TipPickService')} marginLeft />
            </TooltipContainer>
            <Row>
              {cards(parentFields).map((card) => (
                <Column $tablet={4} $mobile={12} key={card.prefix}>
                  <ServiceCard {...card} />
                </Column>
              ))}
            </Row>
          </>
        )}
        {division === Divisions.Sameday.name && (
          <CheckBoxColumns $column={2}>
            <Field
              name="deliveryType"
              type="checkboxes"
              data={[
                { name: DeliveryTypes.Commercial, desc: 'CommercialServiceDesc' },
                { name: DeliveryTypes.Residential, desc: 'ResidentialServiceDesc' },
              ]}
              component={RenderField}
              className="delivery-type"
            />
          </CheckBoxColumns>
        )}
        {division === Divisions.Freight.name &&
          <>
            <CategoryTitle>
              {parentFields.pickAQuoteTypeTitle && parentFields.pickAQuoteTypeTitle.value}
              <Tooltip tip={t('TipCreateQuotePickQuoteType')} marginLeft />
            </CategoryTitle>
            {division === Divisions.Freight.name && <>
              <OverShipmentText>
                {USQuote ? <FontSize12><RichText tag="P" field={parentFields.SpotUSQuoteTeamNote}/></FontSize12> : t('SpotQuoteTeam')}
                {!USQuote ? <p>
                  <a className="link" href="mailto:drspot@dayandrossinc.ca">{t('SpotQuoteTeamEmail')}</a>
                </p> : null}
              </OverShipmentText>
            </>}
            <CheckBoxColumns $column={3}>
              <Field
                name="quoteType"
                type="checkboxes"
                data={[
                  { name: QuoteTypes.Domestic, desc: 'DomesticQuoteDesc' },
                  { name: QuoteTypes.USQuote, desc: 'USQuoteDesc' },
                ]}
                validate={[isRequired]}
                component={RenderField}
                onChange={(newObj, obj, prevObj, fieldName) => { openModal(newObj, obj, prevObj, fieldName); }}
              />
            </CheckBoxColumns>
          </>}

          {division === Divisions.Freight.name && USQuote && quoteType && quoteType.name && (
            <>
              <CategoryTitle>
                {t('ShipperOrConsigneeOption')}
              </CategoryTitle>
              <CheckBoxColumns $column={2}>
                <Field
                  name="userAddressType"
                  type="checkboxes"
                  data={[
                    AddressTypes.SHIPPER,
                    AddressTypes.CONSIGNEE
                  ]}
                  validate={[isRequired]}
                  component={RenderField}
                />
              </CheckBoxColumns>
            </>
          )}

        <div className="row" ref={addressFieldsRef}>
          <AddressBlock className="col-sm-6">
            <CategoryTitle>
              {t('ShipperDetails')}
            </CategoryTitle>
            <AddressBookEntry
              contactType={AddressTypes.SHIPPER}
              formName={formName}
              USQuote={USQuote}
              userAddressType={userAddressType}
              validator={postalValidateCall}
              isAddressRequired
              isPhoneNumberRequired={false}
              emailOptional={!(division === Divisions.Freight.name)}
              companyNameOptional
              isNonServiceableLocation={isNonServiceableLocation}
              openContactPermissionModal={openContactPermissionModal}
              defaultContactId={fromContactId}
            />
          </AddressBlock>
          <AddressBlock className="col-sm-6">
            <CategoryTitle>
              {t('ConsigneeDetailsToAddress')}
            </CategoryTitle>
            <AddressBookEntry
              contactType={AddressTypes.CONSIGNEE}
              formName={formName}
              USQuote={USQuote}
              userAddressType={userAddressType}
              validator={postalValidateCall}
              isAddressRequired
              isPhoneNumberRequired={false}
              emailOptional
              companyNameOptional
              contactNameOptional={!USQuote}
              isNonServiceableLocation={isNonServiceableLocation}
              openContactPermissionModal={openContactPermissionModal}
              defaultContactId={toContactId}
            />
          </AddressBlock>
        </div>
        <PaymentMethod>
          {!USQuote && <>
            <CategoryTitle>
              {t('PaymentMethod')}
            </CategoryTitle>
            <Tooltip tip={t('TipCreditCard')} marginLeft />
            <CheckBoxColumns $column={3}>
              <Field
                name="paymentMethod"
                type="checkboxes"
                data={paymentMethodOptions()}
                component={RenderField}
                validate={[isRequired]}
              />
            </CheckBoxColumns>
          </>}
          {(isAuth && paymentMethod && paymentMethod.name === PaymentMethods.Prepaid) && (
            <>
              <div className="row">
                <div className="col-sm-6">
                  <CategoryTitle>
                    {t('BillingInformation')}
                    <Tooltip tip={t('TipBillingAccountDetails')} marginLeft />
                  </CategoryTitle>
                  <Field
                    name="billTo"
                    type={hasSingularAccount ? 'text' : 'dropdown'}
                    label={t('BillTo')}
                    data={billingAccounts}
                    required={isAuth}
                    textField={billingAccountTextFormatter}
                    valueField="accountNumber"
                    component={RenderField}
                    validate={isAuth && [isRequired]}
                    normalize={billingAccountToNumber}
                    // eslint-disable-next-line no-shadow
                    onChange={(billTo) => changeBillTo(billTo)}
                    readOnly={hasSingularAccount}
                  />
                </div>
              </div>
            </>
          )}
          {!isAuth && USQuote && (
            <>
              <CategoryTitle>{t('BillingInformation')}</CategoryTitle>
              <Field
                name="billTo"
                label={t('BillTo')}
                component={RenderField}
                normalize={digitOnly}
                validate={[maxChar6]}
              />
            </>
          )}

          {USQuote && ((billTo && isAuth) || !isAuth) && !isProtectedAccount && (
            <>
              <CategoryTitle>
                {t('FuelSurchargeAndDiscount')}
              </CategoryTitle>
              {t('PleaseSelectARate')}
              <Field
                name="tariffSurcharge"
                type="tableRowSelect"
                data={tariffOptions}
                columns={[
                  'CustomerName',
                  'From',
                  'To',
                  'MinimumRate',
                  'Discount',
                  'FuelSurchargePercentage']}
                selectedValue={tariffSurcharge}
                component={RenderField}
                validate={[isRequired]}
                onClick={changeTariff}
              />
              {tariffSurcharge &&
                <div className="row">
                  <TariffSurchargeSummaryBlock tariffSurcharge={tariffSurcharge} tablet={8} mobile={12} />
                </div>}
            </>
          )}
          <div className="row">
            <div className="col-sm-6">
              <CategoryTitle>
                {parentFields.emailConfirmationHeading && parentFields.emailConfirmationHeading.value}
                <Tooltip tip={t('TipEmailConfirmation')} marginLeft />
              </CategoryTitle>
              <Field
                name="quoteDest"
                component={RenderField}
                label={t('ContactEmail')}
                validate={[isEmail, maxChar50, isRequired]}
                required
              />
            </div>
          </div>
        </PaymentMethod>
      </>
    );
  }
  return null;
};

// eslint-disable-next-line react/no-multi-comp
const ServiceCard = ({
  prefix,
  thumb,
  clickHandler = (e) => e,
  selectedButtonIsSelected = false,
}) => (
    <Card
      title={t(prefix)}
      thumb={t(thumb)}
      lgThumb
      link="#"
      linkText={t('LearnMore')}
      selectedButton
      selectedButtonIsSelected={selectedButtonIsSelected}
      selectedButtonClickHandler={clickHandler}
    ><CardDescription>{t(`${prefix}Desc`)}</CardDescription></Card>
);

export default Step1;
