import React from 'react';
import { t } from 'i18next';
import { CubeBox, CubeContainer } from './css';
import { P } from '../../../theme/typography';
import { getSizeMeasurementAbbreviationString } from '../../../utils/func';

const Cube = ({
  width = 0,
  height = 0,
  length = 0,
  solid,
  measurementType,
}) => {
  let cubeWidth;
  let cubeHeight;
  let cubeLength;

  if (width > 30) {
    cubeWidth = 300;
  } else if (width < 10) {
    cubeWidth = 100;
  } else {
    cubeWidth = width * 10;
  }

  if (height > 30) {
    cubeHeight = 300;
  } else if (height < 10) {
    cubeHeight = 100;
  } else {
    cubeHeight = height * 10;
  }

  if (length > 30) {
    cubeLength = 300;
  } else if (length < 10) {
    cubeLength = 100;
  } else {
    cubeLength = length * 10;
  }
  const measurement = getSizeMeasurementAbbreviationString(measurementType);

  return (
    <CubeContainer className="text-center">
      <CubeBox
        className={solid && 'solid'}
        $width={cubeWidth}
        $height={cubeHeight}
        $length={cubeLength}
      >
        <div className="after"></div>
        <div className="before"></div>
      </CubeBox>
      <P>
        W:
        {width}
        {' '}
        {measurement}
        {' '}
        - H:
        {' '}
        {height}
        {' '}
        {measurement}
        {' '}
        - L:
        {' '}
        {length}
        {' '}
        {measurement}
      </P>
      <P>{t('NotToScaleBoxHeading')}</P>
    </CubeContainer>
  );
};

export default Cube;
