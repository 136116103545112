import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import Column from '../ReactComponent/Column';

export const Page = styled.div`
    width: 100%;
`;

export const OffsetContainer = styled.div`
    margin: 20px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    ${MediaQuery.medium`
    width: auto;
    margin: 40px 10px;
  `}
`;

export const ActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    ${MediaQuery.medium`
    flex-direction: row;
  `}
`;

export const ProgressBarDecorator = styled.div`
    width: 100%;
`;

export const FormContainer = styled.div`
    width: 100%;
`;

export const AddressFitColumn = styled(Column)`
    &.col-md-6 {
        ${MediaQuery.medium`padding-right: 76px;`}
    }
`;

export const CheckBoxColumns = styled.div`
    .rw-list-option {
        ${MediaQuery.medium`width: ${({ $column }) => `${100 / $column}%`};`}
        label.rw-select-list-label {
            display: block;

            div {
                position: relative;
                left: -28px;
                top: 18px;
                font-size: 13px;
            }

        }

        margin: 3em 0;

        &.rw-state-disabled {
            opacity: 0.5;

            .rw-select-list-input:checked ~ .checkmark {
                background-color: ${colors.DARK_GREY};
            }
        }
`;

export const RadioColumns = styled.div`
    h3 {
        font-size: 24px;
        font-weight: normal;
    }

    .rw-list {
        margin: 0;
    }


    .rw-list-option {
        width: 100%;
        margin: 10px 0;

        ${MediaQuery.medium`width: ${({ column }) => `${100 / column}%`};`}
        label.rw-select-list-label {
            margin-right: 30rem;
            font-size: 1.4rem;
            line-height: 2.1rem;
            display: inline-flex;
            align-items: center;
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            user-select: none;
            flex-wrap: wrap;

            .rw-select-list-input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark {
                    background-color: ${colors.WHITE};

                    &:after {
                        display: block;
                        left: 1px;
                        top: 1px;
                        width: 15px;
                        height: 15px;
                        background-color: ${colors.BRAND_ORANGE};
                        border: 4px solid ${colors.WHITE};
                        border-radius: 20px;
                    }
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                border: 1px solid ${colors.BLACK};
                background-color: ${colors.WHITE};
                border-radius: 20px;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
            }
        }

        label.rw-select-list-label {
            margin-right: 3em;
            padding: 0;

            div {
                margin: 18px 0;
                font-size: 13px;
            }

            .checkmark {
                position: relative;
                margin-right: 10px;
            }
        }

        h3 {
            position: relative;
            top: 3px;
            font-size: 15px;
        }

        div {
            font-size: 13px;
        }
    }
`;

export const CategoryTitle = styled.div`
    font-size: 2.4rem;
    margin-top: 64px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`;

export const ContentBoxLightGrey = styled.div`
    ${({ border }) => border && 'border: solid black 2px'}
    background-color: ${colors.LIGHT_GREY};
    padding: 32px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 100%;
    margin: 40px 0;
    position: relative;
    flex-wrap: wrap;

    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        flex-wrap: wrap;

        p {
            margin: 0;
        }

        > :nth-child(even) {
            background-color: ${colors.WHITE};
        }
    }
`;

export const LineButton = styled.a`
    display: inline-flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    cursor: pointer;
    color: ${colors.BLACK};
    text-decoration: none;
    padding: 8px 17px;
`;

export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;

export const TextProperty = styled.div`
    font-size: 1.4rem;
    margin: 32px 0;
    font-weight: 500;

    .title {
        margin-bottom: 10px;
    }

    .justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    hr {
        border-color: #000;
        margin: 1.5rem 0 3rem;
    }
`;

export const NarrowBlock = styled.div`
    &:nth-child(1) {
        ${MediaQuery.medium`
            padding-right: 76px;
        `}
    }

    &:nth-child(2) {
        ${MediaQuery.medium`
            padding-left: 76px;
        `}
    }

    &.with-bg {
        background-color: ${colors.LIGHT_GREY};
        padding: 32px;
        box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        margin-bottom: 32px;
    }
`;

export const SubItem = styled.div`
    margin-left: 24px;
`;

export const CardDescription = styled.p`
    padding: 0 60px;
    font-size: 12px;
    line-height: 20px;
`;
