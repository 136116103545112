import styled from 'styled-components';
import { H2, P } from '../../../../theme/typographySitecore';
import ArrowRightOrange from '../../../../assets/icons/ArrowRightOrange.svg';
import { colors, MediaQuery } from '../../../../theme/style-helpers';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.COOL_GREY};
  cursor: pointer;

  ${MediaQuery.medium`
    padding: 24px;
  `}
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  ${MediaQuery.medium`
    flex-direction: column;
    align-items: flex-start;
  `}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
    text-align: left;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  padding-left: 24px;
`;

export const ButtonTitle = styled(H2)`
  font-size: 1.6rem;
  margin-bottom: 0;
  text-align: left;

  ${MediaQuery.medium`
    display: unset;
    margin: 0;
    margin-bottom: 16px;
    font-size: 24px;
  `}
`;

export const ButtonDescription = styled(P)`
  display: none;  
  
  ${MediaQuery.medium`
    display: flex;
    margin: 0;
    text-align: start;
  `}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
  }
`;

export const ArrowIcon = styled.img.attrs(() => ({ src: ArrowRightOrange }))`
  max-width: none;
`;
