import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Field } from 'redux-form';
import RenderField from '../../RenderField';
import { P, Span } from '../../../../theme/typographySitecore';
import Column from '../../Column';
import Row from '../../Row';
import { getMeasurementAbbreviation } from '../Units/utils';
import {
  AddItem, AddItemContainer, AddItemLabel, LabelWithUnitsContainer, UnitsColumn,
} from './css';
import PlusGrey from '../../../../assets/icons/PlusGrey.svg';

export const FieldWithUnits = ({
  name,
  label,
  unitsLabel,
  unitType,
  unitScale = 0,
  unitsSup = '',
  validate = [],
  required = false,
  normalize = (e) => e,
}) => (
    <Row>
      <Column $mobile={10}>
        <Field
          name={name}
          type="text"
          component={RenderField}
          required={required}
          validate={validate}
          label={t(label)}
          normalize={normalize}
          style={{ width: '100%' }}
        />
      </Column>
      <UnitsColumn $mobile={1}>
        {unitsLabel && (
          <Span
            field={getMeasurementAbbreviation(unitsLabel, unitType, unitScale)}
          />
        )}
        {unitsSup && <sup>{unitsSup}</sup>}
      </UnitsColumn>
    </Row>
);

FieldWithUnits.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  unitsLabel: PropTypes.string.isRequired,
  unitType: PropTypes.string.isRequired,
  unitScale: PropTypes.number,
  unitsSup: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.func),
  required: PropTypes.bool,
  normalize: PropTypes.func,
};

// eslint-disable-next-line
export const LabelWithUnits = ({
  label,
  value,
  unitsLabel,
  unitType,
  unitScale = 0,
  unitsSup = '',
}) => (
    <LabelWithUnitsContainer>
      <P field={t(label)} />
      <div>
        <P field={value} />
        {unitsLabel && (
          <Span
            field={getMeasurementAbbreviation(unitsLabel, unitType, unitScale)}
          />
        )}
        {unitsSup && <sup>{unitsSup}</sup>}
      </div>
    </LabelWithUnitsContainer>
);

LabelWithUnits.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  unitsLabel: PropTypes.string.isRequired,
  unitType: PropTypes.string.isRequired,
  unitScale: PropTypes.number,
  unitsSup: PropTypes.string,
};

// eslint-disable-next-line
export const AddButton = ({ onClick, title, disabled }) => (
  <AddItemContainer>
    <AddItem onClick={onClick} disabled={disabled}>
      <AddItemLabel>{t(title)}</AddItemLabel>
      <img src={PlusGrey} alt="add item" />
    </AddItem>
  </AddItemContainer>
);

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
