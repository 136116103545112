import styled from 'styled-components';
import { colors } from '../../theme/style-helpers';

export const Icon = styled.img`
    transform: rotate(0);
    transition: all 0.3s;
    cursor: pointer;
    width: 16px;
    height: 20px;
`;

export const FilterContainer = styled.div`
    width: 100%;
    background: ${colors.WHITE};
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 40px;
`;

export const PageDescription = styled.div`
    font-size: 18px;
    line-height: 24px;
`;

export const ModalTitle = styled.div`
    font-size: 2.5rem;
    padding-top: 34px;
    padding-bottom: 25px;
`;

export const Close = styled.div`
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    &:before,
    &:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 33px;
        width: 2px;
        background-color: #333;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
`;

export const ModalBody = styled.div`
    padding: 50px;
    font-size: 1.5rem;
    text-align: center;
`;

export const ButtonDiv = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 0;
`;

export const SuccessDeleteMessage = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: ${colors.LAND_GREEN};
`;
