import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, change } from 'redux-form';
import { t } from 'i18next';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowLeftOrange from '../../assets/icons/ArrowLeftOrange.svg';
import putReduxCompanies from './actions';
import {
  createContact,
  updateContactById,
  getContactInfo
} from '../../services/contacts';
import {
  getCompaniesList,
} from '../../services/companies';
import { formDataToApi, formApiToData } from '../../services/contacts/helpers';
import {
  Icon,
  ButtonGroup,
  FormContainer,
} from './css';
import SecondaryButton from '../ReactComponent/SecondaryButton';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import ContactForm from './components/ContactForm';
import { homeUrlNoTrailingSlash } from '../../utils/func';
import { routes } from '../../utils/constants';
import LeavePageModal from '../ReactComponent/LeavePageModal';

const formName = 'CreateContactForm';
const initialValues = {
  companies: [{}]
};

class CreateContactForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      companiesList: [],
      contactId: '',
      modalIsOpen: false,
    };
  }

  componentDidMount() {
    this.fetchAllCompanies().then(() => {
      if (typeof window !== 'undefined') {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('contactId')) {
          this.setState({ contactId: urlParams.get('contactId') });
          this.handleId(
            urlParams.get('contactId')
          );
        }
      }
    });
  }

  handleId = (Id) => {
    getContactInfo(Id)
      .then((response) => {
        if (response.data) {
          const { data } = response;
          const formedData = formApiToData(data);
          this.dispatchFormValues(formedData);
        }
      });
  };

  dispatchFormValues = (data) => {
    const { dispatch } = this.props;
    Object.keys(data).map((field) => dispatch(change(formName, field, data[field])));
  };

  fetchAllCompanies = () => {
    const {
      division,
      userId
    } = this.props;

    return getCompaniesList(division, userId).then((res) => {
      if (res.status === 200) {
        // const { putReduxCompanies } = this.props;
        putReduxCompanies(res.data);
        this.setState({ companiesList: res.data });
      }
    });
  };

  goToManage = (e) => {
    if (e) {
      e.preventDefault();
    }

    const manageContactsUrl = `${homeUrlNoTrailingSlash()}${routes.manageContacts}`;
    window.location.href = manageContactsUrl;
  };

  updateState = (name, value) => this.setState({ [name]: value });

  scrollToTop = () => window.scrollTo(0, 0);

  handleSubmit = () => {
    const {
      formData,
      isAuthenticated,
    } = this.props;
    const {
      contactId,
      companiesList
    } = this.state;

    if (isAuthenticated) {
      const changedData = formDataToApi(formData, companiesList);

      if (formData.rowVersion) {
        updateContactById(changedData, contactId).then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 204) {
            this.goToManage();
          }
        });
      } else {
        createContact(changedData).then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 204) {
            this.goToManage();
          }
        });
      }
    }
  };

  render() {
    const {
      formData,
      handleSubmit,
      fields
    } = this.props;

    const {
      companiesList,
      modalIsOpen
    } = this.state;

    return (
      <>
        <LeavePageModal isOpen={modalIsOpen} onClose={() => this.setState({ modalIsOpen: false })} onContinue={() => this.goToManage()}/>
        <FormContainer>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <ContactForm
              formData={formData}
              formName={formName}
              companiesList={companiesList}
              parentFields={fields}
            />
            <ButtonGroup>
              <SecondaryButton type="button" className="active" onClick={() => this.setState({ modalIsOpen: true })}>
                <Icon className="icon-left" src={ArrowLeftOrange} alt="back"/>
                {t('Cancel')}
              </SecondaryButton>
              <PrimaryButton type="submit" className="submit">
                <Icon className="icon" src={ArrowRightWhite} alt="submit"/>
                {t('SaveChanges')}
              </PrimaryButton>
            </ButtonGroup>
          </form>
        </FormContainer>
      </>
    );
  }
}

CreateContactForm = reduxForm({
  form: formName, // a unique identifier for this form
  initialValues,
})(CreateContactForm);

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  isAuthenticated: state.profile.isAuthenticated,
  division: state.profile.division,
  userId: state.profile.userId,
});

const mdtp = (dispatch) => ({
  putReduxCompanies: (companiesList) => dispatch(putReduxCompanies(companiesList)),
});

export default connect(mstp, mdtp)(CreateContactForm);
