import styled from 'styled-components';
import { colors, MediaQuery } from '../../../../theme/style-helpers';

export const Container = styled.div`
  background-color: ${colors.LIGHT_GREY};
  padding: 32px;
  margin-bottom: 32px;
`;

export const RadioColumns = styled.div`
  margin-left: 24px;
  h3 {
    font-size: 24px;
    font-weight: normal;
  }
  .rw-list-option {
    width: 100%;
    margin: 5px 0;
    ${MediaQuery.medium`width: ${({ column }) => `${100 / column}%`};`}
    
    &.rw-state-disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    label.rw-select-list-label {
      margin-right: 32px;
      padding: 0;
      .checkmark {
        position: relative;
        margin-right: 12px;
      }
      .rw-select-list-input {
        &:checked ~ .checkmark {
          background-color: white;
        }
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  h2 {
    width: 100%;
  }
`;
