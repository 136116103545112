import React from 'react';
import { t } from 'i18next';
import { PaginationContainer } from './css';

const PaginationControls = ({ currentPage, totalPages, setCurrentPageFn }) => {
  const handlePageFirst = () => {
    setCurrentPageFn(1);
  };

  const handlePagePrevious = () => {
    if (currentPage === 1) {
      return;
    }

    setCurrentPageFn(currentPage - 1);
  };

  const handlePageNext = () => {
    if (currentPage === totalPages) {
      return;
    }

    setCurrentPageFn(currentPage + 1);
  };

  const handlePageLast = () => {
    setCurrentPageFn(totalPages);
  };

  const handleClick = (e) => {
    setCurrentPageFn(Number(e.target.id));
  };

  // eslint-disable-next-line no-shadow
  const getPaginationRange = ({ currentPage, totalPages }) => {
    // Constants...
    const START_PAGE = 1;
    const DEFAULT_RANGE = 5;

    // Initializations...
    let range = DEFAULT_RANGE;

    if (range > totalPages) {
      range = totalPages;
    }

    let start = currentPage - Math.floor(range / 2);
    start = Math.max(start, START_PAGE);
    start = Math.min(start, START_PAGE + totalPages - range);

    return Array.from(
      { length: range },
      (element, index) => start + index
    );
  };

  const pages = getPaginationRange({ currentPage, totalPages });

  return (
    <PaginationContainer>
      <ul>
        <li onClick={handlePageFirst}>
          <div className="firstArrow"/>
          {t('First')}
        </li>
        <li onClick={handlePagePrevious}>
          <div className="prevArrow"/>
          {t('Previous')}
        </li>

        {
          pages.map((page) => (
            <li
              key={page}
              id={page}
              onClick={handleClick}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </li>
          ))
        }

        <li onClick={handlePageNext}>
          {t('Next')}
          <div className="nextArrow"/>
        </li>
        <li onClick={handlePageLast}>
          {t('Last')}
          <div className="lastArrow"/>
        </li>
      </ul>
    </PaginationContainer>
  );
};

export default PaginationControls;
