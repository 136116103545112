import React from 'react';
import {
  Text, RichText, Link
} from '@sitecore-jss/sitecore-jss-react';
import { P } from '../../theme/typography';
import { Img } from '../ReactComponent/Image';
import {
  SectionTitle, Section, LeftColumn, RightColumn, ButtonGroup
} from './css';
import { anchor } from '../../utils/func';

const MediaContent = ({ fields }) => {
  const { primaryLink } = fields;
  const hasPrimary = primaryLink && primaryLink.value && primaryLink.value.href;
  const { secondaryLink } = fields;
  const hasSecondary = secondaryLink && secondaryLink.value && secondaryLink.value.href;

  return (<>
    {fields.heading && fields.heading.value &&
      <SectionTitle>
        <Text field={fields.heading}/>
      </SectionTitle>}
    {fields.content && <P><RichText field={fields.content}/></P>}
    {(hasPrimary || hasSecondary) &&
      <ButtonGroup className="right">
          {hasSecondary &&
              <Link field={secondaryLink} className="secondaryButton">
                  <span className="icon"></span>{secondaryLink.value.text}
              </Link>}
          {hasPrimary &&
              <Link field={primaryLink} className="primaryButton submit">
                  <span className="icon"></span>{primaryLink.value.text}
              </Link>}
      </ButtonGroup>}
  </>);
};

const SectionClass = (isLeft, imageOnRight, useEqualLayoutWidth) => {
  if (useEqualLayoutWidth) {
    return 'flex-50';
  }

  if (isLeft) {
    return imageOnRight ? 'flex-60' : 'flex-40';
  }

  return imageOnRight ? 'flex-40' : 'flex-60';
};

// eslint-disable-next-line react/no-multi-comp
const ArticleSectionMedia = ({ fields }) => {
  const imageOnRight = fields.imageOnRight && fields.imageOnRight.value;
  const useEqualLayoutWidth = fields['use Equal Layout Width'] && fields['use Equal Layout Width'].value;
  const id = fields.anchor && fields.anchor.value ? anchor(fields.anchor.value) : '';

  return (
    <Section id={id} className="static-section">
      <LeftColumn className={SectionClass(true, imageOnRight, useEqualLayoutWidth)}>
        {imageOnRight ?
          <MediaContent fields={fields}></MediaContent> :
          (
            fields.image && <Img field={fields.image}/>
          )}
      </LeftColumn>
      <RightColumn className={SectionClass(false, imageOnRight, useEqualLayoutWidth)}>
        {imageOnRight ? (
          fields.image && <Img field={fields.image}/>
        ) :
        <MediaContent fields={fields}></MediaContent>}
      </RightColumn>
    </Section>
  );
};

export default ArticleSectionMedia;
