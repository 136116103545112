import { ApolloClient, InMemoryCache } from '@apollo/client';

/*
  INTROSPECTION DATA
  See https://www.apollographql.com/docs/react/recipes/fragment-matching.html
  This enables the Apollo cache to process fragments on interface types correctly.
  If this file does not exist, you may need to run the `jss graphql:update` script.
*/

/*
  QUERY LINK SELECTION
  A link is transport which GraphQL queries are pushed across.
  You have many choices.
  See the apollo-link documentation for more details.

  NOTE: to use Sitecore Experience Editor it is essential that your
  link passes cookies along with requests (credentials: 'include').
*/

// choose between a basic HTTP link to run queries...
// import { createHttpLink } from 'apollo-link-http';
// const link = createHttpLink({ uri: endpoint, credentials: 'include' });

// ...or a batched link (multiple queries within 10ms all go in one HTTP request)
import { BatchHttpLink } from '@apollo/client/link/batch-http';

// ...and an automatic persisted query link, which reduces bandwidth by using query hashes to alias content
// the APQ link is _chained_ behind another link that performs the actual HTTP calls, so you can choose
// APQ + batched, or APQ + http links for example.
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';

export default function GraphQLClientFactory(endpoint, ssr, initialCacheState) {
  /* HTTP link selection: default to batched + APQ */
  const link = createPersistedQueryLink({
    generateHash: () => {},
  }).concat(
    new BatchHttpLink({
      uri: endpoint,
      credentials: 'include'
    }),
  );

  const cache = new InMemoryCache({
    typePolicies: {
      // Define possibleTypes for each interface
      // Replace 'YourInterfaceName' with the name of your interface
      // Replace 'YourType1', 'YourType2', etc. with the actual type names
      ItemField: {
        possibleTypes: [
          'ReferenceField',
          'NumberField',
          'MultilistField',
          'LinkField',
          'TextField',
          'IntegerField',
          'ImageField',
          'FileField',
          'DateField',
          'CheckboxField',
          'NameValueListField',
          'LookupField',
          'LayoutField',
        ],
      },
      Item: {
        possibleTypes: [
          'UnknownItem',
          'StyleguideSitecoreContext',
          'StyleguideSection',
          'StyleguideRouteFields',
          'StyleguideMultilingual',
          'StyleguideLayoutTabsTab',
          'StyleguideLayoutTabs',
          'StyleguideLayoutReuse',
          'StyleguideItemLinkItemTemplate',
          'StyleguideFieldUsageText',
          'StyleguideFieldUsageRichText',
          'StyleguideFieldUsageNumber',
          'StyleguideFieldUsageLink',
          'StyleguideFieldUsageItemLink',
          'StyleguideFieldUsageImage',
          'StyleguideFieldUsageFile',
          'StyleguideFieldUsageDate',
          'StyleguideFieldUsageCustom',
          'StyleguideFieldUsageContentList',
          'StyleguideFieldUsageCheckbox',
          'C__StyleguideExplanatoryComponent',
          'StyleguideContentListItemTemplate',
          'StyleguideComponentParams',
          'C__StandardTemplate',
          'GraphQLIntegratedDemo',
          'GraphQLConnectedDemo',
          'ExampleCustomRouteType',
          'ContentBlock',
          'C__AppRoute',
        ],
      },
      StyleguideExplanatoryComponent: {
        possibleTypes: [
          'StyleguideSitecoreContext',
          'StyleguideRouteFields',
          'StyleguideMultilingual',
          'StyleguideLayoutTabs',
          'StyleguideLayoutReuse',
          'StyleguideFieldUsageText',
          'StyleguideFieldUsageRichText',
          'StyleguideFieldUsageNumber',
          'StyleguideFieldUsageLink',
          'StyleguideFieldUsageItemLink',
          'StyleguideFieldUsageImage',
          'StyleguideFieldUsageFile',
          'StyleguideFieldUsageDate',
          'StyleguideFieldUsageCustom',
          'StyleguideFieldUsageContentList',
          'StyleguideFieldUsageCheckbox',
          'C__StyleguideExplanatoryComponent',
          'StyleguideComponentParams',
        ],
      },
      AppRoute: {
        possibleTypes: [
          'ExampleCustomRouteType',
          'C__AppRoute',
        ],
      },
    },
  });

  return new ApolloClient({
    ssrMode: ssr,
    ssrForceFetchDelay: 100,
    link,
    cache: cache.restore(initialCacheState),
  });
}
