import React from 'react';
import PropTypes from 'prop-types';
import { googleMapUrl } from '../../../utils/constants';
import { GoogleMapDiv, GoogleMapTerminal } from './css';
import DefaultIcon from '../../../assets/icons/AddressFrom.svg';
import HighlightedIcon from '../../../assets/icons/AddressTo.svg';

class GoogleMap extends React.Component {
  googleMapRef = React.createRef();

  GOOGLEMAPSCRIPT = 'google-map-script';

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const gmscript = document.getElementById(this.GOOGLEMAPSCRIPT);

      if (!gmscript) {
        const googleMapScript = document.createElement('script');
        googleMapScript.src = googleMapUrl;
        googleMapScript.id = this.GOOGLEMAPSCRIPT;
        window.document.body.appendChild(googleMapScript);
        googleMapScript.addEventListener('load', () => this.initMap());
      } else {
        this.initMap();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (typeof window !== 'undefined') {
      const { markers } = this.props;
      const differentMarkers = prevProps.markers !== markers;

      if (differentMarkers) {
        this.removeCurrentMarkers();
        const isFormNameExist = markers[0]
          && Object.prototype.hasOwnProperty.call(markers[0], 'formName')
          && markers[0].formName === 'TerminalLocationForm';

        if (isFormNameExist) {
          this.googleMarkers = this.createTerminalLocationMarkers(markers);
        } else {
          this.googleMarkers = this.createMarkers(markers);
        }
      }
    }
  }

  initMap = () => {
    const { markers } = this.props;
    const isFormNameExist = markers[0]
      && Object.prototype.hasOwnProperty.call(markers[0], 'formName')
      && markers[0].formName === 'TerminalLocationForm';
    if (isFormNameExist) {
      this.googleMap = this.createGoogleMap();

      this.defaultIcon = {
        size: new window.google.maps.Size(17, 23),
        scaledSize: new window.google.maps.Size(17, 23),
        url: DefaultIcon
      };
      this.highlightedIcon = {
        size: new window.google.maps.Size(17, 23),
        scaledSize: new window.google.maps.Size(17, 23),
        url: HighlightedIcon
      };
      this.selectedIcon = {
        size: new window.google.maps.Size(34, 45),
        scaledSize: new window.google.maps.Size(34, 45),
      };

      this.selectedMarker = null;
      this.googleMarkers = this.createTerminalLocationMarkers(markers);
    } else {
      this.googleMap = this.createGoogleMap();
      this.defaultIcon = {
        size: new window.google.maps.Size(17, 23),
        scaledSize: new window.google.maps.Size(17, 23),
        url: DefaultIcon
      };
      this.highlightedIcon = {
        size: new window.google.maps.Size(17, 23),
        scaledSize: new window.google.maps.Size(17, 23),
        url: HighlightedIcon
      };
      this.selectedIcon = {
        size: new window.google.maps.Size(34, 45),
        scaledSize: new window.google.maps.Size(34, 45),
      };

      this.selectedMarker = null;
      this.googleMarkers = this.createMarkers(markers);
    }
  };

  removeCurrentMarkers = () => {
    if (this.googleMarkers) {
      this.googleMarkers.forEach((m) => m.setMap(null));
    }
    this.googleMarkers = null;
  };

  createGoogleMap = () => new window.google.maps.Map(this.googleMapRef.current, {
    zoom: 17,
    disableDefaultUI: true,
    controlSize: 24,
    mapTypeControl: false,
    mapTypeId: 'roadmap',
    scaleControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    scrollwheel: false,
    zoomControl: true,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_BOTTOM
    }
  });

  createTerminalLocationMarkers = (markers) => {
    const { onClickMarker } = this.props;
    const bounds = new window.google.maps.LatLngBounds();
    const googleMarkers = markers.map((m, i) => {
      const {
        lat,
        lng,
        ishighlighted,
        selected
      } = m;

      const marker = new window.google.maps.Marker({
        position: {
          lat,
          lng
        },
        map: this.googleMap,
        label: `${i + 1}`,
        icon: ishighlighted ? this.highlightedIcon : this.defaultIcon,
        ishighlighted,
      });

      if (selected) {
        this.selectedIcon.url = marker.icon.url;
        marker.setIcon(this.selectedIcon);
        marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 2);
        this.selectedMarker = marker;
      }

      marker.addListener('click', () => {
        const oldMarker = this.selectedMarker;
        this.selectedMarker = marker;

        if (this.selectedMarker === oldMarker) {
          return;
        }

        onClickMarker(m);
      });

      bounds.extend(marker.position);
      return marker;
    });

    if (markers.length === 1) {
      const onemarker = markers[0];
      this.googleMap.setCenter({
        lat: onemarker.lat,
        lng: onemarker.lng
      });
    } else {
      this.googleMap.fitBounds(bounds);
      this.googleMap.panToBounds(bounds);
    }
    return googleMarkers;
  };

  createMarkers = (markers) => {
    const { onClickMarker } = this.props;
    const bounds = new window.google.maps.LatLngBounds();
    const googleMarkers = markers.map((m) => {
      const {
        lat,
        lng,
        ishighlighted,
        selected
      } = m;
      const marker = new window.google.maps.Marker({
        position: {
          lat,
          lng
        },
        map: this.googleMap,
        icon: ishighlighted ? this.highlightedIcon : this.defaultIcon,
        ishighlighted,
      });

      if (selected) {
        this.selectedIcon.url = marker.icon.url;
        marker.setIcon(this.selectedIcon);
        marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 2);
        this.selectedMarker = marker;
      }

      marker.addListener('click', () => {
        const oldMarker = this.selectedMarker;
        this.selectedMarker = marker;

        if (this.selectedMarker === oldMarker) {
          return;
        }

        onClickMarker(m);
      });

      bounds.extend(marker.position);
      return marker;
    });

    if (markers.length === 1) {
      const onemarker = markers[0];
      this.googleMap.setCenter({
        lat: onemarker.lat,
        lng: onemarker.lng
      });
    } else {
      this.googleMap.fitBounds(bounds);
      this.googleMap.panToBounds(bounds);
    }
    return googleMarkers;
  };

  render() {
    const {
      mapid,
      markers
    } = this.props;
    return (
      <div>
        {
          markers[0] && Object.prototype.hasOwnProperty.call(markers[0], 'formName') ?
            <GoogleMapTerminal
              id={mapid}
              className="googleMap"
              ref={this.googleMapRef}
            />
            :
            <GoogleMapDiv
              id={mapid}
              className="googleMap"
              ref={this.googleMapRef}
            />
        }
      </div>
    );
  }
}

GoogleMap.propTypes = {
  markers: PropTypes.array,
  onClickMarker: PropTypes.func,
  mapid: PropTypes.string
};

export default GoogleMap;
