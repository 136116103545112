import React from 'react';
import { t } from 'i18next';
import { ActionButton } from '../../ReactComponent/Modal/css';
import AlertModal from '../../ReactComponent/Modal/AlertModal';
import { P } from '../../../theme/typographySitecore';
import { CheckmarkIcon, HeaderContainer } from './css';

const ActivationEmailModal = ({
  isOpen,
  onClose,
  parentFields
}) => {
  const header = () => (
    <HeaderContainer>
      <CheckmarkIcon/>
      <div>{parentFields.activationEmailModalHeader.value}</div>
    </HeaderContainer>
  );

  const content = () => (
    <P field={parentFields.activationEmailSuccessModalSummary}/>
  );

  const actions = () => (
    <ActionButton className="active" iconRightArrow onClick={onClose}>
      {t('Okay')}
    </ActionButton>
  );

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      content={content}
      actions={actions}
    />
  );
};
export default ActivationEmailModal;
