import React, { useState } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import { P } from '../../theme/typography';
import {
  HeaderContainer,
  HeaderTitle,
  ContactDetailsContainer,
  AddressLine,
  AddressText,
  AddressIcon,
  HeaderTitleContainer,
  EmptyLine,
  ContactUsPageContainer,
  AlignedFluidContainer,
  ContactusBgContainer,
  ContactDetailsRow,
  AddressTextContainer,
  HeaderRow
} from './css';
import GetInTouch from './get-in-touch';
import Column from '../ReactComponent/Column';

const ContactUs = ({ fields }) => {
  const [state] = useState({ contactReason: null });

  const {
    headerTitle,
    headerDescription,
    streetAddress,
    city,
    state: province,
    postalCode,
    country,
    locationImage,
    reasonsBackGroundImage
  } = fields;
  const locationImageDetails = locationImage.value;

  const reasonsBGDetails = reasonsBackGroundImage.value;

  const renderContactusBgPane = () => (
    <AlignedFluidContainer>
      <ContactusBgContainer $img={reasonsBGDetails.src} width={reasonsBGDetails.width} height={reasonsBGDetails.height}>
      </ContactusBgContainer>
    </AlignedFluidContainer>
  );

  const renderHeaderPane = () => <HeaderContainer >
            <HeaderRow>
                <Column $tablet={8} $mobile={12} >
                    <HeaderTitleContainer >
                        <HeaderTitle>{headerTitle.value}</HeaderTitle>
                        <P><RichText tag="P" field={headerDescription}/></P>
                    </HeaderTitleContainer>
                </Column>
                <Column $tablet={4} $mobile={12} >
                    <ContactDetailsContainer>
                        <ContactDetailsRow>
                            <AddressText $tablet={10} $mobile={10}>
                                <AddressTextContainer>
                                    <AddressLine>{streetAddress.value}</AddressLine>
                                    <AddressLine>{`${city.value}, ${province.value}`}</AddressLine>
                                    <AddressLine>{`${postalCode.value}, ${country.value}`}</AddressLine>
                                </AddressTextContainer>
                            </AddressText>
                            <AddressIcon $desktop={2} $mobile={2}>
                                <img
                                    src={locationImageDetails.src}
                                    alt={locationImageDetails.alt}
                                    width={locationImageDetails.width}
                                    height={locationImageDetails.height} />
                            </AddressIcon>
                        </ContactDetailsRow>
                    </ContactDetailsContainer>
                </Column>
            </HeaderRow>
            <EmptyLine />
        </HeaderContainer>;

  const renderGetInTouchPane = () => <GetInTouch reason={state.contactReason} cmsFields={fields}></GetInTouch>;

  return <ContactUsPageContainer >
        {renderHeaderPane()}
        {renderContactusBgPane()}
        {renderGetInTouchPane()}
    </ContactUsPageContainer>;
};

ContactUs.propTypes = {
  fields: PropTypes.object
};

export default ContactUs;
