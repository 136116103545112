import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';

const submitApplicationForm = (formData) => {
  const data = {
    ...formData,
    service: formData.service.name,
    unitType: formData.unitType.name,
    equipmentType: formData.equipmentType && formData.equipmentType.name,
    paymentMethod: formData.paymentMethod.name,
    importType: formData.importType && formData.importType.name,
  };

  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.createSctnQuoteApi}`, data);
};
export default submitApplicationForm;
