import styled from 'styled-components';
import { colors } from '../../../theme/style-helpers';
import CheckmarkWhite from '../../../assets/icons/CheckmarkWhite.svg';
import PrimaryButton from '../PrimaryButton';
import { convertHex } from '../../../utils/func';

export const CardBox = styled.div`
    padding: 32px 0;
    padding-left: 1px; // Used to properly wrap the border around the banner image...
    background: ${colors.LIGHT_GREY};
    border: 1px solid ${colors.LIGHT_GREY};
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    margin-bottom: 16px;

    &.lgThumb {
        padding-top: 0px;
    }

    .image {
        margin-bottom: 32px;
        max-width: 10rem;
        max-height: 10rem;
    }

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    &.active {
        border-color: ${colors.BLACK};
    }

    &.static {
        &:hover {
            border-color: ${colors.LIGHT_GREY};
        }
    }

    .link {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: ${colors.BLACK};
        text-decoration: none;
        margin-top: 10px;
        display: inline-block;
    }
`;

export const LargeThumb = styled.div`
    position: relative;
    top: 0;
    left: 0;

    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 15rem;
    margin-bottom: 32px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-image: ${({ $showGradient }) => (
    $showGradient
      ? `
          linear-gradient(
          to top,
          ${convertHex(colors.BRAND_ORANGE, 50)},
          ${convertHex(colors.BRAND_ORANGE, 50)}
          ),
        `
      : ''
  )} url(${({ $thumb }) => $thumb});

    .largeThumbOverlay {
        ${({ $showGradient }) => (
    $showGradient
      ? `
              background: url(${CheckmarkWhite}) center center no-repeat;
              position: absolute;
              top: 0;
              height: 100%;
              width: 100%;
            `
      : ''
  )}
    }
`;

export const PrimaryButtonStyledComponent = styled(PrimaryButton)`
    width: 50%;
    margin: 30px auto;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: inline-block;
        p {
            padding: 0 1.5rem;
        }
    }
`;
