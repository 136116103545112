import React from 'react';
import { connect } from 'react-redux';
import {
  formValueSelector,
  reduxForm,
  getFormValues,
  getFormSyncErrors
} from 'redux-form';
import { t } from 'i18next';
import { ActionsContainer, DownloadIcon, FormContainer } from '../css';
import AccountPayable from './step2/AccountPayable';
import { FORM_NAME } from '../constants';
import BillingInformation from './step2/BillingInformation';
import FinalInformation from './step2/FinalInformation';
import {
  H1,
  H2,
  P,
  Span
} from '../../../theme/typographySitecore';
import Button from './common';
import { styleTypes } from '../../ReactComponent/PrimaryButton';
import submitApplicationForm from '../api';
import AccordionPage from '../../ReactComponent/Accordion/AccordionPage';
import Accordion from '../../ReactComponent/Accordion/Accordion';
import { isObjectEmpty } from '../../../utils/object-utils';

const LogicalSectionsData = {
  AccountPayable: {
    name: 'AccountPayableSection',
    order: 0
  },
  BillingInformation: {
    name: 'BillingInformationSection',
    order: 1
  },
  FinalInformation: {
    name: 'FinalInformationSection',
    order: 2
  },
};

const LogicalSections = Object.values(LogicalSectionsData);

let Step2 = ({
  onNext,
  onCancel,
  invalid,
  pristine,
  data,
  errors,
  ...rest
}) => {
  const {
    fields: {
      creditApplicationDescription,
      creditApplicationLink
    }
  } = rest;

  const onSubmit = (e) => {
    submitApplicationForm(data);
    onNext(e);
  };

  const canEditSection = (section) => {
    // editing enabled if previous sections are valid (or first section)
    for (let index = 0; index < section.order; index++) {
      if (!LogicalSections[index].valid) {
        return false;
      }
    }

    return true;
  };

  const sectionValid = (sectionName) => {
    if (!errors || isObjectEmpty(errors)) {
      return true;
    }

    const parent = document.getElementById(sectionName);
    if (!parent) {
      return false;
    }

    const namedElements = parent.querySelectorAll('[name]');
    if (namedElements.length === 0) {
      return true;
    }

    namedElements.forEach((element) => {
      let { name } = element;
      const squareBracketIndex = name.indexOf('[');
      if (squareBracketIndex > -1) {
        name = name.substring(0, squareBracketIndex);
      }

      if (errors[name]) {
        return false;
      }

      return undefined;
    });

    return true;
  };

  LogicalSections.forEach((section) => {
    section.valid = sectionValid(section.name);
  });

  return (
    <FormContainer>
      <form onSubmit={onSubmit}>
        <H1 field={t('CreditApplication')} verticalOffset={40} />
        <P field={creditApplicationDescription} verticalOffset={40} />
        <a
          href={creditApplicationLink.value.href}
          rel="noopener noreferrer"
          target="_blank"
          download
          aria-label={t('CreditApplication')}
        >
          <DownloadIcon />
          <Span field={creditApplicationLink.value.text} />
        </a>
        <H2
          field={t('CreateAccountCreditApplicationForm')}
          verticalOffset={40}
        />
        <Accordion initialPageId={0}>
          <AccordionPage
            title={t('CreateAccountAccountPayable')}
            name={LogicalSectionsData.AccountPayable.name}
            isLocked={!canEditSection(LogicalSectionsData.AccountPayable)}>
            <AccountPayable {...rest} />
          </AccordionPage>
          <AccordionPage
            scrollIntoViewWhenExpanded
            title={t('CreateAccountBillingInformation')}
            name={LogicalSectionsData.BillingInformation.name}
            isLocked={!canEditSection(LogicalSectionsData.BillingInformation)}>
            <BillingInformation {...rest} />
          </AccordionPage>
          <AccordionPage
            scrollIntoViewWhenExpanded
            title={t('CreateAccountFinalInformation')}
            name={LogicalSectionsData.FinalInformation.name}
            isLocked={!canEditSection(LogicalSectionsData.FinalInformation)}>
            <FinalInformation {...rest} />
          </AccordionPage>
        </Accordion>
        <ActionsContainer>
          <Button
            type="button"
            styleType={styleTypes.SECONDARY}
            onClick={onCancel}
          >
            {t('Cancel')}
          </Button>
          <Button type="submit" disabled={invalid || pristine}>
            {t('CreateAccountCreditApplicationSubmit')}
          </Button>
        </ActionsContainer>
      </form>
    </FormContainer>
  );
};

Step2 = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: FORM_NAME
})(Step2);

const selector = formValueSelector(FORM_NAME);

export default (Step2 = connect((state) => {
  const thirdPartyInvoices = selector(state, 'thirdPartyInvoices');
  const data = getFormValues(FORM_NAME)(state);
  const errors = getFormSyncErrors(FORM_NAME)(state);
  return {
    thirdPartyInvoices,
    data,
    errors
  };
})(Step2));
