import React from 'react';
import { Field, change } from 'redux-form';
import { t } from 'i18next';
import Card from '../../ReactComponent/Card';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import RenderField from '../../ReactComponent/RenderField';
import { AddressBookEntry } from '../../ReactComponent/AddressBookEntry';
import {
  isEmail,
  isRequired,
  maxChar50,
  maxChar6
} from '../../../utils/validator';
import {
  PageTitle,
  CategoryTitle,
  DivisionRow,
  PaymentMethod,
  PaymentType,
  AddressBlock,
  CheckBox,
} from '../css';
import { P } from '../../../theme/typography';
import {
  AddressTypes,
  PaymentMethods,
  Divisions,
  shipmentPickupServiceTypes
} from '../../../utils/enums';
import { billingAccountTextFormatter, billingAccountToNumber } from '../../../utils/form';
import { digitOnly } from '../../../utils/mask';
import Tooltip from '../../ReactComponent/Tooltip';
import { addressValidate } from '../index';
import { arrayLengthSafe } from '../../../utils/arrays';
import { routes } from '../../../utils/constants';

const FormName = 'CreatePickupForm';
const renderQuoteSection = (shipNowHeading, shipNowSummary) => (
  <Row>
    <Column $tablet={9} $mobile={12}>
      <CategoryTitle>
        {shipNowHeading}
      </CategoryTitle>
      <P>{shipNowSummary}</P>
      <Row>
        <Column $tablet={4} $mobile={12}>
          <Field
            name="quoteNumber"
            type="text"
            component={RenderField}
            label={t('QuoteNumber')}
            validate={[maxChar6]}
            normalize={digitOnly}
          />
        </Column>
      </Row>
    </Column>
  </Row>
);

const renderMandatoryQuoteSectionAllMethod = (shipNowHeading, shipNowSummaryCreditCard) => (
  <Row>
    <Column $tablet={9} $mobile={12}>
      <CategoryTitle>
        {shipNowHeading}
      </CategoryTitle>
      <P>{shipNowSummaryCreditCard}</P>
      <Row>
        <Column $tablet={4} $mobile={12}>
          <Field
            name="quoteNumber"
            type="text"
            component={RenderField}
            label={t('QuoteNumber')}
            validate={[maxChar6]}
            normalize={digitOnly}
          />
        </Column>
      </Row>
    </Column>
  </Row>
);

const renderMandatoryQuoteSection = (shipNowHeading, shipNowSummaryCreditCard) => (
  <Row>
    <Column $tablet={9} $mobile={12}>
      <CategoryTitle>
        {shipNowHeading}
      </CategoryTitle>
      <P>{shipNowSummaryCreditCard}</P>
      <Row>
        <Column $tablet={4} $mobile={12}>
          <Field
            name="quoteNumber"
            type="text"
            component={RenderField}
            label={t('QuoteNumber')}
            validate={[maxChar6, isRequired]}
            normalize={digitOnly}
            required
          />
        </Column>
      </Row>
    </Column>
  </Row>
);

const renderServicesSection = (
  servicesSectionHeading,
  division,
  changeServiceType
) => (
  <>
    <CategoryTitle>
      {servicesSectionHeading}
    </CategoryTitle>
    <Row>
      <Column $tablet={12} $mobile={12}>
        <Field
          name="serviceType"
          type="checkboxes"
          data={shipmentPickupServiceTypes[division]}
          onChange={changeServiceType}
          component={RenderField}
          className="radio-container"
        />
      </Column>
    </Row>
  </>
);

const changeDivisionToFreight = (dispatch) => {
  dispatch(change('CreatePickupForm', 'division', Divisions.Freight.name));
  dispatch(change('CreatePickupForm', 'pickupDetails.createBillOfLading', true));
  dispatch(change('CreatePickupForm', 'serviceType', 'ShipUSToCanada'));
};

const changeDivisionToSameday = (dispatch) => {
  dispatch(change('CreatePickupForm', 'division', Divisions.Sameday.name));
  dispatch(change('CreatePickupForm', 'pickupDetails.createBillOfLading', false));
  dispatch(change('CreatePickupForm', 'serviceType', 'RequestPickup'));
};

const renderDivisionsSection = (
  dispatchFn,
  selectedDivision,
  freightDivisionHeading,
  freightDivisionSummary,
  freightDivisionBannerImage,
  samedayDivisionHeading,
  samedayDivisionSummary,
  samedayDivisionBannerImage,
) => (
  <>
    <CategoryTitle $noJustifyContent>
      {t('PickService')}
      <Tooltip tip={t('TipPickService')} marginLeft/>
    </CategoryTitle>
    <DivisionRow>
      <Row>
        <Column $tablet={6} $mobile={12}>
          <Card
            title={freightDivisionHeading}
            desc={freightDivisionSummary}
            thumb={freightDivisionBannerImage}
            lgThumb
            link="#"
            linkText={t('LearnMore')}
            selectedButton
            selectedButtonIsSelected={selectedDivision === Divisions.Freight.name}
            selectedButtonClickHandler={() => changeDivisionToFreight(dispatchFn)}
          />
        </Column>
        <Column $tablet={6} $mobile={12}>
          <Card
            title={samedayDivisionHeading}
            desc={samedayDivisionSummary}
            thumb={samedayDivisionBannerImage}
            lgThumb
            link="#"
            linkText={t('LearnMore')}
            selectedButton
            selectedButtonIsSelected={selectedDivision === Divisions.Sameday.name}
            selectedButtonClickHandler={() => changeDivisionToSameday(dispatchFn)}
          />
        </Column>
      </Row>
    </DivisionRow>
  </>
);

const renderBillOfLadingRadio = ({ item }) => (
  <>
    <span className="checkmark"/>
    <P>{t(item ? ('Yes') : ('No'))}</P>
  </>
);

const renderBillOfLadingControl = (billOfLadingSummary, billOfLadingSubSummary, defaultValueObj, division) => (
  <Field
    name="pickupDetails.createBillOfLading"
    component={RenderField}
    type="radio"
    value={defaultValueObj}
    label={billOfLadingSummary}
    subLabel={billOfLadingSubSummary}
    disabled={division === Divisions.Sameday.name}
    renderer={renderBillOfLadingRadio}
    data={[
      true,
      false]}
    defaultValue={defaultValueObj}
  />
);

const renderBillingInformation = (paymentMethod, billingAccounts, loggedIn, hasSingularPrepaidAccount) => {
  switch (paymentMethod) {
    case PaymentMethods.Collect:
      return <>
        <CategoryTitle>{t('BillingInformation')}</CategoryTitle>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <Field
              name="billTo"
              label={t('BillTo')}
              component={RenderField}
              normalize={digitOnly}
              validate={[maxChar6]}
            />
          </Column>
        </Row>
      </>;
    case PaymentMethods.ThirdParty:
      return <>
        <CategoryTitle>{t('BillingInformation')}</CategoryTitle>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <Field
              name="billTo"
              label={t('BillTo')}
              component={RenderField}
              normalize={digitOnly}
              validate={[maxChar6]}
            />
            <AddressBookEntry
              contactType="billing"
              formName="CreatePickupForm"
              isAddressRequired
              emailOptional
            />
          </Column>
          <Column $tablet={6} $mobile={12}>
            <Field
              name="gstNumber"
              label={t('GstNumber')}
              component={RenderField}
            />
          </Column>
        </Row>
      </>;
    case PaymentMethods.Prepaid:
    default:
      return <>
        {loggedIn &&
          <><CategoryTitle>{t('BillingInformation')}</CategoryTitle>
            <Row>
              <Column $tablet={6} $mobile={12}>
                <Field
                  name="billTo"
                  type={hasSingularPrepaidAccount ? 'text' : 'dropdown'}
                  label={t('BillTo')}
                  data={billingAccounts}
                  required
                  textField={billingAccountTextFormatter}
                  valueField="accountNumber"
                  component={RenderField}
                  validate={[isRequired]}
                  normalize={billingAccountToNumber}
                  readOnly={hasSingularPrepaidAccount}
                />
              </Column>
            </Row>
          </>}
      </>;
  }
};

const Step1 = ({
  step,
  billingAccounts,
  formData = {},
  dispatch,
  loggedIn,
  parentFields = {},
  fromContactId,
  toContactId,
  addressFieldsRef,
  openContactPermissionModal
}) => {
  const {
    division,
    pickupDetails,
    paymentMethod,
    // serviceType
  } = formData;

  const hasSingularPrepaidAccount = loggedIn
  && paymentMethod
  && paymentMethod.name === PaymentMethods.Prepaid
  && arrayLengthSafe(billingAccounts) === 1;

  const paymentMethodChanged = (method) => {
    if (!loggedIn && (method.name === 'Collect' || method.name === 'Third Party')) {
      renderMandatoryQuoteSectionAllMethod(parentFields.shipNowHeading.value, parentFields.shipNowSummaryCreditCard.value);
    } else if (!loggedIn && method.name === 'Credit Card') {
      renderMandatoryQuoteSection(parentFields.shipNowHeading.value, parentFields.shipNowSummaryCreditCard.value);
    } else {
      renderQuoteSection(parentFields.shipNowHeading.value, parentFields.shipNowSummary.value);
    }

    dispatch(change(FormName, 'billTo', null));
  };

  const changeServiceType = (newServiceType) => {
    switch (newServiceType) {
      case 'ShipInCanada':
      case 'ShipCanadaToUS':
        window.location.href = `${routes.createShipment}?serviceType=${newServiceType}`;
        break;
      default:
        break;
    }
  };
  if (hasSingularPrepaidAccount) {
    dispatch(change(FormName, 'billTo', billingAccounts[0].accountNumber));
  }

  const getSectionToRender = () => {
    if (!loggedIn && paymentMethod && paymentMethod.name === PaymentMethods.CreditCard) {
      return renderMandatoryQuoteSection(parentFields.shipNowHeading.value, parentFields.shipNowSummaryCreditCard.value);
    }
    if (!loggedIn && (paymentMethod && (paymentMethod.name === PaymentMethods.Collect || paymentMethod.name === PaymentMethods.ThirdParty))) {
      return renderMandatoryQuoteSectionAllMethod(parentFields.shipNowHeading.value, parentFields.shipNowSummaryCreditCard.value);
    }
    return renderQuoteSection(parentFields.shipNowHeading.value, parentFields.shipNowSummary.value);
  };

  if (step === 0) {
    return (
      <>

        <PageTitle>
          {parentFields.pageTitle.value}
        </PageTitle>

        {loggedIn &&
          renderServicesSection(
            parentFields.servicesSectionHeading.value,
            division,
            changeServiceType
          )}

        {getSectionToRender()}

        {!loggedIn &&
          renderDivisionsSection(
            dispatch,
            division,
            parentFields.freightDivisionHeading.value,
            parentFields.freightDivisionSummary.value,
            parentFields.freightDivisionBannerImage.value.src,
            parentFields.samedayDivisionHeading.value,
            parentFields.samedayDivisionSummary.value,
            parentFields.samedayDivisionBannerImage.value.src,
          )}

        {pickupDetails && division !== Divisions.Sameday.name &&
          renderBillOfLadingControl(
            parentFields.billOfLadingSummary.value,
            parentFields.billOfLadingSubSummary.value,
            pickupDetails.createBillOfLading,
            division,
            dispatch
          )}

        <Row ref={addressFieldsRef}>
          <AddressBlock className="col-md-6">
            <CategoryTitle>
              {t('ShipperDetails')}
            </CategoryTitle>
            <AddressBookEntry
              contactType={AddressTypes.SHIPPER}
              formName="CreatePickupForm"
              defaultContactId={fromContactId}
              validator={addressValidate}
              isAddressRequired
              openContactPermissionModal={openContactPermissionModal}
            />
          </AddressBlock>

          {pickupDetails &&
            pickupDetails.createBillOfLading &&
            <AddressBlock className="col-md-6">
              <CategoryTitle>
                {t('ConsigneeDetailsToAddress')}
              </CategoryTitle>
              <AddressBookEntry
                contactType={AddressTypes.CONSIGNEE}
                formName="CreatePickupForm"
                defaultContactId={toContactId}
                validator={addressValidate}
                isAddressRequired
                openContactPermissionModal={openContactPermissionModal}
              />
              <CheckBox>
                <Field
                  name="sendConsigneeInfo"
                  type="singleCheckbox"
                  label={parentFields.consigneeCheckboxSummary.value}
                  component={RenderField}
                />
              </CheckBox>
            </AddressBlock>}

        </Row>
        <PaymentMethod>
          <CategoryTitle>
            {t('PaymentMethod')}
          </CategoryTitle>
          <PaymentType $column={3}>
            <CheckBox>
              <Field
                name="paymentMethod"
                type="checkboxes"
                data={[
                  {
                    name: loggedIn ? PaymentMethods.Prepaid : PaymentMethods.CreditCard,
                    desc: loggedIn ? 'PrepaidDesc' : 'CreditCardDesc'
                  },
                  {
                    name: PaymentMethods.Collect,
                    desc: 'CollectDesc'
                  },
                  {
                    name: PaymentMethods.ThirdParty,
                    desc: 'ThirdPartyDesc'
                  },
                ]}
                component={RenderField}
                validate={[isRequired]}
                onChange={paymentMethodChanged}
              />
            </CheckBox>
          </PaymentType>
          <Row>
            <Column>
              {paymentMethod &&
                <>
                  {renderBillingInformation(paymentMethod.name, billingAccounts, loggedIn, hasSingularPrepaidAccount)}
                </>}
            </Column>
          </Row>
          <Row>
            <Column $tablet={6} $mobile={12}>
              <CategoryTitle $noJustifyContent>
                {parentFields.emailConfirmation && parentFields.emailConfirmation.value}
                <Tooltip tip={t('TipEmailConfirmation')} marginLeft/>
              </CategoryTitle>
              <Field
                name="quoteDest"
                component={RenderField}
                label={t('ContactEmail')}
                validate={[isEmail, maxChar50, isRequired]}
                required
              />
            </Column>
          </Row>
        </PaymentMethod>
      </>
    );
  }
  return null;
};

export default Step1;
