import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import TooltipIcon from './css';
import CustomReactTooltip from './ReactTooltip';

const Tooltip = ({
  tip, marginLeft, shipmentsFlow, userFlow, dedicatedClaimFlow,
}) => {
  useEffect(() => {
    // In some cases, the tooltips will not render if we don't rebuild(). Issue tracked by ECE-1957.
    // For more details, see https://github.com/wwayne/react-tooltip/issues/344...
    ReactTooltip.rebuild();
  });

  return (
    <>
      {/* We can't have ReactTooltip / CustomReactTooltip in Layout to make it global and have the tooltips work without calling rebuild() */}
      {/* For the time being, instantiate ReactTooltip / CustomReactTooltip with every Tooltip and rebuild() as part of useEffect()...  */}
      <CustomReactTooltip/>
      <TooltipIcon
        data-tip={tip}
        $marginLeft={marginLeft}
        $shipmentsFlow={shipmentsFlow}
        userFlow={userFlow}
        dedicatedClaimFlow={dedicatedClaimFlow}
      />
    </>
  );
};

Tooltip.propTypes = {
  tip: PropTypes.string,
  marginLeft: PropTypes.bool,
  shipmentsFlow: PropTypes.bool,
  userFlow: PropTypes.string,
  dedicatedClaimFlow: PropTypes.string
};

export default Tooltip;
