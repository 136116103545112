import React from 'react';
import { RichText, Link } from '@sitecore-jss/sitecore-jss-react';
// import { P } from '../../theme/typography';
import {
  ButtonGroup,
} from './css';

const InstructionsTile = (props) => {
  const { fields } = props;
  const primaryLink = fields['primary link'];
  const hasPrimary = primaryLink && primaryLink.value && primaryLink.value.href;
  const secondaryLink = fields['secondary link'];
  const hasSecondary = secondaryLink && secondaryLink.value && secondaryLink.value.href;

  return (
    <>
      <RichText tag="p" field={fields.instructions}/>
      {(hasPrimary || hasSecondary)
        && <ButtonGroup className="right">
          {hasSecondary
            && <Link field={secondaryLink} className="secondaryButton">
              <span className="icon"></span>
              {secondaryLink.value.text}
            </Link>}
          {hasPrimary
            && <Link field={primaryLink} className="primaryButton submit">
              <span className="icon"></span>
              {primaryLink.value.text}
            </Link>}
        </ButtonGroup>}
    </>
  );
};

export default InstructionsTile;
