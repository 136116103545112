import React from 'react';
import PropTypes from 'prop-types';
import {
  change,
  Field,
  formValueSelector,
  getFormSyncErrors
} from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Container, Header, RadioColumns } from './css';
import ItemList from '../ItemList';
import RenderField from '../../RenderField';
import { isRequired } from '../../../../utils/validator';
import { H2 } from '../../../../theme/typographySitecore';
import Row from '../../Row';
import Column from '../../Column';
import { AddButton, FieldWithUnits } from '../common/MeasurementComponents';
import { equipmentSizes, types } from './constants';
import { LENGTH, WEIGHT } from '../Units/utils';
import { SeparatorLine } from '../common/css';
import ContainerCard from './ContainerCard';
import {
  EditButtonGroup,
  Icon,
  LineButton,
} from '../../MeasurementItemCard/css';
import Delete from '../../../../assets/icons/RemoveOrange.svg';
import Edit from '../../../../assets/icons/Edit.svg';
import { digitOnly } from '../../../../utils/mask';

const MeasurementContainer = ({
  item,
  name,
  listName,
  formName,
  id,
  unitType,
  dispatch,
  completed,
  container,
  containersList,
  equipment,
  type,
  isItem,
  isContainerData,
  disabledEquipmentSize,
  formSyncErrors,
  parentFields,
}) => {
  const addItem = () => dispatch(change(formName, `${item}.completed`, true));
  const editMeasurement = () => dispatch(change(formName, `${item}.completed`, false));

  const deleteMeasurement = () => {
    const newList = [...containersList];
    newList.splice(id, 1);
    if (!newList.length) {
      newList.push({});
    }

    dispatch(change(formName, listName, newList));
  };

  const disableAddContainer = !!(formSyncErrors.containers && formSyncErrors.containers[id]);
  return (
    <Container>
      <Header>
        <H2 field={`${t('Container')} ${id + 1}`}/>
        {completed && type !== types.READ_ONLY && (
          <EditButtonGroup>
            <LineButton onClick={deleteMeasurement}>
              <Icon className="icon" src={Delete} alt="delete"/>
              {t('Delete')}
            </LineButton>
            <LineButton onClick={editMeasurement}>
              <Icon className="icon" src={Edit} alt="edit"/>
              {t('Edit')}
            </LineButton>
          </EditButtonGroup>
        )}
      </Header>
      {completed || type === types.READ_ONLY ? (
        <ContainerCard
          container={container}
          unitType={unitType}
          isContainerData={isContainerData}
        />
      ) : (
        isContainerData && (
          <Row>
            <Column $tablet={5} $mobile={12}>
              <FieldWithUnits
                name={`${item}.volume`}
                validate={[isRequired]}
                label="Volume"
                unitsLabel={LENGTH}
                unitsSup="3"
                required
                unitType={unitType}
                unitScale={1}
                normalize={digitOnly}
              />
              <FieldWithUnits
                name={`${item}.weight`}
                validate={[isRequired]}
                label="Weight"
                unitsLabel={WEIGHT}
                required
                unitType={unitType}
                normalize={digitOnly}
              />
              <FieldWithUnits name={`${item}.origin`} label={t('Origin')}/>
              <FieldWithUnits
                name={`${item}.destination`}
                label={t('Destination')}
              />
            </Column>
            <Column $tablet={7} $mobile={12}>
              <RadioColumns column={2}>
                <Field
                  name={`${item}.equipmentSize`}
                  type="radio"
                  data={equipmentSizes}
                  required={disabledEquipmentSize !== equipmentSizes}
                  component={RenderField}
                  label={t('EquipmentSize')}
                  validate={
                    disabledEquipmentSize !== equipmentSizes ? [isRequired] : []
                  }
                  defaultValue={equipment}
                  disabledList={disabledEquipmentSize}
                />
              </RadioColumns>
            </Column>
          </Row>
        )
      )}
      {isItem && (
        <>
          {isContainerData && <SeparatorLine/>}
          <ItemList
            type={type}
            name={`${item}.${name}`}
            formName={formName}
            parentCompleted={completed}
            parentFields={parentFields}
            containerId={id}
          />
        </>
      )}
      {!completed && type !== types.READ_ONLY && (
        <AddButton
          disabled={disableAddContainer}
          onClick={(e) => {
            e.preventDefault();
            addItem();
          }}
          title={t('AddContainer')}
        />
      )}
    </Container>
  );
};

MeasurementContainer.propTypes = {
  item: PropTypes.string,
  name: PropTypes.string,
  listName: PropTypes.string,
  formName: PropTypes.string,
  id: PropTypes.number,
  unitType: PropTypes.string,
  dispatch: PropTypes.func,
  completed: PropTypes.bool,
  container: PropTypes.object,
  containersList: PropTypes.array,
  equipment: PropTypes.string,
  type: PropTypes.string,
  isItem: PropTypes.bool,
  isContainerData: PropTypes.bool,
  disabledEquipmentSize: PropTypes.string,
  formSyncErrors: PropTypes.object,
  parentFields: PropTypes.object,
};

export default connect((state, props) => ({
  unitType: formValueSelector(props.formName)(state, 'unitType'),
  completed: formValueSelector(props.formName)(
    state,
    `${props.item}.completed`,
  ),
  formSyncErrors: getFormSyncErrors(props.formName)(state),
  equipment: formValueSelector(props.formName)(
    state,
    `${props.item}.equipmentSize`,
  ),
}))(MeasurementContainer);
