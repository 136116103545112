import { UnitsOfMeasure } from '../../../../utils/enums';
import {
  getSizeMeasurementAbbreviationString,
  getWeightMeasurementAbbreviationString,
} from '../../../../utils/func';

export const list = [
  { name: 'BtnImperial', value: UnitsOfMeasure.Imperial },
  { name: 'BtnMetric', value: UnitsOfMeasure.Metric },
];

export const LENGTH = 'lengthUnit';
export const WEIGHT = 'weightUnit';

const unitsByMeasurement = Object.freeze({
  [LENGTH]: getSizeMeasurementAbbreviationString,
  [WEIGHT]: getWeightMeasurementAbbreviationString,
});

export const getMeasurementAbbreviation = (measurement, type, scale = 0) => unitsByMeasurement[measurement](
  type ? type.value : UnitsOfMeasure.Metric,
  scale,
);
