import { Field } from 'redux-form';
import styled from 'styled-components';
import { colors } from '../../theme/style-helpers';

export const FilterContainer = styled.div`
    width: 100%;
    background: ${colors.WHITE};
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 40px;
`;

export const FilterIcon = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    margin-top: -28px;
    cursor: pointer;
`;

export const CategoryTabs = styled.div`
    margin-top: 76px;
    margin-bottom: 76px;
`;

export const CategoryTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.04em;
`;

export const Category = styled.div`
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin: 0 8px;
    min-height: 49px;
    vertical-align: middle;
    border-bottom: ${colors.COOL_GREY} 4px solid;
    cursor: pointer;

    &.active {
        border-color: ${colors.BRAND_ORANGE};
    }
`;

export const PageDescription = styled.div`
    font-size: 18px;
    line-height: 24px;
`;

export const PageTitle = styled.div`
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;
`;

export const ErrorIcon = styled.img`
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    height: 38px;
    display: block;
    width: 100%;
`;

export const ModalTitle = styled.div`
    font-size: 2.5rem;
    padding-top: 34px;
    padding-bottom: 25px;
`;

export const Close = styled.div`
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #333;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
`;

export const DateField = styled(Field)`
    margin-bottom: 10px;
    padding-bottom: 20px;
`;

export const ModalBody = styled.div`
    padding: 50px;
    font-size: 1.5rem;
    text-align: center;
`;

export const ButtonDiv = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 0px;
`;

export const Checkboxes = styled(Field)`
    max-height: 40px;
`;
