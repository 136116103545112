import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';

export const getCompaniesList = (division, userId) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.companies}/${division}/users/${userId}`);
export const getAllCompaniesList = (division) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.companies}/${division}/users`);
export const getAllPermissions = (division) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.permissions}/${division}`);
export const getCompanyUsers = (companyId) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.companies}/${companyId}/users`);
export const createCompany = (data) =>
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.companies}`, data);
export const updateCompany = (data, companyID) =>
  axios.put(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.companies}/${companyID}`, data);
export const deleteCompany = (companyID) =>
  axios.delete(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.companies}/${companyID}`);
export const getCompanyInfo = (companyId) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.companies}/${companyId}`);
export const postImportContacts = (data) =>
  axios.post(`${t('ApiEndPoint')}${t('AddressApiVirtualFolder')}${endpoints.address.bulkImport}`, data);
export const getUserManagementCompaniesList = (division, userid, userId) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.companies}/${division}/users/${userid}/${userId}`);
export const getSearchedCompaniesList = (division, formDatatoAPI) =>
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.companies}/${division}`, formDatatoAPI);
