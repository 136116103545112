import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import CheckMarkSolidOrange from '../../../assets/icons/CheckMarkSolidOrange.svg';
import CheckMarkSolidGray from '../../../assets/icons/CheckMarkSolidGray.svg';
import { getMonthDayYear } from '../../../utils/func';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import {
  AddedItemCard,
  TextProperty,
  Icon,
  BlockTitle,
  H2, OriginResultsLeftContainer, OriginResultsNotesContainer,
} from './css';
import { P } from '../../../theme/typography';
import { P as SitecoreP } from '../../../theme/typographySitecore';
import { Divisions } from '../../../utils/enums';

class TransitTimeItemCard extends PureComponent {
  constructor(props) {
    super(props);
    this.formName = props.formName;
    this.listName = props.listName || 'TransitTimeCalculatorForm'; // <- 'measurementList' is to support old codebase
  }

  render() {
    const {
      transitTimeList,
      fieldValues,
      division,
    } = this.props;

    return (
      <>
        {transitTimeList && transitTimeList.data && transitTimeList.data.map((item, index) => (
          item !== ''
            ? <>
              <div>
                <Row>
                  <Column $desktop={10}>
                    <H2>{t('Origin')}</H2>
                  </Column>
                </Row>
                <Row>
                  <Column $tablet={8}>
                    <OriginResultsLeftContainer>
                      <AddedItemCard key={index}>
                        <Row>
                          <Column $desktop={4}>
                            <TextProperty>
                              <P className="title">{t('Origin')}</P>
                              <P>
                                {item.originCity}
                              </P>
                            </TextProperty>
                          </Column>
                          <Column $desktop={4}>
                            <TextProperty>
                              <P className="title">{t('Terminal')}</P>
                              <P>
                                {item.originTerminalCode}
                              </P>
                            </TextProperty>
                          </Column>
                          <Column $desktop={4}>
                            <TextProperty>
                              <P className="title">{t('PickupDate')}</P>
                              {item.pickupDate && <P>
                                {getMonthDayYear(item.pickupDate)}
                              </P>}
                            </TextProperty>
                          </Column>
                        </Row>
                      </AddedItemCard>
                      <OriginResultsNotesContainer>
                        {division === Divisions.Sameday.name
                          && <>
                            <SitecoreP field={fieldValues.transitTimeResultsOriginNotesHeader}/>
                            <SitecoreP field={fieldValues.transitTimeResultsOriginNotesSameday}/>
                          </>}
                        {division === Divisions.Freight.name
                          && <>
                            <SitecoreP field={fieldValues.transitTimeResultsOriginNotesHeader}/>
                            <SitecoreP field={fieldValues.transitTimeResultsOriginNotesFreight}/>
                          </>}
                      </OriginResultsNotesContainer>
                    </OriginResultsLeftContainer>
                  </Column>
                  <Column $tablet={4}>
                    <AddedItemCard key={index}>
                      <Row>
                        <Column $desktop={12}>
                          <TextProperty>
                            <P className="title itemServiceDays">{t('ServiceDays')}</P>
                            {Object.keys(item.originServiceDays).map((eachDay, i) => (
                              <div key={i}>
                                {item.originServiceDays[eachDay] === true
                                  ? <Icon className="icon" src={CheckMarkSolidOrange} alt="check"/>
                                  : <Icon className="icon" src={CheckMarkSolidGray} alt="uncheck"/>}
                                <P className={item.originServiceDays[eachDay] === true ? 'itemWeekdays' : ''}>{eachDay}</P>
                              </div>
                            ))}
                          </TextProperty>
                        </Column>
                      </Row>
                    </AddedItemCard>
                  </Column>
                </Row>
                <Row>
                  <Column $desktop={12}>
                    <H2>{t('Destination')}</H2>
                    {item.destinations
                      .map((eachDestinationItem, indexPos) => (
                        <>
                          <Row>
                            <Column $desktop={12}>
                              <BlockTitle>
                                {t('Location')}
                                {indexPos ? ` ${Number(index + 1)}` : ` ${indexPos + 1}`}
                              </BlockTitle>
                            </Column>
                          </Row>
                          <Row>
                            <Column $desktop={8}>
                              <Row>
                                <Column $desktop={12}>
                                  <AddedItemCard key={indexPos}>
                                    <Row>
                                      <Column $desktop={6}>
                                        <TextProperty>
                                          <P className="title">{t('Destination')}</P>
                                          <P className="itemDesc">
                                            {eachDestinationItem.city}
                                          </P>
                                        </TextProperty>
                                      </Column>
                                      <Column $desktop={6}>
                                        <TextProperty>
                                          <P className="title">{t('Terminal')}</P>
                                          <P className="itemDesc">
                                            {eachDestinationItem.terminalCode}
                                          </P>
                                        </TextProperty>
                                      </Column>
                                    </Row>
                                  </AddedItemCard>
                                </Column>
                              </Row>
                              <Row>
                                <Column $desktop={12}>
                                  <AddedItemCard key={indexPos}>
                                    {eachDestinationItem.serviceLevels.map((eachService) => (
                                      <Row key={eachService}>
                                        <Column $desktop={4}>
                                          <TextProperty>
                                            <P className="title">{t('Service')}</P>
                                            <P className="itemDesc">
                                              {eachService.serviceLevelCode}
                                            </P>
                                          </TextProperty>
                                        </Column>
                                        <Column $desktop={3}>
                                          <TextProperty>
                                            <P className="title">{t('TransitDays')}</P>
                                            <P className="itemDesc">
                                              {eachService.transitDays}
                                              {eachService.transitDays === 1 ? ' day' : ' days'}
                                            </P>
                                          </TextProperty>
                                        </Column>
                                        <Column $desktop={3}>
                                          <TextProperty>
                                            <P className="title">{t('DeliveryDate')}</P>
                                            <P className="itemDesc">
                                              {item.pickupDate && getMonthDayYear(eachService.deliveryDate)}
                                            </P>
                                          </TextProperty>
                                        </Column>
                                        <Column $desktop={2}>
                                          <TextProperty>
                                            <P className="title">{t('Notes')}</P>
                                            <P className="itemDesc">
                                              {eachService.exception}
                                            </P>
                                          </TextProperty>
                                        </Column>
                                      </Row>
                                    ))}
                                  </AddedItemCard>
                                </Column>
                              </Row>
                            </Column>
                            <Column $desktop={4}>
                              <Row>
                                <Column $desktop={12}>
                                  <AddedItemCard key={indexPos}>
                                    <Row>
                                      <Column $desktop={12}>
                                        <TextProperty>
                                          <P className="title itemServiceDays">{t('ServiceDays')}</P>
                                          {
                                            Object.keys(eachDestinationItem.serviceDays).map((eachDay, i) => (
                                              <div key={i}>
                                                {eachDestinationItem.serviceDays[eachDay] === true
                                                  ? <Icon className="icon" src={CheckMarkSolidOrange} alt="check"/>
                                                  : <Icon className="icon" src={CheckMarkSolidGray} alt="uncheck"/>}
                                                <P className={eachDestinationItem.serviceDays[eachDay] === true
                                                  ? 'itemWeekdays'
                                                  : ''}>
                                                  {eachDay}
                                                </P>
                                              </div>
                                            ))
                                          }
                                        </TextProperty>
                                      </Column>
                                    </Row>
                                  </AddedItemCard>
                                </Column>
                              </Row>
                            </Column>
                          </Row>
                        </>
                      ))}
                  </Column>
                </Row>
              </div>
            </>
            : <>
              <P className="itemServiceDays">{t('NoTransitTimesfound')}</P>
            </>
        ))}
      </>

    );
  }
}

TransitTimeItemCard.propTypes = {
  formName: PropTypes.string,
  listName: PropTypes.string,
  transitTimeList: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  fieldValues: PropTypes.shape({
    transitTimeResultsOriginNotesHeader: PropTypes.string,
    transitTimeResultsOriginNotesSameday: PropTypes.string,
    transitTimeResultsOriginNotesFreight: PropTypes.string,
  }),
  division: PropTypes.string,
};

export default TransitTimeItemCard;
