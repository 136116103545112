import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import AlertModal from '../Modal/AlertModal';
import PrimaryButton, { styleTypes } from '../PrimaryButton';
import CancelButton from './css';

const LeavePageModal = ({
  isOpen,
  onClose,
  onContinue,
}) => {
  const header = () => (
    <>{t('Confirm')}</>
  );

  const content = () => (
    <>{t('AreYouSureYouWantToLeave')}</>
  );

  const actions = () => (
    <>
      <CancelButton
        type="button"
        smallWidth
        styleType={styleTypes.SECONDARY}
        onClick={onClose}>
        {t('Cancel')}
      </CancelButton>

      <PrimaryButton
        type="button"
        smallWidth
        iconRightArrow
        onClick={() => onContinue()}>
        {t('Continue')}
      </PrimaryButton>
    </>
  );

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      content={content}
      actions={actions}
    />
  );
};

LeavePageModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
};

export default LeavePageModal;
