import styled from 'styled-components';
import { MDBDropdownToggle as dropdownToggle, MDBDropdownMenu } from 'mdbreact';

export const MDBDropdownToggle = styled(dropdownToggle)`
  background-color: rgba(255, 255, 255, 0) !important; // Transparent for IE...
`;
export const StyledMDBDropdownMenu = styled(MDBDropdownMenu)`
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    min-width:300px !important;
  }
`;
