import axios from 'axios';
import { PUT_REDUX_CLEAR_SESSION } from '../../reducers/constants';
import utils from '../../utils/utils';
import { endpoints } from '../../utils/constants';
import { homeUrl } from '../../utils/func';
import SESSION_TIMEOUT_QUERY_PARAM_KEY from './constants';

const clearSession = () => ({
  type: PUT_REDUX_CLEAR_SESSION,
});

const finalize = (isSessionTimeout) => {
  sessionStorage.clear();
  if (window && typeof window !== 'undefined') {
    window.location.href = homeUrl() + (isSessionTimeout ? `?${SESSION_TIMEOUT_QUERY_PARAM_KEY}=true` : '');
  }
};

const logOut = (params) => {
  const { isSessionTimeout } = params || { isSessionTimeout: false };
  return (dispatch) => {
    if (!utils.isDisconnected) {
      try {
        axios({
          url: endpoints.sitecore.logout,
          method: 'post',
          data: null,
        })
          .then(() => {
            dispatch(clearSession());
            finalize(isSessionTimeout);
          })
          .catch((res) => {
            console.error('logout error', res);
            finalize(isSessionTimeout);
          });
      } catch (e) {
        console.error('logout error', e);
        finalize(isSessionTimeout);
      }
    }
  };
};
export default logOut;
