import { isIsoDateTime, get12HourTime, getEmpty12HoursTime } from '../../utils/dateTime';

// Define the UserPreferences class
class UserPreferences {
  constructor({
    userPreferenceId,
    userId,
    division,
    readyTime,
    closingTime,
    fromContactId,
    toContactId,
    overrideContactId,
    shipperPostalCode,
    shipperName,
    consigneePostalCode,
    billToAccountNumber,
    isPickupScheduled,
    serviceType
  }) {
    this.userPreferenceId = userPreferenceId;
    this.userId = userId;
    this.division = division;
    this.readyTime = normalizeTime(readyTime);
    this.closingTime = normalizeTime(closingTime);
    this.fromContactId = fromContactId;
    this.toContactId = toContactId;
    this.overrideContactId = overrideContactId;
    this.shipperPostalCode = shipperPostalCode;
    this.shipperName = shipperName;
    this.consigneePostalCode = consigneePostalCode;
    this.billToAccountNumber = billToAccountNumber;
    this.isPickupScheduled = isPickupScheduled;
    this.serviceType = serviceType;
  }
}

function normalizeTime(time) {
  if (!time) {
    return getEmpty12HoursTime().toTimeString();
  }

  if (isIsoDateTime(time)) {
    return get12HourTime(time).toTimeString();
  }

  return time;
}

export default UserPreferences;
