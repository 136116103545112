import {
  PUT_REDUX_AUTH_CONTENT,
  PUT_REDUX_GENERAL_SETTINGS,
  PUT_REDUX_CLEAR_SESSION,
  PUT_REDUX_DEFAULT_SESSION,
  PUT_CURRENT_LANGUAGE
} from './constants';
import { Divisions } from '../utils/enums';
import utils from '../utils/utils';

const CurrentLanguage = 'EN';
const nonauthState = {
  divisionCode: '',
  division: '',
  accountNumbers: [],
  userId: '',
  email: '',
  generalSettings: {
    isSet: false,
    service_apiKey: ''
  },
  isAuthenticated: false,
  billingAccounts: [],
  isForceChangePassword: false,
};

const authState = {
  divisionCode: '3',
  division: Divisions.Freight.name,
  currentLanguage: CurrentLanguage,
  accountNumbers: ['000071',
    '002997',
    '010420',
    '019283',
    '050448',
    '066747',
    '067442',
    '999999',
    '106671',
    '108967',
    '134186',
    '135024',
    '141675'],
  userId: '882',
  email: 'dayrossgroup@gmail.com',
  generalSettings: {
    isSet: false,
    // eslint-disable-next-line no-undef
    service_apiKey: process.env.REACT_APP_SERVER_API_KEY
  },
  isAuthenticated: true,
  billingAccounts: [
    {
      id: null,
      company: 'LEVY STEERING CENTRE',
      accountNumber: '009166',
      division: 2,
      protected: false
    },
    {
      id: null,
      company: 'Protected Account Test',
      accountNumber: '146367',
      division: 2,
      protected: false
    }, {
      id: null,
      company: 'CENTRAL FREIGHT SERVICES',
      accountNumber: '010420',
      division: 2,
      protected: false
    }, {
      id: null,
      company: 'MDG COMPUTERS CANADA INC',
      accountNumber: '038105',
      division: 2,
      protected: false
    }, {
      id: null,
      company: 'EDITIONS NOVALIS INC',
      accountNumber: '080031',
      division: 2,
      protected: false
    }, {
      id: null,
      company: 'COSTCO WHOLESALE',
      accountNumber: '088322',
      division: 2,
      protected: false
    },
    {
      id: null,
      company: 'GENERAL WEBSITE QUOTE',
      accountNumber: '090414',
      division: 2,
      protected: false
    },
    {
      id: null,
      company: 'WAL-MART(DOREL DHP)',
      accountNumber: '105902',
      division: 2,
      protected: false
    },
    {
      id: null,
      company: 'A1 FREIGHT FORWARDING INC',
      accountNumber: '108749',
      division: 2,
      protected: false
    },
    {
      id: null,
      company: 'LOWE\'S CANADA',
      accountNumber: '109298',
      division: 2,
      protected: false
    },
    {
      id: null,
      company: 'UPS',
      accountNumber: '113085',
      division: 2,
      protected: false
    },
    {
      id: null,
      company: 'DOLLARAMA',
      accountNumber: '114031',
      division: 2,
      protected: false
    },
    {
      id: null,
      company: 'BESTSELLER CANADA INC',
      accountNumber: '114438',
      division: 2,
      protected: false
    },
    {
      id: null,
      company: 'GILLES BASQUE SALES LTD',
      accountNumber: '135036',
      division: 2,
      protected: false
    }
  ]
};

const initialState = utils.isDisconnected ? authState : nonauthState;

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_REDUX_AUTH_CONTENT:
      return {
        ...state,
        ...action.authContent
      };
    case PUT_REDUX_GENERAL_SETTINGS:
      return {
        ...state,
        generalSettings: {
          isSet: true,
          service_apiKey: action.generalSettings.service_apiKey
        }
      };
    case PUT_REDUX_CLEAR_SESSION:
      return {
        ...state,
        ...nonauthState
      };
    case PUT_REDUX_DEFAULT_SESSION:
      return {
        ...state,
        ...authState
      };
    case PUT_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.currentLanguage
      };
    default:
      return state;
  }
};

export default profileReducer;
