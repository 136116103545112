import React from 'react';
import { Field } from 'redux-form';
import { t } from 'i18next';
import AddressComplete from '../../ReactComponent/AddressComplete';
import RenderField from '../../ReactComponent/RenderField';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import {
  isRequired, isPhone, isEmail, maxChar50, maxChar6,
} from '../../../utils/validator';
import { phoneNumber, digitOnly } from '../../../utils/mask';
import {
  PageTitle,
  TextProperty,
} from '../css';
import { P } from '../../../theme/typography';
import { AddressTypes } from '../../../utils/enums';
// import { CheckboxFieldTooltipContainer, FormFieldTooltipContainer } from '../../ReactComponent/Tooltip/helpers';
import Tooltip from '../../ReactComponent/Tooltip';

const ContactForm = ({
  formData = {},
  formName,
  companiesList,
  parentFields,
}) => {
  const {
    rowVersion,
    nickname,
    // address
  } = formData;
  const update = !!rowVersion;
  return (
    <>
      <PageTitle className="justified">
        {parentFields.pageHeading && parentFields.pageHeading.value}
      </PageTitle>
      <Row>
        <Column $tablet={6} $mobile={12}>
          {!update && <Row style={{ marginTop: '-3px' }}>
            <Column>
              <Field
                name="companyId"
                type="dropdown"
                label={t('SelectCompany')}
                data={companiesList.map((c) => c.companyName)}
                required
                component={RenderField}
                validate={[isRequired]}
              />
            </Column>
          </Row>}
          {update
            ? <>
              <TextProperty>
                <P className="title">{t('Nickname')}</P>
                <P className="text">{nickname.name}</P>
              </TextProperty>
            </>
            : <Row>
              <Column>
                <Field
                  name="nickname"
                  type="text"
                  required
                  component={RenderField}
                  label={t('Nickname')}
                  validate={[isRequired]}
                />
              </Column>
              <Tooltip tip={t('TipNickname')}/>
            </Row>}
          <Row>
            <Column>
              <AddressComplete
                fieldName="address"
                formName={formName}
              />
            </Column>
            <Tooltip tip={t('TipAddressDetails')}/>
          </Row>
          {/* <Row>
              <Column>
              <TextProperty>
                <P className="title">{ parentFields['paragraphTitle']&& parentFields["paragraphTitle"].value }</P>
                <P className="text">{ parentFields['paragraphText']&& parentFields["paragraphText"].value }</P>
              </TextProperty>
              </Column>
            </Row> */}
          <Row className="justify-content-md-center">
            <Column $tablet={8} $mobile={12}>
              <Field
                name="phoneNumber"
                type="text"
                component={RenderField}
                label={t('PhoneNumber')}
                validate={[isPhone, maxChar50, isRequired]}
                normalize={phoneNumber}
                required
              />
            </Column>
            <Column $tablet={4} $mobile={12}>
              <Field
                name="phoneNumberExt"
                type="text"
                component={RenderField}
                label={t('Extension')}
                validate={[maxChar6]}
                normalize={digitOnly}
              />
            </Column>
          </Row>
        </Column>
        <Column $tablet={6} $mobile={12}>
          <Row>
            <Column>
              <Field
                name="contactName"
                type="text"
                required
                component={RenderField}
                label={t('FullName')}
                validate={[isRequired]}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Field
                name="companyName"
                type="text"
                required
                component={RenderField}
                label={t('ContactCompanyName')}
                validate={[isRequired]}
              />
            </Column>
            <Tooltip tip={t('TipCompanyName')}/>
          </Row>
          <Row>
            <Column>
              <Field
                name="email"
                type="email"
                component={RenderField}
                label={t('Email')}
                validate={[isEmail, maxChar50, isRequired]}
                required
              />
            </Column>
          </Row>
          {!update
            && <Row>
              <Column>
                <Field
                  name="contactType"
                  type="checkboxes"
                  label={t('lblContactType')}
                  data={Object.values(AddressTypes)}
                  required
                  component={RenderField}
                  validate={[isRequired]}
                />
              </Column>
              <Tooltip tip={t('TipContactType')}/>
            </Row>}
        </Column>
      </Row>
    </>
  );
};

export default ContactForm;
