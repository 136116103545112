import React from 'react';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { H2 } from '../../../theme/typography';
import {
  TutorialsTile,
  InlineBlock,
} from '../css';

const Tutorials = ({ $fields }) => {
  const { viewMoreLink, image } = $fields;
  const backgroundImageSrc = image && image.value && image.value.src ? image.value.src : null;
  const hasViewMoreLink = viewMoreLink && viewMoreLink.value && viewMoreLink.value.href && viewMoreLink.value.text;

  return (<TutorialsTile $backgroundImageSrc={backgroundImageSrc}>
        <H2>
            <Text field={$fields.heading}/>
        </H2>
        <div className="content-text"><Text field={$fields.contentText}/></div>
        {hasViewMoreLink &&
            <InlineBlock className="view-more">
                <Link field={viewMoreLink} className="primaryButton submit">
                    <span className="icon"></span>{viewMoreLink.value.text}
                </Link>
            </InlineBlock>}
    </TutorialsTile>);
};

export default Tutorials;
