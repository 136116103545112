import styled from 'styled-components';

export const SectionTitle = styled.div`
    margin-bottom: 26px;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
`;

export const Section = styled.div`
`;

