import styled, { css } from 'styled-components';
import { colors } from '../../../../theme/style-helpers';

export const Selector = styled.div`
  display: flex;

  > div {
    display: inline-block;
    margin: 24px 0;
    background-color: #F9F9F9;
    border-radius: 24px;
    height: 48px;
  }

  .rw-list-option {
    .rw-select-list-label {
      padding: 12px 18px;
      border: 1px solid transparent;
      border-radius: 24px;
      background-color: transparent;
      margin: 0;
      cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
      transition: border 0.3s, background-color 0.1s;

      .rw-select-list-input {
        display: none;
      }
      .checkmark {
        display: none;
      }
    }

    &[aria-checked="true"] {
      .rw-select-list-label {
        background-color: ${colors.WHITE};
        border: solid 1px ${colors.BRAND_ORANGE};
      }
    }

    &[aria-checked="false"] {
      .rw-select-list-label {
        ${({ disabled }) => disabled && css`opacity: 0.3`};
      }
    }
  }

  div, ul, h3 {
    margin: 0;
  }
`;

export const UnitSelectorHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
  flex-wrap: wrap;
`;
