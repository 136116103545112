import React from 'react';
import {
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { P, H1 } from '../../theme/typographySitecore';

import CenteredMessage from './css';

const ErrorAccessDenied = (props) => {
  const {
    fields,
    sitecoreContext: { profileData }
  } = props;

  const isauth = profileData && profileData.basic_info ? profileData.basic_info.isAuthenticated : false;

  return <>
    {fields.heading && fields.heading.value && <H1 field={fields.heading}/>}
    <CenteredMessage>
      {isauth && <P field={fields.userAuthenticatedMessage}/>}
      {!isauth && <P field={fields.userNotAuthenticatedMessage}/>}
    </CenteredMessage>
  </>;
};

export default withSitecoreContext()(ErrorAccessDenied);

