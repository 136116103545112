import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';
import { getCurrentLanguage } from '../../utils/func';

export const getShipment = (division) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipments}/${division}`);

export const getShipmentDrafts = (division, userId, numberOfDaysFromToday) =>
  axios.get(
    `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.draftShipments}/${division}/${userId}`,
    {
      params: {
        numberOfDaysFromToday,
      }
    }
  );

export const getShipmentByDivisionAndProBill = (division, proBillNumber) => axios.get(
  `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipments}/${division}/${proBillNumber}`
);

export const getShipmentByDivisionAndShipmentId = (shipmentId) => axios.get(
  `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.draftShipments}/${shipmentId}`
);

export const getShipmentBillOfLanding = (division, proBillNumber) => axios.get(
  `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipmentDocuments}/${division}/${proBillNumber}/bol`
);

export const postShipment = (data) =>
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipments}`, data);

export const postShipmentDraft = (data) =>
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.draftShipments}`, data);

export const apiGetShipments = (division, accountNumbers, fromDate, toDate, historical) =>
  axios.get(
    `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipments}/${division}${historical ? '/historical' : '/recent'}`, {
      params: {
        fromDate,
        toDate
      },
      headers: {
        accountNumbers
      }
    });

export const apiGetShipmentReports = (division, accountNumbers, fromDate, toDate) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipments}/${division}/export`, {
    params: {
      fromDate,
      toDate
    },
    headers: {
      accountNumbers
    }
  });

const shipmentDocumentEndpoint = (division, proBillNumber, descriptor) =>
  // eslint-disable-next-line max-len
  `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipmentDocuments}/${division}/${proBillNumber}/${descriptor.documentId}?type=${descriptor.type}&folderId=${descriptor.folderId}&numOfPages=${descriptor.numberOfPages}`;

export const getShipmentDocument = (division, proBillNumber, descriptor) => axios.get(
  shipmentDocumentEndpoint(division, proBillNumber, descriptor)
);

export const getShipmentDocuments = (division, proBillNumber, accountNumbers) => axios.get(
  `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipmentDocuments}/${division}/${proBillNumber}/`,
  {
    headers: {
      accountNumbers,
    },
  }
);

export const getShipmentTracking = (updateState, index, division, probillNumber, updateError) => {
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipments}/${division}/${probillNumber}/tracking`, {
    params: {
      language: getCurrentLanguage(),
    },
  }).then((res) => {
    if (res.status === 200 || res.status === 404) {
      updateState(index, res.data);
    } else {
      updateError(index, res);
    }
  }).catch((error) => {
    updateError(index, error);
  });
};

export const getShipmentTrackingDetailed = ({
  division,
  probillNumber,
  accountNumbers = []
}) => axios.get(
  `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipments}/${division}/${probillNumber}/tracking-detailed`,
  {
    params: {
      language: getCurrentLanguage(),
    },
    headers: {
      accountNumbers,
    },
  }
);

