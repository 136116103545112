import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';
import ArrowRightBlack from '../../assets/icons/ArrowRightBlack.svg';

export const LeftColumn = styled.div`
    ${MediaQuery.medium`
        vertical-align: top;
        flex: 0 0 50%;

        &.flex-60{
            flex-basis: 60%;
        }

        &.flex-40{
            flex-basis: 40%;
        }

        padding-right: 30px;
    `}
`;

export const RightColumn = styled.div`
    ${MediaQuery.medium`
        flex: 0 0 50%;

        &.flex-60{
            flex-basis: 60%;
        }

        &.flex-40{
            flex-basis: 40%;
        }

        padding-left: 30px;
    `}
`;

export const SectionTitle = styled.div`
    margin-bottom: 26px;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
`;

export const Section = styled.div`
    ${MediaQuery.medium`
        display: flex;
        flex-direction: row;
        align-items: stretch;
    `}
`;

export const ButtonGroup = styled.div`
    margin-top: 32px;

    &.right {
        text-align: right;
    }

    .primaryButton {
        display: inline-block;
        
        background-color: ${colors.DARK_GREY};
        font-style: normal;
        position: relative;
        font-weight: 500;
        font-size: 1.4rem;
        text-align: center;
        color: ${colors.COOL_GREY};
        padding: 16px 46px;
        border: 1px solid ${colors.DARK_GREY};
        border-radius: 3px;
        margin-left: 0;
        margin-bottom: 10px;
        width: 100%;
        cursor: pointer;
        order: 0;

        &.min-width {
            min-width: 300px;
        }

        ${MediaQuery.medium`
            margin-left: 16px;
            margin-bottom: 0;
            order: 1;
            width: initial;
        `}

        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
            margin: 0;
            height: 24px;
            width: 24px;
            background: url(${ArrowRightWhite});
            background-size: cover;
        }

        &.submit, &.active {
            background-color: ${colors.BLACK};

            .icon {
                background: url(${ArrowRightOrange});
            }

            &:hover {
                background-color: ${colors.BRAND_ORANGE};
                color: ${colors.BLACK};
                border-color: ${colors.BRAND_ORANGE};
                text-decoration: none;

                .icon {
                    background: url(${ArrowRightBlack});
                }
            }
        }
    }

    .secondaryButton {
        display: inline-block;
        background-color: ${colors.WHITE};
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        text-align: center;
        color: ${colors.BLACK};
        padding: 16px 46px;
        border: 1px solid ${colors.BLACK};
        border-radius: 3px;
        margin-left: 0;
        margin-bottom: 10px;
        width: 100%;
        order: 1;
        cursor: pointer;

        &.min-width {
            min-width: 300px;
        }

        ${MediaQuery.medium`
            width: initial;
            margin: 0;
            order: 0;
        `}

        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
            margin: 0;
            height: 24px;
            width: 24px;
            background: url(${ArrowRightOrange});
            background-size: cover;
        }

        &.no-border {
            border: none;
        }

        &:hover {
            border-color: ${colors.BRAND_ORANGE};
            text-decoration: none;
            color: ${colors.BLACK};
        }

        &.hidden {
            display: none;
        }
    }
}
`;
