/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { MDBDropdownItem } from 'mdbreact';
import { Th } from 'react-super-responsive-table';
import { Icon as CommonIcon } from '../Icon';
import { colors, MediaQuery } from '../../../theme/style-helpers';
import ArrowFirstOrange from '../../../assets/icons/ArrowFirstOrange.svg';
import ArrowLeftOrange from '../../../assets/icons/ArrowLeftOrange.svg';
import ArrowRightOrange from '../../../assets/icons/ArrowRightOrange.svg';
import ArrowLastOrange from '../../../assets/icons/ArrowLastOrange.svg';
import ArrowDown from '../../../assets/icons/ArrowDown.svg';
import { P } from '../../../theme/typographySitecore';

export const DropdownItem = styled(MDBDropdownItem)`
  display: flex !important;
  align-items: center !important;
  
  &:enabled {
    color: ${colors.WHITE} !important;
  }
  
  &.disabled {
    color: ${colors.DARK_GREY} !important;
  }
  
  &:hover {
    background-color: ${colors.BRAND_ORANGE} !important;
  }
`;

export const DropdownItemIcon = styled(CommonIcon)`
    margin: 0;
    margin-right: 8px;
`;

export const TableContainer = styled.div`
    font-size: 1.4rem;

    table {
        width: 100%;

        td {
            border: 0;
        }

        thead {
            th {
                font-size: 1.6rem;
                min-width: 80px;
                span {
                    cursor: pointer;
                }

            }
            .rw-select-list-label .checkmark {
                left: 10px !important;
            }
        }

        tbody {
            td {
                padding: .5rem 1rem;

                ${MediaQuery.large`
                    padding: 1rem;
                `}
            }
            tr {
                &:nth-child(even) {
                    background: #F9F9F9;
                }
            }
        }
    }

    .dropdown {
        .btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0;
            padding: 15px 20px;
            font-size: 1.4rem;
            color: ${colors.BLACK} !important;
            background-color: ${colors.WHITE + colors.OPACITY_00_ALPHA_HEX_SUFFIX} !important;
            border-color: ${colors.BLACK};

            &:hover {
                background-color: ${colors.WHITE};
                color: ${colors.BLACK};
            }
        }

        .dropdown-menu {
            top: 0;
            left: 0;
            right: 0;
            width: max-content;
            margin-top: 0;
            border-color: ${colors.BLACK};
            border-radius: 0;
            font-size: 1.4rem;

            .dropdown-item {
                white-space: normal;
                margin: 10px 0;
            }
        }
    }

    .no-word-break {
        display: flex;
        align-items: center;
    }
`;

export const Icon = styled.img`
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    width: 36px;
    height: 36px;

    &.active {
        transform: rotate(180deg);
        transition: all .3s;
    }
`;
export const TableHeader = styled(Th)`
    border-bottom: 1px solid ${colors.BLACK};
`;

export const PaginationContainer = styled.div`
    width: 100%;
    margin: 45px 0;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            padding: 1rem 1.5rem;
            font-size: 1.4rem;
            cursor: pointer;
            display: flex;
            align-items: center;

            .firstArrow {
                height: 24px;
                width: 24px;
                background: url(${ArrowFirstOrange});
                margin-right: 8px;
            }

            .prevArrow {
                height: 24px;
                width: 24px;
                background: url(${ArrowLeftOrange});
                margin-right: 8px;
            }

            .nextArrow {
                height: 24px;
                width: 24px;
                background: url(${ArrowRightOrange});
                margin-left: 8px;
            }

            .lastArrow {
                height: 24px;
                width: 24px;
                background: url(${ArrowLastOrange});
                margin-left: 8px;
            }

            &.active {
                font-weight: 600;
                text-decoration: underline;
            }
        }
    }
`;

export const ResponsiveTable = styled.div`
    width: 100%;

    @media (max-width: 767px) {
        tbody {
            tr {
                margin-bottom: 3em;
                border: 1px solid #D9D9D6;
                padding: 0;

                td.pivoted {
                    margin-bottom: 1em;
                    height: 5rem;
                    display: flex;
                    align-items: center;
                    padding-left: calc(50% - 5rem) !important;

                    .tdBefore {
                        width: auto;
                        min-width: 10em;
                        display: flex;
                        border-right: 0.25rem solid #D9D9D6;

                        img {
                            display: none;
                        }
                    }

                    .no-word-break {
                        min-height: 3em;
                    }

                    :first-child {
                        height: 0;
                        padding: 0;
                        .tdBefore {
                            display: none;
                        }

                        .dropdown {
                            background: lightblue;
                            width: fit-content;
                            position: absolute;
                            top: 2em;
                            right: 1em;

                            button {
                                padding: 0;
                                width: 1em;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const FilterText = styled.label`
    margin-bottom: 2rem;
    margin-right: 3rem;
    font-size: 1.4rem;
    line-height: 2.1rem;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    user-select: none;

    .checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            background-color: ${colors.BRAND_ORANGE};

            &:after {
                display: block;
                left: 5px;
                top: 1px;
                width: 7px;
                height: 11px;
                border: solid ${colors.WHITE};
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 21px;
        width: 21px;
        border: 1.5px solid ${colors.BLACK};
        background-color: ${colors.WHITE};
        border-radius: 2px;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }
`;

export const FilterLabel = styled.p`
    font-weight: 600;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    line-height: 2.1rem;
`;

export const PrimaryButton = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 16px 56px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    order: 0;

    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        margin-bottom: 0;
        order: 1;
    `}

    &.submit, &.active {
        background-color: ${colors.BLACK};

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};
        }
    }
`;

export const SecondaryButton = styled.button`
    background-color: ${colors.WHITE};
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    padding: 16px 56px;
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    margin-left: 0;
    width: 100%;
    order: 1;
    cursor: pointer;

    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        order: 0;
    `}

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
`;

export const TableHeaderLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 8px;
`;

export const TableHeaderLabel = styled(P)`
    margin: 0 !important;
`;

export const ArrowDownIcon = styled.img.attrs(() => ({ src: ArrowDown }))`
    max-width: initial;
`;
