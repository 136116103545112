// based on https://github.com/the-darc/string-mask
import StringMask from 'string-mask';

export const pattern = {
  digitOnly: /[^\d]/g,
  numberAndDecimals: /[^0-9.]/g,
  multipleDots: /(\..*){2,}/g,
  threeDecimalPlaces: /\.([0-9]){3,}/g,
  currencyOnly: /^[0.]|(\.{2,})|[^0-9.]|(\.(\d{3,}))/g,
  whitespace: /\s/g,
  letterAndSpace: /[^a-zA-Z\s-]/g,
  multipleSpace: /\s\s+/g,
  amex: /^3[47][0-9]{13}$/,
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  masterCard: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
};

const mask = (value, format) => {
  if (!value) {
    return value;
  }

  return StringMask.apply(value.replace(pattern.multipleSpace, ' '), format);
};

export const phoneNumber = (value) => {
  const input = value.replace(pattern.digitOnly, '').substring(0, 11);
  if (input.length <= 3) {
    return mask(input, '000');
  }

  if (input.length <= 6) {
    return mask(input, '000-000');
  }

  return mask(input, '000-000-0000');
};

export const dateMask = (value) => {
  const input = value.replace(pattern.digitOnly, '').substring(0, 10);
  if (input.length <= 4) {
    return mask(input, '0000');
  }

  if (input.length <= 6) {
    return mask(input, '0000-00');
  }

  return mask(input, '0000-00-00');
};

export const twoDigits = (value) => {
  const leadingZeroOrDigitInField = value.length > 2;
  const input = value.replace(pattern.digitOnly, '')
    // fill in input from the right, if already 2 digits, take the second
    // and third spots of value as input, otherwise take first and second spots
    .substring(leadingZeroOrDigitInField ? 1 : 0, leadingZeroOrDigitInField ? 3 : 2);
  // fill in leading 0 if initial input, otherwise mask with 2 digits
  return mask(input, `${input.length === 1 ? '$0' : '0'}0`);
};

export const shortPhoneNumber = (value) => {
  const input = value.replace(pattern.digitOnly, '');
  if (input.length <= 3) {
    return mask(input, '000');
  }

  return mask(input, '000-0000');
};

export const postalCode = (value) => {
  const input = value.replace(pattern.whitespace, '');
  return mask(input, 'U0U 0U0');
};

export const digitOnly = (value) => value && value.replace(pattern.digitOnly, '');
export const numbersAndTwoDecimals = (value) => {
  if (value) {
    let temp = value.replace(pattern.numberAndDecimals, '');
    temp = temp.match(pattern.multipleDots) ? temp.slice(0, -1) : temp;
    temp = temp.match(pattern.threeDecimalPlaces) ? temp.slice(0, -1) : temp;
    return temp;
  }

  return null;
};

export const currencyOnly = (value) => value && value.replace(pattern.currencyOnly, '');

export const letterAndSpace = (value) => value && value.replace(pattern.letterAndSpace, '');
