import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';

export const getInvoicesByDivision = (
  division,
  accountNumbers,
  fromDate,
  toDate,
  historical
) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.invoices}/${division}${historical ? '/historical' : ''}`,
    {
      headers: {
        accountNumbers
      },
      params: {
        fromDate,
        toDate
      }
    });

export const getInvoiceDocument = (division, invoiceNumber, invoiceType, historical, language) =>
  axios.get(
    // eslint-disable-next-line max-len
    `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.invoices}/${historical ? `${division}/${invoiceNumber}/document-historical` : `${invoiceNumber}/document`}`,
    {
      params: {
        invoiceType,
        language
      }
    }
  );

export const getInvoiceReports = (
  division,
  accountNumbers,
  fromDate,
  toDate,
  language,
  historical
) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.invoices}/${division}${historical ? '/historical' : ''}/export`,
    {
      headers: {
        accountNumbers,
      },
      params: {
        fromDate,
        toDate,
        language
      }
    });
