import styled, { css } from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';

import PrimButton from '../ReactComponent/PrimaryButton';
import SecondaryButton from '../ReactComponent/SecondaryButton';

export const CommonPrimaryButton = styled(PrimButton)`
    display: inline-block;
    ${MediaQuery.medium`
    width: auto;
    margin:0;
    padding:0 25px;
  `}
`;

export const CommonSecondaryButton = styled(SecondaryButton)`
    border: none;
    width: auto;
    margin: 0;
    padding: 0;
    display: inline-block;
`;

export const NewsPage = styled.div`
    width: 100%;
    text-align: center;
`;

export const NewsTitle = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 59px;
    width: 296px;

    ${MediaQuery.small`
    padding: 16px;
    width: 100%;      
  `}
    display: inline-block;
    text-align: left;
    margin-bottom: 36px;
`;

export const SearchContainer = styled.div`
    padding: 0;
    width: 296px;
    ${MediaQuery.small`
        padding: 16px;
        width: 100%;      
    `}
    display: inline-block;

    .reset, .sort {
        width: 50%;
        display: inline-block;
    }

    .sort {
        text-align: right;
    }

    .summary {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.04em;
        margin: 20px 0;

        .keyword {
            color: ${colors.BRAND_ORANGE}
        }
    }

    > form {
        margin: 20px 0;

        > div:first-of-type {
            margin: 24px 0 34px;
        }
    }
`;

export const CardListContainer = styled.div`
    max-width: 830px;
    width: 100%;
    display: inline-block;
    overflow: auto;
    text-align: center;
    ${MediaQuery.small`
      text-align:left;
  `}

`;

export const ReadMoreLine = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

`;

export const CardContainer = styled.div`

    width: 100%;

    ${MediaQuery.medium`
        width: 28%;
        margin-right: 16px;
        margin-left: 16px;
    `}

    ${MediaQuery.large`
        width: 29%;
    `}


    position: relative;
    display: inline-block;

    margin-bottom: 36px;
    text-align: left;

    vertical-align: top;
`;

export const CardImageContainer = styled.div`
    height: 184px;
    overflow: hidden;
    margin-bottom: 16px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${colors.DARK_GREY};
`;

export const CardImageItem = styled(CardImageContainer)`
    ${({ backgroundImageSrc }) => (backgroundImageSrc
    ? css`
                background-image: url('${backgroundImageSrc}') !important;
            `
    : null)
}`;

export const NewsHeader = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    height: 34px;
    text-overflow: ellipsis;
    margin-bottom: 16px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
`;

export const DateLine = styled.div`
    font-family: Montserrat;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    display: inline-block;
    height: 15px;
    color: ${colors.DARK_GREY};
`;

export const CenteredMessage = styled.div`
    width: 100%;
    height: 300px;
    text-align: center;
    vertical-align: middle;
    padding-top: 120px;

    &.text-align-left {
        text-align: left;
    }

    &.padding-40 {
        padding-top: 40px;
    }

    &.padding-70 {
        padding-top: 70px;
    }

    .icon-100 {
        width: 100px;
        height: 100px;
    }

    .heading-message {
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
    }

    .bold-message {
        margin-top: 40px;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
    }
`;

export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;

export const NewsDescription = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: 5px;
    height: 65px;
    color: ${colors.DARK_GREY};
`;
