import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import {
  Button,
  CloseIcon,
  ContentContainer,
  FreightButton,
  HeaderContainer,
  HeaderSubText,
  HeaderText,
  WindowContainer
} from './css';
import samedaySalesForceChat from './samedaySalesForceChat';
import freightSalesForceChat from './freightSalesForceChat';
import { CLOSE_CHAT_WINDOW } from './actions';

const ChatWindow = (props) => {
  const { chatWindow, closeChatWindow } = props;

  if (!chatWindow.isOpen) {
    return null;
  }

  return (
    <WindowContainer>
      <HeaderContainer>
        <HeaderText field={t('ChatWithUs')}/>
        <HeaderSubText field={t('ChatHours')}/>
        <CloseIcon onClick={() => closeChatWindow()}/>
      </HeaderContainer>
      <ContentContainer>
        <FreightButton onClick={() => { freightSalesForceChat().showChatWindow(); closeChatWindow(); }}>
          {t('LtlTruckload')}
        </FreightButton>
        <Button onClick={() => { samedaySalesForceChat().showChatWindow(); closeChatWindow(); }}>
          {t('CommerceSolutions')}
        </Button>
      </ContentContainer>
    </WindowContainer>
  );
};

ChatWindow.propTypes = {
  chatWindow: PropTypes.object,
  closeChatWindow: PropTypes.func
};

const mapStateToProps = (state) => ({
  chatWindow: state.chatWindow,
});

const mapDispatchToProps = (dispatch) => ({
  closeChatWindow: () => dispatch({ type: CLOSE_CHAT_WINDOW }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatWindow);
