import React from 'react';
import {
  PageTitle
} from '../css';
import SummaryForm from './SummaryForm';

const Step5 = ({
  formData = {},
  step,
  editStepOne,
  editStepTwo,
  editStepThree,
  editStepFour,
  parentFields,
}) => {
  if (step === 4) {
    return (
      <>
        <PageTitle>{parentFields.finalizeAndPayHeading && parentFields.finalizeAndPayHeading.value}</PageTitle>
        <SummaryForm
          formData={formData}
          editStepOne={editStepOne}
          editStepTwo={editStepTwo}
          editStepThree={editStepThree}
          editStepFour={editStepFour}
          parentFields={parentFields}
        />
      </>
    );
  }

  return null;
};

export default Step5;
