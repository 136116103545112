import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../utils/constants';

export const getAccessorialByDivision = (division, filterForPickup) => {
  const queryParams = filterForPickup != null ? `?filterForPickup=${filterForPickup}` : '';

  return axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.accessorials}/${division}${queryParams}`);
};

export const deleteAccessorialByDivision = (division) =>
  axios.delete(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.accessorials}/${division}/cache`);

export const getAccessorialCustomerByDivision = (division) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.accessorials}/${division}/customer`);
