/* eslint-disable */
import React, { Fragment } from 'react';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { reduxForm, Field, change, getFormValues } from 'redux-form';
import {
  MDBDropdown
} from 'mdbreact';
import Modal from 'react-modal';
import { routes } from '../../utils/constants';
import Column from '../ReactComponent/Column';
import RenderField from '../ReactComponent/RenderField';
import Row from '../ReactComponent/Row';
import SearchWithFilter from '../ReactComponent/SearchWithFilter';
import SortableTable from '../ReactComponent/SortableTable';
import NoDataDecorator from '../ReactComponent/SortableTable/NoDataDecorator';
import { H2 } from '../../theme/typography';
import Actions from '../../assets/icons/Actions.svg';
import EditWhite from '../../assets/icons/EditWhite.svg';
import DeleteWhite from '../../assets/icons/DeleteWhite.svg';
import {
  FilterContainer,
  PageDescription,
  ModalTitle,
  Close,
  ModalBody,
  ButtonDiv,
  SuccessDeleteMessage
} from './css';
import { Icon as CommonIcon } from '../ReactComponent/Icon';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import putReduxUserDetails from './actions';
import {
  homeUrlNoTrailingSlash,
  openInNewTab,
  formatDate
} from '../../utils/func';
import { getCompaniesList, getCompanyUsers } from '../../services/companies';
import { deleteUserById } from '../../services/users';
import putReduxAllCompanies from '../ManageCompanies/actions';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import SecondaryButton from '../ReactComponent/SecondaryButton';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';

const ManageUsersForm = 'ManageUsers';

const Dropdown = ({
  openModal,
  userId
}) => (
  <MDBDropdown className="dropdown">
    <MDBDropdownToggle className="border-0">
      <CommonIcon iconSrc={Actions}/>
    </MDBDropdownToggle>
    <StyledMDBDropdownMenu className="text-white bg-dark" basic>
      <DropdownItem onClick={() => window.location.href = (`/create-user?userId=${userId}`)}>
        <DropdownItemIcon iconSrc={EditWhite}/>
        {t('EditUser')}
      </DropdownItem>
      <DropdownItem onClick={() => openModal()}>
        <DropdownItemIcon iconSrc={DeleteWhite}/>
        {t('DeleteUser')}
      </DropdownItem>
    </StyledMDBDropdownMenu>
  </MDBDropdown>
);

const columns = [
  {
    id: 1,
    label: 'Action',
    field: 'actions',
    sort: false,
    selected: true,
    default: true
  },
  {
    id: 2,
    label: 'UsernameEmail',
    field: 'username',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 3,
    label: 'Company',
    field: 'company',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 4,
    label: 'Description',
    field: 'description',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 5,
    label: 'LastLoginDate',
    field: 'lastLogOnDate',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 6,
    label: 'Status',
    field: 'status',
    sort: true,
    selected: false,
    default: false
  }
];

const ModalContent = ({
  closeModal,
  handleDeleteUser
}) => (
  <>
    <ModalTitle>{t('DeleteUser')}</ModalTitle>
    {t('DeleteUserPrompt')}
    <ButtonDiv>
      <SecondaryButton
        onClick={() => closeModal()}
        className="active min-width"
      >
        {t('Cancel')}
      </SecondaryButton>
      <PrimaryButton
        onClick={() => handleDeleteUser()}
        className="active min-width"
      >
        {t('RemoveUser')}
      </PrimaryButton>
    </ButtonDiv>
  </>
);

class ManageUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataPerPage: 10,
      activeTab: 0,
      totalPages: [],
      columns: columns,
      modalIsOpen: false,
      companies: [],
      companyNames: [],
      itemToDelete: null,
      showSuccessMessage: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.fetchCompaniesAssociatedToUser();
    }, 200);
  }

  fetchCompaniesAssociatedToUser = async () => {
    const {
      division,
      userId,
      putReduxAllCompanies
    } = this.props;
    const { data: companies } = await getCompaniesList(division, userId);

    if (companies) {
      putReduxAllCompanies(companies);
      const companyNames = companies.map(company => company.companyName);
      this.setState({
        companies,
        companyNames
      });
      this.sendInitialRequest(companies);
    }
  };

  sendInitialRequest = async companies => {
    const {
      dispatch,
      putReduxUserDetails
    } = this.props;
    const firstCompany = companies[0];
    const {
      companyName,
      companyId
    } = firstCompany;

    if (companyName && companyId) {
      dispatch(change('ManageUsers', 'selectedCompany', companyName));
      const { data: firstCompanyUsers } = await getCompanyUsers(companyId);
      const convertedData = this.convertDataFormat(
        firstCompanyUsers,
        companyName
      );
      putReduxUserDetails(convertedData);
      this.setState({ data: convertedData });
    }
  };

  handleCompanySelect = async newValue => {
    const {
      dispatch,
      putReduxUserDetails
    } = this.props;
    const { companies } = this.state;

    dispatch(change('ManageUsers', 'selectedCompany', newValue));

    if (companies) {
      const selectedCompany = companies.filter(
        company => company.companyName === newValue
      );
      const companyId = selectedCompany[0].companyId;
      const companyName = selectedCompany[0].companyName;
      const { data: companyUsers } = await getCompanyUsers(companyId);

      if (companyUsers) {
        const convertedUserData = this.convertDataFormat(
          companyUsers,
          companyName
        );
        putReduxUserDetails(convertedUserData);
        this.setState({ data: convertedUserData });
      }
    }
  };

  handleCreateUser = e => {
    e.preventDefault();
    const createUserURL = `${homeUrlNoTrailingSlash()}${routes.createUser}`;
    openInNewTab(createUserURL);
  };

  renderHeader = ({
    heading,
    description
  }) => {
    // TODO: Extract this to an outer component its used in ManagePickups as well
    return (
      <Row style={{ margin: '45px 0' }}>
        <Column $tablet={8} $mobile={12}>
          <div>
            <H2>{heading}</H2>
            <PageDescription>{description}</PageDescription>
          </div>
        </Column>
        <Column $tablet={4} $mobile={12} style={{ textAlign: 'end' }}>
          <PrimaryButton
            className="active"
            iconRightArrow
            style={{ margin: '16px 0' }}
            onClick={e => this.handleCreateUser(e)}
          >
            {t('AddNewUser')}
          </PrimaryButton>
        </Column>
      </Row>
    );
  };

  handleDeleteUser = async () => {
    const { itemToDelete } = this.state;
    const {
      itemData: {
        userId,
        username
      }
    } = itemToDelete;
    const { data } = this.state;
    const oldData = [...data];

    this.setState({ showSuccessMessage: false });
    this.openModal();

    deleteUserById(userId)
      .then(response => {
        if (response && response.status > 200) {
          const updatedListItems = oldData.filter(item => {
            return item.username !== username;
          });
          this.setState({
            data: updatedListItems,
            itemToDelete: null,
            showSuccessMessage: true
          });
        }
      })
      .catch(error => {
        this.setState({
          itemToDelete: null,
          showSuccessMessage: false,
          error
        });
      });

    this.closeModal();
    this.resetData();
  };

  resetData = () => {
    this.setState({
      itemToDelete: null
    });
  };

  convertDataFormat = (companyUsers, companyName) => {
    return companyUsers.map((each, index) => ({
      actions: (
        <Dropdown openModal={() => this.openModal(each, index)} index={index} userId={each.userId}/>
      ),
      username: each.username || 'N/A',
      company: companyName || 'N/A',
      description: each.description || 'N/A',
      lastLogOnDate:
        (each.lastLogOnDate && formatDate(each.lastLogOnDate)) || 'N/A',
      status: each.isActive ? t('Active') : t('Inactive')
    }));
  };

  openModal = (each, index) => {
    this.setState({
      modalIsOpen: true,
      itemToDelete: {
        itemData: each,
        index: index
      }
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      itemToDelete: null
    });
  };

  getTotalPages = () => {
    const {
      dataPerPage,
      data
    } = this.state;
    const totalPages = [];
    for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
      totalPages.push(i);
    }
    this.setState({ totalPages });
  };

  getSuggestions = value => {
    const { allUsers } = this.props;
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    const filteredData =
      inputLength === 0
        ? allUsers
        : allUsers.filter(
          item => item && item.username.slice(0, inputLength).toLowerCase() === inputValue.toLowerCase()
        );
    this.setState({ data: filteredData }, () => this.getTotalPages());
  };

  render() {
    const {
      rendering: {
        fields: {
          heading,
          description
        }
      }
    } = this.props;
    const {
      data,
      modalIsOpen,
      companyNames = [],
      showSuccessMessage
    } = this.state;
    return (
      <Fragment>
        <Modal
          isOpen={modalIsOpen}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <Close onClick={() => this.closeModal()}/>
          <ModalBody className="justify-content-center">
            <ModalContent
              closeModal={this.closeModal}
              handleDeleteUser={this.handleDeleteUser}
            />
          </ModalBody>
        </Modal>

        {this.renderHeader({
          heading: heading.value,
          description: description.value
        })}

        <FilterContainer>
          <div className="row justify-content-md-center">
            <Column $tablet={9}>
              <Field
                name="selectedCompany"
                type="dropdown"
                label={t(`Companies`)}
                data={[...companyNames]}
                component={RenderField}
                onChange={(event, newValue) =>
                  this.handleCompanySelect(event, newValue)
                }
              />
              <SearchWithFilter
                label={t('SearchUserText')}
                getSuggestions={this.getSuggestions}
              />
            </Column>
            {showSuccessMessage && (
              <SuccessDeleteMessage>
                You have successfully removed a user
              </SuccessDeleteMessage>
            )}
          </div>
        </FilterContainer>
        <NoDataDecorator component={SortableTable} data={data} columns={this.state.columns} className="mb-5"/>
      </Fragment>
    );
  }
}

const mstp = (state) => ({
  formData: getFormValues('ManageUsersForm')(state),
  division: state.profile.division,
  userId: state.profile.userId,
  allUsers: state.user.userDetails,
  selectedCompany:
    state.form.ManageUsersForm &&
    state.form.ManageUsersForm.values &&
    state.form.ManageUsersForm.values.selectedCompany
});

const mdtp = dispatch => ({
  putReduxUserDetails: userDetails => dispatch(putReduxUserDetails(userDetails)),
  putReduxAllCompanies: companies => dispatch(putReduxAllCompanies(companies))
});

ManageUsers = reduxForm({
  form: ManageUsersForm // a unique identifier for this form
})(ManageUsers);

export default connect(
  mstp,
  mdtp
)(ManageUsers);
