import React from 'react';
import axios from 'axios';
import { t } from 'i18next';
import { connect } from 'react-redux';
import {
  reduxForm, Field, change, getFormValues
} from 'redux-form';
import { MDBDropdown } from 'mdbreact';
import Modal from 'react-modal';
import { endpoints, routes } from '../../utils/constants';
import RenderField from '../ReactComponent/RenderField';
import SortableTable from '../ReactComponent/SortableTable';
import SideBar from '../ReactComponent/SideBar';
import { H2 } from '../../theme/typography';
import Filter from '../../assets/icons/Filter.svg';
import FilterOrange from '../../assets/icons/FilterOrange.svg';
import {
  isEmail, maxChar255, isRequired, dateRangeValidate
} from '../../utils/validator';
import Grid from '../../assets/icons/CustomizeGrid.svg';
import Actions from '../../assets/icons/Actions.svg';
import Error from '../../assets/icons/Error.svg';
import TrackWhite from '../../assets/icons/TrackWhite.svg';
import TrackGrey from '../../assets/icons/TrackGrey.svg';
import ViewWhite from '../../assets/icons/ViewWhite.svg';
import ViewGrey from '../../assets/icons/ViewGrey.svg';
import ViewSignedDocumentsGrey from '../../assets/icons/ViewSignedDocumentsGrey.svg';
import ViewSignedDocumentsWhite from '../../assets/icons/ViewSignedDocumentsWhite.svg';
import DownloadWhite from '../../assets/icons/DownloadWhite.svg';
import DownloadGrey from '../../assets/icons/DownloadGrey.svg';
import CancelWhite from '../../assets/icons/CancelWhite.svg';
import DuplicateShipmentGrey from '../../assets/icons/DuplicateShipmentGrey.svg';
import DuplicateShipmentWhite from '../../assets/icons/DuplicateShipmentWhite.svg';
import ShipmentInProgressWhite from '../../assets/icons/ShipmentInProgressWhite.svg';
import { Icon as CommonIcon } from '../ReactComponent/Icon';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import SecondaryBtn from '../ReactComponent/SecondaryButton';
import Column from '../ReactComponent/Column';
import Row from '../ReactComponent/Row';
import {
  PrimaryButton,
  SecondaryButton,
  ButtonGroup,
  FilterContainer,
  FilterIcon,
  Icon,
  CategoryTabs,
  Category,
  PageDescription,
  CategoryTitle,
  PageTitle,
  SmallDesc,
  ErrorIcon,
  ModalTitle,
  Close,
  ModalBody,
  ButtonDiv,
  DateField,
  CheckBoxField,
  DownloadModalBody, ResetFiltersButton, CommonButtonGroup
} from './css';
import { putReduxAllShipments, putReduxAllShipmentsHistory, putReduxAllDrafts } from './actions';
import SearchWithFilter from '../ReactComponent/SearchWithFilter';
import CustomizeTableView from '../ReactComponent/CustomizeTableView';
import {
  homeUrlNoTrailingSlash,
  formatDate,
  downloadFile,
  formatDisplayDate,
  formatMoney,
  runningOnBrowser
} from '../../utils/func';
import { apiGetShipments, getShipmentDrafts, apiGetShipmentReports } from '../../services/shipments';
import NoDataDecorator from '../ReactComponent/SortableTable/NoDataDecorator';
import { ShipmentStatuses } from '../../utils/enums';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import { getDeliveryDate } from '../../utils/dateTime';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';
import CommonPrimaryButton, { styleTypes } from '../ReactComponent/PrimaryButton';
import ColumnTypes from '../ReactComponent/SortableTable/constants';

const formName = 'ManageShipments';
const FORM_FIELDS = {
  FILTER_ACCT_NUMBERS: 'filterAccountNumbers'
};

const Dropdown = ({
  draftActions,
  status,
  openBOL,
  cancelShipment,
  // eslint-disable-next-line no-unused-vars
  shareShipment,
  probillNumber,
  division,
  shipmentId
}) => (
  <MDBDropdown className="dropdown" >
    <MDBDropdownToggle className="border-0">
      <CommonIcon iconSrc={Actions} />
    </MDBDropdownToggle>
    <StyledMDBDropdownMenu className="text-white bg-dark" basic>
      {draftActions ? (
        <DropdownItem onClick={() => window.open(`${routes.createShipment}?division=${division}&shipmentId=${shipmentId}`)}>
          <DropdownItemIcon iconSrc={ShipmentInProgressWhite} />
          {t('CompleteDraft')}
        </DropdownItem>) : (
          <>
            <DropdownItem
              disabled={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.NotYetPickedUp}
              onClick={() => window.open(`${routes.viewShipmentTracking}?division=${division}&probillNumber=${probillNumber}`)}
            >
              <DropdownItemIcon
              iconSrc={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.NotYetPickedUp
                ? TrackGrey
                : TrackWhite} />
              {t('ViewTrackingDetails')}
            </DropdownItem>
            <DropdownItem
              disabled={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.NotYetPickedUp}
              onClick={() => window.open(`${routes.viewShipment}?division=${division}&probillNumber=${probillNumber}`)}>
              <DropdownItemIcon
              iconSrc={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.NotYetPickedUp
                ? ViewGrey
                : ViewWhite} />
              {t('ViewShipmentDetails')}
            </DropdownItem>
            <DropdownItem
            disabled={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.NotYetPickedUp}
            onClick={() => window.open(`${routes.viewShipment}?division=${division}&probillNumber=${probillNumber}&scrollToDocs=true`)}>
              <DropdownItemIcon
              iconSrc={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.NotYetPickedUp
                ? ViewSignedDocumentsGrey
                : ViewSignedDocumentsWhite} />
              {t('ViewSignedDocuments')}
            </DropdownItem>
            <DropdownItem disabled={status !== ShipmentStatuses.NotYetPickedUp} onClick={() => openBOL()}>
              <DropdownItemIcon iconSrc={status !== ShipmentStatuses.NotYetPickedUp ? DownloadGrey : DownloadWhite} />
              {t('DownloadShippingDocuments')}
            </DropdownItem>
            <DropdownItem
            disabled={status === ShipmentStatuses.UnderReview}
            onClick={() => window.open(`${routes.createShipment}?recreate=true&division=${division}&probillNumber=${probillNumber}`)}>
              <DropdownItemIcon iconSrc={status === ShipmentStatuses.UnderReview ? DuplicateShipmentGrey : DuplicateShipmentWhite} />
              {t('DuplicateShipment')}
            </DropdownItem>
            <DropdownItem onClick={() => cancelShipment()}>
              <DropdownItemIcon iconSrc={CancelWhite} />
              {t('CancelShipment')}
            </DropdownItem>
          </>)}
    </StyledMDBDropdownMenu>
  </MDBDropdown>
);

const columns = [
  {
    id: 1,
    label: '',
    field: 'actions',
    sort: false,
    selected: true,
    default: true,
  },
  {
    id: 2,
    label: 'BillOfPayment',
    field: 'probillNumber',
    sort: true,
    selected: true,
    default: true,
    disabled: true
  },
  {
    id: 3,
    label: 'DeliveryDate',
    field: 'deliveryDate',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 4,
    label: 'From',
    field: 'from',
    sort: true,
    selected: true,
    default: true,
  },
  {
    id: 5,
    label: 'To',
    field: 'to',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 6,
    label: 'Status',
    field: 'status',
    sort: true,
    selected: true,
    default: true,
  },
  {
    id: 7,
    label: 'ProofOfDeliveryNo',
    field: 'pod',
    sort: true,
    selected: false,
    default: false,
  },
  {
    id: 8,
    label: 'BillOfLading',
    field: 'bol',
    sort: true,
    selected: false,
    default: false,
  },
  {
    id: 9,
    label: 'ShipmentDate',
    field: 'shipmentDate',
    sort: true,
    selected: false,
    default: false,
  },
  {
    id: 10,
    label: 'DeliveryOrigin',
    field: 'deliveryOrigin',
    sort: true,
    selected: false,
    default: false,
  },
  {
    id: 11,
    label: 'DeliveryDestination',
    field: 'deliveryDestination',
    sort: true,
    selected: false,
    default: false,
  },
  {
    id: 12,
    label: 'PiecesInDelivery',
    field: 'piecesInDelivery',
    sort: true,
    selected: false,
    default: false,
  },
  {
    id: 13,
    label: 'WeightOfDelivery',
    field: 'weightOfDelivery',
    sort: true,
    selected: false,
    default: false,
  },
  {
    id: 14,
    label: 'Charge',
    field: 'charge',
    sort: true,
    selected: false,
    default: false,
    type: ColumnTypes.Currency
  }
];

const defaultShipmentColumns = [
  {
    id: 1,
    label: '',
    field: 'actions',
    sort: false,
    selected: true,
    default: true,
  },
  {
    id: 2,
    label: 'BillOfPayment',
    field: 'probillNumber',
    sort: true,
    selected: true,
    default: true,
    disabled: true
  },
  {
    id: 3,
    label: 'From',
    field: 'from',
    sort: true,
    selected: true,
    default: true,
  },
  {
    id: 4,
    label: 'To',
    field: 'to',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 5,
    label: 'Status',
    field: 'status',
    sort: true,
    selected: true,
    default: true,
  },
  {
    id: 6,
    label: 'DeliveryDate',
    field: 'deliveryDate',
    sort: true,
    selected: true,
    default: true
  },
];
const defaultDraftColumns = [
  {
    id: 1,
    label: '',
    field: 'actions',
    sort: false,
    selected: true,
    default: true,
  },
  {
    id: 2,
    label: 'ShipmentId',
    field: 'shipmentId',
    sort: true,
    selected: true,
    default: true,
    disabled: true
  },
  {
    id: 3,
    label: 'CreateDate',
    field: 'createDate',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 4,
    label: 'From',
    field: 'from',
    sort: true,
    selected: true,
    default: true,
  },
  {
    id: 5,
    label: 'To',
    field: 'to',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 6,
    label: 'Status',
    field: 'status',
    sort: true,
    selected: true,
    default: true,
  },
];

const tabFilters = {
  default: () => true,
  1: ({ status }) => ![ShipmentStatuses.Delivered, ShipmentStatuses.NotYetPickedUp].includes(status),
  2: ({ status }) => status === ShipmentStatuses.Delivered
};
const NUMBER_OF_DAYS = 60;
const initialValues = {
  filterFromDate: formatDate(new Date((new Date()).getTime() - (NUMBER_OF_DAYS * 24 * 60 * 60 * 1000))), filterToDate: formatDate(new Date())
};

// eslint-disable-next-line react/no-multi-comp
const ModalContent = ({
  // eslint-disable-next-line no-unused-vars
  modalAction, modalShipmentNumber, closeModal, postShare, valid, accountNumbers, handleDownloadSubmit
// eslint-disable-next-line no-nested-ternary
}) => (modalAction === 'share' ?
    <>
      <ModalTitle>{t('Share')}</ModalTitle>
      {t('ShareByEmailModal')}
      <Field
        name="shareShipmentEmail"
        type="email"
        component={RenderField}
        className="col"
        label={t('Email')}
        required
        validate={[isEmail, maxChar255, isRequired]}
      />
      <Field
        name="shareShipmentBody"
        type="textarea"
        component={RenderField}
        className="col"
        label={t('EmailBody')}
        validate={[maxChar255]}
      />
      <ButtonDiv>
        <PrimaryButton onClick={postShare} className={`${valid && 'active'} min-width`}>
          {t('ok')}
        </PrimaryButton>
      </ButtonDiv>
    </> :
  modalAction === 'download' ?
      <>
        <ModalTitle>{t('DownloadShipmentReportTitle')}</ModalTitle>
        <SmallDesc className="text-center">{t('DownloadShipmentReportDescription')}</SmallDesc>
        <CategoryTitle>{t('DownloadShipmentReportDateRange')}</CategoryTitle>
        <DownloadModalBody>
          <div className="date-fields">
            <Field
              name="filterFromDate"
              type="date"
              component={RenderField}
              className="col"
              label={t('FromDate')}
              validate={[isRequired, dateRangeValidate]}
            />
            <Field
              name="filterToDate"
              type="date"
              component={RenderField}
              className="col"
              label={t('ToDate')}
              validate={[isRequired, dateRangeValidate]}
            />
          </div>
          <CategoryTitle>{t('FilterByBillingAccount')}</CategoryTitle>
          <Field
            name={FORM_FIELDS.FILTER_ACCT_NUMBERS}
            type="checkboxes"
            component={RenderField}
            multiple
            containerMaxHeight="100px"
            data={accountNumbers}
          />
        </DownloadModalBody>
        <ButtonGroup>
          <SecondaryButton type="button" onClick={closeModal}>
            <span className="icon"></span>
            {t('Cancel')}
          </SecondaryButton>
          <PrimaryButton className="submit" onClick={handleDownloadSubmit}>
            <span className="icon"></span>
            {t('Download')}
          </PrimaryButton>
        </ButtonGroup>
      </>
    :
      <>
        <ErrorIcon src={Error} alt="Error" />
        <ModalTitle>{t('ErrorWithShippingAction')}</ModalTitle>
        <p>{t('PleaseContactSupportHotline')}</p>
        <ButtonDiv>
          <PrimaryButton onClick={closeModal} className="active min-width">
            {t('ok')}
          </PrimaryButton>
        </ButtonDiv>
      </>
);
// eslint-disable-next-line react/no-multi-comp
class ManageShipments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filterOpen: false,
      customizeOpen: false,
      dataPerPage: 10,
      activeTab: 0,
      statusList: [],
      initialStatusList: [],
      prevFromDate: initialValues.filterFromDate,
      prevToDate: initialValues.filterToDate,
      // eslint-disable-next-line react/destructuring-assignment
      prevAccountNumbers: this.props.accountNumbers,
      columns: defaultShipmentColumns,
      displayCustomizeTableView: false,
      modalAction: null,
      modalIsOpen: false,
      modalShipmentNumber: null,
      statusFilter: () => true,
      searchValue: '',
      draftsVisited: false,
      historyVisited: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const { dispatch, accountNumbers } = this.props;
    dispatch(change('ManageShipments', FORM_FIELDS.FILTER_ACCT_NUMBERS, accountNumbers));
    setTimeout(() => { this.fetchAllShipments(true); }, 200);
  }

  cancelShipment(shipmentNumber) {
    this.openModal(shipmentNumber, 'cancel');
  }

  shareShipment(shipmentNumber) {
    this.openModal(shipmentNumber, 'share');
  }

  openModal(shipmentNumber, modalAction) {
    this.setState({ modalAction });
    this.setState({ modalIsOpen: true });
    this.setState({ modalShipmentNumber: shipmentNumber });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ modalShipmentNumber: null });
    this.setState({ modalAction: null });
  }

  postShare() {
    if (this.props.valid) {
      this.closeModal();
    }
  }

  fetchAllShipments = async (init, filterFrom, filterTo, filterAccountNumbers) => {
    const { division, accountNumbers, userId } = this.props;
    const { activeTab } = this.state;
    if (activeTab === 3) {
      getShipmentDrafts(division, userId, 30).then((res) => {
        if (res.status === 200) {
          // eslint-disable-next-line no-shadow
          const { putReduxAllDrafts } = this.props;
          const isDraft = true;
          const convertedData = this.convertDataFormat(res.data, isDraft);
          putReduxAllDrafts(convertedData);
          this.setState({ data: convertedData });
        }
      });
    } else {
      apiGetShipments(division,
        filterAccountNumbers ? filterAccountNumbers : accountNumbers,
        filterFrom ? filterFrom : initialValues.filterFromDate,
        filterTo ? filterTo : initialValues.filterToDate,
        activeTab === 4)// active tab === history tab
        .then((res) => {
          if (res.status === 200) {
            // eslint-disable-next-line no-shadow
            const { putReduxAllShipments, putReduxAllShipmentsHistory } = this.props;
            const convertedData = this.convertDataFormat(res.data);
            if (activeTab === 4) {
              putReduxAllShipmentsHistory(convertedData);
            } else {
              putReduxAllShipments(convertedData);
            };
            this.setState({ data: convertedData });
            if (!init) {
              this.applyFrontFilters();
              this.filterList();
              this.getStatusList();
            }
            if (init) {
              this.getInitialStatusList();
            }
          }
        });
    };
    this.setState({ prevFromDate: filterFrom ? filterFrom : initialValues.filterFromDate });
    this.setState({ prevToDate: filterTo ? filterTo : initialValues.filterToDate });
    this.setState({ prevAccountNumbers: filterAccountNumbers ? filterAccountNumbers : accountNumbers });
  };

  getInitialStatusList = () => {
    const { allShipments, allShipmentsHistory, dispatch } = this.props;
    const { activeTab } = this.state;
    const uniqueStatus = (activeTab === 4
      ? allShipmentsHistory
      : allShipments).map((s) => s.status).filter((value, index, self) => (self.indexOf(value) === index));
    this.setState({ statusList: uniqueStatus, initialStatusList: uniqueStatus });
    dispatch(change('ManageShipments', 'filterShipmentStatus', uniqueStatus));
  };

  getStatusList = () => {
    const { allShipments, allShipmentsHistory, dispatch } = this.props;
    const { activeTab } = this.props;
    const uniqueStatus = (activeTab === 4
      ? allShipmentsHistory
      : allShipments).map((s) => s.status).filter((value, index, self) => (self.indexOf(value) === index));
    this.setState({ statusList: uniqueStatus });
    dispatch(change('ManageShipments', 'filterShipmentStatus', uniqueStatus));
  };

  openBOL = (probillNumber) => {
    const { division, emailAddress, userId } = this.props;
    axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipmentDocuments}/${division}/${probillNumber}/bol`, {
      params: {
        emailAddress,
        userId
      }
    }).then((res) => {
      if (res.status === 200) {
        downloadFile({ dataAsBase64: res.data.fileDataAsBase64, mimeType: res.data.mimeType, filename: res.data.fileName });
      }
    });
  };

  convertDataFormat = (data, draftActions) => {
    const { division } = this.props;
    return data.map((each) => ({
      probillNumber: each.probillNumber,
      shipmentId: each.shipmentId,
      deliveryDate: each.deliveryDate && getDeliveryDate({ deliveryDate: each.deliveryDate, deliveryDateType: each.deliveryDateType }),
      createDate: each.createDate && formatDisplayDate(each.createDate),
      from: `${[each.from.name, each.from.city, each.from.provinceCode, each.from.countryCode].filter(Boolean).join(', ')}`,
      to: `${[each.to.name, each.to.city, each.to.provinceCode, each.to.countryCode].filter(Boolean).join(', ')}`,
      bol: each.bolNumber,
      shipmentDate: each.shipDate && formatDisplayDate(each.shipDate),
      deliveryOrigin: each.originTerminal,
      deliveryDestination: each.destinationTerminal,
      piecesInDelivery: each.pieces,
      weightOfDelivery: each.weight.value,
      charge: each.charge ? formatMoney(each.charge.value) : null,
      status: each.status,
      actions: <Dropdown
        draftActions={draftActions}
        status={each.status}
        openBOL={() => this.openBOL(each.probillNumber)}
        probillNumber={each.probillNumber}
        shipmentId={each.shipmentId}
        division={division}
        cancelShipment={() => this.cancelShipment(each.probillNumber)}
        shareShipment={() => this.shareShipment(each.probillNumber)}
      />
    }));
  };

  getSuggestions = (value) => {
    const searchValue = value.toLowerCase().trim();
    this.setState({ searchValue }, this.filterList);
  };

  filterRowByValue = (value) =>
    (row) => columns.some(
      ({ field }) => typeof row[field] === 'string' && row[field].toLowerCase().indexOf(value) !== -1
    );

  changeTab = (tabIndex) => {
    const statusFilter = tabFilters[tabIndex] ? tabFilters[tabIndex] : tabFilters.default;
    if (tabIndex === 3) {
      this.setState({ columns: defaultDraftColumns }, () => {
        if (!this.state.draftsVisited) {
          this.fetchAllShipments();
        }
        this.setState({ draftsVisited: true });
      });
    } else if (tabIndex === 4) {
      this.setState({ columns: defaultShipmentColumns }, () => {
        if (!this.state.historyVisited) {
          this.fetchAllShipments();
        }
        this.setState({ historyVisited: true });
      });
    }
    this.setState({ statusFilter, activeTab: tabIndex, columns: defaultShipmentColumns }, this.filterList);
  };

  filterList = () => {
    const { statusFilter, searchValue, activeTab } = this.state;
    const { allShipments, allDrafts, allShipmentsHistory } = this.props;
    const filterBySearchInput = this.filterRowByValue(searchValue);
    let data = allShipments;
    if (activeTab === 4) {
      data = allShipmentsHistory;
    } else if (activeTab === 3) {
      data = allDrafts;
    }
    const filteredData = data
      .filter(statusFilter)
      .filter(filterBySearchInput);
    this.setState({ data: filteredData });
  };

  applyFrontFilters = () => {
    const {
      dispatch, allShipments, allShipmentsHistory, formData: { filterShipmentStatus }
    } = this.props;
    const { activeTab } = this.state;
    dispatch(change(formName, 'searchByKey', ''));
    let filteredData = [];
    if (filterShipmentStatus) {
      filterShipmentStatus.forEach((status) => {
        filteredData = filteredData.concat(
          (activeTab === 4 ? allShipmentsHistory : allShipments)
            .filter((s) => s.status === status)
        );
        filteredData = filteredData.filter((value, index, self) => (self.indexOf(value) === index));
      });
    }
    this.setState({ data: filteredData });
  };

  handleSubmitFilter = () => {
    const { formData: { filterFromDate, filterToDate, filterAccountNumbers }, dispatch } = this.props;
    const {
      activeTab, prevFromDate, prevToDate, prevAccountNumbers
    } = this.state;
    if (activeTab === 3 || filterFromDate !== prevFromDate || filterToDate !== prevToDate || filterAccountNumbers !== prevAccountNumbers) {
      this.fetchAllShipments(false, filterFromDate, filterToDate, filterAccountNumbers);
    } else {
      this.applyFrontFilters();
    }
    dispatch(change(formName, 'searchByKey', '')); // Clear the search field, on submit (ECE-1643)...
    this.toggleFilter();
  };

  resetFilters = () => {
    const { dispatch } = this.props;
    dispatch(change('ManageShipments', 'filterShipmentStatus', this.state.initialStatusList));
    dispatch(change('ManageShipments', 'filterFromDate', initialValues.filterFromDate));
    dispatch(change('ManageShipments', 'filterToDate', initialValues.filterToDate));
    dispatch(change('ManageShipments', FORM_FIELDS.FILTER_ACCT_NUMBERS, this.props.accountNumbers));
    dispatch(change(formName, 'searchByKey', '')); // Clear the search field, on reset (ECE-1643)...

    this.fetchAllShipments(false, initialValues.filterFromDate, initialValues.filterToDate, this.props.accountNumbers);
  };

  toggleFilter = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    const { filterOpen } = this.state;
    this.setState({ filterOpen: !filterOpen });
  };

  updateTable = (newColumns) => {
    this.setState({ columns: newColumns });
  };

  toggleCustomizeTableView = () => {
    if (runningOnBrowser()) {
      window.scrollTo(0, 0);
    }
    this.setState((prevState) => ({ displayCustomizeTableView: !prevState.displayCustomizeTableView }));
  };

  handleCreateShipment = (e) => {
    e.preventDefault();
    const createShipmentUrl = `${homeUrlNoTrailingSlash()}${routes.createShipment}`;
    window.location.href = createShipmentUrl;
  };

  handleDownloadReport = () => {
    this.setState({ modalIsOpen: true, modalAction: 'download' });
  };

  handleDownloadSubmit = async () => {
    if (!this.props.valid) return;

    const { division, formData: { filterFromDate, filterToDate, filterAccountNumbers } } = this.props;

    const accountNumbers = filterAccountNumbers.filter((n) => n !== '');
    if (!accountNumbers || accountNumbers.length === 0) return;
    await apiGetShipmentReports(division, accountNumbers, filterFromDate, filterToDate).then((res) => {
      if (res.status === 200) {
        const { fileName, mimeType, fileDataAsBase64 } = res.data;
        const fileType = fileName.includes('.csv') ? '' : '.csv';
        downloadFile({ dataAsBase64: fileDataAsBase64, mimeType, filename: fileName + fileType });
      }
    });
  };

  handleFilterFromDate = (date) => {
    const { dispatch } = this.props;
    const updatedFilterToDate = formatDate(new Date(date).getTime() + (NUMBER_OF_DAYS * 24 * 60 * 60 * 1000));
    dispatch(change(formName, 'filterToDate', updatedFilterToDate));
  };

  renderHeader = ({ heading, description }) => (
      <Row style={{ margin: '45px 0' }}>
        <Column $tablet={8} $mobile={12}>
          <div>
            <H2>{heading}</H2>
            <PageDescription>{description}</PageDescription>
          </div>
        </Column>
        <Column $tablet={4} $mobile={12} style={{ textAlign: 'end' }}>
          <CommonPrimaryButton className="active" iconRightArrow style={{ margin: '16px 0' }} onClick={(e) => this.handleCreateShipment(e)}>
            {t('CreateAShipment')}
          </CommonPrimaryButton>
          <SecondaryBtn className="active" iconRightArrow onClick={() => this.handleDownloadReport()}>
            {t('DownloadReport')}
          </SecondaryBtn>
        </Column>
      </Row>
  );

  render() {
    const {
      rendering: { fields: { heading, description } }, accountNumbers, valid, formData
    } = this.props;
    const {
      filterOpen, customizeOpen, activeTab, data, initialStatusList, modalShipmentNumber, modalAction, modalIsOpen
    } = this.state;
    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <Close onClick={this.closeModal} />
          <ModalBody className="justify-content-center">
            <ModalContent
              closeModal={this.closeModal}
              modalAction={modalAction}
              modalShipmentNumber={modalShipmentNumber}
              handleDownloadSubmit={this.handleDownloadSubmit}
              accountNumbers={accountNumbers}
              postShare={(e) => {
                e.preventDefault();
                this.postShare(modalShipmentNumber);
              }}
              valid={valid} />
          </ModalBody>
        </Modal>
        {
          filterOpen && <SideBar close={this.toggleFilter}>
            <form>
              <PageTitle>{t('ApplyFilters')}</PageTitle>
              {
                activeTab === 0 && (
                  <>
                    <CategoryTitle>{t('FilterByShipmentStatus')}</CategoryTitle>
                    <CheckBoxField
                      name="filterShipmentStatus"
                      type="checkboxes"
                      component={RenderField}
                      multiple
                      data={initialStatusList}
                    />
                  </>
                )
              }
              <CategoryTitle>{t('FilterByDateCreated')}</CategoryTitle>
              <DateField
                name="filterFromDate"
                type="date"
                component={RenderField}
                className="col"
                label={t('FromDate')}
                validate={[isRequired, dateRangeValidate]}
                onChange={(date) => this.handleFilterFromDate(date)}
              />
              <DateField
                name="filterToDate"
                type="date"
                component={RenderField}
                className="col"
                label={t('ToDate')}
                validate={[isRequired, dateRangeValidate]}
              />
              <CategoryTitle>{t('FilterByBillingAccount')}</CategoryTitle>
              <Field
                name={FORM_FIELDS.FILTER_ACCT_NUMBERS}
                type="checkboxes"
                component={RenderField}
                multiple
                data={accountNumbers}
              />
              <CommonButtonGroup>
                <ResetFiltersButton
                  type="button"
                  styleType={styleTypes.SECONDARY}
                  onClick={() => this.resetFilters()}>
                  <span className="icon"></span>
                  {t('ResetFilters')}
                </ResetFiltersButton>
                <CommonPrimaryButton
                  type="button"
                  onClick={() => this.handleSubmitFilter()}
                  disabled={
                    !valid ||
                    (formData && formData[FORM_FIELDS.FILTER_ACCT_NUMBERS] && formData[FORM_FIELDS.FILTER_ACCT_NUMBERS].length) <= 0
                  }
                >
                  <span className="icon"></span>
                  {t('ApplyFilters')}
                </CommonPrimaryButton>
              </CommonButtonGroup>
            </form>
          </SideBar>
          }
        {this.renderHeader({ heading: heading.value, description: description.value })}

        <FilterContainer>
          <CategoryTabs className="row">
            <Category className={`col ${activeTab === 0 && 'active'}`} onClick={() => this.changeTab(0)}>{t('RecentShipments')}</Category>
            <Category className={`col ${activeTab === 3 && 'active'}`} onClick={() => this.changeTab(3)}>{t('Drafts')}</Category>
            <Category className={`col ${activeTab === 4 && 'active'}`} onClick={() => this.changeTab(4)}>{t('ByAccountNum')}</Category>
          </CategoryTabs>
          <div className="row justify-content-md-center" >
            <div className="col-md-9">
              <div className="col">
                <SearchWithFilter label={t('SearchShipmentText')} getSuggestions={this.getSuggestions} />
                {activeTab !== 3 &&
                  <>
                    <div className="row justify-content-between">
                      <FilterIcon className="col-md-3" onClick={this.toggleFilter}>
                        <Icon src={filterOpen ? Filter : FilterOrange} alt="Filter" />
                        {t('ApplyFilters')}
                      </FilterIcon>
                      <FilterIcon className="col-md-3" onClick={this.toggleCustomizeTableView}>
                        <Icon src={Grid} alt="Customize" className={customizeOpen ? 'active' : ''} />
                        {t('CustomizeView')}
                      </FilterIcon>
                    </div>
                  </>}
              </div>
            </div>
          </div>
        </FilterContainer>

        <NoDataDecorator component={SortableTable} data={data} columns={this.state.columns} />

        {
          this.state.displayCustomizeTableView && (
            <CustomizeTableView
              columns={columns}
              updateTable={this.updateTable}
              hideView={this.toggleCustomizeTableView}
            />
          )
        }

      </>
    );
  }
}

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  division: state.profile.division,
  accountNumbers: state.profile.accountNumbers,
  userId: state.profile.userId,
  allShipments: state.user.allShipments,
  allDrafts: state.user.allDrafts,
  allShipmentsHistory: state.user.allShipmentsHistory,
  emailAddress: state.profile.email
});

const mdtp = (dispatch) => ({
  putReduxAllShipments: (allShipments) => dispatch(putReduxAllShipments(allShipments)),
  putReduxAllShipmentsHistory: (allShipmentsHistory) => dispatch(putReduxAllShipmentsHistory(allShipmentsHistory)),
  putReduxAllDrafts: (allDrafts) => dispatch(putReduxAllDrafts(allDrafts)),
});

ManageShipments = reduxForm({
  form: formName, // a unique identifier for this form
  initialValues,
})(ManageShipments);

export default connect(mstp, mdtp)(ManageShipments);
