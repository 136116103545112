/**
 * Accordion component
 *
 *
 * Usage example:
 * <Accordion initialPageId={0}> // will expand page 0 by default
 <AccordionPage title={"page 1"}>
 <Content1 />
 </AccordionPage>
 <AccordionPage title={"page 2"}>
 <Content2 />
 </AccordionPage>
 <AccordionPage title={"page 3"}>
 <Content3 />
 </AccordionPage>
 </Accordion>
 *
 *
 * Re-drawing:
 * in case if page is changing it's height dynamically - redraw function should be called.
 * redraw is provided to each page content component. (props.redraw());
 */

import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @param children - (SKIP IT), default react prop
 * @param initialPageId - page id which needs to be expanded first. By default all pages are collapsed
 * @param speed - (optional) animation speed. (values: 0.1 - 1);
 * @param name - (optional) for keys purposes in case of multiple accordions on a page.
 * @returns {*}
 */
const Accordion = ({
  children,
  initialPageId = -1,
  speed = 0.4,
  name = 'accordion'
}) => {
  const [currentPageId, setCurrentPageId] = useState(initialPageId);
  const togglePage = (id) => setCurrentPageId(currentPageId === id ? -1 : id);

  return (
    <>
      {children.map((child, id) => cloneElement(child, {
        id,
        togglePage,
        key: name + id,
        speed,
        isActive: currentPageId === id,
      }),
      )}
    </>
  );
};

Accordion.propTypes = {
  children: PropTypes.node,
  initialPageId: PropTypes.number,
  speed: PropTypes.number,
  name: PropTypes.string,
};

export default Accordion;
