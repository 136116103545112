import React from 'react';
import { CheckMarkIcon, Li } from '../css';
import { Span } from '../../../theme/typographySitecore';

const Step0List = ({ data }) => (
  <ul>
    {data.map(({ fields: { documentName } }, id) => (
      <Li key={documentName + id}>
        <CheckMarkIcon/>
        <Span field={documentName}/>
      </Li>
    ))}
  </ul>
);

export default Step0List;
