import React from 'react';
import { t } from 'i18next';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import { getMonthDayYear, formatMoney } from '../../../utils/func';
import {
  PageTitle,
  CategoryTitle,
  TextProperty,
  Terms,
  PrimaryButton,
  ButtonGroup
} from '../css';
import { H2, H3, P } from '../../../theme/typography';
import { SummaryForm } from './Step5';
import GreyBoxWithBorder from '../../_styledComponents/GreyBoxWithBorder';
import { routes } from '../../../utils/constants';

const renderBottomNav = () => (
    <ButtonGroup>
      <PrimaryButton
      className="submit"
      onClick={() => {
        if (window && typeof window !== 'undefined') {
          window.location.href = routes.viewUserDashboard;
          return window.location.href;
        }
        return '';
      }}>
        <span className="icon" alt="exit" />
        {t('Exit')}
      </PrimaryButton>
    </ButtonGroup>
);

const Step6 = ({
  formData = {}, serviceLevelQuotes, chosenServiceLevelCode, quoteData, parentFields, billingAccounts
}) => {
  // eslint-disable-next-line no-shadow
  const service = serviceLevelQuotes.filter((service) => service.serviceLevelCode === chosenServiceLevelCode)[0];
  const { description, expectedDeliveryDate } = service;
  const {
    probillNumber, orderNumber, charges, totalAmount, serviceLevelDescription
  } = quoteData;
  const { accessorials } = formData;
  let protectedAccount = billingAccounts && billingAccounts.some((a) => a.accountNumber === formData.billTo && a.protected);
  if (formData && formData.division === 'Sameday' && formData.paymentMethod) {
    if (formData.paymentMethod.name === 'Collect'
    || ((formData.paymentMethod.name === 'Third Party' && protectedAccount) || formData.billToMethod === 'EnterBillTo')) {
      protectedAccount = true;
    }
  }
  return (
    <>
      <PageTitle>
        {parentFields.step6PageTitle && parentFields.step6PageTitle.value}
      </PageTitle>
      <CategoryTitle>
        {parentFields.step6CategoryTitle && parentFields.step6CategoryTitle.value}
      </CategoryTitle>
      <GreyBoxWithBorder>
        <Row>
          <Column $tablet={4} $mobile={12}>
            <TextProperty>
              <P className="title">{parentFields.createShipmentProBillNumber && parentFields.createShipmentProBillNumber.value} </P>
              <H3>{probillNumber}</H3>
            </TextProperty>
            <TextProperty>
              <P className="title">{parentFields.createShipmentExpectedDeliveryDate && parentFields.createShipmentExpectedDeliveryDate.value}</P>
              <H3>{getMonthDayYear(expectedDeliveryDate)}</H3>
            </TextProperty>
          </Column>
          <Column $tablet={4} $mobile={12}>
            <TextProperty>
              <P className="title">{parentFields.createShipmentOrderNumber && parentFields.createShipmentOrderNumber.value}</P>
              <H3>{orderNumber}</H3>
            </TextProperty>
            <TextProperty>
              <P className="title">{parentFields.createShipmentStatus && parentFields.createShipmentStatus.value}</P>
              <H3>{parentFields.createShipmentOrderNumberH3 && parentFields.createShipmentOrderNumberH3.value}</H3>
            </TextProperty>
          </Column>
        </Row>
      </GreyBoxWithBorder>
      <CategoryTitle>{parentFields.step6CategoryTitle2 && parentFields.step6CategoryTitle2.value}</CategoryTitle>
      <Row>
        <Column $tablet={4} $mobile={12}>
          <GreyBoxWithBorder>
            <TextProperty>
              <P className="title">{parentFields.createShipmentServiceLevel && parentFields.createShipmentServiceLevel.value}</P>
              <H3>{serviceLevelDescription || description}</H3>
            </TextProperty>
          </GreyBoxWithBorder>
        </Column>
        <Column $tablet={8} $mobile={12}>
          <GreyBoxWithBorder>
            {(totalAmount ? totalAmount.value === 0 : service.totalAmount.value === 0) ? (
              <TextProperty>
                <H3>{parentFields.rateConfirmationH3 && parentFields.rateConfirmationH3.value}</H3>
              </TextProperty>
            ) : (
                <TextProperty>
                  <P className="title">
                    {parentFields.createShipmentPriceTitle && parentFields.createShipmentPriceTitle.value}
                  </P>
                  {(charges ? charges : service.charges).map((charge, index) => (
                    <H3
                    className="justified"
                    key={index}>
                      <span>{charge.description}</span> <span>{formatMoney(charge.amount.value, protectedAccount)}</span>
                    </H3>
                  ))}
                  <hr />
                  <H2
                  className="justified">
                    {/* eslint-disable-next-line max-len */}
                    <span>{parentFields.createShipmentTotal && parentFields.createShipmentTotal.value}</span> <span>{formatMoney(totalAmount ? totalAmount.value : service.totalAmount.value, protectedAccount)}</span>
                  </H2>
                </TextProperty>
            )}
          </GreyBoxWithBorder>
        </Column>
      </Row>
      <SummaryForm formData={formData} accessorialsList={accessorials} hideCube parentFields={parentFields} />
      <Terms>
        <CategoryTitle>{parentFields.step6TermsAndConditions && parentFields.step6TermsAndConditions.value}</CategoryTitle>
        <P>{parentFields.step6TermsAndConditionsDesc && parentFields.step6TermsAndConditionsDesc.value}</P>
      </Terms>
      {renderBottomNav()}
    </>
  );
};

export default Step6;
