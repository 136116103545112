import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import {
  CloseIcon,
  ModalCloseButton,
  ModalContainer,
  ModalContent,
  ModalContentContainer,
  ModalHeader,
  modalStyle, SideMenuModal,
} from './css';

/**
 * Creates a side menu
 *
 * @param Header - header {Component}
 * @param Content - alert content/body
 * @param isOpen - is modal opened {boolean}
 * @param onClose - close callback {function}
 * @returns {Component}
 */
const SideMenu = ({
  header: Header,
  content: Content,
  isOpen,
  onClose,
}) => (
    <SideMenuModal isOpen={isOpen} style={modalStyle}>
      <ModalContainer>
        <ModalCloseButton onClick={onClose}>
          {t('Close')}
          <CloseIcon />
        </ModalCloseButton>
        <ModalContentContainer>
          <ModalHeader>
            <Header />
          </ModalHeader>
          <ModalContent>
            <Content />
          </ModalContent>
        </ModalContentContainer>
      </ModalContainer>
    </SideMenuModal>
);

SideMenu.propTypes = {
  header: PropTypes.elementType,
  content: PropTypes.elementType,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SideMenu;
