import styled from 'styled-components';
import PrimaryButton from '../../ReactComponent/PrimaryButton';
import { MediaQuery } from '../../../theme/style-helpers';

const CancelButton = styled(PrimaryButton)`
  margin-bottom: 8px;
  
  ${MediaQuery.medium`
    margin-bottom: 0;
    margin-right: 8px;    
  `}
`;
export default CancelButton;
