import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import { H1, P } from '../../theme/typographySitecore';
import Row from '../ReactComponent/Row';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import Column from '../ReactComponent/Column';

export const HeaderContainer = styled(Row)`
  margin: 45px 0;
`;

export const HeaderTitle = styled(H1)`
  margin-bottom: 3rem;
`;

export const FieldContainer = styled(Column)`
  flex-basis: unset !important;
`;

export const ReferenceNumberContainer = styled(Row)`
  padding-top: 34px;
  margin-bottom: 34px;
  background-color: ${colors.LIGHT_GREY};
`;

export const ReferenceNumberSummary = styled(P)`
  padding-top: 24px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 72px;
    flex-wrap: wrap;
`;

export const ResetButton = styled(PrimaryButton)`
  margin-bottom: 16px;
  
  ${MediaQuery.medium`
    margin-bottom: 0;
    margin-right: 16px;    
  `} 
`;
