import { CategoriesStatuses, PickupCategories } from '../constants';

const countPickups = (data) => data.reduce(
  (acc, { status }) => {
    if (CategoriesStatuses[PickupCategories.PendingPickups].includes(status)) ++acc[PickupCategories.PendingPickups];
    if (CategoriesStatuses[PickupCategories.Completed].includes(status)) ++acc[PickupCategories.Completed];
    return acc;
  },
  {
    [PickupCategories.PendingPickups]: 0,
    [PickupCategories.Completed]: 0,
  },
);
export default countPickups;
