import React from 'react';
import PropTypes from 'prop-types';
import chatIcon from '../../assets/icons/Chat.svg';
import phoneIcon from '../../assets/icons/Phone.svg';
import emailIcon from '../../assets/icons/Email.svg';
import {
  GetInTouchTitle, GetInTouchContainer, GetInTouchContactsContainer, GetInTouchFieldsContainer,
  GetInTouchContactLine, GetInTouchContactsTitle, GetInTouchContactsText, GetInTouchContactsIcon,
  EmptyLine, GetInTouchPane, ChatLinkContainer
} from './css';
import freightSalesForceChat from '../ReactComponent/ChatWindow/freightSalesForceChat';
import samedaySalesForceChat from '../ReactComponent/ChatWindow/samedaySalesForceChat';

export const GetInTouchReasonType = {
  CustomerCare: 'customer-care',
  Sales: 'sales',
  MediaInquiries: 'media-inquiries'
};

const GetInTouch = (props) => {
  const {
    cmsFields
  } = props;
  const {
    contactsTruckloadTitle,
    contactsTruckloadPhone,
    contactsTruckloadEmail,
    contactsCommerceSolutionsTitle,
    contactsCommerceSolutionsPhone,
    contactsCommerceSolutionsEmail,
    contactsFlatBedTitle,
    contactsFlatBedEmail,
    contactsSalesInquireiesTitle,
    contactsSalesInquiriesEmail,
    contactsGeneralInquiriesSubmitQuery,
    contactsGeneralInquiriesTitle,
    contactsLogisticsTitle,
    contactsLogisticsPhone,
    contactsLogisticsEmail,
    contactsWebsiteSupportTitle,
    contactsWebsiteSupportEmail,
    contactsBeginChatText,
    BillingInquiriesTitle,
    BillingInquiriesEmail,
    BillingInquiriesPhone
  } = cmsFields;

  const getCMSFieldValue = (field) => (field ? field.value : null);

  const renderGetInTouchContacts = () =>
    <GetInTouchContactsContainer $tablet={12} $mobile={12}>
      {renderCustomerCareContacts()}
    </GetInTouchContactsContainer>;

  const renderChatLine = ({ onClick }) => <ChatLinkContainer onClick={onClick}>
    <GetInTouchContactsIcon $mobile={2}><img src={chatIcon} alt="chat" /></GetInTouchContactsIcon>
    <GetInTouchContactsText $mobile={10}>{getCMSFieldValue(contactsBeginChatText)}</GetInTouchContactsText>
  </ChatLinkContainer >;

  const renderPhoneLine = (field) => <GetInTouchContactLine >
    <GetInTouchContactsIcon $mobile={2}><img src={phoneIcon} alt="phone" /></GetInTouchContactsIcon>
    <GetInTouchContactsText $mobile={10}>{getCMSFieldValue(field)}</GetInTouchContactsText>
  </GetInTouchContactLine >;

  const renderCommerceEmailLine = (field) => <GetInTouchContactLine >
    <GetInTouchContactsIcon $mobile={2}><img src={emailIcon} alt="email" /></GetInTouchContactsIcon>
    <GetInTouchContactsText $mobile={10}>{getCMSFieldValue(field)}</GetInTouchContactsText>
  </GetInTouchContactLine >;

  const renderLTLEmailLine = (field) => <GetInTouchContactLine >
    <GetInTouchContactsIcon $mobile={2}><img src={emailIcon} alt="email" /></GetInTouchContactsIcon>
    <GetInTouchContactsText $mobile={10}>{getCMSFieldValue(field)}</GetInTouchContactsText>
  </GetInTouchContactLine >;

  const renderLogisticsEmailLine = (field) => <GetInTouchContactLine >
    <GetInTouchContactsIcon $mobile={2}><img src={emailIcon} alt="email" /></GetInTouchContactsIcon>
    <GetInTouchContactsText $mobile={10}>{getCMSFieldValue(field)}</GetInTouchContactsText>
  </GetInTouchContactLine >;

  const renderFlatBedEmailLine = (field) => <GetInTouchContactLine >
    <GetInTouchContactsIcon $mobile={2}><img src={emailIcon} alt="email" /></GetInTouchContactsIcon>
    <GetInTouchContactsText $mobile={10}>{getCMSFieldValue(field)}</GetInTouchContactsText>
  </GetInTouchContactLine >;

  const renderSalesInquiriesEmailLine = (field) => <GetInTouchContactLine >
    <GetInTouchContactsIcon $mobile={2}><img src={emailIcon} alt="email" /></GetInTouchContactsIcon>
    <GetInTouchContactsText $mobile={10}>{getCMSFieldValue(field)}</GetInTouchContactsText>
  </GetInTouchContactLine >;

  const renderSubmitQueryEmailLine = (field) => <GetInTouchContactLine >
    <GetInTouchContactsIcon $mobile={2}><img src={emailIcon} alt="email" /></GetInTouchContactsIcon>
    <GetInTouchContactsText $mobile={10}>{getCMSFieldValue(field)}</GetInTouchContactsText>
  </GetInTouchContactLine >;

  const renderWebsiteSupportEmailLine = (field) => <GetInTouchContactLine >
    <GetInTouchContactsIcon $mobile={2}><img src={emailIcon} alt="email" /></GetInTouchContactsIcon>
    <GetInTouchContactsText $mobile={10}>{getCMSFieldValue(field)}</GetInTouchContactsText>
  </GetInTouchContactLine >;

  const renderBillingEnquiriesEmailLine = (field) => <GetInTouchContactLine >
    <GetInTouchContactsIcon $mobile={2}><img src={emailIcon} alt="email" /></GetInTouchContactsIcon>
    <GetInTouchContactsText $mobile={10}>{getCMSFieldValue(field)}</GetInTouchContactsText>
  </GetInTouchContactLine >;

  const renderCustomerCareContacts = () => {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://service.force.com/embeddedservice/5.0/esw.min.js';
      document.body.appendChild(script);
    }

    return <div>
      {/*  Commerce Solutions  */}
      <GetInTouchContactsTitle>{getCMSFieldValue(contactsCommerceSolutionsTitle)}</GetInTouchContactsTitle>
      {renderPhoneLine(contactsCommerceSolutionsPhone)}
      {renderChatLine({ onClick: () => samedaySalesForceChat().showChatWindow() })}
      <a href="mailto:commercesolutions@dayross.com">{renderCommerceEmailLine(contactsCommerceSolutionsEmail)}</a>
      <EmptyLine />

      {/*  LTL/Truckload  */}
      <GetInTouchContactsTitle>{getCMSFieldValue(contactsTruckloadTitle)}</GetInTouchContactsTitle>
      {renderPhoneLine(contactsTruckloadPhone)}
      {renderChatLine({ onClick: () => freightSalesForceChat().showChatWindow() })}
      <a href="mailto:custservice@dayandrossinc.ca">{renderLTLEmailLine(contactsTruckloadEmail)}</a>
      <EmptyLine />

      {/*  Logistics  */}
      <GetInTouchContactsTitle>{getCMSFieldValue(contactsLogisticsTitle)}</GetInTouchContactsTitle>
      {renderPhoneLine(contactsLogisticsPhone)}
      <a href="mailto:Logistics.Atlantic@dayross.com"> {renderLogisticsEmailLine(contactsLogisticsEmail)}</a>
      <EmptyLine />

      {/*  Flat Bed  */}
      <GetInTouchContactsTitle>{getCMSFieldValue(contactsFlatBedTitle)}</GetInTouchContactsTitle>
      {/* {renderPhoneLine(contactsFlatBedPhone)} */}
      <a href="mailto:flatbed@dayandrossinc.ca">{renderFlatBedEmailLine(contactsFlatBedEmail)}</a>
      <EmptyLine />

      {/*  Sales Inquiries  */}
      <GetInTouchContactsTitle>{getCMSFieldValue(contactsSalesInquireiesTitle)}</GetInTouchContactsTitle>
      {/* {renderPhoneLine(contactsFlatBedPhone)} */}
      <a href="mailto:sales@dayross.com">{renderSalesInquiriesEmailLine(contactsSalesInquiriesEmail)}</a>
      <EmptyLine />

      {/*  General Inquiries  */}
      <GetInTouchContactsTitle>{getCMSFieldValue(contactsGeneralInquiriesTitle)}</GetInTouchContactsTitle>
      <a href="/submit-feedback"> {renderSubmitQueryEmailLine(contactsGeneralInquiriesSubmitQuery)}</a>
      <EmptyLine />

      {/*  Website Support  */}
      <GetInTouchContactsTitle>{getCMSFieldValue(contactsWebsiteSupportTitle)}</GetInTouchContactsTitle>
      <a href="mailto:web.support@dayross.com">{renderWebsiteSupportEmailLine(contactsWebsiteSupportEmail)}</a>
      <EmptyLine />

      {/*  Billing Enquiries  */}
      <GetInTouchContactsTitle>{getCMSFieldValue(BillingInquiriesTitle)}</GetInTouchContactsTitle>
      {renderPhoneLine(BillingInquiriesPhone)}
      <a href="mailto:invres@dayandrossinc.ca">{renderBillingEnquiriesEmailLine(BillingInquiriesEmail)}</a>
      <EmptyLine />
    </div>;
  };

  const renderGetInTouchPane = () => <GetInTouchPane >
    <GetInTouchContainer>
      <GetInTouchTitle $mobile={12} $desktop={12} $tablet={12}></GetInTouchTitle>
      {renderGetInTouchContacts()}
      <GetInTouchFieldsContainer $mobile={12} $tablet={6}>
        {/* <ContactUsForm fields={cmsFields} /> */}
      </GetInTouchFieldsContainer>
    </GetInTouchContainer>
  </GetInTouchPane>;

  return renderGetInTouchPane();
};

GetInTouch.propTypes = {
  reason: PropTypes.string,
  cmsFields: PropTypes.object
};

export default GetInTouch;

export function ReasonDataItem(name, reason) {
  this.name = name;
  this.reason = reason;
}

