import {
  getAddress,
  getCity,
  getState,
  getZip,
  getCountry
} from '../../utils/func';
import { digitOnly } from '../../utils/mask';

export const getCompanyIdFromListByName = (companyName, companiesList) =>
  companiesList.filter((c) => c.companyName === companyName).map((c) => c.companyId).toString();
export const formDataToApi = (formData, division, companiesList) => {
  const {
    email,
    companyName,
    name,
    title,
    address,
    addressAdditional,
    phoneNumber,
    phoneNumberExt,
    description,
    oldPassword,
    password,
    changePassword,
    companies,
    disabled,
    rowVersion
  } = formData;
  const formedCompanies = companies.map((c) => (
    {
      accounts: c.accounts.filter((a) => a.accountNumber),
      permissions: c.permissions.filter((p) => p.permissionId),
      companyId: getCompanyIdFromListByName(c.companyId, companiesList)
    }
  )
  );
  const formValueToApi = {
    division,
    rowVersion,
    userEmail: email,
    userPassword: !rowVersion && password,
    oldPassword: rowVersion && oldPassword,
    newPassword: rowVersion && password,
    description,
    isActive: (disabled && disabled.length > 1) ? false : true,
    isForceChangePassword: (changePassword && changePassword.length > 1) ? true : false,
    profileDetails: {
      companyName,
      fullName: name,
      title,
      address1: getAddress(address),
      address2: addressAdditional,
      city: getCity(address),
      provinceCode: getState(address),
      countryCode: getCountry(address),
      postalCode: getZip(address),
      phoneNumber: digitOnly(phoneNumber),
      phoneNumberExt
    },
    companies: formedCompanies
  };
  return formValueToApi;
};

export const formApiToData = (data, companiesList) => {
  const {
    companies,
    description,
    isActive,
    isForceChangePassword,
    profileDetails,
    rowVersion,
    userEmail,
  } = data;
  const {
    companyName,
    fullName,
    title,
    address1,
    address2,
    city,
    provinceCode,
    postalCode,
    countryCode,
    phoneNumber,
    phoneNumberExt
  } = profileDetails;
  const address = `${address1 ? `${address1}, ` : ''}${city ? `${city}, ` : ''}${provinceCode ?
    `${provinceCode} ` : ''}${postalCode ? `${postalCode}, ` : ''}${countryCode ? countryCode : ''}`;
  const addressAdditional = address2;
  const formedCompanies = companies.map((each) => ({
    ...each,
    companyId: companiesList.filter((c) => c.companyId === each.companyId).map((c) => c.companyName).toString(),
    completed: true
  }));
  const formValueToData = {
    rowVersion,
    email: userEmail,
    companyName,
    name: fullName,
    title,
    address,
    addressAdditional,
    phoneNumber,
    phoneNumberExt,
    description,
    changePassword: isForceChangePassword ? ['Force User To Reset Password'] : [''],
    disabled: isActive ? [''] : ['', 'Disable User'],
    companies: formedCompanies
  };

  return formValueToData;
};
