import styled from 'styled-components';
import { colors, MediaQuery, styleHelpers } from '../../theme/style-helpers';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';
import ArrowRightBlack from '../../assets/icons/ArrowRightBlack.svg';
import ArrowLeftOrange from '../../assets/icons/ArrowLeftOrange.svg';

export const FormContainer = styled.div`

    .rw-list-option {
        ${styleHelpers.mobile`
            width: 50%;
        `}

        .rw-select-list-label {
            background-color: ${colors.COOL_GREY};
            border: 1px solid ${colors.BLACK};
            padding: 16px 41px;
            line-height: 1;
            box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.2);
            margin: 0;
            cursor: pointer;
            ${styleHelpers.mobile`
                justify-content: center;
            `}

            .rw-select-list-input {
                display: none;
            }
        }

        &[aria-checked="true"] {
            .rw-select-list-label {
                background-color: ${colors.WHITE};
                box-shadow: none;
            }
        }
    }

    .added-numbers {
        position: relative;
        display: block;
        text-align: right;
        top: -30px;
        font-size: 1.4rem;
    }

    .results-table {
        margin:30px 0;
    }

    .hide-onmobile{
        ${styleHelpers.mobile`
            display: none;
        `}
    }

    .show-onmobile{
        display:none;
        ${styleHelpers.mobile`
            display: block;
        `}
    }
`;

export const InputStrategy = styled.div`
    width: 100%;   
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 58px;
    flex-wrap: wrap;
`;

export const PrimaryButton = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 16px 56px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    order: 0;
    float: none;

    &.min-width {
        min-width: 300px;
    }

    &.max-width-200 {
        max-width: 200px;
    }

    ${MediaQuery.medium`
        width: auto;
        margin-bottom: 0;
        margin-top:24px;
        order: 1;

        &.float-right{
            float: right;
        }
    `}

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowRightWhite});
        background-size: cover;
    }

    &.submit, &.active {
        background-color: ${colors.BLACK};

        .icon {
            background: url(${ArrowRightOrange});
        }

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};

            .icon {
                background: url(${ArrowRightBlack});
            }
        }
    }
`;

export const SecondaryButton = styled.button`
    background-color: ${colors.WHITE};
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    padding: 16px 56px;
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    margin-left: 0;
    width: 100%;
    order: 1;
    cursor: pointer;

    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`
        width: auto;        
        order: 0;
    `}

    .icon-left {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowLeftOrange});
        background-size: cover;
    }

    .icon-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowRightOrange});
        background-size: cover;
    }

    &.no-border {
        border: none;
    }

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    &.hidden {
        display: none;
    }
`;

export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;

export const Error = styled.p`
    font-weight:700;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    color:  ${colors.ERROR};
    margin:20px 0;
`;

export const ExpandButton = styled.button`
    width: 100%;
    background-color: transparent;
    border: none;
    text-align: center;

    &.open {
        >img{
            transform:rotate(180deg);
        }
    }
`;
