import styled from 'styled-components';
import CheckmarkCircledOrange from '../../../assets/icons/CheckmarkCircledOrange.svg';

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const CheckmarkIcon = styled.img.attrs(() => ({ src: CheckmarkCircledOrange }))`
    width: 42px;
    height: 42px;
    margin-bottom: 32px;
`;

export const ErrorList = styled.ol`
    height: 155px;
    overflow-y: auto;

    li {
        padding-left: 15px;
        text-align: left;
        padding-top: 5px;

        &:first-child {
            padding-top: 0;
        }
    }
`;
