/**
 * Converts billingAccounts field into a text to be shown on UI
 * @param {String} company - company name
 * @param {String} accountNumber
 * @returns {string}
 */
export const billingAccountTextFormatter = (details) => {
  if (!details) {
    return null;
  }

  const { company, accountNumber } = details;
  return company ? `${company} - ${accountNumber}` : company;
};

/**
 * Normalize billingAccounts item to accountNumber (to send only number to the server)
 * @param value - billing account object
 * @returns {string}
 */
export const billingAccountToNumber = (value) => (value ? value.accountNumber : value);
