import styled from 'styled-components';
import { colors, styleHelpers, MediaQuery } from '../../../theme/style-helpers';

export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;

export const H2 = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.6rem;
    color: ${colors.BLACK};
    margin-bottom: 3rem;

    &.white {
        color: ${colors.WHITE}
    }

    ${MediaQuery.medium`
        line-height: normal;
        font-size: 2.4rem;
    `}
    &.center {
        text-align: center;
    }
`;

export const AddedItemCard = styled.div`
    ${({ secondary }) => (secondary ? 'background-color: white' : `background-color: ${colors.LIGHT_GREY}`)};
    padding: 32px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 100%;
    margin: 40px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .row {
        width: 100%;
    }

    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        flex-wrap: wrap;

        p {
            margin: 0;
        }

        > :nth-child(even) {
            background-color: ${colors.WHITE};
        }
    }

    .itemServiceDays {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        align-items: center;
        letter-spacing: 0.04em;
        text-transform: capitalize;
    }

    .itemDesc {
        font-size: 16px;
        font-weight: 600;
    }


    img {
        float: left;
    }

    h2, h3 {
        color: ${colors.DARK_GREY};
    }
`;

export const CategoryTitle = styled.div`
    font-size: 2.4rem;
    margin-top: 64px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`;

export const TextProperty = styled.div`
    font-size: 1.4rem;
    margin-bottom: 32px;
    font-weight: 500;

    .title {
        margin-bottom: 10px;
    }

    p:nth-child(2) {
        font-size: 16px;
        font-weight: normal;
        text-transform: capitalize;
    }

    .itemWeekdays {
        font-weight: 600 !important;
    }

    .justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    hr {
        border-color: #000;
        margin: 1.5rem 0 3rem;
    }
`;

export const EditButtonGroup = styled.div`
    text-align: right;
    width: 100%;

    ${styleHelpers.mobile`
        text-align: left;
        order: 1;
    `}
`;

export const LineButton = styled.button`
    -webkit-appearance: none;
    background: none;
    border: none;
    display: inline-flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    cursor: pointer;
    color: ${colors.BLACK};
    text-decoration: none;
    padding: 8px 17px;
`;

export const BlockTitle = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: ${colors.BLACK};
    margin: 32px 0;
`;

export const OriginResultsLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OriginResultsNotesContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 8px;
`;
