import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { ProgressContainer, ProgressItem } from './css';

const FormProgressBar = ({ steps = [], current = 0, disabled = false }) => (
    <ProgressContainer disabled={disabled}>
        <div className="mobile-step">
            Step
{' '}
{current + 1}
{' '}
out of
{' '}
{steps.length}
{' '}
-
{' '}
{steps[current]}
        </div>
        {steps.map((step, index) => (
            <ProgressItem
            key={index}
            className={`${(index === current && !disabled) ? 'active' : ''} ${index < current ? 'done' : ''}`}
            width={steps.length}>
                <span className="step">
{t('Step')}
{' '}
{index + 1}
</span>
                <span className="title">{t(step)}</span>
                <span className="progress-bar"></span>
            </ProgressItem>
        ))}
    </ProgressContainer>
);

FormProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  current: PropTypes.number,
  disabled: PropTypes.bool
};

export default FormProgressBar;
