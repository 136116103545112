import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import { P } from '../../theme/typographySitecore';

export const NavigationContainer = styled.header`
    justify-content: flex-end;
    background-color: ${colors.WHITE};
    box-shadow: 0 1px 0 ${colors.COOL_GREY};
`;

export const NavigationInner = styled.nav`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
    width: 100%;
    max-width: 1204px;
    margin: 0 auto;
`;
export const Breadcrumb = styled.div`
    justify-content: space-between;
    padding: 2rem 1.6rem 0 1.6rem;
    width: 100%;
    max-width: 1204px;
    margin: 0 auto;

    p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
    }
`;

export const NavigationMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const SearchBox = styled.div`
    margin-right: 20px;
    position: relative;
    min-width: 24px;
    min-height: 24px;

    ${MediaQuery.small`
    margin-right: 41px;
    `}
    .search-bar {
        display: none;

        ${MediaQuery.small`
            display: block;
            border: none;
            border-bottom: 1.5px solid ${colors.BLACK};
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: #63666A;
            padding: 6px 24px 6px 6px;
            border-radius: 0;
        `}
    }

    .search-icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
`;

export const UserBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;

    ${MediaQuery.small`
        margin-right: 41px;
        flex-direction: row;
    `}
`;

export const LangBox = styled.div`
    font-size: 1.2rem;
    display: flex;
    margin-right: 20px;

    ${MediaQuery.small`
        margin-right: 41px;
    `}
    .lang-link {
        text-decoration: none;
        color: ${colors.BLACK};
        margin: 0 9px;

        &.active::after {
            content: ' ';
            height: 2px;
            width: 100%;
            background: ${colors.BLACK};
            display: block;
            margin-top: 4px;
        }
    }
`;

export const Icon = styled.img`
    height: 24px;
    width: 24px;
`;

export const DivisionLabel = styled(P)`
    text-align: center;
    margin: 0;
    font-size: 8px;

    ${MediaQuery.small`  
    margin-left: 8px;
    font-size: 14px;
  `}
`;

export const MenuContainer = styled.div`
    .nav-menu {
        display: flex;
        width: 100%;
        max-width: 1204px;
        margin: auto;
        list-style: none;
        padding: 0;
        flex-direction: column;

        ${MediaQuery.small`
            flex-direction: row;
        `}
        .nav-item {
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.6rem;
            padding: 1rem 1.5rem;

            ${MediaQuery.small`
                padding: 2rem 3rem;
            `}
            .nav-link {
                color: ${colors.BLACK};
            }
        }
    }
`;

export const MenuIcon = styled.img`

`;

export const Logo = styled.img`
    max-width: 40px;

    ${MediaQuery.small`
        max-width:60px;
    `}
`;
