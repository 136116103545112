import React from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import {
  reduxForm,
  Field,
  change,
  getFormValues
} from 'redux-form';
import { MDBDropdown } from 'mdbreact';
import Modal from 'react-modal';
import { routes } from '../../utils/constants';
import RenderField from '../ReactComponent/RenderField';
import SortableTable from '../ReactComponent/SortableTable';
import SideBar from '../ReactComponent/SideBar';
import { H2 } from '../../theme/typography';
import Actions from '../../assets/icons/Actions.svg';
import Filter from '../../assets/icons/Filter.svg';
import FilterOrange from '../../assets/icons/FilterOrange.svg';
import Error from '../../assets/icons/Error.svg';
import PrintWhite from '../../assets/icons/PrintWhite.svg';
import PrintGrey from '../../assets/icons/PrintGrey.svg';
import CreateAShipmentWhite from '../../assets/icons/CreateAShipmentWhite.svg';
import CreateAShipmentGrey from '../../assets/icons/CreateAShipmentGrey.svg';
import CreateQuoteFromExistingGrey from '../../assets/icons/CreateQuoteFromExistingGrey.svg';
import CreateQuoteFromExistingWhite from '../../assets/icons/CreateQuoteFromExistingWhite.svg';
import Column from '../ReactComponent/Column';
import PrimaryBtn from '../ReactComponent/PrimaryButton';
import SecondaryBtn from '../ReactComponent/SecondaryButton';
import { openFileInNewTab, homeUrlNoTrailingSlash, formatDate } from '../../utils/func';
import {
  PrimaryButton,
  SecondaryButton,
  ButtonGroup,
  TitleGroup,
  Icon,
  FilterIcon,
  ErrorIcon,
  ModalTitle,
  Close,
  ModalBody,
  ButtonDiv,
  FilterContainer,
  CategoryTitle,
  PageTitle,
  PageDescription,
  Checkboxes
} from './css';
import {
  getQuoteDocument,
  apiGetQuotes,
  getQuotesByDivision,
  apiGetFilteredQuotes,
  apiGetSameDayQuotes
} from '../../services/quotes';
import { Icon as CommonIcon } from '../ReactComponent/Icon';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import { defaultLanguage } from '../../temp/config';
import { DownloadFileNames, downloadReport, formatQuotesReport } from '../../utils/reports';
import DownloadReportModal from '../ReactComponent/DownloadReportModal';
import putReduxAllQuotes from './actions';
import SearchWithFilter from '../ReactComponent/SearchWithFilter';
import NoDataDecorator from '../ReactComponent/SortableTable/NoDataDecorator';
import { ShipmentStatuses, QuoteTypes, Locales } from '../../utils/enums';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';
import mapStatusListToValueObjects from './helpers';
import { isRequired, dateRangeValidate } from '../../utils/validator';

const formName = 'ManageQuotes';

const NUMBER_OF_DAYS = 60;
const initialValues = {
  filterQuoteStatus: [],
  filterQuoteType: [QuoteTypes.Domestic, QuoteTypes.USQuote],
  filterFromDate: formatDate(new Date((new Date()).getTime() - (NUMBER_OF_DAYS * 24 * 60 * 60 * 1000))),
  filterToDate: formatDate(new Date())
};

const Dropdown = ({
  division,
  quoteNumber,
  status,
  // eslint-disable-next-line no-unused-vars
  deleteQuote,
  openBOL
}) => (
  <MDBDropdown className="dropdown" >
    <MDBDropdownToggle className="border-0">
      <CommonIcon iconSrc={Actions} />
    </MDBDropdownToggle>
    <StyledMDBDropdownMenu className="text-white bg-dark" basic>
      <DropdownItem disabled={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview} onClick={() => openBOL()}>
        <DropdownItemIcon iconSrc={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview ? PrintGrey : PrintWhite} />
        {t('Print')}
      </DropdownItem>
      <DropdownItem
        disabled={status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.Expired}
        onClick={() => window.open(`${routes.createShipment}?division=${division}&quoteNumber=${quoteNumber}`)}>
        <DropdownItemIcon
          iconSrc={(status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview) ? CreateAShipmentGrey : CreateAShipmentWhite} />
        {t('CreateAShipment')}
      </DropdownItem>
      <DropdownItem
        disabled={status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.USQuote}
        onClick={() => window.open(`${routes.createQuote}?recreate=true&division=${division}&quoteNumber=${quoteNumber}`)}>
        <DropdownItemIcon
          iconSrc={(status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.USQuote) ?
            CreateQuoteFromExistingGrey :
            CreateQuoteFromExistingWhite} />
        {t('CreateANewQuoteFromThisQuote')}
      </DropdownItem>
    </StyledMDBDropdownMenu>
  </MDBDropdown>
);

const columns = [
  {
    label: '',
    field: 'actions',
    sort: false,
  },
  {
    label: 'QuoteNumber',
    field: 'quoteNumber',
    sort: true,
  },
  {
    label: 'Type',
    field: 'type',
    sort: true,
  },
  {
    label: 'From',
    field: 'from',
    sort: true,
  },
  {
    label: 'To',
    field: 'to',
    sort: true,
  },
  {
    label: 'Pieces',
    field: 'pieces',
    sort: true,
  },
  {
    label: 'Weight',
    field: 'weight',
    sort: true,
  },
  {
    label: 'Status',
    field: 'status',
    sort: true,
  }
];

// eslint-disable-next-line react/no-multi-comp
class ManageQuotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiThirtyDaysFlag: '',
      data: [],
      modalIsOpen: false,
      modalQuoteNumber: '',
      downloadOpen: false,
      filterOpen: false,
      statusList: [],
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    setTimeout(() => { this.fetchAllQuotes(30, false); }, 200);
  }

  openBOL = ({
    division,
    quoteNumber,
    language,
    USQuote
  }) => {
    getQuoteDocument({
      division,
      quoteNumber,
      language,
      USQuote
    })
      .then((res) => {
        if (res.status === 200) {
          openFileInNewTab({
            dataAsBase64: res.data.fileDataAsBase64,
            mimeType: res.data.mimeType,
            fileName: res.data.fileName
          });
        }
      });
  };

  openModal(quoteNumber) {
    this.setState({ modalIsOpen: true });
    this.setState({ modalQuoteNumber: quoteNumber });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ modalQuoteNumber: '' });
  }

  deleteQuote(quoteNumber) {
    this.openModal(quoteNumber);
  }

  handleCreateQuote = (e) => {
    e.preventDefault();
    const createQuoteUrl = `${homeUrlNoTrailingSlash()}${routes.createQuote}`;
    window.location.href = createQuoteUrl;
  };

  // eslint-disable-next-line no-shadow
  fetchAllQuotes = async (NUMBER_OF_DAYS, IsThirtyDays) => {
    const {
      division,
      accountNumbers
    } = this.props;

    getQuotesByDivision({
      division,
      numberOfDaysFromToday: NUMBER_OF_DAYS,
      accountNumbers
    }).then((res) => {
      if (res.status === 200) {
        // eslint-disable-next-line no-shadow
        const { putReduxAllQuotes } = this.props;
        const convertedData = this.convertDataFormat(res.data, division);
        putReduxAllQuotes(convertedData);
        this.getStatusList();
        this.setState({
          data: convertedData,
          apiThirtyDaysFlag: IsThirtyDays
        });
      }
    });
  };

  fetchAllApplyFilterQuotes = async (init, filterAccountNumbers) => {
    const {
      dispatch,
      division,
      accountNumbers,
      formData: {
        filterFromDate,
        filterToDate,
        filterQuoteType = [],
        filterQuoteStatus = []
      }
    } = this.props;
    let selectedQuote = '';
    if (division === 'Sameday') {
      selectedQuote = '';
      await apiGetSameDayQuotes(division,
        filterAccountNumbers ? filterAccountNumbers : accountNumbers,
        filterFromDate,
        filterToDate
      )
        .then((res) => {
          if (res.status === 200) {
            // eslint-disable-next-line no-shadow
            const { putReduxAllQuotes } = this.props;
            const convertedData = this.convertDataFormat(res.data, division);
            putReduxAllQuotes(convertedData);
            if (!init) {
              this.getStatusList();
            }
            dispatch(change(formName, 'searchByKey', ''));
            let filteredData = [];
            if (filterQuoteStatus) {
              filterQuoteStatus.forEach((status) => {
                filteredData = filteredData.concat(convertedData.filter(
                  (s) => s.status.toLowerCase() === status.value.toLowerCase()
                    && filterQuoteType.includes(s.type)
                ));
                filteredData = filteredData.filter((value, index, self) => (self.indexOf(value) === index));
              });
            }
            this.setState({ data: filteredData });
          }
        });
    } else {
      /* eslint-disable no-nested-ternary */
      selectedQuote = filterQuoteType.length > 1
        ? ''
        : (filterQuoteType[0] === QuoteTypes.Domestic
          ? 'DOMESTIC'
          : (filterQuoteType[0] === QuoteTypes.USQuote
            ? 'US'
            : ''));
      /* eslint-enable no-nested-ternary */
      await apiGetFilteredQuotes(division,
        filterAccountNumbers ? filterAccountNumbers : accountNumbers,
        filterFromDate,
        filterToDate,
        selectedQuote
      )
        .then((res) => {
          if (res.status === 200) {
            // eslint-disable-next-line no-shadow
            const { putReduxAllQuotes } = this.props;
            const convertedData = this.convertDataFormat(res.data, division);
            setTimeout(() => { putReduxAllQuotes(convertedData); }, 2000);
            if (!init) {
              this.getStatusList();
            }
            dispatch(change(formName, 'searchByKey', ''));
            let filteredData = [];
            if (filterQuoteStatus) {
              filterQuoteStatus.forEach((status) => {
                filteredData = filteredData.concat(convertedData.filter(
                  (s) => s.status.toLowerCase() === status.value.toLowerCase()
                    && filterQuoteType.includes(s.type)
                ));
                filteredData = filteredData.filter((value, index, self) => (self.indexOf(value) === index));
              });
            }
            this.setState({ data: filteredData });
          }
        });
    }
  };

  getStatusList = () => {
    const {
      allQuotes,
      dispatch
    } = this.props;
    const uniqueStatus = allQuotes.map((s) => s.status).filter((value, index, self) => (self.indexOf(value) === index));
    const uniqueStatusValueObjects = mapStatusListToValueObjects(uniqueStatus);
    this.setState({ statusList: uniqueStatusValueObjects });
    dispatch(change(formName, 'filterQuoteStatus', uniqueStatusValueObjects));
  };

  sixDigitQuote = (quoteNumber) => {
    const quoteValue = quoteNumber.toString().split('');
    const quoteLength = quoteValue.length;
    for (let i = 0; i < 6 - quoteLength; i++) {
      quoteValue.unshift('0');
    }
    return quoteValue.join('');
  };

  convertDataFormat = (data, division) => data.map((each) => {
    const quoteType = (window.location.pathname.toLowerCase().includes(`/${Locales.French.toLowerCase()}/`)) ? 'Taux domestique' : 'Domestic Quote';
    return (
      {
        quoteNumber: this.sixDigitQuote(each.quoteNumber),
        type: (division === 'Sameday') ? quoteType : each.quoteType,
        from: `${[each.addressFrom.city, each.addressFrom.provinceCode, each.addressFrom.countryCode].filter(Boolean).join(', ')}`,
        to: `${[each.addressTo.city, each.addressTo.provinceCode, each.addressTo.countryCode].filter(Boolean).join(', ')}`,
        pieces: each.pieces,
        weight: `${each.weight.value} Lbs`,
        status: each.status,
        actions: <Dropdown
          status={each.status}
          deleteQuote={() => this.deleteQuote(each.quoteNumber)}
          quoteNumber={each.quoteNumber}
          division={division}
          openBOL={() => this.openBOL({
            division,
            quoteNumber: each.quoteNumber,
            language: defaultLanguage,
            USQuote: each.status === ShipmentStatuses.USQuote
          })} // TODO: Replace defaultLanguage w/ the language that is currently selected...
        />
      });
  });

  handleDownloadReport = () => {
    this.setState({ downloadOpen: true });
  };

  closeDownloadReport = () => {
    this.setState({ downloadOpen: false });
  };

  handleDownloadSubmit = async ({
    filterFromDate,
    filterToDate,
    filterAccountNumbers
  }) => {
    const { division } = this.props;
    await apiGetQuotes(division, filterAccountNumbers, filterFromDate, filterToDate).then((res) => {
      if (res.status === 200) {
        downloadReport(DownloadFileNames.QuotesReport, formatQuotesReport, res.data);
      }
    });
  };

  getSuggestions = (value) => {
    const { allQuotes } = this.props;
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    const filteredData = inputLength === 0 ?
      allQuotes :
      allQuotes.filter((item) => (`${item.quoteNumber}`).slice(0, inputLength).toLowerCase() === inputValue.toLowerCase());
    this.setState({ data: filteredData });
  };

  toggleFilter = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    this.setState((prevState) => ({ filterOpen: !prevState.filterOpen }));
  };

  applyFilter = () => {
    const {
      apiThirtyDaysFlag,
      filterAccountNumbers
    } = this.state;
    if (!apiThirtyDaysFlag) {
      setTimeout(() => { this.fetchAllApplyFilterQuotes(true, filterAccountNumbers); }, 200);
    }
    this.setState((prevState) => ({ filterOpen: !prevState.filterOpen }));
  };

  resetFilters = () => {
    const { dispatch } = this.props;
    dispatch(change('ManageQuotes', 'filterQuotesStatus', this.state.initialStatusList));
    dispatch(change('ManageQuotes', 'filterFromDate', initialValues.filterFromDate));
    dispatch(change('ManageQuotes', 'filterToDate', initialValues.filterToDate));
    dispatch(change(formName, 'filterQuoteType', [QuoteTypes.Domestic, QuoteTypes.USQuote]));
    this.getStatusList();
    this.setState({ data: [] }, () => this.applyFilter());
  };

  compare(fVal, secVal) {
    // Use toUpperCase() to ignore character casing
    const quoteNumberA = fVal.quoteNumber;
    const quoteNumberB = secVal.quoteNumber;

    let comparison = 0;
    if (quoteNumberA > quoteNumberB) {
      comparison = -1;
    } else if (quoteNumberA < quoteNumberB) {
      comparison = 1;
    }
    return comparison;
  }

  handleFilterFromDate = (date) => {
    const { dispatch } = this.props;
    const updatedFilterToDate = formatDate(new Date(date).getTime() + (NUMBER_OF_DAYS * 24 * 60 * 60 * 1000));
    dispatch(change(formName, 'filterToDate', updatedFilterToDate));
  };

  render() {
    const {
      rendering: {
        fields: {
          heading,
          description
        }
      },
      accountNumbers
    } = this.props;
    const {
      data,
      modalQuoteNumber,
      modalIsOpen,
      downloadOpen,
      filterOpen,
      statusList
    } = this.state;
    data.sort(this.compare);
    return (
      <>
        <TitleGroup>
          <div className="col">
            <H2>{heading.value}</H2>
            <PageDescription>{description.value}</PageDescription>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={this.closeModal}
            style={ModalBoxStyle}
            contentLabel="Modal">
            <Close onClick={this.closeModal} />
            <ModalBody className="justify-content-center">
              <ErrorIcon src={Error} alt="Error" />
              <ModalTitle>{t('ErrorWithAction')}</ModalTitle>
              <p>{t(`Unable to delete Quote ${modalQuoteNumber}.`)}</p>
              <p>{t('PleaseContactSupportHotline')}</p>
              <ButtonDiv>
                <PrimaryButton onClick={this.closeModal} className="active min-width">
                  {t('ok')}
                </PrimaryButton>
              </ButtonDiv>
            </ModalBody>
          </Modal>
          {downloadOpen && <DownloadReportModal
            onClose={this.closeDownloadReport}
            onDownload={this.handleDownloadSubmit}
            title={t('DownloadQuotesReportTitle')}
            description={t('DownloadQuotesReportDescription')}
            accountNumbers={accountNumbers}
          />}
          <Column $tablet={4} $mobile={12} style={{ textAlign: 'end' }}>
            <PrimaryBtn className="active" iconRightArrow style={{ margin: '16px 0' }} onClick={(e) => this.handleCreateQuote(e)}>
              {t('CreateAQuote')}
            </PrimaryBtn>
            <SecondaryBtn className="active" iconRightArrow onClick={() => this.handleDownloadReport()}>
              {t('DownloadReport')}
            </SecondaryBtn>
          </Column>
        </TitleGroup>
        <SearchWithFilter
          label={t('SeachQuoteText')}
          getSuggestions={this.getSuggestions}
        />
        <FilterContainer>
          <FilterIcon className="col-md-3" onClick={this.toggleFilter}>
            <Icon src={filterOpen ? Filter : FilterOrange} alt="Filter" />
            {t('ApplyFilters')}
          </FilterIcon>
          {
            filterOpen && <SideBar close={this.toggleFilter}>
              <form>
                <PageTitle>{t('ApplyFilters')}</PageTitle>
                <CategoryTitle>{t('FilterByDateCreated')}</CategoryTitle>
                <Field
                  name="filterFromDate"
                  type="date"
                  component={RenderField}
                  className="col"
                  label={t('FromDate')}
                  validate={[isRequired, dateRangeValidate]}
                  onChange={(date) => this.handleFilterFromDate(date)}
                />
                <Field
                  name="filterToDate"
                  type="date"
                  component={RenderField}
                  className="col"
                  label={t('ToDate')}
                  validate={[isRequired, dateRangeValidate]}
                />
                <Checkboxes
                  name="filterQuoteType"
                  type="checkboxes"
                  component={RenderField}
                  multiple
                  data={(this.props.division === 'Sameday') ? [QuoteTypes.Domestic] : [QuoteTypes.Domestic, QuoteTypes.USQuote]}
                  label={t('FilterByQuoteType')}
                />
                <Checkboxes
                  name="filterQuoteStatus"
                  type="checkboxes"
                  component={RenderField}
                  multiple
                  data={statusList}
                  label={t('FilterByQuoteStatus')}
                />
                <ButtonGroup>
                  <SecondaryButton type="button" onClick={() => this.resetFilters()}>
                    <span className="icon"></span>
                    {t('ResetFilters')}
                  </SecondaryButton>
                  <PrimaryButton className="submit" type="button" onClick={() => this.applyFilter()}>
                    <span className="icon"></span>
                    {t('ApplyFilters')}
                  </PrimaryButton>
                </ButtonGroup>
              </form>
            </SideBar>
          }
        </FilterContainer>
        <NoDataDecorator component={SortableTable} data={data} columns={columns} />
      </>
    );
  }
}

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  division: state.profile.division,
  accountNumbers: state.profile.accountNumbers,
  allQuotes: state.user.allQuotes
});

const mdtp = (dispatch) => ({
  putReduxAllQuotes: (allQuotes) => dispatch(putReduxAllQuotes(allQuotes)),
});

ManageQuotes = reduxForm({
  form: formName, // a unique identifier for this form
  initialValues
})(ManageQuotes);

export default connect(mstp, mdtp)(ManageQuotes);
