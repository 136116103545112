import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  PageTitle,
  UpdateDetailsContainer,
  UpdateDateLine,
  UpdateDescription,
  CurrentRateLine,
  ContactPhoneNumber,
  ConverterContainer,
  ConverterTitle,
  EqualsContainer,
  EqualsSign,
  ConverterControlsContainer,
  PageContainer
} from './css';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import NumericInput from '../ReactComponent/NumericInput';
import { isNullOrWhiteSpace } from '../../utils/strings';
import { isNumeric } from '../../utils/numbers';
import { getCurrentLanguage, formatValuesto2Decimals } from '../../utils/func';
import { getMomentByLanguage } from '../../utils/dateTime';

const ExchangeRate = (props) => {
  const [state, setState] = useState({
    usd: 1,
    cad: 0
  });

  const { fields } = props;

  const {
    USDToCAD,
    pageTitle,
    updatedOn,
    currentRateTitle,
    sampleRateTitle,
    customerCarePhoneNumber,
    converterTitle,
    USDCurrency,
    CADCurrency,
    USDToCADUpdateDate
  } = fields;

  const usdToCadExchange = USDToCAD.value;

  const renderTitle = () => <PageTitle>{textFromField(pageTitle)}</PageTitle>;

  const textFromField = (field) => (field ? field.value : null);

  const formatCurrentRateLine = () => `$1.00 ${textFromField(USDCurrency)} = $${textFromField(USDToCAD)} ${textFromField(CADCurrency)}`;

  const renderContactPhoneNumber = () => <ContactPhoneNumber>{textFromField(customerCarePhoneNumber)}</ContactPhoneNumber>;

  const renderUpdateDetails = () => <UpdateDetailsContainer $tablet={6} $mobile={12}>
    <UpdateDateLine>{`${textFromField(updatedOn)} ${getUpdateDate()}`}</UpdateDateLine>
    <UpdateDescription>{textFromField(currentRateTitle)}</UpdateDescription>
    <CurrentRateLine>{formatCurrentRateLine()}</CurrentRateLine>
    <UpdateDescription>
      {`${textFromField(sampleRateTitle)}`}
      {renderContactPhoneNumber()}
    </UpdateDescription>
  </UpdateDetailsContainer>;

  const renderConverter = () => <Column $tablet={6} $mobile={12}>
    <ConverterContainer >
      <ConverterTitle>{textFromField(converterTitle)}</ConverterTitle>
      <ConverterControlsContainer>
        <NumericInput value={state.usd} onChange={(value) => recalcExchange({ usd: value })} label="USD" />
        <EqualsContainer>
          <EqualsSign />
        </EqualsContainer>
        <NumericInput value={state.cad} label="CAD" disabled />
      </ConverterControlsContainer>
    </ConverterContainer>
  </Column>;

  const recalcExchange = ({ usd }) => {
    const exchange = usdToCadExchange || 1;
    if (isNullOrWhiteSpace(usd) || !isNumeric(usd)) {
      setState({
        cad: 0,
        usd
      });
    } else {
      setState({
        cad: formatValuesto2Decimals(usd * exchange),
        usd
      });
    }
  };

  const getUpdateDate = () => {
    const theMoment = getMomentByLanguage(getCurrentLanguage(), USDToCADUpdateDate.value);
    return theMoment;
  };

  return <PageContainer className="container">
    {renderTitle()}
    <Row >
      {renderUpdateDetails()}
      {renderConverter()}
    </Row>
  </PageContainer>;
};

ExchangeRate.propTypes = { fields: PropTypes.object };

export default ExchangeRate;
