import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const IconStyledComponent = styled.div`
    height: 24px;
    width: 24px;
    background: url(${({ $iconSrc }) => $iconSrc}) no-repeat;
    margin: 0 8px;
`;

export const Icon = ({
  iconSrc,
  ...rest
}) => (
  <IconStyledComponent $iconSrc={iconSrc} {...rest}/>
);

Icon.propTypes = {
  iconSrc: PropTypes.string
};

export default Icon;

const ICON_SIZE = '21px';

export const IconButton = styled.button`
    height: ${ICON_SIZE};
    width: ${ICON_SIZE};
    background: url(${({ iconSrc }) => iconSrc});
    border: none;
    cursor: pointer;

    &:hover {
        background: url(${({ iconHoverSrc }) => iconHoverSrc});
    }
`;
