/**
 * Ex:  <PasswordGroup passwordImage={passwordImage} form={'sign-up'}/>
 */

import React, { useState } from 'react';
import { t } from 'i18next';
import { Field } from 'redux-form';
import passwordValidator from 'password-validator';
import Column from '../Column';
import { H2, Span } from '../../../theme/typographySitecore';
import RenderField from '../RenderField';
import { isRequired } from '../../../utils/validator';
import {
  CheckMarkGreenIcon,
  CrossRedIcon,
  Li,
  PasswordStrengthFill,
  PasswordStrengthOutline,
  PasswordStrengthView,
  PixelatedImgStyled,
  Ul
} from './css';
import Row from '../Row';

const pswValidatorsList = [
  'min',
  'uppercase',
  'lowercase',
  'digits',
  'symbols'
];

// eslint-disable-next-line new-cap
const pswValidator = new passwordValidator()
  .is().min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

export const passwordValidate = (value) => (pswValidator.validate(value) ? '' : 'externalMessage');

export const PASSWORD_GROUP_FORM_FIELDS = {
  password: 'password',
  retypePassword: 'retypePassword'
};

const isMatch = (value, allValues) => (allValues.password === allValues.retypePassword ? '' : t('PasswordsDontMatch'));

const PasswordGroup = ({ passwordImage }) => {
  const [passwordValidationErrors, setPasswordValidationErrors] = useState(
    pswValidatorsList
  );
  const [passwordStrengthIndex, setPasswordStrengthIndex] = useState(0);

  const onPasswordChange = ({ currentTarget: { value } }) => {
    const errors = pswValidator.validate(value, { list: true });
    setPasswordStrengthIndex(1 - errors.length / pswValidatorsList.length);
    setPasswordValidationErrors(errors);
  };
  return (
    <Row>
      <Column $tablet={6} $mobile={12}>
        <H2 field={t('SetPassword')} verticalOffset={50}/>
        <Field
          name={PASSWORD_GROUP_FORM_FIELDS.password}
          type="password"
          component={RenderField}
          required
          validate={[isRequired, passwordValidate]}
          label={t('YourPassword')}
          onChange={onPasswordChange}
        />
        <Ul>
          {pswValidatorsList.map((item) => (
            <Li key={item}>
              {passwordValidationErrors.includes(item) ? (
                <CrossRedIcon />
              ) : (
                <CheckMarkGreenIcon />
              )}
              <Span field={t(`Password${item[0].toUpperCase()}${item.slice(1)}`)} />
            </Li>
          ))}
        </Ul>
        <PasswordStrengthView>
          <PasswordStrengthOutline />
          <PasswordStrengthFill $lvl={passwordStrengthIndex} />
        </PasswordStrengthView>

        <Field
          name={PASSWORD_GROUP_FORM_FIELDS.retypePassword}
          type="password"
          component={RenderField}
          required
          validate={[isRequired, isMatch]}
          label={t('RetypePassword')}
        />
      </Column>
      <Column $tablet={6} $mobile={12}>
        <PixelatedImgStyled
          img={passwordImage.value.src}
          fitHeight={376}
          lvl={passwordStrengthIndex}
        />
      </Column>
    </Row>
  );
};

export default PasswordGroup;
