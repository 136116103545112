import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import {
  Placeholder,
  withPlaceholder,
  withSitecoreContext,
  Text,
  RichText,
  getChildPlaceholder,
  getFieldValue
} from '@sitecore-jss/sitecore-jss-react';
import { P } from '../../theme/typography';
import {
  PageTitle,
  LayoutContainer,
  LeftColumn,
  RightColumn,
  NavContainer,
  Icon,
  GoToTop
} from './css';
import { anchor as anchorUtil } from '../../utils/func';
import ResetOrange from '../../assets/icons/ArrowUpOrange.svg';
import utils from '../../utils/utils';
import { Img } from '../ReactComponent/Image';

const NewsCarousel = 'NewsCarousel';

class StaticPageSublayout extends PureComponent {
  scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };

  getSectionData = (section) => {
    const {
      uid,
      componentName
    } = section;

    switch (componentName) {
      case NewsCarousel: {
        if (utils.isDisconnected) {
          return {
            heading: getFieldValue(section, 'heading'),
            anchor: getFieldValue(section, 'anchor'),
            uid
          };
        }
        const { fields: { data: { datasourceItem } } } = section;
        if (!datasourceItem) {
          return null;
        }

        const {
          heading,
          anchor
        } = datasourceItem;
        return {
          heading: heading ? heading.value : null,
          anchor: anchor ? anchor.value : null,
          uid
        };
      }
      default:
        return {
          heading: getFieldValue(section, 'heading'),
          anchor: getFieldValue(section, 'anchor'),
          uid
        };
    }
  };

  render() {
    const {
      sitecoreContext,
      rendering,
      sitecoreContext: { route: { fields } }
    } = this.props;
    const isEditing = sitecoreContext && sitecoreContext.pageEditing;
    const showNavigation = fields.showNavigation && fields.showNavigation.value;
    const sectionsWithHeading = showNavigation ?
      getChildPlaceholder(rendering, 'jss-staticpage-sections')
        .map((section) => this.getSectionData(section))
        .filter((sectionData) => sectionData && sectionData.heading && sectionData.anchor)
        .map((sectionData) => ({
          heading: sectionData.heading,
          anchor: `#${anchorUtil(sectionData.anchor)}`,
          id: sectionData.uid,
        })) :
      null;

    return (
      <>
        <PageTitle>
          {isEditing || (fields.heading && <Text field={fields.heading}/>)}
        </PageTitle>
        {isEditing || (fields.description && <P><RichText field={fields.description}/></P>)}
        {isEditing || (fields.heroImage && fields.heroImage.value.src && <Img field={fields.heroImage}/>)}
        <LayoutContainer className={showNavigation ? 'with-navigation' : ''}>
          {showNavigation ? (
            <>
              <LeftColumn>
                <NavContainer>
                  {
                    sectionsWithHeading.map((section) => {
                      const className = typeof window !== 'undefined' && section.anchor === window.location.hash ? 'active' : '';
                      return <a key={section.id} href={section.anchor} className={className}>
                        {section.heading}
                      </a>;
                    })
                  }
                </NavContainer>
              </LeftColumn>
              <RightColumn>
                <Placeholder name="jss-staticpage-sections" rendering={rendering} showNavigation/>
              </RightColumn>
            </>) : (
            <Placeholder name="jss-staticpage-sections" rendering={rendering}/>)}
          <GoToTop>
            { // eslint-disable-next-line no-script-url
              <a href="javascript:void(0)" onClick={this.scrollToTop}>
                <Icon className="icon-up" src={ResetOrange} alt="gototop"/>
                {t('GoToTop')}
              </a>
            }
          </GoToTop>
        </LayoutContainer>
      </>);
  };
}

StaticPageSublayout.propTypes = {
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({
      fields: PropTypes.shape({
        showNavigation: PropTypes.bool,
        heading: PropTypes.string,
        description: PropTypes.string,
        heroImage: PropTypes.shape({
          value: PropTypes.string,
        }),
      }),
    }),
    pageEditing: PropTypes.bool,
  }),
  rendering: PropTypes.object,
};

const staticPageWithPlaceholderInjected = withPlaceholder({
  placeholder: 'jss-staticpage-sections',
  prop: 'sections',
})(StaticPageSublayout);

const staticPageWithPlaceholderAndSitecoreContext = withSitecoreContext()(
  staticPageWithPlaceholderInjected
);

export default staticPageWithPlaceholderAndSitecoreContext;
