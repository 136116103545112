import styled from 'styled-components';
import CheckmarkCircledOrange from '../../../assets/icons/CheckmarkCircledOrange.svg';
import { MediaQuery } from '../../../theme/style-helpers';
import SecondaryButton from '../../ReactComponent/SecondaryButton';

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 72px;
`;

export const SecondButton = styled(SecondaryButton)`
    width: 70%;
    padding: 10px;
    ${MediaQuery.medium`      
      width: 265px;  
    `}
`;

export const OkayButton = styled(SecondaryButton)`
    width: 30%;
    padding: 10px;
    ${MediaQuery.medium`      
    `}
`;

export const CheckmarkIcon = styled.img.attrs(() => ({ src: CheckmarkCircledOrange }))`
    width: 42px;
    height: 42px;
    margin-bottom: 32px;
`;
