import styled from 'styled-components';

export const ComponentContainer = styled.div`
    position: relative;
`;
export const AddressContainer = styled.div`
    background-color: #fafafa;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2);
    border: 1px solid #ccc;
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 50px;
    left: 0;
`;
export const AddressSuggestion = styled.div`
    padding: 7px 10px;
    font-size: 1.25rem;
    line-height: 2.1rem;

    &:hover {
        background-color: #ccc;
    }
`;
export const AddressDiv = styled.div`
    margin-top: -48px
`;
