import styled from 'styled-components';
import Column from '../Column';

export const FormFieldTooltipContainer = styled(Column)`
  padding-top: 38px;
`;

export const CheckboxFieldTooltipContainer = styled.div`
  padding-top: 24px;
`;
