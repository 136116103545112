import { Field } from 'redux-form';
import styled from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';
import { P } from '../../../theme/typographySitecore';
import CheckmarkCircledGreen from '../../../assets/icons/CheckmarkCircledGreen.svg';
import FieldFileInput from '../FileUploadButton';

export const UploadDocumentsContainer = styled.div`
    border-radius: 6px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
`;

export const UploadDocumentsRowContainer = styled.div`
    padding: 0 16px;
    background-color: ${colors.LIGHT_GREY};
`;
export const FieldGroup = styled.div`
    margin: 24px 0 8px;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .relative {
        position: relative;
    }

`;
export const UploadDocumentsRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    border-bottom: 1px solid ${colors.COOL_GREY}
    flex-direction: column;
    align-items: flex-start;

    ${MediaQuery.medium`
    flex-direction: row;
    align-items: center;
  `}
`;

export const RowLabel = styled(P)`
    margin: 0;

    ${MediaQuery.medium`
    margin: 15px 0;
  `}
`;

export const UploadButton = styled(Field)`
    width: unset;
`;

export const FileNameContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 0;
    padding-right: 8px;

    ${MediaQuery.medium`
    flex-direction: row;
    padding-right: initial;
  `}
`;

export const CheckmarkIcon = styled.img.attrs(() => ({ src: CheckmarkCircledGreen }))`
`;

export const FileUploadLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: ${colors.DARK_GREY};
    margin: 0 8px;

    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${MediaQuery.medium`
      margin-right: 8px;
      margin-bottom: 0;
      
      max-width: initial;
      white-space: initial;
      overflow: initial;
      text-overflow: initial;
    `}
`;

export const FileInputField = styled(FieldFileInput)`
    padding: 0;

    div {
        flex-direction: row-reverse;
        padding: 0;

        label {
            margin-left: 8px;

            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    ${MediaQuery.medium`
    padding: initial;

    div {
      flex-direction: initial;
      padding: initial;
      
      label {      
        margin-left: initial;
        
        max-width: initial;
        white-space: initial;
        overflow: initial;
        text-overflow: initial;
      }
    }
  `}
`;
