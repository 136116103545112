import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { NavLink, Route, withRouter } from 'react-router-dom';
import {
  NavigationContainer,
  NavigationInner,
  Icon,
  MenuIcon,
  Logo,
  UserBox,
  NavigationMenu,
  LangBox,
  SearchBox,
  DivisionLabel,
  Breadcrumb
} from './css';
import logoColourOnWhite from '../../assets/logo/logoColourOnWhite.svg';
import Menu from '../../assets/icons/Menu_flat.svg';
import LoggedOut from '../../assets/icons/LoggedOut.svg';
import LoggedIn from '../../assets/icons/LoggedIn.svg';
import Search from '../../assets/icons/Search.svg';
import SecondaryNavigation from '../SecondaryNavigation';
import utils from '../../utils/utils';
import { homeUrl, getCurrentLanguage } from '../../utils/func';
import { TOGGLE_ACCOUNT_SIDE_MENU } from '../ReactComponent/AccountSideMenu/actions';
import { routes } from '../../utils/constants';
import { Divisions, Locales } from '../../utils/enums';
import readCookie from '../../utils/cookies';
import putCurrentLanguage from './actions';
import mockSecNav from '../../mockups/secondaryNavigation';

class Navigation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      homeUrl: '/',
      keyword: '',
      profileIconImage: LoggedOut,
    };
  }

  toggleMenu = () => this.setState((prevState) => ({ expanded: !prevState.expanded }));

  removeLocaleFromPathAndSearch = (pathAndSearch) => {
    const pathAndSearchArr = pathAndSearch.split('/');

    // Check to see if locale is at index 1, since "" is at index 0, when we split
    // a string on "/" and the string starts w/ "/", like int the case of pathAndSearch...
    if (pathAndSearchArr[1] !== Locales.English &&
      pathAndSearchArr[1] !== Locales.French) {
      // No expected locale found. Return pathAndSearch...
      return pathAndSearch;
    }

    // Expected locale found. Remove locale from pathAndSearch...
    pathAndSearchArr.shift(); // Remove ""
    pathAndSearchArr.shift(); // Remove locale string...

    return `/${pathAndSearchArr.join('/')}`;
  };

  handleAccountSideMenuClick = () => {
    const {
      isAuthenticated,
      //      dispatch
    } = this.props;

    if (!isAuthenticated) {
      window.location.href = routes.login;
      return;
    }
    this.props.toggleAccountSideMenu();
    // dispatch({ type: TOGGLE_ACCOUNT_SIDE_MENU })
  };

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.setState({ profileIconImage: LoggedIn });
    }
    this.setState({ homeUrl: homeUrl() });
    const CurrentLanguage = getCurrentLanguage();
    this.props.putCurrentLanguage(CurrentLanguage);
  }

  getLocaleFromCookie = () => (readCookie('internet#lang'));

  getDivisionString = (division) => {
    if (division === Divisions.Freight.name) {
      return t('LtlTruckload');
    }
    if (division === Divisions.Sameday.name) {
      return t('CommerceSolutions');
    }
    return '';
  };

  processBreadCrumbs = (currentPageUrl) => {
    const { fields } = this.props;
    const breadcrumbsObject = {};

    const secondaryNavigationData = utils.isDisconnected ? mockSecNav : fields;

    if (secondaryNavigationData.data?.item) {
      const breadcrumbArrToObject = secondaryNavigationData.data.item.children.reduce((newObj, item) => {
        if (item?.children) {
          let translatedFirstLevelNavSectionWithChildren;
          try {
            // Will resolve, when connected to sitecore...
            translatedFirstLevelNavSectionWithChildren = item.title.value;
          } catch (e) {
            // Allow rendering, when disconnected from sitecore...
            translatedFirstLevelNavSectionWithChildren = item.name;
          }

          newObj[translatedFirstLevelNavSectionWithChildren] = item.children.map((secondLevelItem) => {
            if (secondLevelItem?.link?.jss) {
              return {
                Name: secondLevelItem.link.jss.value.text,
                Href: secondLevelItem.link.jss.value.href
              };
            }
            return secondLevelItem.children.map((thirdLevelItem) => {
              if (thirdLevelItem?.link?.jss) {
                return {
                  Name: thirdLevelItem.link.jss.value.text,
                  Href: thirdLevelItem.link.jss.value.href
                };
              }
              return null;
            });
          });
        } else {
          let translatedFirstLevelNavSection;
          try {
            // Will resolve, when connected to sitecore...
            translatedFirstLevelNavSection = item.link.jss.value.text;
          } catch (e) {
            // Allow rendering, when disconnected from sitecore...
            translatedFirstLevelNavSection = item.name;
          }

          newObj[translatedFirstLevelNavSection] = [{
            Name: item.link.jss.value.text,
            Href: item.link.jss.value.href
          }];
        }
        return newObj;
      }, []);

      Object.keys(breadcrumbArrToObject).forEach((d) => {
        breadcrumbArrToObject[d].forEach((itemData) => {
          if (itemData.Href && itemData.Href.toLocaleLowerCase().indexOf(currentPageUrl.toLocaleLowerCase()) > 0) {
            breadcrumbsObject[d] = itemData;
          }
        });
      });
    }
    return breadcrumbsObject;
  };

  searchHandler = (event) => {
    this.setState({
      keyword: event.target.value
    });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    const { keyword } = this.state;
    window.location.href = routes.search + (keyword ? `?q=${keyword}` : '');
  };

  handleLanguageSubmit = (e) => {
    this.props.putCurrentLanguage(e.target.innerHTML);
  };

  render() {
    // eslint-disable-next-line
    const Breadcrumbs = () =>
      <Route
        path="*"
        render={
          (props) => {
            let parts = props.location.pathname.split('/');
            const place = parts[parts.length - 1];
            // eslint-disable-next-line
            parts = this.processBreadCrumbs(place);
            return (
              <div>
                {
                  Object.entries(parts).length > 0 &&
                  <Breadcrumb>
                    <p>
                      {
                        Object.keys(parts).map((crumb) => (
                          crumb !== parts[crumb].Name ? `${crumb}/${parts[crumb].Name}` : '')
                        )
                      }
                    </p>
                  </Breadcrumb>
                }
              </div>
            );
          }
        }/>;

    const {
      fields,
      location,
      division
    } = this.props;

    const secondaryNavigationData = utils.isDisconnected ? mockSecNav : fields;

    const {
      // eslint-disable-next-line
      homeUrl,
      keyword,
      profileIconImage
    } = this.state;
    const localeFromCookie = this.getLocaleFromCookie();

    const pathAndSearch = location.pathname + location.search;

    return (
      <div>
        <NavigationContainer>
          <NavigationInner>
            <a href={homeUrl}><Logo src={logoColourOnWhite} alt="logo"/></a>
            <NavigationMenu>
              <SearchBox>
                <form onSubmit={this.handleSearchSubmit}>
                  <input className="search-bar" value={keyword} onChange={this.searchHandler}/>
                  <a onClick={(e) => this.handleSearchSubmit(e)}><Icon src={Search} className="search-icon"/></a>
                </form>
              </SearchBox>
              <LangBox>
                <NavLink
                  to={`/${Locales.English}${this.removeLocaleFromPathAndSearch(pathAndSearch)}`}
                  isActive={() => localeFromCookie === null /* English is defaulted so show as active */ || localeFromCookie === Locales.English}
                  onClick={(e) => this.handleLanguageSubmit(e)}
                  className="lang-link">
                  EN
                </NavLink>
                <NavLink
                  to={`/${Locales.French}${this.removeLocaleFromPathAndSearch(pathAndSearch)}`}
                  isActive={() => localeFromCookie === Locales.French}
                  onClick={(e) => this.handleLanguageSubmit(e)}
                  className="lang-link">
                  FR
                </NavLink>
              </LangBox>
              <UserBox onClick={this.handleAccountSideMenuClick}>
                <Icon src={profileIconImage}/>
                <DivisionLabel field={this.getDivisionString(division)}/>
              </UserBox>
              <MenuIcon src={Menu} onClick={this.toggleMenu}/>
            </NavigationMenu>
          </NavigationInner>
          <SecondaryNavigation fields={secondaryNavigationData} isDisplayed={this.state.expanded}/>

        </NavigationContainer>
        <Breadcrumbs/>
      </div>
    );
  }
}

const mdtp = (dispatch) => ({
  toggleAccountSideMenu: () => dispatch({ type: TOGGLE_ACCOUNT_SIDE_MENU }),
  putCurrentLanguage: (currentLanguage) => dispatch(putCurrentLanguage(currentLanguage)),
});

const mstp = (state) => ({
  isAuthenticated: state.profile.isAuthenticated,
  division: state.profile.division,
  currentLanguage: state.profile.currentLanguage
});

export default connect(mstp, mdtp)(withRouter(Navigation));
