import React from 'react';
import { Text, RichText, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { P } from '../../theme/typography';
import {
  SectionTitle, Section,
} from './css';
import { anchor } from '../../utils/func';
import utils from '../../utils/utils';

const ArticleSectionRichText = ({ fields }) => {
  let textAlignItem = null;

  if (fields.textAlign) {
    textAlignItem = utils.isDisconnected ? fields.textAlign[0] : fields.textAlign;
  }
  const className = textAlignItem ? getFieldValue(textAlignItem, 'className') : '';

  const id = fields.anchor && fields.anchor.value ? anchor(fields.anchor.value) : '';

  return (<Section id={id} className={`static-section ${className}`}>
    {fields.heading && fields.heading.value
      && <SectionTitle>
        <Text field={fields.heading}/>
      </SectionTitle>}
    {fields.content && fields.content.value && <P><RichText field={fields.content}/></P>}
  </Section>);
};

export default ArticleSectionRichText;
