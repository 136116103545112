import { BillingAccountType } from '../../utils/enums';

export const formDataToApi = (formData, division) => {
  const {
    companyName,
    description,
    accountList,
    permissions,
    disabled,
    rowVersion,
    password,
    changePassword,
    email
  } = formData;

  const apiAccountList = accountList.map((each) => ({
    accountNumber: each.accountNumber,
    accountType: each.accountType === BillingAccountType.Billing ? 'BA' : 'PA',
  }));

  const permissionsList = permissions
    ? Object.values(permissions)
      .filter((perm) => perm.selected.length > 1)
      .map((each) => {
        const { selected } = each;
        return {
          permissionId: selected[1].code,
          permissionName: selected[1].name
        };
      })
    : '';

  const formValueToApi = {
    division,
    rowVersion,
    userEmail: email,
    userPassword: password,
    companyName,
    description,
    isActive: (disabled && disabled.length > 1) ? false : true,
    isForceChangePassword: (changePassword && changePassword.length > 1) ? true : false,
    accounts: [...apiAccountList],
    permissions: [...permissionsList]
  };

  return formValueToApi;
};

export const formApiToData = (data, permissionsList) => {
  const {
    division,
    companyId,
    companyName,
    description,
    isActive,
    rowVersion,
    permissions,
    accounts,
  } = data;

  const accountList = accounts.map((each) => ({
    accountNumber: each.accountNumber,
    accountType: each.accountType === 'BA' ? BillingAccountType.Billing : BillingAccountType.Protected,
    completed: true
  }));
  const selectedPermissions = permissions
    ? permissionsList
      .filter((perm) => permissions.map((p) => p.permissionId).includes(perm.permissionId))
      .map((each) => ({
        [each.permissionName]: {
          selected: ['', {
            name: each.permissionName,
            desc: each.description,
            code: each.permissionId
          }]
        }
      })).reduce((curr, accum) => ({ ...accum, ...curr }), {})
    : '';
  const formValueToData = {
    companyName,
    description,
    rowVersion,
    companyId,
    division,
    disabled: isActive ? [''] : ['', 'Disable Company'],
    accountList,
    permissions: selectedPermissions
  };

  return formValueToData;
};
