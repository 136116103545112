import styled from 'styled-components';
import { colors, MediaQuery, styleHelpers } from '../../theme/style-helpers';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';
import ArrowRightBlack from '../../assets/icons/ArrowRightBlack.svg';

export const FormContainer = styled.div`
    width: 100%;
    padding: 72px 0;
    margin: 0 auto;

    .disabled-dropdown {
        pointer-events: none;
    }


    h2 {
        margin-top: 30px
    }
`;

export const AddItemContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
    padding: 18px 8px;
    margin-bottom: 40px;

    &.with-background {
        background-color: ${colors.WHITE};
        border-bottom: 1px solid ${colors.COOL_GREY};

        p {
            margin: 0;
        }
    }
`;

export const AddItemLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: ${colors.DARK_GREY};
    margin-bottom: 0;
    margin-left: 8px;
`;

export const PageTitle = styled.div`
    font-weight: 300;
    font-size: 4.8rem;
    margin-bottom: 32px;

    &.justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const InputUnit = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-top: 45px;
`;

export const LineButton = styled.button`
    -webkit-appearance: none;
    background: none;
    border: none;
    display: inline-flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    cursor: pointer;
    color: ${colors.BLACK};
    text-decoration: none;
    padding: 8px 17px;
`;

export const ContentBoxLightGrey = styled.div`
    background-color: ${colors.LIGHT_GREY};
    padding: 32px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 100%;
    margin: 40px 0;
    position: relative;
    flex-wrap: wrap;

    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        flex-wrap: wrap;

        p {
            margin: 0;
        }

        > :nth-child(even) {
            background-color: ${colors.WHITE};
        }
    }

    h2, h3 {
        color: ${colors.DARK_GREY};
    }
`;

export const MeasurementTab = styled.div`

    font-weight: 500;
    font-size: 1.6rem;

    margin-top: 10px;
    margin-bottom: 58px;

    > div {
        margin: 0;
    }

    .alignDate {
        margin-top: 0 !important;
    }


    ${styleHelpers.mobile`
        width: 100%;

        > div {
            margin-bottom: 0;
        }
    `}
    .rw-list-option {
        ${styleHelpers.mobile`
            width: 50%;
        `}
        .rw-select-list-label {
            background-color: ${colors.COOL_GREY};
            border: 1px solid ${colors.BLACK};
            padding: 16px 41px;
            line-height: 1;
            box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.2);
            margin: 0;
            cursor: pointer;

            ${styleHelpers.mobile`
                justify-content: center;
            `}
            .rw-select-list-input {
                display: none;
            }
        }

        &[aria-checked="true"] {
            .rw-select-list-label {
                background-color: ${colors.WHITE};
                box-shadow: none;
            }
        }
    }
`;

export const FileUploadContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding: 18px 8px;
    order: 99;

    &.with-dot {
        margin-bottom: 57px;
        padding: 0;

        &:after {
            content: '';
            background-image: linear-gradient(to right, ${colors.DARK_GREY} 33%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 6px 2px;
            background-repeat: repeat-x;
            height: 2px;
            flex: 1 1 auto;
            width: auto;
            max-width: none;
        }
    }

    &.with-background {
        background-color: ${colors.WHITE};
        border-bottom: 1px solid ${colors.COOL_GREY};

        p {
            margin: 0;
        }
    }
`;

export const FileUpload = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    .hidden-button {
        display: none;
    }
`;

export const FileUploadLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: ${colors.DARK_GREY};
    margin-bottom: 0;
    margin-left: 8px;
    margin-right: 8px;
`;
export const ItemContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const SecondaryButton = styled.button`
    background-color: ${colors.WHITE};
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    padding: 16px 56px;
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    margin-left: 0;
    width: 100%;
    order: 1;
    cursor: pointer;

    &.min-width {
        min-width: 172px;
    }


    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        order: 0;
    `}
    .icon-left {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background-size: cover;
    }

    .icon-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background-size: cover;
    }

    &.no-border {
        border: none;
    }

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    &.hidden {
        display: none;
    }

`;

export const PrimaryButton = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 16px 56px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    order: 0;
    float: none;

    &.active {
        background-color: ${colors.BLACK};
    }

    &.min-width {
        min-width: 172px;
    }

    &.max-width-200 {
        max-width: 172px;
    }

    img {
        position: absolute;
        left: 150px
    }

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        margin-bottom: 0;
        order: 1;

        &.float-right{
            float: right;
        }
    `}
    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowRightWhite});
        background-size: cover;
    }

    &.submit, &.active {
        background-color: ${colors.BLACK};

        .icon {
            background: url(${ArrowRightOrange});
        }

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};

            .icon {
                background: url(${ArrowRightBlack});
            }
        }
    }
`;
export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 72px;
    flex-wrap: wrap;
`;

export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;
