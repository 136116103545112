import React from 'react';
import { t } from 'i18next';
import {
  CardContainer,
  ContentContainer,
  ContentUserEmail,
  HeaderContainer,
  HeaderSummary,
  HeaderTitle,
  LoggedInIcon,
  SwitchAccountsButton
} from './css';

const Card = ({
  // eslint-disable-next-line no-unused-vars
  title, description, userEmail, isLoggedIn, onSwitch, borderRight
}) => {
  // eslint-disable-next-line no-shadow
  const renderHeader = ({ title, description }) => (
      <HeaderContainer>
        <HeaderTitle field={title}/>
        <HeaderSummary field={description}/>
      </HeaderContainer>
  );

  // eslint-disable-next-line no-shadow
  const renderContent = ({ userEmail, isLoggedIn }) => (
      <ContentContainer>

        { isLoggedIn ?
          (
            <>
             <LoggedInIcon/>
             { userEmail && <ContentUserEmail field={userEmail}/> }
            </>
          ) :
          (
            <>
              <SwitchAccountsButton onClick={onSwitch}>
                {t('SwitchAccounts')}
              </SwitchAccountsButton>
            </>
          )}

      </ContentContainer>
  );

  return (
    <CardContainer>
      {renderHeader({ title, description })}
      {renderContent({ userEmail, isLoggedIn })}
    </CardContainer>
  );
};

export default Card;
