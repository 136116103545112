import React from 'react';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { t } from 'i18next';
import { H2 } from '../../../theme/typography';
import PickupSummaryIcon from '../../../assets/icons/PickupSummary.svg';
import LoadingIndicator from '../../../assets/image/LoadingIndicator.gif';
import {
  Tile,
  InlineBlock,
  CenteredMessage,
  Icon,
} from '../css';
import { Stages, PickupCategories } from '../constants';
import {
  apiGetPickups,
} from '../api';
import ResultCategory from '../ReactComponents/ResultCategory';
import countPickups from './utils';

class PickupSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: Stages.Loading,
      data: [],
      error: '',
    };
  }

  componentDidMount() {
    const { division, accountNumbers } = this.props;

    apiGetPickups(this.onSetData, accountNumbers, this.onError, division);
  }

  onSetData = (data) => {
    if (data && data.length > 0) {
      this.setState({ data: countPickups(data), stage: Stages.DataLoaded });
    } else {
      this.setState({ data: [], stage: Stages.NoResults });
    }
  };

  onError = () => {
    this.setState({ error: `${t('Request returned an error')}`, stage: Stages.Error });
  };

  render() {
    const { stage, error, data } = this.state;

    const { fields } = this.props;

    const { managePickupsLink, noResultsText, loadingText } = fields;
    const hasManagePickupsLink = managePickupsLink && managePickupsLink.value && managePickupsLink.value.href && managePickupsLink.value.text;

    return (<Tile>
            {stage === Stages.Loading &&
                <CenteredMessage className="padding-70">
                    <Icon src={LoadingIndicator} alt="no results" className="icon-100"/>
                    <Text tag="div" className="bold-message" field={loadingText}/>
                </CenteredMessage>}
            {stage === Stages.DataLoaded && <>
                {fields.heading && fields.heading.value &&
                    <InlineBlock>
                    <H2>
                        <Text field={fields.heading}/>
                    </H2>
                    </InlineBlock>}
                <div className="margin-20">
                    <InlineBlock className="pickup-summary-block">
                        <InlineBlock>
                            <ResultCategory prefix={t('PendingPickups')} count={data[PickupCategories.PendingPickups]}/>
                        </InlineBlock>
                        <InlineBlock>
                            <ResultCategory prefix={t('PickedUp')} count={data[PickupCategories.Completed]}/>
                        </InlineBlock>
                    </InlineBlock>
                </div>
                <InlineBlock className="margin-20">
                    {hasManagePickupsLink &&
                        <Link field={managePickupsLink} className="secondaryButton no-border">
                            <span className="icon"></span>{managePickupsLink.value.text}
                        </Link>}
                </InlineBlock>
                </>}
            {stage === Stages.NoResults && <CenteredMessage className="padding-40">
                <Icon src={PickupSummaryIcon} alt="no results" className="icon-100"/>
                <Text tag="div" className="bold-message" field={noResultsText}/>
                {hasManagePickupsLink &&
                    <InlineBlock className="margin-20">
                    <Link field={managePickupsLink} className="primaryButton submit">
                        <span className="icon"></span>{managePickupsLink.value.text}
                    </Link>
                    </InlineBlock>}
                </CenteredMessage>}
            {stage === Stages.Error && <CenteredMessage>{error}</CenteredMessage>}
        </Tile>);
  }
}

export default PickupSummary;
