import React from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import RenderField from '../../ReactComponent/RenderField';
import { isEmail, isRequired, maxChar50 } from '../../../utils/validator';
import {
  ActionsContainer,
  AddressFitColumn, CardDescription,
  FormContainer,
  RadioColumns
} from '../css';
import { H1, H2, H3 } from '../../../theme/typographySitecore';
import { Button } from './common';
import {
  FORM_NAME,
  cards,
  payments,
  Currency,
} from '../constants';
import Card from '../../ReactComponent/Card';
import { AddressBlock } from '../../CreatePickup/css';
import { AddressBookEntry } from '../../ReactComponent/AddressBookEntry';
import { AddressTypes } from '../../../utils/enums';

let Step1 = ({
  onNext,
  pristine,
  invalid,
  data,
  fields
}) => {
  if (!data) return null;

  return (
    <FormContainer>
      <form onSubmit={onNext}>
        <H1 field={t('SctnQuoteAddressDetails')} verticalOffset={40} />
        <H2 field={t('SctnQuotePickService')} verticalOffset={40} />
        <Row>
          {cards(fields).map((card) => (
            <ServiceCard {...card} key={card.name} />
          ))}
        </Row>
        <H3
          field={fields.dedicatedLogistics}
          verticalOffset={40}
          center
        />
        <Row>
          <AddressFitColumn $tablet={6} $mobile={12}>
            <H2 field={t('YourDetails')} />
            <Field
              name="name"
              type="text"
              component={RenderField}
              required
              validate={[isRequired, maxChar50]}
              label={t('Name')}
            />
            <Field
              name="email"
              type="text"
              component={RenderField}
              required
              validate={[isRequired, isEmail]}
              label={t('EmailAddress')}
            />
          </AddressFitColumn>
        </Row>

        <Row>
          <AddressBlock className="col-md-6">
            <H2 field={t('ShipperDetails')} />
            <AddressBookEntry
              contactType={AddressTypes.SHIPPER}
              formName={FORM_NAME}
              isPhoneNumberRequired={false}
              validator={addressValidate}
              noAddressSuggestion
            />
          </AddressBlock>
          <AddressBlock className="col-md-6">
            <H2 field={t('ConsigneeDetails')} />
            <AddressBookEntry
              contactType={AddressTypes.CONSIGNEE}
              formName={FORM_NAME}
              isPhoneNumberRequired={false}
              validator={addressValidate}
              noAddressSuggestion
            />
          </AddressBlock>
        </Row>
        <H2 field={t('PaymentMethod')} />
        <RadioColumns column={3}>
          <Field
            name="paymentMethod"
            type="checkboxes"
            data={payments(fields)}
            component={RenderField}
          />
        </RadioColumns>
        <H2 field={t('Currency')} />
        <Row>
          <AddressFitColumn $tablet={6} $mobile={12}>
            <Field
              name="currency"
              component={RenderField}
              label={t('Currency')}
              type="dropdown"
              data={Currency}
              validate={[isRequired]}
              required
            />
          </AddressFitColumn>
        </Row>
        <H2 field={t('SctnQuoteConfirmationEmailedTo')} />
        <Row>
          <AddressFitColumn $tablet={6} $mobile={12}>
            <Field
              name="confirmationEmail"
              type="email"
              component={RenderField}
              required
              validate={[isRequired, isEmail]}
              label={t('EmailAddress')}
            />
          </AddressFitColumn>
        </Row>
        <ActionsContainer>
          <Button type="submit" iconRightArrow disabled={invalid || pristine}>
            {t('Next')}
          </Button>
        </ActionsContainer>
      </form>
    </FormContainer>
  );
};

// eslint-disable-next-line react/no-multi-comp
const ServiceCard = ({
  name,
  desc,
  image,
  clickHandler = (e) => e,
  selectedButtonIsSelected = false,
  tabletSize = 4
}) => (
  <Column $tablet={tabletSize} $mobile={12}>
    <Card
      title={name}
      thumb={image}
      lgThumb
      link="#"
      linkText={t('LearnMore')}
      selectedButton
      selectedButtonIsSelected={selectedButtonIsSelected}
      selectedButtonClickHandler={clickHandler}
    ><CardDescription>{desc}</CardDescription></Card>
  </Column>
);

const addressValidate = (values, dispatch, props, fieldName) => {
  const {
    shipperAddress,
    consigneeAddress
  } = values;
  if (fieldName && fieldName.includes('Nickname')) {
    const addressFieldName = `${fieldName.split('Nickname')[0]}Address`;
    props.touch(addressFieldName); // turn on error messages
    return props.asyncValidate(values, dispatch, props, addressFieldName); // evaluate whether the address field will fail or not
  }
  if (shipperAddress && consigneeAddress && shipperAddress === consigneeAddress) {
    return new Promise(() => {
      const error = {
        ...props.asyncErrors,
        consigneeAddress: t('NoSameAddress'),
        shipperAddress: t('NoSameAddress')
      };

      throw error;
    });
  }
  return Promise.resolve();
};

const mapStateToProps = (state, { initialValues }) => initialValues;

Step1 = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: FORM_NAME,
  asyncValidate: addressValidate,
  asyncBlurFields: ['consigneeAddress', 'shipperAddress', 'consigneeNickname', 'shipperNickname']
}, mapStateToProps)(Step1);

export default Step1 = connect((state) => {
  const data = getFormValues(FORM_NAME)(state);
  return {
    data
  };
})(Step1);
