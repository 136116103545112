import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SideBarContainer, Close, SideBarModal } from './css';

class SideBar extends PureComponent {
  render() {
    const { close, children } = this.props;
    return (
          <>
            <SideBarModal>
              <SideBarContainer>
                <Close onClick={close} />
                <div>{children}</div>
              </SideBarContainer>
            </SideBarModal>
          </>
    );
  }
}

SideBar.propTypes = {
  close: PropTypes.func,
  children: PropTypes.elementType
};

export default SideBar;
