export const HttpStatusCode = {
  OK: 200,
  Created: 201,
  NoContent: 204,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  InternalServerError: 500,
};

export const isHttpSuccessStatus = (status) => [
  HttpStatusCode.OK,
  HttpStatusCode.Created,
  HttpStatusCode.NoContent].includes(status);
