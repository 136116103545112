import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  Field,
  change,
  getFormValues
} from 'redux-form';
import { t } from 'i18next';
import Modal from 'react-modal';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import RenderField from '../ReactComponent/RenderField';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';
import { isRequired } from '../../utils/validator';
import FieldFileInput from '../ReactComponent/FileUploadButton';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import putReduxAllCompanies from '../ManageCompanies/actions';
import { getCompaniesList, postImportContacts } from '../../services/companies';
import ImportModal from './ImportModal';
import { getCompanyContacts } from '../../services/contacts';
import { DownloadFileNames, downloadReport, formatContactReports } from '../../utils/reports';
import { ImportContactTypes, Locales } from '../../utils/enums';
import Tooltip from '../ReactComponent/Tooltip';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import {
  Close,
  ModalBody,
  ModalTitle,
  ButtonDiv
} from '../ManagePickups/css';
import {
  PageTitle,
  PageDescription,
  ContactsContainer,
  DropdownField,
  ModalContent,
  Icon,
  SecondaryButton,
  ContentBoxLightGrey,
  FileUpload,
  InstructionLink
} from './css';
import { P, H2, H3 } from '../../theme/typography';

const formName = 'ImportExportForm';
let locale;

class ImportExportForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ddlCompanyNames: [],
      companiesList: [],
      errorList: [],
      openImportModal: false,
      companyId: '',
      contactTypesToImport: '',
      contactTypesToExport: '',
      disabledExportButton: true,
      noFileforDownload: false,
      modalIsOpen: false
    };
  }

  componentDidMount() {
    setTimeout(() => { this.fetchCompaniesAssociatedToUser(); }, 200);

    this.fetchCompaniesAssociatedToUser();
  }

  fetchCompaniesAssociatedToUser = async () => {
    // eslint-disable-next-line no-shadow
    const { division, userId, putReduxAllCompanies } = this.props;
    const { data: companies } = await getCompaniesList(division, userId);
    if (companies) {
      putReduxAllCompanies(companies);
      const companyNames = companies.map((company) => company.companyName);
      this.setState({
        ddlCompanyNames: companyNames,
        companiesList: companies
      });
    }
  };

  handleCompanySelect = (event, newValue) => {
    const { dispatch } = this.props;
    const { companiesList } = this.state;
    if (companiesList) {
      const companyIdValue = companiesList.filter(
        (eachCompany) => eachCompany.companyName === newValue)[0].companyId;
      this.setState({ companyId: companyIdValue });
    }
    dispatch(change(formName, 'exportContractType', 'All'));
  };

  onImportChangeContractType = (e) => {
    this.setState({
      contactTypesToImport: e.value,
      errorList: []
    });
  };

  reset() {
    const { dispatch } = this.props;
    dispatch(change(formName, 'importFileUpload', ''));
  }

  onExportChangeContractType = (e) => {
    this.setState({
      contactTypesToExport: e.value,
      disabledExportButton: false,
      noFileforDownload: false
    });
  };

  handleDownloadSubmit = async () => {
    const {
      companyId,
      contactTypesToExport
    } = this.state;
    this.setState({ errorList: [] });
    await getCompanyContacts(companyId, contactTypesToExport).then((res) => {
      if (res.status === 200) {
        const filterValue = contactTypesToExport !== 'All' && ImportContactTypes[contactTypesToExport.toUpperCase()];
        const responseData = contactTypesToExport !== 'All' ? res.data.filter((eachItem) => eachItem.contactType === filterValue) : res.data;
        if (responseData.length > 0) {
          downloadReport(DownloadFileNames.ContactsReport, formatContactReports, responseData);
        } else {
          this.setState({
            noFileforDownload: true,
            errorList: []
          });
          this.importModalBox(true);
        }
      }
    });
    this.reset();
  };

  processImportCSVData = () => {
    const {
      valid,
      formData
    } = this.props;

    if (valid) {
      const {
        companyId,
        contactTypesToImport
      } = this.state;
      const formDatatoAPI = {
        companyId,
        contactTypesToImport,
        csvFileDataAsBase64: formData.importFileUpload.file
      };

      postImportContacts(formDatatoAPI).then((res) => {
        const allErrors = res.data.filter((eachContact) => eachContact.statusCode >= 200 || eachContact.statusCode < 300);
        this.setState({
          errorList: allErrors,
          noFileforDownload: false
        });
        this.importModalBox(true);
        this.reset();
      }).catch(() => {
        this.openModal();
      });
    }
  };

  renderHeader = ({
    heading,
    description
  }) => (
    <Row>
      <Column $tablet={12} $mobile={12}>
        <div>
          <PageTitle>{heading}</PageTitle>
          <PageDescription>{description}</PageDescription>
        </div>
      </Column>
    </Row>
  );

  importModalBox = (isOpen) => {
    this.setState({ openImportModal: isOpen });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  render() {
    const {
      rendering: {
        fields: {
          heading,
          description,
          importContactTypesAll,
          importContactTypesShipper,
          importContactTypesConsignee,
          importContactTypesOther,
          importContactTypesShared,
          importContactFormAction
        }
      },
      valid
    } = this.props;
    const {
      openImportModal,
      ddlCompanyNames,
      errorList,
      companyId,
      disabledExportButton,
      noFileforDownload,
      modalIsOpen
    } = this.state;
    if (typeof window !== 'undefined') {
      locale = window.location.pathname.toLowerCase().includes(`/${Locales.French.toLowerCase()}/`)
        ? 'https://dayross.com/fr-CA/Tutorials-Importing-Contacts'
        : 'https://www.dayross.com/Tutorials-Importing-Contacts';
    }
    return (
      <>
        {this.renderHeader({
          heading: heading.value,
          description: description.value
        })}

        {openImportModal &&
          <ImportModal
            isOpen={openImportModal}
            onClose={() => this.importModalBox(false)}
            onSuccess={() => {
              this.closeImportModal(false);
            }}
            errorData={errorList}
            noFile={noFileforDownload}
          />}

        <ContactsContainer>
          <div className="row justify-content-md-center">
            <Column $tablet={11}>
              <DropdownField
                name="selectedCompany"
                type="dropdown"
                label={t('importlblCompanies')}
                data={[...ddlCompanyNames]}
                component={RenderField}
                onChange={(event, newValue) =>
                  this.handleCompanySelect(event, newValue)}
              />
            </Column>
          </div>
          {companyId &&
            <>
              <Row>
                <Column $tablet={12} $mobile={12}>
                  <ContentBoxLightGrey>
                    <ModalContent>
                      <Row>
                        <form action={importContactFormAction.value.href}>
                          <Column $tablet={12} $mobile={12}>
                            <H2>{t('lblImportContacts')}</H2>
                          </Column>
                          <Column $desktop={12}>
                            <H3>{t('lblImportFileTemplate')}</H3>
                          </Column>
                          <Column $desktop={12}>
                            <SecondaryButton>
                              <Icon className="icon-right" src={ArrowRightOrange} alt="back" />
                              {t('btnDownloadTemplate')}
                            </SecondaryButton>
                            <InstructionLink>
                              <a
                              href={locale}
                              rel="noopener noreferrer"
                              target="_blank"
                              className="link">
                                {t('InstructionsforImportingContacts')}
                              </a>
                            </InstructionLink>
                          </Column>
                          <Column $desktop={12}>
                            <ul>
                              <li>{t('DownloadTemplateRuleList1')}</li>
                              <li>{t('DownloadTemplateRuleList2')}</li>
                              <li>{t('DownloadTemplateRuleList3')}</li>
                              <li>{t('DownloadTemplateRuleList4')}</li>
                              <li>{t('DownloadTemplateRuleList5')}</li>
                              <li>{t('DownloadTemplateRuleList6')}</li>
                            </ul>
                          </Column>
                        </form>
                      </Row>
                      <Row>
                        <Column $desktop={12}>
                          <H2>{t('lblSelectTypeToImport')}</H2>
                        </Column>
                        <Column $desktop={12} >
                          <H3 style={{ margin: 0 }}>{t('lblContactType')} <Tooltip tip={t('TipContactType')}/></H3>

                          <Field
                            name="importContractType"
                            component={RenderField}
                            type="radio"
                            data={[
                              {
                                name: t(importContactTypesAll.value),
                                value: 'ALL'
                              },
                              {
                                name: t(importContactTypesShipper.value),
                                value: 'SHP'
                              },
                              {
                                name: t(importContactTypesConsignee.value),
                                value: 'CON'
                              },
                              {
                                name: t(importContactTypesOther.value),
                                value: 'OTR'
                              },
                              {
                                name: t(importContactTypesShared.value),
                                value: 'SHR'
                              }]}
                            onChange={(e) => this.onImportChangeContractType(e)}
                            validate={[isRequired]}
                            style={{ margin: 0 }}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column $desktop={12}>
                          <H3>{t('lblSelectFileToUpload')}</H3>
                        </Column>
                        <Column $desktop={12}>
                          <P>{t('lblAcceptedFile')} </P>
                        </Column>
                        <Column $desktop={12}>
                          <FileUpload
                            component={FieldFileInput}
                            attachButton="File Upload"
                            name="importFileUpload"
                            className="inline file-upload"
                            validate={[isRequired]}
                          />
                        </Column>
                        <Column $desktop={12}>
                          <PrimaryButton
                            className="active"
                            iconRightArrow
                            style={{ margin: '16px 0' }}
                            disabled={!valid}
                            onClick={this.processImportCSVData}>
                            {t('lblImportContacts')}
                          </PrimaryButton>
                        </Column>
                      </Row>
                    </ModalContent>
                  </ContentBoxLightGrey>
                </Column>
              </Row>
              <Row>
                <Column $desktop={12}>
                  <ContentBoxLightGrey>
                    <ModalContent>
                      <Row>
                        <Column $desktop={12}>
                          <H2>{t('lblExportContacts')}</H2>
                        </Column>
                        <Column $desktop={12}>
                          <P>{t('lblExportDesc')}</P>
                        </Column>
                      </Row>
                      <Row>
                        <Column $desktop={12}>
                          <H2>{t('lblSelectContactTypeExport')}</H2>
                        </Column>
                        <Column $desktop={12}>
                          <H3>{t('lblContactType')} <Tooltip tip={t('TipContactType')}/></H3>

                          <Field
                            name="exportContractType"
                            component={RenderField}
                            type="radio"
                            style={{ margin: 0 }}
                            data={[
                              {
                                name: t(importContactTypesAll.value),
                                value: ImportContactTypes.ALL
                              },
                              {
                                name: t(importContactTypesShipper.value),
                                value: ImportContactTypes.SHIPPER
                              },
                              {
                                name: t(importContactTypesConsignee.value),
                                value: ImportContactTypes.CONSIGNEE
                              },
                              {
                                name: t(importContactTypesOther.value),
                                value: ImportContactTypes.OTHER
                              },
                              {
                                name: t(importContactTypesShared.value),
                                value: ImportContactTypes.SHARED
                              }]}
                            onChange={(e) => this.onExportChangeContractType(e)} />
                        </Column>
                      </Row>
                      <Row>
                        <Column $desktop={12}>
                          <PrimaryButton
                            className="active"
                            iconRightArrow
                            style={{ margin: '16px 0' }}
                            disabled={disabledExportButton}
                            onClick={this.handleDownloadSubmit}>
                            {t('lblExportContacts')}
                          </PrimaryButton>
                        </Column>
                      </Row>
                    </ModalContent>
                  </ContentBoxLightGrey>
                </Column>
              </Row>
            </>}
        </ContactsContainer>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <Close onClick={this.closeModal} />
          <ModalBody className="justify-content-center">
            <ModalTitle>{ t('WarningTitle')}</ModalTitle>
            <p>{t('CSVFileCheckWarning')}</p>
             <ButtonDiv className="row">
              <div className="col-sm-12">
                <SecondaryButton
                  onClick={(e) => this.closeModal(e)}
                  className="active"
                  name="acceptErrorMessage"
                >
                  {t('Okay')}
                  <span className="icon" />
                </SecondaryButton>
              </div>
            </ButtonDiv>
          </ModalBody>
        </Modal>
      </>
    );
  };
}

ImportExportForm = reduxForm({
  form: formName // a unique identifier for this form
})(ImportExportForm);

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  division: state.profile.division,
  userId: state.profile.userId,
  selectedCompany:
    state.form.formName &&
    state.form.formName.values &&
    state.form.formName.values.selectedCompany
});

const mdtp = (dispatch) => ({
  putReduxAllCompanies: (companies) => dispatch(putReduxAllCompanies(companies))
});

export default connect(
  mstp,
  mdtp
)(ImportExportForm);
