import styled from 'styled-components';
import PropTypes from 'prop-types';

const Column = styled.div.attrs(
  ({
    $mobile,
    $tablet,
    $desktop,
    $index,
    className,
  }) => ({
    className: (() => {
      const classes = [];
      if ($desktop) {
        classes.push(`col-lg-${$desktop}`);
      }

      if ($tablet) {
        classes.push(`col-md-${$tablet}`);
      }

      if ($mobile) {
        classes.push(`col-${$mobile}`);
      } else {
        classes.push('col');
      }

      if ($index) {
        classes.push(`col-index-${$index}`);
      }

      if (className) {
        classes.push(className);
      }

      return classes.join(' ');
    })(),
  })
)``;

Column.propTypes = {
  $mobile: PropTypes.any,
  $desktop: PropTypes.any,
  className: PropTypes.string,
  $index: PropTypes.any,
};

Column.defaultProps = {
  $mobile: null,
  $desktop: null,
  $index: null,
  className: '',
};

export default Column;
