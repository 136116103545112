import styled from 'styled-components';
import PrimaryButton, { styleTypes } from '../../ReactComponent/PrimaryButton';
import { P } from '../../../theme/typographySitecore';
import { MediaQuery } from '../../../theme/style-helpers';

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const HeaderSummary = styled(P)`
    margin-top: 40px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
`;

export const CancelButton = styled(PrimaryButton).attrs(() => ({ styleType: styleTypes.SECONDARY }))`
    margin-bottom: 8px;

    ${MediaQuery.medium`
    margin-bottom: 0;      
    margin-right: 8px;
  `}
`;
