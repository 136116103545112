const handleDeliveryType = (deliveryType, otherServiceLevelCode) => {
  /* ***** For Default selected Service as per selected Delivery type by user *****
             Purpose: Ground Service must be selected while user select delivery address
                      "Commercial", and H1 or Home1 must be selected when user select "Residential"
             Required Attribute: Delivery Type
             Service Level Code: Commercial - 'EG' or Residential - 'H1'
   ***** End of Description ************************************************  ****** */
  let selectedServiceLevelCode = otherServiceLevelCode.data
    && otherServiceLevelCode.data.serviceLevels[0]
    && otherServiceLevelCode.data.serviceLevels[0].serviceLevelCode;
  if (!deliveryType) {
    return selectedServiceLevelCode;
  }

  if ((otherServiceLevelCode.data.serviceLevels.filter((sr) => sr.serviceLevelCode === 'EG').length > 0) && deliveryType.name === 'Commercial') {
    selectedServiceLevelCode = 'EG';
  } else if ((otherServiceLevelCode.data.serviceLevels.filter((sr) => sr.serviceLevelCode === 'NS').length > 0)
    && deliveryType.name === 'Residential') {
    selectedServiceLevelCode = 'NS';
  }

  return selectedServiceLevelCode;
};
export default handleDeliveryType;
