// all global font styles go here
import styled from 'styled-components';
import { colors, MediaQuery } from './style-helpers';

export const H1 = styled.h1`
    font-style: normal;
    font-weight: 300;
    font-size: 2.6rem;
    line-height: normal;
    color: ${colors.BLACK};
    margin-bottom: 3.2rem;

    &.white {
        color: ${colors.WHITE};
    }

    ${MediaQuery.medium`
        font-size: 4.8rem;
    `}

    &.center {
        text-align: center;
    }
`;

export const H2 = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.6rem;
    color: ${colors.BLACK};
    margin-bottom: 3rem;

    &.white {
        color: ${colors.WHITE};
    }

    ${MediaQuery.medium`
        line-height: normal;
        font-size: 2.4rem;
    `}
    &.center {
        text-align: center;
    }
`;

export const H3 = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: ${colors.BLACK};
    &.center {
        text-align: center;
    }
    &.white {
        color: ${colors.WHITE};
    }
`;

export const H4 = styled.h4`
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${colors.BLACK};

    &.center {
        text-align: center;
    }
    &.white {
        color: ${colors.WHITE};
    }
`;

export const CopyText = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1.3rem;
    color: ${colors.BLACK};

    ${MediaQuery.medium`
        font-size: 1rem;
    `}

    &.center {
        text-align: center;
    }
`;
export const P = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;    
    color: ${colors.BLACK};

    &.center {
        text-align: center;
    }
    &.white {
        color: ${colors.WHITE};
    }
`;

export const PLight = styled(P)`
    font-wight: 500;
    color: ${colors.DARK_GREY};

    &.center {
        text-align: center;
    }
`;

export const HeadLine = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: normal;
    color: ${colors.BLACK};

    &.center {
        text-align: center;
    }

    ${MediaQuery.medium`
        font-size: 1.4rem;
        font-weight: 600;
    `}
`;
