import { Field } from 'redux-form';
import styled from 'styled-components';
import close from '../../assets/icons/Close.svg';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import { colors, MediaQuery } from '../../theme/style-helpers';

export const PageTitle = styled.div`
    margin-top: 45px;
    font-weight: 300;
    font-size: 4.8rem;
    margin: 45px 10px 32px 0;

    &.justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const PageDescription = styled.div`
    font-size: 18px;
    line-height: 24px;
`;

export const ContactsContainer = styled.div`
    width: 100%;
    background: ${colors.WHITE};
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 40px;
`;

export const DropdownField = styled(Field)`
    margin: 24px 0 20px;
`;

export const SuccessMessage = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: ${colors.LAND_GREEN};
`;

export const ModalContainer = styled.div`
    line-height: 150%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 650px;
`;

export const ModalContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ModalHeader = styled.div`
    font-weight: 300;
    font-size: 4.8rem;
    margin-bottom: 32px;

    &.justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .headerDescription {
        padding: 0 10px;
        color: red;
    }
`;

export const ModalHeaderDesciption = styled.span`
    padding: 0 10px;
    color: red;
`;

export const ModalContent = styled.div`
    width: 100%;
    font-size: 13px;
`;

export const ContentBoxLightGrey = styled.div`
    background-color: ${colors.LIGHT_GREY};
    padding: 32px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 100%;
    margin: 20px 0;
    position: relative;
    flex-wrap: wrap;

    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        flex-wrap: wrap;

        p {
            margin: 0;
        }

        > :nth-child(even) {
            background-color: ${colors.WHITE};
        }
    }

    h2, h3 {
        color: ${colors.DARK_GREY};
    }
`;

export const FileUpload = styled(Field)`
    padding: 15px 0 !important;

    div {
        padding-left: 0 !important;
    }

`;

export const ModalActions = styled.div`
    padding: 48px 0;
`;

export const SecondaryButton = styled.button`
    background-color: ${colors.WHITE};
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    padding: 16px 56px;
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    margin-left: 0;
    width: 100%;
    order: 1;
    cursor: pointer;
    top: 22px;

    &.min-width {
        min-width: 172px;
    }


    ${MediaQuery.medium`
        width: auto;
        top: 22px;
        margin-bottom: 40px;
        order: 0;
    `}
    .icon-left {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background-size: cover;
    }

    .icon-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background-size: cover;
    }

    &.no-border {
        border: none;
    }

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    &.hidden {
        display: none;
    }

`;

export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;

export const ModalFooter = styled.div`
    text-align: center;
`;

export const ModalCloseButton = styled.button`
    align-self: flex-end;
    border: none;
    background: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    padding: 5px 5px 5px 10px;
    transition: background-color 0.4s;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: #EEE;
    }
`;

export const CloseIcon = styled.img.attrs(() => ({ src: close }))`
`;

export const ActionButton = styled(PrimaryButton)`
    width: 166px;
`;

const MAX_Z_INDEX = 10000;

export const modalStyle = ({
//   shrinkwrapContent,
  maximizeZIndex
}) => ({
  overlay: {
    zIndex: maximizeZIndex ? MAX_Z_INDEX : 'unset',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    minWidth: '30em',
    maxWidth: '120em',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '6px',
    background: `${colors.WHITE}`,
  },
});

export const InstructionLink = styled.h3`
    display: inline-block;
    margin-left: 40px;
    position: absolute;
    top: 35px;
    font-size: 1.6rem;
`;
