import styled from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';

export const FileUploadContainer = styled.div`
    padding: 27px 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid ${colors.COOL_GREY};
    justify-content: space-between;

    &.inline {
        padding-top: 10px;
        padding-bottom: 10px;
        border: none;
    }

    p.label {
        ${MediaQuery.large`
            max-width: 55%;
            margin-bottom: 0;
        `}

        margin-bottom: 10px;
    }
`;

export const FileUpload = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding-left: 15px;

    .hidden-button {
        display: none;
    }

    ${MediaQuery.medium`
        width: auto;
    `}
`;

export const FileUploadLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: ${colors.DARK_GREY};
    margin-bottom: 0;
    margin-right: 8px;
`;
