import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  change, Field, getFormValues, reduxForm
} from 'redux-form';
import { t } from 'i18next';
import { Button } from './common';
import { styleTypes } from '../../ReactComponent/PrimaryButton';
import {
  ActionsContainer,
  CheckBoxColumns,
  FormContainer,
  RadioColumns,
} from '../css';
import {
  H1, H2, H3, P
} from '../../../theme/typographySitecore';
import RenderField from '../../ReactComponent/RenderField';
import { isRequired, maxChar75, isValidTextAreaEntry } from '../../../utils/validator';
import {
  additionalServices as additionalServicesCreator,
  FORM_NAME,
  importTypes,
} from '../constants';
import Column from '../../ReactComponent/Column';
import Row from '../../ReactComponent/Row';

/**
 checks if there is a dangerous item
 */
const isDangerousItem = (containers) => containers.some(
  ({ items }) => items && items.some(({ itemDangerous }) => itemDangerous),
);

let Step3 = ({
  onNext,
  onBack,
  data,
  dispatch,
  fields,
  pristine,
  invalid
}) => {
  const additionalServices = additionalServicesCreator(fields);

  useEffect(() => {
    // change current services according with isDangerousItem
    let currentAdditionalServices;
    if (isDangerousItem(data.containers)) {
      currentAdditionalServices = [...data.additionalServices];
      // add dangerous item if it's not there yet
      if (!data.additionalServices.includes(additionalServices[2])) {
        currentAdditionalServices.push(additionalServices[2]);
      }
    }

    dispatch(
      change(FORM_NAME, 'additionalServices', currentAdditionalServices),
    );
  }, []);

  return (
    <FormContainer>
      <form onSubmit={onNext}>
        <H1 field={t('SctnQuoteAdditionalInformation')} verticalOffset={40}/>
        <H2
          field={t('SctnQuoteFullDescriptionOfCommodity')}
          verticalOffset={40}
        />
        <Row>
          <Column $tablet={6} $mobile={12}>
            <Field
              name="commodityDescription"
              type="textarea"
              component={RenderField}
              label={t('SctnQuoteDescriptionOfCommodity')}
              required
              validate={[isRequired]}
            />
          </Column>
        </Row>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <H2 field={t('Value')} verticalOffset={40}/>
            <Field
              name="invoiceValue"
              type="text"
              component={RenderField}
              label={t('SctnQuoteInvoiceValue')}
            />
          </Column>
        </Row>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <Field
              name="declaredValue"
              type="text"
              component={RenderField}
              label={t('SctnQuoteDeclaredValue')}
            />
          </Column>
          <Column $tablet={6} $mobile={12}>
            <P field={t('SctnQuoteInsuranceInfo')} verticalOffset={40}/>
          </Column>
        </Row>
        <H2 field={t('SctnQuoteCustomRequirements')} verticalOffset={40}/>
        <H3 field={t('SctnQuoteTransborderShipments')} verticalOffset={40}/>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <Field
              name="hsCode"
              type="text"
              component={RenderField}
              label={t('SctnQuoteHarmonizedShipping')}
            />
          </Column>
        </Row>
        <H2 field={t('SctnQuoteTypeOfImport')}/>
        <RadioColumns column={3}>
          <Field
            name="importType"
            type="checkboxes"
            data={importTypes(fields)}
            component={RenderField}
          />
        </RadioColumns>
        <H1 field={t('SctnQuoteAdditionalServices')}/>
        <P field={t('SctnQuoteAdditionalCharges')}/>
        <CheckBoxColumns $column={2}>
          <Field
            name="additionalServices"
            type="checkboxes"
            multiple
            data={additionalServices}
            component={RenderField}
            label={t('SctnQuoteTypeOfShipping')}
            disabledList={
              isDangerousItem(data.containers) ? [additionalServices[2]] : []
            }
          />
        </CheckBoxColumns>
        {data.additionalServices.includes(additionalServices[2]) && (
          <>
            <H2
              field={t('SctnQuoteDangerousGoodsInformation')}
              verticalOffset={40}
            />
            <Row>
              <Column $tablet={6} $mobile={12}>
                <Field
                  name="unNumber"
                  type="text"
                  component={RenderField}
                  label={t('SctnQuoteUnNumber')}
                  validate={[isRequired]}
                  required
                />
                <Field
                  name="hazmat"
                  type="text"
                  component={RenderField}
                  label={t('SctnQuoteDescriptionHAZMAT')}
                  validate={[isRequired]}
                  required
                />
              </Column>
            </Row>
            <H2 field={t('SctnQuoteSpecialInstructions')} verticalOffset={40}/>
            <Row>
              <Column $tablet={8} $mobile={12}>
                <Field
                  name="specialInstructions"
                  type="textarea"
                  component={RenderField}
                  label={t('SctnQuoteSpecialAdditionalInformation')}
                  validate={[maxChar75, isValidTextAreaEntry]}
                />
              </Column>
            </Row>
          </>
        )}
        <ActionsContainer>
          <Button
            type="button"
            iconLeftArrow
            styleType={styleTypes.SECONDARY}
            onClick={onBack}
          >
            {t('Back')}
          </Button>
          <Button type="submit" iconRightArrow onClick={onNext} disabled={invalid || pristine}>
            {t('Next')}
          </Button>
        </ActionsContainer>
      </form>
    </FormContainer>
  );
};

Step3 = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: FORM_NAME,
})(Step3);

export default Step3 = connect((state) => {
  const data = getFormValues(FORM_NAME)(state);
  return {
    data,
  };
})(Step3);
