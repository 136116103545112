import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, change } from 'redux-form';
import Modal from 'react-modal';
import { t } from 'i18next';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowLeftOrange from '../../assets/icons/ArrowLeftOrange.svg';
import putReduxCompanies from './actions';
import TabsBar from '../ReactComponent/TabsBar';
import {
  UserProfile, DeliveryTypes, IsYesOrNo, SamedayServiceType, CommerceServiceType
} from '../../utils/enums';
import { setAuthContent } from '../../actions/accounts';
import {
  updateUserProfileById,
  getUserById,
  getPreferences,
  updatePreferences,
  createPreferences
} from '../../services/users';
import {
  getCompaniesList,
  getCompanyInfo
} from '../../services/companies';
import { formDataToApi, formApiToData, getCompanyIdFromListByName } from '../../services/users/helpers';
import {
  Icon,
  ButtonGroup,
  FormContainer,
  CancelButton,
  SaveButton,
} from './css';
import SecondaryButton from '../ReactComponent/SecondaryButton';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import {
  ModalTitle,
  Close,
  ModalBody,
  ButtonDiv,
} from '../ManageUsers/css';
import UserForm from './components/UserForm';
import { homeUrlNoTrailingSlash, checkBillToAccountProtectedStatus } from '../../utils/func';
import { routes } from '../../utils/constants';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import LeavePageModal from '../ReactComponent/LeavePageModal';
import validateTime from './validators';
import { getCompanyContacts } from '../../services/contacts';
import UserPreferences from '../../services/users/user-preferences';
import { isHttpSuccessStatus, HttpStatusCode } from '../../utils/http';
import { Time12Hours, parse12HourTime as get12HourTimeFromTimeString } from '../../utils/dateTime';
import { isNullOrUndefined } from '../../utils/object-utils';
import { padNumberWithZeros } from '../../utils/numbers';

const DEFAULT_TIME = '12:00 AM';
const formName = 'UserProfileForm';
const initialValues = {
  companies: [{}],
  serviceType: { name: DeliveryTypes.Commercial, desc: 'CommercialServiceDesc' },
  isPickupScheduled: { name: IsYesOrNo.No, value: false },
};
const ModalContent = ({
  closeModal, handleDeleteUser, errorMessages, successAction, goToManage
}) => (
  // eslint-disable-next-line no-nested-ternary
  successAction ? <>
    <ModalTitle>{t('Success')}</ModalTitle>
    <p>{t(successAction)}</p>
    <ButtonDiv>
      <SecondaryButton
        onClick={() => goToManage()}
        className="active min-width"
      >
        {t('Ok')}
      </SecondaryButton>
    </ButtonDiv>
  </> :
    errorMessages.length > 0 ?
      <>
        <ModalTitle>{t('Error')}</ModalTitle>
        {errorMessages.map((error) => <p>{t(error.errorMessage)}</p>)}
        <ButtonDiv>
          <SecondaryButton
            onClick={() => closeModal()}
            className="active min-width"
          >
            {t('Ok')}
          </SecondaryButton>
        </ButtonDiv>
      </> :
      <>
        <ModalTitle>{t('DeleteUser')}</ModalTitle>
        {t('DeleteUserPrompt')}
        <ButtonDiv>
          <SecondaryButton
            onClick={() => closeModal()}
            className="active min-width"
          >
            {t('Cancel')}
          </SecondaryButton>
          <PrimaryButton
            onClick={() => handleDeleteUser()}
            className="active min-width"
          >
            {t('RemoveUser')}
          </PrimaryButton>
        </ButtonDiv>
      </>
);

// eslint-disable-next-line react/no-multi-comp
class UserProfileForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      companiesList: [],
      userId: '',
      companyDetails: [],
      modalIsOpen: false,
      errorMessages: [],
      leaveModalIsOpen: false,
      currentTab: UserProfile.PROFILE_INFORMATION,
      oldPasswordAutoFocus: false,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      division,
      userId,
      billingAccounts
    } = this.props;
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const changePassword = urlParams.get('changePassword');

      if (changePassword === 'true') {
        this.setState({ oldPasswordAutoFocus: true });
      }
    }
    setTimeout(() => this.fetchAllCompanies().then(() => {
      this.handleUserId();
    }), 500);
    checkBillToAccountProtectedStatus(dispatch, division, userId, billingAccounts);
  }

  changeBillTo = (billTo) => {
    const { dispatch } = this.props;
    dispatch(change(formName, 'billTo', billTo));
  };

  fetchAllContacts = (companies) => {
    if (companies) {
      return Promise.all(companies.map((comp) => getCompanyContacts(comp.companyId))).then((contactRequests) => {
        let allContacts = [];
        contactRequests.forEach((contactRequest) => {
          if (!contactRequest.data || contactRequest.data.length === 0) {
            return;
          }

          allContacts = allContacts.concat(contactRequest.data);
        });

        this.setState({ contacts: allContacts }, () => this.dispatchUserPrefsContactFields());

        return allContacts;
      }).catch(() => this.openErrorModal('GeneralError'));
    }

    return undefined;
  };

  fetchUserPreferences = async () => {
    const { userId, division } = this.props;
    try {
      const userPreferencesResult = await getPreferences(userId, division);
      if (!isHttpSuccessStatus(userPreferencesResult.status)) {
        return;
      }

      const userPreferences = new UserPreferences(userPreferencesResult.data);
      this.setState({ userPreferences },
        () => this.dispatchUserPreferenceFields());
    } catch (err) {
      if (err.response && err.response.data && err.response.data.statusCode === HttpStatusCode.NotFound) {
        this.dispatchTimeFields(DEFAULT_TIME, 'ready');
        this.dispatchTimeFields(DEFAULT_TIME, 'closing');
      }
    }
  };

  dispatchUserPreferenceFields = () => {
    const { userPreferences } = this.state;
    this.dispatchTimeFields(userPreferences.readyTime, 'ready');
    this.dispatchTimeFields(userPreferences.closingTime, 'closing');
    this.dispatchUserPrefsContactFields();
    this.dispatchUserPrefsUsContactDetails();
  };

  dispatchUserPrefsUsContactDetails = () => {
    const { dispatch } = this.props;
    const { userPreferences } = this.state;
    if (isNullOrUndefined(userPreferences)) {
      return;
    }
    if (userPreferences.shipperPostalCode) {
      dispatch(change(formName, 'shipperPostalCode', userPreferences.shipperPostalCode));
    }
    if (userPreferences.shipperName) {
      dispatch(change(formName, 'shipperName', userPreferences.shipperName));
    }
    if (userPreferences.consigneePostalCode) {
      dispatch(change(formName, 'consigneePostalCode', userPreferences.consigneePostalCode));
    }
    if (userPreferences.serviceType) {
      let newDeliveryType;
      if (userPreferences.serviceType === SamedayServiceType.Commercial) {
        newDeliveryType = {
          name: DeliveryTypes.Commercial,
          desc: 'CommercialServiceDesc'
        };
      }
      if (userPreferences.serviceType === CommerceServiceType.Residential) {
        newDeliveryType = {
          name: DeliveryTypes.Residential,
          desc: 'ResidentialServiceDesc'
        };
      }
      dispatch(change(formName, 'serviceType', newDeliveryType));
    }
    if (userPreferences.billToAccountNumber) {
      dispatch(change(formName, 'billToAccountNumber', userPreferences.billToAccountNumber));
    }
    if (userPreferences.isPickupScheduled) {
      let userPrefPickup;
      if (userPreferences.isPickupScheduled === IsYesOrNo.Yes) {
        userPrefPickup = {
          name: IsYesOrNo.Yes,
          value: true
        };
      }

      if (userPreferences.isPickupScheduled === IsYesOrNo.No) {
        userPrefPickup = {
          name: IsYesOrNo.No,
          value: false
        };
      }

      dispatch(change(formName, 'isPickupScheduled', userPrefPickup));
    }
  };

  dispatchUserPrefsContactFields = () => {
    const { dispatch } = this.props;
    const { userPreferences, contacts } = this.state;
    if (isNullOrUndefined(userPreferences) || isNullOrUndefined(contacts)) {
      return;
    }

    if (userPreferences.fromContactId) {
      const fromContact = contacts.find((c) => c.contactId === userPreferences.fromContactId);
      if (fromContact) {
        dispatch(change(formName, 'fromContact', fromContact));
      }
    }

    if (userPreferences.toContactId) {
      const toContact = contacts.find((c) => c.contactId === userPreferences.toContactId);
      if (toContact) {
        dispatch(change(formName, 'toContact', toContact));
      }
    }
    if (userPreferences.overrideContactId) {
      const overrideContact = contacts.find((c) => c.contactId === userPreferences.overrideContactId);
      if (overrideContact) {
        dispatch(change(formName, 'overrideContact', overrideContact));
      }
    }
  };

  dispatchTimeFields = (currentPrefTime, groupName) => {
    if (!currentPrefTime) {
      return;
    }

    const { dispatch } = this.props;
    const time = get12HourTimeFromTimeString(currentPrefTime);
    if (time) {
      dispatch(change(formName, `${groupName}TimeHour`, padNumberWithZeros(time.hours, 2)));
      dispatch(change(formName, `${groupName}TimeMinutes`, padNumberWithZeros(time.minutes, 2)));
      dispatch(change(formName, `${groupName}AMPM`, time.amPm));
    }
  };

  handleUserId = () => {
    const { division, userId } = this.props;

    getUserById(division, userId)
      .then((response) => {
        if (response.data) {
          const { data } = response;
          const { companiesList } = this.state;
          const formedData = formApiToData(data, companiesList);
          formedData.companies.forEach((c, index) => {
            this.getCompanyInfo(c.companyId, index);
          });
          this.dispatchFormValues(formedData);
        }
      }).catch(() => this.openErrorModal('GeneralError'));
  };

  dispatchFormValues = (data) => {
    const { dispatch } = this.props;
    Object.keys(data).map((field) => dispatch(change(formName, field, data[field])));
  };

  fetchAllCompanies = () => {
    const { division, userId } = this.props;
    return getCompaniesList(division, userId).then((res) => {
      if (res.status === 200) {
        // eslint-disable-next-line no-shadow
        const { putReduxCompanies } = this.props;
        putReduxCompanies(res.data);
        this.fetchUserPreferences();
        this.fetchAllContacts(res.data);
        this.setState({ companiesList: res.data });
      }
    }).catch(() => console.error('**** failed to get all companies'));
  };

  goToManage = (e) => {
    e?.preventDefault();
    const manageUsersUrl = `${homeUrlNoTrailingSlash()}${routes.userProfile}`;
    window.location.href = manageUsersUrl;
  };

  updateState = (name, value) => this.setState({ [name]: value });

  scrollToTop = () => window.scrollTo(0, 0);

  selectShippingPermission(index) {
    const { dispatch, formData: { companies } } = this.props;
    const { companyDetails } = this.state;
    if (companies[index] && !companies[index].permissions) {
      companies[index].permissions = companyDetails[index].permissions.filter((c) => c.permissionName === 'Shipping');
    } else if (companies[index].permissions.filter((c) => c.permissionName === 'Shipping').length < 1) {
      companies[index].permissions.push(companyDetails[index].permissions.filter((c) => c.permissionName === 'Shipping'));
    }

    dispatch(change(formName, `companies[${index}].permissions`, companies[index].permissions));
  }

  getCompanyInfo = (companyName, index) => {
    const { companiesList } = this.state;
    const companyID = getCompanyIdFromListByName(companyName, companiesList);
    getCompanyInfo(companyID).then((res) => {
      this.setState((prevState) => {
        const companyDetailsCopy = { ...prevState.companyDetails };
        companyDetailsCopy[index] = res.data;
        return { companyDetails: companyDetailsCopy };
      },
      () => this.selectShippingPermission(index)
      );
    });
  };

  handleSubmit = () => {
    const {
      isAuthenticated,
      valid
    } = this.props;
    const { currentTab } = this.state;
    if (isAuthenticated && valid) {
      switch (currentTab) {
        case UserProfile.PROFILE_INFORMATION:
        case UserProfile.ACCOUNT_PRIVILEAGES:
          this.handleUserSubmit();
          break;
        case UserProfile.USER_PREFERENCES:
          this.handleUserPreferencesSubmit();
          break;
        default:
          break;
      }
    }
  };

  handleUserPreferencesSubmit = () => {
    const { userPreferences } = this.state;
    if (userPreferences && userPreferences.userId > 0) {
      this.updateUserPreferences(userPreferences);
    } else {
      this.createUserPreferences();
    }
  };

  createUserPreferences = () => {
    const {
      division,
      userId
    } = this.props;

    let userPreferences = new UserPreferences({ userId, division });
    userPreferences = this.setUserPreferenceFields(userPreferences);
    createPreferences(userPreferences).then((res) => {
      if (isHttpSuccessStatus(res.status)) {
        userPreferences = new UserPreferences(res.data);
        this.setState({ userPreferences });
        this.openSuccessModal('UserPrefsCreateSuccess');
      } else {
        console.error('failed to update');
      }
    }).catch(() => {
      this.openErrorModal('GeneralError');
    });
  };

  updateUserPreferences = (userPreferences) => {
    userPreferences = this.setUserPreferenceFields(userPreferences);
    updatePreferences(userPreferences).then((res) => {
      if (isHttpSuccessStatus(res.status)) {
        this.openSuccessModal('UserPrefsUpdateSuccess');
      } else {
        console.error('failed to update');
      }
    }).catch((err) => {
      console.error(`failed to update: ${JSON.stringify(err, null, '\t')}`);
      this.openErrorModal('GeneralError');
    });
  };

  setUserPreferenceFields = (userPreferences) => {
    const { division } = this.props;
    if (division) {
      userPreferences.division = division;
    }
    userPreferences = this.setTimeFields(userPreferences);
    userPreferences = this.setContacts(userPreferences);
    userPreferences = this.setUSPrefsDetails(userPreferences);
    return userPreferences;
  };

  setUSPrefsDetails = (userPreferences) => {
    const {
      formData,
    } = this.props;
    if (formData.shipperPostalCode) {
      userPreferences.shipperPostalCode = formData.shipperPostalCode;
    } else {
      delete userPreferences.shipperPostalCode;
    }
    if (formData.shipperName) {
      userPreferences.shipperName = formData.shipperName;
    } else {
      delete userPreferences.shipperName;
    }
    if (formData.consigneePostalCode) {
      userPreferences.consigneePostalCode = formData.consigneePostalCode;
    } else {
      delete userPreferences.consigneePostalCode;
    }
    if (formData.serviceType.name) {
      userPreferences.serviceType = formData.serviceType.name;
    } else {
      delete userPreferences.serviceType;
    }
    if (formData.billToAccountNumber) {
      userPreferences.billToAccountNumber = formData.billToAccountNumber;
    } else {
      delete userPreferences.billToAccountNumber;
    }
    if (formData.isPickupScheduled.name) {
      userPreferences.isPickupScheduled = formData.isPickupScheduled.name;
    } else {
      delete userPreferences.isPickupScheduled;
    }
    return userPreferences;
  };

  setContacts = (userPreferences) => {
    const {
      formData,
    } = this.props;

    if (formData.fromContact) {
      userPreferences.fromContactId = formData.fromContact.contactId;
    } else {
      userPreferences.fromContactId = undefined;
    }

    if (formData.toContact) {
      userPreferences.toContactId = formData.toContact.contactId;
    } else {
      userPreferences.toContactId = undefined;
    }

    if (formData.overrideContact) {
      userPreferences.overrideContactId = formData.overrideContact.contactId;
    } else {
      userPreferences.overrideContactId = undefined;
    }
    return userPreferences;
  };

  setTimeFields = (userPreferences) => {
    const {
      formData,
    } = this.props;

    userPreferences.readyTime = (formData.readyTimeHour)
      ? new Time12Hours(
        {
          hours: formData.readyTimeHour,
          minutes: formData.readyTimeMinutes,
          amPm: formData.readyAMPM
        }).toTimeString()
      : undefined;

    userPreferences.closingTime = (formData.closingTimeHour)
      ? new Time12Hours(
        {
          hours: formData.closingTimeHour,
          minutes: formData.closingTimeMinutes,
          amPm: formData.closingAMPM
        }
      ).toTimeString()
      : undefined;

    return userPreferences;
  };

  handleUserSubmit = () => {
    const {
      formData,
      division,
      userId,
      dispatch
    } = this.props;
    const { companiesList } = this.state;
    const changedData = formDataToApi(formData, division, companiesList);
    if (formData.rowVersion) {
      updateUserProfileById(changedData, userId).then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 204) {
          if (changedData.isForceChangePassword) {
            const authContent = {
              isForceChangePassword: false,
            };

            dispatch(setAuthContent(authContent));
          }

          this.openSuccessModal('UserUpdateSuccess');
        }
      });
    }
  };

  openSuccessModal = (successAction) => {
    this.setState({
      modalIsOpen: true,
      successAction
    });
  };

  openErrorModal = (errorMessage) => {
    const { errorMessages } = this.state;
    errorMessages.push({ errorMessage });
    this.setState({
      modalIsOpen: true,
      errorMessages
    });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false, errorMessages: [] });
  };

  onTabChange = (tabName) => {
    this.setState({ currentTab: tabName });
  };

  render() {
    const {
      formData, handleSubmit, dispatch, fields, valid, division, billingAccounts
    } = this.props;
    const {
      companiesList,
      companyDetails,
      modalIsOpen,
      userId,
      errorMessages,
      leaveModalIsOpen,
      successAction,
      currentTab,
      userPreferences,
      oldPasswordAutoFocus
    } = this.state;
    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <Close onClick={() => this.closeModal()} />
          <ModalBody className="justify-content-center">
            <ModalContent
              closeModal={this.closeModal}
              errorMessages={errorMessages}
              goToManage={this.goToManage}
              successAction={successAction}
            />
          </ModalBody>
        </Modal>
        <LeavePageModal
          isOpen={leaveModalIsOpen}
          onClose={() => this.setState({ leaveModalIsOpen: false })}
          onContinue={() => this.goToManage()} />
        <FormContainer>
          <TabsBar current={this.state.currentTab} steps={Object.values(UserProfile)} onTabChange={this.onTabChange} />
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <UserForm
              fields={fields}
              division={division}
              formData={formData}
              dispatch={dispatch}
              formName={formName}
              companiesList={companiesList}
              getCompanyInfo={this.getCompanyInfo}
              companyDetails={companyDetails}
              valid={valid}
              selectedTab={currentTab}
              showAdd={userId ? true : false}
              userId={this.props.userId}
              contacts={this.state.contacts}
              userPreferences={userPreferences}
              oldPasswordAutoFocus={oldPasswordAutoFocus}
              billingAccounts={billingAccounts}
              changeBillTo={this.changeBillTo} />

            <ButtonGroup>
              <CancelButton type="button" className="active" onClick={() => this.setState({ leaveModalIsOpen: true })}>
                <Icon className="icon-left" src={ArrowLeftOrange} alt="back" />
                {t('Cancel')}
              </CancelButton>
              <SaveButton type="submit" disabled={!valid} className="submit">
                <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                {t('SaveChanges')}
              </SaveButton>
            </ButtonGroup>
          </form>
        </FormContainer>
      </>
    );
  }
}
const validate = (values) => validateTime(values);

UserProfileForm = reduxForm({
  validate,
  form: formName, // a unique identifier for this form
  initialValues,
})(UserProfileForm);

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  isAuthenticated: state.profile.isAuthenticated,
  division: state.profile.division,
  userId: state.profile.userId,
  email: state.profile.email,
  billingAccounts: state.profile.billingAccounts,
});

const mdtp = (dispatch) => ({
  putReduxCompanies: (companiesList) => dispatch(putReduxCompanies(companiesList)),
});

export default connect(mstp, mdtp)(UserProfileForm);
