import axios from 'axios';
import { t } from 'i18next';
import { get12HourTime } from '../utils/dateTime';

const postErrorLog = (config, action, errorString, errorMessage) => {
  const requestTime = new Date();
  const time12Hrs = get12HourTime(requestTime);
  let month = requestTime.getMonth() + 1;
  let logDate = requestTime.getDate();
  if (month < 10) {
    month = `0${month}`;
  }

  if (logDate < 10) {
    logDate = `0${logDate}`;
  }

  const url = t('AzureLogErrorEndPoint');
  const formattedTime = `${requestTime.getFullYear()}-${month}-${logDate} ${time12Hrs.hours}:${time12Hrs.minutes} ${time12Hrs.amPm}`;
  const passingObj = {
    Url: config.url,
    callingTime: formattedTime,
    action,
    errorValue: errorString,
    errorDescription: errorMessage,
  };
  axios.post(url, passingObj).then(() => {
  }).catch((err) => {
    console.error(`Error post unsuccessfully ${err}`);
  });
};
export default postErrorLog;
