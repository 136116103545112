// External script provided by a third party
import initLTL from './LTLChatScript';

const freightSalesForceChat = () => ({
  showChatWindow() {
    if (!window.embedded_svc) {
      const s = document.createElement('script');
      // eslint-disable-next-line no-undef
      s.setAttribute('src', process.env.REACT_APP_SALESFORCE_EMBEDDED_SERVICE);
      s.onload = () => {
        initLTL(null);
        setTimeout(() => {
          window.embedded_svc.bootstrapEmbeddedService();
        }, 2000);
      };
      document.body.appendChild(s);
    } else {
      initLTL('https://service.force.com');
      setTimeout(() => {
        window.embedded_svc.bootstrapEmbeddedService();
      }, 2000);
    }
  }
});

export default freightSalesForceChat;
