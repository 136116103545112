import React, { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { t } from 'i18next';
import RenderField from '../../../ReactComponent/RenderField';
import { isRequired, hasSomeSelected, maxLength } from '../../../../utils/validator';
import Column from '../../../ReactComponent/Column';
import { currencyOnly } from '../../../../utils/mask';
import { H2, P } from '../../../../theme/typographySitecore';
import {
  AccordionPage, AddItemContainer, AddItemLabel, ControlLabel, LineButton, Icon,
} from '../../css';
import Row from '../../../ReactComponent/Row';
import Plus from '../../../../assets/icons/Plus.svg';
import Delete from '../../../../assets/icons/RemoveOrange.svg';
import { Currency } from '../../constants';
import Tooltip from '../../../ReactComponent/Tooltip';

const MAX_REFERENCE_NUMBER_LENGTH = 9;
const FinalInformation = ({
  gstExempt,
  fields: {
    creditApplicationAgreement,
    logisticPaymentAgreement,
    shipmentTypes,
    serviceTypes,
  },
}) => (
  <AccordionPage style={{ height: '2150px' }}>
    <Tooltip tip={t('TipGstExempt')} marginLeft/>
    <Field
      name="gstExempt"
      component={RenderField}
      type="radio"
      value={gstExempt}
      data={[
        {
          name: t('Yes'),
          value: true
        },
        {
          name: t('No'),
          value: false
        },
      ]}
      label={<ControlLabel field={t('CreateAccountGSTExempt')}/>}
    />

    <Tooltip tip={t('TipShippingType')} marginLeft/>
    <Field
      name="typeOfShipping"
      type="checkboxes"
      multiple
      data={shipmentTypes.value.split(',')}
      component={RenderField}
      label={<ControlLabel field={t('CreateAccountShippingType')}/>}
    />

    <Tooltip tip={t('TipServices')} marginLeft/>
    <Field
      name="typeOfService"
      type="checkboxes"
      multiple
      data={serviceTypes.value.split(',')}
      verticalList
      containerMaxHeight="140px"
      component={RenderField}
      validate={[hasSomeSelected]}
      required
      showErrorAlways
      label={<ControlLabel field={t('CreateAccountAccountService')}/>}
    />

    <Row>
      <Column $tablet={7} $mobile={12}>
        <Field
          name="amountOfCredit"
          type="text"
          component={RenderField}
          normalize={currencyOnly}
          required
          showErrorAlways
          validate={[isRequired, maxLength(MAX_REFERENCE_NUMBER_LENGTH)]}
          label={t('CreateAccountMonthlyCreditAmount')}
        />
      </Column>
      <Column $tablet={5} $mobile={12}>
        <Field
          name="currency"
          component={RenderField}
          label={t('Currency')}
          type="dropdown"
          data={Currency}
          validate={[isRequired]}
          required
          showErrorAlways
        />
      </Column>
    </Row>
    <H2 field={t('CreateAccountLogisticsProviders')}/>
    <P
      field={logisticPaymentAgreement}
      verticalOffset={40}
    />
    <H2 field={t('CreateAccountOwnerInformation')} verticalOffset={20}/>
    <FieldArray name="ownerInformation" component={renderOwner}/>
    <H2 field={t('CreateAccountESignature')} verticalOffset={20}/>
    <Row>
      <Column $tablet={7} $mobile={12}>
        <Field
          name="signatoryName"
          type="text"
          component={RenderField}
          required
          showErrorAlways
          validate={[isRequired]}
          label={t('CreateAccountSignatoryName')}
        />
      </Column>
      <Column $tablet={5} $mobile={12}>
        <Field
          name="signatoryTitle"
          type="text"
          component={RenderField}
          required
          showErrorAlways
          validate={[isRequired]}
          label={t('Title')}
        />
      </Column>
    </Row>
    <Field
      name="signatureDate"
      type="date"
      component={RenderField}
      required
      showErrorAlways
      validate={[isRequired]}
      label={t('Date')}
      asteriskStyle={{ top: '10px' }}
    />
    <H2 field={t('CreateAccountLegalTerms')} verticalOffset={30}/>
    <P field={creditApplicationAgreement}/>
    <Field
      name="finalLegalTC"
      type="singleCheckbox"
      label={t('AcceptTermsConditions')}
      component={RenderField}
      required
      validate={[isRequired]}
    />
  </AccordionPage>
);

const renderOwner = ({ fields }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({
    ownerList: [],
  });

  // const { ownerList } = state;
  const deleteOwnerTitleList = (currentOwnerListItem, index) => {
    if (currentOwnerListItem.length > 1) {
      fields.splice(index, 1);
    }
  };

  return (
    <>
      {fields.map((owner, index) => (
        <div key={`owner${index}`}>
          <Row>
            <Column $tablet={6} $mobile={12}>
              <Field
                name={`${owner}.ownerName`}
                type="text"
                component={RenderField}
                required
                showErrorAlways
                validate={[isRequired]}
                label={t('CreateAccountPrincipalName')}
              />
            </Column>
            <Column $tablet={4} $mobile={12}>
              <Field
                name={`${owner}.ownerTitle`}
                type="text"
                component={RenderField}
                required
                showErrorAlways
                validate={[isRequired]}
                label={t('Title')}
              />
            </Column>
            {index > 0 && <Column $desktop={2}>
              <LineButton name="deleteTransitTime" type="button">
                <Icon className="icon" src={Delete} alt="delete" onClick={() => deleteOwnerTitleList(fields, index)}/>
                {t('Delete')}
              </LineButton>
            </Column>}
          </Row>
        </div>
      ))}
      {fields.length < 3
        && <AddItemContainer onClick={() => fields.push({})}>
          <img src={Plus} alt={t('CreateAccountAddPrincipal')}/>
          <AddItemLabel>{t('CreateAccountAddPrincipal')}</AddItemLabel>
        </AddItemContainer>}
    </>
  );
};

export default FinalInformation;
