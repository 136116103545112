import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { ProgressContainer, ProgressItem } from './css';

const TabsBar = ({ steps = [], current = '', onTabChange }) => (
    <ProgressContainer>
        {steps.map((step, index) => (
            <ProgressItem
            key={index}
            className={`${step === current ? 'active' : ''}`}
            width={steps.length}>
              {/* eslint-disable jsx-a11y/click-events-have-key-events */}
              {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                <span
                className="title"
                step={step}
                onClick={(e) => onTabChange(e.target.attributes.step.value)}>
                  {t(step)}
                </span>
                <span className="progress-bar"></span>
              {/* eslint-enable jsx-a11y/click-events-have-key-events */}
              {/* eslint-enable jsx-a11y/no-static-element-interactions */}
            </ProgressItem>
        ))}
    </ProgressContainer>
);

TabsBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  current: PropTypes.string,
  onTabChange: PropTypes.func,
};

export default TabsBar;
