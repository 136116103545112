import styled from 'styled-components';
import { colors, styleHelpers } from '../../../theme/style-helpers';

export const CubeBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    height: ${({ $height }) => `${$height}px`};
    flex: 1;
    max-width: ${({ $width }) => `${$width}px`};
    position: relative;
    margin: 120px auto 20px;
    border: 1px dashed;
    background-image: linear-gradient(45deg, #ffffff 29.41%, #ffd1bd 29.41%, #ffd1bd 50%, #ffffff 50%, #ffffff 79.41%, #ffd1bd 79.41%, #ffd1bd 100%);
    background-size: 20px 20px;

    .before {
        width: ${({ $length }) => `${$length / 3}px`};
        height: 100%;
        right: -4px;
        transform: translatex(100%) skewy(-45deg);
        top: ${({ $length }) => `${`-${$length / 6}` - 2}px`};
        position: absolute;
        border: 1px dashed;
        background-image: linear-gradient(
                45deg,
                #ffffff 29.41%,
                #ffd1bd 29.41%,
                #ffd1bd 50%,
                #ffffff 50%,
                #ffffff 79.41%,
                #ffd1bd 79.41%,
                #ffd1bd 100%
        );
        background-size: 12px 12px;
    }

    .after {
        width: 100%;
        height: ${({ $length }) => `${$length / 3}px`};
        left: ${({ $length }) => `${$length / 6 + 2}px`};
        transform: translatey(-100%) skewx(-45deg);
        top: -4px;
        position: absolute;
        border: 1px dashed;
        background-image: linear-gradient(
                45deg,
                #ffffff 29.41%,
                #ffd1bd 29.41%,
                #ffd1bd 50%,
                #ffffff 50%,
                #ffffff 79.41%,
                #ffd1bd 79.41%,
                #ffd1bd 100%
        );
        background-size: 12px 12px;
    }

    &.solid {
        background: ${colors.BRAND_ORANGE};
        border: 1px solid;

        .before {
            right: 0;
            top: ${({ $length }) => `-${$length / 6}px`};

            position: absolute;
            border: 1px solid;
            background: #DE5210;
        }

        .after {
            left: ${({ $length }) => `${$length / 6}px`};
            top: 0;

            position: absolute;
            border: 1px solid;
            background: #F9B08E;
        }
    }
`;
export const CubeContainer = styled.div`
    width: 100%;

    ${styleHelpers.mobile`
        display: none;
    `}
`;
