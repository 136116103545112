import React from 'react';
import { reduxForm, getFormValues, change } from 'redux-form';
import { MDBDropdown } from 'mdbreact';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { t } from 'i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { P } from '../../theme/typography';
import TabsBar from '../ReactComponent/TabsBar';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import Order from './strategies/order';
import Reference from './strategies/reference';
import Shipment from './strategies/shipment';
import {
  FormContainer,
  InputStrategy,
  Icon,
  SecondaryButton,
  Error,
  ExpandButton
} from './css';
import Actions from '../../assets/icons/Actions.svg';
import ResetOrange from '../../assets/icons/ResetOrange.svg';
import ArrowDown from '../../assets/icons/ArrowDown.svg';
import TrackWhite from '../../assets/icons/TrackWhite.svg';
import TrackGrey from '../../assets/icons/TrackGrey.svg';
import ViewSignedDocumentsGrey from '../../assets/icons/ViewSignedDocumentsGrey.svg';
import ViewSignedDocumentsWhite from '../../assets/icons/ViewSignedDocumentsWhite.svg';
import { Icon as CommonIcon } from '../ReactComponent/Icon';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import {
  strategies,
  formName,
  initialValues,
  validnumberregex,
  columns
} from './constants';
import { ShipmentHistoryStates } from '../ViewUserDashboard/constants';
import { routes } from '../../utils/constants';
import { validateForm } from './validation';
import {
  apiGetShipmentSummaryByShipmentNumbers,
  apiGetShipmentSummaryByReferenceNumber,
  apiGetShipmentSummaryByOrderNumbers
} from './api';
import {
  getShipmentTrackingDetailed
} from '../../services/shipments';
import { ShipmentStatuses } from '../../utils/enums';
import SortableTable from '../ReactComponent/SortableTable';
import NoDataDecorator from '../ReactComponent/SortableTable/NoDataDecorator';
import ShipmentDetails from '../ReactComponent/ShipmentDetails';
import { getDeliveryDate } from '../../utils/dateTime';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import {
  Close,
  ModalBody,
  ModalTitle,
  ButtonDiv
} from '../ManagePickups/css';

const Dropdown = ({
  status,
  probillNumber,
  division,
  isAuthenticated,
  disabled
}) => {
  const isDisabled = status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview || disabled;
  return (<MDBDropdown className="dropdown" >
        <MDBDropdownToggle className="border-0">
            <CommonIcon iconSrc={Actions} />
        </MDBDropdownToggle>
        <StyledMDBDropdownMenu className="text-white bg-dark" basic>
            <DropdownItem
                disabled={isDisabled}
                onClick={() => window.open(`${routes.viewShipmentTracking}?division=${division}&probillNumber=${probillNumber}`)}
            >
                <DropdownItemIcon iconSrc={isDisabled ? TrackGrey : TrackWhite} />
                {t('ViewTrackingDetails')}
            </DropdownItem>
            {isAuthenticated &&
                <DropdownItem
                disabled={isDisabled || status === ShipmentStatuses.NotYetPickedUp}
                onClick={() => {
                  window.location.href = `${routes.viewShipment}?division=${division}&probillNumber=${probillNumber}&scrollToDocs=true`;
                  return window.location.href;
                }}>
                    <DropdownItemIcon
                    iconSrc={isDisabled || status === ShipmentStatuses.NotYetPickedUp ? ViewSignedDocumentsGrey : ViewSignedDocumentsWhite} />
                    {t('ViewSignedDocuments')}
                </DropdownItem>}
        </StyledMDBDropdownMenu>
    </MDBDropdown>);
};

// eslint-disable-next-line react/no-multi-comp
class TrackShipmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStrategy: strategies.shipment,
      data: null,
      error: '',
      modalIsOpen: false
    };
  }

  componentDidMount() {
    const { isAuthenticated, dispatch, division } = this.props;
    if (isAuthenticated) {
      dispatch(change(formName, 'division', division));
    }
  }

  onTabChange = (strategy) => {
    this.setState({
      currentStrategy: strategy,
      data: [],
      error: ''
    });
    this.reset();
  };

  getDivision = () => {
    if (this.props.isAuthenticated) {
      return this.props.division;
    }

    const { formData: { division } } = this.props;
    return division.value;
  };

  reset = () => {
    this.setState({
      data: null,
      error: ''
    });

    this.props.dispatch(change(formName, 'shipmentNumbers', ''));
    this.props.dispatch(change(formName, 'referenceNumber', ''));
    this.props.dispatch(change(formName, 'orderNumbers', ''));
    this.props.dispatch(change(formName, 'postalCode', ''));
  };

  renderDetailsDataError = (index) => {
    const { data } = this.state;
    const dataRow = data[index];

    if (!dataRow) {
      return;
    }

    dataRow.separateRow = <ShipmentDetails error={t('ShipmentDetailsError')} />;
    this.setState({ data });
  };

  renderDetailsData = (index, detailsData) => {
    const { data } = this.state;
    const { isAuthenticated } = this.props;
    const division = this.getDivision();
    const dataRow = data[index];

    if (!dataRow) {
      return;
    }

    const { basicData } = dataRow;
    const historyData = detailsData.filter((history) => history.status !== ShipmentHistoryStates.DoNotShow);

    dataRow.detailsData = historyData;
    dataRow.separateRow =
      <ShipmentDetails
        key={index}
        mapid={`google-map-${index}`}
        basicData={basicData}
        detailsData={historyData}
        isAuthenticated={isAuthenticated}
        division={division}/>;

    this.setState({ data });
  };

  fetchAndRenderDetailsData = (index) => {
    const { data } = this.state;
    const dataRow = data[index];

    if (!dataRow) {
      return null;
    }

    const {
      detailsData
    } = dataRow;

    if (detailsData) { // tbd
      this.renderDetailsData(index, detailsData);
      return null;
    }
    if (detailsData) {
      this.renderDetailsData(index, detailsData);
    } else {
      this.renderDetailsDataError(index);
    }

    return undefined;
  };

  addSpecial = (index) => {
    const { data } = this.state;

    if (data[index].separateRow) {
      data[index].separateRow = null;
      data[index].expand = this.prepareExpandButton(index, false);
    } else {
      this.fetchAndRenderDetailsData(index);
      data[index].expand = this.prepareExpandButton(index, true);
    }

    this.setState({ data });
  };

  prepareExpandButton = (index, open) =>
    <ExpandButton key={index} type="button" onClick={() => this.addSpecial(index)} className={open ? 'open' : ''}>
      <Icon src={ArrowDown} alt="expand filter"/>
    </ExpandButton>;

  convertSummaryDataFormat = (summaryData) => {
    const { isAuthenticated } = this.props;
    const division = this.getDivision();
    const newData = [];
    summaryData.map((each, index) => {
      getShipmentTrackingDetailed({
        division,
        probillNumber: each.probillNumber
      }).then((res) => {
        // successfull row object
        newData[index] = {
          probillNumber: each.probillNumber,
          referenceNumber: each.referenceNumber,
          deliveryDate: getDeliveryDate({
            deliveryDate: each.deliveryDate,
            deliveryDateType: each.deliveryDateType
          }),
          status: each.status,
          actions: <Dropdown
              status={each.status}
              probillNumber={each.probillNumber}
              division={division}
              isAuthenticated={isAuthenticated}
            />,
          expand: this.prepareExpandButton(index, false),
          basicData: res.data,
          detailsData: res.data.trackingItems ? res.data.trackingItems : null
        };
        // only set the state once all objects have been processed, prevents null render error
        if (Object.values(newData).length === summaryData.length) {
          this.setState({ data: newData });
        }
      }
      ).catch(() => {
        // shipment details object where shipment summary succeeds but shipment
        // details call fails - means we can't show tracking information or view shipment page
        newData[index] = {
          probillNumber: each.probillNumber,
          referenceNumber: each.referenceNumber,
          deliveryDate: getDeliveryDate({
            deliveryDate: each.deliveryDate,
            deliveryDateType: each.deliveryDateType
          }),
          status: each.status,
          actions: <Dropdown
            status={each.status}
            probillNumber={each.probillNumber}
            division={division}
            isAuthenticated={isAuthenticated}
            disabled
          />,
          expand: t('Tracking Information Not Found'),
          basicData: null,
          detailsData: null
        };
        // only set the state once all objects have been processed
        if (Object.values(newData).length === summaryData.length) {
          this.setState({ data: newData });
        }
      });
      return undefined;
    });
  };

  convertPickupDataFormat = (data) => data.map((each) => ({
    orderNumber: each.orderNumber,
    pickupDate: each.pickupDate,
    pickupAddress: each.pickupAddress,
    status: each.status,
    actions: null
  }));

  redirectIfMatchingNumber = (number, requestNumber, probillNumber) => {
    const division = this.getDivision();

    if (requestNumber.trim().includes(number.trim())) {
      window.open(`${routes.viewShipmentTracking}?division=${division}&probillNumber=${probillNumber}`);
    }
  };

  unfulfilledRequests = (data, requestData, useOrderNumber) => requestData.filter((rdv) => {
    const index = data.findIndex((dv) => {
      const currentValue = rdv.toUpperCase();
      const {
        probillNumber,
        orderNumber
      } = dv;
      const rc = useOrderNumber ? currentValue === orderNumber.trim().toUpperCase() : currentValue.includes(probillNumber.trim().toUpperCase());
      return rc;
    });
    return index < 0;
  });

  onSetData = (data, requestData) => {
    const { currentStrategy } = this.state;
    switch (currentStrategy) {
      case strategies.shipment: {
        // redirect if it's one
        if (requestData.length === 1 && data.length === 1) {
          const { probillNumber } = data[0];
          this.redirectIfMatchingNumber(probillNumber, requestData[0], probillNumber);
        }

        this.convertSummaryDataFormat(data);
        break;
      }

      case strategies.reference: {
        const { isAuthenticated } = this.props;
        const requestReferenceNumber = requestData[0];
        if (data.length === 0 && !isAuthenticated) {
          const msg = `${t('NoResultsFoundFor')}: ${requestReferenceNumber}`;
          this.setState({ error: msg });
          break;
        }
        // redirect if it's one
        if (data.length === 1) {
          const {
            referenceNumber,
            probillNumber
          } = data[0];
          this.redirectIfMatchingNumber(referenceNumber, requestReferenceNumber, probillNumber);
        }

        this.convertSummaryDataFormat(data);
        break;
      }

      case strategies.order: {
        const cvData = this.convertPickupDataFormat(data);
        this.setState({ data: cvData });
        break;
      }

      default: {
        break;
      }
    }
  };

  onError = () => {
    this.setState({
      error: `${t('ShipmentTrackingError')}`,
      data: []
    });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  columns = () => {
    const { currentStrategy } = this.state;
    switch (currentStrategy) {
      case strategies.shipment: {
        return columns.shipment;
      }

      case strategies.reference: {
        return columns.reference;
      }

      case strategies.order: {
        return columns.order;
      }

      default: {
        return null;
      }
    }
  };

  handleFormSubmit = () => {
    const { valid } = this.props;
    const { currentStrategy } = this.state;

    if (!valid) {
      return;
    }

    this.setState({
      data: null,
      error: ''
    });

    switch (currentStrategy) {
      case strategies.shipment: {
        const { formData: { shipmentNumbers } } = this.props;
        const numbers = shipmentNumbers.split(validnumberregex)
          .map((num) => (num ? num.trim() : undefined))
          .reduce((accum, curr) => (curr ? accum.concat(curr) : accum), []);
        apiGetShipmentSummaryByShipmentNumbers(this.onSetData, this.getDivision(), numbers, this.onError, this.openModal);
        break;
      }
      case strategies.reference: {
        const {
          formData: {
            referenceNumber,
            postalCode
          }
        } = this.props;
        apiGetShipmentSummaryByReferenceNumber(this.onSetData, this.getDivision(), referenceNumber, postalCode, this.onError, this.openModal);
        break;
      }

      case strategies.order: {
        const { formData: { orderNumbers } } = this.props;
        const numbers = orderNumbers.split(validnumberregex);
        apiGetShipmentSummaryByOrderNumbers(this.onSetData, numbers, this.onError);
        break;
      }
      default: {
        break;
      }
    }
  };

  render() {
    const {
      fields,
      isAuthenticated,
      handleSubmit,
      formData,
      valid
    } = this.props;

    const {
      data,
      modalIsOpen,
      currentStrategy,
      error
    } = this.state;

    return (
            <>
            <FormContainer>
                <TabsBar
                current={currentStrategy}
                steps={Object.values(strategies)}
                onTabChange={this.onTabChange} />
                <InputStrategy>
                    <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                        {currentStrategy === strategies.shipment &&
                        <Shipment
                        fields={fields}
                        isAuthenticated={isAuthenticated}
                        formData={formData}
                        valid={valid} />}
                        {currentStrategy === strategies.reference &&
                        <Reference
                        fields={fields}
                        isAuthenticated={isAuthenticated}
                        formData={formData}
                        valid={valid} />}
                        {currentStrategy === strategies.order &&
                        <Order fields={fields} formData={formData} valid={valid} />}
                    </form>
                </InputStrategy>
                <Row>
                    <Column $tablet={3} $mobile={12}>
                        <SecondaryButton type="button" className="reset" onClick={() => this.reset()}>
                            <Icon className="icon" src={ResetOrange} alt="reset" />
                            {t('Reset')}
                        </SecondaryButton>
                    </Column>
                </Row>
                {error && <Error>{error}</Error>}
                <div className="results-table">
                    <NoDataDecorator component={SortableTable} data={data} columns={this.columns()} />
                </div>
            </FormContainer>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={this.closeModal}
                style={ModalBoxStyle}
                contentLabel="Modal"
            >
            <Close onClick={this.closeModal} />
                <ModalBody className="justify-content-center">
                    <ModalTitle>{ t('WarningTitle')}</ModalTitle>
                    <P><RichText field={fields.selectaServiceWarning} /></P>
                    <ButtonDiv className="row">
                    <div className="col-sm-12">
                        <SecondaryButton
                        onClick={(e) => this.closeModal(e)}
                        className="active"
                        name="acceptErrorMessage"
                        >
                        {t('Okay')}
                        <span className="icon" />
                        </SecondaryButton>
                    </div>
                    </ButtonDiv>
                </ModalBody>
            </Modal>
            </>
    );
  }
}

TrackShipmentForm = reduxForm({
  form: formName,
  initialValues,
  validate: validateForm,
})(TrackShipmentForm);

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  isAuthenticated: state.profile.isAuthenticated,
  division: state.profile.division,
});

export default connect(
  mstp,
)(TrackShipmentForm);
