import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { t } from 'i18next';
import { H2 } from '../../../theme/typography';
import QuotesReportIcon from '../../../assets/icons/QuotesReport.svg';
import ShipmentsInProgressReportIcon from '../../../assets/icons/ShipmentsInProgressReport.svg';
import ShipmentsCompletedReportIcon from '../../../assets/icons/ShipmentsCompletedReport.svg';
import {
  QuickReportsTile,
  InlineBlock,
  Icon
} from '../css';
import { ShipmentCategories, ShipmentStates, NUMBER_OF_DAYS } from '../constants';
import {
  DownloadFileNames,
  downloadReport,
  formatQuotesReport,
  formatInvoicesReport,
  formatShipmentsReport
} from '../../../utils/reports';

class QuickReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quotesData: [],
      shipmentsData: [],
      invoicesData: [],
    };
  }

  downloadQuotesReport = () => {
    const { quotesData } = this.state;
    if (quotesData && quotesData.length > 0) {
      downloadReport(DownloadFileNames.QuotesReport, formatQuotesReport, quotesData);
    }
  };

  downloadShipmentsInProgressReport = () => {
    const { shipmentsData } = this.state;
    const inProgressData = shipmentsData[ShipmentCategories.ShipmentsInProgress];
    if (inProgressData && inProgressData.length > 0) {
      downloadReport(DownloadFileNames.ShipmentsInProgressReport, formatShipmentsReport, inProgressData);
    }
  };

  downloadShipmentsCompletedReport = () => {
    const { shipmentsData } = this.state;
    const completedData = shipmentsData[ShipmentCategories.Completed];
    if (completedData && completedData.length > 0) {
      downloadReport(DownloadFileNames.ShipmentsCompletedReport, formatShipmentsReport, completedData);
    }
  };

  downloadInvoicesReport = () => {
    const { invoicesData } = this.state;
    if (invoicesData && invoicesData.length > 0) {
      downloadReport(DownloadFileNames.InvoicesReport, formatInvoicesReport, invoicesData);
    }
  };

  groupShipmentData = (data) => {
    const shipmentGroupedData = [];
    shipmentGroupedData[ShipmentCategories.ShipmentsInProgress] = [];
    shipmentGroupedData[ShipmentCategories.Completed] = [];

    return data.reduce((categories, item) => {
      const { status } = item;
      if (status === ShipmentStates.Delivered) {
        categories[ShipmentCategories.Completed].push(item);
      } else {
        categories[ShipmentCategories.ShipmentsInProgress].push(item);
      }
      return categories;
    }, shipmentGroupedData);
  };

  filterInvoicesData = (data) => {
    const last30Days = new Date();
    last30Days.setDate(last30Days.getDate() - NUMBER_OF_DAYS);
    return data.filter((i) => new Date(i.dueDate) <= last30Days);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      quotesData,
      shipmentsData,
      invoicesData
    } = this.props;
    const differentQuotes = quotesData !== nextProps.quotesData;
    if (differentQuotes) {
      this.setState({
        quotesData: nextProps.quotesData
      }
      );
    }

    const differentShipments = shipmentsData !== nextProps.shipmentsData;
    if (differentShipments) {
      this.setState({
        shipmentsData: this.groupShipmentData(nextProps.shipmentsData)
      }
      );
    }

    const differentInvoices = invoicesData !== nextProps.invoicesData;
    if (differentInvoices) {
      this.setState({
        invoicesData: this.filterInvoicesData(nextProps.invoicesData)
      });
    }
  }

  render() {
    const { fields } = this.props;

    const {
      quotesData,
      shipmentsData
    } = this.state;

    const quotesEnabled = quotesData && quotesData.length > 0;
    const shipmentsCompletedEnabled = shipmentsData && shipmentsData[ShipmentCategories.Completed]
      && shipmentsData[ShipmentCategories.Completed].length > 0;
    const shipmentsInProgressEnabled = shipmentsData && shipmentsData[ShipmentCategories.ShipmentsInProgress]
      && shipmentsData[ShipmentCategories.ShipmentsInProgress].length > 0;

    return (<QuickReportsTile>
      <H2>
        <Text field={fields.heading}/>
      </H2>
      <InlineBlock className="content-text"><Text field={fields.contentText}/></InlineBlock>
      <InlineBlock className="margin-10">
        <button
          type="button"
          className="secondaryButton no-border report-link"
          disabled={!quotesEnabled}
          onClick={() => this.downloadQuotesReport()}>
          <Icon src={QuotesReportIcon} alt="quotes" className="icon-20"/>
          {t('ReportQuotes')}
          <span className="icon"/>
        </button>
      </InlineBlock>
      <InlineBlock className="margin-10">
        <button
          type="button"
          className="secondaryButton no-border report-link"
          disabled={!shipmentsInProgressEnabled}
          onClick={() => this.downloadShipmentsInProgressReport()}>
          <Icon src={ShipmentsInProgressReportIcon} alt="shipments in progress" className="icon-20"/>
          {t('ReportShipmentsInProgress')}
          <span className="icon"/>
        </button>
      </InlineBlock>
      <InlineBlock className="margin-10">
        <button
          type="button"
          className="secondaryButton no-border report-link"
          disabled={!shipmentsCompletedEnabled}
          onClick={() => this.downloadShipmentsCompletedReport()}>
          <Icon src={ShipmentsCompletedReportIcon} alt="shipments completed" className="icon-20"/>
          {t('ReportShipmentsCompleted')}
          <span className="icon"/>
        </button>
      </InlineBlock>
    </QuickReportsTile>);
  }
}

QuickReports.propTypes = {
  fields: PropTypes.object,
  quotesData: PropTypes.array,
  shipmentsData: PropTypes.array,
  invoicesData: PropTypes.array
};

export default QuickReports;
