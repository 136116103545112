import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import {
  change, Field, formValueSelector, getFormSyncErrors
} from 'redux-form';
import { CubeContainer, Item } from './css';
import RenderField from '../../RenderField';
import {
  isRequired,
  maxChar50,
  isValidDescription,
  maxChar6
} from '../../../../utils/validator';
import { H2, P } from '../../../../theme/typographySitecore';
import { LENGTH, WEIGHT } from '../Units/utils';
import { AddButton, FieldWithUnits } from '../common/MeasurementComponents';
import Column from '../../Column';
import Row from '../../Row';
import Cube from '../../Cube';
import { digitOnly } from '../../../../utils/mask';
import MeasurementItemCard from '../../MeasurementItemCard';
import { types } from '../Container/constants';

const MeasurementItem = ({
  item,
  id,
  unitType,
  formName,
  dispatch,
  completed,
  fields,
  listName,
  type,
  formSyncErrors,
  parentFields,
  containerId,
}) => {
  const addItem = () => dispatch(change(formName, `${item}.completed`, true));
  const disableAddItem = !!(formSyncErrors.containers
    && formSyncErrors.containers[containerId]
    && formSyncErrors.containers[containerId].items
    && formSyncErrors.containers[containerId].items[id]);
  const {
    itemWidth,
    itemHeight,
    itemLength,
  } = fields[id];
  if (completed) {
    return (
      <MeasurementItemCard
        key={item}
        formName={formName}
        measurementList={[fields[id]]}
        dispatch={dispatch}
        measurementType={unitType.value}
        order={id}
        currentMeasurementList={fields}
        listName={listName}
        secondary
        hideDangerousItem
        hideButtons={type === types.READ_ONLY}
      />
    );
  }

  return (
    <Item>
      <H2 field={`${t('Item')} ${id + 1}`}/>
      <Row>
        <Column $tablet={5} $mobile={12}>
          <FieldWithUnits
            name={`${item}.itemCount`}
            validate={[isRequired, maxChar6]}
            label={t('NumberOfPieces')}
            required
            normalize={digitOnly}
          />
          <FieldWithUnits
            name={`${item}.itemWeight`}
            validate={[isRequired, maxChar50]}
            label={t('Weight')}
            unitsLabel={WEIGHT}
            required
            unitType={unitType}
            normalize={digitOnly}
          />
          <FieldWithUnits
            name={`${item}.itemLength`}
            validate={[isRequired, maxChar6]}
            label={t('ItemLength')}
            unitsLabel={LENGTH}
            required
            unitType={unitType}
            normalize={digitOnly}
          />
          <FieldWithUnits
            name={`${item}.itemWidth`}
            validate={[isRequired, maxChar6]}
            label={t('ItemWidth')}
            unitsLabel={LENGTH}
            required
            unitType={unitType}
            normalize={digitOnly}
          />
          <FieldWithUnits
            name={`${item}.itemHeight`}
            validate={[isRequired, maxChar6]}
            label={t('ItemHeight')}
            unitsLabel={LENGTH}
            required
            unitType={unitType}
            normalize={digitOnly}
          />
          <Field
            name={`${item}.itemDangerous`}
            type="singleCheckbox"
            label={t('DangerousGoods')}
            component={RenderField}
          />
        </Column>
        <Column $tablet={7} $mobile={12}>
          <Field
            name={`${item}.itemDescription`}
            type="text"
            component={RenderField}
            required
            validate={[isRequired, maxChar50, isValidDescription]}
            label={t('Description')}
          />
          <Column $tablet={12} $mobile={12}>
            <CubeContainer>
              <Cube
                width={itemWidth}
                height={itemHeight}
                length={itemLength}
                measurementType={unitType.value}
              />
            </CubeContainer>
          </Column>
        </Column>
      </Row>
      <Row>
        <Column $tablet={7} $mobile={12}>
          <P
            field={
              parentFields.RestrictedItemsParagraph
            }
          />
        </Column>
        <Column $tablet={5} $mobile={12}>
          <AddButton
            disabled={disableAddItem}
            onClick={(e) => {
              e.preventDefault();
              addItem();
            }}
            title={t('SaveItem')}
          />
        </Column>
      </Row>
    </Item>
  );
};

MeasurementItem.propTypes = {
  item: PropTypes.string,
  id: PropTypes.string,
  unitType: PropTypes.object,
  formName: PropTypes.string,
  dispatch: PropTypes.func,
  completed: PropTypes.bool,
  listName: PropTypes.string,
  type: PropTypes.string,
  containerId: PropTypes.string,
  formSyncErrors: PropTypes.shape({
    containers: PropTypes.objectOf(
      PropTypes.shape({
        items: PropTypes.objectOf(PropTypes.object),
      })
    ),
  }),
  fields: PropTypes.objectOf(
    PropTypes.shape({
      itemWidth: PropTypes.string,
      itemHeight: PropTypes.string,
      itemLength: PropTypes.string,
    })
  ),
  parentFields: PropTypes.shape({
    RestrictedItemsParagraph: PropTypes.string,
  }),
};

export default connect((state, props) => ({
  unitType: formValueSelector(props.formName)(state, 'unitType'),
  completed: formValueSelector(props.formName)(
    state,
    `${props.item}.completed`,
  ),
  formSyncErrors: getFormSyncErrors(props.formName)(state),
}))(MeasurementItem);
