import styled from 'styled-components';
import Row from '../ReactComponent/Row';
import { H1, H2, P } from '../../theme/typographySitecore';

export const HeaderContainer = styled(Row)`
    margin: 45px 0;
`;

export const HeaderTitle = styled(H1)`
    margin-bottom: 3rem;
`;

export const ShippingDetailsPostScript = styled(P)`
    margin-top: 32px;
`;

export const QuestionsBlockContainer = styled.div`
    margin: 64px 0 !important;
`;

export const HelpBox = styled.div`
    margin: 10px;
    background: grey;
`;

export const CategoryTitle = styled(H2)`
    margin-top: 64px;
    margin-bottom: 32px;
`;

export const TextProperty = styled.div`
    font-size: 1.4rem;
    margin-bottom: 32px;
    font-weight: 500;

    .title {
        margin-bottom: 10px;
    }

    .justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    hr {
        border-color: #000;
        margin: 1.5rem 0 3rem;
    }
`;
