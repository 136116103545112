import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { t } from 'i18next';
import { P } from '../../../theme/typography';
import RenderField from '../../ReactComponent/RenderField';
import { PrimaryButton, ButtonGroup, Icon } from '../css';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import { isRequired } from '../../../utils/validator';
import { isReferenceNumberValid, isZipPostalCodeValid } from '../validation';
import ArrowRightWhite from '../../../assets/icons/ArrowRightWhite.svg';
import Tooltip from '../../ReactComponent/Tooltip';
import { FormFieldTooltipContainer } from '../../ReactComponent/Tooltip/helpers';
import { Divisions } from '../../../utils/enums';

const Reference = ({ fields, isAuthenticated }) => (<>
    {fields.referenceInstructions
        && <Row>
            <Column $tablet={8} $mobile={12}>
                <P><RichText field={fields.referenceInstructions} /></P>
            </Column>
        </Row>}
    <Row>
        {!isAuthenticated && <Column $tablet={3} $mobile={12}>
                <Field
                    name="division"
                    type="dropdown"
                    data={[
                      { name: t('SelectaService'), value: Divisions.SelectaService.name },
                      { name: t('CommerceSolutions'), value: Divisions.Sameday.name },
                      { name: t('Freight'), value: Divisions.Freight.name },
                    ]}
                    textField="name"
                    valueField="value"
                    component={RenderField}
                    required
                    validate={[isRequired]}
                    label={t('Service')}
                    style={{ width: '275px' }}
                />
            </Column>}
        <Column $tablet={4} $mobile={12}>
            <Field
                name="referenceNumber"
                type="text"
                component={RenderField}
                required
                validate={[isRequired, isReferenceNumberValid]}
                label={t('ReferenceNumber')}
            />
        </Column>
        <Column $tablet={5} $mobile={12} className="hide-onmobile">
            <ButtonGroup>
                <PrimaryButton type="submit" className="submit">
                    <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                    {t('Track')}
                </PrimaryButton>
            </ButtonGroup>
        </Column>
    </Row>
    {!isAuthenticated && <Row>
        <Column $tablet={4} $mobile={10} className="offset-md-3">
            <Field
                name="postalCode"
                type="text"
                component={RenderField}
                validate={[isZipPostalCodeValid]}
                label={t('TrackShipment_PostalCode')}
            />
        </Column>
        <FormFieldTooltipContainer $mobile={2}>
          <Tooltip tip={t('TipTrackShipmentsPostalCode')}/>
        </FormFieldTooltipContainer>
    </Row>}
    <Row className="show-onmobile">
        <Column $mobile={12}>
            <ButtonGroup>
                <PrimaryButton type="submit" className="submit">
                    <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                    {t('Track')}
                </PrimaryButton>
            </ButtonGroup>
        </Column>
    </Row>
  </>
);

Reference.propTypes = {
  fields: PropTypes.shape({
    referenceInstructions: PropTypes.string,
  }),
  isAuthenticated: PropTypes.bool,
};

export default Reference;
