import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';

export const getUserById = (division, userId) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.users}/${division}/${userId}`);

export const createUser = (data) =>
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.users}`, data);

export const updateUserById = (data, userId) =>
  axios.put(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.users}/${userId}`, data);
export const updateUserProfileById = (data, userId) =>
  axios.put(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.users}/${userId}/profile`, data);

export const deleteUserById = (userId) =>
  axios.delete(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.users}/${userId}`);

export const requestPasswordReset = (userEmail) => {
  const requestBody = { emailAddress: userEmail };
  return axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.users}/password-reset`, requestBody);
};

export const validatePasswordResetToken = ({
  token,
  userEmail
}) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.users}/password-reset`, {
    params: {
      token,
      email: userEmail,
    },
  });

export const passwordReset = ({
  token,
  userEmail,
  newPassword
}) => {
  const requestBody = {
    emailAddress: userEmail,
    newPassword,
    token,
  };
  return axios.put(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.users}/password`, requestBody);
};

export const activate = ({
  token,
  userEmail
}) => {
  const requestBody = {
    emailAddress: userEmail,
    token,
  };
  return axios.put(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.users}/activate`, requestBody);
};

export const getPreferences = (userId, division) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.userPreferences}/${userId}`, {
    params: {
      division,
    },
  });

export const updatePreferences = (userPreferences) =>
  axios.put(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.userPreferences}/${userPreferences.userId}`, userPreferences);

export const createPreferences = (userPreferences) =>
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.userPreferences}`, userPreferences);

export const getManageUserById = (division, userid, userId) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.users}/${division}/${userId}/${userid}`);
