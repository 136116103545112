import React from 'react';
import { Field } from 'redux-form';
import { t } from 'i18next';
import RenderField from '../../../ReactComponent/RenderField';
import {
  isEmail,
  isPhone,
  isRequired,
  maxChar50,
  maxChar25,
} from '../../../../utils/validator';
import Column from '../../../ReactComponent/Column';
import { digitOnly, phoneNumber } from '../../../../utils/mask';
import { H2, H3, P } from '../../../../theme/typographySitecore';
import { AccordionPage } from '../../css';
import AddressComplete from '../../../ReactComponent/AddressComplete';
import { FORM_NAME } from '../../constants';
import Row from '../../../ReactComponent/Row';
import Tooltip from '../../../ReactComponent/Tooltip';

const BillingInformation = ({ USQuote }) => (
  <AccordionPage>
    <H2 field={t('BillingInformation')} verticalOffset={50}/>
    <Row>
      <Column>
        <Field
          name="bankingInstitutionName"
          type="text"
          component={RenderField}
          required
          showErrorAlways
          validate={[isRequired]}
          label={t('CreateAccountBankName')}
        />
      </Column>
    </Row>
    <Row>
      <Column>
        <Field
          name="accountNumber"
          type="text"
          component={RenderField}
          normalize={digitOnly}
          required
          showErrorAlways
          validate={[maxChar50, isRequired]}
          label={t('AccountNumber')}
        />
      </Column>
    </Row>
    <Row>
      <Column>
        {USQuote ? (
          <Field
            name="bankingInstitutionAddress"
            type="text"
            component={RenderField}
            label={t('CreateAccountBankAddress')}
            validate={[maxChar50, isRequired]}
            required
            showErrorAlways
          />
        ) : (
          <AddressComplete
            fieldName="bankingInstitutionAddress"
            formName={FORM_NAME}
            required
            showErrorAlways
          />
        )}
      </Column>
    </Row>
    <Row>
      <Column>
        <Field
          name="dunnBradstreetNo"
          type="text"
          component={RenderField}
          validate={[maxChar25]}
          label={t('DunnBradstreetNumber')}
        />
      </Column>
    </Row>
    <Row>
      <Column>
        <Field
          name="specialBillInstr"
          type="text"
          component={RenderField}
          validate={[maxChar50]}
          label={t('SpecialBillingInstruction')}
        />
      </Column>
    </Row>
    <H2 field={t('CreateAccountCreditReference')} verticalOffset={50}/>
    <Tooltip tip={t('TipReferences')} marginLeft/>
    <P field={t('CreateAccountTransportationReference')}/>
    {[1, 2, 3].map((id) => (
      <div key={id}>
        <H3 field={`${t('Reference')} ${id}`} verticalOffset={40}/>
        <Row>
          <Column>
            <Field
              name={`referenceName${id}`}
              type="text"
              component={RenderField}
              required
              showErrorAlways
              validate={[isRequired]}
              label={t('CreateAccountBusinessName')}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            {USQuote ? (
              <Field
                name={`referenceAddress${id}`}
                type="text"
                component={RenderField}
                label={t('Address')}
                validate={[maxChar50, isRequired]}
                required
                showErrorAlways
              />
            ) : (
              <AddressComplete
                fieldName={`referenceAddress${id}`}
                formName={FORM_NAME}
                required
                showErrorAlways
              />
            )}
          </Column>
        </Row>
        <Row>
          <Column $tablet={7} $mobile={12}>
            <Field
              name={`referencePhone${id}`}
              type="text"
              component={RenderField}
              required
              showErrorAlways
              validate={[isPhone, maxChar50, isRequired]}
              normalize={phoneNumber}
              label={t('PhoneNumber')}
            />
          </Column>
          <Column $tablet={5} $mobile={12}>
            <Field
              name={`referenceFaxPhone${id}`}
              type="text"
              component={RenderField}
              validate={[maxChar50]}
              normalize={digitOnly}
              label={t('Fax')}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Field
              name={`referenceEmail${id}`}
              type="email"
              component={RenderField}
              required
              showErrorAlways
              validate={[isRequired, isEmail]}
              label={t('EmailAddress')}
            />
          </Column>
        </Row>
      </div>
    ))}
  </AccordionPage>
);

export default BillingInformation;
