/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  reduxForm, change, getFormValues, reset, getFormInitialValues, SubmissionError, getFormSyncErrors, touch, updateSyncErrors
} from 'redux-form';
import { t } from 'i18next';
import { MDBDropdown } from 'mdbreact';
import Modal from 'react-modal';
import { Beforeunload } from 'react-beforeunload';
import { getAccessorials } from '../CreateShipment/api';
import FormProgressBar from '../ReactComponent/FormProgressBar';
import SignupBanner from '../ReactComponent/SignupBanner';
import SecondaryButton from '../ReactComponent/SecondaryButton';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowLeftOrange from '../../assets/icons/ArrowLeftOrange.svg';
import { putReduxServiceLevelQuotes, putReduxServiceLevelCode } from './actions';
import { endpoints, routes, formNames } from '../../utils/constants';
import {
  getAddress,
  getZip,
  getCity,
  getState,
  getCountry,
  validatePostal,
  scrollToTop,
  openFileInNewTab,
  isCanadianAddress,
  checkBillToAccountProtectedStatus,
  scrollToRef
} from '../../utils/func';
import { measurementValidate, listCompleted } from '../../utils/validator';
import { digitOnly, phoneNumber } from '../../utils/mask';
import handleDeliveryType from '../../utils/deliveryAddress';
import {
  QuoteTypes, DeliveryTypes, AddressTypes, ShipmentTypes, Divisions, PaymentMethods, SamedayServiceType, CommerceServiceType
} from '../../utils/enums';
import {
  Icon,
  PrimaryButton,
  ButtonGroup,
  FormContainer,
  ModalTitle,
  ModalBody,
  ButtonDiv
} from './css';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import QuestionsBlock from '../ReactComponent/QuestionsBlock';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import PrintGrey from '../../assets/icons/PrintGrey.svg';
import PrintWhite from '../../assets/icons/PrintWhite.svg';
import DeleteWhite from '../../assets/icons/DeleteWhite.svg';
import CreateQuoteFromExistingWhite from '../../assets/icons/CreateQuoteFromExistingWhite.svg';
import CreateQuoteFromExistingGrey from '../../assets/icons/CreateQuoteFromExistingGrey.svg';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import { getQuoteDocument, getQuoteByDivisionAndQuoteNumber } from '../../services/quotes';
import { LastActions } from '../CreateShipment/css';
import { defaultLanguage } from '../../temp/config';
import { list as unitsList } from '../ReactComponent/Measurement/Units/utils';
import { arrayLengthSafe } from '../../utils/arrays';
import { validateTariffZone } from '../../services/tariffService';
import AddressPickerModal from '../ReactComponent/AddressPickerModal';
import { getPreferences } from '../../services/users';
import { getCompaniesList } from '../../services/companies';
import { getCompanyContacts } from '../../services/contacts';
import putReduxAllCompanies from '../ManageCompanies/actions';
import putReduxAllContacts from '../ManageContacts/actions';

export const getTotalWeight = (list = []) => list.map((item) => item.itemWeight).reduce((accum, curr) => Number(accum) + Number(curr), 0);
const formName = formNames.createQuote;

const initialValues = {
  division: Divisions.Freight.name,
  deliveryType: { name: DeliveryTypes.Commercial, desc: 'CommercialServiceDesc' },
  measurementType: unitsList[0],
  quoteType: { name: QuoteTypes.Domestic, desc: 'DomesticQuoteDesc' },
  userAddressType: AddressTypes.SHIPPER,
  paymentMethod: { name: PaymentMethods.Prepaid, desc: 'PrepaidDesc' },
  accessorials: {},
  dangerDisable: false,
  measurementList: [{}],
  isNonServiceableLocation: false,
  isWinterNonServiceableLocation: false
};
class CreateQuoteForm extends PureComponent {
  constructor(props) {
    super(props);
    const { fields } = props;
    this.steps = [fields.step1Title.value, fields.step2Title.value, fields.step3Title.value, fields.step4Title.value, fields.step5Title.value];
    this.state = {
      currentStep: 0,
      tariffOptions: [],
      data: {},
      modalIsOpen: false,
      errorMessage: '',
      accList: [],
      submitFailure: false,
      isProtectedAccount: false,
      isManageContactPermission: false,
      embargoErrorMessage: false
    };
    this.addressFieldsRef = React.createRef();
  }

  updateState = (name, value) => this.setState({ [name]: value });

  componentDidMount = async () => {
    const {
      isAuthenticated, dispatch, division, userEmail, userId, formData
    } = this.props;
    if (isAuthenticated) {
      dispatch(change(formName, 'quoteDest', userEmail));
    }
    this.setInitialPaymentMethod();
    let urlParams;
    if (typeof window !== 'undefined') {
      urlParams = new URLSearchParams(window.location.search);
      const divisionQueryParam = urlParams.get('division');
      if (divisionQueryParam) {
        dispatch(change(formName, 'division', divisionQueryParam));
      } else if (isAuthenticated) {
        // Get value from store, which was populated from storage...
        dispatch(change(formName, 'division', division));
      }

      getAccessorials({
        isAuthenticated, division: divisionQueryParam || division || Divisions.Freight.name, updateState: this.updateState, billTo: ''
      }).then(() => {
        if (urlParams.get('recreate')) {
          this.getQuoteDetails(urlParams.get('division'), urlParams.get('quoteNumber'));
        }
      });
    }
    checkBillToAccountProtectedStatus(this.props.dispatch, this.props.division, this.props.userId, this.props.billingAccounts);
    const USQuote = (formData && formData.quoteType && formData.quoteType.name === QuoteTypes.USQuote);
    if (USQuote) {
      this.getUSQuoteDetails(userId);
    } else {
      this.getUserPrefSamedayDetails(userId);
    }
  };

  formUserPrefApiValueToData = (data) => {
    const { dispatch, division } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const dontUsePref = (urlParams.get('quoteNumber') || urlParams.get('shipmentId') || urlParams.get('probillNumber')) ? true : false;
    const { serviceType } = data;
    return {
      ...data,
      fromContactId: dontUsePref ? undefined : data.fromContactId,
      toContactId: dontUsePref ? undefined : data.toContactId,
      billTo: dontUsePref ? undefined : dispatch(change(formName, 'billTo', data.billToAccountNumber)),
      deliveryType: (division === Divisions.Sameday.name) ? this.setDeliveryType(serviceType) : undefined
    };
  };

  getQuoteDetails(division, quoteNumber) {
    getQuoteByDivisionAndQuoteNumber(division, quoteNumber).then((res) => {
      if (res.status === 200) {
        const { data } = res;
        const { accList } = this.state;
        data.accList = accList;
        const formedData = this.formApiToData(res.data);
        this.setState({ data: formedData }, () => {
          this.dispatchFormValues(formedData);
        });
      }
    });
  }

  getUSQuoteDetails(userId) {
    const { division } = this.props;
    getPreferences(userId, division).then((response) => {
      if (response.data) {
        const { data } = response;
        this.formUserPrefUSQuoteApiValueToData(data);
        this.setState({ ...data });
      }
    });
  }

  getUserPrefSamedayDetails(userId) {
    const { division } = this.props;
    getPreferences(userId, division).then((response) => {
      if (response.data) {
        const { data } = response;
        this.formUserPrefApiValueToData(data);
        this.setState({ ...data });
      }
    });
  }

  setInitialPaymentMethod = () => {
    const { dispatch, isAuthenticated } = this.props;
    if (isAuthenticated) {
      dispatch(change(formName, 'paymentMethod', { name: PaymentMethods.Prepaid, desc: 'PrepaidDesc' }));
    } else {
      dispatch(change(formName, 'paymentMethod', { name: PaymentMethods.CreditCard, desc: 'CreditCardDesc' }));
    }
  };

  setPaymentMethod = (paymentMethod) => {
    const { dispatch } = this.props;
    let newPaymentMethod;

    if (paymentMethod === PaymentMethods.Collect) {
      newPaymentMethod = {
        name: PaymentMethods.Collect,
        desc:
          'CollectDesc'
      };
    }

    if (paymentMethod === PaymentMethods.Prepaid) {
      newPaymentMethod = {
        name: PaymentMethods.Prepaid,
        desc:
          'PrepaidDesc'
      };
    }

    if (paymentMethod === PaymentMethods.ThirdParty) {
      newPaymentMethod = {
        name: PaymentMethods.ThirdParty,
        desc:
          'ThirdPartyDesc'
      };
    }

    dispatch(change(formName, 'paymentMethod', newPaymentMethod));
  };

  setDeliveryType = (serviceType) => {
    const { dispatch } = this.props;
    let newDeliveryType;

    if (serviceType === SamedayServiceType.Commercial) {
      newDeliveryType = { name: DeliveryTypes.Commercial, desc: 'CommercialServiceDesc' };
    }

    if (serviceType === SamedayServiceType.Residential || serviceType === CommerceServiceType.Residential) {
      newDeliveryType = { name: DeliveryTypes.Residential, desc: 'ResidentialServiceDesc' };
    }

    dispatch(change(formName, 'deliveryType', newDeliveryType));
  };

  dispatchFormValues = (data) => {
    const { dispatch } = this.props;
    const { serviceType, paymentMethod } = data;

    Object.keys(data).map((field) => !(field === 'paymentMethod' || field === 'serviceType') && dispatch(change(formName, field, data[field])));
    if (serviceType) this.setDeliveryType(serviceType);
    if (paymentMethod) this.setPaymentMethod(paymentMethod);
  };

  formUserPrefUSQuoteApiValueToData = (data) => {
    const { dispatch, division } = this.props;
    const {
      shipperName,
      shipperPostalCode,
      consigneePostalCode,
      billToAccountNumber
    } = data;
    if (billToAccountNumber) {
      const { accountNumber } = billToAccountNumber;
      this.getFuelSurcharges(division, accountNumber || billToAccountNumber);
    }

    return {
      ...data,
      billTo: dispatch(change(formName, 'billTo', billToAccountNumber)),
      shipperContactName: dispatch(change(formName, 'shipperContactName', shipperName)),
      shipperAddress: dispatch(change(formName, 'shipperAddress', shipperPostalCode)),
      consigneeAddress: dispatch(change(formName, 'consigneeAddress', consigneePostalCode))
    };
  };

  formApiToData = (rawData) => {
    const {
      division,
      paymentType,
      from,
      to,
      items,
      measurementType,
      billToAccountNumber,
      quoteEmail,
      accessorials,
      accList,
      serviceType,
      expiryDate,
      totalAmount,
      serviceLevelCode,
      serviceLevelDescription,
      emailAddress
    } = rawData;
    const shipperAddress = `${from.address1}, ${from.city}, ${from.provinceCode} ${from.postalCode}, ${from.countryCode}`;
    const consigneeAddress = `${to.address1}, ${to.city}, ${to.provinceCode} ${to.postalCode}, ${to.countryCode}`;
    const measurementList = items.map((each) => ({
      itemWeight: each.weight,
      itemLength: each.length,
      itemWidth: each.width,
      itemHeight: each.height,
      itemCount: each.pieces,
      itemDescription: each.description,
      itemWeightUnit: each.weightUnitType,
      itemLengthUnitType: each.lengthUnitType,
      completed: true,
    }));
    const accCodesList = accessorials.map((a) => a.code.trim());
    const accessorialsList = accList ? accList
      .filter((each) => accCodesList.includes(each.code))
      .map((each) => {
        const accessorialFromBackend = accessorials.find((a) => a.code === each.code);
        return {
          [each.code]: {
            selected: ['',
              {
                name: each.description,
                code: each.code,
                accessorialId: each.accessorialId
              }],
            value: accessorialFromBackend.amount && accessorialFromBackend.amount.value,
            currency: accessorialFromBackend.amount && accessorialFromBackend.amount.currencyCode,
          }
        };
      })
      .reduce((curr, accum) => ({ ...accum, ...curr }), {}) : '';
    return {
      division,
      paymentMethod: paymentType,

      shipperCompanyName: from.companyName,
      shipperContactName: from.contactName,
      shipperAddress,
      shipperAddressAdditional: from.address2,
      shipperEmail: from.contactEmail,
      shipperPhoneNumber: from.contactPhoneNumber && phoneNumber(from.contactPhoneNumber),
      shipperPhoneNumberExt: from.contactPhoneNumberExt,

      consigneeCompanyName: to.companyName,
      consigneeContactName: to.contactName,
      consigneeAddress,
      consigneeAddressAdditional: to.address2,
      consigneeEmail: to.contactEmail,
      consigneePhoneNumber: to.contactPhoneNumber && phoneNumber(to.contactPhoneNumber),
      consigneePhoneNumberExt: to.contactPhoneNumberExt,

      accessorials: accessorialsList,
      measurementList,
      measurementType: unitsList.find((mt) => mt.value === measurementType),
      billTo: billToAccountNumber,
      quoteDest: quoteEmail || emailAddress,
      serviceType,
      expiryDate,
      totalAmount: totalAmount && totalAmount.value ? totalAmount.value : 0,
      serviceLevelCode,
      serviceLevelDescription,
    };
  };

  getFuelSurcharges = (division, billTo) => {
    axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.tariffs}/${division}/discount`,
      {
        headers:
          {
            accountNumber: billTo
          }
      }).then((res) => {
      if (res.status === 200) {
        this.updateState('tariffOptions', res.data);
        const { formData: { tariffSurcharge }, dispatch } = this.props;
        if (!tariffSurcharge) {
          dispatch(change(formName, 'tariffSurcharge', res.data[0]));
        }
      }
    });
  };

  nextStep = async (e) => {
    e?.preventDefault();
    const {
      formData, division, formData: {
        measurementList, quoteType, billTo, usFromAddress, usToAddress, tariffSurcharge
      },
      valid, dispatch, isAuthenticated, formSyncErrors
    } = this.props;
    const {
      currentStep, accList, isProtectedAccount, isNonServiceableLocation
    } = this.state;
    // eslint-disable-next-line
    const hasMeasureMents = currentStep !== 1 ? true : measurementList ? true : false;
    const USQuote = quoteType && quoteType.name === QuoteTypes.USQuote;
    const shipperCountry = getCountry(formData.shipperAddress);
    const consigneeCountry = getCountry(formData.consigneeAddress);
    if (valid && hasMeasureMents) {
      if (
        currentStep === 0
        && (t('EmbargoFlag') === 'ON')
        && ((formData.shipperAddress && formData.shipperAddress.includes('BC'))
        || (formData.consigneeAddress && formData.consigneeAddress.includes('BC'))
        || (formData.shipperAddress && formData.shipperAddress.includes('AB'))
        || (formData.consigneeAddress && formData.consigneeAddress.includes('AB'))
        || (formData.shipperAddress && formData.shipperAddress.includes('SK'))
        || (formData.consigneeAddress && formData.consigneeAddress.includes('SK'))
        || (formData.shipperAddress && formData.shipperAddress.includes('MB'))
        || (formData.consigneeAddress && formData.consigneeAddress.includes('MB')))) {
        if ((shipperCountry !== 'US') && (consigneeCountry !== 'US')) {
          const errorMessage = t('EmbargoWarning');
          this.setState({ modalIsOpen: true, errorMessage, embargoErrorMessage: true });
        }
      }
      if (currentStep === 0 && !isProtectedAccount && !isNonServiceableLocation) {
        const addressFields = ['shipperAddress', 'consigneeAddress'];
        if (USQuote) {
          await Promise.all(
            addressFields.map(
              (fieldName) => validatePostal(formData[fieldName], formData.division || division || Divisions.Freight.name).then((msg) => {
                const error = formSyncErrors;
                if (!Array.isArray(msg)) {
                  error[fieldName] = msg;
                } else {
                  dispatch(change(formName, fieldName === 'shipperAddress' ? 'usFromAddress' : 'usToAddress', msg[0]));
                  error[fieldName] = undefined;
                }
                if (error[fieldName]) {
                  scrollToRef(this.addressFieldsRef);
                  dispatch(touch(formName, fieldName));
                  throw dispatch(updateSyncErrors(formName, error));
                }
              })
            )
          );
          validateTariffZone({
            accountNumber: isAuthenticated ? billTo : '999998',
            fromPostalCode: (usFromAddress === undefined) ? formData.shipperAddress : (usFromAddress && usFromAddress.postalCode) || usFromAddress,
            toPostalCode: (usToAddress === undefined) ? formData.consigneeAddress : (usToAddress && usToAddress.postalCode) || usToAddress,
            originZone: tariffSurcharge && tariffSurcharge.origin,
            destinationZone: tariffSurcharge && tariffSurcharge.destination
          }).then((res) => {
            if (!res.data) {
              const errorMessage = t('IncorrectTariffZone');
              this.setState({ modalIsOpen: true, errorMessage });
              return;
            }
            this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
              setTimeout(() => scrollToTop(), 200);
            });
          });
        } else {
          validatePostal(getZip(formData.shipperAddress), formData.division || division || Divisions.Freight.name).then((msg) => {
            const error = formSyncErrors;
            if (!Array.isArray(msg)) {
              error.shipperAddress = msg;
            } else {
              error.shipperAddress = undefined;
              if (!msg.find((c) =>
                c.cityName && c.cityName.toLowerCase() === getCity(formData.shipperAddress).toLowerCase()
              )) {
                this.setState({ addressPickerModalOpen: true, AddressOptions: msg.filter((a) => a.cityName), addressPickField: 'shipperAddress' });
                return;
              }
            }
            if (error.shipperAddress) {
              scrollToRef(this.addressFieldsRef);
              dispatch(touch(formName, 'shipperAddress'));
              throw dispatch(updateSyncErrors(formName, error));
            }
            // eslint-disable-next-line no-shadow
            validatePostal(getZip(formData.consigneeAddress), formData.division || division || Divisions.Freight.name).then((msg) => {
              // eslint-disable-next-line no-shadow
              const error = formSyncErrors;
              if (!Array.isArray(msg)) {
                error.consigneeAddress = msg;
              } else {
                error.consigneeAddress = undefined;
                if (!msg.find((c) =>
                  c.cityName && c.cityName.toLowerCase() === getCity(formData.consigneeAddress).toLowerCase()
                )) {
                  this.setState(
                    { addressPickerModalOpen: true, AddressOptions: msg.filter((a) => a.cityName), addressPickField: 'consigneeAddress' }
                  );
                  return;
                }
              }
              if (error.consigneeAddress) {
                scrollToRef(this.addressFieldsRef);
                dispatch(touch(formName, 'consigneeAddress'));
                throw dispatch(updateSyncErrors(formName, error));
              }
              this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
                setTimeout(() => scrollToTop(), 200);
              });
            });
          });
        }
        dispatch(change(formName, ShipmentTypes.US_SHIPMENT, USQuote));
      }

      if (currentStep === 1) {
        if (!listCompleted(measurementList)) {
          return;
        }
        if (USQuote) {
          const adminSelected = [''].concat(
            accList.filter((acc) => acc.code === 'ADMIN').map((acc) => ({ name: acc.description, code: acc.code, accessorialId: acc.accessorialId }))
          );
          dispatch(change(formName, 'accessorials.ADMIN.selected', adminSelected));
        }
        this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
          setTimeout(() => scrollToTop(), 200);
        });
      }
      if (currentStep === 2) {
        if (isAuthenticated) {
          // check if selected bill to account number is protected
          checkBillToAccountProtectedStatus(this.props.dispatch, this.props.division, this.props.userId, this.props.billingAccounts);
        }
        this.getServiceQuotes().then(
          this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
            setTimeout(() => scrollToTop(), 200);
          })
        );
      }
    }
  };

  prevStep = (e) => {
    e?.preventDefault();
    this.scrollToTop();
    this.setState((prevState) => ({ currentStep: prevState.currentStep - 1 }));
  };

  scrollToTop = () => window.scrollTo(0, 0);

  editStepOne = (e) => {
    e?.preventDefault();
    this.setState({ currentStep: 0 });
  };

  editStepTwo = (e) => {
    e?.preventDefault();
    this.setState({ currentStep: 1 });
  };

  editStepThree = (e) => {
    e?.preventDefault();
    this.setState({ currentStep: 2 });
  };

  getServiceQuotes = (serviceLvlCode) => {
    const {
      isAuthenticated,
      // eslint-disable-next-line no-shadow
      putReduxServiceLevelQuotes,
      // eslint-disable-next-line no-shadow
      putReduxServiceLevelCode,
      fields,
      formData: {
        quoteType,
        usFromAddress,
        usToAddress,
        deliveryType,
        division,
        paymentMethod,
        shipperContactName,
        shipperCompanyName,
        shipperAddress,
        shipperAddressAdditional,
        shipperEmail,
        shipperPhoneNumber,
        shipperPhoneNumberExt,
        consigneeAddressAdditional,
        consigneeEmail,
        consigneePhoneNumber,
        consigneeAddress,
        consigneeContactName,
        consigneeCompanyName,
        consigneePhoneNumberExt,
        measurementType,
        measurementList,
        accessorials,
        quoteDest,
        tariffSurcharge,
        userAddressType,
        billTo
      }
    } = this.props;
    const { currentStep } = this.state;
    const measurementLists = measurementList.map((each) => ({
      measurementType: measurementType.value,
      weight: each.itemWeight,
      length: each.itemLength,
      width: each.itemWidth,
      height: each.itemHeight,
      pieces: each.itemCount,
      description: each.itemDescription,
      class: each.itemClass,
      isDangerousGoods: each.itemDangerous
    }));

    const accessorialsLists = accessorials ? Object.values(accessorials).filter((acc) => acc.selected.length > 1).map((each) => {
      const { selected, value, currency } = each;
      if (value && currency) {
        return {
          accessorialId: selected[1].accessorialId ? selected[1].accessorialId : 1,
          code: selected[1].code,
          value: Number(value),
          currencyCode: currency
        };
      }
      return {
        accessorialId: selected[1].accessorialId ? selected[1].accessorialId : 1,
        code: selected[1].code
      };
    }) : '';

    const USQuote = quoteType && quoteType.name === QuoteTypes.USQuote;

    let shipperAddress1;
    let shipperCity;
    let shipperState;
    let shipperZip;
    let shipperCountry;

    let consigneeAddress1;
    let consigneeCity;
    let consigneeState;
    let consigneeZip;
    let consigneeCountry;

    if (USQuote) {
      shipperCity = usFromAddress.cityName;
      shipperState = usFromAddress.provinceCode;
      shipperZip = usFromAddress.postalCode;
      shipperCountry = usFromAddress.countryCode;

      consigneeCity = usToAddress.cityName;
      consigneeState = usToAddress.provinceCode;
      consigneeZip = usToAddress.postalCode;
      consigneeCountry = usToAddress.countryCode;
    } else {
      shipperAddress1 = getAddress(shipperAddress);
      shipperCity = getCity(shipperAddress);
      shipperState = getState(shipperAddress);
      shipperZip = getZip(shipperAddress);
      shipperCountry = getCountry(shipperAddress);

      consigneeAddress1 = getAddress(consigneeAddress);
      consigneeCity = getCity(consigneeAddress);
      consigneeState = getState(consigneeAddress);
      consigneeZip = getZip(consigneeAddress);
      consigneeCountry = getCountry(consigneeAddress);
    }

    const formValueToApi = {
      isGuestUser: !isAuthenticated,
      serviceLevelCode: serviceLvlCode,
      serviceType: (deliveryType && deliveryType.name === DeliveryTypes.Residential) ? SamedayServiceType.Residential : SamedayServiceType.Commercial,
      paymentType: !USQuote ? paymentMethod.name.split(' ').join('') : undefined,
      language: 'EN',
      accountNumber: (this.props.isAuthenticated && billTo) || '',
      customerId: '176692', // tbr
      division,
      discountPercentage: (tariffSurcharge && tariffSurcharge.discountPercentage) ? tariffSurcharge.discountPercentage : undefined,
      minimumRateAmount: (
        tariffSurcharge
        && tariffSurcharge.minimumRateAmount
        && tariffSurcharge.minimumRateAmount.value) ? tariffSurcharge.minimumRateAmount.value : undefined,
      measurementType: measurementType.value,
      requestor: {
        companyName: shipperCompanyName,
        contactName: shipperContactName,
        address1: shipperAddress1,
        address2: shipperAddressAdditional,
        city: shipperCity,
        provinceCode: shipperState,
        countryCode: shipperCountry,
        postalCode: shipperZip,
        contactPhoneNumber: digitOnly(shipperPhoneNumber),
        contactPhoneNumberExt: shipperPhoneNumberExt,
        contactEmail: quoteDest
      },
      from: {
        companyName: shipperCompanyName,
        contactName: shipperContactName,
        address1: shipperAddress1,
        address2: shipperAddressAdditional,
        city: shipperCity,
        provinceCode: shipperState,
        countryCode: shipperCountry,
        postalCode: shipperZip,
        contactPhoneNumber: digitOnly(shipperPhoneNumber),
        contactPhoneNumberExt: shipperPhoneNumberExt,
        contactEmail: shipperEmail
      },
      to: {
        companyName: consigneeCompanyName,
        contactName: consigneeContactName,
        address1: consigneeAddress1,
        address2: consigneeAddressAdditional,
        city: consigneeCity,
        provinceCode: consigneeState,
        countryCode: consigneeCountry,
        postalCode: consigneeZip,
        contactPhoneNumber: digitOnly(consigneePhoneNumber),
        contactPhoneNumberExt: consigneePhoneNumberExt,
        contactEmail: consigneeEmail
      },
      shipmentItems: [
        ...measurementLists
      ],
      accessorials: [
        ...accessorialsLists
      ]
    };
    if (serviceLvlCode || (currentStep === 3)) {
      formValueToApi.quoteEmail = quoteDest;
      formValueToApi.addressType = userAddressType;
      formValueToApi.createdBy = quoteDest;
      return axios.post(
        `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${USQuote ? endpoints.shipping.usQuote : endpoints.shipping.quotes}`, formValueToApi
      ).then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (USQuote) {
            this.setState((prevState) => ({
              data: {
                ...prevState.data,
                quoteNumber: res.data.quoteNumber,
              },
            }));
          } else if (res.data.exception === 'NullValueDatabaseError') {
            const errorMessage = t(fields.InsertTheValueNull.value);
            this.setState({
              modalIsOpen: true, errorMessage, submitFailure: true, currentStep: 0
            });
            return;
          } else {
            this.setState({ data: res.data, submitFailure: false, currentStep: 4 });
          }
          this.scrollToTop();
        }
      }).catch(() => {
        throw new SubmissionError({});
      });
    }
    return axios.post(
      `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${USQuote ? endpoints.shipping.usQuoteRate : endpoints.shipping.serviceLevel}`,
      formValueToApi).then((res) => {
      if (res.status === 200) {
        if (USQuote) {
          this.setState({ data: res.data });
        } else {
          putReduxServiceLevelQuotes(res.data.serviceLevels);
          putReduxServiceLevelCode(handleDeliveryType(deliveryType, res));
        }
      }
    }).catch(() => {
      if (USQuote) {
        const errorMessage = t(fields.NonServiceableLocationError.value);
        this.setState({
          modalIsOpen: true, errorMessage, currentStep: 0, isNonServiceableLocation: true
        });
      } else {
        if (division === Divisions.Sameday.name) {
          const errorMessage = t(fields.WinterNonServiceableLocationWarning.value);
          this.setState({
            modalIsOpen: true, errorMessage, currentStep: 0, isWinterNonServiceableLocation: true
          });
        }
        this.prevStep();
      }
    });
  };

  handleSubmit = async () => {
    const { serviceLevelCode } = this.props;
    const { currentStep } = this.state;
    await this.getServiceQuotes(serviceLevelCode).catch(() => {
      throw new SubmissionError({});
    });
    if (currentStep === 3) {
      this.scrollToTop();
      this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
        setTimeout(() => scrollToTop(), 200);
      });
    }
  };

  changeBillTo = (billTo) => {
    const {
      division, dispatch, formData, isAuthenticated, billingAccounts
    } = this.props;
    const { accountNumber } = billTo;
    const USQuote = formData && formData.quoteType && formData.quoteType.name === QuoteTypes.USQuote;
    const hasSingularAccount = arrayLengthSafe(billingAccounts) === 1;
    dispatch(change(formName, 'tariffSurcharge', null));
    if (billTo.protected === true && isAuthenticated) {
      this.setState({ isProtectedAccount: true, modalIsOpen: true });
    } else {
      this.setState({ isProtectedAccount: false, modalIsOpen: false });
      if (USQuote) {
        this.getFuelSurcharges(division, accountNumber || billTo);

        getAccessorials({
          isAuthenticated, division, updateState: this.updateState, USQuote, billTo: (accountNumber || billTo) ? (accountNumber || billTo) : ''
        }).then(() => {
          const { accList } = this.state;
          if (accList.length === 0) {
            if (!hasSingularAccount) {
              dispatch(change(formName, 'billTo', null));
            }
            const errorMessage = t('AccNumNoUSQuote', { accountNumber });
            this.setState({ modalIsOpen: true, errorMessage });
          }
        });
      }
    }
  };

  openModal = (newObj, obj, prevObj, fieldName) => {
    const {
    // eslint-disable-next-line no-shadow
      formData, initialValues, dispatch, userId, userEmail, division, isAuthenticated
    } = this.props;
    this.setState({ fieldName, prevObj, newObj });
    const initialObjectValues = JSON.parse(JSON.stringify(initialValues));
    const fromObjectValues = JSON.parse(JSON.stringify(formData));

    delete initialObjectValues.deliveryType;
    delete initialObjectValues.quoteType;
    delete initialObjectValues.division;
    delete fromObjectValues.quoteType;
    delete fromObjectValues.deliveryType;
    delete fromObjectValues.tariffSurcharge;
    delete fromObjectValues.division;

    if (Object.keys(fromObjectValues).length > Object.keys(initialObjectValues).length) {
      this.setState({ modalIsOpen: true });
    } else {
      dispatch(reset(formName));
      if (isAuthenticated) {
        this.changeDivisionAndQuoteType(fieldName ? Divisions.Freight.name : newObj);
      } else {
        this.setState({ fieldName, prevObj, newObj }, this.changeUnAuthDivisionAndQuoteType(fieldName ? Divisions.Freight.name : newObj, newObj)
        );
      }
      dispatch(change(formName, 'quoteDest', userEmail));
      const DomesticQuote = (formData && formData.quoteType && formData.quoteType.name === QuoteTypes.Domestic);
      if (division === Divisions.Sameday.name || DomesticQuote) {
        this.getUserPrefSamedayDetails(userId);
      } else {
        this.getUSQuoteDetails(userId);
      }
    }
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      fieldName: undefined,
      prevObj: undefined,
      newObj: undefined,
      errorMessage: undefined,
      isWinterNonServiceableLocation: false,
      embargoErrorMessage: false
    });
  };

  initialCalls = (division, userId) => {
    getCompaniesList(division, userId).then(
      (cl) => {
        // eslint-disable-next-line no-shadow
        const { putReduxAllCompanies, putReduxAllContacts } = this.props;
        putReduxAllCompanies(cl.data);
        this.fetchAllContacts(cl.data).then((res) => {
          const formedContactList = res;
          putReduxAllContacts(formedContactList);
        });
      }
    );
  };

  fetchAllContacts = (companies) => {
    if (companies) {
      return Promise.all(companies.map((comp) => getCompanyContacts(comp.companyId))).then((contactRequests) => {
        let allContacts = [];
        contactRequests.forEach((contactRequest) => {
          if (!contactRequest.data || contactRequest.data.length === 0) {
            return;
          }
          allContacts = allContacts.concat(contactRequest.data);
        });
        return allContacts;
      });
    }
    return null;
  };

  handleModalButtons = (event) => {
    event?.preventDefault();
    const {
      dispatch, formData, userId, userEmail, division
    } = this.props;
    const { fieldName, prevObj, newObj } = this.state;
    const {
      target: { name }
    } = event;

    if (name === 'continueButton') {
      dispatch(reset(formName));
      this.changeDivisionAndQuoteType(fieldName ? Divisions.Freight.name : newObj);
      dispatch(change(formName, 'quoteDest', userEmail));
      const USQuote = (formData && formData.quoteType && formData.quoteType.name === QuoteTypes.USQuote);
      if (USQuote) {
        this.getUSQuoteDetails(userId);
      } else {
        this.getUserPrefSamedayDetails(userId);
        this.initialCalls(division, userId);
      }
    } else if (name === 'cancelButton') {
      dispatch(change(formName, fieldName, prevObj));
    } else if (name === 'returnToStepOne') {
      this.setState({ currentStep: 0 });
    } else if (name === 'createANewQuote') {
      window.location.href = routes.createQuote;
    } else if (name === 'returnToStepTwo') {
      // this.setState({ currentStep: 1})
      setTimeout(() => scrollToTop(), 200);
    }
    this.closeModal();
  };

  changeDivisionAndQuoteType = (division) => {
    const { dispatch, isAuthenticated, formData } = this.props;
    const { fieldName, newObj } = this.state;
    const USQuote = (formData && formData.quoteType && formData.quoteType.name === QuoteTypes.USQuote);
    dispatch(change(formName, 'division', division));
    if (fieldName) {
      dispatch(change(formName, fieldName, newObj));
    }
    if (!isAuthenticated) {
      this.getFuelSurcharges(division, isAuthenticated && formData.billTo ? formData.billTo : '');
    }
    this.setInitialPaymentMethod();
    if (division === 'Freight') {
      if (USQuote) {
        getAccessorials({
          isAuthenticated, USQuote: true, division: division || Divisions.Freight.name, updateState: this.updateState, billTo: ''
        });
      } else {
        getAccessorials({
          isAuthenticated, division: division || Divisions.Freight.name, updateState: this.updateState, billTo: ''
        });
      }
    }
  };

  changeUnAuthDivisionAndQuoteType = (division, currentObj) => {
    const { dispatch, isAuthenticated, formData } = this.props;
    const { fieldName, newObj } = this.state;
    dispatch(change(formName, 'division', division));
    if (fieldName) {
      dispatch(change(formName, fieldName, newObj));
    }
    if (!isAuthenticated) {
      this.getFuelSurcharges(division, isAuthenticated && formData.billTo ? formData.billTo : '');
    }
    this.setInitialPaymentMethod();
    if (currentObj && currentObj.name === 'US Quote' && division === 'Freight') {
      getAccessorials({
        isAuthenticated, division: Divisions.Freight.name, updateState: this.updateState, USQuote: true, billTo: ''
      });
    } else if ((currentObj && currentObj.name === 'Domestic Quote' && division === 'Freight') || division === currentObj) {
      getAccessorials({
        isAuthenticated: false, division: division || Divisions.Freight.name, updateState: this.updateState, USQuote: false, billTo: ''
      });
    }
  };

  openBOL = ({
    division, quoteNumber, language, USQuote
  }) => {
    getQuoteDocument({
      division, quoteNumber, language, USQuote
    })
      .then((res) => {
        if (res.status === 200) {
          openFileInNewTab({ dataAsBase64: res.data.fileDataAsBase64, mimeType: res.data.mimeType, fileName: res.data.fileName });
        }
      });
  };

  openDeleteModal = () => {
    const { quoteNumber } = this.state;
    const errorMessage = t(`Unable to delete Quote ${quoteNumber}.`);
    this.setState({ modalIsOpen: true, errorMessage });
  };

  handleShipNow = () => {
    const { isAuthenticated, formData } = this.props;
    const { data } = this.state;
    window.location.href =
    `${isAuthenticated
      ? routes.createShipment
      : routes.createPickup}?division=${formData && formData.division}&quoteNumber=${data && data.quoteNumber}`;
    return window.location.href;
  };

  handleAddressPickSubmit = (selectedAddress) => {
    const { dispatch, formData } = this.props;
    const { addressPickField } = this.state;
    const newVal = formData[addressPickField].replace(getCity(formData[addressPickField]), selectedAddress.cityName);
    dispatch(change(formName, addressPickField, newVal));
    this.closeAddressModal(selectedAddress);
  };

  closeAddressModal = (selectedAddress) => {
    this.setState({ addressPickerModalOpen: false, AddressOptions: [] },
      () => {
        if (selectedAddress && selectedAddress.cityName) {
          this.nextStep();
          return null;
        }
        const { dispatch, formSyncErrors } = this.props;
        const { addressPickField } = this.state;
        const error = formSyncErrors;
        error[addressPickField] = t('AddressNotFound');
        return dispatch(updateSyncErrors(formName, error));
      });
  };

  handleNonserviceableLocation = () => {
    this.setState({ isNonServiceableLocation: false });
  };

  openContactPermissionModal = () => {
    const { fields } = this.props;
    const errorMessage = t(fields.ManageContactPermissionWarning.value);
    this.setState({
      modalIsOpen: true, errorMessage, currentStep: 0, isManageContactPermission: true
    });
  };

  render() {
    const {
      formData,
      valid,
      handleSubmit,
      submitSucceeded,
      dispatch,
      serviceLevelQuotes,
      // eslint-disable-next-line no-shadow
      putReduxServiceLevelCode,
      serviceLevelCode,
      isAuthenticated,
      billingAccounts,
      fields,
      formSyncErrors,
      division
    } = this.props;
    const {
      currentStep,
      accList,
      data,
      tariffOptions,
      modalIsOpen,
      errorMessage,
      submitFailure,
      isProtectedAccount,
      isNonServiceableLocation,
      isManageContactPermission,
      fromContactId,
      toContactId,
      isWinterNonServiceableLocation,
      embargoErrorMessage
    } = this.state;
    const {
      totalAmount
    } = data;
    const USQuote = (formData && formData.quoteType && formData.quoteType.name === QuoteTypes.USQuote);
    const DomesticQuote = (formData && formData.quoteType && formData.quoteType.name === QuoteTypes.Domestic);
    const Sameday = division === Divisions.Sameday.name;
    return (
      <>
        <Beforeunload onBeforeunload={() => "You'll lose your data!"} />
        <FormProgressBar current={currentStep} steps={this.steps} />
        {this.state.addressPickerModalOpen && <AddressPickerModal
          onClose={this.closeAddressModal}
          handleSubmit={this.handleAddressPickSubmit}
          Options={this.state.AddressOptions}
        />}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <ModalBody className="justify-content-center">
            {isProtectedAccount ? '' :
              <ModalTitle>
                {embargoErrorMessage
                  ? t('Alert')
                  : Sameday && errorMessage && isWinterNonServiceableLocation
                    ? t('WarningTitle')
                    : DomesticQuote && errorMessage && !isManageContactPermission
                      ? t(fields.tariffError.value)
                      : USQuote && errorMessage
                        ? t('')
                        : (DomesticQuote && errorMessage && isManageContactPermission)
                          ? t(fields.ManageContactPermissionTitle.value)
                          : t('Wait, you are making a big change!')}
              </ModalTitle>}
            {USQuote && errorMessage ?
              <>
                <p>{errorMessage}</p>
                {isNonServiceableLocation || embargoErrorMessage ? '' : <p>{t('PleaseContactSupportHotline')}</p>}
              </> :
              DomesticQuote && errorMessage && isManageContactPermission ?
                <p>{errorMessage}</p> :
                DomesticQuote && errorMessage ? <p>{errorMessage}</p> :
                  !isProtectedAccount ? <p>{t('Clicking continue will reset the form')}</p> : ''}
            {
              isProtectedAccount ?
                <p>{t('ProtectedBillingAccountWarningMsg')}</p> : ''
            }
            <ButtonDiv className="row">
              <div className="col-sm-12">
                {
                  embargoErrorMessage ?
                    <>
                      <SecondaryButton
                        onClick={(e) => this.handleModalButtons(e)}
                        className="active"
                        name="returnToStepTwo"
                        style={{ padding: '10px' }}
                      >
                        {t('Okay')}
                        <span className="icon" />
                      </SecondaryButton>
                    </> :
                    (Sameday && errorMessage && isWinterNonServiceableLocation) || (DomesticQuote && errorMessage && !isManageContactPermission) ?
                      <>
                        <PrimaryButton
                          onClick={(e) => this.handleModalButtons(e)}
                          className="active"
                          name="returnToStepOne"
                          style={{ marginBottom: '20px' }}
                        >
                          {t(fields.ReturnToCurrentQuote.value)}
                          <span className="icon" />
                        </PrimaryButton>
                        <SecondaryButton
                          onClick={(e) => this.handleModalButtons(e)}
                          className="active"
                          name="createANewQuote"
                          style={{ padding: '10px' }}
                        >
                          {t(fields.CreateANewQuote.value)}
                          <span className="icon" />
                        </SecondaryButton>
                      </>
                      :
                      DomesticQuote && errorMessage && isManageContactPermission ?
                        <>
                          <SecondaryButton
                            onClick={(e) => this.handleModalButtons(e)}
                            className="active"
                            name="Okay"
                          >
                            {t('Okay')}
                            <span className="icon" />
                          </SecondaryButton>
                        </> :
                        errorMessage || isProtectedAccount ? <SecondaryButton
                            onClick={(e) => this.handleModalButtons(e)}
                            className="active"
                            name="acceptErrorMessage"
                          >
                            {t('Accept')}
                            <span className="icon" />
                          </SecondaryButton> :
                          <>
                            <SecondaryButton
                              onClick={(e) => this.handleModalButtons(e)}
                              className="active"
                              name="cancelButton"
                            >
                              {t('Cancel')}
                              <span className="icon" />
                            </SecondaryButton>
                            <PrimaryButton
                              onClick={(e) => this.handleModalButtons(e)}
                              className="active"
                              name="continueButton"
                            >
                              {t('ContinueWithChanges')}
                              <span className="icon" />
                            </PrimaryButton>
                          </>
}
              </div>
            </ButtonDiv>
          </ModalBody>
        </Modal>
        <SignupBanner loggedIn={isAuthenticated} />
        <FormContainer>
          {submitSucceeded && (submitFailure === false) ? (
            <>
              <LastActions className="float-right " $msButtonWidth="25.75Rem">
                <Column>
                  <Row>
                    <Column>
                      <PrimaryButton
                        style={{ margin: '0px', minWidth: '255px' }}
                        className="active"
                        onClick={() => this.handleShipNow()}>
                        {t('ShipNow')}
                      </PrimaryButton>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <MDBDropdown className="float-right" >
                        <MDBDropdownToggle>
                          <Row>
                            <Column $tablet={10} $mobile={10}>
                              {t('MoreOptions')}
                            </Column>
                            <span className="icon icon-more-options" />
                          </Row>
                        </MDBDropdownToggle>
                        <StyledMDBDropdownMenu className="text-white bg-dark" basic>
                          {((!totalAmount || totalAmount.value === 0) && !USQuote) ?
                            <DropdownItem disabled="disabled">
                              <DropdownItemIcon iconSrc={PrintGrey} />
                              {t('Print')}
                            </DropdownItem> :
                            <DropdownItem onClick={() => this.openBOL({
                              division: formData && formData.division, quoteNumber: data && data.quoteNumber, language: defaultLanguage, USQuote
                            })}>
                              <DropdownItemIcon iconSrc={PrintWhite} />
                              {t('Print')}
                            </DropdownItem>}
                          <DropdownItem
                            disabled={USQuote}
                            onClick={() => {
                              window.location.href =
                              `${routes.createQuote}?recreate=true&division=${formData && formData.division}&quoteNumber=${data && data.quoteNumber}`;
                            }}>
                            <DropdownItemIcon iconSrc={USQuote ? CreateQuoteFromExistingGrey : CreateQuoteFromExistingWhite} />
                            {t('CreateANewQuoteFromThisQuote')}
                          </DropdownItem>
                          <DropdownItem onClick={() => this.openDeleteModal()}>
                            <DropdownItemIcon iconSrc={DeleteWhite} />
                            {t('Delete')}
                          </DropdownItem>
                        </StyledMDBDropdownMenu>
                      </MDBDropdown>
                    </Column>
                  </Row>
                </Column>
              </LastActions>
              <Step5
                formData={formData}
                quoteData={data}
                authenticated={isAuthenticated}
                serviceLevelQuotes={serviceLevelQuotes}
                chosenServiceLevelCode={serviceLevelCode}
                USQuote={USQuote}
                parentFields={fields}
                billingAccounts={billingAccounts}
              />
            </>
          ) : (
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              {currentStep === 3 && valid && (
                <ButtonGroup>
                  <SecondaryButton type="button" onClick={this.prevStep} className={`${valid ? 'active' : ''} ${currentStep === 0 ? 'hidden' : ''}`}>
                    <Icon className="icon-left" src={ArrowLeftOrange} alt="back" />
                    {t('Back')}
                  </SecondaryButton>
                  <PrimaryButton type="submit" className="submit">
                    <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                    {t('RequestQuote')}
                  </PrimaryButton>
                </ButtonGroup>
              )}
              <Step1
                formData={formData}
                step={currentStep}
                authenticated={isAuthenticated}
                getServiceLevel={this.getServiceLevel}
                dispatch={dispatch}
                changeBillTo={this.changeBillTo}
                tariffOptions={tariffOptions}
                USQuote={USQuote}
                formName={formName}
                openModal={this.openModal}
                billingAccounts={billingAccounts}
                parentFields={fields}
                isLoggedIn={isAuthenticated}
                addressFieldsRef={this.addressFieldsRef}
                isProtectedAccount={isProtectedAccount}
                isNonServiceableLocation={this.handleNonserviceableLocation}
                openContactPermissionModal={this.openContactPermissionModal}
                fromContactId={fromContactId}
                toContactId={toContactId}
              />
              <Step2
                formData={formData}
                step={currentStep}
                valid={valid}
                dispatch={dispatch}
                formName={formName}
                accessorialsList={accList}
                USQuote={USQuote}
                parentFields={fields}
                error={formSyncErrors} />
              <Step3
                accessorialsList={accList}
                formData={formData}
                step={currentStep}
                prevStep={this.prevStep}
                nextStep={this.nextStep}
                parentFields={fields}
                formName={formName}
                dispatch={dispatch}
              />
              <Step4
                formData={formData}
                quoteData={data}
                step={currentStep}
                editStepOne={this.editStepOne}
                editStepTwo={this.editStepTwo}
                editStepThree={this.editStepThree}
                serviceLevelQuotes={serviceLevelQuotes}
                putReduxServiceLevelCode={putReduxServiceLevelCode}
                serviceLevelCode={serviceLevelCode}
                USQuote={USQuote}
                parentFields={fields}
                billingAccounts={billingAccounts}
              />
              {currentStep === 3 && valid ? (
                <ButtonGroup>
                  <SecondaryButton type="button" onClick={this.prevStep} className={`${valid ? 'active' : ''} ${currentStep === 0 ? 'hidden' : ''}`}>
                    <Icon className="icon-left" src={ArrowLeftOrange} alt="back" />
                    {t('Back')}
                  </SecondaryButton>
                  <PrimaryButton type="submit" className="submit">
                    <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                    {t('RequestQuote')}
                  </PrimaryButton>
                </ButtonGroup>
              ) : (
                <ButtonGroup>
                  <SecondaryButton type="button" onClick={this.prevStep} className={`${valid ? 'active' : ''} ${currentStep === 0 ? 'hidden' : ''}`}>
                    <Icon className="icon-left" src={ArrowLeftOrange} alt="back" />
                    {t('Back')}
                  </SecondaryButton>
                  {isProtectedAccount || isNonServiceableLocation ?
                    <PrimaryButton
                    type="button"
                    disabled={isProtectedAccount || isNonServiceableLocation}
                    onClick={this.nextStep}
                    className={`${(!isProtectedAccount || !isNonServiceableLocation) ? '' : 'active'}`}>
                      <Icon className="icon" src={ArrowRightWhite} alt="next" />
                      {t('Next')}
                    </PrimaryButton> :
                    <PrimaryButton
                    type="button"
                    onClick={this.nextStep}
                    className={`${valid && !(currentStep === 1 && !listCompleted(formData.measurementList)) ? 'active' : ''}`}>
                      <Icon className="icon" src={ArrowRightWhite} alt="next" />
                      {t('Next')}
                    </PrimaryButton>}
                </ButtonGroup>
              )}
            </form>
          )}
          <QuestionsBlock />
        </FormContainer>
      </>
    );
  }
}
export const postalValidateCall = (values, dispatch, props, fieldName) => {
  const {
    shipperAddress, consigneeAddress, division, quoteType
  } = values;
  // eslint-disable-next-line no-shadow
  const digitOnly = /[^\d]/g;
  const postal = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  if (fieldName && fieldName.includes('Nickname')) {
    const addressFieldName = `${fieldName.split('Nickname')[0]}Address`;
    props.touch(addressFieldName); // turn on error messages
    return props.asyncValidate(values, dispatch, props, addressFieldName); // evaluate whether the address field will fail or not
  }
  if (shipperAddress && consigneeAddress && shipperAddress === consigneeAddress) {
    return new Promise(() => {
      const error = { ...props.asyncErrors, consigneeAddress: t('NoSameAddress'), shipperAddress: t('NoSameAddress') };
      throw error;
    });
  }
  if (division === Divisions.Sameday.name || !(quoteType && quoteType.name === QuoteTypes.USQuote)) {
    if (shipperAddress && !isCanadianAddress(shipperAddress)) {
      return new Promise(() => {
        const error = { ...props.asyncErrors, shipperAddress: t('NoServiceOutsideCanada') };
        throw error;
      });
    }
    if (consigneeAddress && !isCanadianAddress(consigneeAddress)) {
      return new Promise(() => {
        const error = { ...props.asyncErrors, consigneeAddress: t('NoServiceOutsideCanada') };
        throw error;
      });
    }
  }
  let error = { ...props.asyncErrors, [fieldName]: undefined };
  if (fieldName) {
    if (quoteType && quoteType.name === QuoteTypes.USQuote) {
      if (shipperAddress && consigneeAddress) {
        if (!digitOnly.test(shipperAddress) && !digitOnly.test(consigneeAddress)) {
          error = { ...props.asyncErrors, shipperAddress: t('OnePostalMustBeCanadian'), consigneeAddress: t('OnePostalMustBeCanadian') };
        } else if ((postal.test(shipperAddress) && postal.test(consigneeAddress))) {
          error = { ...props.asyncErrors, shipperAddress: t('OnePostalMustBeUS'), consigneeAddress: t('OnePostalMustBeUS') };
        } else {
          if (error.shipperAddress === t('NoSameAddress')
            || error.shipperAddress === t('OnePostalMustBeUS')
            || error.shipperAddress === t('OnePostalMustBeCanadian')) {
            error.shipperAddress = undefined;
          }
          if (error.consigneeAddress === t('NoSameAddress')
            || error.consigneeAddress === t('OnePostalMustBeUS')
            || error.consigneeAddress === t('OnePostalMustBeCanadian')) {
            error.consigneeAddress = undefined;
          }
        }
      }
    } else if (shipperAddress && !isCanadianAddress(shipperAddress) && consigneeAddress && !isCanadianAddress(consigneeAddress)) {
      error.shipperAddress = t('OneAddressMustBeCanadian');
      error.consigneeAddress = t('OneAddressMustBeCanadian');
    } else {
      if (props.asyncErrors
          && (props.asyncErrors.shipperAddress === t('NoSameAddress') || props.asyncErrors.shipperAddress === t('OneAddressMustBeCanadian'))) {
        error.shipperAddress = undefined;
      }
      if (props.asyncErrors
          && (props.asyncErrors.consigneeAddress === t('NoSameAddress') || props.asyncErrors.consigneeAddress === t('OneAddressMustBeCanadian'))) {
        error.consigneeAddress = undefined;
      }
    }
  }
  const hasError = Object.keys(error).reduce((accum, curr) => (error[curr] ? true : accum), false);
  return hasError ? new Promise(() => { throw error; }) : Promise.resolve();
};

CreateQuoteForm = reduxForm({
  form: formName, // a unique identifier for this form
  initialValues,
  validate: measurementValidate, // <--- validation function given to redux-form
  asyncValidate: postalValidateCall,
  asyncBlurFields: ['consigneeAddress', 'shipperAddress', 'consigneeNickname', 'shipperNickname']
})(CreateQuoteForm);

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  formSyncErrors: getFormSyncErrors(formName)(state),
  initialValues: getFormInitialValues('CreateQuoteForm')(state),
  serviceLevelQuotes: state.user.serviceLevelQuotes,
  serviceLevelCode: state.user.serviceLevelCode,
  isAuthenticated: state.profile.isAuthenticated,
  billingAccounts: state.profile.billingAccounts,
  division: state.profile.division,
  userEmail: state.profile.email,
  userId: state.profile.userId
});

const mdtp = (dispatch) => ({
  putReduxServiceLevelQuotes: (serviceLevelQuotes) => dispatch(putReduxServiceLevelQuotes(serviceLevelQuotes)),
  putReduxServiceLevelCode: (serviceLevelCode) => dispatch(putReduxServiceLevelCode(serviceLevelCode)),
  putReduxAllContacts: (allContacts) => dispatch(putReduxAllContacts(allContacts)),
  putReduxAllCompanies: (allCompanies) => dispatch(putReduxAllCompanies(allCompanies)),
});

export default connect(mstp, mdtp)(CreateQuoteForm);
