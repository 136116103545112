import React from 'react';
import { t } from 'i18next';
import { ActionButton } from '../../ReactComponent/Modal/css';
import AlertModal from '../../ReactComponent/Modal/AlertModal';
import { P } from '../../../theme/typographySitecore';
import { CheckmarkIcon, HeaderContainer, ErrorList } from './css';

const ImportModal = ({
  isOpen,
  onClose,
  errorData,
  noFile,
}) => {
  // eslint-disable-next-line react/no-multi-comp
  const HeaderText = () => {
    if (errorData.length > 0) {
      return t('lblImportErrorMessageHeader');
    }
    if (noFile) {
      return (
        <>

          <CheckmarkIcon />
          {t('lblNoFileForDownloadHeader')}

        </>
      );
    }
    return t('lblFileUploadedHeader');
  };

  // eslint-disable-next-line react/no-multi-comp
  const DescriptionText = () => {
    if (errorData.length > 0) {
      return (
        <>
          <P field={t('lblImportUploadErrorDesc')} />
          <ErrorList>
            {
              errorData.map((eachErrorMessage) => {
                const errorMessage = eachErrorMessage.errors.map((element, index) => (<ul><li key={index}>{element.errorMessage}</li></ul>));
                return <li>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {eachErrorMessage.statusCode === 200
                    ? eachErrorMessage.message
                    : eachErrorMessage.errors.length > 1
                      ? errorMessage
                      : eachErrorMessage.errors[0].errorMessage }
                  </li>;
              })
            }
          </ErrorList>
        </>
      );
    }
    if (noFile) {
      return '';
    }
    return <P field={t('lblImportUploadSucessDesc')} />;
  };
  const header = () => (
    <HeaderContainer>
      <HeaderText />
    </HeaderContainer>
  );

  const content = () => (
    <DescriptionText />
  );

  const actions = () => (
    <ActionButton className="active" iconRightArrow onClick={onClose}>
      {t('Okay')}
    </ActionButton>
  );

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      content={content}
      actions={actions}
    />
  );
};

export default ImportModal;
