import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, change, getFormValues } from 'redux-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { t } from 'i18next';
import PaginationControls from '../ReactComponent/SortableTable/PaginationControls';
import NewsCard from './news-card';
import SearchWithFilter from '../ReactComponent/SearchWithFilter';
import ResetOrange from '../../assets/icons/ResetOrange.svg';
import Sort from '../../assets/icons/Sort.svg';
import LoadingIndicator from '../../assets/image/LoadingIndicator.gif';
import {
  formName,
  client,
  SEARCH_QUERY,
  Stages,
  PageSize,
  SortOrder
} from './constants';
import {
  CardListContainer,
  NewsPage,
  NewsTitle,
  SearchContainer,
  CommonSecondaryButton,
  CenteredMessage,
  Icon,
  CommonPrimaryButton
} from './css';

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: Stages.Loading,
      keyword: '',
      results: null,
      currentPage: 1,
      totalPages: 0,
      sortOrder: SortOrder.Descending
    };
  }

  componentDidMount() {
    this.searchContent(this.onSetData, this.onSetError);
  }

  reset = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    this.setState({
      keyword: '',
      currentPage: 1
    }, () => this.searchContent(this.onSetData, this.onSetError));
    dispatch(change(formName, 'searchByKey', ''));
  };

  onSetData = (results) => {
    const hasResults = results && results.items && results.items.length > 0;
    const totalPages = hasResults ? Math.ceil(results.totalCount / PageSize) : 0;

    this.setState({
      results,
      stage: hasResults ? Stages.DataLoaded : Stages.NoResults,
      totalPages
    });
  };

  onSetError = () => {
    this.setState({ stage: Stages.Error });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.searchContent(this.onSetData, this.onSetError);
  };

  searchContent = (onSetData, onSetError) => {
    const {
      keyword,
      currentPage,
      sortOrder
    } = this.state;

    const after = currentPage ? ((currentPage - 1) * PageSize) : 0;

    const queryOptions = {
      query: SEARCH_QUERY(sortOrder),
      variables: {
        keyword,
        skip: `${after}`,
      }
    };

    this.setState({ stage: Stages.Loading });

    client.query(queryOptions)
      .then((response) => {
        if (response && response.data && response.data.drsearch) {
          if (onSetData) {
            onSetData(response.data.drsearch.results);
          }
        } else if (onSetError) {
          const errors = response ? response.errors : null;
          onSetError(errors);
        }
      }).catch((error) => {
        if (onSetError) {
          onSetError(error);
        }
      });
  };

  setCurrentPage = (pageNum) => {
    this.setState({ currentPage: pageNum }, () => this.searchContent(this.onSetData, this.onSetError));
  };

  onToggleSort = (e) => {
    e.preventDefault();
    const { sortOrder } = this.state;
    const newSort = (SortOrder.Ascending === sortOrder) ? SortOrder.Descending : SortOrder.Ascending;

    this.setState({ sortOrder: newSort }, () => this.searchContent(this.onSetData, this.onSetError));
  };

  renderPaging = () => {
    const {
      currentPage,
      totalPages
    } = this.state;
    return <div>
      <PaginationControls currentPage={currentPage} totalPages={totalPages} setCurrentPageFn={this.setCurrentPage}/>
    </div>;
  };

  handleSearchInputChange = (value) => {
    this.setState({
      keyword: value,
      currentPage: 1
    });
  };

  formatDate = (date) => {
    if (!date) {
      return null;
    }

    const d = new Date(date);
    if (!d) return null;

    const formatedDate = `${d.toLocaleString('en', { month: 'short' })} ${d.getDate()}, ${d.getFullYear()}`;

    return formatedDate;
  };

  renderNewsCards = (items) => (
    <>
      {items.map((each, index) => {
        const {
          item,
          updateddate,
          name
        } = each;

        if (!item) {
          return null;
        }

        const {
          heading,
          thumbnailImage,
          url,
          description
        } = item;
        const imageSource = thumbnailImage &&
        thumbnailImage.jss &&
        thumbnailImage.jss.value &&
        thumbnailImage.jss.value.src ? thumbnailImage.jss.value.src : null;
        const title = heading && heading.value ? heading.value : name;
        const descriptionValue = description ? description.value : null;

        return <NewsCard
          key={index}
          title={title}
          url={url}
          imageSource={imageSource}
          date={this.formatDate(updateddate)}
          description={descriptionValue}/>;
      })}
    </>
  );

  render() {
    const { fields } = this.props;
    const {
      keyword,
      results,
      stage
    } = this.state;
    const resultsLength = results && results.items ? results.items.length : 0;

    return <NewsPage>
      <CardListContainer>
        {fields.heading && fields.heading.value && <NewsTitle><Text field={fields.heading}/></NewsTitle>}
        <SearchContainer>
          <form onSubmit={this.handleSubmit}>
            <SearchWithFilter
              className="searchbox"
              label={t('SearchUsingKeywords')}
              getSuggestions={this.handleSearchInputChange}
            />
            <CommonPrimaryButton type="submit" onClick={(e) => this.handleSubmit(e)}>
              {t('SearchButtonText')}
            </CommonPrimaryButton>
          </form>
          <div className="actionsGroup">
            <div className="reset">
              <CommonSecondaryButton type="button" onClick={(e) => this.reset(e)}>
                <Icon className="icon-left" src={ResetOrange} alt="back"/>
                {t('SearchReset')}
              </CommonSecondaryButton>
            </div>
            <div className="sort">
              <CommonSecondaryButton type="button" onClick={(e) => this.onToggleSort(e)}>
                <Icon className="icon-left" src={Sort} alt="back"/>
                {t('SearchSortBy')}
              </CommonSecondaryButton>
            </div>
          </div>
          {keyword && <div className="summary">
            <span>{`${t('NewsSearchSummaryPrefix')} ${resultsLength} ${t('NewsSearchSummaryPostfix')}`}</span>
            :&nbsp;;
            <span className="keyword">
              “
              {keyword}
              ”
            </span>
          </div>}
        </SearchContainer>
        {stage === Stages.DataLoaded && this.renderNewsCards(results.items)}
        {stage === Stages.Loading &&
          <CenteredMessage className="padding-70">
            <Icon src={LoadingIndicator} alt="no results" className="icon-100"/>
            <div className="bold-message">
              {t('SearchSearching')}
            </div>
          </CenteredMessage>}
        {stage === Stages.NoResults && <CenteredMessage className="padding-40">
          <div className="heading-message">
            {t('SearchNoResultsHeading')}
          </div>
          <div className="bold-message">
            {t('SearchNoResults')}
          </div>
        </CenteredMessage>}
      </CardListContainer>
      {stage === Stages.DataLoaded && this.renderPaging()}
    </NewsPage>;
  }
}

News.propTypes = {
  dispatch: PropTypes.func,
  fields: PropTypes.object,
};

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
});

News = reduxForm({
  form: formName, // a unique identifier for this form
})(News);

export default connect(
  mstp,
)(News);
