import axios from 'axios';
import { endpoints } from '../utils/constants';

// eslint-disable-next-line import/prefer-default-export
export const switchDivision = ({ userId, divisionCode, token }) => {
  const requestBody = {
    userId,
    division: divisionCode,
    token,
  };
  return axios.post(endpoints.sitecore.switchDivision, requestBody, {});
};
