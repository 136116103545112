import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  getFormValues,
  change,
  getFormSyncErrors
} from 'redux-form';
import Modal from 'react-modal';
import { t } from 'i18next';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowLeftOrange from '../../assets/icons/ArrowLeftOrange.svg';
import putReduxCompanies from './actions';
import {
  createUser,
  updateUserById,
  deleteUserById,
  getManageUserById,
  getUserById
} from '../../services/users';
import {
  getCompanyInfo,
  getCompaniesList,
  getUserManagementCompaniesList
} from '../../services/companies';
import { formDataToApi, formApiToData, getCompanyIdFromListByName } from '../../services/users/helpers';
import {
  Icon,
  ButtonGroup,
  FormContainer,
} from './css';
import SecondaryButton from '../ReactComponent/SecondaryButton';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import {
  ModalTitle,
  Close,
  ModalBody,
  ButtonDiv,
} from '../ManageUsers/css';
import UserForm from './components/UserForm';
import { homeUrlNoTrailingSlash } from '../../utils/func';
import { routes } from '../../utils/constants';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import LeavePageModal from '../ReactComponent/LeavePageModal';

const formName = 'CreateUserForm';
const initialValues = {
  companies: [{}]
};

class CreateUserForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      companiesList: [],
      userId: '',
      companyDetails: [],
      modalIsOpen: false,
      errorMessages: [],
      leaveModalIsOpen: false
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get('userId') || urlParams.get('userEmail');
      if (userId) {
        this.handleUserId(userId);
      } else {
        this.fetchAllCompanies(this.props.userId);
      }
    }
  }

  handleUserId = (userid) => {
    const {
      division,
      userId
    } = this.props;
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('userEmail')) {
      getUserById(division, userid)
        .then((response) => {
          if (response.data) {
            this.setState({ userId: response.data.userId });
            const { data } = response;
            this.fetchAllCompanies(data.userId).then(() => {
              const { companiesList } = this.state;
              const formedData = formApiToData(data, companiesList);
              formedData.companies.forEach((c, index) => {
                this.getCompanyInfo(c.companyId, index);
              });
              this.dispatchFormValues(formedData);
            });
          }
        });
    } else {
      getManageUserById(division, userid, userId)
        .then((response) => {
          if (response.data) {
            this.setState({ userId: response.data.userId });
            const { data } = response;
            this.fetchAllCompanies(data.userId).then(() => {
              const { companiesList } = this.state;
              const formedData = formApiToData(data, companiesList);
              formedData.companies.forEach((c, index) => {
                this.getCompanyInfo(c.companyId, index);
              });
              this.dispatchFormValues(formedData);
            });
          }
        });
    }
  };

  dispatchFormValues = (data) => {
    const { dispatch } = this.props;
    Object.keys(data).map((field) => dispatch(change(formName, field, data[field])));
  };

  // eslint-disable-next-line
  fetchAllCompanies = (userid) => {
    const {
      division,
      userId
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('page') === 'manage-companies') {
      if (localStorage.getItem('view/editCompanyData')) {
        const storedViewEditData = localStorage.getItem('view/editCompanyData');
        if (storedViewEditData) {
          const parsedData = JSON.parse(storedViewEditData);
          this.state.companiesList.push(parsedData);
        }
        localStorage.removeItem('view/editCompanyData');
      }

      if (localStorage.getItem('addCompanydata')) {
        const storedAddCompanyData = localStorage.getItem('addCompanydata');
        if (storedAddCompanyData) {
          const parsedData = JSON.parse(storedAddCompanyData);
          this.setState({ companiesList: parsedData });
        }
        localStorage.removeItem('addCompanydata');
      }
      getUserManagementCompaniesList(division, userid, userId).then((res) => {
        if (res.status === 200) {
          putReduxCompanies(res.data);
        }
      });
    } else if (urlParams.get('userEmail')) {
      return getCompaniesList(division, userid).then((res) => {
        if (res.status === 200) {
          putReduxCompanies(res.data);
          this.setState({ companiesList: res.data });
        }
      });
    } else {
      return getUserManagementCompaniesList(division, userid, userId).then((res) => {
        if (res.status === 200) {
          putReduxCompanies(res.data);
          this.setState({ companiesList: res.data });
        }
      });
    }
  };

  goToManage = (e) => {
    if (e) {
      e.preventDefault();
    }
    const manageUsersUrl = `${homeUrlNoTrailingSlash()}${routes.manageUsers}`;
    window.location.href = manageUsersUrl;
  };

  updateState = (name, value) => this.setState({ [name]: value });

  scrollToTop = () => window.scrollTo(0, 0);

  selectShippingPermission(index) {
    const {
      dispatch,
      formData: { companies }
    } = this.props;
    const { companyDetails } = this.state;
    if (companies[index] && !companies[index].permissions) {
      companies[index].permissions = companyDetails[index].permissions.filter((c) => c.permissionName === 'Shipping');
    } else if (companies[index].permissions.filter((c) => c.permissionName === 'Shipping').length < 1) {
      companies[index].permissions.push(companyDetails[index].permissions.filter((c) => c.permissionName === 'Shipping'));
    }
    dispatch(change(formName, `companies[${index}].permissions`, companies[index].permissions));
  }

  getCompanyInfo = (companyName, index) => {
    const { dispatch } = this.props;
    const { companiesList } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    dispatch(change(formName, `companies[${index}]`, { companyId: companyName }));
    // const companyID = getCompanyIdFromListByName(companyName, companiesList)
    let companyID = '';
    if (urlParams.get('page') === 'manage-companies') {
      const copyGetCompanyIdFromListByName = getCompanyIdFromListByName(companyName, companiesList);
      if (copyGetCompanyIdFromListByName.includes(',')) {
        [companyID] = copyGetCompanyIdFromListByName.split(',');
      } else {
        companyID = getCompanyIdFromListByName(companyName, companiesList);
      }
    } else {
      companyID = getCompanyIdFromListByName(companyName, companiesList);
    }

    getCompanyInfo(companyID).then((res) => {
      this.setState((prevState) => {
        const companyDetailsCopy = { ...prevState.companyDetails };
        companyDetailsCopy[index] = res.data;
        return { companyDetails: companyDetailsCopy };
      },
      () => this.selectShippingPermission(index)
      );
    });
  };

  handleSubmit = () => {
    const {
      formData,
      isAuthenticated,
      division,
    } = this.props;
    const {
      userId,
      companiesList
    } = this.state;
    if (isAuthenticated) {
      const changedData = formDataToApi(formData, division, companiesList);
      if (formData.rowVersion) {
        updateUserById(changedData, userId).then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 204) {
            this.openModal('updated');
          }
        });
      } else {
        createUser(changedData).then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 204) {
            this.openModal('created');
          }
        }).catch((error) => {
          if (error.response && error.response.status) {
            this.setState({
              modalIsOpen: true,
              errorMessages: error.response.data.errors
            });
          }
        });
      }
    }
  };

  openModal = (successAction) => {
    this.setState({
      modalIsOpen: true,
      successAction
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      errorMessages: []
    });
  };

  handleDeleteUser = async () => {
    const { userId } = this.state;

    deleteUserById(userId).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        this.openModal('deleted');
      }
    });
  };

  render() {
    const {
      formData,
      handleSubmit,
      dispatch,
      fields,
      valid,
      formSyncError
    } = this.props;
    const {
      companiesList,
      companyDetails,
      modalIsOpen,
      userId,
      errorMessages,
      leaveModalIsOpen,
      successAction
    } = this.state;

    const renderModalContent = () => {
      if (successAction) {
        return (
          <>
            <ModalTitle>{t('Success')}</ModalTitle>
            <p>{`${t('User')} ${t(successAction)} ${t('Successfully')}`}</p>
            <p>{t('You will now be taken to Manage Users')}</p>
            <ButtonDiv>
            <SecondaryButton
              onClick={() => this.goToManage()}
              className="active min-width"
            >
              {t('Accept')}
            </SecondaryButton>
            </ButtonDiv>
           </>
        );
      }
      if (errorMessages.length > 0) {
        return (
          <>
            <ModalTitle>{t('Error')}</ModalTitle>
            {errorMessages.map((error) => <p>{t(error.errorMessage)}</p>)}
            <ButtonDiv>
              <SecondaryButton
                onClick={() => this.closeModal()}
                className="active min-width"
              >
                {t('Accept')}
              </SecondaryButton>
            </ButtonDiv>
          </>
        );
      }
      return (
        <>
          <ModalTitle>{t('DeleteUser')}</ModalTitle>
          {t('DeleteUserPrompt')}
          <ButtonDiv>
          <SecondaryButton
            onClick={() => this.closeModal()}
            className="active min-width"
          >
          {t('Cancel')}
          </SecondaryButton>
          <PrimaryButton
            onClick={() => this.handleDeleteUser()}
            className="active min-width"
          >
            {t('RemoveUser')}
          </PrimaryButton>
          </ButtonDiv>
        </>
      );
    };
    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          // onRequestClose={this.closeModal}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <Close onClick={() => this.closeModal()}/>
          <ModalBody className="justify-content-center">
            {renderModalContent()}
          </ModalBody>
        </Modal>
        <LeavePageModal isOpen={leaveModalIsOpen} onClose={() => this.setState({ leaveModalIsOpen: false })} onContinue={() => this.goToManage()}/>
        <FormContainer>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <UserForm
              fields={fields}
              formData={formData}
              dispatch={dispatch}
              formName={formName}
              companiesList={companiesList}
              deleteUser={this.openModal}
              getCompanyInfo={this.getCompanyInfo}
              companyDetails={companyDetails}
              valid={valid}
              showAdd={userId ? true : false}
              error={formSyncError}
            />
            <ButtonGroup>
              <SecondaryButton type="button" className="active" onClick={() => this.setState({ leaveModalIsOpen: true })}>
                <Icon className="icon-left" src={ArrowLeftOrange} alt="back"/>
                {t('Cancel')}
              </SecondaryButton>
              <PrimaryButton type="submit" className="submit">
                <Icon className="icon" src={ArrowRightWhite} alt="submit"/>
                {t('SaveChanges')}
              </PrimaryButton>
            </ButtonGroup>
          </form>
        </FormContainer>
      </>
    );
  }
}

CreateUserForm = reduxForm({
  form: formName, // a unique identifier for this form
  initialValues,
})(CreateUserForm);

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  formSyncError: getFormSyncErrors(formName)(state),
  isAuthenticated: state.profile.isAuthenticated,
  division: state.profile.division,
  userId: state.profile.userId,
  email: state.profile.email,
});

const mdtp = (dispatch) => ({
  putReduxCompanies: (companiesList) => dispatch(putReduxCompanies(companiesList)),
});

export default connect(mstp, mdtp)(CreateUserForm);
