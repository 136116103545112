import styled from 'styled-components';
import { styleHelpers } from '../../../theme/style-helpers';

export const SideBarModal = styled.div`
    flex-wrap: wrap;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */

    ${styleHelpers.mobile`
        width: 100%;
        top: 0;
    `}
    &:has(> div) {
        height: 100%;
    }

`;

export const SideBarContainer = styled.div`
    display: block;
    border: 1px solid;
    background: white;
    padding: 40px;
    flex-wrap: wrap;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;

    ${styleHelpers.mobile`
        width: 100%;
        top: 0;
    `}
`;

export const FieldGroup = styled.div`
    margin: 24px 0 18px;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .relative {
        position: relative;
    }
`;

export const Close = styled.div`
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    cursor:pointer;
    &:hover {
        opacity: 1;
    }
    &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #333;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;
