import styled from 'styled-components';
import Column from '../../Column';
import { colors, MediaQuery } from '../../../../theme/style-helpers';

export const UnitsColumn = styled(Column)`
  &.col-1 {
    padding: 40px 0 0 0;
  }
`;

export const SeparatorLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #D9D9D6;
`;

export const AddItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
  padding: 18px 8px;
  margin-bottom: 40px;
`;

export const AddAnotherItemContainer = styled(AddItemContainer)`
  justify-content: flex-start;
  img {
    cursor: pointer;
  }
  &.with-background {
    background-color: ${colors.WHITE};
    border-bottom: 1px solid ${colors.COOL_GREY};

    p {
      margin: 0;
    }
  }

  &.with-dot {
    margin: 40px 0;
    padding: 0;

    &:after {
      content: "";
      width: calc(100% - 300px);
      background-image: linear-gradient(
        to right,
        ${colors.DARK_GREY} 33%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: bottom;
      background-size: 6px 2px;
      background-repeat: repeat-x;
      height: 2px;
      margin-left: 5px;
    }
  }
`;
export const AddItem = styled.button`
  background-color: ${colors.WHITE};
  font-style: normal;
  position: relative;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: normal;
  text-align: center;
  color: ${colors.DARK_GREY};
  padding: 17px 34px;
  border: 1px solid ${colors.COOL_GREY};
  border-radius: 3px;
  margin-left: 0;
  margin-bottom: 10px;
  width: 100%;
  cursor: pointer;
  order: 0;

  ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        margin-bottom: 0;
        order: 1;
    `}

  &.active {
    background-color: ${colors.BLACK};

    &:hover {
      background-color: ${colors.BRAND_ORANGE};
      color: ${colors.BLACK};
      border-color: ${colors.BRAND_ORANGE};
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .hidden-button {
    display: none;
  }
`;

export const AddItemLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: ${colors.DARK_GREY};
  margin-bottom: 0;
  margin-left: 8px;
  cursor: pointer;
`;

export const LabelWithUnitsContainer = styled.div`
  margin: 24px 0;
  p {
    display: inline-block;
    padding-right: 10px;
    margin-bottom: 5px;
  }
`;
