export const PUT_REDUX_QUOTE_LIST = 'PUT_REDUX_QUOTE_LIST';
export const PUT_REDUX_SHIPMENT_LIST = 'PUT_REDUX_SHIPMENT_LIST';
export const PUT_REDUX_SHIPMENT_HISTORY_LIST = 'PUT_REDUX_SHIPMENT_HISTORY_LIST';
export const PUT_REDUX_DRAFTS_LIST = 'PUT_REDUX_DRAFTS_LIST';
export const PUT_REDUX_SERVICE_LEVEL_QUOTE = 'PUT_REDUX_SERVICE_LEVEL_QUOTE';
export const PUT_REDUX_SERVICE_LEVEL_CODE = 'PUT_REDUX_SERVICE_LEVEL_CODE';
export const PUT_REDUX_AUTH_CONTENT = 'PUT_REDUX_AUTH_CONTENT';
export const PUT_REDUX_GENERAL_SETTINGS = 'PUT_REDUX_GENERAL_SETTINGS';
export const PUT_REDUX_CLEAR_SESSION = 'PUT_REDUX_CLEAR_SESSION';
export const PUT_REDUX_DEFAULT_SESSION = 'PUT_REDUX_DEFAULT_SESSION';
export const PUT_REDUX_INVOICE = 'PUT_REDUX_INVOICE';
export const PUT_REDUX_ALL_RECENT_INVOICES = 'PUT_REDUX_ALL_RECENT_INVOICES';
export const PUT_REDUX_ALL_HISTORICAL_INVOICES = 'PUT_REDUX_ALL_HISTORICAL_INVOICES';
export const PUT_REDUX_COMPANIES_LIST = 'PUT_REDUX_COMPANIES_LIST';
export const PUT_REDUX_PERMISSION_LIST = 'PUT_REDUX_PERMISSION_LIST';
export const PUT_REDUX_USER_DETAILS = 'PUT_REDUX_USER_DETAILS';
export const PUT_REDUX_CONTACT_LIST = 'PUT_REDUX_CONTACT_LIST';
export const PUT_CURRENT_LANGUAGE = 'PUT_CURRENT_LANGUAGE';
