import React from 'react';
import {
  Field, formValueSelector, getFormValues, reduxForm,
} from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import RenderField from '../../ReactComponent/RenderField';
import {
  isEmail,
  isPhone,
  isRequired,
  maxChar50,
  maxChar6,
} from '../../../utils/validator';
import { ActionsContainer, FormContainer, ControlLabel } from '../css';
import {
  H1, H2, P,
} from '../../../theme/typographySitecore';
import Button from './common';
import PasswordGroup from '../../ReactComponent/PasswordGroup';
import { FORM_NAME } from '../constants';
import AddressComplete from '../../ReactComponent/AddressComplete';
import { digitOnly, phoneNumber } from '../../../utils/mask';
import { styleTypes } from '../../ReactComponent/PrimaryButton';
import submitApplicationForm from '../api';

let Step1 = ({
  fields,
  onNext,
  onCancel,
  USQuote,
  pristine,
  invalid,
  data,
  finish,
  isBillingAccount,
}) => {
  const {
    basicInformation,
    basicInformationDescription,
    passwordImage,
  } = fields;

  const onSubmit = () => {
    // TODO: add loading...(Async button with loading indicator) and error handling
    //  (Common error message)
    submitApplicationForm(data);
    finish();
  };

  return (
    <FormContainer>
      <form
        onSubmit={
          isBillingAccount && isBillingAccount.value ? onSubmit : onNext
        }
      >
        <Row>
          <Column $tablet={9} $mobile={12}>
            <H1 field={basicInformation} verticalOffset={30}/>
            <P field={basicInformationDescription}/>
          </Column>
        </Row>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <Field
              name="email"
              type="email"
              component={RenderField}
              required
              validate={[isRequired, isEmail]}
              label={t('EmailAddress')}
            />
          </Column>
        </Row>

        <PasswordGroup passwordImage={passwordImage}/>
        <H2 field={fields.contactDetails} verticalOffset={50}/>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <Field
              name="contactName"
              type="text"
              component={RenderField}
              required
              validate={[isRequired]}
              label={t('ContactName')}
            />
            {USQuote ? (
              <Field
                name="address"
                type="text"
                component={RenderField}
                label={t('Address')}
                validate={[maxChar50, isRequired]}
                required
              />
            ) : (
              <AddressComplete required fieldName="address" formName={FORM_NAME}/>
            )}
          </Column>
          <Column $tablet={6} $mobile={12}>
            <Field
              name="companyName"
              type="text"
              component={RenderField}
              required
              validate={[isRequired]}
              label={t('CreditApplicantCompanyName')}
            />
            <Row style={{ marginTop: '-25px' }}>
              {' '}
              {/* TODO: Ask how to add a row of fields inside a row and have it aligned */}
              <Column $tablet={7} $mobile={12}>
                <Field
                  name="phoneNumber"
                  type="text"
                  component={RenderField}
                  required
                  validate={[isPhone, maxChar50, isRequired]}
                  normalize={phoneNumber}
                  label={t('PhoneNumber')}
                />
              </Column>
              <Column $tablet={5} $mobile={12}>
                <Field
                  name="extension"
                  type="text"
                  component={RenderField}
                  validate={[maxChar6]}
                  normalize={digitOnly}
                  label={t('Extension')}
                />
              </Column>
            </Row>
          </Column>
        </Row>

        <Field
          name="isBillingAccount"
          component={RenderField}
          type="radio"
          value={isBillingAccount}
          data={[
            {
              name: fields.billingAccountExist.value,
              value: true
            },
            {
              name: fields.billingAccountNotExist.value,
              value: false
            },
          ]}
          validate={[isRequired]}
          label={<ControlLabel field={fields.billingAccount}/>}
          required
        />
        {isBillingAccount && isBillingAccount.value && (
          <Row>
            <Column $tablet={6} $mobile={12}>
              <Field
                name="billingAccountNumber"
                type="text"
                component={RenderField}
                required
                normalize={digitOnly}
                validate={[isRequired, maxChar6]}
                label={t('BillingAccountNumber')}
              />
            </Column>
          </Row>
        )}
        <ActionsContainer>
          <Button
            type="button"
            styleType={styleTypes.SECONDARY}
            onClick={onCancel}
          >
            {t('Cancel')}
          </Button>
          {isBillingAccount && isBillingAccount.value ? (
            <Button type="submit" disabled={invalid || pristine}>
              {t('Submit')}
            </Button>
          ) : (
            <Button type="submit" disabled={invalid || pristine}>
              {t('Next')}
            </Button>
          )}
        </ActionsContainer>
      </form>
    </FormContainer>
  );
};

Step1 = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: FORM_NAME,
  initialValues: {
    ownerInformation: [{
      ownerName: '',
      ownerTitle: ''
    }],
  },
})(Step1);

const selector = formValueSelector(FORM_NAME);

export default connect((state) => {
  const isBillingAccount = selector(state, 'isBillingAccount');
  const data = getFormValues(FORM_NAME)(state);
  return {
    isBillingAccount,
    data,
  };
})(Step1);
