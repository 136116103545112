import React from 'react';
import { t } from 'i18next';
import { AddressBookText } from '../../ReactComponent/AddressBookEntry';
import MeasurementItemCard from '../../ReactComponent/MeasurementItemCard';
import TotalWeightBlock from '../../ReactComponent/TotalWeightBlock';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import { AccessorialsText } from '../../ReactComponent/FormSummaryBlock';
import Edit from '../../../assets/icons/Edit.svg';
import {
  AddressBlock,
  ContentBoxLightGrey,
  // PageTitle,
  CategoryTitle,
  BlockTitle,
  Icon,
  TextProperty,
  LineButton,
  UploadedDocumentsSummary,
} from '../css';
import { H3, P } from '../../../theme/typography';
import { AddressTypes, PaymentMethods } from '../../../utils/enums';
import UploadDocuments from '../../ReactComponent/UploadDocuments';
import UploadDocumentsRenderModes from '../../ReactComponent/UploadDocuments/constants';
import { getMonthDayYear } from '../../../utils/func';

const renderShipperDetails = (formData) => (
  <Column $tablet={6} $mobile={12}>
    <AddressBlock className="with-bg">
      <H3>
        {t('ShipperDetails')}
      </H3>
      <AddressBookText contactType={AddressTypes.SHIPPER} formData={formData}/>
    </AddressBlock>
  </Column>
);

const renderConsigneeDetails = (formData) => (
  <Column $tablet={6} $mobile={12}>
    <AddressBlock className="with-bg">
    <H3>
      {t('ConsigneeDetails')}
    </H3>
    <AddressBookText contactType={AddressTypes.CONSIGNEE} formData={formData}/>
    </AddressBlock>
  </Column>
);

const SummaryForm = ({
  formData = {},
  editStepOne,
  editStepTwo,
  editStepThree,
  editStepFour,
  hideCube,
  parentFields,
}) => {
  const {
    paymentMethod: { name },
    measurementList,
    measurementType,
    pickupDate,
    pickupDetails,
    referenceNumber,
    poNumber,
    specialInstructions,
    closingTime,
    readyTime,
    emergencyPhone,
    erapReference,
    erapPhone,
    billTo,
    gstNumber,
    USShipment,
  } = formData;

  if (!parentFields) {
    return null;
  }

  return (
    <>
      <CategoryTitle>
        {parentFields.pickupDetailsHeading && parentFields.pickupDetailsHeading.value}
        {editStepFour && (
          <LineButton onClick={editStepFour}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step one"/>
          </LineButton>
        )}
      </CategoryTitle>
      <ContentBoxLightGrey>
        <Row>
          <Column $tablet={3} $mobile={12}>
            <TextProperty>
              <P className="title">{t('PickupDate')}</P>
              <P>{getMonthDayYear(pickupDate)}</P>
            </TextProperty>
          </Column>
          <Column $tablet={3} $mobile={12}>
            <TextProperty>
              <P className="title">{t('ReadyTime')}</P>
              <P>
                {readyTime.hours}
                :
                {readyTime.minutes}
                {' '}
                {readyTime.amOrPm}
              </P>
            </TextProperty>
            <TextProperty>
              <P className="title">{t('ClosingTime')}</P>
              <P>
                {closingTime.hours}
                :
                {closingTime.minutes}
                {' '}
                {closingTime.amOrPm}
              </P>
            </TextProperty>
          </Column>
          <Column $tablet={3} $mobile={12}>
            <TextProperty>
              <P className="title">{t('AccessibleByTrailer')}</P>
              <P>{pickupDetails.accessByTrailer.name}</P>
            </TextProperty>
            {specialInstructions
              && <TextProperty>
                <P className="title">{t('SpecialInstructionsForPickup')}</P>
                <P>{specialInstructions}</P>
              </TextProperty>}
          </Column>
        </Row>
      </ContentBoxLightGrey>

      {/* --- Uploaded Documents --- */}
      <CategoryTitle>
        {parentFields.uploadedDocumentsHeader.value}
        {editStepFour && (
          <LineButton onClick={editStepFour}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step four"/>
          </LineButton>
        )}
      </CategoryTitle>
      <UploadedDocumentsSummary field={parentFields.uploadedDocumentsSummary}/>
      <UploadDocuments numberOfPlaceholders={4} renderMode={UploadDocumentsRenderModes.REVIEW}/>

      <CategoryTitle>
        {parentFields.addressDetailsHeading && parentFields.addressDetailsHeading.value}
        {editStepOne && (
          <LineButton onClick={editStepOne}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step one"/>
          </LineButton>
        )}
      </CategoryTitle>
      <Row>
        {renderShipperDetails(formData)}
        {pickupDetails.createBillOfLading && renderConsigneeDetails(formData)}
      </Row>
      <Row>
        <Column $tablet={6} $mobile={12}>
          <AddressBlock className="with-bg">
            <H3>
              {t('BillingInformation')}
            </H3>
            <TextProperty>
              <P className="title">{t('PaymentMethod')}</P>
              <P>{t(name)}</P>
              {name === PaymentMethods.ThirdParty
                && <Row>
                  <Column $tablet={6} $mobile={12}>
                    <AddressBookText contactType="billing" formData={formData}/>
                  </Column>
                  <Column $tablet={6} $mobile={12}>
                    {billTo && <>
                      <P className="title">{t('BillTo')}</P>
                      <P>{billTo}</P>
                    </>}
                    <P className="title">{t('GstNumber')}</P>
                    <P>{gstNumber}</P>
                  </Column>
                </Row>}

            </TextProperty>
            {billTo && name !== PaymentMethods.ThirdParty && <TextProperty>
              <P className="title">{t('BillTo')}</P>
              <P>{billTo}</P>
            </TextProperty>}
          </AddressBlock>
        </Column>
      </Row>
      <CategoryTitle>
        {parentFields.measurementsHeading && parentFields.measurementsHeading.value}
        {editStepTwo && (
          <LineButton onClick={editStepTwo}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step two"/>
          </LineButton>
        )}
      </CategoryTitle>
      <MeasurementItemCard
        formName="CreatePickupForm"
        measurementList={measurementList}
        measurementType={measurementType.value}
        hideButtons
        hideCube={hideCube}
        USShipment={USShipment}
      />
      <TotalWeightBlock
        measurementList={measurementList}
        measurementType={measurementType.value}
        totalNumber
      />
      {emergencyPhone && <ContentBoxLightGrey>
        <BlockTitle>
          {t('Additional Information')}
        </BlockTitle>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <TextProperty>
              <P className="title">{t('EmergencyNumber')}</P>
              <P>{emergencyPhone || 'N/A'}</P>
            </TextProperty>
            <TextProperty>
              <P className="title">{t('ERAPReferenceNumber')}</P>
              <P>{erapReference || 'N/A'}</P>
            </TextProperty>
            <TextProperty>
              <P className="title">{t('ERAPPhoneNumber')}</P>
              <P>{erapPhone || 'N/A'}</P>
            </TextProperty>
            <TextProperty>
              <P className="title">{t('ReferenceNumber')}</P>
              <P>{referenceNumber}</P>
            </TextProperty>
            <TextProperty>
              <P className="title">{t('PurchaseOrderReferenceNumber')}</P>
              <P>{poNumber}</P>
            </TextProperty>
          </Column>
          <Column $tablet={6} $mobile={12}>
            <TextProperty>
              <P className="title">{t('SpecialInstructionToShipment')}</P>
              <P>{specialInstructions}</P>
            </TextProperty>
          </Column>
        </Row>
      </ContentBoxLightGrey>}
      <CategoryTitle>
        <div>
          {parentFields.additionalServicesHeading && parentFields.additionalServicesHeading.value}
          <P style={{ marginTop: '10px' }}>{parentFields.additionalServicesH3 && parentFields.additionalServicesH3.value}</P>
        </div>
        {editStepThree && (
          <LineButton onClick={editStepThree}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step three"/>
          </LineButton>
        )}
      </CategoryTitle>
      <AccessorialsText formData={formData}/>
    </>
  );
};

export default SummaryForm;
