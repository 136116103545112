import React, { useRef, useEffect } from 'react';
import Modal from 'react-modal';
import { t } from 'i18next';
import {
  CloseIcon,
  ModalActions,
  ModalCloseButton,
  ModalContainer,
  ModalContent, ModalContentContainer, ModalFooter,
  ModalHeader,
  modalStyle
} from './css';

/**
 * Creates a modal alert popup (take a look into SystemError component for example)
 *
 * @param Header - header {Component}
 * @param Content - alert content/body
 * @param Actions - alert actions {Component}
 * @param isOpen - is modal opened {boolean}
 * @param onClose - close callback {function}
 * @param isCloseButton - is close button is presented {boolean}
 * @returns {Component}
 */
const AlertModal = ({
  header: Header,
  content: Content,
  actions: Actions,
  footer: Footer,
  isOpen,
  onClose,
  isCloseButton = true,
  shrinkwrapContent = false,
  maximizeZIndex = false,
}) => {
  /*
    Quick fix for the scrolling issue.
    TODO: when we have time we need to investigate the root cause and then remove this code.
    Some info: Issue is related to modal appearance/closing. documentElement.scrollTop is changed.
   */
  const pos = useRef(0);
  if (typeof document !== 'undefined') {
    pos.current = document.documentElement.scrollTop;
  };
  useEffect(() => () => setTimeout(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, pos.current);
    }
  }, 0), [isOpen]);
  // till this point

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyle({
        shrinkwrapContent,
        maximizeZIndex
      })}>
      <ModalContainer>
        {isCloseButton && (
          <ModalCloseButton onClick={onClose}>
            {t('Close')}
            <CloseIcon />
          </ModalCloseButton>
        )}
        <ModalContentContainer>
          <ModalHeader>
            <Header />
          </ModalHeader>
          <ModalContent>
            <Content />
          </ModalContent>
          {Actions && <ModalActions>
            <Actions />
          </ModalActions>}
          {Footer && <ModalFooter>
            <Footer />
          </ModalFooter>}
        </ModalContentContainer>
      </ModalContainer>
    </Modal>
  );
};

export default AlertModal;
