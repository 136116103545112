import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';
import ArrowRightBlack from '../../assets/icons/ArrowRightBlack.svg';
import Rectangle from '../../assets/icons/Rectangle.svg';

export const TileGroup = styled.div`
    display: inline-block;
    width: 100%;
    padding: 32px 10px;
    background: ${colors.LIGHT_GREY};
    border: 1px solid ${colors.LIGHT_GREY};
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    margin: 20px 0;
    text-align: center;

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    &.active {
        border-color: ${colors.BLACK};
    }

    &.static {
        &:hover {
            border-color: ${colors.LIGHT_GREY};
        }
    }

    ${MediaQuery.medium`
        &:first-of-type{
            margin-right:6%;
        }
    `}
`;

export const FormSummary = styled.summary`
    margin: 32px 0 72px;
    cursor: default;
`;

export const ButtonGroup = styled.div`
    margin: 20px 0;

    &.right {
        text-align: right;
    }

    .primaryButton {
        display: inline-block;

        background-color: ${colors.DARK_GREY};
        font-style: normal;
        position: relative;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: normal;
        text-align: center;
        color: ${colors.COOL_GREY};
        padding: 16px 21px;
        border: 1px solid ${colors.DARK_GREY};
        border-radius: 3px;
        margin-left: 0;
        margin-bottom: 10px;
        width: 100%;
        cursor: pointer;
        order: 0;

        &.min-width {
            min-width: 300px;
        }

        ${MediaQuery.medium`
            width: auto;
            margin: 0 5px;
            margin-bottom: 0;
            order: 1;
        `}
        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0;
            height: 24px;
            width: 24px;
            background: url(${ArrowRightWhite});
            background-size: cover;
        }

        &.submit, &.active {
            background-color: ${colors.BLACK};

            .icon {
                background: url(${ArrowRightOrange});
            }

            &:hover {
                background-color: ${colors.BRAND_ORANGE};
                color: ${colors.BLACK};
                border-color: ${colors.BRAND_ORANGE};
                text-decoration: none;

                .icon {
                    background: url(${ArrowRightBlack});
                }
            }
        }
    }

    .secondaryButton {
        display: inline-block;
        background-color: ${colors.WHITE};
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: normal;
        text-align: center;
        color: ${colors.BLACK};
        padding: 16px 21px;
        border: 1px solid ${colors.BLACK};
        border-radius: 3px;
        margin-left: 0;
        margin-bottom: 10px;
        width: 100%;
        order: 1;
        cursor: pointer;

        &.min-width {
            min-width: 300px;
        }


        ${MediaQuery.medium`
            width: auto;
            margin: 0 5px;
            order: 0;
        `}
        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0;
            height: 24px;
            width: 24px;
            background: url(${ArrowRightOrange});
            background-size: cover;
        }

        &.no-border {
            border: none;
        }

        &:hover {
            border-color: ${colors.BRAND_ORANGE};
            text-decoration: none;
            color: ${colors.BLACK};
        }

        &.hidden {
            display: none;
        }
    }
}
`;

export const Icon = styled.span`
    &.icon {
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 30px;
        width: 22px;
        margin: 10px 0;
    }

    &.rectangle {
        background-image: url(${Rectangle});
    }
`;
