import React from 'react';
import {
  ContentContainer, StyledReactTooltip, TooltipText, VectorIcon,
} from './css';

const ReactTooltip = () => (
    <StyledReactTooltip
      effect="solid"
      event="click"
      globalEventOff="click"
      getContent={(tooltipText) => (
        <ContentContainer>
          <TooltipText field={tooltipText}/>
          <VectorIcon/>
        </ContentContainer>
      )}
    />
);

export default ReactTooltip;
