/* eslint-disable no-undef */
// External script provided by a third party
import initCOM from './CommerceChatScript';

const samedaySalesForceChat = () => ({
  showChatWindow() {
    if (!window.embedded_svc) {
      const s = document.createElement('script');
      s.setAttribute('src', process.env.REACT_APP_SALESFORCE_EMBEDDED_SERVICE);
      s.onload = () => {
        initCOM(null);
        setTimeout(() => {
          window.embedded_svc.bootstrapEmbeddedService();
        }, 2000);
      };
      document.body.appendChild(s);
    } else {
      initCOM('https://service.force.com');
      setTimeout(() => {
        window.embedded_svc.bootstrapEmbeddedService();
      }, 2000);
    }
  }
});

export default samedaySalesForceChat;
