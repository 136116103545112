import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { getMonthDayYear } from '../../../utils/func';
import {
  ShipmentDetailsDiv,
  AddressHeaderDiv,
  HistoryDiv,
  HistoryEntryDiv,
  ShipmentDetailsErrorContainer,
} from './css';
import { Icon } from '../Icon';
import AddressFromIcon from '../../../assets/icons/AddressFrom.svg';
import AddressToIcon from '../../../assets/icons/AddressTo.svg';
import { ShipmentHistoryStates } from '../../ViewUserDashboard/constants';
import { Locales } from '../../../utils/enums';

import GoogleMap from '../GoogleMap';

const AddressHeader = ({
  title,
  icon,
  street,
  city,
  province,
  // eslint-disable-next-line no-unused-vars
  postalCode,
  country,
  isAuthenticated
}) => <AddressHeaderDiv>
  <span className="title">
    <Icon iconSrc={icon}></Icon> {title}</span>
  <div className="addressdetails">
    {(isAuthenticated) ? <span className="street">{street}</span> : ''}
    <span className="city">{city}</span>
    <span className="postalcode">{province} {country}</span>
  </div>
</AddressHeaderDiv>;

AddressHeader.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  province: PropTypes.string,
  country: PropTypes.string,
  isAuthenticated: PropTypes.bool
};

// eslint-disable-next-line react/no-multi-comp
const HistoryEntry = ({
  data,
  onClickEntry,
  division
}) => {
  const {
    status,
    date,
    city,
    provinceCode,
    countryCode,
    selected,
    detailsEN,
    detailsFR
  } = data;
  const isFrenchApplication = typeof window !== 'undefined'
    ? (window.location.pathname.toLowerCase().includes(`/${Locales.French.toLowerCase()}/`)) : false;
  return <HistoryEntryDiv
    className={`${status === 'Delivered' ? 'highlighted' : ''} ${status === 'Livré' ? 'highlighted' : ''} ${selected ? 'selected' : ''}`}
    onClick={() => onClickEntry(data)}>
    <div className="heading">
      <span className="status">{status}</span>
      <span className="date">{getMonthDayYear(date)}</span>
    </div>
    {detailsEN && status === 'Exception' && division === 'Sameday' && !isFrenchApplication &&
      <div className="location">
        <span className="address">{detailsEN.split('|')[1] || detailsEN}</span>
      </div>}
    {detailsFR && status === 'Exception' && division === 'Sameday' && isFrenchApplication &&
      <div className="location">
        <span className="address">{detailsFR.split('|')[1] || detailsFR}</span>
      </div>}
    {city && provinceCode && countryCode &&
      <div className="location">
        <span className="address">{city},{provinceCode} {countryCode}</span>
      </div>}
  </HistoryEntryDiv>;
};

HistoryEntry.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string,
    date: PropTypes.string,
    city: PropTypes.string,
    provinceCode: PropTypes.string,
    countryCode: PropTypes.string,
    selected: PropTypes.bool,
    detailsEN: PropTypes.string,
    detailsFR: PropTypes.string,
  }),
  onClickEntry: PropTypes.func,
  division: PropTypes.string,
};

// eslint-disable-next-line react/no-multi-comp
class ShipmentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedDetailsData: [],
      mapBoundary: [],
    };
    this.historyEntries = React.createRef();
  }

  initState = (detailsData) => {
    const mappedDetailsData = detailsData.map((each, index) => ({
      originalIndex: index,
      id: `he${index}`,
      ...each
    }));
    const uniqueHistoryEntries = mappedDetailsData.filter((value, i, self) => {
      const index = self.findIndex((x) => x.latitude === value.latitude && x.longitude === value.longitude);
      if (index === i) {
        return true;
      }

      return false;
    });

    const mapBoundary = uniqueHistoryEntries
      .filter((each) =>
        !!(each.latitude && each.longitude) // Filter out the entries that have lat / long = 0...
      )
      .map((each) => {
        if (each.status === 'Livré' && each.countryCode.toLowerCase() === 'ca') {
          ShipmentHistoryStates.Delivered = 'Livré';
        } else {
          ShipmentHistoryStates.Delivered = 'Delivered';
        }
        return {
          lat: each.latitude,
          lng: each.longitude,
          ishighlighted: each.status === ShipmentHistoryStates.Delivered,
          originalIndex: each.originalIndex,
          id: each.id
        };
      });

    this.setState({
      mappedDetailsData,
      mapBoundary
    });
  };

  componentDidMount() {
    const { detailsData } = this.props;
    this.initState(detailsData);
  }

  componentDidUpdate(nextProps) {
    const { detailsData } = this.props;
    const differentDetailsData = detailsData !== nextProps.detailsData;

    if (differentDetailsData) {
      this.initState(nextProps.detailsData);
    }
  }

  selectItemInState = (id) => {
    const {
      mappedDetailsData,
      mapBoundary
    } = this.state;

    const newDetailsData = mappedDetailsData.map((each) => {
      each.selected = each.id === id;
      return each;
    });

    const newBoundaryData = mapBoundary.map((each) => {
      each.selected = each.id === id;
      return each;
    });

    this.setState({
      mappedDetailsData: newDetailsData,
      mapBoundary: newBoundaryData
    });
  };

  onClickHistoryEntry = (selectedItem) => {
    const { id } = selectedItem;
    const { mapBoundary } = this.state;

    const currentSelectedItem = mapBoundary.find((each) => each.selected);
    if (currentSelectedItem == null || currentSelectedItem.id !== id) {
      this.selectItemInState(id);
    }
  };

  historyElementScrollTo = (index) => {
    const rootElement = this.historyEntries.current;
    if (!rootElement) {
      return;
    }

    const historyElement = rootElement.children[index];
    if (historyElement) {
      const firstElement = rootElement.children[0];
      const top = historyElement.offsetTop - firstElement.offsetTop;
      rootElement.scrollTo({
        top,
        behavior: 'smooth'
      });
    }
  };

  onClickMapMarker = (marker) => {
    const {
      id,
      originalIndex
    } = marker;
    this.selectItemInState(id);
    this.historyElementScrollTo(originalIndex);
  };

  render() {
    const {
      basicData,
      error,
      mapid,
      division,
      isAuthenticated
    } = this.props;
    const {
      mapBoundary,
      mappedDetailsData
    } = this.state;
    const hasData = mappedDetailsData && mappedDetailsData.length > 0;
    const {
      from,
      to
    } = basicData;
    return <>
      {error && <div>{error}</div>}
      {!error && <ShipmentDetailsDiv>
        <div className="column left">
          {from && <AddressHeader
            title={t('FromAddress')}
            icon={AddressFromIcon}
            street={from.address1}
            postalCode={from.postalCode}
            province={from.provinceCode}
            country={from.countryCode}
            city={from.city}
            isAuthenticated={isAuthenticated}/>}
          {hasData &&
            <HistoryDiv ref={this.historyEntries}>
              {mappedDetailsData.map((each, index) => <HistoryEntry
                key={index}
                data={each}
                division={division}
                onClickEntry={this.onClickHistoryEntry}/>)}
            </HistoryDiv>}
        </div>
        <div className="column right">
          {to && <AddressHeader
            title={t('ToAddress')}
            icon={AddressToIcon}
            street={to.address1}
            postalCode={to.postalCode}
            province={to.provinceCode}
            country={to.countryCode}
            city={to.city}
            isAuthenticated={isAuthenticated}/>}
          {hasData && <GoogleMap mapid={mapid} markers={mapBoundary} onClickMarker={this.onClickMapMarker}/>}
        </div>
      </ShipmentDetailsDiv>}
      {!error && !hasData && <ShipmentDetailsErrorContainer>{t('ShipmentDetailsNoData')}</ShipmentDetailsErrorContainer>}
    </>;
  }
}

ShipmentDetails.propTypes = {
  basicData: PropTypes.shape({
    from: PropTypes.shape({
      address1: PropTypes.string,
      postalCode: PropTypes.string,
      provinceCode: PropTypes.string,
      countryCode: PropTypes.string,
      city: PropTypes.string,
    }),
    to: PropTypes.shape({
      address1: PropTypes.string,
      postalCode: PropTypes.string,
      provinceCode: PropTypes.string,
      countryCode: PropTypes.string,
      city: PropTypes.string,
    }),
  }),
  detailsData: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string,
    date: PropTypes.string,
    city: PropTypes.string,
    provinceCode: PropTypes.string,
    countryCode: PropTypes.string,
    selected: PropTypes.bool,
    detailsEN: PropTypes.string,
    detailsFR: PropTypes.string,
  })),
  error: PropTypes.string,
  mapid: PropTypes.string,
  division: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};

export default ShipmentDetails;
