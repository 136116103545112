import React from 'react';
import { t } from 'i18next';
import { InformationPage, IntroIcon } from '../css';
import thankYouIcon from '../../../assets/auth/thankYouIcon.svg';
import { H2, P } from '../../../theme/typographySitecore';
import Button from './common';
import { styleTypes } from '../../ReactComponent/PrimaryButton';
import { homeUrl } from '../../../utils/func';

const Step3 = ({
  fields: {
    thankYou,
    receivingConfirmation,
    customerService,
  },
}) => {
  const navigateHome = () => {
    window.location.href = homeUrl();
  };

  return (
    <InformationPage>
      <IntroIcon src={thankYouIcon}/>
      <H2
        field={thankYou}
        verticalOffset={20}
        center
      />
      <P
        field={receivingConfirmation}
        center
      />
      <P
        field={customerService}
        verticalOffset={10}
        center
      />
      <Button type="button" styleType={styleTypes.SECONDARY} onClick={navigateHome}>
        {t('CreateAccountReturnHome')}
      </Button>
    </InformationPage>
  );
};

export default Step3;
