import { Currencies, Divisions } from '../../utils/enums';
import { equipmentSizes } from '../ReactComponent/Measurement/Container/constants';

export const FORM_NAME = 'createSctnQuoteForm';
const defaultListItem = {
  name: 'name',
  desc: 'desc'
};

const memo = (f) => {
  const cache = {};
  return (args) => {
    if (cache[args]) {
      return cache[args];
    }

    cache[args] = f(args);
    return (cache[args]);
  };
};

const getValue = (field) => (field ? ({
  name: field.fields.name.value,
  desc: field.fields.desc.value
}) : defaultListItem);

const getCardValue = (field) => ({
  ...getValue(field),
  image: field.fields.image.value.src
});

export const steps = (t) => [
  t('SctnQuoteAddresDetailsPage'),
  t('SctnQuoteServiceAndEquipmentPage'),
  t('SctnQuoteAdditionalInformationPage'),
  t('SctnQuoteFinalizePage'),
  t('SctnQuoteQuotePage')
];

export const incoterms = (t) => [
  t('SctnQuoteEXW'),
  t('SctnQuoteFCA'),
  t('SctnQuoteCPT'),
  t('SctnQuoteCIP'),
  t('SctnQuoteDAT'),
  t('SctnQuoteDAP')
];

export const temperatureUnits = ['Celsius', 'Fahrenheit'];

export const genset = ['Yes', 'No'];

export const serviceTypes = {
  OCEAN_LCC: 0,
  OCEAN_FCL: 1,
  AIR: 2,
  TRUCKLOAD: 3,
  OTHER: 4
};

const typeOfDeliveryOcean = (t) => [
  t('SctnQuoteDeliveryTypeYardToYard'),
  t('SctnQuoteDeliveryTypeDoorToYard'),
  t('SctnQuoteDeliveryTypeYardToDoor'),
  t('SctnQuoteDeliveryTypeDoorToDoor')
];

const typeOfDeliveryAir = (t) => [
  t('SctnQuoteDeliveryTypeAirportToAirport'),
  t('SctnQuoteDeliveryTypeDoorToDoor'),
  t('SctnQuoteDeliveryTypeAirportToDoor'),
  t('SctnQuoteDeliveryTypeDoorToAirport')
];

const typeOfDeliveryOther = (t) => [
  t('SctnQuoteDeliveryTypeAirportToAirport'),
  t('SctnQuoteDeliveryTypeYardToYard'),
  t('SctnQuoteDeliveryTypeAirportToDoor'),
  t('SctnQuoteDeliveryTypeDoorToYard'),
  t('SctnQuoteDeliveryTypeDoorToAirport'),
  t('SctnQuoteDeliveryTypeYardToDoor'),
  t('SctnQuoteDeliveryTypeDoorToDoor')
];

export const typeOfDelivery = (t) => [
  typeOfDeliveryOcean(t),
  typeOfDeliveryOcean(t),
  typeOfDeliveryAir(t),
  [],
  typeOfDeliveryOther(t)
];

export const chassisSize = ['20 Ft', '40 Ft', '45 Ft'];

export const frequencyOfShipment = (t) => [
  t('SctnQuoteFrequencyOne'),
  t('SctnQuoteFrequencyDaily'),
  t('SctnQuoteFrequencyWeekly'),
  t('SctnQuoteFrequencyMonthly'),
  t('SctnQuoteFrequencyQuarterly')
];

export const temperatureRequirements = (t) => [t('Yes'), t('No')];

export const Currency = [Currencies.CAD, Currencies.USD];

export const cards = memo((fields) => [
  {
    ...getCardValue(fields.truckloadCard),
    clickHandler: () => {
      window.location.href = `/create-quote?division=${Divisions.Freight.name}`;
      return window.location.href;
    }
  },
  {
    ...getCardValue(fields.commerceSolutionsCard),
    clickHandler: () => {
      window.location.href = `/create-quote?division=${Divisions.Sameday.name}`;
      return window.location.href;
    }
  },
  {
    ...getCardValue(fields.logisticsCard),
    selectedButtonIsSelected: true
  }
]);

export const importTypes = memo((fields) => [
  getValue(fields.importTypePermanent),
  getValue(fields.importTypeTemporary),
  getValue(fields.importTypeReturn)
]);

export const additionalServices = memo((fields) => [
  getValue(fields.additionalServiceInsurance),
  getValue(fields.additionalServiceBrokerage),
  getValue(fields.additionalServiceDangerous),
  getValue(fields.additionalServiceExport)
]);

export const services = memo((fields) => [
  getValue(fields.serviceLCL),
  getValue(fields.serviceFCL),
  getValue(fields.serviceAir),
  getValue(fields.serviceTruckload),
  getValue(fields.serviceOther)
]);

export const payments = memo((fields) => [
  getValue(fields.paymentPrepaid),
  getValue(fields.paymentCollect),
  getValue(fields.paymentThirdParty)
]);

export const typeOfEquipment = memo((fields) => [
  getValue(fields.equipmentContainer),
  getValue(fields.equipmentDryContainer)
]);

// Define if containers data should be presented in a container by service type
export const containerDataByService = [true, true, true, true, false];

// Define if items should be presented in a container by service type
export const itemsByService = [true, false, true, false, true];

// Define disabled equipment size in a container by service type
export const disabledEquipmentSize = [
  equipmentSizes,
  [equipmentSizes[1], equipmentSizes[3], equipmentSizes[5]],
  equipmentSizes,
  [equipmentSizes[0], equipmentSizes[2], equipmentSizes[4], equipmentSizes[6]],
  equipmentSizes
];
