import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';
import postErrorLog from '../../services/errorLog';

export const apiGetShipmentSummaryByShipmentNumbers = (updateState, division, shipmentNumbers, updateError, openModal) => {
  // eslint-disable-next-line max-len
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipments}/${division}/summary?probillNumbers=${shipmentNumbers.join(',')}`).then((res) => {
    if (res.status === 200) {
      updateState(res.data, shipmentNumbers);
    } else {
      updateError(res);
    }
  }).catch((error) => {
    console.error('error', error.response.data.errors);
    const errorMessage = error.response.data.errors.map((err) => err.errorMessage).join(',');
    if (error.config && error.config.url.indexOf('CreateErrorLog') === -1 && error.response.data.statusCode !== 500) {
      const comingError = error;
      const action = window.location.href;
      postErrorLog(error.config, action, comingError.toString(), errorMessage);
    } else if (error.response.data.statusCode === 500) {
      updateError(error);
    } else {
      openModal();
    }
  });
};

export const apiGetShipmentSummaryByReferenceNumber = (updateState, division, referenceNumber, postalCode, updateError, openModal) => {
  // eslint-disable-next-line max-len
  let url = `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipments}/${division}/summary?referenceNumber=${encodeURIComponent(referenceNumber)}`;
  if (postalCode) {
    url += `&fromOrToPostalCode=${postalCode}`;
  }

  axios.get(url).then((res) => {
    if (res.status === 200) {
      updateState(res.data, [referenceNumber, postalCode]);
    } else {
      updateError(res);
    }
  }).catch((error) => {
    console.error('error', error.response.data.errors.errorMessage);
    const { errorMessage } = error.response.data.errors;
    if (error.config && error.config.url.indexOf('CreateErrorLog') === -1) {
      const comingError = error;
      postErrorLog(error.config, 'Tracking Reference Numbers', comingError.toString(), errorMessage);
    } else if (error.response.data.statusCode === 500) {
      updateError(error);
    } else {
      openModal();
    }
  });
};

export const apiGetShipmentSummaryByOrderNumbers = (updateState, orderNumbers, updateError) => {
  axios.get(
    `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.pickups}?orderNumbers=${orderNumbers.join(',')}&division=unknown`
  ).then((res) => {
    if (res.status === 200) {
      updateState(res.data, orderNumbers);
    } else {
      updateError(res);
    }
  }).catch((error) => {
    const { errorMessage } = error.response.data.errors;
    if (error.config && error.config.url.indexOf('CreateErrorLog') === -1) {
      const comingError = error;
      postErrorLog(error.config, 'Tracking Order Numbers', comingError.toString(), errorMessage);
    } else {
      updateError(error);
    }
  });
};
