import TOGGLE_SCROLL_TO_REF from '../components/HomePageCopyBlock/actions';

const initialState = {
  toggleScrollToRef: false,
};
const homePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SCROLL_TO_REF:
      return {
        ...state,
        toggleScrollToRef: !state.toggleScrollToRef,
      };
    default:
      return state;
  }
};

export default homePageReducer;
