import React from 'react';
import { Field } from 'redux-form';
import { t } from 'i18next';
import RenderField from '../ReactComponent/RenderField';
import { isEmail, isRequired, maxChar255 } from '../../utils/validator';
import Error from '../../assets/icons/Error.svg';
import { ButtonDiv, ErrorIcon, ModalTitle } from './css';
import PrimaryButton from '../ReactComponent/PrimaryButton';

const ModalContent = ({
  modalAction,
  // entityId,
  closeModal,
  postShare,
  valid,
}) => (modalAction === 'share'
  ? <>
      <ModalTitle>{t('Share')}</ModalTitle>
      {t('ShareByEmailModal')}
      <Field
        name="shareShipmentEmail"
        type="email"
        component={RenderField}
        className="col"
        label={t('Email')}
        validate={[isEmail, maxChar255, isRequired]}
      />
      <Field
        name="shareShipmentBody"
        type="textarea"
        component={RenderField}
        className="col"
        label={t('Email Body')}
        validate={[maxChar255]}
      />
      <ButtonDiv>
        <PrimaryButton onClick={postShare} className={`${valid && 'active'}`}>
          {t('Ok')}
        </PrimaryButton>
      </ButtonDiv>
    </>
  : <>
      <ErrorIcon src={Error} alt="Error"/>
      <ModalTitle>{t('ErrorWithShippingAction')}</ModalTitle>
      <p>{t('PleaseContactSupportHotline')}</p>
      <ButtonDiv>
        <PrimaryButton onClick={closeModal} className="active">
          {t('Ok')}
        </PrimaryButton>
      </ButtonDiv>
    </>
);
export default ModalContent;
