import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { colors, MediaQuery } from '../../../theme/style-helpers';
import ArrowRightOrange from '../../../assets/icons/ArrowRightOrange.svg';
import ArrowRightWhite from '../../../assets/icons/ArrowRightWhite.svg';
import ArrowLeftOrange from '../../../assets/icons/ArrowLeftOrange.svg';
import ArrowRightBlack from '../../../assets/icons/ArrowRightBlack.svg';
import Actions from '../../../assets/icons/Actions.svg';

const PrimaryButtonStyledComponent = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 0 16px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    width: 100%;
    height: 52px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;

    ${MediaQuery.medium`
        width: 300px;
        
        ${({ $autoWidth }) => $autoWidth && css`width: auto`}
        
        ${({ $mediumWidth }) => $mediumWidth && css`
        width: 180px;
    `}

        ${({ $smallWidth }) => $smallWidth && css`
        width: 180px;
    `} 
               
    `}
    ${MediaQuery.small`
    ${({ $smallerWidth }) => $smallerWidth && css`
        width: 100%;
    `} 
    `}
    ${MediaQuery.large`
  
    ${({ $smallerWidth }) => $smallerWidth && css`
        width: 152px;
    `} 
    ${({ $rightButtonMargin }) => $rightButtonMargin && css`
        margin-left: 16px;
    `}   
    `}
    .icon {
        height: 24px;
        width: 24px;
        margin-left: 10px;
        background-size: cover;
        ${({ $iconSrc }) => ($iconSrc ? `background: url(${$iconSrc})` : '')}
    }

    .icon-left-arrow {
        background: url(${ArrowLeftOrange});
        margin-right: 10px;
    }

    .icon-right-arrow {
        background: url(${ArrowRightOrange});
        margin-left: 10px;
    }

    .icon-right-arrow-white {
        background: url(${ArrowRightWhite});
        margin-left: 10px;
    }

    .icon-actions {
        background: url(${Actions});
    }

    background-color: ${colors.BLACK};

    &:hover:enabled {
        background-color: ${colors.BRAND_ORANGE};
        color: ${colors.BLACK};
        border-color: ${colors.BRAND_ORANGE};

        .icon-right-arrow {
            background: url(${ArrowRightBlack});
        }
    }

    &:disabled {
        background-color: #63666A;
        color: #D9D9D6;
        cursor: default;

        .icon {
            filter: grayscale(100%);
        }
    }

    ${({ $styleType }) => $styleType === styleTypes.SECONDARY && css`
        background-color: ${colors.WHITE};
        color: ${colors.BLACK};
        border: 1px solid ${colors.BLACK};

        &:hover:enabled {
            background-color: ${colors.WHITE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};

            .icon-right-arrow {
                background: url(${ArrowRightOrange});
            }
        }
    `}
    .children {
        flex: 1;
    }
`;

const PrimaryButton = ({
  iconSrc,
  iconLeftArrow,
  iconRightArrow,
  iconRightArrowWhite,
  iconActions,
  styleType,
  smallWidth,
  autoWidth,
  smallerWidth,
  mediumWidth,
  rightButtonMargin,
  ...rest
}) => {
  const {
    children,
    className
  } = rest;
  const classNames = classnames(className);

  return (
    <PrimaryButtonStyledComponent
    {...rest}
    $rightButtonMargin={rightButtonMargin}
    $smallerWidth={smallerWidth}
    $smallWidth={smallWidth}
    $autoWidth={autoWidth}
    $mediumWidth={mediumWidth}
    $styleType={styleType}
    $iconSrc={iconSrc}
    className={classNames}>

      {iconLeftArrow && <span className="icon icon-left-arrow"/>}

      <div className="children">{children}</div>

      {iconSrc && <span className="icon"/>}
      {iconRightArrow && <span className="icon icon-right-arrow"/>}
      {iconRightArrowWhite && <span className="icon icon-right-arrow-white"/>}
      {iconActions && <span className="icon icon-actions"/>}

    </PrimaryButtonStyledComponent>
  );
};

PrimaryButton.propTypes = {
  iconSrc: PropTypes.string,
  iconLeftArrow: PropTypes.bool,
  iconRightArrow: PropTypes.bool,
  iconRightArrowWhite: PropTypes.bool,
  iconActions: PropTypes.bool,
};

export const styleTypes = Object.freeze({
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
});

export default PrimaryButton;
