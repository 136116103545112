import React, { useState } from 'react';
import { Field, FieldArray, change } from 'redux-form';
import { t } from 'i18next';
import AddressComplete from '../../ReactComponent/AddressComplete';
import RenderField from '../../ReactComponent/RenderField';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import CheckMarkSolidOrange from '../../../assets/icons/CheckMarkSolidOrange.svg';
import PasswordGroup from '../../ReactComponent/PasswordGroup';
import {
  isRequired,
  isPhone,
  isEmail,
  maxChar50,
  maxChar6,
  validateHours,
  validateMinutes
} from '../../../utils/validator';
import { phoneNumber, digitOnly, twoDigits } from '../../../utils/mask';
import Error from '../../../assets/icons/Error.svg';
import { PaymentType } from '../../CreateShipment/css';
import {
  PageTitle,
  ContentBoxLightGrey,
  CategoryTitle,
  CardTitle,
  ItemContainer,
  TextProperty,
  TimeSeperator,
  ErrorMessage,
  Icon,
  UserPrefsTimeField,
  UserPrefsTimeLine,
} from '../css';
import { H3, P } from '../../../theme/typography';
import {
  BillingAccountType,
  UserProfile,
  AddressTypes,
  AMPM,
  Divisions,
  DeliveryTypes,
  IsYesOrNo
} from '../../../utils/enums';
import { NavToPageAndShowAddNewLineButton } from '../../../utils/func';
import { isNullOrEmpty } from '../../../utils/arrays';
import getFieldValue from '../../../utils/sitecore-utils';
import Tooltip from '../../ReactComponent/Tooltip';
import { billingAccountTextFormatter, billingAccountToNumber } from '../../../utils/form';

const companyCheckboxRenderer = ({ item }) => (
  <>
    <span className="checkmark"></span>
    <Column $tablet={6} $mobile={12}>
      <H3>{item.accountNumber || item.permissionName}</H3>
    </Column>
    {item.accountType === 'PA' &&
      <Column $tablet={6} $mobile={12}>
        <H3>{t('Protected Billing Account')}</H3>
      </Column>}
  </>
);

const renderUserCompanyList = ({
  // eslint-disable-next-line no-unused-vars
  fields, dispatch, formName, formData, valid, companiesList, currentCompaniesList, getCompanyInfo, companyDetails, showAdd
}) => {
  const [state, setState] = useState({
    added: showAdd
  });

  const setAddedTrue = () => setState({
    ...state,
    added: true
  });

  NavToPageAndShowAddNewLineButton(currentCompaniesList, state.added, setAddedTrue);
  return (
    <>
      {fields.map((item, index) => {
        const fieldValue = fields.get(index);
        const {
          completed,
          companyId,
          accounts,
          permissions
        } = fieldValue;

        const onlyUsefulPermissions = permissions && permissions.length > 0
          ? permissions.filter((permission) => permission.permissionName !== 'CreateShipment' &&
          permission.permissionName !== 'InterfaceSetting' &&
          permission.permissionName !== 'PrintLabels' &&
          permission.permissionName !== 'QuickEstimate')
          : permissions;

        if (completed) {
          return (
            <ContentBoxLightGrey key={index}>
              <CardTitle>
                {t('Company')}
                {` ${index + 1}`}
              </CardTitle>
              <Row>
                <Column>
                  <TextProperty>
                    <P className="title">{t('SelectedCompany')}</P>
                    <P className="text">{companyId}</P>
                  </TextProperty>
                </Column>
              </Row>
              <Row>
                <Column>
                  <TextProperty>
                    <P className="title">{t('BillingAccountNumber')}</P>
                    {accounts.map((a) =>
                      a.accountNumber &&
                      <P className="text" key={a.accountNumber}>
                        <img src={CheckMarkSolidOrange} alt="added item" />
                        {a.accountNumber} {`- ${a.accountType === 'PA' ?
                          t(BillingAccountType.Protected)
                          :
                          t(BillingAccountType.Billing)}`}
                      </P>
                    )}
                  </TextProperty>
                </Column>
              </Row>
              <Row>
                <Column>
                  <TextProperty>
                    <P className="title">{t('AssignedPermissions')}</P>
                    {onlyUsefulPermissions && onlyUsefulPermissions.length > 0 ? onlyUsefulPermissions.map((p) =>
                      p.permissionId &&
                      <P className="text" key={p.permissionId}>
                        <img src={CheckMarkSolidOrange} alt="added item" />
                        {
                        // eslint-disable-next-line no-nested-ternary
                        p.permissionName && p.permissionName === 'ShipmentTracking'
                          ? 'Invoices'
                          // eslint-disable-next-line no-nested-ternary
                          : p.permissionName && p.permissionName === 'RequestPickup'
                            ? 'Pickups'
                            // eslint-disable-next-line no-nested-ternary
                            : p.permissionName && p.permissionName === 'ManageAddressBooks'
                              ? 'Manage Contacts'
                              : p.permissionName && p.permissionName === 'ManageAccounts'
                                ? 'Manage Companies'
                                : p.permissionName
                                }
                      </P>
                    ) : []}
                  </TextProperty>
                </Column>
              </Row>
            </ContentBoxLightGrey>
          );
        }

        return (
          <ContentBoxLightGrey className="edit-item-box" key={index}>
            <CardTitle>
              {t('Company')}
              {` ${index + 1}`}
            </CardTitle>
            <Row>
              <Column $tablet={5} $mobile={12}>
                <Field
                  name={`${item}.companyId`}
                  type="dropdown"
                  label={t('SelectedCompany')}
                  data={companiesList.map((c) => c.companyName)}
                  required
                  onChange={(e) => getCompanyInfo(e, index)}
                  component={RenderField}
                  validate={[isRequired]}
                />
              </Column>
            </Row>
            {formData.companies && formData.companies[index] && formData.companies[index].companyId &&
              <>
                <Row>
                  <Column>
                    <TextProperty>
                      <P className="text">{t('BillingAccountNumber')}</P>
                      <P className="title">{t('SelectBillingAccount')}</P>
                    </TextProperty>
                  </Column>
                </Row>
                <Row>
                  <Column $tablet={6} $mobile={12}>
                    <Field
                      name={`${item}.accounts`}
                      type="checkboxes"
                      label={t('SelectedAccountNumbers')}
                      data={companyDetails[index] && companyDetails[index].accounts}
                      renderer={companyCheckboxRenderer}
                      className="w-50"
                      required
                      multiple
                      component={RenderField}
                      validate={[isRequired]}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <TextProperty>
                      <P className="text">{t('UserPermissions')}</P>
                    </TextProperty>
                  </Column>
                </Row>
                <Row>
                  <Column $tablet={6} $mobile={12}>
                    <Field
                      name={`${item}.permissions`}
                      type="checkboxes"
                      label={t('SelectedPermissionNumbers')}
                      data={companyDetails[index] && companyDetails[index].permissions}
                      renderer={companyCheckboxRenderer}
                      className="w-50"
                      required
                      multiple
                      component={RenderField}
                      validate={[isRequired]}
                    />
                  </Column>
                </Row>
              </>}
          </ContentBoxLightGrey>
        );
      })}
    </>
  );
};

const UserForm = ({
  fields,
  division,
  formData = {},
  formName,
  dispatch,
  companiesList,
  getCompanyInfo,
  companyDetails,
  valid,
  showAdd,
  selectedTab,
  contacts,
  oldPasswordAutoFocus,
  billingAccounts,
  changeBillTo
}) => {
  const {
    companies,
    rowVersion,
    email,
    oldPassword,
    isPickupScheduled
  } = formData;
  const {
    passwordImage,
    ReadyTimeTitle,
    ClosingTimeTitle,
    DefaultShippingAddressTitle,
    DefaultConsigneeAddressTitle,
    DefaultOverrideAddressTitle,
    DefaultOverrideAddressDesc,
    ShipperPostalZipcode,
    ShipperContactName,
    ConsigneePostalZipcode,
    USQuoteDefaults,
    DefaultRegularSchedulePickup
  } = fields;
  const update = rowVersion ? true : false;
  const renderProfileInformation = () => <div>
      <PageTitle className="justified">
        {fields.pageTitle && fields.pageTitle.value}
      </PageTitle>
      <Row className="justify-content-md-center">
        <Column $tablet={12} $mobile={12}>
          <TextProperty>
            {fields.emailAddressInfo && fields.emailAddressInfo.value}
          </TextProperty>
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={12} $mobile={12}>
          {update ?
            <TextProperty>
              <P className="title">{t('Email')}</P>
              <P className="text">{email}</P>
            </TextProperty> :
            <Field
              name="email"
              type="text"
              required
              component={RenderField}
              label={t('Email')}
              validate={[isRequired, isEmail]}
            />}
        </Column>
      </Row>
      <Row>
        <Column $tablet={12} $mobile={12}>
          <CategoryTitle>
            {fields.passwordTitle && fields.passwordTitle.value}
          </CategoryTitle>
          {update &&
            <>
              <TextProperty>
                {fields.passwordSummary && fields.passwordSummary.value}
              </TextProperty>
              <Row className="justify-content-md-center">
                <Column $tablet={12} $mobile={12}>
                  <Field
                    name="oldPassword"
                    type="password"
                    required
                    component={RenderField}
                    label={t('OldPassword')}
                    validate={[isRequired]}
                    meta={{
                      active: oldPasswordAutoFocus,
                      touched: oldPasswordAutoFocus
                    }}
                  />
                </Column>
              </Row>
            </>}
          {(!update || (update && oldPassword)) &&
            <Row className="justify-content-md-center">
              <Column $tablet={12} $mobile={12}>
                <PasswordGroup passwordImage={passwordImage} />
              </Column>
            </Row>}
        </Column>
      </Row>
      <CategoryTitle>
        {fields.profileDetailsTitle && fields.profileDetailsTitle.value}
      </CategoryTitle>
      <Row className="justify-content-md-center">
        <Column $tablet={12} $mobile={12}>
          <Field
            name="companyName"
            type="text"
            required
            component={RenderField}
            label={t('CompanyName')}
            validate={[isRequired]}
          />
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={6} $mobile={12}>
          <Field
            name="name"
            type="text"
            required
            component={RenderField}
            label={t('FullName')}
            validate={[isRequired]}
          />
        </Column>
        <Column $tablet={6} $mobile={12}>
          <Field
            name="title"
            type="text"
            component={RenderField}
            label={t('Title')}
          />
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={12} $mobile={12}>
          <AddressComplete
            fieldName="address"
            formName={formName}
            required
          />
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={6} $mobile={12}>
          <Field
            name="phoneNumber"
            type="text"
            component={RenderField}
            label={t('PhoneNumber')}
            validate={[isPhone, maxChar50, isRequired]}
            normalize={phoneNumber}
            required
          />
        </Column>
        <Column $tablet={6} $mobile={12}>
          <Field
            name="phoneNumberExt"
            type="text"
            component={RenderField}
            label={t('Extension')}
            validate={[maxChar6]}
            normalize={digitOnly}
          />
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={12} $mobile={12}>
          <Field
            name="description"
            type="text"
            required
            component={RenderField}
            label={t('Description')}
            validate={[isRequired]}
          />
        </Column>
      </Row>
    </div>;

  const renderAccountPrivelages = () => <div>
      <Row>
        <Column>
          <CategoryTitle>
            {fields.Tab2PageTitle && fields.Tab2PageTitle.value}
          </CategoryTitle>
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={12} $mobile={12}>
          <TextProperty>
            {fields.accountPrivileges && fields.accountPrivileges.value}
          </TextProperty>
        </Column>
      </Row>
      <Row>
        <Column>
          <ItemContainer>
            <FieldArray
              name="companies"
              dispatch={dispatch}
              component={renderUserCompanyList}
              valid={valid}
              formName={formName}
              companiesList={companiesList}
              currentCompaniesList={companies}
              getCompanyInfo={getCompanyInfo}
              companyDetails={companyDetails}
              formData={formData}
              showAdd={showAdd}
            />
          </ItemContainer>
        </Column>
      </Row>
    </div>;

  const renderError = ({ input, meta, ...props }) => (
    meta.error
      ? <ErrorMessage {...props} className="error"><Icon src={Error} /> {meta.error}</ErrorMessage>
      : null

  );

  const renderUserPreferences = () => <div>
      <Row>
      {division === Divisions.Sameday.name &&
      <Column $mobile={12}>
          <CategoryTitle>
            {t('Select a Service')}
          </CategoryTitle>
          <PaymentType $column={3}>
            <Field
              name="serviceType"
              type="checkboxes"
              data={[
                { name: DeliveryTypes.Commercial, desc: 'CommercialServiceDesc' },
                { name: DeliveryTypes.Residential, desc: 'ResidentialServiceDesc' },
              ]}
              validate={[isRequired]}
              component={RenderField}
            />
          </PaymentType>
        </Column>}
      </Row>
      <div className="row">
                <div className="col-sm-6">
                  <CategoryTitle>
                    {t('BillingInformation')}
                    <Tooltip tip={t('TipBillingAccountDetails')} marginLeft />
                  </CategoryTitle>
                  <Field
                    name="billToAccountNumber"
                    type="dropdown"
                    label={t('BillTo')}
                    data={billingAccounts}
                    textField={billingAccountTextFormatter}
                    valueField="accountNumber"
                    component={RenderField}
                    normalize={billingAccountToNumber}
                    onChange={(billTo) => changeBillTo(billTo)}
                  />
                </div>
              </div>
      <Row>
        <Column $mobile={12}>
          <Field
                name="isPickupScheduled"
                label={getFieldValue(DefaultRegularSchedulePickup)}
                component={RenderField}
                type="radio"
                value={isPickupScheduled}
                data={[
                  { name: IsYesOrNo.Yes, value: true },
                  { name: IsYesOrNo.No, value: false }]}
                validate={[isRequired]}
            />
        </Column>
      </Row>

      <Row>
        <Column $mobile={12}>
          <CategoryTitle>
            {getFieldValue(ReadyTimeTitle)}
          </CategoryTitle>
          <Field
            type="hidden"
            name="readyTimeError"
            component={renderError}
          />

        </Column>
      </Row>
      <UserPrefsTimeLine>
        <UserPrefsTimeField>
          <Field
            name="readyTimeHour"
            type="text"
            component={RenderField}
            label={t('Hours')}
            normalize={twoDigits}
            validate={[validateHours, isRequired]}
            required
          />
        </UserPrefsTimeField>
        <TimeSeperator>:</TimeSeperator>
        <UserPrefsTimeField>
          <Field
            name="readyTimeMinutes"
            type="text"
            component={RenderField}
            normalize={twoDigits}
            label={t('Minutes')}
            validate={[validateMinutes, isRequired]}
            required
          />
        </UserPrefsTimeField>

        <UserPrefsTimeField>
          <Field
            name="readyAMPM"
            type="dropdown"
            component={RenderField}
            data={[AMPM.AM, AMPM.PM]}
            label={t('AM/PM')}
            required

          />
        </UserPrefsTimeField>
      </UserPrefsTimeLine>
      <Row>
        <Column $mobile={12}>
          <CategoryTitle>
            {getFieldValue(ClosingTimeTitle)}
          </CategoryTitle>
          <Field
            type="hidden"
            name="closingTimeError"
            component={renderError}
          />
        </Column>
      </Row>
      <UserPrefsTimeLine>
        <UserPrefsTimeField>
          <Field
            name="closingTimeHour"
            type="text"
            component={RenderField}
            label={t('Hours')}
            normalize={twoDigits}
            validate={[validateHours, isRequired]}
            required
          />
        </UserPrefsTimeField>
        <TimeSeperator>:</TimeSeperator>
        <UserPrefsTimeField>
          <Field
            name="closingTimeMinutes"
            type="text"
            component={RenderField}
            normalize={twoDigits}
            label={t('Minutes')}
            validate={[validateMinutes, isRequired]}
            required
          />
        </UserPrefsTimeField>
        <UserPrefsTimeField>
          <Field
            name="closingAMPM"
            type="dropdown"
            component={RenderField}
            data={[AMPM.AM, AMPM.PM]}
            label={t('AM/PM')}
            required
          />
        </UserPrefsTimeField>

      </UserPrefsTimeLine>

      <Row className="justify-content-md-center">
        <Column $tablet={12} $mobile={12}>
          <Field
            name="fromContact"
            type="dropdown"
            component={RenderField}
            data={getContactsByType(AddressTypes.SHIPPER)}
            textField="text"
            valueField="contactId"
            label={getFieldValue(DefaultShippingAddressTitle)}
            clearable
            onReset={() => dispatch(change(formName, 'fromContact', null))}
          />
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={12} $mobile={12}>
          <Field
            name="toContact"
            type="dropdown"
            component={RenderField}
            data={getContactsByType(AddressTypes.CONSIGNEE)}
            textField="text"
            valueField="contactId"
            label={getFieldValue(DefaultConsigneeAddressTitle)}
            clearable
            onReset={() => dispatch(change(formName, 'toContact', null))}
          />
        </Column>
      </Row>
      {division === Divisions.Sameday.name && <Row className="justify-content-md-center">
        <Column $tablet={12} $mobile={12}>
          <Field
            name="overrideContact"
            type="dropdown"
            component={RenderField}
            data={contacts && contacts.map((item) => ({
              contactId: item.contactId,
              text: item.nickname || item.contactName
            }))}
            textField="text"
            valueField="contactId"
            label={getFieldValue(DefaultOverrideAddressTitle)}
            description={DefaultOverrideAddressDesc}
            clearable
            onReset={() => dispatch(change(formName, 'overrideContact', null))}
          />
        </Column>
      </Row>}
      {(division === Divisions.Freight.name) ?
      <>
      <Row>
        <Column $mobile={12}>
          <CategoryTitle>
            {getFieldValue(USQuoteDefaults)}
          </CategoryTitle>
          <Field
            type="hidden"
            name="usQuoteDefaults"
            component={renderError}
          />
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={4} $mobile={12}>
          <Field
            name="shipperPostalCode"
            type="text"
            component={RenderField}
            valueField="uscontactId"
            label={getFieldValue(ShipperPostalZipcode)}
          />
        </Column>
        <Column $tablet={4} $mobile={12}>
          <Field
            name="shipperName"
            type="text"
            component={RenderField}
            valueField="uscontactId"
            label={getFieldValue(ShipperContactName)}
          />
        </Column>
        <Column $tablet={4} $mobile={12}>
          <Field
            name="consigneePostalCode"
            type="text"
            component={RenderField}
            valueField="uscontactId"
            label={getFieldValue(ConsigneePostalZipcode)}
          />
        </Column>
      </Row></> : ''}
    </div>;

  const getContactsByType = (type) => {
    if (isNullOrEmpty(contacts)) {
      return [];
    }

    return contacts.filter((contact) => [type, AddressTypes.SHARED, AddressTypes.OTHER]
      .includes(contact.contactType
        .toLowerCase()))
      .map((item) => ({
        contactId: item.contactId,
        text: item.nickname || item.contactName
      }));
  };

  let renderedTab = null;
  switch (selectedTab) {
    case UserProfile.PROFILE_INFORMATION:
      renderedTab = renderProfileInformation();
      break;
    case UserProfile.ACCOUNT_PRIVILEAGES:
      renderedTab = renderAccountPrivelages();
      break;
    case UserProfile.USER_PREFERENCES:
      renderedTab = renderUserPreferences();
      break;
    default:
      renderedTab = null;
  }

  return renderedTab;
};

export default UserForm;
