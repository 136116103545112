import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import SummaryForm from '../ReactComponent/FormSummaryBlock';
import formApiToData from '../../utils/apiMapping';
import { routes } from '../../utils/constants';
import {
  getMonthDayYear,
  formatMoney,
  openFileInNewTab,
  checkBillToAccountProtectedStatus
} from '../../utils/func';
import { defaultLanguage } from '../../temp/config';
import {
  CategoryTitle,
  TextProperty,
  Terms,
  PageTitle, StyledMDBDropdown,
} from './css';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';
import {
  ModalTitle,
  ModalBody,
  ButtonDiv,
  LastActions
} from '../CreateShipment/css';
import { H2, H3, P } from '../../theme/typography';
import { Divisions } from '../../utils/enums';
import GreyBoxWithBorder from '../_styledComponents/GreyBoxWithBorder';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import SecondaryButton from '../ReactComponent/SecondaryButton';
import PrintWhite from '../../assets/icons/PrintWhite.svg';
import DeleteWhite from '../../assets/icons/DeleteWhite.svg';
import CreateQuoteFromExistingWhite from '../../assets/icons/CreateQuoteFromExistingWhite.svg';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import { getQuoteDocument, getQuoteByDivisionAndQuoteNumber } from '../../services/quotes';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import { getAccessorials } from '../CreateShipment/api';

class ViewQuote extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      division: '',
      quoteNumber: '',
      printToPDF: false,
      modalIsOpen: false,
      errorMessage: '',
      hideView: false,
    };
  }

  getQuoteDetails() {
    const {
      division,
      quoteNumber,
      USQuote,
      printToPDF
    } = this.state;
    getQuoteByDivisionAndQuoteNumber(division, quoteNumber, USQuote)
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;
          const { accList } = this.state;
          data.accList = accList;
          const formedData = formApiToData(data);
          this.setState({ data: formedData }, () => {
            if (printToPDF) {
              this.printButton.printPage();
            }
          });
        }
      })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError = () => {
    this.setState({ errorMessage: 'Quote Not Found' });
    this.openModal();
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      errorMessage: ''
    });
  };

  componentDidMount() {
    const {
      isAuthenticated,
      dispatch,
      division,
      userId,
      billingAccounts
    } = this.props;

    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      this.setState({
        division: urlParams.get('division'),
        quoteNumber: urlParams.get('quoteNumber'),
        printToPDF: urlParams.get('printToPDF'),
        USQuote: urlParams.get('USQuote')
      }, () => {
        if (isAuthenticated) {
          checkBillToAccountProtectedStatus(
            dispatch,
            division || division || Divisions.Freight.name,
            userId,
            billingAccounts);
        }
        getAccessorials({
          isAuthenticated,
          division: this.state.division || division || Divisions.Freight.name,
          updateState: this.updateState,
          billTo: ''
        }).then(() => {
          this.getQuoteDetails();
        });
      });
    }
  }

  updateState = (name, value) => this.setState({ [name]: value });

  openBOL = ({
    division,
    quoteNumber,
    language,
    USQuote
  }) => {
    getQuoteDocument({
      division,
      quoteNumber,
      language,
      USQuote
    })
      .then((res) => {
        if (res.status === 200) {
          openFileInNewTab({
            dataAsBase64: res.data.fileDataAsBase64,
            mimeType: res.data.mimeType,
            fileName: res.data.fileName
          });
        }
      });
  };

  openDeleteModal = () => {
    const { quoteNumber } = this.state;
    const errorMessage = t(`Unable to delete Quote ${quoteNumber}.`);
    this.setState({
      modalIsOpen: true,
      errorMessage
    });
  };

  render() {
    const {
      data,
      quoteNumber,
      errorMessage,
      modalIsOpen,
      hideView,
      USQuote
    } = this.state;
    const {
      expiryDate,
      totalAmount,
      division,
      charges,
      billTo
    } = data;
    const { billingAccounts } = this.props;
    const protectedAccount = billingAccounts
      && billingAccounts.some((a) => a.accountNumber === billTo && a.protected);
    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <ModalBody className="justify-content-center">
            <ModalTitle>{t('Error')}</ModalTitle>
            <p>{errorMessage}</p>
            <p>{t('PleaseContactSupportHotline')}</p>
            <ButtonDiv className="row">
              <div className="col-sm-12">
                <SecondaryButton
                  onClick={(e) => this.closeModal(e)}
                  className="active"
                  name="acceptErrorMessage"
                >
                  {t('Accept')}
                  <span className="icon" />
                </SecondaryButton>
              </div>
            </ButtonDiv>
          </ModalBody>
        </Modal>
        {!hideView && <>
          <LastActions className="float-right ">
            <Column>
              <Row>
                <Column>
                  <PrimaryButton
                  disabled={USQuote}
                  iconRightArrow
                  onClick={() => {
                    window.location.href = `${routes.createShipment}?division=${division}&quoteNumber=${quoteNumber}`;
                    return window.location.href;
                  }}>
                    {t('CreateAShipment')}
                  </PrimaryButton>
                </Column>
              </Row>
              <Row>
                <Column>
                  <StyledMDBDropdown>
                    <MDBDropdownToggle>
                      <Row>
                        <Column $tablet={10} $mobile={12}>
                          {t('MoreOptions')}
                        </Column>
                        <span className="icon icon-more-options" />
                      </Row>
                    </MDBDropdownToggle>
                    <StyledMDBDropdownMenu className="text-white bg-dark" basic>
                      <DropdownItem onClick={() => this.openBOL({
                        division,
                        quoteNumber,
                        language: defaultLanguage,
                        USQuote
                      })}>
                        <DropdownItemIcon iconSrc={PrintWhite} />
                        {t('Print')}
                      </DropdownItem>
                      <DropdownItem
                      disabled={USQuote}
                      onClick={() => {
                        window.location.href = `${routes.createQuote}?recreate=true&division=${division}&quoteNumber=${quoteNumber}`;
                        return window.location.href;
                      }}>
                        <DropdownItemIcon iconSrc={CreateQuoteFromExistingWhite} />
                        {t('CreateANewQuoteFromThisQuote')}
                      </DropdownItem>
                      <DropdownItem onClick={() => this.openDeleteModal()}>
                        <DropdownItemIcon iconSrc={DeleteWhite} />
                        {t('Delete')}
                      </DropdownItem>
                    </StyledMDBDropdownMenu>
                  </StyledMDBDropdown>
                </Column>
              </Row>
            </Column>
          </LastActions>

          <PageTitle>{t('Your Quote')} - <strong>{quoteNumber}</strong></PageTitle>
          <CategoryTitle>{t('Summary')}</CategoryTitle>
          <div className="row">
            <div className="col-sm-8">
              <GreyBoxWithBorder>
                {!totalAmount || totalAmount.value === 0 ? (
                  <TextProperty>
                    <H3>{t('QuoteNotificationEmail')}</H3>
                  </TextProperty>
                ) : (
                    <TextProperty>
                      <P className="title">
                        {t('Price')}
                      </P>
                      {charges && charges.map((charge, index) => (
                        <H3 className="justified" key={index}>
                          <span>{charge.description}</span> <span>{formatMoney(charge.amount.value, protectedAccount)}</span>
                        </H3>
                      ))}
                      <hr />
                      <H2 className="justified">
                        <span>{t('Total')}</span> <span>{formatMoney(totalAmount, protectedAccount)}</span>
                      </H2>
                      <P className="text-right">{t('ThisQuoteWillExpireOn')} {getMonthDayYear(expiryDate)}</P>
                    </TextProperty>
                )}
              </GreyBoxWithBorder>
            </div>
          </div>
          <SummaryForm formData={data} />
        </>}
        <Terms>
          <CategoryTitle>{t('TermsConditions')}</CategoryTitle>
          <P>{t('ViewQuoteTermsConditions')}</P>
          <P><a href="#">{t('ReadMore')}</a></P>
        </Terms>
      </>
    );
  }
}

ViewQuote.propTypes = {
  isAuthenticated: PropTypes.bool,
  dispatch: PropTypes.func,
  division: PropTypes.string,
  userId: PropTypes.string,
  billingAccounts: PropTypes.array
};

const mstp = (state) => ({
  billingAccounts: state.profile.billingAccounts,
  division: state.profile.division,
  userId: state.profile.userId,
  isAuthenticated: state.profile.isAuthenticated
});

export default connect(
  mstp
)(ViewQuote);
