/**
 * Checks weather an object has no properties - {}
 * @param {*} value the object
 * @throws Will throw an error if object is null/undefined
 */
export function isObjectEmpty(value) {
  if (!value) {
    throw new Error('object not instantiated');
  }

  return Object.keys(value).length === 0;
}

export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}
