import React, { useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import {
  ImgDiv,
  HeroHeader,
  HomeCopyStyle,
  ButtonRow,
  CopyTextHeader,
  CopyTextContent,
  OrangeArrowRight,
  ImageLink,
  HeroLink,
  Header,
  RightButton,
  LinksContainer,
  HorizontalSectionContainer,
  TextSideContainer,
  LeftButton,
  ImageLinkSummary,
  ImageLinkDelimiter,
  MultipanelImgDiv,
  SinglePanelImgDiv,
  PaddinglessColumn,
  MultiPanelTextContainer, HeroControlsContainer, DownArrowContainer, DownArrow, Video, HeroControlsSubContainer,
} from './css';
import { styleTypes } from '../ReactComponent/PrimaryButton';
import TOGGLE_SCROLL_TO_REF from './actions';
import { scrollToRef as scrollToRefUtilsFn } from '../../utils/func';

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const TextSide = ({ fields, centerText }) => (
      <TextSideContainer>
        <CopyTextHeader field={fields.heading} centerText={centerText} />
        <CopyTextContent field={fields.content} centerText={centerText} />
        <ButtonRow>

          {fields.leftButton && fields.leftButton.value && fields.leftButton.value.text && (
            <LeftButton
              type="button"
              styleType={styleTypes.SECONDARY}
              onClick={() => {
                window.location.href = fields.leftButton.value.href;
              }}
              iconRightArrow
            >
              {fields.leftButton.value.text}
            </LeftButton>
          )}

          {fields.rightButton && fields.rightButton.value && fields.rightButton.value.text && (
            <RightButton
              type="button"
              onClick={() => {
                window.location.href = fields.rightButton.value.href;
              }}
              iconRightArrow
            >
              {fields.rightButton.value.text}
            </RightButton>
          )}

        </ButtonRow>
      </TextSideContainer>
);

// eslint-disable-next-line react/no-multi-comp
const ImageSide = ({
  fields: {
    image1, image2, image3, image4, link1, link2, link3, link4, link1Summary, link2Summary, link3Summary, link4Summary
  }
}) => {
  if (image1 &&
      image1.value &&
      image2 &&
      image2.value &&
      image3 &&
      image3.value &&
      image4 &&
      image4.value &&
      link1Summary &&
      link1Summary.value &&
      link2Summary &&
      link2Summary.value &&
      link3Summary &&
      link3Summary.value &&
      link4Summary &&
      link4Summary.value
  ) {
    return (
      <>
      {image1 && image1.value && image1.value.src && (
        <MultipanelImgDiv className="text-at-bottom col-sm-3 first" $img={image1.value.src} alt={image1.value.alt}>
          <ImageLink>
            <Link field={link1} />
            <ImageLinkDelimiter/>
            <ImageLinkSummary field={link1Summary}/>
          </ImageLink>
        </MultipanelImgDiv>
      )}
      {image2 && image2.value && image2.value.src && (
        <MultipanelImgDiv className="text-at-bottom col-sm-3" $img={image2.value.src} alt={image2.value.alt}>
          <ImageLink>
            <Link field={link2} />
            <ImageLinkDelimiter/>
            <ImageLinkSummary field={link2Summary}/>
          </ImageLink>
        </MultipanelImgDiv>
      )}
      {image3 && image3.value && image3.value.src && (
        <MultipanelImgDiv className="text-at-bottom col-sm-3" $img={image3.value.src} alt={image3.value.alt}>
          <ImageLink>
            <Link field={link3} />
            <ImageLinkDelimiter/>
            <ImageLinkSummary field={link3Summary}/>
          </ImageLink>
        </MultipanelImgDiv>
      )}
      {image4 && image4.value && image4.value.src && (
        <MultipanelImgDiv className="text-at-bottom col-sm-3 last" $img={image4.value.src} alt={image4.value.alt}>
          <ImageLink>
            <Link field={link4} />
            <ImageLinkDelimiter/>
            <ImageLinkSummary field={link4Summary}/>
          </ImageLink>
        </MultipanelImgDiv>
      )}
    </>
    );
  }

  return (
    <div className="col">
      <div className="row">
        {image1 && image1.value && image1.value.src && (
          <SinglePanelImgDiv className="text-at-bottom col first" $img={image1.value.src} alt={image1.value.alt}>
            <ImageLink>
              <Link field={link1} />
            </ImageLink>
          </SinglePanelImgDiv>
        )}
      </div>
    </div>);
};

// eslint-disable-next-line react/no-multi-comp
const HomePageCopyBlock = ({ fields, ...rest }) => {
  const { isScrollToRef, toggleScrollToRef, isAuthenticated } = rest;
  const myRef = useRef(null);

  const multiPanel = fields.multiPanel.value;
  const horizontal = fields.horizontal.value;
  const imageFirst = fields.imageFirst.value;

  /**
   * Because the "home page" is made up of multiple instances of HomePageCopyBlock, and
   * because we want to be able to trigger a scroll to one of those instances from another,
   * we're making use of the redux store to determine if we should scroll...
   */
  useLayoutEffect(() => {
    if (isScrollToRef && // Value in store says we should scroll...
        myRef.current && // myRef has been initialized...
        (multiPanel && !horizontal && !imageFirst)) { // Current component instance represents the one we want to scroll to...
      scrollToRefUtilsFn(myRef); // Scroll...
      toggleScrollToRef(); // Reset the redux store value so we can scroll again...
    }
  }, [isScrollToRef]);

  if (multiPanel) {
    return (
      <>
        <HomeCopyStyle ref={myRef}>
          {horizontal ?
            (
          <HorizontalSectionContainer $imageFirst={imageFirst}>
            <PaddinglessColumn $desktop={5}>
              <TextSide fields={fields} />
            </PaddinglessColumn>
            <PaddinglessColumn $desktop={6}>
              {fields.multiPanel && <ImageSide fields={fields} />}
            </PaddinglessColumn>
          </HorizontalSectionContainer>
            ) :
            (
          <div className="justify-content-md-center">
            <div className="col-md-12">
              {imageFirst ? (
                <>
                  <div className="row">
                    {fields.multiPanel && <ImageSide fields={fields} />}
                  </div>
                  <div className="row">
                    <TextSide fields={fields} />
                  </div>
                </>) : (
                  <>
                    <MultiPanelTextContainer>
                        <TextSide fields={fields} centerText />
                    </MultiPanelTextContainer>
                    <div className="row">
                      {fields.multiPanel && <ImageSide fields={fields} />}
                    </div>
                  </>
              )}
            </div>
          </div>
            )}
        </HomeCopyStyle>
      </>
    );
  }

  return (
        <>
          <div>
          <ImgDiv className="hero-image">
            <Video
              playsInline
              autoPlay
              muted
              loop
              poster={fields.videoPoster && fields.videoPoster.value.src}
            >
              <source
                src={fields.video && fields.video.value.src}
                type="video/mp4"
              />
            </Video>
            <HeroControlsContainer>
              <HeroControlsSubContainer>
                <HeroHeader>
                  <Header field={fields.heading}/>
                </HeroHeader>
                <div className="row"><RichText className="contentDescription col" field={fields.content} /></div>
                <ButtonRow className="row">

                  {fields.leftButton && (
                    <LeftButton
                      type="button"
                      onClick={() => {
                        window.location.href = fields.leftButton.value.href;
                      }}
                      iconRightArrow
                    >
                      {fields.leftButton.value.text}
                    </LeftButton>
                  )}

                  {!isAuthenticated &&
                   fields.rightButton &&
                    (
                      <RightButton
                        type="button"
                        styleType={styleTypes.SECONDARY}
                        onClick={() => {
                          window.location.href = fields.rightButton.value.href;
                        }}
                        iconRightArrow
                      >
                        {fields.rightButton.value.text}
                      </RightButton>
                    )}

                </ButtonRow>

                <LinksContainer className="col">
                  {fields.link1 && fields.link1.value && fields.link1.value.text && (
                  <HeroLink className="row">
                    <Link field={fields.link1} />
                    <OrangeArrowRight />
                  </HeroLink>)}
                  {fields.link2 && fields.link2.value && fields.link2.value.text && (
                  <HeroLink className="row">
                    <Link field={fields.link2} />
                    <OrangeArrowRight />
                  </HeroLink>)}
                  {fields.link3 && fields.link3.value && fields.link3.value.text && (
                  <HeroLink className="row">
                    <Link field={fields.link3} />
                    <OrangeArrowRight />
                  </HeroLink>)}
                  {fields.link4 && fields.link4.value && fields.link4.value.text && (
                  <HeroLink className="row">
                    <Link field={fields.link4} />
                    <OrangeArrowRight />
                  </HeroLink>)}
                </LinksContainer>
              </HeroControlsSubContainer>
            </HeroControlsContainer>

            <DownArrowContainer>
              <DownArrow onClick={() => toggleScrollToRef()}/>
            </DownArrowContainer>
          </ImgDiv>
          </div>
        </>
  );
};

const mapStateToProps = (state) => ({
  isScrollToRef: state.homePage.toggleScrollToRef,
  isAuthenticated: state.profile.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  toggleScrollToRef: () => dispatch({ type: TOGGLE_SCROLL_TO_REF }),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageCopyBlock);
