import styled from 'styled-components';
import Error from '../../../assets/icons/Error.svg';

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ErrorIcon = styled.img.attrs(() => ({ src: Error }))`
    width: 42px;
    height: 42px;
    margin-bottom: 32px;
`;
