import styled, { css } from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';
import ArrowDown from '../../../assets/icons/ArrowDown.svg';
import eyeIcon from '../../../assets/icons/View.svg';

export const StyledInput = styled.input`
    width: 100%;
    background: ${colors.WHITE};
    border: 1px solid ${(props) => (props.disabled ? colors.COOL_GREY : colors.BLACK)};
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding: 15px 30px 8px 16px;
    color: ${colors.DARK_GREY};
`;
export const FieldGroup = styled.div`
    margin: 24px 0 58px;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .relative {
        position: relative;
    }

`;

export const VerticalFieldGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction:column;
    position: relative;
    margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '20px')};
    .relative {
        position: relative;
    }

`;

export const ErrorMessage = styled.span`
    font-style: normal;
    padding: 10px 6px;
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    margin-bottom: -34px;
    color:  ${colors.ERROR};
`;
export const Icon = styled.img`
    margin-right: 10px;
`;

export const TextFieldIcon = styled.img`
    position: absolute;
    top: 12px;
    right: 10px;
    transition: opacity 0.2s;
    &:hover {
        opacity: 0.7;
    }
`;

export const RequiredAsterisk = styled.span`
    float: right;
    color: ${colors.BRAND_ORANGE};
    font-size: 1.8rem;
    position: absolute;
    right: 0;
    top: -14px;
`;
export const ResetOnClick = styled.span`
    float: right;
    color: ${colors.BRAND_ORANGE};
    font-size: 1.8rem;
    position: absolute;
    right: 45px;
    bottom: 14px;
    border: 1px solid;
    border-radius: 15px;
    width: 26px;
    cursor: pointer;
`;
export const StyledTextarea = styled.textarea`
    width: 100%;
    background: ${colors.WHITE};
    border: 1px solid ${colors.BLACK};
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding: 15px 56px 8px 16px;
    color: ${colors.DARK_GREY};
    min-height: 15rem;
`;
export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: ${colors.DARK_GREY};
    position: absolute;
    top: 16px;
    left: 20px;
    user-select: none;
    pointer-events: none;
    transition: all 300ms ease 0s;

    &.active {
        font-size: 1.4rem;
        top: -24px;
        transition: all 300ms ease;
    }
`;

export const CheckBox = styled.label`
    margin-right: 3rem;
    font-size: 1.4rem;
    line-height: 2.1rem;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    user-select: none;
    flex-wrap:wrap;
    margin-top: 24px;
    margin-bottom: 58px;

    .hidden-check {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            background-color: ${({ disabled }) => (disabled ? colors.COOL_GREY : colors.BRAND_ORANGE)};

            &:after {
                display: block;
                left: 5px;
                top: 1px;
                width: 7px;
                height: 11px;
                border: solid ${colors.WHITE};
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 21px;
        width: 21px;
        border: 1px solid ${colors.BLACK};
        background-color: ${colors.WHITE};
        border-radius: 2px;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }
`;

export const CheckBoxContainer = styled.div`
    margin-top: 5px;
    ${({ $containerMaxHeight }) => (
    $containerMaxHeight && css`
        max-height: ${$containerMaxHeight};
        overflow-y: auto;
      `
  )}

    ul {
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
     }

    .rw-select-list-label {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width:100%;
            .row {
                width:100%;
            }
        }
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        line-height: 2.1rem;
        margin-right: 24px;

        .rw-select-list-input {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }
    }
    &.checkbox-container{
        .rw-list-option {
            width: ${({ column }) => `${100 / column}%`};
        }

        .rw-list {
            margin: 0;
        }

        .rw-select-list-label {
            margin-right: 3rem;
            font-size: 1.4rem;
            line-height: 2.1rem;
            display: inline-flex;
            align-items: center;
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            user-select: none;
            flex-wrap:wrap;

            .rw-select-list-input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark {
                    background-color: ${({ disabled }) => (disabled ? colors.COOL_GREY : colors.BRAND_ORANGE)};
                    
                    &:after {
                        display: block;
                        left: 5px;
                        top: 1px;
                        width: 7px;
                        height: 11px;
                        border: solid ${colors.WHITE};
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                }
            }
        
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 21px;
                width: 21px;
                border: 1px solid ${(props) => (props.disabled ? colors.COOL_GREY : colors.BLACK)};
                background-color: ${colors.WHITE};
                border-radius: 2px;
                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
            }
        }
    }
    
    &.checkbox-container-disabled{
        .rw-list-option {
            width: ${({ column }) => `${100 / column}%`};
        }

        .rw-list {
            margin: 0;
        }

        .rw-select-list-label {
            margin-right: 3rem;
            font-size: 1.4rem;
            line-height: 2.1rem;
            display: inline-flex;
            align-items: center;
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            user-select: none;
            flex-wrap:wrap;

            .rw-select-list-input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark {
                    background-color: ${({ disabled }) => (disabled ? colors.COOL_GREY : colors.BRAND_ORANGE)};
                    
                    &:after {
                        display: block;
                        left: 5px;
                        top: 1px;
                        width: 7px;
                        height: 11px;
                        border: solid ${colors.WHITE};
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                }
            }
        
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 21px;
                width: 21px;
                border: 1px solid ${(props) => (props.disabled ? colors.COOL_GREY : colors.BLACK)};
                background-color: ${colors.WHITE};
                border-radius: 2px;
                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
            }
        }
    }

    &.radio-container {
        .rw-select-list-label {
            margin-right: 3rem;
            font-size: 1.4rem;
            line-height: 2.1rem;
            display: inline-flex;
            align-items: center;
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            user-select: none;
            flex-wrap:wrap;

            p {
                margin: 0;
            }

            .rw-select-list-input {
                position: absolute;
                opacity: 0;
                cursor: pointer;

                &:checked ~ .checkmark {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:after {
                        display: block;
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: ${({ disabled }) => (disabled ? colors.COOL_GREY : colors.BRAND_ORANGE)};
                   }
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 21px;
                width: 21px;
                background-color: ${colors.WHITE};
                border: 1px solid ${colors.BLACK};
                border-radius: 50%;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
            }
        }
    }
`;

export const DropDown = styled.div`
    margin: 24px 0 58px;
    position: relative;

    .rw-widget-*{
        box-sizing: border-box;
    }
    .rw-widget-container {
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .rw-widget-picker {
        overflow: hidden;
        border-collapse: separate;
        display: inline-table;
        height: 2.429em;
    }
    .rw-filter-input, .rw-input {
        outline: 0;
        color: #555;
        padding: 0 .857em;
        background-color: ${colors.WHITE};
    }
    .rw-filter-input {
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        width:100%;
        font-size:initial;
    }
    .rw-input-reset{
        outline: 0;
        border: 0;
        width: 100%;
        height: 100%;
    }

    .rw-widget-input {
        display: inline-table;
        width: 100%;
        background: ${colors.WHITE};
        border: 1px solid ${colors.BLACK};
        box-sizing: border-box;
        border-radius: 3px;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.4rem;
        padding: 15px 56px 8px 16px;
        color: ${colors.DARK_GREY};
        min-height: 50px;
        
        .rw-input {
            width: 100%;
            height: 100%;
            display: table-cell;

            input {
                display: none;
            }
        }

        .rw-select {
            height: 24px;
            display: table-cell;
            position: relative;
            border: none;
            outline: none;
            white-space: nowrap;
            width: 24px;
            right: -50px;

            .rw-btn {
                background: url(${ArrowDown});
                height: 24px;
                width: 24px;
                background-size: cover;
                border: 0;
                cursor: pointer;
            }
        }
    }


    .rw-popup-transition-exited {
        display: none;
    }

    .rw-popup-container {
        max-height: 185px;
        ${MediaQuery.medium`
            max-height: 285px;
        `}

        overflow-y: auto;
        border: 1px solid ${colors.BLACK};
        padding: 10px 0;
        border-top: 0;
        border-radius: 0 0 3px 3px;
        background: ${colors.WHITE};
        ul {
            list-style-type: none;
            padding: 0;

            li {
                cursor: pointer;
                font-style: normal;
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.4rem;
                padding: 5px 15px;
            }
        }
    }
`;

export const BlockTitle = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: ${colors.BLACK};
    margin: 32px 0;
    
    .asterisk {        
        color: ${colors.BRAND_ORANGE};
        font-size: 1.8rem;
        font-weight: unset !important;
    }
`;

export const ResponsiveTable = styled.div`
width: 100%;
`;

export const TableContainer = styled.div`
    margin-top:25px;
    font-size: 1.4rem;

    table {
        width: 100%;

        // td {
        //     border: 0;
        // }
    
        input[type="radio"] {
            left:35px;
            border-radius: 8px;
            cursor: pointer;
            display: inline-block;
            height: 15px;
            margin-right: 15px;
            position: relative;
            width: 15px;
        }

        thead {
            font-weight: 600;
            th {
                padding: .5rem 2rem;
                font-size: 1.6rem;
                min-width: 80px;
                ${MediaQuery.large`
                    padding: 1rem;
                `}
                    

                span {
                    cursor: pointer;
                }                    
            }
            tr{
                td{
                    margin-left:1px;
                    margin-right:1px;
                    border-bottom: 2px solid ${colors.COOL_GREY};
                }
            }
        }
    
        tbody {
            td {
                margin-left:1px;
                margin-right:1px;
                padding: .5rem 2rem;

                ${MediaQuery.large`
                    padding: 1rem;
                `}
            }
            tr{
                :nth-child(even) {
                    background: #F9F9F9;
                }
            }
        }
    }

    .dropdown {
        .btn{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0;
            padding: 15px 20px;
            font-size: 1.4rem;
            color: ${colors.BLACK} !important;
            background-color: ${colors.WHITE} !important;
            border-color: ${colors.BLACK};

            &:hover {
                background-color: ${colors.WHITE};
                color: ${colors.BLACK};
            }
        }

        .dropdown-menu {
            top: 0;
            left: 0;
            right: 0;
            width: max-content;
            margin-top: 0;
            border-color: ${colors.BLACK};
            border-radius: 0;
            font-size: 1.4rem;

            .dropdown-item {
                white-space: normal;
                margin: 10px 0;
            }
        }
    }

    .no-word-break {
        display: flex;
        align-items: center;
    }
`;

export const PasswordHiddenButton = styled.button.attrs(() => ({ tabIndex: '-1' }))`
    position: absolute;
    right: 10px;
    top: 15px;
    background: url(${eyeIcon});
    width: 24px;
    height: 24px;
    border: none;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;

export const CrossLine = styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    border-top: 2px solid black;
    transform: translate(-15px, -4px) rotate(45deg);
`;
export const SearchIcon = styled.img`
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
`;

export const DateContainer = styled.div`
   width: 100%;
  .react-datepicker-wrapper {
    display: block;
  }
  .react-datepicker__year-read-view--down-arrow {
    top: 3px;
    margin-left: 20px;
  }

  .react-datepicker__day--keyboard-selected.react-datepicker__day--today {
    background-color: ${colors.LIGHT_ORANGE};
    color: ${colors.WHITE};

    &:hover {
        background: ${colors.ORANGE};
    }
  }
`;

export const DateInputContainer = styled.div`
    cursor: pointer;
`;

export const ListLabel = styled.div`
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: ${colors.BLACK};
`;

export const ListLabelAsterisk = styled.span`
    content: "*";
    color: ${colors.BRAND_ORANGE};
    font-size: 1.8rem;
    font-weight: normal;
  `;
export const SmallDesc = styled.div`
  font-size: 12px;
  line-height: 16px;
  ${MediaQuery.large`
  max-width: 50%;
`}
`;
