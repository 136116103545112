import React from 'react';
import PropTypes from 'prop-types';

export const Img = ({ field }) => {
  if (!field || !field.value || !field.value.src) return null;
  return <img src={field.value.src} alt={field.value.alt} />;
};

Img.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  })
};

export default Img;
