import styled from 'styled-components';
import { colors, styleHelpers } from '../../../theme/style-helpers';

export const TextProperty = styled.div`
    font-size: 1.4rem;
    margin: 32px 0;
    font-weight: 500;

    .title {
        margin-bottom: 10px;
    }
`;
export const EditButtonGroup = styled.div`
    text-align: right;
    width: 100%;

    ${styleHelpers.mobile`
        text-align: left;
        order: 1;
    `}
`;
export const LineButton = styled.button`
    -webkit-appearance: none;
    background: none;
    border: none;
    display: inline-flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    cursor: pointer;
    color: ${({ disabled }) => (disabled ? colors.COOL_GREY : colors.BLACK)};
    text-decoration: none;
    padding: 8px 17px;
`;
export const SuccessMsg = styled.div`
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    color: ${colors.LAND_GREEN};
    text-decoration: none;
    padding: 8px 17px;
`;
export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;
