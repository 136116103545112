import React, { useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import RenderField from '../RenderField';
import { isNullOrWhiteSpace } from '../../../utils/strings';
import { isNumeric } from '../../../utils/numbers';
import { isNullOrUndefined } from '../../../utils/object-utils';

const NumericInput = (props) => {
  const [state, setState] = useState({ touched: false });

  const {
    value, onChange, label, min, max, disabled,
  } = props;

  const validateValue = () => {
    if (isNullOrWhiteSpace(value)) {
      return null;
    }

    if (!isNumeric(value)) {
      return t('NotAValidNumber');
    }

    const parsedValue = parseFloat(value);
    if (!isNullOrUndefined(min) && parsedValue < min) {
      return `${t('NumberTooSmall')} ${min}`;
    }

    if (!isNullOrUndefined(max) && parsedValue > max) {
      return `${t('NumberTooBig')} ${max}`;
    }

    return null;
  };

  const error = validateValue();

  return <RenderField
        type="text"
        meta={{ touched: state.touched, error }}
        input={{
          value,
          onChange: (e) => onChange(e.target.value),
          onFocus: () => setState({ touched: true }),
        }}
        disabled={disabled}
        label={label}
    />;
};

NumericInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
};

export default NumericInput;
