import styled, { css } from 'styled-components';
import { MediaQuery } from '../../../theme/style-helpers';
import checkMarkGreenSvg from '../../../assets/icons/CheckmarkGreen.svg';
import crossRedSvg from '../../../assets/icons/CrossRed.svg';
import PixelatedImg from '../PixelatedImg';

export const Ul = styled.ul`
  margin-top: -20px;
  padding: 0;
`;
export const Li = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

export const CheckMarkGreenIcon = styled.img.attrs(() => ({ src: checkMarkGreenSvg }))`
  margin 0 10px 0 0
`;

export const CrossRedIcon = styled.img.attrs(() => ({ src: crossRedSvg }))`
  margin 0 10px 0 0
`;

export const PasswordStrengthView = styled.div`
  width: 100%;
  position: relative;
  ${MediaQuery.medium`width: 244px`}
  margin: 30px 0 80px 0;
`;

export const PasswordStrengthOutline = styled.div`
  width: 100%;
  height: 15px;
  border: 2px solid black;
  border-radius: 8px;
  position: absolute;
`;

export const PasswordStrengthFill = styled.div`
  height: 15px;
  transition: width 0.5s;
  ${({ $lvl }) => {
    const width = $lvl * 100;
    let backgroundColor = '#CE0505';
    let borderRadius = '8px';
    if ($lvl > 0.5) {
      backgroundColor = $lvl === 1 ? '#4B9560' : '#EAAA00';
      borderRadius = $lvl === 1 ? '8px' : '8px 0 0 8px';
    }

    return css`
      width: ${width}%;
      background-color: ${backgroundColor};
      border-radius: ${borderRadius};
    `;
  }}
`;

export const PixelatedImgStyled = styled(PixelatedImg)`
  display: none;
  margin: 120px 0 0 60px;
  ${MediaQuery.medium`display: block`}
`;
