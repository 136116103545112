import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { t } from 'i18next';
import { ActionsContainer, FormContainer, RadioColumns } from '../css';
import {
  chassisSize,
  containerDataByService,
  disabledEquipmentSize,
  FORM_NAME,
  frequencyOfShipment,
  genset,
  incoterms as incotermsListCreator,
  itemsByService,
  services as servicesListCreator,
  serviceTypes,
  temperatureRequirements,
  temperatureUnits,
  typeOfDelivery,
  typeOfEquipment,
} from '../constants';
import { H1, H2, P } from '../../../theme/typographySitecore';
import RenderField from '../../ReactComponent/RenderField';
import { isRequired } from '../../../utils/validator';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import ContainerList from '../../ReactComponent/Measurement/ContainerList';
import TotalWeightBlock from '../../ReactComponent/TotalWeightBlock';
import { Button } from './common';
import { styleTypes } from '../../ReactComponent/PrimaryButton';

const isUncompletedContainer = (containers) => containers.some(
  (container) => !container.completed
    || (container.items && container.items.some(({ completed }) => !completed)),
);

const resetFields = [
  'chassisSize',
  'incoterms',
  'deliveryType',
  'equipmentType',
  'temperature',
  'temperatureUnit',
  'gensetRequired',
  'temperatureRequirements'
];

let Step2 = ({
  onBack,
  onNext,
  invalid,
  pristine,
  data,
  initialized,
  change,
  clearFields,
  fields,
}) => {
  let itemsList = [];
  if (!initialized) return null;
  const prevService = useRef(null);
  if (data.containers) {
    itemsList = data.containers.reduce(
      (acc, { items }) => (items ? acc.concat(items) : acc),
      itemsList,
    );
  }

  useEffect(() => {
    if (prevService.current && prevService.current !== data.service) {
      change('containers', []);
      resetFields.forEach((field) => clearFields(false, false, field));
    }

    prevService.current = data.service;
  }, [data.service]);
  const services = servicesListCreator(fields);
  const serviceId = services.indexOf(data.service);

  const incoterms = incotermsListCreator(t);

  return (
    <FormContainer>
      <form>
        <H1 field={t('SctnQuoteServiceAndEquipment')} verticalOffset={40}/>
        <H2 field={t('SctnQuotePickMode')}/>
        <RadioColumns column={3}>
          <Field
            name="service"
            type="checkboxes"
            data={services}
            required
            component={RenderField}
            validate={[isRequired]}
            defaultValue={services[0]}
          />
        </RadioColumns>
        <>
          {serviceId !== serviceTypes.TRUCKLOAD && (
            <>
              <H2 field={t('SctnQuoteTypeOfDelivery')}/>
              <Row>
                <Column>
                  <RadioColumns column={2}>
                    <Field
                      name="deliveryType"
                      type="checkboxes"
                      required
                      data={typeOfDelivery(t)[serviceId]}
                      component={RenderField}
                      validate={[isRequired]}
                    />
                  </RadioColumns>
                </Column>
              </Row>
            </>
          )}
          {[
            serviceTypes.OCEAN_FCL,
            serviceTypes.OCEAN_LCC,
            serviceTypes.AIR,
          ].includes(serviceId) && (
            <>
              <H2 field={t('SctnQuoteIncoterms')}/>
              <Row>
                <Column $tablet={5} $mobile={12}>
                  <Field
                    name="incoterms"
                    component={RenderField}
                    label={t('SctnQuoteListOfIncoterms')}
                    type="dropdown"
                    data={incoterms}
                    validate={[isRequired]}
                    required
                  />
                </Column>
              </Row>
            </>
          )}
          <H2 field={t('SctnQuoteFrequencyOfShipment')}/>
          <Row>
            <Column $tablet={5} $mobile={12}>
              <Field
                name="frequencyOfShipment"
                component={RenderField}
                label={t('SctnQuoteSelectFrequency')}
                type="dropdown"
                data={frequencyOfShipment(t)}
                validate={[isRequired]}
                required
              />
            </Column>
          </Row>
          {serviceId === serviceTypes.OCEAN_FCL && (
            <Row>
              <Column $tablet={5} $mobile={12}>
                <Field
                  name="chassisSize"
                  component={RenderField}
                  label={t('SctnQuoteChassisSize')}
                  type="dropdown"
                  data={chassisSize}
                  validate={[isRequired]}
                  required
                />
              </Column>
            </Row>
          )}
          {serviceId !== serviceTypes.AIR ? (
            <>
              <H2 field={t('SctnQuoteTypeOfEquipment')}/>
              <Row>
                <Column>
                  <RadioColumns column={3}>
                    <Field
                      name="equipmentType"
                      type="checkboxes"
                      required
                      data={typeOfEquipment(fields)}
                      component={RenderField}
                      validate={[isRequired]}
                    />
                  </RadioColumns>
                </Column>
              </Row>
            </>
          ) : (
            <>
              <H2 field={t('SctnQuoteTemperatureOfShipment')}/>
              <Row>
                <Column>
                  <RadioColumns column={10}>
                    <Field
                      name="temperatureRequirements"
                      type="checkboxes"
                      required
                      data={temperatureRequirements(t)}
                      component={RenderField}
                      validate={[isRequired]}
                    />
                  </RadioColumns>
                </Column>
              </Row>
            </>
          )}
          {(data.temperatureRequirements === temperatureRequirements(t)[0]
            || data.equipmentType === typeOfEquipment(fields)[0]) && <>
            <Row>
              <Column $tablet={4} $mobile={12}>
                <Field
                  name="temperature"
                  type="text"
                  component={RenderField}
                  required
                  validate={[isRequired]}
                  label={t('Temperature')}
                />
              </Column>
              <Column $tablet={1} $mobile={0}/>
              <Column $tablet={4} $mobile={12}>
                <Field
                  name="temperatureUnit"
                  component={RenderField}
                  label={t('SctnQuoteSelectTemperature')}
                  type="dropdown"
                  data={temperatureUnits}
                  validate={[isRequired]}
                  required
                />
              </Column>
            </Row>
            <Row>
              <Column $tablet={4} $mobile={12}>
                <Field
                  name="gensetRequired"
                  component={RenderField}
                  label={t('SctnQuoteGensetRequired')}
                  type="dropdown"
                  data={genset}
                  validate={[isRequired]}
                  required
                />
              </Column>
            </Row>
          </>}
          <ContainerList
            type="editable"
            name="containers"
            formName={FORM_NAME}
            isItem={itemsByService[serviceId]}
            isContainerData={containerDataByService[serviceId]}
            disabledEquipmentSize={disabledEquipmentSize[serviceId]}
            parentFields={fields}
          />
          <TotalWeightBlock
            measurementList={itemsList}
            measurementType={data.unitType.value}
            totalNumber
          />
          <P
            verticalOffset={40}
            field={fields.containerNote}
          />
        </>
        <ActionsContainer>
          <Button
            type="button"
            styleType={styleTypes.SECONDARY}
            onClick={onBack}
            iconLeftArrow
          >
            {t('Back')}
          </Button>
          <Button
            type="submit"
            iconRightArrow
            disabled={
              invalid || pristine || isUncompletedContainer(data.containers)
            }
            onClick={onNext}
          >
            {t('Next')}
          </Button>
        </ActionsContainer>
      </form>
    </FormContainer>
  );
};

Step2 = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: FORM_NAME,
})(Step2);

export default Step2 = connect((state) => {
  const data = getFormValues(FORM_NAME)(state);
  return {
    data,
  };
})(Step2);
