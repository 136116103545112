import styled from 'styled-components';
import checkMarkSvg from '../../assets/icons/CheckMarkSolidOrange.svg';
import checkMarkGreenSvg from '../../assets/icons/CheckmarkGreen.svg';
import crossRedSvg from '../../assets/icons/CrossRed.svg';
import downloadSvg from '../../assets/icons/DownloadOrange.svg';
import { colors, MediaQuery } from '../../theme/style-helpers';
import { H2 } from '../../theme/typographySitecore';

export const SignUpPage = styled.div`
    letter-spacing: 0.01em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Li = styled.li`
    list-style: none;
    display: flex;
    align-items: center;
    margin: 5px 0;
`;

export const InformationPage = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 740px;
    font-family: Montserrat;
    padding-bottom: 30px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .row {
            display: block !important;
        }

        button {
            min-width: 200px;
        }

        p {
            max-width: 500px;
        }
    }
`;

export const CheckMarkIcon = styled.img.attrs(() => ({ src: checkMarkSvg }))`
    margin 0 10px 0 0
`;

export const CheckMarkGreenIcon = styled.img.attrs(() => ({
  src: checkMarkGreenSvg,
}))`
    margin 0 10px 0 0
`;

export const CrossRedIcon = styled.img.attrs(() => ({ src: crossRedSvg }))`
    margin 0 10px 0 0
`;

export const DownloadIcon = styled.img.attrs(() => ({ src: downloadSvg }))`
    margin 0 10px 0 0
`;

export const IntroIcon = styled.img`
    width: 148px;
    height: 148px;
    margin: 60px 0 42px 0;
`;

export const OffsetContainer = styled.div`
    margin: 20px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    ${MediaQuery.medium`
    width: auto;
    margin: 40px 10px;
  `}
`;

export const ActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    ${MediaQuery.medium`
    flex-direction: row;
  `} @media screen and (
    -ms-high-contrast: active), (-ms-high-contrast: none) {
    button {
        min-width: 200px;
    }
}
`;

export const ProgressBarDecorator = styled.div`
    width: 100%;
`;

export const FormContainer = styled.div`
    width: 100%;
`;

export const AccordionPage = styled.div`
    padding: 0 10%;
`;

export const FileUploadGroup = styled.div`
    .squeeze {
        margin: 20px 0;
        padding: 0;
    }
`;

export const AddItemContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const AddItemLabel = styled.label`
    font-size: 16px;
    margin: 0 0 0 10px;
    color: ${colors.DARK_GREY};
    cursor: pointer;
`;

export const ControlLabel = styled(H2)`
    display: inline;
    margin-right: 5px;
`;

export const LineButton = styled.button`
    -webkit-appearance: none;
    background: none;
    border: none;
    display: inline-flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    cursor: pointer;
    color: ${colors.BLACK};
    text-decoration: none;
    padding: 8px 17px;
    margin-top: 32px;
`;
export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;
