import React, { useRef, useEffect, useState } from 'react';

const PixelatedImg = ({
  img,
  lvl,
  fitHeight = 100,
  className
}) => {
  const canvasRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const image = new Image();
    image.src = img;
    const onLoad = () => {
      if (lvl >= 1) {
        lvl = 1; // define minimal level
      } else {
        lvl /= 4;
      } // make pixelation more visible

      if (lvl <= 0.02) lvl = 0.02; // define maximal level of pixelation
      // eslint-disable-next-line no-shadow
      const width = (image.width * fitHeight) / image.height;
      setWidth(width);
      const context = canvasRef.current.getContext('2d');
      context.imageSmoothingEnabled = lvl === 1;
      context.drawImage(image, 0, 0, width * lvl, fitHeight * lvl);
      context.drawImage(
        canvasRef.current,
        0,
        0,
        width * lvl,
        fitHeight * lvl,
        0,
        0,
        width,
        fitHeight
      );
    };
    image.addEventListener('load', onLoad);
    return () => image.removeEventListener('load', onLoad);
  }, [img, lvl, fitHeight]);

  return (
    <canvas
      className={className}
      ref={canvasRef}
      width={width}
      height={fitHeight}
    />
  );
};

export default PixelatedImg;
