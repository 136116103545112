import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { P } from '../../theme/typography';
import {
  PageTitle,
} from './css';
import ShipmentTracking from './tiles/shipmentTracking';
import QuoteTracking from './tiles/quoteTracking';
import PickupSummary from './tiles/pickupSummary';
import Tutorials from './tiles/tutorials';
import QuickReports from './tiles/quickReports';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';

class ViewUserDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quotesData: [],
      shipmentsData: [],
      invoicesData: [],
    };
  }

  onSetQuotesData = (data) => {
    this.setState({ quotesData: data });
  };

  onSetShipmentsData = (data) => {
    this.setState({ shipmentsData: data });
  };

  onSetInvoicesData = (data) => {
    this.setState({ invoicesData: data });
  };

  render() {
    const {
      fields, division, accountNumbers, isAuthenticated
    } = this.props;
    const { quotesData, shipmentsData, invoicesData } = this.state;

    return (
            <>
                {fields.heading && fields.heading.value &&
                    <PageTitle>
                        <Text field={fields.heading}/>
                    </PageTitle>}
                {fields.description && fields.description.value &&
                    <P><RichText tag="P" field={fields.description}/></P>}

                <Row>
                    <Column $tablet={9} $mobile={12}>
                        {fields.shipmentTrackingDashboard &&
                            <ShipmentTracking
                            fields={fields.shipmentTrackingDashboard.fields}
                            division={division}
                            accountNumbers={accountNumbers}
                            onSetData={this.onSetShipmentsData}
                            isAuthenticated={isAuthenticated}/>}
                        {fields.quoteTrackingDashboard &&
                            <QuoteTracking
                            fields={fields.quoteTrackingDashboard.fields}
                            division={division}
                            accountNumbers={accountNumbers}
                            onSetData={this.onSetQuotesData}/>}
                    </Column>
                    <Column $tablet={3} $mobile={12}>
                        {fields.pickupSummaryDashboard &&
                            <PickupSummary fields={fields.pickupSummaryDashboard.fields} division={division} accountNumbers={accountNumbers}/>}
                        {fields.tutorialsDashboard &&
                            <Tutorials $fields={fields.tutorialsDashboard.fields}/>}
                        {fields.quickReportsDashboard &&
                            <QuickReports
                            fields={fields.quickReportsDashboard.fields}
                            quotesData={quotesData}
                            shipmentsData={shipmentsData}
                            invoicesData={invoicesData}/>}
                    </Column>
                </Row>
            </>);
  }
}

const mstp = (state) => ({
  isAuthenticated: state.profile.isAuthenticated,
  division: state.profile.division,
  userId: state.profile.userId,
  accountNumbers: state.profile.accountNumbers,
});

export default connect(
  mstp
)(ViewUserDashboard);
