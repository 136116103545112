import styled from 'styled-components';

const CenteredMessage = styled.div`
    width: 100%;
    height: 300px;
    text-align: center;
    vertical-align: middle;
    padding-top: 120px;

    &.padding-40 {
        padding-top: 40px;
    }

    &.padding-70 {
        padding-top: 70px;
    }

    .icon-100 {
        width: 100px;
        height: 100px;
    }
`;
export default CenteredMessage;
