import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';

export const PageTitle = styled.div`

    font-weight: 300;
    font-size: 4.8rem;

    margin-bottom: 32px;

    &.justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const LayoutContainer = styled.div`
    margin:30px 0;
    position:relative;

    &.with-navigation{
        display: flex;
        flex-direction: row;
        min-height: 100%;
        align-items: stretch;       
    }

    .static-section{
        margin-top: 30px;
        margin-bottom:30px;

        &:first-of-type{
            margin-top:0;
        }
    }
`;

export const GoToTop = styled.div`
    display: inline-block;
    width: 30px;
    text-align: center;
    padding: 0;

    position: absolute;
    right: 0;
    bottom: -25px;

    ${MediaQuery.medium`
        right: -30px;
        bottom: 0;
    `}

    a{
        color:${colors.BLACK}

        &:hover{
            text-decoration:none;
            color:${colors.BLACK}
        }
    }    
`;

export const LeftColumn = styled.div`
    vertical-align:top;
    padding-right:5px;
    flex: 0 0 25%;
    max-width:25%;
    display: none;

    ${MediaQuery.medium`
        padding-right:30px;
        display: block;
    `}

    a {
        display: block;
        font-size: 1.5rem;
        line-height:2.5rem;

        ${MediaQuery.medium`
            line-height:4rem;
        `}

        text-decoration:none;
        border-bottom: 1px solid ${colors.COOL_GREY};
        color:${colors.BLACK}

        &:last-of-type{
            border:none;
        }

        &:hover{
            text-decoration:none;
            color:${colors.BLACK}
        }

        &.active{
            padding-left:5px;
            border-left:solid 3px ${colors.BRAND_ORANGE};
            font-weight:600;

            ${MediaQuery.medium`
                padding-left:10px;
            `}
        }
    }
`;

export const NavContainer = styled.div`
    position:sticky;
    top:0;
`;

export const RightColumn = styled.div`    
    flex: 0 0 100%;
    max-width:100%;

    ${MediaQuery.medium`
        padding-left:30px;
        flex: 0 0 75%;
        max-width:75%;
    `}
`;

export const Icon = styled.img`
    &.icon-up{
        width: 100%;
        height: 25px;
    }

    margin-bottom:5px;
`;
