import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { ActionButton } from '../ReactComponent/Modal/css';
import AlertModal from '../ReactComponent/Modal/AlertModal';
import { P } from '../../theme/typographySitecore';
import { runningOnBrowser, homeUrl } from '../../utils/func';
import { routes } from '../../utils/constants';
import SESSION_TIMEOUT_QUERY_PARAM_KEY from '../Logout/constants';

const SessionTimeoutModal = ({ fields }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (runningOnBrowser()) {
      const urlParams = new URLSearchParams(window.location.search);
      const isSessionTimeoutParam = urlParams.get(SESSION_TIMEOUT_QUERY_PARAM_KEY);
      setIsOpen(!!isSessionTimeoutParam);
    }
  }, []);

  const onClose = () => {
    setIsOpen(false);
    if (window && typeof window !== 'undefined') {
      window.location.href = homeUrl();
    }
  };

  const header = () => (
    <div>{fields.header.value}</div>
  );

  const content = () => (
    <P field={fields.summary}/>
  );

  const actions = () => (
    <ActionButton
    className="active"
    iconRightArrow
    onClick={() => {
      window.location.href = routes.login;
      return window.location.href;
    }}>
      {t('LogIn')}
    </ActionButton>
  );

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      content={content}
      actions={actions}
    />
  );
};

export default SessionTimeoutModal;
