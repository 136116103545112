import styled from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';

export const GoogleMapDiv = styled.div`

    display: none;
    height:320px;
    margin:20px 10px 10px;
    border: 1px solid ${colors.BRAND_ORANGE};

    ${MediaQuery.medium`
        display:block;
    `}
`;

export const GoogleMapTerminal = styled.div`

    display: none;
    height:532px;
    margin: 0 10px 10px;
    border: 1px solid ${colors.BRAND_ORANGE};

    ${MediaQuery.medium`
        display:block;
    `}
`;
