import React from 'react';
import PropTypes from 'prop-types';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import TerminalLocationDetails from '../../ReactComponent/TerminalLocationDetails';

const AllLocations = ({
  fields,
  locationData,
  divisionName
}) => (<>
  <Row>
    <Column $tablet={12} $mobile={12}>
      <TerminalLocationDetails key="0" mapid="google-map-0" fields={fields} detailsData={locationData} divisionData={divisionName}/>
    </Column>
  </Row>
</>);

AllLocations.propTypes = {
  fields: PropTypes.object,
  locationData: PropTypes.array,
  divisionName: PropTypes.string
};

export default AllLocations;
