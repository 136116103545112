import React from 'react';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import Step0 from './steps/Step0';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import FormProgressBar from '../ReactComponent/FormProgressBar';
import { SignUpPage, ProgressBarDecorator } from './css';
import { scrollToTop } from '../../utils/func';
import { FORM_NAME } from './constants';

const steps = ['BasicInformation', 'CreditApplication', 'PendingApproval'];

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0
    };
  }

  handleSubmit = () => {};

  cancel = (e) => {
    e.preventDefault();
    this.props.dispatch(reset(FORM_NAME));
    this.setState(() => ({ currentStep: 0 }));
    scrollToTop();
  };

  next = (e) => {
    e.preventDefault();
    scrollToTop();
    this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
      setTimeout(() => scrollToTop(), 200);
    });
  };

  finish = () => {
    scrollToTop();
    this.setState(() => ({ currentStep: steps.length }));
  };

  render() {
    const { fields } = this.props;
    const { currentStep } = this.state;

    return (
      <SignUpPage>
        <ProgressBarDecorator>
          <FormProgressBar
            current={currentStep - 1}
            steps={steps}
            disabled={currentStep === 0}
          />
        </ProgressBarDecorator>

        {
          {
            0: <Step0 fields={fields} onNext={this.next}/>,
            1: (
              <Step1
                fields={fields}
                onNext={this.next}
                onCancel={this.cancel}
                finish={this.finish}
              />
            ),
            2: (
              <Step2
                fields={fields}
                onNext={this.next}
                onCancel={this.cancel}
              />
            ),
            3: <Step3 fields={fields} onNext={this.next}/>
          }[currentStep]
        }
      </SignUpPage>
    );
  }
}

export default connect()(SignUp);
