import styled, { css } from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';

export const ProgressContainer = styled.div`
    display: flex;
    padding: 40px 0 0;
    flex-wrap: wrap;

    ${MediaQuery.large`
        padding-bottom: 40px;
    `}

    .mobile-step {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.3rem;
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;

        ${MediaQuery.large`
            display: none;
        `}
        ${({ disabled }) => disabled && css`color: ${colors.COOL_GREY};`}
    }
`;

export const ProgressItem = styled.div`
    width: ${({ width }) => `calc(100% / ${width})`};
    text-align: center;

    .step {
        display: none;

        ${MediaQuery.large`
            display: block;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.3rem;
            color: ${colors.COOL_GREY};
        `}
    }

    .title {
        display: none;

        ${MediaQuery.large`
            display: block;
            font-style: normal;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.4rem;
            padding: 8px 0;
            color: ${colors.COOL_GREY};
        `}
    }

    .progress-bar {
        height: 4px;
        display: block;
        background-color: ${colors.COOL_GREY};
        margin: 0 4px;
    }

    &.active {

        .step {
            color: ${colors.BLACK};
        }

        .title {
            color: ${colors.DARK_GREY};
        }

        .progress-bar {
            background-color: ${colors.BRAND_ORANGE};
        }
    }

    &.done {
        .step {
            color: ${colors.BLACK};
        }

        .title {
            color: ${colors.DARK_GREY};
        }

        .progress-bar {
            background-color: ${colors.DARK_GREY};
        }
    }
`;
