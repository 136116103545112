import { OPEN_CHAT_WINDOW, CLOSE_CHAT_WINDOW } from '../components/ReactComponent/ChatWindow/actions';

const initialState = {
  isOpen: false,
};

const chatWindowReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CHAT_WINDOW:
      return {
        ...state,
        isOpen: true
      };
    case CLOSE_CHAT_WINDOW:
      return {
        ...state,
        isOpen: false
      };
    default:
      return state;
  }
};

export default chatWindowReducer;
