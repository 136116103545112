import styled from 'styled-components';
import close from '../../../assets/icons/Close.svg';
import PrimaryButton from '../PrimaryButton';
import { colors, MediaQuery } from '../../../theme/style-helpers';

export const ModalContainer = styled.div`
  line-height: 150%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  
  ${MediaQuery.medium`
    padding: 0 100px;
  `}
`;

export const ModalHeader = styled.div`
  padding: 20px 0;
  ${MediaQuery.medium`
    padding: 40px 0;
    padding-bottom: 20px;
  `}
  font-size: 3em;
  line-height: normal !important;
  text-align: center;
`;

export const ModalContent = styled.div`
  width: 100%;
  font-size: 13px;
  text-align: center;
`;

export const ModalActions = styled.div`
   padding: 20px 0;
`;

export const ModalFooter = styled.div`
  text-align: center;
`;

export const ModalCloseButton = styled.button`
  align-self: flex-end;
  border: none;
  background: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  padding: 5px 5px 5px 10px;
  transition: background-color 0.4s;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #EEE;
  }
`;

export const CloseIcon = styled.img.attrs(() => ({ src: close }))`
`;

export const ActionButton = styled(PrimaryButton)`
  width: 166px;
`;

const MAX_Z_INDEX = 10000;

export const modalStyle = ({
  shrinkwrapContent,
  maximizeZIndex
}) => ({
  overlay: {
    zIndex: maximizeZIndex ? MAX_Z_INDEX : 'unset',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    width: shrinkwrapContent ? 'auto' : '50%',
    minWidth: '30em',
    maxWidth: '90em',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '6px',
    background: `${colors.WHITE}`,
    padding: '15px',
  },
});
