import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { t } from 'i18next';
import RenderField from '../../ReactComponent/RenderField';
import { validnumberregex } from '../constants';
import { P } from '../../../theme/typography';
import { PrimaryButton, ButtonGroup, Icon } from '../css';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import { isRequired } from '../../../utils/validator';
import ArrowRightWhite from '../../../assets/icons/ArrowRightWhite.svg';
import { Divisions } from '../../../utils/enums';

const Shipment = ({
  fields, isAuthenticated, formData, valid
}) => {
  const shipmentNumbers = valid && formData ? formData.shipmentNumbers.split(validnumberregex)
    .map((num) => (num ? num.trim() : undefined))
    .reduce((accum, curr) => (curr ? accum.concat(curr) : accum), [])
    : null;

  return (<>
        {fields.shipmentInstructions
            && <Row>
                <Column $tablet={8} $mobile={12}>
                    <P><RichText field={fields.shipmentInstructions} /></P>
                </Column>
            </Row>}
        <Row>
            {!isAuthenticated
                && <Column $tablet={3} $mobile={12}>
                    <Field
                        name="division"
                        type="dropdown"
                        data={[
                          { name: t('SelectaService'), value: Divisions.SelectaService.name },
                          { name: t('CommerceSolutions'), value: Divisions.Sameday.name },
                          { name: t('Freight'), value: Divisions.Freight.name },
                        ]}
                        textField="name"
                        valueField="value"
                        component={RenderField}
                        required
                        validate={[isRequired]}
                        label={t('Service')}
                        style={{ width: '100%' }}
                    />
                </Column>}
            <Column $tablet={4} $mobile={12}>
                <Field
                    name="shipmentNumbers"
                    type="textarea"
                    component={RenderField}
                    required
                    validate={[isRequired]}
                    label={t('ShipmentNumbers')}
                />
                {shipmentNumbers && <span className="added-numbers">{shipmentNumbers.length} {t('NumbersAdded')}</span>}
            </Column>
            <Column $tablet={5} $mobile={12}>
                <ButtonGroup>
                    <PrimaryButton type="submit" className="submit">
                        <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                        {t('Track')}
                    </PrimaryButton>
                </ButtonGroup>
            </Column>
        </Row>
    </>);
};

Shipment.propTypes = {
  fields: PropTypes.shape({
    shipmentInstructions: PropTypes.object,
  }),
  isAuthenticated: PropTypes.bool,
  formData: PropTypes.shape({
    shipmentNumbers: PropTypes.string,
  }),
  valid: PropTypes.bool,
};

export default Shipment;
