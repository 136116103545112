import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { t } from 'i18next';
import RenderField from '../../../ReactComponent/RenderField';
import {
  isEmail,
  isPhone,
  isRequired,
  maxChar50
} from '../../../../utils/validator';
import Column from '../../../ReactComponent/Column';
import { digitOnly, phoneNumber } from '../../../../utils/mask';
import { H2, H3, P } from '../../../../theme/typographySitecore';
import { AccordionPage, FileUploadGroup } from '../../css';
import AddressComplete from '../../../ReactComponent/AddressComplete';
import { FORM_NAME } from '../../constants';
import Row from '../../../ReactComponent/Row';
import FieldFileInput from '../../../ReactComponent/FileUploadButton';
import Tooltip from '../../../ReactComponent/Tooltip';

const OPTIONAL_FIELD = true;
const AccountPayable = ({
  USQuote,
  thirdPartyInvoices,
  redraw,
  fields: {
    thirdPartyDetails,
    creditApplicationTermsConditions
  }
}) => {
  useEffect(() => {
    redraw();
  }, [thirdPartyInvoices]);
  return (
    <AccordionPage>
      <H2 field={t('AdditionalCompanyDetails')} verticalOffset={50} />
      <Field
        name="companyTradeName"
        type="text"
        component={RenderField}
        label={t('CompanyTradeName')}
        validate={[maxChar50]}
      />
      {USQuote ? (
        <Field
          name="mailingAddress"
          type="text"
          component={RenderField}
          label={t('MailingAddress')}
        />
      ) : (<AddressComplete fieldName="mailingAddress" validateData={OPTIONAL_FIELD} label={t('MailingAddress')} formName={FORM_NAME} />)}
      <Field
        name="emailForInvoices"
        type="email"
        component={RenderField}
        required
        showErrorAlways
        validate={[isRequired, isEmail]}
        label={t('EmailForInvoices')}
      />
      <Field
        name="estimatedBusinessStartDate"
        type="monthyear"
        component={RenderField}
        label={t('EstimatedBusinessStartDate')}
        required
        validate={[isRequired]}
      />
      <Field
        name="salesRepresentative"
        type="text"
        component={RenderField}
        validate={[maxChar50]}
        label={t('SalesRepresentative')}
      />
      <H2 field={t('CreateAccountAccountPayableContact')} verticalOffset={50} />
      <Field
        name="accountPayableName"
        type="text"
        component={RenderField}
        required
        showErrorAlways
        validate={[isRequired]}
        label={t('Name')}
      />
      <Field
        name="accountPayableEmail"
        type="email"
        component={RenderField}
        required
        showErrorAlways
        validate={[isRequired, isEmail]}
        label={t('EmailAddress')}
      />
      <Field
        name="accountPayablePhone"
        type="text"
        component={RenderField}
        required
        showErrorAlways
        validate={[isPhone, maxChar50, isRequired]}
        normalize={phoneNumber}
        label={t('PhoneNumber')}
      />
      <H2 field={t('CreateAccountThirdPartyDetails')} />
      <P
        field={thirdPartyDetails}
        verticalOffset={30}
      />

      <Field
        name="thirdPartyInvoices"
        label={t('CreateAccountThirdPartyInvoices')}
        component={RenderField}
        type="radio"
        value={thirdPartyInvoices}
        required
        showErrorAlways
        data={[
          {
            name: t('Yes'),
            value: true
          },
          {
            name: t('No'),
            value: false
          },
        ]}
        validate={[isRequired]}
      />
      {thirdPartyInvoices && thirdPartyInvoices.value && (
        <>
          <Field
            name="thirdPartyCompanyName"
            type="text"
            component={RenderField}
            required
            showErrorAlways
            validate={[isRequired]}
            label={t('Name')}
          />
          {USQuote ? (
            <Field
              name="thirdPartyCompanyAddress"
              type="text"
              component={RenderField}
              label={t('Address')}
              validate={[maxChar50, isRequired]}
              required
              showErrorAlways
            />
          ) : (
            <AddressComplete
              fieldName="thirdPartyCompanyAddress"
              formName={FORM_NAME}
              showErrorAlways
            />
          )}
          <Row style={{ marginTop: '-25px' }}>
            <Column $tablet={7} $mobile={12}>
              <Field
                name="thirdPartyPhoneNumber"
                type="text"
                component={RenderField}
                required
                showErrorAlways
                validate={[isPhone, maxChar50]}
                normalize={phoneNumber}
                label={t('PhoneNumber')}
              />
            </Column>
            <Column $tablet={5} $mobile={12}>
              <Field
                name="thirdPartyFax"
                type="text"
                component={RenderField}
                validate={[maxChar50]}
                normalize={digitOnly}
                label={t('Fax')}
              />
            </Column>
          </Row>
          <Field
            name="thirdPartyInvoicingEmail"
            type="email"
            component={RenderField}
            required
            showErrorAlways
            validate={[isRequired, isEmail]}
            label={t('EmailAddress')}
          />
          <H3 field={t('CreateAccountAttachInvoices')} verticalOffset={40}/>
          <Tooltip tip={t('TipAttachment')} marginLeft/>
          <FileUploadGroup>
            <Field
              name="additionalNodesBilling"
              type="text"
              component={RenderField}
              label={t('AdditionalNotes')}
              className="squeeze"
            />
            <Field
              component={FieldFileInput}
              attachButton="Attach"
              name="attachBilling"
              className="col-sm-12 inline squeeze"
            />
          </FileUploadGroup>
          <H3 field={t('CreateAccountAdditionalAccountAddresses')} verticalOffset={40}/>
          <P field={t('CreateAccountAdditionalNotes')} verticalOffset={20}/>
          <FileUploadGroup>
            <Field
              name="additionalBranchNotes"
              type="text"
              component={RenderField}
              label={t('AdditionalNotes')}
              className="squeeze"
            />
            <Field
              component={FieldFileInput}
              attachButton="Attach"
              name="attachBranch"
              className="col-sm-12 inline squeeze"
            />
          </FileUploadGroup>
        </>
      )}
      <H3 field={t('CreateAccountCreditTermsConditions')} />
      <P
        field={creditApplicationTermsConditions}
      />
      <Field
        name="creditTC"
        type="singleCheckbox"
        label={t('AcceptTermsConditions')}
        component={RenderField}
        required
        showErrorAlways
        validate={[isRequired]}
      />
    </AccordionPage>
  );
};

export default AccountPayable;
