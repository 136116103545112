import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import logoColourOnBlack from '../../../assets/logo/logoColourOnBlack.svg';
import iconFacebook from '../../../assets/icons/Facebook.svg';
import iconLinkedIn from '../../../assets/icons/LinkedIn.svg';
import iconPhone from '../../../assets/icons/Phone.svg';
import iconEmail from '../../../assets/icons/Email.svg';
import iconChat from '../../../assets/icons/Chat.svg';
import { Link, MainFooter, MarginlessP } from './css';
import { OPEN_CHAT_WINDOW } from '../ChatWindow/actions';
import { Locales } from '../../../utils/enums';

const Footer = (props) => {
  const handleLanguageSwitch = (type) => {
    let url;
    const locale = window.location.pathname.toLowerCase().includes(`/${Locales.French.toLowerCase()}/`) ? 'fr-CA' : 'en';
    if (locale === 'fr-CA') {
      if (type === 'privacy') {
        url = 'https://dayross.com/fr-CA/privacy-policy';
      } else {
        url = 'https://dayross.com/fr-CA/accessibility';
      }
    } else if (type === 'privacy') {
      url = 'https://dayross.com/en/privacy-policy';
    } else {
      url = 'https://dayross.com/accessibility';
    }

    window.open(url, '_blank');
  };

  const { openChatWindow } = props;

  return (
    <MainFooter>
      <div className="container">
        <div className="row">

          <div className="col-md-2 col-sm-12">
            <a className="navbar-brand" href="/"><img src={logoColourOnBlack} alt="Day & Ross"/></a>
          </div>

          <div className="col-md-2 col-sm-12">
            <nav className="nav flex-column footer-column">
              <a className="nav-link small text-white" href="/track-shipments" aria-label={t('Tracking')}>
                <MarginlessP field={t('Tracking')}/>
              </a>
              <a className="nav-link small text-white" href="/About-Us-Our-Story" aria-label={t('AboutUs')}>
                <MarginlessP field={t('AboutUs')}/>
              </a>
              <a className="nav-link small text-white" href="/CareersWorkwithus" aria-label={t('Careers')}>
                <MarginlessP field={t('Careers')}/>
              </a>
              <a className="nav-link small text-white" href="https://www.mccain.com" aria-label={t('McCainFoods')}>
                <MarginlessP field={t('McCainFoods')}/>
              </a>
            </nav>
          </div>

          <div className="col-md-2 col-sm-12">
            <nav className="nav flex-column footer-column">
              <a
                className="nav-link small text-white"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.mccain.com/terms-and-conditions/"
                aria-label={t('TermsAndConditions')}>
                <MarginlessP field={t('TermsAndConditions')}/>
              </a>
              {/* eslint-disable jsx-a11y/click-events-have-key-events */}
              {/* eslint-disable jsx-a11y/no-static-element-interactions */}
              <span className="nav-link small text-white pointer" onClick={() => handleLanguageSwitch('privacy')}>
                    <MarginlessP field={t('PrivacyPolicy')}/>
                  </span>
              <span className="nav-link small text-white pointer" onClick={() => handleLanguageSwitch('accessibility')}>
                    <MarginlessP field={t('Accessibility')}/>
                  </span>
              {/* eslint-enable jsx-a11y/click-events-have-key-events */}
              {/* eslint-enable jsx-a11y/no-static-element-interactions */}
              <div>
                <a
                  className="nav-link small text-white social-media"
                  href="https://www.facebook.com/DayRossInc/">
                  <img src={iconFacebook} alt="facebook"/>
                </a>
                <a
                  className="nav-link small text-white social-media"
                  href="https://www.linkedin.com/company/day-&-ross-general-freight">
                  <img src={iconLinkedIn} alt="linkedin"/>
                </a>
              </div>
            </nav>
          </div>

          <div className="col-md-6 col-sm-12 contact-box">
            <nav className="nav flex-column footer-column">
              <Link className="nav-link small text-white" onClick={() => openChatWindow()}>
                <MarginlessP field={t('ChatWithUs')}/>
                <img src={iconChat} className="contact-icon" alt="chat"/>
              </Link>
              <a href="/contact-us" className="nav-link small text-white">
                <MarginlessP field={t('ContactUS')}/>
                <img src={iconPhone} className="contact-icon" alt="phone"/>
              </a>
              <a href="/submit-feedback" className="nav-link small text-white">
                <MarginlessP field={t('SubmitFeedback')}/>
                <img src={iconEmail} className="contact-icon" alt="email"/>
              </a>
            </nav>
          </div>

        </div>
      </div>
    </MainFooter>
  );
};

Footer.propTypes = {
  openChatWindow: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  openChatWindow: () => dispatch({ type: OPEN_CHAT_WINDOW }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
