import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Placeholder, VisitorIdentification, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Helmet } from 'react-helmet';
import { t } from 'i18next';
import GlobalStyles from './theme/global-styles';
import Footer from './components/ReactComponent/Footer';
import './theme/bootstrap.css';
import ChatWindow from './components/ReactComponent/ChatWindow';
import initGoogleAnalytics from './utils/googleAnalytics';
import SystemError from './components/ReactComponent/Error/SystemError';
import LoadingIndicator from './components/ReactComponent/LoadingIndicator';
import AccountSideMenu from './components/ReactComponent/AccountSideMenu';
import CookiePopup from './components/ReactComponent/CookiePopup';
import AutoLogout from './components/ReactComponent/AutoLogout';
import getLayoutResources from './services/layoutService';
import c from './temp/config';
import utils from './utils/utils';
import { getCurrentLocale } from './utils/func';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's
// removed here.

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const FluidContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

const Layout = ({ route, isFluid, isssr }) => {
  const [_websiteHeaders, setWebsiteHeaders] = useState({});

  useEffect(() => {
    if (!utils.isDisconnected) {
      getLayoutResources({ sitecoreApiKey: c.sitecoreApiKey, locale: getCurrentLocale() }).then((response) => {
        if (response.status === 200 && response.data && response.data.sitecore.context.Metadata && response.data.sitecore.context.Metadata.title) {
          setWebsiteHeaders(response.data.sitecore.context.Metadata);
        }
      });
    }
  }, [getCurrentLocale]);

  initGoogleAnalytics(!isssr && t('googleAnalyticsKey'));
  return (
    <>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat:300,500,600" />
        <title>
          {(_websiteHeaders && _websiteHeaders.title && _websiteHeaders.title) ||
            (route.fields && route.fields.pageTitle && route.fields.pageTitle.value) || 'Page'}
        </title>
        <meta charset={_websiteHeaders && _websiteHeaders.charset ? _websiteHeaders.charset : ''}></meta>
        <meta name="description" content={_websiteHeaders && _websiteHeaders.description ? _websiteHeaders.description : ''}></meta>
        <meta name="viewport" content={_websiteHeaders && _websiteHeaders.viewport ? _websiteHeaders.viewport : ''}></meta>
      </Helmet>
      <GlobalStyles />

      {
        /*
        VisitorIdentification is necessary for Sitecore Analytics to
        determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required
        or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.
        VI detection only runs once for a given analytics ID,
        so this is not a recurring operation once cookies are established.
        */
      }
      <VisitorIdentification />
      <AutoLogout />
      {!isExperienceEditorActive() && <SystemError />}
      <LoadingIndicator />
      <AccountSideMenu />
      {!isssr && <CookiePopup />}
      {/* root placeholder for the app, which we add components to using route data */}
      <Placeholder name="jss-main-header" rendering={route} />

      {isFluid
        ? (
          <FluidContainer>
            <ChatWindow />
            <Placeholder name="jss-main" rendering={route} />
          </FluidContainer>
        )
        : (
          <div className="container">
            <ChatWindow />
            <Placeholder name="jss-main" rendering={route} />
          </div>
        )}

      <Placeholder name="jss-main-footer" rendering={route} />

      <Footer />
    </>
  );
};

export default Layout;
