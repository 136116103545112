import React, {
  cloneElement,
  memo,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {
  AccordionPageContainer,
  AccordionPageContent,
  AccordionPageContentWrapper,
  AccordionPageTitle,
  AccordionTitleShadow,
  Arrow,
} from './css';

// Animation final step precision in pixels
const PRECISION = 2;

const AccordionPage = memo(
  ({
    id,
    title,
    children,
    togglePage,
    speed,
    isActive = false,
    isLocked,
    name,
    scrollIntoViewWhenExpanded
  }) => {
    const contentRef = useRef(null);
    const [currentHeight, setCurrentHeight] = useState(0);

    // animation step
    const step = () => {
      const direction = isActive ? 1 : -1;
      if (!contentRef.current) return;
      const {
        scrollHeight,
        style
      } = contentRef.current;
      let height = currentHeight
        + (direction > 0 ? scrollHeight - currentHeight : -currentHeight) * speed;

      if (direction > 0 && height >= scrollHeight - PRECISION) height = scrollHeight;
      if (direction < 0 && height <= PRECISION) height = 0;
      setCurrentHeight(height);
      style.height = `${height}px`;
    };

    const redraw = () => {
      if (!contentRef.current) return;
      const { style } = contentRef.current;
      style.height = '100%'; // refresh height to content one.
      requestAnimationFrame(step);
    };

    useEffect(() => {
      requestAnimationFrame(step);
    }, [isActive]);

    useEffect(() => {
      const scrollHeight = contentRef.current
        ? contentRef.current.scrollHeight
        : 0;

      const isAccordionExpanded = (currentHeight === scrollHeight);

      if (!isAccordionExpanded || currentHeight !== 0) {
        requestAnimationFrame(step);
      }

      if (scrollIntoViewWhenExpanded && isAccordionExpanded) {
        const element = document.getElementById(name);
        if (element) {
          element.scrollIntoView();
        }
      }
    }, [currentHeight]);

    return (
      <AccordionPageContainer id={name}>
        <AccordionPageTitle
          $isActive={isActive}
          onClick={() => {
            if (isLocked !== true) {
              togglePage(id);
            }
          }}>
          {title}
          <Arrow $isActive={isActive}/>
        </AccordionPageTitle>
        <AccordionTitleShadow/>
        {/* Make content styles configurable at this point by changing AccordionPageContent (add props with default value)) */}
        <AccordionPageContent ref={contentRef}>
          <AccordionPageContentWrapper>
            {cloneElement(children, { redraw })}
          </AccordionPageContentWrapper>
        </AccordionPageContent>
      </AccordionPageContainer>
    );
  }
);

AccordionPage.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  children: PropTypes.node,
  togglePage: PropTypes.func,
  speed: PropTypes.number,
  isActive: PropTypes.bool,
  isLocked: PropTypes.bool,
  name: PropTypes.string,
  scrollIntoViewWhenExpanded: PropTypes.bool,
};

AccordionPage.displayName = 'AccordionPage';

export default AccordionPage;
