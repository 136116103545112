import React, { useState, useEffect } from 'react';
import { Field, FieldArray, change } from 'redux-form';
import { t } from 'i18next';
import Cube from '../../ReactComponent/Cube';
import PlusGrey from '../../../assets/icons/PlusGrey.svg';
import CloseOrange from '../../../assets/icons/Close.svg';
import RenderField from '../../ReactComponent/RenderField';
import TotalWeightBlock from '../../ReactComponent/TotalWeightBlock';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import MeasurementItemCard from '../../ReactComponent/MeasurementItemCard';
import Plus from '../../../assets/icons/Plus.svg';
import {
  maxChar50, maxChar6, isRequired, oneTo999, isValidDigitInput, isValidDescription
} from '../../../utils/validator';
import { digitOnly } from '../../../utils/mask';
import { P } from '../../../theme/typographySitecore';
import {
  AddItemContainer,
  AddItem,
  AddItemLabel,
  PageTitle,
  InputUnit,
  ContentBoxLightGrey,
  FileUploadLabel,
  FileUploadContainer,
  FileUpload,
  ItemContainer,
  ErrorMessage,
} from '../css';
import UnitSelector from '../../ReactComponent/Measurement/Units';
import Tooltip from '../../ReactComponent/Tooltip';
import { FormFieldTooltipContainer } from '../../ReactComponent/Tooltip/helpers';

const renderMeasurementList = ({
  fields,
  measurementType,
  dispatch,
  currentMeasurementList,
  USQuote,
  formName,
  // valid,
  error
}) => {
  const disableAddItem = error.measurementList ? true : false;
  const addItem = (index) => {
    dispatch(change(formName, `measurementList[${index}].completed`, true));

    if (currentMeasurementList[index].itemDangerous) {
      dispatch(change(formName, 'dangerDisable', true));
    }
  };

  const removeItem = (index) => {
    const newlist = [...currentMeasurementList];
    newlist.splice(index, 1);
    if (newlist.length === 0) {
      newlist.push({});
    }

    dispatch(change(formName, 'measurementList', newlist));
  };

  const [state, setState] = useState({
    showAddLineButton: false,
    hideRemoveButton: true
  });

  useEffect(() => {
    const hideRemoveButton = currentMeasurementList.length <= 1;
    const showAddLineButton = !(currentMeasurementList.some((m) => !m.completed));
    setState({
      ...state,
      hideRemoveButton,
      showAddLineButton
    });
  }, [currentMeasurementList]);
  const classTypes = ['500', '400', '300', '250', '200', '175', '150', '125', '110', '100', '92.5', '85', '77.5', '70', '65', '60', '55', '50'];
  const sizeValidators = USQuote ? [maxChar6, isValidDigitInput] : [isRequired, maxChar6, isValidDigitInput];
  return (
    <>
      {fields.map((item, index) => {
        const fieldValue = fields.get(index);
        const {
          completed,
          itemWidth,
          itemHeight,
          itemLength
        } = fieldValue;

        if (completed) {
          return (
            <MeasurementItemCard
              key={index}
              formName={formName}
              measurementList={[fieldValue]}
              dispatch={dispatch}
              measurementType={measurementType.value}
              order={index}
              currentMeasurementList={currentMeasurementList}
              USQuote={USQuote}
              hideDangerousItem
              quote
              hideEdit={!state.showAddLineButton}
            />
          );
        }

        return (
          <ContentBoxLightGrey className="edit-item-box" key={index}>
            {USQuote ?
              <>
                <Row>
                  <Column $tablet={6} $mobile={12}>
                    <Row>
                      <Column $mobile={10}>
                        <Field
                          name={`${item}.itemClass`}
                          component={RenderField}
                          type="dropdown"
                          label={t('item class')}
                          required
                          data={[...classTypes]}
                          validate={[isRequired]}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column $mobile={10}>
                        <Field
                          name={`${item}.itemWeight`}
                          component={RenderField}
                          label={t('TotalWeight')}
                          normalize={digitOnly}
                          required
                          validate={[isRequired, isValidDigitInput, maxChar6]}
                        />
                      </Column>
                      <Column $mobile={2}>
                        <InputUnit>lb</InputUnit>
                      </Column>
                    </Row>
                  </Column>
                  <Column $tablet={6} $mobile={12}>
                    <Row>
                      <Column $mobile={10}>
                        <Field
                          name={`${item}.itemLength`}
                          component={RenderField}
                          label={t('ItemLength')}
                          normalize={digitOnly}
                          required={!USQuote}
                          validate={sizeValidators}
                        />
                      </Column>
                      <Column $mobile={2}>
                        <InputUnit>in</InputUnit>
                      </Column>
                    </Row>
                    <Row>
                      <Column $mobile={10}>
                        <Field
                          name={`${item}.itemWidth`}
                          component={RenderField}
                          label={t('ItemWidth')}
                          normalize={digitOnly}
                          required={!USQuote}
                          validate={sizeValidators}
                        />
                      </Column>
                      <Column $mobile={2}>
                        <InputUnit>in</InputUnit>
                      </Column>
                    </Row>
                    <Row>
                      <Column $mobile={10}>
                        <Field
                          name={`${item}.itemHeight`}
                          component={RenderField}
                          label={t('ItemHeight')}
                          normalize={digitOnly}
                          required={!USQuote}
                          validate={sizeValidators}
                        />
                      </Column>
                      <Column $mobile={2}>
                        <InputUnit>in</InputUnit>
                      </Column>
                    </Row>
                  </Column>
                </Row>
              </> :
              <>
                <Row>
                  <Column $tablet={5} $mobile={10}>
                    <Field
                      name={`${item}.itemCount`}
                      component={RenderField}
                      label={t('TotalPieces')}
                      normalize={digitOnly}
                      required
                      validate={[isRequired, maxChar6, isValidDigitInput, oneTo999]}
                    />
                  </Column>
                  <FormFieldTooltipContainer $tablet={1} $mobile={2}>
                    <Tooltip tip={t('TipTotalPieces')} />
                  </FormFieldTooltipContainer>
                  <Column $tablet={6} $mobile={12}>
                    <Field
                      name={`${item}.itemDescription`}
                      component={RenderField}
                      label={t('Description')}
                      required
                      validate={[isRequired, maxChar50, isValidDescription]}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column $tablet={6} $mobile={12}>
                    <Row>
                      <Column $mobile={10}>
                        <Field
                          name={`${item}.itemWeight`}
                          component={RenderField}
                          label={t('TotalWeight')}
                          normalize={digitOnly}
                          required
                          validate={[isRequired, isValidDigitInput, maxChar6]}
                        />
                      </Column>
                      <Column $mobile={2}>
                        <InputUnit>{measurementType.value === 'Metric' ? 'kg' : t('lbs')}</InputUnit>
                      </Column>
                    </Row>
                    <Row>
                      <Column $mobile={10}>
                        <Field
                          name={`${item}.itemLength`}
                          component={RenderField}
                          label={t('ItemLength')}
                          normalize={digitOnly}
                          required
                          validate={[isRequired, isValidDigitInput, maxChar6]}
                        />
                      </Column>
                      <Column $mobile={2}>
                        <InputUnit>{measurementType.value === 'Metric' ? 'cm' : t('Inches')}</InputUnit>
                      </Column>
                    </Row>
                    <Row>
                      <Column $mobile={10}>
                        <Field
                          name={`${item}.itemWidth`}
                          component={RenderField}
                          label={t('ItemWidth')}
                          normalize={digitOnly}
                          required
                          validate={[isRequired, isValidDigitInput, maxChar6]}
                        />
                      </Column>
                      <Column $mobile={2}>
                        <InputUnit>{measurementType.value === 'Metric' ? 'cm' : t('Inches')}</InputUnit>
                      </Column>
                    </Row>
                    <Row>
                      <Column $mobile={10}>
                        <Field
                          name={`${item}.itemHeight`}
                          component={RenderField}
                          label={t('ItemHeight')}
                          normalize={digitOnly}
                          required
                          validate={[isRequired, isValidDigitInput, maxChar6]}
                        />
                      </Column>
                      <Column $mobile={2}>
                        <InputUnit>{measurementType.value === 'Metric' ? 'cm' : t('Inches')}</InputUnit>
                      </Column>
                    </Row>
                    <Row>
                      <Column $mobile={10}>
                        <Field
                          name={`${item}.itemDangerous`}
                          type="singleCheckbox"
                          label={t('DangerousGoods')}
                          component={RenderField}
                        />
                        <Tooltip tip={t('TipDangerousGoods')} />
                      </Column>
                    </Row>
                    <P field={{ value: t('DangerousGoodsDisclaimer') }} />
                  </Column>
                  <Column $tablet={6} $mobile={12}>
                    <Cube
                      width={itemWidth}
                      height={itemHeight}
                      length={itemLength}
                      measurementType={measurementType.value}
                    />
                  </Column>
                </Row>
              </>}
            <AddItemContainer>
              <AddItem className={state.hideRemoveButton ? 'hide' : ''} onClick={() => removeItem(index)}>
                <AddItemLabel className="active">{t('RemoveItem')}</AddItemLabel>
                <img src={CloseOrange} alt="remove item" />
              </AddItem>
              <AddItem className={disableAddItem ? '' : 'active'} disabled={disableAddItem} onClick={() => addItem(index)}>
                <AddItemLabel>{t('SaveItem')}</AddItemLabel>
                <img src={disableAddItem ? PlusGrey : Plus} alt="add item" />
              </AddItem>
            </AddItemContainer>
            {!disableAddItem && !completed && <Row className="justify-content-end">
              <Column $tablet={4} $mobile={12}>
                <ErrorMessage>
                  {t('itemMustBeSaved')}
                </ErrorMessage>
              </Column>
            </Row>}
          </ContentBoxLightGrey>
        );
      })}
      {state.showAddLineButton && (
        <FileUploadContainer className="with-dot">
          <FileUpload onClick={() => {
            fields.push({});
          }}>
            <img src={Plus} alt="add more part file" />
            <FileUploadLabel>{t('AddAnotherItem')}</FileUploadLabel>
          </FileUpload>
        </FileUploadContainer>
      )}
    </>
  );
};

const Step2 = ({
  formData = {}, step, formName, dispatch, accessorialsList, USQuote, valid, parentFields, error
}) => {
  const { measurementList, measurementType, editingItemIndex } = formData;
  if (step === 1) {
    return (
      <>
        <PageTitle className="justified">
          {parentFields.step2PageTitle && parentFields.step2PageTitle.value}
          <UnitSelector formName={formName} fieldName="measurementType" disabled={USQuote || measurementList.some((m) => m.completed)} moveRight />
        </PageTitle>
        <ItemContainer>
          <FieldArray
            name="measurementList"
            dispatch={dispatch}
            measurementType={measurementType}
            component={renderMeasurementList}
            formName={formName}
            USQuote={USQuote}
            accessorialsList={accessorialsList}
            editingItemIndex={editingItemIndex}
            currentMeasurementList={measurementList}
            valid={valid}
            error={error}
          />
        </ItemContainer>
        <TotalWeightBlock
          measurementList={measurementList}
          measurementType={measurementType.value}
          totalNumber={!USQuote}
        />
      </>
    );
  }

  return null;
};

export default Step2;
