import React, { useState, useEffect } from 'react';
import { Field, FieldArray, change } from 'redux-form';
import { t } from 'i18next';
import Cube from '../../ReactComponent/Cube';
import PlusGrey from '../../../assets/icons/PlusGrey.svg';
import CloseOrange from '../../../assets/icons/Close.svg';
import RenderField from '../../ReactComponent/RenderField';
import TotalWeightBlock from '../../ReactComponent/TotalWeightBlock';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import MeasurementItemCard from '../../ReactComponent/MeasurementItemCard';
import Plus from '../../../assets/icons/Plus.svg';
import {
  maxChar50,
  isRequired,
  maxChar25,
  maxChar4,
  maxChar3,
  maxChar6,
  maxChar10,
  isPhone,
  oneTo999,
  isValidDigitInput,
  isValidDescription
} from '../../../utils/validator';
import { digitOnly, phoneNumber } from '../../../utils/mask';
import { getTotalDensity } from '../../../utils/func';
import {
  AddItemContainer,
  AddItem,
  AddItemLabel,
  PageTitle,
  InputUnit,
  ContentBoxLightGrey,
  CategoryTitle,
  BlockTitle,
  FileUploadLabel,
  FileUploadContainer,
  FileUpload,
  ItemContainer,
  NeqCheckboxContainer,
  ErrorMessage,
} from '../css';
import UnitSelector from '../../ReactComponent/Measurement/Units';
import { P } from '../../../theme/typographySitecore';
import {
  Divisions,
  PackagingGroups,
  SamedayShipmentType,
  PrefillNumberTypes
} from '../../../utils/enums';
import { CheckboxFieldTooltipContainer, FormFieldTooltipContainer } from '../../ReactComponent/Tooltip/helpers';
import Tooltip from '../../ReactComponent/Tooltip';

let isDangerousItemFlag = false;
const renderMeasurementList = ({
  formData,
  loggedIn,
  fields,
  measurementType,
  dispatch,
  currentMeasurementList,
  USShipment,
  formName,
  error
}) => {
  const disableAddItem = error.measurementList ? true : false;

  const addItem = (index) => {
    const currentItem = currentMeasurementList[index];
    dispatch(change(formName, `measurementList[${index}].completed`, true));

    const itemDensity = getTotalDensity(measurementType.value, [currentItem]);
    dispatch(change(formName, `measurementList[${index}].itemDensity`, itemDensity));

    if (currentMeasurementList[index].itemDangerous) {
      dispatch(change(formName, 'dangerDisable', true));
      isDangerousItemFlag = true;
    }
  };

  const removeItem = (index) => {
    const newlist = [...currentMeasurementList];
    newlist.splice(index, 1);
    if (newlist.length === 0) {
      newlist.push({});
    }

    dispatch(change(formName, 'measurementList', newlist));
  };

  const [state, setState] = useState({
    showAddLineButton: false,
    hideRemoveButton: true
  });
  const classTypes = ['500', '400', '300', '250', '200', '175', '150', '125', '110', '100', '92.5', '85', '77.5', '70', '65', '60', '55', '50'];
  useEffect(() => {
    const hideRemoveButton = currentMeasurementList.length <= 1;
    const showAddLineButton = !(currentMeasurementList.some((m) => !m.completed));
    setState({
      ...state,
      hideRemoveButton,
      showAddLineButton
    });
  }, [currentMeasurementList]);
  const quoteOrShipment =
    formData &&
    formData.numberTypeSelected &&
    formData.numberTypeSelected.value;
  const quotePrefilled = (loggedIn && quoteOrShipment === PrefillNumberTypes.quote) ? true : false;

  return (
    <>
      {fields.map((item, index) => {
        const fieldValue = fields.get(index);
        const {
          itemDangerous,
          primeClass,
          completed,
          itemWidth,
          itemHeight,
          itemLength,
        } = fieldValue;

        if (completed) {
          return (
            <MeasurementItemCard
              key={index}
              formName={formName}
              measurementList={[fieldValue]}
              dispatch={dispatch}
              measurementType={measurementType.value}
              order={index}
              currentMeasurementList={currentMeasurementList}
              USShipment={USShipment}
              showDensity
              hideEdit={!state.showAddLineButton}
              isDangerousItemFlag={isDangerousItemFlag}
            />
          );
        }

        return (
          <ContentBoxLightGrey className="edit-item-box" key={index}>
            {USShipment && <Row>
              <Column $tablet={6} $mobile={12}>
                <Row>
                  <Column $mobile={10}>
                    <Field
                      name={`${item}.itemFreightClass`}
                      component={RenderField}
                      type="dropdown"
                      label={t('ItemClass')}
                      required
                      data={[...classTypes]}
                      validate={[isRequired]}
                    />
                  </Column>
                </Row>
              </Column>
              <Column $tablet={6} $mobile={12}>
                <Field
                  name={`${item}.itemNMFC`}
                  component={RenderField}
                  label={t('NmfcCode')}
                  validate={[maxChar50]}
                />
              </Column>
            </Row>}
            <Row>
              <Column $tablet={5} $mobile={10}>
                <Field
                  name={`${item}.itemCount`}
                  component={RenderField}
                  label={t('TotalPieces')}
                  normalize={digitOnly}
                  required
                  validate={[isRequired, isValidDigitInput, maxChar6, oneTo999]}
                  disabled={quotePrefilled}
                />
              </Column>
              <FormFieldTooltipContainer $tablet={1} $mobile={2}>
                <Tooltip tip={t('TipTotalPieces')}/>
              </FormFieldTooltipContainer>
              <Column $tablet={6} $mobile={10}>
                <Field
                  name={`${item}.itemDescription`}
                  component={RenderField}
                  label={t('Description')}
                  required
                  validate={[maxChar50, isRequired, isValidDescription]}
                />
              </Column>
            </Row>
            <Row>
              <Column $tablet={6} $mobile={12}>
                <Row>
                  <Column $mobile={10}>
                    <Field
                      name={`${item}.itemWeight`}
                      component={RenderField}
                      label={t('TotalWeight')}
                      normalize={digitOnly}
                      required
                      validate={[isRequired, maxChar6, isValidDigitInput]}
                      disabled={quotePrefilled}
                    />
                  </Column>
                  <Column $mobile={2}>
                    <InputUnit>{measurementType.value === 'Metric' ? 'kg' : t('lbs')}</InputUnit>
                  </Column>
                </Row>
                <Row>
                  <Column $mobile={10}>
                    <Field
                      name={`${item}.itemLength`}
                      component={RenderField}
                      label={t('ItemLength')}
                      normalize={digitOnly}
                      required
                      validate={[isRequired, maxChar6, isValidDigitInput]}
                      disabled={quotePrefilled}
                    />
                  </Column>
                  <Column $mobile={2}>
                    <InputUnit>{measurementType.value === 'Metric' ? 'cm' : t('Inches')}</InputUnit>
                  </Column>
                </Row>
                <Row>
                  <Column $mobile={10}>
                    <Field
                      name={`${item}.itemWidth`}
                      component={RenderField}
                      label={t('ItemWidth')}
                      normalize={digitOnly}
                      required
                      validate={[isRequired, maxChar6, isValidDigitInput]}
                      disabled={quotePrefilled}
                    />
                  </Column>
                  <Column $mobile={2}>
                    <InputUnit>{measurementType.value === 'Metric' ? 'cm' : t('Inches')}</InputUnit>
                  </Column>
                </Row>
                <Row>
                  <Column $mobile={10}>
                    <Field
                      name={`${item}.itemHeight`}
                      component={RenderField}
                      label={t('ItemHeight')}
                      normalize={digitOnly}
                      required
                      validate={[isRequired, maxChar6, isValidDigitInput]}
                      disabled={quotePrefilled}
                    />
                  </Column>
                  <Column $mobile={2}>
                    <InputUnit>{measurementType.value === 'Metric' ? 'cm' : t('Inches')}</InputUnit>
                  </Column>
                </Row>
                <Row>
                  <Column $mobile={10}>
                    <Field
                      name={`${item}.itemDangerous`}
                      type="singleCheckbox"
                      label={t('DangerousGoods')}
                      component={RenderField}
                    />
                    <Tooltip tip={t('TipDangerousGoods')}/>
                  </Column>
                </Row>
                <Row>
                  <Column style={{ fontSize: '14px' }}>
                    <div style={{
                      padding: '0',
                      paddingBottom: '20px',
                      marginTop: '-25px'
                    }}>{t('DangerousGoodsDisclaimerGuide3')}</div>
                    {t('DangerousGoodsDisclaimerGuide1')}
                    <a
                      href={t('RestrictedArticlesDomesticLink')}
                      rel="noopener noreferrer"
                      style={{ fontWeight: 'bold' }}
                      target="_blank">{t('RestrictedArticlesDomestic')}</a> {t('or')} <a
                    href={t('RestrictedArticlesTransborderLink')}
                    rel="noopener noreferrer"
                    style={{ fontWeight: 'bold' }}
                    target="_blank">{t('RestrictedArticlesTransborder')}</a> {t('DangerousGoodsDisclaimerGuide2')}
                  </Column>
                </Row>
              </Column>
              <Column $tablet={6} $mobile={12}>
                <Cube
                  width={itemWidth}
                  height={itemHeight}
                  length={itemLength}
                  measurementType={measurementType.value}
                />
              </Column>
            </Row>

            {
              itemDangerous && (
                <Row>
                  <hr width="100%"/>
                  <Column $mobile={12}>
                    <BlockTitle>{t('DangerousGoods')}</BlockTitle>
                  </Column>
                  <Column $tablet={7} $mobile={12}>
                    <Row>
                      <Column $tablet={10} $mobile={10}>
                        <Field
                          name={`${item}.unNumber`}
                          component={RenderField}
                          label={t('UNNumber')}
                          normalize={digitOnly}
                          required
                          validate={[maxChar4, isRequired, isValidDigitInput]}
                        />
                      </Column>
                      <FormFieldTooltipContainer $tablet={2} $mobile={2}>
                        <Tooltip tip={t('TipDangerousGoodsUnNumber')}/>
                      </FormFieldTooltipContainer>
                    </Row>
                    <Row>
                      <Column $tablet={10} $mobile={10}>
                        <Field
                          name={`${item}.shippingName`}
                          component={RenderField}
                          label={t('ShippingName')}
                          required
                          validate={[maxChar50, isRequired, isValidDescription]}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column $tablet={10} $mobile={10}>
                        <Field
                          name={`${item}.totalQuantity`}
                          component={RenderField}
                          label={t('TotalQuantity')}
                          normalize={digitOnly}
                          validate={[isRequired, isValidDigitInput]}
                          required
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column $tablet={10} $mobile={10}>
                        <Field
                          name={`${item}.totalQuantityUnits`}
                          component={RenderField}
                          label={t('TotalQuantityUnit')}
                          type="dropdown"
                          data={['KG', 'L']}
                          validate={[isRequired]}
                          required
                        />
                      </Column>
                    </Row>
                    <br/>
                    <Row>
                      <Column $tablet={10} $mobile={10}>
                        <Field
                          name={`${item}.numberPackRequireLabel`}
                          component={RenderField}
                          label={t('NumberOfPackagesRequiringLabel')}
                          normalize={digitOnly}
                          required
                          validate={[maxChar3, isRequired, isValidDigitInput]}
                        />
                      </Column>
                    </Row>
                  </Column>
                  <Column $tablet={5} $mobile={12}>
                    <Row>
                      <Column $tablet={10} $mobile={10}>
                        <Field
                          name={`${item}.primeClass`}
                          component={RenderField}
                          label={t('PrimaryClass')}
                          required
                          validate={[maxChar10, isRequired]}
                        />
                      </Column>
                      <FormFieldTooltipContainer $tablet={2} $mobile={2}>
                        <Tooltip tip={t('TipDangerousGoodsPrimaryClass')}/>
                      </FormFieldTooltipContainer>
                    </Row>
                    <Row>
                      <Column $tablet={10} $mobile={10}>
                        <Field
                          name={`${item}.secondClass`}
                          component={RenderField}
                          label={t('SecondaryClass')}
                          validate={[maxChar10]}
                        />
                      </Column>
                      <FormFieldTooltipContainer $tablet={2} $mobile={2}>
                        <Tooltip tip={t('TipDangerousGoodsSecondaryClass')}/>
                      </FormFieldTooltipContainer>
                    </Row>
                    <Row>
                      <Column $tablet={10} $mobile={10}>
                        <Field
                          name={`${item}.packagingGroup`}
                          type="dropdown"
                          label={t('PackagingGroup')}
                          data={[
                            {
                              name: PackagingGroups.I.name,
                              value: PackagingGroups.I.value
                            },
                            {
                              name: PackagingGroups.II.name,
                              value: PackagingGroups.II.value
                            },
                            {
                              name: PackagingGroups.III.name,
                              value: PackagingGroups.III.value
                            },
                            {
                              name: PackagingGroups.NotApplicable.name,
                              value: PackagingGroups.NotApplicable.value
                            },
                          ]}
                          textField="name"
                          valueField="value"
                          required
                          component={RenderField}
                          validate={[isRequired]}
                        />
                      </Column>
                      <FormFieldTooltipContainer $tablet={2} $mobile={2}>
                        <Tooltip tip={t('TipDangerousGoodsPackagingGroup')}/>
                      </FormFieldTooltipContainer>
                    </Row>
                    <Field
                      name={`${item}.toxic`}
                      type="singleCheckbox"
                      label={t('ToxicByInhalation')}
                      component={RenderField}
                    />
                    {primeClass && primeClass[0] === '1' && (
                      <NeqCheckboxContainer>
                        <Field
                          name={`${item}.neq`}
                          type="singleCheckbox"
                          label={t('NEQ')}
                          component={RenderField}
                        />
                        <CheckboxFieldTooltipContainer>
                          <Tooltip tip={t('TipDangerousGoodsNEQ')}/>
                        </CheckboxFieldTooltipContainer>
                      </NeqCheckboxContainer>
                    )}
                  </Column>
                </Row>
              )
            }
            <AddItemContainer>
              <AddItem className={state.hideRemoveButton ? 'hide' : ''} onClick={() => removeItem(index)}>
                <AddItemLabel className="active">{t('RemoveItem')}</AddItemLabel>
                <img src={CloseOrange} alt="remove item"/>
              </AddItem>
              <AddItem className={disableAddItem ? '' : 'active'} disabled={disableAddItem} onClick={() => addItem(index)}>
                <AddItemLabel>{t('SaveItem')}</AddItemLabel>
                <img src={disableAddItem ? PlusGrey : Plus} alt="add item"/>
              </AddItem>
            </AddItemContainer>
            {!disableAddItem && !completed && <Row className="justify-content-end">
              <Column $tablet={4} $mobile={12}>
                <ErrorMessage>
                  {t('itemMustBeSaved')}
                </ErrorMessage>
              </Column>
            </Row>}
          </ContentBoxLightGrey>
        );
      })}
      {
        state.showAddLineButton && (
          <FileUploadContainer className="with-dot">
            <FileUpload onClick={() => {
              if (quoteOrShipment !== PrefillNumberTypes.quote) {
                fields.push({});
              }
            }}>
              <img src={Plus} alt="add more part file"/>
              <FileUploadLabel>{t('AddAnotherItem')}</FileUploadLabel>
            </FileUpload>
          </FileUploadContainer>
        )
      }
    </>
  );
};

const Step2 = ({
  formData = {},
  loggedIn,
  step,
  formName,
  dispatch,
  accessorialsList,
  valid,
  parentFields,
  error
}) => {
  const {
    measurementList,
    measurementType,
    division,
    editingItemIndex,
    USShipment,
    shipmentType
  } = formData;
  if (step === 1) {
    return (
      <>
        <PageTitle className="justified">
          {parentFields.pageHeadingStep2 && parentFields.pageHeadingStep2.value}
          <UnitSelector formName={formName} fieldName="measurementType" moveRight disabled={measurementList.some((m) => m.completed)}/>
        </PageTitle>
        <ItemContainer>
          <FieldArray
            name="measurementList"
            dispatch={dispatch}
            measurementType={measurementType}
            component={renderMeasurementList}
            formName={formName}
            USShipment={USShipment}
            accessorialsList={accessorialsList}
            editingItemIndex={editingItemIndex}
            currentMeasurementList={measurementList}
            valid={valid}
            error={error}
            formData={formData}
            loggedIn={loggedIn}
          />
        </ItemContainer>
        <TotalWeightBlock
          measurementList={measurementList}
          measurementType={measurementType.value}
          totalNumber
        />
        {
          measurementList.some((item) => item.itemDangerous) && (
            <Row>
              <Column $tablet={5} $mobile={12}>
                <Row>
                  <Column $tablet={10} $mobile={10}>
                    <Field
                      name="emergencyPhone"
                      component={RenderField}
                      label={t('EmergencyNumber')}
                      normalize={phoneNumber}
                      required
                      validate={[maxChar25, isRequired, isPhone]}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column $tablet={10} $mobile={10}>
                    <Field
                      name="erapReference"
                      component={RenderField}
                      label={t('ERAPReferenceNumber')}
                      normalize={digitOnly}
                      validate={[maxChar6]}
                    />
                  </Column>
                  <FormFieldTooltipContainer $tablet={2} $mobile={2}>
                    <Tooltip tip={t('TipDangerousGoodsErapReferenceNumber')}/>
                  </FormFieldTooltipContainer>
                </Row>
                <Row>
                  <Column $tablet={10} $mobile={10}>
                    <Field
                      name="erapPhone"
                      component={RenderField}
                      label={t('ERAPPhoneNumber')}
                      normalize={phoneNumber}
                      validate={[maxChar25, isPhone]}
                    />
                  </Column>
                  <FormFieldTooltipContainer $tablet={2} $mobile={2}>
                    <Tooltip tip={t('TipDangerousGoodsErapPhoneNumber')}/>
                  </FormFieldTooltipContainer>
                </Row>
              </Column>
            </Row>
          )
        }
        <CategoryTitle>
          {t('Additional Information')}
        </CategoryTitle>
        <P field={parentFields.additionalInformationSummary}/>
        <Row>
          <Column $tablet={5} $mobile={12}>
            <Row>
              <Column $tablet={10} $mobile={10}>
                <Field
                  name="referenceNumber"
                  component={RenderField}
                  label={t('ReferenceNumber')}
                  required={division === Divisions.Sameday.name && shipmentType !== SamedayShipmentType.Regular}
                  validate={division === Divisions.Sameday.name && shipmentType !== SamedayShipmentType.Regular ?
                    [isRequired, maxChar50] : [maxChar50]}
                />
              </Column>
              <FormFieldTooltipContainer $tablet={2} $mobile={2}>
                <Tooltip tip={t('TipCreateShipmentReferenceNumber')}/>
              </FormFieldTooltipContainer>
            </Row>
            <Row>
              <Column $tablet={10} $mobile={10}>
                <Field
                  name="poNumber"
                  component={RenderField}
                  label={t('PurchaseOrderNumber')}
                  validate={[maxChar50]}
                />
              </Column>
              <FormFieldTooltipContainer $tablet={2} $mobile={2}>
                <Tooltip tip={t('TipCreateShipmentPurchaseOrderNumber')}/>
              </FormFieldTooltipContainer>
            </Row>
          </Column>
        </Row>
      </>
    );
  }

  return null;
};

export default Step2;
