import { t } from 'i18next';
import { QuoteStatuses } from '../../utils/enums';

const mapStatusListToValueObjects = (statusList) => statusList.map((status) => {
  switch (status) {
    case QuoteStatuses.Expired:
      return {
        name: t('Expired'),
        value: status
      };
    case QuoteStatuses.Finalized:
      return {
        name: t('Finalized'),
        value: status
      };
    case QuoteStatuses.UnderReview:
      return {
        name: t('UnderReview'),
        value: status
      };
    case QuoteStatuses.USQuote:
      return {
        name: t('US Quote'),
        value: status
      };
    default:
      return {
        name: status,
        value: status
      };
  }
});
export default mapStatusListToValueObjects;
