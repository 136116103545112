import React from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { colors, MediaQuery } from '../../../theme/style-helpers';
import ArrowRightOrange from '../../../assets/icons/ArrowRightOrange.svg';
import ArrowLeftOrange from '../../../assets/icons/ArrowLeftOrange.svg';
import Actions from '../../../assets/icons/Actions.svg';

const SecondaryButtonStyledComponent = styled.button`
    background-color: ${colors.WHITE};
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    ${({ noPadding }) => !noPadding && css`padding: 16px 56px;`}
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    width: 100%;
    height: 52px;
    cursor: pointer;

    ${MediaQuery.large`
        width: 300px;
        
        ${({ $autoWidth }) => $autoWidth && css`width: auto;`}
        
        &.mediumWidth {
          width: 225px;
        }
        ${({ smallWidth }) => smallWidth && css`
        width: 180px;
      `}
    `}

    ${MediaQuery.small`
    ${({ $smallerWidth }) => $smallerWidth && css`
    width: 100%;
    margin-top: 10px;
  `} 
    `}
    ${MediaQuery.xSmall`
    ${({ $smallerWidth }) => $smallerWidth && css`
      width: 100%;
      margin-top: 10px;
    `}
    `}
    ${MediaQuery.large`
  
    ${({ $smallerWidth }) => $smallerWidth && css`
    width: 152px;
    margin-top: 0;
  `} 
    ${({ rightButtonMargin }) => rightButtonMargin && css`
      margin-left: 16px;
    `}
    `}

    .icon {
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;
        background-size: cover;
        ${({ iconSrc }) => (iconSrc ? `background: url(${iconSrc});` : '')}
        &.topLeftIcon {
            left: 17px;
            right: auto;
        }
    }

    .icon-left-arrow {
        background: url(${ArrowLeftOrange});
    }

    .icon-right-arrow {
        background: url(${ArrowRightOrange});
    }

    .icon-actions {
        background: url(${Actions});
    }

    &.no-border {
        border: none;
    }

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    &.hidden {
        display: none;
    }
`;

const SecondaryButton = ({
  iconSrc,
  iconLeftArrow,
  iconRightArrow,
  iconActions,
  mediumWidth,
  smallerWidth,
  ...rest
}) => {
  const {
    children,
    className
  } = rest;
  const classNames = classnames(className, { mediumWidth });

  return (
    <SecondaryButtonStyledComponent {...rest} $smallerWidth={smallerWidth} iconSrc={iconSrc} className={classNames} >
      {children}

      { /* --- Icons --- */}
      {iconSrc && <span className="icon" />}
      {iconLeftArrow && <span className="icon icon-left-arrow" />}
      {iconRightArrow && <span className="icon icon-right-arrow" />}
      {iconActions && <span className="icon icon-actions" />}
    </SecondaryButtonStyledComponent>
  );
};

export default SecondaryButton;
