import {
  PUT_REDUX_QUOTE_LIST,
  PUT_REDUX_SHIPMENT_LIST,
  PUT_REDUX_SHIPMENT_HISTORY_LIST,
  PUT_REDUX_DRAFTS_LIST,
  PUT_REDUX_COMPANIES_LIST,
  PUT_REDUX_SERVICE_LEVEL_QUOTE,
  PUT_REDUX_SERVICE_LEVEL_CODE,
  PUT_REDUX_ALL_RECENT_INVOICES,
  PUT_REDUX_ALL_HISTORICAL_INVOICES,
  PUT_REDUX_INVOICE,
  PUT_REDUX_PERMISSION_LIST,
  PUT_REDUX_USER_DETAILS,
  PUT_REDUX_CONTACT_LIST,
} from './constants';
import { Divisions } from '../utils/enums';

const initialState = {
  division: Divisions.Freight.name,
  accountNumbers: [
    '135024',
    '010420'
  ],
  allQuotes: [],
  allShipments: [],
  allShipmentsHistory: [],
  allDrafts: [],
  allCompanies: [],
  allContacts: [],
  allRecentInvoices: [],
  allHistoricalInvoices: [],
  serviceLevelQuotes: [],
  permissionList: [],
  serviceLevelCode: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_REDUX_QUOTE_LIST:
      return {
        ...state,
        allQuotes: action.allQuotes
      };
    case PUT_REDUX_SHIPMENT_LIST:
      return {
        ...state,
        allShipments: action.allShipments
      };
    case PUT_REDUX_SHIPMENT_HISTORY_LIST:
      return {
        ...state,
        allShipmentsHistory: action.allShipmentsHistory
      };
    case PUT_REDUX_DRAFTS_LIST:
      return {
        ...state,
        allDrafts: action.allDrafts
      };
    case PUT_REDUX_COMPANIES_LIST:
      return {
        ...state,
        allCompanies: action.allCompanies
      };
    case PUT_REDUX_CONTACT_LIST:
      return {
        ...state,
        allContacts: action.contactsList
      };
    case PUT_REDUX_SERVICE_LEVEL_QUOTE:
      return {
        ...state,
        serviceLevelQuotes: action.serviceLevelQuotes
      };
    case PUT_REDUX_SERVICE_LEVEL_CODE:
      return {
        ...state,
        serviceLevelCode: action.serviceLevelCode
      };
    case PUT_REDUX_ALL_RECENT_INVOICES:
      return {
        ...state,
        invoices: action.allRecentInvoices
      };
    case PUT_REDUX_ALL_HISTORICAL_INVOICES:
      return {
        ...state,
        historicalInvoices: action.allHistoricalInvoices
      };
    case PUT_REDUX_INVOICE:
      return {
        ...state,
        singleInvoice: action.invoice
      };
    case PUT_REDUX_PERMISSION_LIST:
      return {
        ...state,
        permissionList: action.permissionList
      };
    case PUT_REDUX_USER_DETAILS:
      return {
        ...state,
        userDetails: action.userDetails
      };
    default:
      return state;
  }
};

export default userReducer;
