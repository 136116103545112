import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import AlertModal from '../../ReactComponent/Modal/AlertModal';
import { P } from '../../../theme/typographySitecore';
import PrimaryButton, { styleTypes } from '../../ReactComponent/PrimaryButton';
import CancelButton from './css';

const ResetFormModal = ({
  isOpen, onClose, parentFields, onReset,
}) => {
  const header = () => (
    <>{parentFields.resetFormModalHeader.value}</>
  );

  const content = () => (
    <P field={parentFields.resetFormModalBody}/>
  );

  const actions = () => (
    <>
      <CancelButton
        type="button"
        smallWidth
        iconRightArrow
        styleType={styleTypes.SECONDARY}
        onClick={onClose}>
        {t('Cancel')}
      </CancelButton>

      <PrimaryButton
        type="button"
        smallWidth
        iconRightArrow
        onClick={() => { onReset(); onClose(); }}>
        {t('Reset')}
      </PrimaryButton>
    </>
  );

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      content={content}
      actions={actions}
    />
  );
};

ResetFormModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  parentFields: PropTypes.shape({
    resetFormModalHeader: PropTypes.shape({
      value: PropTypes.string,
    }),
    resetFormModalBody: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  onReset: PropTypes.func,
};
export default ResetFormModal;
