import { t } from 'i18next';
import { validnumberregex } from './constants';

const isTrackNumberValid = (v) => v.match(/^[a-z0-9\s]+$/gi);
const isTrackNumberInvalid = (v) => !isTrackNumberValid(v);
const isReferenceNumberValidEx = (v) => v.match(/^[a-z0-9\s\-\\/]+$/gi);

const MAX_TRACKING_NUMBERS = 19;

export const isReferenceNumberValid = (value) => {
  if (isReferenceNumberValidEx(value)) {
    return undefined;
  }

  return t('TrackShipment_CheckForTypos');
};

export const isZipPostalCodeValid = (value) => {
  if (!value) {
    return undefined;
  }

  if (isTrackNumberValid(value)) {
    return undefined;
  }

  return t('InvalidPostalOrZipCode');
};

export const validateForm = (values) => {
  const errors = {};
  if (values.shipmentNumbers) {
    const numbers = values.shipmentNumbers.split(validnumberregex).map((num) => (num ? num.trim() : undefined));
    const invalidnumbers = numbers.filter((n) => n && isTrackNumberInvalid(n));
    if (invalidnumbers.length > 0) {
      errors.shipmentNumbers = t('TrackShipment_CheckForTypos');
    }

    if (numbers.length === 0 || numbers.length > MAX_TRACKING_NUMBERS) {
      errors.shipmentNumbers = t('TrackShipment_ShipmentNumbersNotInRange', { num: MAX_TRACKING_NUMBERS });
    }
  } else if (values.orderNumbers) {
    const numbers = values.orderNumbers.split(validnumberregex);

    const invalidnumbers = numbers.filter(isTrackNumberInvalid);

    if (invalidnumbers.length > 0) {
      errors.orderNumbers = t('TrackShipment_CheckForTypos');
    }

    if (numbers.length === 0 || numbers.length > 20) {
      errors.orderNumbers = t('TrackShipment_OrderNumbersNotInRange');
    }
  }

  return errors;
};
