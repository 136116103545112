import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { H2, P } from '../../../theme/typography';
import { BannerSection, PrimaryButton, SecondaryButton } from './css';
import { routes } from '../../../utils/constants';

const SignupBanner = ({ loggedIn }) => {
  const onCreateAccount = () => {
    window.location.href = routes.createAccount;
  };

  if (!loggedIn) {
    return (
      <BannerSection>
        <div className="row">
          <div className="col-md-8">
            <H2 className="white">{t('ExistingWebAccount')}</H2>
            <P className="white signup__copy">{t('LoginPrompt')}</P>
            <P className="white signup__copy">{t('SignupPrompt')}</P>
          </div>
          <div className="col-md-4 text-right">
            <PrimaryButton className="active min-width" onClick={() => { window.location.href = routes.login; }}>
              {t('LogIn')}
              {' '}
              <span className="icon"></span>
            </PrimaryButton>
            <SecondaryButton className="active min-width" onClick={onCreateAccount}>
              {t('SignUp')}
              {' '}
              <span className="icon-right"></span>
            </SecondaryButton>
          </div>
        </div>
      </BannerSection>
    );
  }

  return null;
};

SignupBanner.propTypes = {
  loggedIn: PropTypes.bool
};

export default SignupBanner;
