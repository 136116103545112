import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { ActionButton } from '../Modal/css';
import AlertModal from '../Modal/AlertModal';
import { P } from '../../../theme/typographySitecore';
import { CheckmarkIcon, HeaderContainer } from './css';

const SuccessModal = ({ isOpen, onClose, parentFields }) => {
  const header = () => (
    <HeaderContainer>
      <CheckmarkIcon/>
      {parentFields.successModalHeader.value}
    </HeaderContainer>
  );

  const content = () => (
    <P field={parentFields.successModalBody}/>
  );

  const actions = () => (
    <ActionButton className="active" iconRightArrow onClick={onClose}>
      {t('Okay')}
    </ActionButton>
  );

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      content={content}
      actions={actions}
    />
  );
};

SuccessModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  parentFields: PropTypes.shape({
    successModalHeader: PropTypes.shape({
      value: PropTypes.string
    }),
    successModalBody: PropTypes.object
  })
};
export default SuccessModal;
