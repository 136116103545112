import {
  ApolloClient, InMemoryCache, createHttpLink, gql,
} from '@apollo/client';
import utils from '../../utils/utils';
import packageJson from '../../../package.json';
import config from '../../temp/config';
import { endpoints } from '../../utils/constants';

export const formName = 'NewsSearch';
export const PageSize = 12;

const link = createHttpLink({
  uri: utils.isDisconnected
    ? 'http://internet.dev.local/api/internet?sc_apikey={43A6A824-9E28-4AD8-AB16-9550F0094466}'
    : `${endpoints.sitecore.graphService}?sc_apikey=${config.sitecoreApiKey}`,
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions,
});

/* eslint-disable max-len */
export const SEARCH_QUERY = (sort) => gql`
    query searchItems($keyword:String, $skip:String) {    
    drsearch(fieldsEqual: [{name: "_templatename", value: "StaticPage"}, {name: "newsContent", value: "1"}], keyword: $keyword, after:$skip, first:${PageSize},mediaRootItem:"/sitecore/media library/${packageJson.config.appName}/data/media/files",rootItem: "/sitecore/content/${packageJson.config.appName}/home", sort: [{name: "smallupdateddate_2", value: "${sort}"}]){
        results {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          items{
            uri
            name
            updateddate:field(name:"smallupdateddate_2")
            item{
              url                
              ...on StaticPage{                
                thumbnailImage{
                  jss
                }
                heading{
                  value
                }
                description{
                  value
                }
              }
            }
          }
        }        
      }
}
`;
/* eslint-enable max-len */

export const Stages = {
  Loading: 'Loading',
  DataLoaded: 'DataLoaded',
  NoResults: 'NoResults',
  Error: 'Error',
};

export const SortOrder = {
  Ascending: 'asc',
  Descending: 'desc',
};
