import { phoneNumber } from './mask';
import { PaymentMethods, PackagingGroups } from './enums';
import { list as unitsList } from '../components/ReactComponent/Measurement/Units/utils';

const formApiToData = (data) => {
  const {
    accessorials,
    accList,
    division,
    paymentType,
    serviceType,
    from,
    to,
    thirdParty,
    items,
    measurementType,
    charges,
    quoteNumber,
    expiryDate,
    createDate,
    totalAmount,
    billToAccountNumber,
    serviceLevelCode,
    serviceLevelDescription,
    isStraightTruck,
    status,
    quoteEmail,
    emailAddress,
    referenceNumber,
    poNumber,
    specialInstructions,
    shipmentType,
    shipmentId,
    probillNumber,
    emergencyPhoneNumber,
    erapPhoneNumber,
    erapReferenceNumber,
    quoteType,
  } = data;
  const shipperAddress = `${from.address1}, ${from.city}, ${from.provinceCode} ${from.postalCode}, ${from.countryCode}`;
  const consigneeAddress = `${to.address1}, ${to.city}, ${to.provinceCode} ${to.postalCode}, ${to.countryCode}`;
  const billingAddress = paymentType === 'ThirdParty'
  && thirdParty ?
    `${thirdParty.address1}, ${thirdParty.city}, ${thirdParty.provinceCode} ${thirdParty.postalCode}, ${thirdParty.countryCode}`
    : '';
  const measurementList = items.map((each) => ({
    itemWeight: each.weight,
    itemLength: each.length,
    itemWidth: each.width,
    itemHeight: each.height,
    itemCount: each.pieces,
    itemDescription: each.description,
    itemWeightUnit: each.weightUnitType,
    itemLengthUnitType: each.lengthUnitType,
    itemNMFC: each.nmfc,
    itemClass: each.freightClass,
    completed: true,
    itemDangerous: each.isDangerousGoods,
    unNumber: each.unNumber,
    primeClass: each.primaryClass,
    secondClass: each.subsidaryClass,
    packagingGroup: each.packagingGroup ? PackagingGroups[each.packagingGroup] : PackagingGroups.NotApplicable,
    toxic: each.isToxicByInhalation,
    totalQuantity: each.quantity,
    totalQuantityUnits: each.quantityType,
    shippingName: each.shippingName,
    numberPackRequireLabel: each.numOfPackagesRequiringLabels,
    neq: each.isNEQ,
    itemFreightClass: each.freightClass
  }));
  const accCodesList = accessorials.map((a) => a.code.trim());
  const accessorialsList = accList ? accList
    .filter((each) => accCodesList.includes(each.code))
    .map((each) => {
      const accessorialFromBackend = accessorials.find((a) => a.code === each.code);
      return {
        [each.code]: {
          selected: ['',
            {
              name: each.description,
              code: each.code,
              accessorialId: each.accessorialId
            }],
          value: accessorialFromBackend.amount && accessorialFromBackend.amount.value,
          currency: accessorialFromBackend.amount && accessorialFromBackend.amount.value && quoteType === 'Domestic Quote' ? 'CAD' : ''
        }
      };
    })
    .reduce((curr, accum) => ({ ...accum, ...curr }), {}) : '';
  return {
    accessorials: accessorialsList,
    consigneeCompanyName: to.companyName,
    consigneeContactName: to.contactName,
    consigneeAddress,
    consigneeAddressAdditional: to.address2,
    consigneeEmail: to.contactEmail,
    consigneePhoneNumber: to.contactPhoneNumber && phoneNumber(to.contactPhoneNumber),
    consigneePhoneNumberExt: to.contactPhoneNumberExt,
    division,
    paymentMethod: paymentType === 'ThirdParty' ? PaymentMethods.ThirdParty : paymentType,
    serviceType,
    serviceLevelCode,
    serviceLevelDescription,
    isStraightTruck,
    shipperCompanyName: from.companyName,
    shipperContactName: from.contactName,
    shipperAddress,
    shipperAddressAdditional: from.address2,
    shipperEmail: from.contactEmail,
    shipperPhoneNumber: from.contactPhoneNumber && phoneNumber(from.contactPhoneNumber),
    shipperPhoneNumberExt: from.contactPhoneNumberExt,
    billingCompanyName: thirdParty && thirdParty.companyName,
    billingContactName: thirdParty && thirdParty.contactName,
    billingAddress,
    billingAddressAdditional: thirdParty && thirdParty.address2,
    billingEmail: thirdParty && thirdParty.contactEmail,
    billingPhoneNumber: thirdParty && thirdParty.contactPhoneNumber && phoneNumber(thirdParty.contactPhoneNumber),
    billingPhoneNumberExt: thirdParty && thirdParty.contactPhoneNumberExt,
    measurementList,
    measurementType: unitsList.find((mt) => mt.value === measurementType),
    charges,
    billTo: billToAccountNumber,
    quoteNumber,
    expiryDate,
    status,
    created: createDate,
    quoteDest: quoteEmail || emailAddress,
    totalAmount: totalAmount && totalAmount.value ? totalAmount.value : 0,
    referenceNumber,
    poNumber,
    specialInstructions,
    shipmentType,
    shipmentId,
    probillNumber,
    emergencyPhone: emergencyPhoneNumber && phoneNumber(emergencyPhoneNumber),
    erapPhone: erapPhoneNumber && phoneNumber(erapPhoneNumber),
    erapReference: erapReferenceNumber,
    quoteType
  };
};
export default formApiToData;
