import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../utils/constants';

export const getPickUps = ({
  accountNumbers,
  fromDate,
  toDate,
  numberOfDaysFromToday,
  orderNumbers,
}) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.pickups}`,
    {
      headers: {
        accountNumbers,
      },
      params: {
        fromDate,
        toDate,
        numberOfDaysFromToday,
        orderNumbers,
      }
    });

export const getPickupBol = ({
  division,
  orderNumber
}) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.pickups}/${division}/${orderNumber}/bol`);

export const postPickUp = (requestBody) =>
  axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.pickups}`, requestBody);

export const apiGetPickups = (division, accountNumbers, fromDate, toDate) =>
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.pickups}`, {
    params: {
      fromDate,
      toDate,
      division,
    },
    headers: {
      accountNumbers,
    }
  });

