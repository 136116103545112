import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, change, stopAsyncValidation } from 'redux-form';
import { t } from 'i18next';
import RenderField from '../RenderField';
import { addressValidate, isRequired, maxChar100 } from '../../../utils/validator';
import zipPlus4ToZip from './helpers';
import Tooltip from '../Tooltip';
import { AddressDiv } from './css';

const loadScriptAsync = function loadScriptAsync(uri, elementID) {
  return new Promise((resolve) => {
    const tag = document.createElement('script');
    tag.id = elementID;
    tag.src = uri;
    tag.async = true;
    tag.onload = () => {
      resolve();
    };
    tag.onerror = () => {
      document.getElementById(elementID).parentNode.removeChild(tag);
      loadScriptAsync(uri, elementID);
    };
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  });
};

const loadStyleSheetAsync = function loadStyleSheetAsync(href, elementID) {
  return new Promise((resolve) => {
    const tag = document.createElement('link');
    tag.id = elementID;
    tag.rel = 'styleSheet';
    tag.href = href;
    tag.type = 'text/css';
    tag.async = true;
    tag.onload = () => {
      resolve();
    };
    tag.onerror = () => {
      document.getElementById(elementID).parentNode.removeChild(tag);
      loadStyleSheetAsync(href, elementID);
    };
    const firstLinkTag = document.getElementsByTagName('link')[0];
    firstLinkTag.parentNode.insertBefore(tag, firstLinkTag);
  });
};

class AddressComplete extends PureComponent {
  addressApiInit = async () => {
    if (!global.CPscriptLoaded) {
      global.CPscriptLoaded = loadScriptAsync('https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js', 'CPScript');
    }
    global.CPscriptLoaded.then(() => {
      if (!global.CPstyleLoaded) {
        global.CPstyleLoaded = loadStyleSheetAsync('https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css', 'CPStyle');
      }
      return global.CPstyleLoaded;
    }).then(() => {
      const {
        dispatch,
        formName,
        fieldName
      } = this.props;

      const shipperAddressFields = [
        {
          element: fieldName,
          field: '',
          mode: global.pca.fieldMode.SEARCH
        }
      ];
      const options = {
        key: t('CanadaPostAddressCompleteApiKey'),
        countries: { codesList: 'CAN, USA' }
      };

      // Decorate field whose "name" and / or "id" matches shipperAddressFields elements' "element" attribute...
      const addressElement = new global.pca.Address(shipperAddressFields, options);

      // addressElement.onChange() =>
      addressElement.listen('populate', async (address) => {
        // Formatting pieces that will make up the field value, given country, ...
        let postalCode;
        let countryName;
        if (address.CountryIso2 === 'US') {
          postalCode = zipPlus4ToZip(address.PostalCode);
          countryName = address.CountryIso3;
        } else {
          postalCode = address.PostalCode;
          countryName = address.CountryName;
        }

        // Format field value...
        const formattedAddress = `${address.Line1}, ${address.City}, ${address.ProvinceCode} ${postalCode}, ${countryName}`;

        // Update redux form field value...
        dispatch(change(formName, fieldName, formattedAddress));
        const { validator } = this.props;
        if (validator) {
          try {
            await validator();
          } catch (e) {
            dispatch(stopAsyncValidation(formName, e));
          }
        }
      });
    }).catch((err) =>
      console.error(err));
  };

  componentDidMount() {
    this.addressApiInit();
  }

  render() {
    const {
      validateData,
      tooltipRequired,
      shipmentsFlow,
      userFlow,
      fieldName,
      label,
      required,
      showErrorAlways
    } = this.props;
    const isOptionalField = validateData ? [] : [isRequired, maxChar100, addressValidate];
    const finalAddress = tooltipRequired ? (
      <>
        <Tooltip tip={t('TipAddressDetails')} marginLeft shipmentsFlow={shipmentsFlow} userFlow={userFlow}/>
        <AddressDiv>
          <Field
            name={fieldName}
            component={RenderField}
            id={fieldName}
            label={label || t('Address')}
            validate={isOptionalField} // onChange validation...
            required={required}
            showErrorAlways={showErrorAlways}

          />
        </AddressDiv>
      </>
    ) : (
      <Field
        name={fieldName}
        component={RenderField}
        id={fieldName}
        label={label || t('Address')}
        validate={isOptionalField} // onChange validation...
        required={required}
        showErrorAlways={showErrorAlways}

      />
    );
    return (
      <>
        {finalAddress}
      </>
    );
  }
}

AddressComplete.propTypes = {
  dispatch: PropTypes.func,
  formName: PropTypes.string,
  fieldName: PropTypes.string,
  validator: PropTypes.func,
  validateData: PropTypes.bool,
  tooltipRequired: PropTypes.bool,
  shipmentsFlow: PropTypes.bool,
  userFlow: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  showErrorAlways: PropTypes.bool,
};

export default connect()(AddressComplete);
