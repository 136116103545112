import styled from 'styled-components';
import { H2, P } from '../../../../theme/typographySitecore';
import LoggedIn from '../../../../assets/icons/LoggedIn.svg';
import PrimaryButton, { styleTypes } from '../../PrimaryButton';

export const CardContainer = styled.div`
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HeaderTitle = styled(H2)`
    font-size: 20px;
`;

export const HeaderSummary = styled(P)`
    margin-bottom: 0;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContentUserEmail = styled(P)`
    overflow-wrap: break-word;
    word-break: break-word;
`;

export const LoggedInIcon = styled.img.attrs(() => ({ src: LoggedIn }))`
    height: 35px;
    width: 35px;
    margin: 16px 0;
`;

export const SwitchAccountsButton = styled(PrimaryButton).attrs(() => ({
  styleType: styleTypes.SECONDARY,
  autoWidth: true
}))`
    margin: 16px 0;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 100%;
    }
`;
