import styled from 'styled-components';
import CheckmarkCircledOrange from '../../../assets/icons/CheckmarkCircledOrange.svg';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;    
  align-items: center;
`;

export const CheckmarkIcon = styled.img.attrs(() => ({ src: CheckmarkCircledOrange }))`
  width: 42px;
  height: 42px;
  margin-bottom: 32px;
`;
