import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import ArrowRightOrange from '../../../assets/icons/ArrowRightOrange.svg';
import CheckMarkSolidOrange from '../../../assets/icons/CheckMarkSolidOrange.svg';
import { P, H2, H3 } from '../../../theme/typography';
import {
  CardBox, ContentContainer, LargeThumb, PrimaryButtonStyledComponent
} from './css';
import { styleTypes as StyleTypes } from '../PrimaryButton';

const renderThumb = (thumb, title) => (
<img className="image" src={thumb} alt={title} />
);

const renderLgThumb = (thumb, showGradient) => (
    <LargeThumb $thumb={thumb} $showGradient={showGradient}>
      <div className="largeThumbOverlay" />
    </LargeThumb>
);

const renderSelectedButton = (isSelected, clickHandler) => (
    <PrimaryButtonStyledComponent
      styleType={StyleTypes.SECONDARY}
      type="button"
      onClick={clickHandler}
      smallWidth
      iconSrc={isSelected ? CheckMarkSolidOrange : ArrowRightOrange}
    >
      {t('Select')}
    </PrimaryButtonStyledComponent>
);

const Card = ({
  thumb,
  lgThumb,
  title,
  desc,
  children,
  link,
  linkText,
  linkToNewTab,
  active,
  callOut,
  noHover,
  selectedButton,
  selectedButtonIsSelected,
  selectedButtonClickHandler
}) => {
  const target = linkToNewTab ? { target: '_blank' } : {};

  return (
    <CardBox className={`text-center ${active ? 'active' : ''} ${noHover ? 'static' : ''} ${lgThumb ? 'lgThumb' : ''}`}>

      {thumb && !lgThumb && renderThumb(thumb, title)}
      {thumb && lgThumb && renderLgThumb(thumb, selectedButtonIsSelected)}

      <ContentContainer>

        <H2>{title}</H2>
        {desc && <P>{desc}</P>}

        {children}

        {selectedButton && renderSelectedButton(selectedButtonIsSelected, selectedButtonClickHandler)}

        {link && linkText && (
          <Link to={link} {...target} className="link">{linkText} <img src={ArrowRightOrange} alt={linkText} /></Link>
        )}
        {callOut && (
          <H3>{callOut}</H3>
        )}

      </ContentContainer>
    </CardBox>
  );
};

export default Card;
