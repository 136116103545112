import { css } from 'styled-components';

export const colors = {
  BRAND_ORANGE: '#FF6319',
  LIGHT_ORANGE: '#FF864C',
  ORANGE: '#FF5200',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  COOL_GREY: '#D9D9D6',
  DARK_GREY: '#63666A',
  LIGHT_GREY: '#F9F9F9',
  LAND_GREEN: '#4B9560',
  SUN_YELLOW: '#EAAA00',
  SEA_BLUE: '#667F91',
  SKY_GREY: '#ACC8CA',
  DISABLED: '#EEEEEE',
  DISABLED2: '#CCC',
  ERROR: '#CE0505',
  PURPLE: '#B07DF1',
  BLUE: '#7DB2F1',
  TEAL: '#7DF1C7',
  YELLOW: '#F1CA7D',
  RED: '#F17D7D',
  RED_ERROR: '#CE0505',
  GREEN: '#D3F17D',

  OPACITY_00_ALPHA_HEX_SUFFIX: '00', // 0% opacity, fully transparent
  OPACITY_50_ALPHA_HEX_SUFFIX: '80', // 50% opacity
  OPACITY_70_ALPHA_HEX_SUFFIX: 'B3', // 70% opacity
};

const baseGutter = 10;
export const gutter = {
  small: `${baseGutter / 2}px`,
  base: `${baseGutter}px`,
  medium: `${baseGutter * 1.5}px`,
  large: `${baseGutter * 2}px`,
  xLarge: `${baseGutter * 3}px`,
  xxLarge: `${baseGutter * 6}px`,
};

export const zIndexes = {
  FOOTER: 200,
  ADDRESS_DROPDOWN: 210,
  CAMERA_CLICK_FOOTER: 250,
  HEADER: 290,
  MODAL: 1050,
};

export const breakPoints = {
  MOBILE: 766,
  TABLET: 990,
  IPHONE5: 320,
};

export const styleHelpers = {
  CARD_BORDER_RADIUS: 0,
  mobile: (...args) => css`
    @media only screen and (max-width: ${breakPoints.MOBILE}px) {
      ${css(...args)}
    }
  `,
  iphone5: (...args) => css`
    @media only screen and (max-width: ${breakPoints.MOBILE}px) {
      ${css(...args)}
    }
  `,
  notMobile: (...args) => css`
    @media only screen and (min-width: ${breakPoints.MOBILE + 1}px) {
      ${css(...args)}
    }
  `,
  upToTablet: (...args) => css`
    @media only screen and (max-width: ${breakPoints.TABLET}px) {
      ${css(...args)}
    }
  `,
};

const BREAKPOINTS = {
  xSmall: '320px',
  small: '576px',
  medium: '768px',
  large: '992px',
  xLarge: '1204px',
};

/* using no media query will target screens under 758px, styles cascade up */
export const MediaQuery = {
  /* targets screens that are at most 320px wide */
  xSmall: (...args) => css`
    @media only screen and (max-width: ${BREAKPOINTS.small}) {
      ${css(...args)}
    }
  `,
  /* targets screens that are at least 576px wide */
  small: (...args) => css`
    @media only screen and (min-width: ${BREAKPOINTS.small}) {
      ${css(...args)}
    }
  `,
  /* targets screens that are at least 768px wide */
  medium: (...args) => css`
    @media only screen and (min-width: ${BREAKPOINTS.medium}) {
      ${css(...args)}
    }
  `,
  /* targets screens that are at least 992px wide */
  large: (...args) => css`
    @media only screen and (min-width: ${BREAKPOINTS.large}) {
      ${css(...args)}
    }
  `,
  /* targets screens that are at least 1200px wide */
  xLarge: (...args) => css`
    @media only screen and (min-width: ${BREAKPOINTS.xLarge}) {
      ${css(...args)}
    }
  `,
};
