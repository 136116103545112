import React from 'react';
import PropTypes from 'prop-types';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import styled, { css } from 'styled-components';
import { MediaQuery, colors } from './style-helpers';

const sitecoreFieldTypes = {
  Text: 'Text',
  RichText: 'RichText',
};

/**
 * Filters unknown props for a Text component (as it passes them to DOM objects which causes a warning)
 * https://www.styled-components.com/docs/faqs#why-am-i-getting-html-attribute-warnings
 *
 * Can be used as with sitecore or dictionary
 *
 * Ex:
 * <P field={myField} />
 * <P field={t('myField')} />
 */
const TextFilter = ({
  field, tag: Tag, className, fieldType /* = sitecoreFieldTypes.Text */,
}) => {
  if (!field) {
    return null;
  }
  if (typeof field === 'string') {
    return <Tag className={className}>{field}</Tag>;
  }
  if (fieldType === sitecoreFieldTypes.RichText && typeof field === 'object') {
    return <RichText field={field} tag={Tag} className={className}/>;
  }
  return <Text field={field} tag={Tag} className={className}/>;
};

TextFilter.propTypes = {
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tag: PropTypes.string,
  className: PropTypes.string,
  fieldType: PropTypes.string,
};

/**
 * All shared decorations can be added here
 *
 * @example: <P center field={myField} /> - text will be centered
 */
export const TextBase = styled(TextFilter)`
  ${({ center }) => center
    && css`
      text-align: center;
    `}
  ${({ verticalOffset }) => verticalOffset
    && css`
        margin: ${verticalOffset}px 0;
    `}
`;

export const H1 = styled(TextBase).attrs(() => ({ tag: 'h1' }))`
  font-weight: 300;
  font-size: 26px;
  ${MediaQuery.medium`font-size: 48px;`}
`;

export const H2 = styled(TextBase).attrs(() => ({ tag: 'h2' }))`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
`;

export const H3 = styled(TextBase).attrs(() => ({ tag: 'h3' }))`
  font-weight: 600;
  font-size: 14px;
`;

export const H4 = styled(TextBase).attrs(() => ({ tag: 'h4' }))`
    font-weight: 600;
    font-size: 1.2rem;
`;

export const P = styled(TextBase).attrs(() => ({ tag: 'p', fieldType: sitecoreFieldTypes.RichText }))`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  
  a {
    color: ${colors.BLACK};
    font-weight: bold;
    text-decoration: underline;
    
    :hover {
      color: ${colors.BLACK};      
    }
  }
`;

export const Div = styled(TextBase).attrs(() => ({ tag: 'div', fieldType: sitecoreFieldTypes.RichText }))`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  
  a {
    color: ${colors.BLACK};
    font-weight: bold;
    text-decoration: underline;
    
    :hover {
      color: ${colors.BLACK};      
    }
  }
`;

export const Span = styled(TextBase).attrs(() => ({ tag: 'span' }))`
  font-size: 14px;
`;
