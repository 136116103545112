import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Vector from './icons/Vector.svg';
import { P } from '../../../../theme/typographySitecore';

export const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 300px !important;
  padding: 0 !important;
  opacity: 1 !important;
  color: black !important;
  background-color: white !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
  
  // --------------------
  // -- Tooltip arrows --
  // --------------------
    
  &.place-top {
    &:after {
      border-top: 8px solid white !important;
    }
  }
  
  &.place-right {
    &:after {
      border-right: 8px solid white !important;
    }
  }
  
  &.place-bottom {
    &:after {
      border-bottom: 8px solid white !important;
    }
  }
  
  &.place-left {
    &:after {
      border-left: 8px solid white !important;
    }
  }
`;

export const ContentContainer = styled.div`
  position: relative;  
`;

export const TooltipText = styled(P)`
  padding: 16px;
  margin: 0;
`;

export const VectorIcon = styled.img.attrs(() => ({ src: Vector }))`
  position: absolute;
  right: 0;
  bottom: 0;
`;
