import {
  ApolloClient, InMemoryCache, createHttpLink, gql,
} from '@apollo/client';
import utils from '../../utils/utils';
import packageJson from '../../../package.json';
import config from '../../temp/config';
import { endpoints } from '../../utils/constants';

export const formName = 'SiteSearch';
export const PageSize = 12;

const link = createHttpLink({
  uri: utils.isDisconnected
    ? 'http://internet.dev.local/api/internet?sc_apikey={43A6A824-9E28-4AD8-AB16-9550F0094466}'
    : `${endpoints.sitecore.graphService}?sc_apikey=${config.sitecoreApiKey}`,
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions,
});

export const SEARCH_QUERY_SORT_BY_CREATEDATE = ', sort: [{name: "__smallcreateddate", value: "desc"}]';

export const SEARCH_QUERY = (sort) => gql`
    query searchItems($keyword:String, $skip:String) {    
    drsearch(
    fieldsEqual: [{name: "issearchableitem", value: "true"}],
      keyword: $keyword, 
      after:$skip,
      first:${PageSize},
      mediaRootItem:"/sitecore/media library/${packageJson.config.appName}/data/media/files",
      rootItem: "/sitecore/content/${packageJson.config.appName}/home"${sort}) {
        results {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          items {
            uri
            name
            computedtitle:field(name:"computedtitle")
            description:field(name:"description")
            item{
                url
            }
          }
        }        
      }
}
`;

export const Stages = {
  NoTerm: 'NoTerm',
  Loading: 'Loading',
  DataLoaded: 'DataLoaded',
  NoResults: 'NoResults',
  Error: 'Error',
};

export const SortBy = {
  Relevance: 'Relevance',
  CreatedDate: 'Created Date',
};
