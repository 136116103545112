import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import Modal from 'react-modal';
import { H1 } from '../../../theme/typographySitecore';
import { TOGGLE_ACCOUNT_SIDE_MENU, TOGGLE_ACCOUNT_SIDE_MENU_ERROR_MODAL } from './actions';
import SideMenu from '../SideMenu';
import Card from './Card';
import LargeButton from './LargeButton';
import {
  BorderedColumn,
  Button,
  CardContainer,
  ExternalLinksContainer,
  LogOutButtonContainer,
  ErrorIcon,
  ModalTitle,
  Close,
  ModalBody,
  ButtonDiv,
  ActionButton
} from './css';
import { Divisions } from '../../../utils/enums';
import { routes } from '../../../utils/constants';
import logOut from '../../Logout/actions';
import { switchDivision } from '../../../actions/accounts';
import ModalBoxStyle from '../../_styledComponents/ModalBoxStyle';
import Error from '../../../assets/icons/Error.svg';
import readCookie from '../../../utils/cookies';
import { getSitecoreStateAuthToken } from '../../../utils/func';

const AccountSideMenu = ({
  accountSideMenu,
  userId,
  userEmail,
  userDivision,
  toggleAccountSideMenu,
  toggleAccountSideMenuModal,
  // eslint-disable-next-line no-shadow
  logOut,
  // eslint-disable-next-line no-shadow
  switchDivision,
  token,
  isAuthenticated
}) => {
  const header = () => (
    <H1 field={{ value: t('MyAccount') }} />
  );

  const handleSwitch = ({
    // eslint-disable-next-line no-shadow
    userId, divisionCode, token, isAuthenticated
  }) => {
    switchDivision({
      userId: parseInt(userId, 10), divisionCode, token, isAuthenticated
    });
  };

  const content = () => (
    <>
      <Modal
        isOpen={accountSideMenu.isModelOpen}
        onRequestClose={onCloseModal}
        style={ModalBoxStyle}
        contentLabel="Modal"
      >
        <Close onClick={onCloseModal} />
        <ModalBody className="justify-content-center">
          <ErrorIcon src={Error} alt="Error" />
          <ModalTitle>{t('AccessOtherServices')}</ModalTitle>
          <p>{t('SwitchDivisionPrompt')}</p>
          <ButtonDiv className="row">
            <div className="col-sm-6">
              <ActionButton onClick={openNewIncognitoWindow} className="active min-width">
                {t('ContinueAsAnonymousUser')}
              </ActionButton>
            </div>
            <div className="col-sm-6">
              <ActionButton onClick={onCloseModal} className="active min-width">
                {t('ReturnToLoggedInUser')}
              </ActionButton>
            </div>
          </ButtonDiv>
        </ModalBody>
      </Modal>
      <CardContainer>
        <BorderedColumn $tablet={6} $mobile={12}>
          <Card
            title={t('LtlTruckload')}
            description={t('FormerlyKnownAsFreight')}
            userEmail={userEmail}
            isLoggedIn={Divisions.Freight.name === userDivision}
            onSwitch={() => handleSwitch({
              userId, divisionCode: Divisions.Freight.code, token, isAuthenticated
            })}
            borderRight
          />
        </BorderedColumn>
        <BorderedColumn $hideBorderRight $tablet={6} $mobile={12}>
          <Card
            title={t('CommerceSolutions')}
            description={t('FormerlyKnownAsSameday')}
            userEmail={userEmail}
            isLoggedIn={Divisions.Sameday.name === userDivision}
            onSwitch={() => handleSwitch({
              userId, divisionCode: Divisions.Sameday.code, token, isAuthenticated
            })}
          />
        </BorderedColumn>
      </CardContainer>

      <LargeButton
        title={t('MyProfile')}
        description={t('AccountManagementDesc')}
        onClick={() => { window.location.href = routes.userProfile; }}
      />
      <LargeButton
        title={t('MyAccountPrivileges')}
        description="View your account privileges, including companies and roles you have access to."
        onClick={() => { window.location.href = routes.userProfile; }}
      />

      <LogOutButtonContainer>
        <Button onClick={logOut}>
          {t('LogOut')}
        </Button>
      </LogOutButtonContainer>

      <ExternalLinksContainer>
        <Button onClick={() => window.open(t('TrackItToolLink'))}>
          {t('BtnLoginTrackItTool')}
        </Button>
        <Button onClick={() => window.open(t('DayRossUsaLink'))}>
          {t('BtnLoginUS')}
        </Button>
      </ExternalLinksContainer>
    </>
  );

  const onClose = () => {
    toggleAccountSideMenu();
  };
  const onCloseModal = () => {
    toggleAccountSideMenuModal();
  };

  const getLocaleFromCookie = () => readCookie('internet#lang');

  const openNewIncognitoWindow = () => {
    let domainNameUrl;

    if (typeof window !== 'undefined') {
      const subDomainUrl = window.location.href.indexOf('www') > -1 ? '' : 'www.';

      if (window.location.hostname.split('.').length === 3) {
        domainNameUrl = [window.location.hostname.split('.')[1], window.location.hostname.split('.')[2]].join('.');
      } else {
        domainNameUrl = [window.location.hostname.split('.')[0], window.location.hostname.split('.')[1]].join('.');
      }
      const baseUrl = subDomainUrl + [domainNameUrl, getLocaleFromCookie()].join('/');

      window.open(`https://${baseUrl}`, '_blank', '', false);
      onCloseModal();
    }
  };

  return (
    <SideMenu
      isOpen={accountSideMenu.isOpen}
      onClose={onClose}
      header={header}
      content={content}
    />
  );
};

const mapStateToProps = (state) => ({
  accountSideMenu: state.accountSideMenu,
  userDivision: state.profile.division,
  userId: state.profile.userId,
  userEmail: state.profile.email,
  isAuthenticated: state.profile.isAuthenticated,
  token: (state.profile.generalSettings && state.profile.generalSettings.service_apiKey) || getSitecoreStateAuthToken(),
});

const mapDispatchToProps = (dispatch) => ({
  toggleAccountSideMenu: () => dispatch({ type: TOGGLE_ACCOUNT_SIDE_MENU }),
  toggleAccountSideMenuModal: () => dispatch({ type: TOGGLE_ACCOUNT_SIDE_MENU_ERROR_MODAL }),
  logOut: () => dispatch(logOut()),
  switchDivision: ({
    userId, divisionCode, token, isAuthenticated
  }) =>
    dispatch(
      switchDivision({
        userId,
        divisionCode,
        token,
        onAfterSwitch: () => dispatch({ type: TOGGLE_ACCOUNT_SIDE_MENU }),
        onSwitchFail: () => dispatch({ type: TOGGLE_ACCOUNT_SIDE_MENU_ERROR_MODAL }),
        isAuthenticated
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSideMenu);
