import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Row from '../Row';
import Column from '../Column';
import { getTotalWeight } from '../../../utils/func';
import { H3, P } from '../../../theme/typography';
import GreyBoxWithBorder from '../../_styledComponents/GreyBoxWithBorder';

const TotalWeightBlock = ({ measurementList, measurementType, totalNumber }) => (
  <Row>
    <Column $tablet={4} $mobile={12}>
      <GreyBoxWithBorder>
        <P>{t('TotalWeightOfItemsInShipment')}</P>
        <H3>
{measurementList && getTotalWeight(measurementList)}
{' '}
{measurementType === 'Metric' ? ' kg' : ' lbs'}
</H3>
      </GreyBoxWithBorder>
    </Column>
    {totalNumber && (
      <Column $tablet={4} $mobile={12}>
        <GreyBoxWithBorder>
          <P>{t('TotalNumberOfPiecesInShipment')}</P>
          <H3>
{measurementList && measurementList.length ? measurementList
  .reduce((accum, curr) => // add up pieces (called itemCount) from measurementList
    (curr.itemCount // if the currently inspected field has this value:
      ? (Number(accum) + Number(curr.itemCount)) : accum), 0)// add the curr value to the accum or return the accum if no curr value
            || 0 // if neither accum OR curr have a value, return 0
  : '-'}
</H3>
{/* if fields haven't been filled at all, return '-' */}
        </GreyBoxWithBorder>
      </Column>
    )}
  </Row>
);

TotalWeightBlock.propTypes = {
  measurementList: PropTypes.array,
  measurementType: PropTypes.string,
  totalNumber: PropTypes.bool
};

export default TotalWeightBlock;
