import styled from 'styled-components';
import { colors, MediaQuery } from '../../../theme/style-helpers';

export const ProgressContainer = styled.div`
    display: flex;
    padding: 40px 0 0;
    flex-wrap: wrap;

    ${MediaQuery.large`
        padding-bottom: 40px;
    `}   
`;

export const ProgressItem = styled.div`
    width: calc(100%/2);
    text-align: center;
    cursor:pointer;

    ${MediaQuery.medium`
        width: ${({ width }) => `calc(100% / ${width})`};
        
    `}
    .step {        
        ${MediaQuery.medium`
            display: block;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.3rem;
            color: ${colors.COOL_GREY};
        `}
    }

    .title {
        font-size: 0.9rem;
        line-height: 2.4rem;
        padding: 5px 4px;
        display: block;

        ${MediaQuery.medium`
            
            font-style: normal;
            font-weight: 500;
            font-size: 1.8rem;
            padding: 8px 4px;
            color: ${colors.COOL_GREY};
        `}
    }

    .progress-bar {
        height: 4px;
        display: block;
        background-color: ${colors.COOL_GREY};
        margin: 0 4px;
    }

    &.active {
        cursor:default;
        .step {
            color: ${colors.BLACK};
        }

        .title {
            color: ${colors.DARK_GREY};
        }

        .progress-bar {
            background-color: ${colors.BRAND_ORANGE};
        }
    }    
`;
