import styled, { css } from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';
import CarouselLeftArrow from '../../assets/icons/CarouselLeftArrow.svg';
import CarouselRightArrow from '../../assets/icons/CarouselRightArrow.svg';

export const SectionTitle = styled.div`
    margin-bottom: 26px;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;

    &.justified {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const Section = styled.div`

    &.text-align-left {
        text-align: left;
    }

    &.text-align-right {
        text-align: right;
    }

    &.text-align-middle {
        width: 70%;
        margin-left: 15%;
        text-align: center;
    }

    .carousel-items {
        .slick-arrow {
            z-index: 1;
            cursor: pointer;
            color: ${colors.WHITE};
            height: 50px;
            width: 30px;
            background-position: center center;
            background-repeat: no-repeat;
            box-sizing: border-box;
            background-size: contain;

            &:before {
                content: '';
            }

            &.slick-prev {
                left: 15px;
                background-image: url('${CarouselLeftArrow}');
            }

            &.slick-next {
                right: 15px;
                background-image: url('${CarouselRightArrow}');
            }
        }

        .slick-dots {
            display: flex;
            justify-content: center;
            list-style-type: none;
            padding: 0;
            margin: 0;
            position: absolute;
            bottom: 50px;
            left: 0;
            right: 0;

            li {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                button {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: ${colors.WHITE};
                    text-indent: -9999px;
                }

                &.slick-active {
                    button {
                        background-color: ${colors.BRAND_ORANGE};
                    }
                }
            }
        }
    }
`;

export const CarouselItemBase = styled.div`
    height: 500px;
    background-image: radial-gradient(
            25.37% 122.96% at 100% 38.7%,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
            86.51deg,
            rgba(0, 0, 0, 0.7) 1.31%,
            rgba(0, 0, 0, 0) 58.4%
    );
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-size: cover;

    ${MediaQuery.medium`
        height: 530px;
    `}
    .content {
        width: 380px;
        padding: 0;
        margin: 10%;
        display: inline-block;
        text-align: left;

        ${MediaQuery.medium`
            margin: 130px 10%;
        `}
        .text {
            display: block;
            font-size: 24px;
            line-height: 150%;
            font-weight: 500;
            margin: 10px 0;
            width: 70%;
            color: ${colors.WHITE};
        }

        .heading {
            display: block;
            padding-bottom: 30px;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.04em;
            color: ${colors.WHITE};
        }

        .readMore {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-decoration: none;
            color: ${colors.WHITE};

            .icon {
                background: url(${ArrowRightOrange});
                width: 20px;
                height: 26px;
                display: inline-block;
                margin-left: 30px;
                vertical-align: middle
            }
        }
    }
`;

export const CarouselItem = styled(CarouselItemBase)`
    ${({ $backgroundImageSrc }) => ($backgroundImageSrc
    ? css`
                background-image: radial-gradient(
                        25.37% 122.96% at 100% 38.7%,
                        rgba(0, 0, 0, 0.2) 0%,
                        rgba(0, 0, 0, 0) 100%
                ),
                linear-gradient(
                        86.51deg,
                        rgba(0, 0, 0, 0.7) 1.31%,
                        rgba(0, 0, 0, 0) 58.4%
                ),
                url('${$backgroundImageSrc}') !important;
                color: ${colors.WHITE};

                .readMore {
                    color: ${colors.WHITE};
                }
            `
    : null)
}`;

export const HeadingLine = styled.hr`
    height: 1px;
    display: block;
    width: 100px;
    margin: 0;
    background-color: ${colors.BRAND_ORANGE}
`;
