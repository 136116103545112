import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { t } from 'i18next';
import { AddAnotherItemContainer, AddItemLabel } from '../common/css';
import Plus from '../../../../assets/icons/Plus.svg';
import MeasurementContainer from '../Container';
import { UnitSelectorHeader } from '../Units/css';
import { H1 } from '../../../../theme/typographySitecore';
import UnitSelector from '../Units';
import { types } from '../Container/constants';

const ContainerList = (props) => {
  const { name } = props;
  return (<FieldArray props={props} name={name} component={Containers}/>);
};

ContainerList.propTypes = {
  name: PropTypes.string,
};

// eslint-disable-next-line
const Containers = ({
  fields,
  name,
  formName,
  type,
  isItem,
  isContainerData,
  disabledEquipmentSize,
  parentFields,
}) => {
  useEffect(() => {
    if (!fields.length) fields.push({});
  }, [fields]);
  const fieldsArray = fields.getAll();
  const disableUnitSelect = fieldsArray.some(
    (container) => container.completed
      || (container.items && container.items.some(
        (item) => item.completed
      )));
  return (
    <>
      {type !== types.READ_ONLY && (
        <UnitSelectorHeader>
          <H1 field={t('ShipmentSize')}/>
          <UnitSelector formName={formName} moveRight disabled={disableUnitSelect}/>
        </UnitSelectorHeader>
      )}
      {fields.map((item, id) => (
        <MeasurementContainer
          item={item}
          name="items"
          id={id}
          key={name + id}
          formName={formName}
          container={fields.get(id)}
          containersList={fields.getAll()}
          listName={name}
          type={type}
          isItem={isItem}
          isContainerData={isContainerData}
          disabledEquipmentSize={disabledEquipmentSize}
          parentFields={parentFields}
        />
      ))}
      {type !== types.READ_ONLY
        && fieldsArray
        && fieldsArray.every(({ completed }) => completed) && (
          <AddAnotherItemContainer
            className="with-dot"
            onClick={() => fields.push({})}
          >
            <img src={Plus} alt={t('SaveItem')}/>
            <AddItemLabel>{t('AddAnotherContainer')}</AddItemLabel>
          </AddAnotherItemContainer>
      )}
    </>
  );
};

Containers.propTypes = {
  fields: PropTypes.object,
  name: PropTypes.string,
  formName: PropTypes.string,
  type: PropTypes.string,
  isItem: PropTypes.bool,
  isContainerData: PropTypes.bool,
  disabledEquipmentSize: PropTypes.string,
  parentFields: PropTypes.object,
};

export default ContainerList;
