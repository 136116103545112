import React, { useState, useEffect } from 'react';
import { Field, FieldArray, change } from 'redux-form';
import { t } from 'i18next';
import AddressComplete from '../../ReactComponent/AddressComplete';
import PlusGrey from '../../../assets/icons/PlusGrey.svg';
import CloseOrange from '../../../assets/icons/Close.svg';
import RenderField from '../../ReactComponent/RenderField';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import Plus from '../../../assets/icons/Plus.svg';
import Delete from '../../../assets/icons/RemoveOrange.svg';
import CheckMarkSolidOrange from '../../../assets/icons/CheckMarkSolidOrange.svg';
import Edit from '../../../assets/icons/Edit.svg';
import PasswordGroup from '../../ReactComponent/PasswordGroup';
import {
  isRequired,
  isPhone,
  isEmail,
  maxChar50,
  maxChar6
} from '../../../utils/validator';
import { phoneNumber, digitOnly } from '../../../utils/mask';
import {
  AddItemContainer,
  AddItem,
  AddItemLabel,
  PageTitle,
  ContentBoxLightGrey,
  CategoryTitle,
  CardTitle,
  FileUploadLabel,
  FileUploadContainer,
  FileUpload,
  ItemContainer,
  Icon,
  EditButtonGroup,
  LineButton,
  TextProperty,
} from '../css';
import PrimaryButton from '../../ReactComponent/PrimaryButton';
import { H3, P } from '../../../theme/typography';
import { BillingAccountType } from '../../../utils/enums';
import Tooltip from '../../ReactComponent/Tooltip';

const companyCheckboxRenderer = ({ item }) => (
  <>
    <span className="checkmark"></span>
    <Column $tablet={12} $mobile={12}>
      <H3>{t(item.accountNumber || item.permissionName)}</H3>
    </Column>
    {item.accountType === 'PA' &&
      <Column $tablet={12} $mobile={12}>
        <H3>{t('Protected Billing Account')}</H3>
      </Column>}
  </>
);

const renderUserCompanyList = ({
  fields,
  dispatch,
  formName,
  formData,
  companiesList,
  currentCompaniesList,
  getCompanyInfo,
  companyDetails,
  // showAdd,
  error
}) => {
  const disableAddItem = error.companies ? true : false;
  const addItem = (index) => {
    dispatch(change(formName, `companies[${index}].completed`, true));
  };
  const [state, setState] = useState({
    showAddLineButton: false,
    hideRemoveButton: true
  });

  useEffect(() => {
    const hideRemoveButton = currentCompaniesList.length <= 1;
    const showAddLineButton = !(currentCompaniesList.some((m) => !m.completed));
    setState({
      ...state,
      hideRemoveButton,
      showAddLineButton
    });
  }, [currentCompaniesList]);
  const editUser = (index) => {
    dispatch(
      change(
        formName,
        `companies[${index}].completed`,
        false
      )
    );
  };
  const deleteCompany = (index) => {
    const newlist = [...currentCompaniesList];
    newlist.splice(index, 1);
    if (newlist.length === 0) {
      newlist.push({});
    }

    dispatch(
      change(
        formName,
        'companies',
        newlist
      )
    );
  };
  return (
    <>
      {fields.map((item, index) => {
        const fieldValue = fields.get(index);
        const {
          completed,
          companyId,
          accounts,
          permissions
        } = fieldValue;

        const onlyUsefulPermissions = permissions && permissions.length > 0 ?
          permissions.filter((permission) => (
            permission.permissionName !== 'CreateShipment' &&
            permission.permissionName !== 'InterfaceSetting' &&
            permission.permissionName !== 'PrintLabels' &&
            permission.permissionName !== 'QuickEstimate'
          )) : permissions;

        const companyDetailsPermissions = companyDetails[index] && companyDetails[index].permissions.filter((permission) => (
          permission.permissionName !== 'CreateShipment' &&
            permission.permissionName !== 'InterfaceSetting' &&
            permission.permissionName !== 'PrintLabels' &&
            permission.permissionName !== 'QuickEstimate'
        ));

        if (completed) {
          return (
            <ContentBoxLightGrey key={index}>
              <EditButtonGroup>
                <LineButton onClick={() => deleteCompany(index)}>
                  <Icon className="icon" src={Delete} alt="delete"/>
                  {t('Delete')}
                </LineButton>
                {state.showAddLineButton && <LineButton onClick={() => editUser(index)}>
                  <Icon className="icon" src={Edit} alt="edit"/>
                  {t('Edit')}
                </LineButton>}
              </EditButtonGroup>
              <CardTitle>
                {t('Company')}
                {` ${index + 1}`}
              </CardTitle>
              <Row>
                <Column>
                  <TextProperty>
                    <P className="title">{t('SelectedCompany')}</P>
                    <P className="text">{companyId}</P>
                  </TextProperty>
                </Column>
              </Row>
              <Row>
                <Column>
                  <TextProperty>
                    <P className="title">{t('BillingAccountNumber')}</P>
                    {accounts.map((a) =>
                      a.accountNumber &&
                      <P className="text" key={a.accountNumber}>
                        <img src={CheckMarkSolidOrange} alt="added item"/>
                        {a.accountNumber} {`- ${a.accountType === 'PA' ?
                          t(BillingAccountType.Protected)
                          :
                          t(BillingAccountType.Billing)}`}
                      </P>
                    )}
                  </TextProperty>
                </Column>
              </Row>
              <Row>
                <Column>
                  <TextProperty>
                  <P className="title">{t('AssignedPermissions')}</P>
                  <P className="title">{t('AssignedPermissions')}</P>
                    {onlyUsefulPermissions && onlyUsefulPermissions.length > 0 && onlyUsefulPermissions.map((p) => {
                      if (p.permissionId) {
                        let displayText;
                        if (p.permissionName === 'ShipmentTracking') {
                          displayText = 'Invoices';
                        } else if (p.permissionName === 'RequestPickup') {
                          displayText = 'Pickups';
                        } else if (p.permissionName === 'ManageAddressBooks') {
                          displayText = 'Manage Contacts';
                        } else if (p.permissionName === 'ManageAccounts') {
                          displayText = 'Manage Companies';
                        } else {
                          displayText = p.permissionName;
                        }
                        return (
                          <P className="text" key={p.permissionId}>
                            <img src={CheckMarkSolidOrange} alt="added item"/> {displayText}
                          </P>
                        );
                      }
                      return null;
                    })}
                  </TextProperty>
                </Column>
              </Row>
            </ContentBoxLightGrey>
          );
        }

        return (
          <ContentBoxLightGrey className="edit-item-box" key={index}>
            <CardTitle>
              {t('Company')}
              {` ${index + 1}`}
            </CardTitle>
            <Row>
              <Column $tablet={5} $mobile={12}>
                <Field
                  name={`${item}.companyId`}
                  type="dropdown"
                  label={t('SelectedCompany')}
                  data={companiesList.map((c) => c.companyName)}
                  required
                  onChange={(e) => getCompanyInfo(e, index)}
                  component={RenderField}
                  validate={[isRequired]}
                />
              </Column>
            </Row>
            {formData.companies && formData.companies[index] && formData.companies[index].companyId &&
              <>
                <Row>
                  <Column>
                    <TextProperty>
                      <P className="text">{t('BillingAccountNumber')}</P>
                      <P className="title">{t('SelectBillingAccountForUser')}</P>
                    </TextProperty>
                  </Column>
                </Row>
                <Row>
                  <Column $tablet={6} $mobile={12}>
                    <Field
                      name={`${item}.accounts`}
                      type="checkboxes"
                      label={t('SelectedAccountNumbers')}
                      data={companyDetails[index] && companyDetails[index].accounts}
                      renderer={companyCheckboxRenderer}
                      className="w-50"
                      required
                      multiple
                      component={RenderField}
                      validate={[isRequired]}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <TextProperty>
                      <P className="text">{t('UserPermissions')}</P>
                      <Tooltip tip={t('TipAddContact')}/>
                    </TextProperty>
                  </Column>
                </Row>
                <Row>
                  <Column $tablet={6} $mobile={12}>
                    <Field
                      name={`${item}.permissions`}
                      type="checkboxes"
                      label={t('Selected Permission Numbers')}
                      data={companyDetailsPermissions && companyDetailsPermissions.length > 0 ? companyDetailsPermissions : []}
                      renderer={companyCheckboxRenderer}
                      className="w-50"
                      required
                      multiple
                      component={RenderField}
                      validate={[isRequired]}
                    />
                  </Column>
                </Row>
                <AddItemContainer>
                  {state.hideRemoveButton ? undefined :
                    <AddItem onClick={() => deleteCompany(index)}>
                      <AddItemLabel>{t('Cancel')}</AddItemLabel>
                      <img src={CloseOrange} alt="remove item"/>
                    </AddItem>}
                  <AddItem className={disableAddItem ? '' : 'active'} disabled={disableAddItem} onClick={() => addItem(index)}>
                    <AddItemLabel>{t('SaveItem')}</AddItemLabel>
                    <img src={disableAddItem ? PlusGrey : Plus} alt="add item"/>
                  </AddItem>
                </AddItemContainer>
              </>}
          </ContentBoxLightGrey>
        );
      })}
      {state.showAddLineButton && (
        <FileUploadContainer className="with-dot">
          <FileUpload onClick={() => {
            fields.push({});
          }}>
            <img src={Plus} alt="add more part file"/>
            <FileUploadLabel>{t('AddAnotherItem')}</FileUploadLabel>
          </FileUpload>
        </FileUploadContainer>
      )}
    </>
  );
};

const UserForm = ({
  fields,
  formData = {},
  formName,
  dispatch,
  companiesList,
  deleteUser,
  getCompanyInfo,
  companyDetails,
  valid,
  showAdd,
  error
}) => {
  const {
    companies = [],
    rowVersion,
    email,
    oldPassword
  } = formData;
  const {
    passwordImage
  } = fields;
  const update = rowVersion ? true : false;
  return (
    <>
      <PageTitle className="justified">
        {fields.pageTitle && fields.pageTitle.value}
      </PageTitle>
      <Row className="justify-content-md-center">
        <Column $tablet={10} $mobile={12}>
          <TextProperty>
            {fields.emailAddressInfo && fields.emailAddressInfo.value}
          </TextProperty>
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={10} $mobile={12}>
          {update ?
            <TextProperty>
              <P className="title">{t('Email')}</P>
              <P className="text">{email}</P>
            </TextProperty> :
            <Field
              name="email"
              type="text"
              required
              component={RenderField}
              label={t('Email')}
              validate={[isRequired, isEmail]}
            />}
        </Column>
      </Row>
      <CategoryTitle>
        {fields.profileDetailsTitle && fields.profileDetailsTitle.value}
      </CategoryTitle>
      <Row className="justify-content-md-center">
        <Column $tablet={10} $mobile={12}>
          <Field
            name="companyName"
            type="text"
            required
            component={RenderField}
            label={t('CompanyName')}
            validate={[isRequired]}
          />
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={6} $mobile={12}>
          <Field
            name="name"
            type="text"
            required
            component={RenderField}
            label={t('FullName')}
            validate={[isRequired]}
          />
        </Column>
        <Column $tablet={4} $mobile={12}>
          <Field
            name="title"
            type="text"
            component={RenderField}
            label={t('Title')}
          />
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={10} $mobile={12}>
          <AddressComplete
            fieldName="address"
            formName={formName}
            required
          />
        </Column>
        <Tooltip userFlow tip={t('TipAddressDetails')}/>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={6} $mobile={12}>
          <Field
            name="phoneNumber"
            type="text"
            component={RenderField}
            label={t('PhoneNumber')}
            validate={[isPhone, maxChar50, isRequired]}
            normalize={phoneNumber}
            required
          />
        </Column>
        <Column $tablet={4} $mobile={12}>
          <Field
            name="phoneNumberExt"
            type="text"
            component={RenderField}
            label={t('Extension')}
            validate={[maxChar6]}
            normalize={digitOnly}
          />
        </Column>
      </Row>
      <Row className="justify-content-md-center">
        <Column $tablet={10} $mobile={12}>
          <Field
            name="description"
            type="text"
            required
            component={RenderField}
            label={t('Description')}
            validate={[isRequired]}
          />
        </Column>
      </Row>
      <CategoryTitle>
        {fields.passwordTitle && fields.passwordTitle.value}
      </CategoryTitle>
      {update &&
        <>
          <TextProperty>
            {fields.passwordSummary && fields.passwordSummary.value}
          </TextProperty>
          <Row className="justify-content-md-center">
            <Column $tablet={10} $mobile={12}>
              <Field
                name="oldPassword"
                type="password"
                required
                component={RenderField}
                label={t('OldPassword')}
                validate={[]}
              />
            </Column>
          </Row>
        </>}
      {(!update || (update && oldPassword)) &&
        <Row className="justify-content-md-center">
          <Column $tablet={10} $mobile={12}>
            <PasswordGroup passwordImage={passwordImage}/>
          </Column>
        </Row>}
      <TextProperty>
        {fields.checkboxText && fields.checkboxText.value}
      </TextProperty>
      <Row>
        <Column>
          <Field
            name="changePassword"
            type="checkboxes"
            data={['Force User To Reset Password']}
            component={RenderField}
            multiple
          />
        </Column>
      </Row>
      <CategoryTitle>
        {fields.addUserTitle && fields.addUserTitle.value}
      </CategoryTitle>
      <ItemContainer>
        <FieldArray
          name="companies"
          dispatch={dispatch}
          component={renderUserCompanyList}
          valid={valid}
          formName={formName}
          companiesList={companiesList}
          currentCompaniesList={companies}
          getCompanyInfo={getCompanyInfo}
          companyDetails={companyDetails}
          formData={formData}
          showAdd={showAdd}
          error={error}
        />
      </ItemContainer>
      <CategoryTitle>
        {fields.disableUserTitle && fields.disableUserTitle.value}
      </CategoryTitle>
      <TextProperty>
        {fields.disableUserText && fields.disableUserText.value}
      </TextProperty>
      <Row>
        <Column>
          <Field
            name="disabled"
            type="checkboxes"
            data={['Disable User']}
            component={RenderField}
            multiple
          />
        </Column>
      </Row>
      <CategoryTitle>
        {fields.deleteUserTitle && fields.deleteUserTitle.value}
      </CategoryTitle>
      <TextProperty>
        {fields.deleteUserText && fields.deleteUserText.value}
      </TextProperty>
      <Row>
        <PrimaryButton type="button" className={`${update && 'active'}`} disabled={!update} onClick={() => deleteUser()}>
          {t('DeleteUser')}
        </PrimaryButton>
      </Row>
    </>
  );
};

export default UserForm;
