import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';
import { NUMBER_OF_DAYS } from './constants';
import { getQuotesByDivision } from '../../services/quotes';

export const apiGetShipments = (updateState, division, accountNumbers, updateError) => {
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipments}/${division}/recent`, {
    params: {
      numberOfDaysFromToday: NUMBER_OF_DAYS,
    },
    headers: {
      accountNumbers
    }
  }).then((res) => {
    if (res.status === 200) {
      updateState(res.data);
    } else {
      updateError(res);
    }
  }).catch((error) => {
    updateError(error);
  });
};

export const apiGetQuotes = (updateState, division, accountNumbers, updateError) => {
  getQuotesByDivision({ division, numberOfDaysFromToday: NUMBER_OF_DAYS, accountNumbers }).then((res) => {
    if (res.status === 200) {
      updateState(res.data);
    } else {
      updateError(res);
    }
  }).catch((error) => {
    updateError(error);
  });
};

export const apiGetInvoices = (updateState, division, accountNumbers, fromDate, toDate, updateError) => {
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.invoices}/${division}`, {
    params: {
      fromDate,
      toDate
    },
    headers: {
      accountNumbers
    }
  }).then((res) => {
    if (res.status === 200) {
      updateState(res.data);
    } else {
      updateError(res);
    }
  }).catch((error) => {
    updateError(error);
  });
};

export const apiGetPickups = (updateState, accountNumbers, updateError, division) => {
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.pickups}`, {
    params: {
      numberOfDaysFromToday: NUMBER_OF_DAYS,
      division
    },
    headers: {
      accountNumbers
    }
  }).then((res) => {
    if (res.status === 200 || res.status === 404) {
      updateState(res.data);
    } else {
      updateError(res);
    }
  }).catch((error) => {
    updateError(error);
  });
};
