import React from 'react';
import PropTypes from 'prop-types';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { t } from 'i18next';
import { MDBDropdown } from 'mdbreact';
import { routes } from '../../../utils/constants';
import Actions from '../../../assets/icons/Actions.svg';
import ShipmentTrackingIcon from '../../../assets/icons/ShipmentTracking.svg';
import LoadingIndicator from '../../../assets/image/LoadingIndicator.gif';
import ArrowDown from '../../../assets/icons/ArrowDown.svg';
import ViewWhite from '../../../assets/icons/ViewWhite.svg';
import ViewGrey from '../../../assets/icons/ViewGrey.svg';
import TrackWhite from '../../../assets/icons/TrackWhite.svg';
import TrackGrey from '../../../assets/icons/TrackGrey.svg';
import ViewSignedDocumentsWhite from '../../../assets/icons/ViewSignedDocumentsWhite.svg';
import ViewSignedDocumentsGrey from '../../../assets/icons/ViewSignedDocumentsGrey.svg';
import { H2 } from '../../../theme/typography';

import SortableTable from '../../ReactComponent/SortableTable';
import NoDataDecorator from '../../ReactComponent/SortableTable/NoDataDecorator';
import ShipmentDetails from '../../ReactComponent/ShipmentDetails';

import {
  Tile,
  InlineBlock,
  CenteredMessage,
  Icon,
  ExpandButton
} from '../css';
import { Icon as CommonIcon } from '../../ReactComponent/Icon';
import { DropdownItem, DropdownItemIcon } from '../../ReactComponent/SortableTable/css';
import {
  Stages,
  ShipmentCategories,
  ShipmentStates,
  shipmentTableColumns,
  NUMBER_OF_SAMPLE_DATA,
  ShipmentHistoryStates
} from '../constants';
import { apiGetShipments } from '../api';
import { ShipmentStatuses } from '../../../utils/enums';
import { getShipmentTracking } from '../../../services/shipments';
import { getDeliveryDate } from '../../../utils/dateTime';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../../_styledComponents/MDBDropdownToggle';

const Dropdown = ({
  status,
  probillNumber,
  division
}) => (<MDBDropdown className="dropdown">
  <MDBDropdownToggle className="border-0">
    <CommonIcon iconSrc={Actions}/>
  </MDBDropdownToggle>
  <StyledMDBDropdownMenu className="text-white bg-dark" basic>
    <DropdownItem
      disabled={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.NotYetPickedUp}
      onClick={() => { window.location.href = `${routes.viewShipmentTracking}?division=${division}&probillNumber=${probillNumber}`; }}>
      <DropdownItemIcon
        iconSrc={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview
        || status === ShipmentStatuses.NotYetPickedUp ? TrackGrey : TrackWhite}/>
      {t('ViewTrackingDetails')}
    </DropdownItem>
    <DropdownItem
      disabled={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.NotYetPickedUp}
      onClick={() => { window.location.href = `${routes.viewShipment}?division=${division}&probillNumber=${probillNumber}`; }}>
      <DropdownItemIcon
        iconSrc={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview
        || status === ShipmentStatuses.NotYetPickedUp ? ViewGrey : ViewWhite}/>
      {t('ViewShipmentDetails')}
    </DropdownItem>
    <DropdownItem
      disabled={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.NotYetPickedUp}
      onClick={() => { window.location.href = `${routes.viewShipment}?division=${division}&probillNumber=${probillNumber}&scrollToDocs=true`; }}>
      <DropdownItemIcon
        iconSrc={status === ShipmentStatuses.Expired || status === ShipmentStatuses.UnderReview
        || status === ShipmentStatuses.NotYetPickedUp ? ViewSignedDocumentsGrey : ViewSignedDocumentsWhite}/>
      {t('ViewSignedDocuments')}
    </DropdownItem>
  </StyledMDBDropdownMenu>
</MDBDropdown>);

Dropdown.propTypes = {
  status: PropTypes.string,
  probillNumber: PropTypes.string,
  division: PropTypes.string
};

// eslint-disable-next-line
class ShipmentTracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: Stages.Loading,
      data: [],
      tableData: [],
      groupedData: [],
      error: '',
    };
  }

  componentDidMount() {
    const {
      division,
      accountNumbers
    } = this.props;

    apiGetShipments(this.onSetData, division, accountNumbers, this.onError);
  }

  groupData = (data) => data.reduce((categories, item) => {
    const { status } = item;
    if (!categories[status]) {
      categories[status] = [];
    }

    categories[status].push(item);
    return categories;
  }, {});

  renderDetailsDataError = (index) => {
    const { tableData } = this.state;
    const dataRow = tableData[index];

    if (!dataRow) {
      return;
    }

    dataRow.separateRow = <ShipmentDetails error={t('ShipmentDetailsError')}/>;
    this.setState({ tableData });
  };

  renderDetailsData = (index, detailsData) => {
    const { isAuthenticated } = this.props;
    const { tableData } = this.state;
    const dataRow = tableData[index];

    if (!dataRow) {
      return;
    }

    const { basicData } = dataRow;
    const historyData = detailsData.filter((history) => history.status !== ShipmentHistoryStates.DoNotShow);

    dataRow.detailsData = historyData;
    dataRow.separateRow =
      <ShipmentDetails key={index} mapid={`google-map-${index}`} basicData={basicData} detailsData={historyData} isAuthenticated={isAuthenticated}/>;

    this.setState({ tableData });
  };

  fetchAndRenderDetailsData = (index) => {
    const { tableData } = this.state;
    const dataRow = tableData[index];

    if (!dataRow) {
      return null;
    }

    const {
      probillNumber,
      detailsData
    } = dataRow;

    if (detailsData) {
      this.renderDetailsData(index, detailsData);
      return null;
    }

    const { division } = this.props;
    getShipmentTracking(this.renderDetailsData, index, division, probillNumber, this.renderDetailsDataError);
    return null;
  };

  prepareExpandButton = (index, open) =>
    <ExpandButton key={index} type="button" onClick={() => this.addSpecial(index)} className={open ? 'open' : ''}>
      <Icon src={ArrowDown} alt="expand filter"/>
    </ExpandButton>;

  fetchSampleData = () => {
    const { data } = this.state;
    const { division } = this.props;

    const sampleData = data.slice(0, NUMBER_OF_SAMPLE_DATA); // Get the top NUMBER_OF_SAMPLE_DATA shipments...

    return sampleData.map((each, index) => ({
      probillNumber: each.probillNumber,
      deliveryDate: each.deliveryDate ? getDeliveryDate({
        deliveryDate: each.deliveryDate,
        deliveryDateType: each.deliveryDateType
      }) : '',
      status: each.status,
      actions: <Dropdown
        status={each.status}
        probillNumber={each.probillNumber}
        division={division}
      />,
      expand: this.prepareExpandButton(index, false),
      basicData: each,
      detailsData: null,
    })
    );
  };

  addSpecial = (index) => {
    const { tableData } = this.state;

    if (tableData[index].separateRow) {
      tableData[index].separateRow = null;
      tableData[index].expand = this.prepareExpandButton(index, false);
    } else {
      this.fetchAndRenderDetailsData(index);
      tableData[index].expand = this.prepareExpandButton(index, true);
    }

    this.setState({ tableData });
  };

  fetchCategoriesCount = () => {
    const { groupedData } = this.state;
    const currentDate = new Date();
    return Object.keys(groupedData).reduce((categories, item) => {
      switch (item) {
        case ShipmentStates.PickedUp:
        case ShipmentStates.InTransit:
        case ShipmentStates.OnDock:
        case ShipmentStates.Transfer: {
          if (item.deliveryDate && new Date(item.deliveryDate) < currentDate) {
            if (!categories[ShipmentCategories.ShipmentsDelayed]) {
              categories[ShipmentCategories.ShipmentsDelayed] = groupedData[item].length;
            } else {
              categories[ShipmentCategories.ShipmentsDelayed] += groupedData[item].length;
            }
          } else if (!categories[ShipmentCategories.OnRoute]) {
            categories[ShipmentCategories.OnRoute] = groupedData[item].length;
          } else {
            categories[ShipmentCategories.OnRoute] += groupedData[item].length;
          }
          break;
        }
        case ShipmentStates.Delivered: {
          if (!categories[ShipmentCategories.ShipmentsCompleted]) {
            categories[ShipmentCategories.ShipmentsCompleted] = groupedData[item].length;
          } else {
            categories[ShipmentCategories.ShipmentsCompleted] += groupedData[item].length;
          }
          break;
        }
        default:
          break;
      }
      return categories;
    }, []);
  };

  onSetData = (data) => {
    if (data && data.length > 0) {
      const groupedData = this.groupData(data);
      const sortedData = data.sort((a, b) => Date.parse(b.shipDate) - Date.parse(a.shipDate));
      this.setState({
        groupedData,
        data: sortedData,
        stage: Stages.DataLoaded
      });
      this.setState({ tableData: this.fetchSampleData() });
      const { onSetData } = this.props;
      if (onSetData) {
        onSetData(data);
      }
    } else {
      this.setState({
        groupedData: [],
        data: [],
        stage: Stages.NoResults
      });
    }
  };

  onError = () => {
    this.setState({
      error: `${t('Request returned an error')}`,
      stage: Stages.Error
    });
  };

  render() {
    const {
      stage,
      error,
      tableData
    } = this.state;

    const { fields } = this.props;

    const {
      createShipmentLink,
      manageShipmentsLink,
      trackShipmentsLink,
      noResultsText,
      loadingText
    } = fields;
    const hasCreateShipmentLink = createShipmentLink && createShipmentLink.value
      && createShipmentLink.value.href && createShipmentLink.value.text;
    const hasManageShipmentsLink = manageShipmentsLink && manageShipmentsLink.value
      && manageShipmentsLink.value.href && manageShipmentsLink.value.text;
    const hasTrackShipmentsLink = trackShipmentsLink && trackShipmentsLink.value
      && trackShipmentsLink.value.href && trackShipmentsLink.value.text;

    return (<Tile>
      {stage === Stages.Loading &&
        <CenteredMessage className="padding-70">
          <Icon src={LoadingIndicator} alt="no results" className="icon-100"/>
          <Text tag="div" className="bold-message" field={loadingText}/>
        </CenteredMessage>}
      {stage === Stages.DataLoaded && <>
        {fields.heading && fields.heading.value &&
          <InlineBlock width={50}>
            <H2>
              <Text field={fields.heading}/>
            </H2>
          </InlineBlock>}
        {hasCreateShipmentLink &&
          <InlineBlock width={50} className="right">
            <Link field={createShipmentLink} className="primaryButton submit">
              <span className="icon"></span>
              {createShipmentLink.value.text}
            </Link>
          </InlineBlock>}
        <div className="margin-20">
          <NoDataDecorator component={SortableTable} data={tableData} columns={shipmentTableColumns} hidePagination/>
        </div>
        <InlineBlock className="right margin-20">
          {hasManageShipmentsLink &&
            <Link field={manageShipmentsLink} className="secondaryButton no-border">
              <span className="icon"></span>
              {manageShipmentsLink.value.text}
            </Link>}
          {hasTrackShipmentsLink &&
            <Link field={trackShipmentsLink} className="secondaryButton">
              <span className="icon"></span>
              {trackShipmentsLink.value.text}
            </Link>}
        </InlineBlock>
      </>}
      {stage === Stages.NoResults && <CenteredMessage className="padding-40">
        <Icon src={ShipmentTrackingIcon} alt="no results" className="icon-100"/>
        <Text tag="div" className="bold-message" field={noResultsText}/>
        {hasCreateShipmentLink &&
          <InlineBlock className="margin-20">
            <Link field={createShipmentLink} className="primaryButton submit">
              <span className="icon"></span>
              {createShipmentLink.value.text}
            </Link>
          </InlineBlock>}
      </CenteredMessage>}
      {stage === Stages.Error && <CenteredMessage>{error}</CenteredMessage>}
    </Tile>);
  }
}

ShipmentTracking.propTypes = {
  division: PropTypes.string,
  accountNumbers: PropTypes.arrayOf(PropTypes.string),
  isAuthenticated: PropTypes.bool,
  fields: PropTypes.shape({
    createShipmentLink: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
    manageShipmentsLink: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
    trackShipmentsLink: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
    noResultsText: PropTypes.object,
    loadingText: PropTypes.object,
    heading: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  onSetData: PropTypes.func,
};

export default ShipmentTracking;
