import React from 'react';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { t } from 'i18next';
import { MDBDropdown } from 'mdbreact';
import { H2 } from '../../../theme/typography';
import Actions from '../../../assets/icons/Actions.svg';
import QuoteSummaryIcon from '../../../assets/icons/QuoteSummary.svg';
import CreateAShipmentWhite from '../../../assets/icons/CreateAShipmentWhite.svg';
import CreateAShipmentGrey from '../../../assets/icons/CreateAShipmentGrey.svg';
import CreateQuoteFromExistingGrey from '../../../assets/icons/CreateQuoteFromExistingGrey.svg';
import CreateQuoteFromExistingWhite from '../../../assets/icons/CreateQuoteFromExistingWhite.svg';
import LoadingIndicator from '../../../assets/image/LoadingIndicator.gif';
import { Icon as CommonIcon } from '../../ReactComponent/Icon';
import { DropdownItem, DropdownItemIcon } from '../../ReactComponent/SortableTable/css';
import SortableTable from '../../ReactComponent/SortableTable';
import NoDataDecorator from '../../ReactComponent/SortableTable/NoDataDecorator';
import {
  Tile,
  InlineBlock,
  CenteredMessage,
  Icon
} from '../css';
import { Stages, quoteTableColumns, NUMBER_OF_SAMPLE_DATA } from '../constants';
import {
  apiGetQuotes,
} from '../api';
import { ShipmentStatuses } from '../../../utils/enums';
import { routes } from '../../../utils/constants';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../../_styledComponents/MDBDropdownToggle';

const sixDigitQuote = (quoteNumber) => {
  const quoteValue = quoteNumber.toString().split('');
  const quoteLength = quoteValue.length;
  for (let i = 0; i < 6 - quoteLength; i++) {
    quoteValue.unshift('0');
  }
  return quoteValue.join('');
};

const QuoteInfo = ({ quoteNumber }) => (
        <div className="quote-info">
            <span>
                {sixDigitQuote(quoteNumber)}
            </span>
        </div>);

// eslint-disable-next-line react/no-multi-comp
const Dropdown = ({ status, quoteNumber, division }) => (<MDBDropdown className="dropdown" >
        <MDBDropdownToggle className="border-0">
            <CommonIcon iconSrc={Actions} />
        </MDBDropdownToggle>
        <StyledMDBDropdownMenu className="text-white bg-dark" basic>
            <DropdownItem
            disabled={status !== ShipmentStatuses.Finalized}
            onClick={() => {
              window.location.href = `${routes.createShipment}?division=${division}&quoteNumber=${quoteNumber}`;
              return window.location.href;
            }}>
                <DropdownItemIcon iconSrc={status !== ShipmentStatuses.Finalized ? CreateAShipmentGrey : CreateAShipmentWhite} />
                {t('CreateAShipment')}
            </DropdownItem>
            <DropdownItem
            disabled={status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.USQuote}
            onClick={() => {
              window.location.href = `${routes.createQuote}?recreate=true&division=${division}&quoteNumber=${quoteNumber}`;
              return window.location.href;
            }}>
                <DropdownItemIcon
                iconSrc={(status === ShipmentStatuses.UnderReview || status === ShipmentStatuses.USQuote)
                  ? CreateQuoteFromExistingGrey
                  : CreateQuoteFromExistingWhite} />
                {t('Renew')}
            </DropdownItem>
        </StyledMDBDropdownMenu>
    </MDBDropdown>);

// eslint-disable-next-line react/no-multi-comp
class QuoteTracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: Stages.Loading,
      data: [],
      error: '',
    };
  }

  componentDidMount() {
    const { division, accountNumbers } = this.props;

    apiGetQuotes(this.onSetData, division, accountNumbers, this.onError);
  }

  groupData = (data) => {
    const groups = data.reduce((categories, item) => {
      const { status } = item;
      if (!categories[status]) {
        categories[status] = [];
      }

      categories[status].push(item);
      return categories;
    }, []);

    return groups;
  };

  fetchSampleData = () => {
    const { data } = this.state;
    const { division } = this.props;

    const sampleData = data.slice(0, NUMBER_OF_SAMPLE_DATA);

    return sampleData.map((each) => ({
      quoteNumber: <QuoteInfo quoteNumber={each.quoteNumber} />,
      fromTo: `${each.addressFrom.city} to ${each.addressTo.city}`,
      status: each.status,
      actions: <Dropdown
        status={each.status}
        quoteNumber={each.quoteNumber}
        division={division}
      />
    }));
  };

  onSetData = (data) => {
    if (data && data.length > 0) {
      const sortedData = data.sort((a, b) => b.quoteNumber - a.quoteNumber);
      this.setState({ data: sortedData, stage: Stages.DataLoaded });

      const { onSetData } = this.props;
      if (onSetData) {
        onSetData(data);
      }
    } else {
      this.setState({ data: [], stage: Stages.NoResults });
    }
  };

  onError = () => {
    this.setState({ error: `${t('Request returned an error')}`, stage: Stages.Error });
  };

  render() {
    const { stage, error } = this.state;

    const { fields } = this.props;

    const {
      requestQuoteLink, manageQuotesLink, noResultsText, loadingText
    } = fields;
    const hasRequestQuoteLink = requestQuoteLink && requestQuoteLink.value && requestQuoteLink.value.href && requestQuoteLink.value.text;
    const hasManageShipmentsLink = manageQuotesLink && manageQuotesLink.value && manageQuotesLink.value.href && manageQuotesLink.value.text;
    const tableData = (stage === Stages.DataLoaded) ? this.fetchSampleData() : [];

    return (<Tile>
            {stage === Stages.Loading &&
                <CenteredMessage className="padding-70">
                    <Icon src={LoadingIndicator} alt="no results" className="icon-100" />
                    <Text tag="div" className="bold-message" field={loadingText} />
                </CenteredMessage>}
            {stage === Stages.DataLoaded && <>
                {fields.heading && fields.heading.value &&
                    <InlineBlock width={50}>
                        <H2>
                            <Text field={fields.heading} />
                        </H2>
                    </InlineBlock>}
                {hasRequestQuoteLink &&
                    <InlineBlock width={50} className="right">
                        <Link field={requestQuoteLink} className="primaryButton submit">
                            <span className="icon"></span>{requestQuoteLink.value.text}
                        </Link>
                    </InlineBlock>}
                <div className="margin-20">
                    <NoDataDecorator component={SortableTable} data={tableData} columns={quoteTableColumns} hidePagination />
                </div>
                <InlineBlock className="right margin-20">
                    {hasManageShipmentsLink &&
                        <Link field={manageQuotesLink} className="secondaryButton no-border">
                            <span className="icon"></span>{manageQuotesLink.value.text}
                        </Link>}
                </InlineBlock>
            </>}
            {stage === Stages.NoResults && <CenteredMessage className="padding-40">
                <Icon src={QuoteSummaryIcon} alt="no results" className="icon-100" />
                <Text tag="div" className="bold-message" field={noResultsText} />
                {hasRequestQuoteLink &&
                    <InlineBlock className="margin-20">
                        <Link field={requestQuoteLink} className="primaryButton submit">
                            <span className="icon"></span>{requestQuoteLink.value.text}
                        </Link>
                    </InlineBlock>}
            </CenteredMessage>}
            {stage === Stages.Error && <CenteredMessage>{error}</CenteredMessage>}
        </Tile>);
  }
}

export default QuoteTracking;
