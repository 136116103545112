import styled, { css } from 'styled-components';
import Tooltip from '../../../assets/icons/Tooltip.svg';

const TooltipIcon = styled.img.attrs(() => ({ src: Tooltip }))`
  cursor: pointer;
  
  ${({ $marginLeft }) => $marginLeft && css`
    margin-left: 8px;
  `}
  ${({ $shipmentsFlow }) => $shipmentsFlow && css`
    @media (min-width: 768px) {
      position : relative;
      top : 15px;
      left : 485px;
    }
    
  `}
  ${({ userFlow }) => userFlow && css`
  @media  (min-width: 768px) {
    position : relative;
    top : -15px;
  }
  `}
  ${({ dedicatedClaimFlow }) => dedicatedClaimFlow && css`
  @media  (min-width: 768px) {
    position : absolute;
    top : 745px;
    left : 1040px
  }
  `}
`;
export default TooltipIcon;
