import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { ActionButton } from '../../ReactComponent/Modal/css';
import AlertModal from '../../ReactComponent/Modal/AlertModal';
import { P } from '../../../theme/typographySitecore';
import { CheckmarkIcon, HeaderContainer } from './css';
import { routes } from '../../../utils/constants';

const PasswordResetSuccessModal = ({
  isOpen,
  parentFields
}) => {
  const onClose = () => {
    window.location.href = routes.login;
  };

  const header = () => (
    <HeaderContainer>
      <CheckmarkIcon/>
      <div>{parentFields.passwordResetSuccessModalHeader.value}</div>
    </HeaderContainer>
  );

  const content = () => (
    <P field={parentFields.passwordResetSuccessModalSummary}/>
  );

  const actions = () => (
    <ActionButton className="active" iconRightArrow onClick={onClose}>
      {t('Okay')}
    </ActionButton>
  );

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      content={content}
      actions={actions}
    />
  );
};

PasswordResetSuccessModal.propTypes = {
  isOpen: PropTypes.func,
  parentFields: PropTypes.shape({
    passwordResetSuccessModalHeader: PropTypes.shape({
      value: PropTypes.string
    }),
    passwordResetSuccessModalSummary: PropTypes.string
  })
};
export default PasswordResetSuccessModal;
