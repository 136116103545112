import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import {
  reduxForm,
  change,
  getFormValues,
  Field
} from 'redux-form';
import RenderField from '../ReactComponent/RenderField';
import { H1, H3 } from '../../theme/typography';
import SearchWithFilter from '../ReactComponent/SearchWithFilter';
import PaginationControls from '../ReactComponent/SortableTable/PaginationControls';
import LoadingIndicator from '../../assets/image/LoadingIndicator.gif';
import ResetOrange from '../../assets/icons/ResetOrange.svg';
import {
  formName,
  client,
  SEARCH_QUERY,
  Stages,
  PageSize,
  SortBy,
  SEARCH_QUERY_SORT_BY_CREATEDATE
} from './constants';
import {
  ResultItem,
  SearchContainer,
  CommonPrimaryButton,
  CommonSecondaryButton,
  CenteredMessage,
  Icon,
} from './css';

class SiteSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: Stages.NoTerm,
      keyword: '',
      results: null,
      currentPage: 1,
      totalPages: 0,
      sortBy: SortBy.Relevance
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { sortBy } = this.state;

    dispatch(change(formName, 'sortBy', sortBy));

    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const q = urlParams.get('q');
      const keyword = q ? q.trim() : '';
      if (keyword && keyword.length > 0) {
        let newState = { keyword };
        const page = urlParams.get('page');
        if (page) {
          const currentPage = parseInt(page, 10);
          if (currentPage > 1) {
            newState = {
              ...newState,
              currentPage
            };
          }
        }
        this.setState(newState, () => this.searchContent(this.onSetData, this.onSetError));
        dispatch(change(formName, 'searchByKey', keyword));
      }
    }
  }

  reset = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    this.setState({
      keyword: '',
      currentPage: 1,
      stage: Stages.NoTerm
    });
    dispatch(change(formName, 'searchByKey', ''));
  };

  onSetData = (results) => {
    const hasResults = results && results.items && results.items.length > 0;
    const totalPages = hasResults ? Math.ceil(results.totalCount / PageSize) : 0;
    this.setState({
      results,
      stage: hasResults ? Stages.DataLoaded : Stages.NoResults,
      totalPages
    });
  };

  onSetError = (error) => {
    console.error('SiteSearch::onSetError', error);
    this.setState({ stage: Stages.Error });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.searchContent(this.onSetData, this.onSetError);
  };

  changeHandler = (newvalue) => {
    const keyword = newvalue ? newvalue.trim() : '';
    const { stage } = this.state;
    this.setState({
      keyword: newvalue,
      currentPage: 1,
      stage: keyword ? stage : Stages.NoTerm
    });
  };

  searchContent = (onSetData, onSetError) => {
    const {
      keyword,
      currentPage,
      sortBy
    } = this.state;
    const after = currentPage ? ((currentPage - 1) * PageSize) : 0;
    const sortByStatement = sortBy === SortBy.CreatedDate ? SEARCH_QUERY_SORT_BY_CREATEDATE : '';

    const queryOptions = {
      query: SEARCH_QUERY(sortByStatement),
      variables: {
        keyword,
        skip: `${after}`,
      }
    };

    this.setState({ stage: Stages.Loading });

    client.query(queryOptions)
      .then((response) => {
        if (response && response.data && response.data.drsearch) {
          if (onSetData) {
            onSetData(response.data.drsearch.results);
          }
        } else if (onSetError) {
          const errors = response ? response.errors : null;
          onSetError(errors);
        }
      }).catch((error) => {
        if (onSetError) {
          onSetError(error);
        }
      });
  };

  setCurrentPage = (pageNum) => {
    this.setState({ currentPage: pageNum }, () => this.searchContent(this.onSetData, this.onSetError));
  };

  onSortChange = (newValue) => {
    this.setState({ sortBy: newValue }, () => this.searchContent(this.onSetData, this.onSetError));
  };

  render() {
    const {
      keyword,
      results,
      stage,
      currentPage,
      totalPages
    } = this.state;
    const resultsLength = results && results.items ? results.items.length : 0;

    return <SearchContainer>
      <H1>{t('SearchHeading')}</H1>
      <form onSubmit={this.handleSubmit}>
        <SearchWithFilter
          className="searchbox"
          label={t('SearchUsingKeywords')}
          getSuggestions={this.changeHandler}
        />
        <CommonPrimaryButton
          type="submit"
          onClick={(e) => this.handleSubmit(e)}
        >
          {t('SearchButtonText')}
        </CommonPrimaryButton>
      </form>
      {stage !== Stages.NoTerm && <div className="actionsGroup">
        <div className="sort">
          <H3>{t('SearchSortBy')}</H3>
          <Field
            name="sortBy"
            type="dropdown"
            data={[SortBy.Relevance, SortBy.CreatedDate]}
            component={RenderField}
            onChange={this.onSortChange}
          />
        </div>
        <div className="summary">
          <span>
            {t('SearchSummaryPrefix')}
            &nbsp;
            {resultsLength}
            &nbsp;
            {t('SearchSummaryPostfix')}
          </span>
          :&nbsp;
          <span className="keyword">
            “
            {keyword}
            ”
          </span>
        </div>
        <div className="reset">
          <CommonSecondaryButton
            type="button"
            onClick={(e) => this.reset(e)}
          >
            <Icon className="icon-left" src={ResetOrange} alt="back"/>
            {t('SearchReset')}
          </CommonSecondaryButton>
        </div>
      </div>}
      <div>
        {stage === Stages.Loading &&
          <CenteredMessage className="padding-70">
            <Icon src={LoadingIndicator} alt="no results" className="icon-100"/>
            { // eslint-disable-next-line react/no-unknown-property
              <div tag="div" className="bold-message">
                {t('SearchSearching')}
              </div>
            }
          </CenteredMessage>}
        {stage === Stages.DataLoaded &&
          <>
            {results.items.map((each, index) =>
              each.item && <ResultItem key={index}>
                <span className="title">{each.computedtitle}</span>
                <span className="path">{each.item.url}</span>
                <span className="description">
                                {each.description}
                            </span>
                <span className="viewMoreContainer">
                                <a href={each.item.url} className="viewMore">
                                    {t('View')}
                                    <span className="icon"></span>
                                </a>
                            </span>
              </ResultItem>)}
            {resultsLength && PaginationControls({
              currentPage,
              totalPages,
              setCurrentPageFn: this.setCurrentPage
            })}
          </>}
        {stage === Stages.NoResults && <CenteredMessage className="padding-40">
          <div className="red-message">
            {t('SearchNoResultsHeading')}
          </div>
          <div className="bold-message">
            {t('SearchNoResults')}
          </div>
        </CenteredMessage>}
      </div>
    </SearchContainer>;
  }
}

SiteSearch.propTypes = {
  dispatch: PropTypes.func
};

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
});

SiteSearch = reduxForm({
  form: formName, // a unique identifier for this form
})(SiteSearch);

export default connect(
  mstp,
)(SiteSearch);
