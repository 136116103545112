import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import {
  SectionTitle, Section,
} from './css';
import { anchor } from '../../utils/func';
import { Img } from '../ReactComponent/Image';

const ArticleSectionImage = ({ fields }) => {
  const id = fields.anchor && fields.anchor.value ? anchor(fields.anchor.value) : '';

  return (<Section id={id} className="static-section">
    {fields.heading && fields.heading.value
      && <SectionTitle>
        <Text field={fields.heading}/>
      </SectionTitle>}
    {fields.image && <Img field={fields.image}/>}
  </Section>);
};

export default ArticleSectionImage;
