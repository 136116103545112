import React from 'react';
import { Field, change } from 'redux-form';
import { t } from 'i18next';
import Card from '../../ReactComponent/Card';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import RenderField from '../../ReactComponent/RenderField';
import { AddressBookEntry } from '../../ReactComponent/AddressBookEntry';
import {
  isEmail, isRequired, maxChar50, maxChar6
} from '../../../utils/validator';
import { digitOnly } from '../../../utils/mask';
import {
  PageTitle,
  CategoryTitle,
  DivisionRow,
  PaymentMethod,
  PaymentType,
  AddressBlock,
  UseQuoteOrShipmentNumberContainer,
  PrimaryButton, UseQuoteOrShipmentNumberHeaderContainer, ErrorMessage, ErrorIcon,
} from '../css';
import {
  DeliveryTypes, AddressTypes, Divisions, PaymentMethods, PrefillNumberTypes, BillToMethods, SamedayShipmentType, shipmentPickupServiceTypes
} from '../../../utils/enums';
import { billingAccountTextFormatter, billingAccountToNumber } from '../../../utils/form';
import Tooltip from '../../ReactComponent/Tooltip';
import { addressValidate } from '../index';
import { arrayLengthSafe } from '../../../utils/arrays';
import { routes } from '../../../utils/constants';

const FormName = 'CreateShipmentForm';

const renderServicesSection = (
  servicesSectionHeading,
  division,
  changeServiceType,
) => (
  <>
    <CategoryTitle>
      {servicesSectionHeading}
    </CategoryTitle>
    <Row>
      <Column $tablet={12} $mobile={12}>
        <Field
          name="serviceType"
          type="checkboxes"
          data={shipmentPickupServiceTypes[division]}
          onChange={changeServiceType}
          component={RenderField}
          className="radio-container"
        />
      </Column>
    </Row>
  </>
);

const renderBillingInformation = (paymentMethod, billingAccounts, hasSingularPrepaidAccount, billToMethod, dispatch) => {
  const thirdpartyValueChanged = () => {
    dispatch(change(FormName, 'billTo', null));
    dispatch(change(FormName, 'thirdPartyAccountNumber', null));
  };
  switch (paymentMethod) {
    case PaymentMethods.Collect:
      return <>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <Field
              name="billTo"
              label={t('BillTo')}
              component={RenderField}
              normalize={digitOnly}
              validate={[maxChar6]}
            />
          </Column>
        </Row>
      </>;
    case PaymentMethods.ThirdParty:
      return <>
        <Row>
          <Column $tablet={8} $mobile={12}>
            <Field
              name="billToMethod"
              type="checkboxes"
              data={[
                BillToMethods.pickBillTo,
                BillToMethods.enterBillTo,
              ]}
              onChange={thirdpartyValueChanged}
              validate={[isRequired]}
              component={RenderField}
            />
          </Column>
        </Row>
        {billToMethod && (billToMethod === BillToMethods.pickBillTo
          ? <Column $tablet={6} $mobile={12}>
            <Field
              name="billTo"
              type={hasSingularPrepaidAccount ? 'text' : 'dropdown'}
              label={t('BillTo')}
              data={billingAccounts}
              required
              textField={billingAccountTextFormatter}
              valueField="accountNumber"
              component={RenderField}
              validate={[isRequired]}
              normalize={billingAccountToNumber}
            />
          </Column> : <Row>
            <Column $tablet={6} $mobile={12}>
              <Field
                name="thirdPartyAccountNumber"
                label={t('BillTo')}
                normalize={digitOnly}
                validate={[maxChar6]}
                component={RenderField}
              />
              <AddressBookEntry
                contactType="billing"
                formName="CreateShipmentForm"
                validator={addressValidate}
                isAddressRequired
                emailOptional
              />
            </Column>
            <Column $tablet={6} $mobile={12}>
              <Field
                name="gstNumber"
                label={t('GstNumber')}
                component={RenderField}
              />
            </Column>
          </Row>)}
      </>;
    case PaymentMethods.Prepaid:
    default:
      return <>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <Field
              name="billTo"
              type={hasSingularPrepaidAccount ? 'text' : 'dropdown'}
              label={t('BillTo')}
              data={billingAccounts}
              required
              textField={billingAccountTextFormatter}
              valueField="accountNumber"
              component={RenderField}
              validate={[isRequired]}
              normalize={billingAccountToNumber}
            />
          </Column>
        </Row>
      </>;
  }
};

const Step1 = ({
  step,
  formData = {},
  dispatch,
  loggedIn,
  handlePreviousQuotesLink,
  handleExistingQuoteOrShipment,
  quoteOrShipmentType,
  quoteNumberError,
  parentFields = {},
  billingAccounts = [],
  fromContactId,
  toContactId,
  isLoggedIn,
  addressFieldsRef,
  openContactPermissionModal,
}) => {
  const {
    division,
    paymentMethod,
    billToMethod
  } = formData;
  const changeDivisionToFreight = () => dispatch(change('CreateShipmentForm', 'division', Divisions.Freight.name));
  const changeDivisionToSameday = () => dispatch(change('CreateShipmentForm', 'division', Divisions.Sameday.name));
  const changeDivisionToSCTN = () => dispatch(change('CreateShipmentForm', 'division', Divisions.SCTN.name));

  const hasSingularPrepaidAccount = isLoggedIn &&
    paymentMethod &&
    paymentMethod.name === PaymentMethods.Prepaid &&
    arrayLengthSafe(billingAccounts) === 1;

  if (hasSingularPrepaidAccount) {
    dispatch(change(FormName, 'billTo', billingAccounts[0].accountNumber));
  }

  const paymentMethodChanged = (method) => {
    dispatch(change(FormName, 'billTo', null));
    if (method.name !== PaymentMethods.ThirdParty) {
      dispatch(change(FormName, 'billToMethod', null));
    }
  };
  const changeServiceType = (newServiceType) => {
    switch (newServiceType) {
      case 'ShipUSToCanada':
      case 'RequestPickup':
        window.location.href = `${routes.createPickup}?serviceType=${newServiceType}`;
        break;
      default:
        break;
    }
  };
  if (step === 0) {
    return (
      <>
        <PageTitle>
          {parentFields.pageHeading && parentFields.pageHeading.value}
        </PageTitle>

        {loggedIn &&
          renderServicesSection(
            parentFields.servicesSectionHeading.value,
            division,
            changeServiceType
          )}
        {
          !loggedIn && (
            <>
              <CategoryTitle>
                {t('PickService')}
              </CategoryTitle>
              <DivisionRow>
                <Row>
                  <Column $tablet={4} $mobile={12} onClick={changeDivisionToFreight}>
                    <Card
                      title={parentFields.freightDivisionHeading.value}
                      desc={parentFields.freightDivisionSummary.value}
                      thumb={parentFields.freightDivisionBannerImage.value.src}
                      active={division === Divisions.Freight.name}
                      link="#"
                      linkText="View More Information"
                    />
                  </Column>
                  <Column $tablet={4} $mobile={12} onClick={changeDivisionToSameday}>
                    <Card
                      title={parentFields.samedayDivisionHeading.value}
                      desc={parentFields.samedayDivisionSummary.value}
                      thumb={parentFields.samedayDivisionBannerImage.value.src}
                      active={division === Divisions.Sameday.name}
                      link="#"
                      linkText="View More Information"
                    />
                  </Column>
                  <Column $tablet={4} $mobile={12} onClick={changeDivisionToSCTN}>
                    <Card
                      title={parentFields.SCTNDivisionHeading.value}
                      desc={parentFields.SCTNDivisionSummary.value}
                      thumb={parentFields.SCTNDivisionBannerImage.value.src}
                      active={division === Divisions.SCTN.name}
                      link="#"
                      linkText="View More Information"
                    />
                  </Column>
                </Row>
              </DivisionRow>
            </>
          )
        }

        <Row>
          <Column $mobile={12}>
            {division === Divisions.Sameday.name && (
              <>
                <CategoryTitle>
                  {t('Select a Service')}
                </CategoryTitle>
                <PaymentType $column={3}>
                  <Field
                    name="deliveryType"
                    type="checkboxes"
                    data={[
                      {
                        name: DeliveryTypes.Commercial,
                        desc: 'CommercialServiceDesc'
                      },
                      {
                        name: DeliveryTypes.Residential,
                        desc: 'ResidentialServiceDesc'
                      },
                    ]}
                    validate={[isRequired]}
                    component={RenderField}
                  />
                </PaymentType>
                <Row>
                  <Column $tablet={11} $mobile={12}>
                    <Row>
                      <Column $tablet={6} $mobile={12}>
                        <Field
                          name="shipmentType"
                          type="dropdown"
                          label={t('Select Shipment Type')}
                          data={Object.values(SamedayShipmentType)}
                          required
                          component={RenderField}
                          validate={[isRequired]}
                        />
                      </Column>
                    </Row>
                  </Column>
                </Row>
              </>
            )}
          </Column>
        </Row>

        {
          // loggedIn && (
          <UseQuoteOrShipmentNumberContainer>

            <UseQuoteOrShipmentNumberHeaderContainer>
              <h3> {parentFields.UseQuoteOrShipmentNumberH3 && parentFields.UseQuoteOrShipmentNumberH3.value}</h3>
              <Tooltip tip={`${t('TipCreateShipmentUseQuoteNumber')} ${t('TipCreateShipmentUseShipmentNumber')}`} marginLeft/>
            </UseQuoteOrShipmentNumberHeaderContainer>

            <div className="quoteOrShipmentRadios">
              <Field
                name="numberTypeSelected"
                type="checkboxes"
                data={[
                  {
                    name: 'None',
                    value: PrefillNumberTypes.none
                  },
                  {
                    name: 'Use Quote Number',
                    value: PrefillNumberTypes.quote
                  },
                  {
                    name: 'Use Shipment Number',
                    value: PrefillNumberTypes.shipment
                  },
                ]}
                component={RenderField}
              />
            </div>

            {
              quoteOrShipmentType !== PrefillNumberTypes.none && (
                <div className="row">
                  <div className="col-md-6 numberTypeInput">
                    <Field
                      name="quoteOrShipmentNumber"
                      type="text"
                      component={RenderField}
                      label={t('QuoteOrShipmentNumber')}
                    />
                    {quoteNumberError && (
                      <ErrorMessage>
                        <ErrorIcon/>
                        {quoteNumberError}
                      </ErrorMessage>
                    )}
                  </div>
                  <div className="col-md-6">
                    <PrimaryButton
                      onClick={handleExistingQuoteOrShipment}
                      type="button"
                      className="active ml-0">
                      {t('Apply')}
                      <span className="icon"/>
                    </PrimaryButton>
                  </div>
                  <div className="col-md-6 previousQuotesOrShipmentsLink">
                    <a className="previousQuotesOrShipmentsLink" onClick={handlePreviousQuotesLink}>
                      {`${t('LookupPrevious')} ${quoteOrShipmentType === PrefillNumberTypes.quote ? t('Quotes') : t('Shipments')}`}
                    </a>
                  </div>
                </div>
              )
            }
          </UseQuoteOrShipmentNumberContainer>
          // )
        }

        <Row ref={addressFieldsRef}>
          <AddressBlock className="col-md-6">
            <CategoryTitle>
              {t('ShipperDetails')}
            </CategoryTitle>
            <AddressBookEntry
              contactType={AddressTypes.SHIPPER}
              formName="CreateShipmentForm"
              defaultContactId={fromContactId}
              validator={addressValidate}
              isAddressRequired
              openContactPermissionModal={openContactPermissionModal}
            />
          </AddressBlock>
          <AddressBlock className="col-md-6">
            <CategoryTitle>
              {t('ConsigneeDetails')}
            </CategoryTitle>
            <AddressBookEntry
              contactType={AddressTypes.CONSIGNEE}
              formName="CreateShipmentForm"
              defaultContactId={toContactId}
              validator={addressValidate}
              isAddressRequired
              openContactPermissionModal={openContactPermissionModal}
            />
            <Row>
              <Column>
                <Field
                  name="sendConsigneeInfo"
                  type="singleCheckbox"
                  label={t('EmailConsigneeShipmentSummary')}
                  component={RenderField}
                />
              </Column>
            </Row>
          </AddressBlock>
        </Row>

        <PaymentMethod>
          <CategoryTitle>
            {t('PaymentMethod')}
          </CategoryTitle>
          <PaymentType $column={3}>
            <Field
              name="paymentMethod"
              type="checkboxes"
              onChange={paymentMethodChanged}
              data={[
                {
                  name: PaymentMethods.Prepaid,
                  desc: 'PrepaidDesc'
                },
                {
                  name: PaymentMethods.Collect,
                  desc: 'CollectDesc'
                },
                {
                  name: PaymentMethods.ThirdParty,
                  desc: 'ThirdPartyDesc'
                },
              ]}
              component={RenderField}
              validate={[isRequired]}
            />
          </PaymentType>
          <Row>
            <Column>
              {loggedIn && paymentMethod &&
                <>
                  <CategoryTitle>{parentFields.categoryTitleStep1 && parentFields.categoryTitleStep1.value}</CategoryTitle>
                  {renderBillingInformation(paymentMethod.name, billingAccounts, hasSingularPrepaidAccount, billToMethod, dispatch)}
                </>}
            </Column>
          </Row>
          <Row>
            <Column $tablet={6} $mobile={12}>
              <CategoryTitle $noJustifyContent>
                {parentFields.categoryTitle2Step1 && parentFields.categoryTitle2Step1.value}
                <Tooltip tip={t('TipEmailConfirmation')} marginLeft/>
              </CategoryTitle>
              <Field
                name="quoteDest"
                component={RenderField}
                label={t('ContactEmail')}
                validate={[isEmail, maxChar50, isRequired]}
                required
              />
            </Column>
          </Row>
        </PaymentMethod>
      </>
    );
  }

  return null;
};

export default Step1;
