import styled from 'styled-components';
import { H1 } from '../../theme/typographySitecore';
import Row from '../ReactComponent/Row';
import { MediaQuery } from '../../theme/style-helpers';
import Column from '../ReactComponent/Column';

export const HeaderContainer = styled(Row)`
  margin: 45px 0;
`;

export const HeaderTitle = styled(H1)`
  margin-bottom: 3rem;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 72px;
  
  ${MediaQuery.medium`
    flex-direction: row;    
  `}
`;

export const TextContainer = styled(Column)`
  padding: 0 !important;
  margin-bottom: 36px;
  
  ${MediaQuery.medium`
    flex-direction: row;
    margin-bottom: unset;
  `}
`;

export const ButtonContainer = styled(Column)`
  display: flex;
  justify-content: flex-end;
  padding: 0 !important;
`;
