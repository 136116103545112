/* eslint-disable */
import React from 'react';
import axios from 'axios';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { reduxForm, Field, change, getFormValues } from 'redux-form';
import { MDBDropdown } from 'mdbreact';
import Modal from 'react-modal';
import { endpoints, routes } from '../../utils/constants';
import RenderField from '../ReactComponent/RenderField';
import SortableTable from '../ReactComponent/SortableTable';
import SideBar from '../ReactComponent/SideBar';
import { H2 } from '../../theme/typography';
import FilterOrange_v2 from '../../assets/icons/FilterOrange_v2.svg';
import { isRequired, dateRangeValidate } from '../../utils/validator';
import Actions from '../../assets/icons/Actions.svg';
import DownloadWhite from '../../assets/icons/DownloadWhite.svg';
import DownloadGrey from '../../assets/icons/DownloadGrey.svg';
import PrintWhite from '../../assets/icons/PrintWhite.svg';
import PrintGrey from '../../assets/icons/PrintGrey.svg';
import CancelWhite from '../../assets/icons/CancelWhite.svg';
import ResetOrange from '../../assets/icons/ResetOrange.svg';
import ModalContent from './Modal';
import {
  FilterContainer,
  FilterIcon,
  CategoryTabs,
  Category,
  PageDescription,
  CategoryTitle,
  PageTitle,
  Close,
  ModalBody,
  Checkboxes,
  DateField,
} from './css';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import SecondaryButton from '../ReactComponent/SecondaryButton';
import putReduxAllShipments from './actions';
import SearchWithFilter from '../ReactComponent/SearchWithFilter';
import CustomizeTableView from '../ReactComponent/CustomizeTableView';
import { homeUrlNoTrailingSlash, formatDisplayDate, formatDate, downloadFile, openFileInNewTab } from '../../utils/func';
import Column from '../ReactComponent/Column';
import Row from '../ReactComponent/Row';
import { CategoriesStatuses, PickupCategories } from '../../components/ViewUserDashboard/constants';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import { Icon as CommonIcon } from '../ReactComponent/Icon';
import { getPickupBol, apiGetPickups } from '../../services/pickupService';
import { DownloadFileNames, downloadReport, formatPickupsReport } from '../../utils/reports';
import DownloadReportModal from '../ReactComponent/DownloadReportModal';
import NoDataDecorator from '../ReactComponent/SortableTable/NoDataDecorator';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';

const formName = 'ManagePickupsForm';

const Dropdown = ({
  hasBOL,
  openBOL,
  downloadBOL,
  cancelFn,
  shareFn
}) => (
  <MDBDropdown className="dropdown">
    <MDBDropdownToggle className="border-0">
      <CommonIcon iconSrc={Actions}/>
    </MDBDropdownToggle>
    <StyledMDBDropdownMenu className="text-white bg-dark" basic>
      <DropdownItem disabled={!hasBOL} onClick={() => downloadBOL()}>
        <DropdownItemIcon iconSrc={hasBOL ? DownloadWhite : DownloadGrey}/>
        {t('DownloadPickupDocuments')}
      </DropdownItem>
      <DropdownItem disabled={!hasBOL} onClick={() => openBOL()}>
        <DropdownItemIcon iconSrc={hasBOL ? PrintWhite : PrintGrey}/>
        {t('PrintSummary')}
      </DropdownItem>
      {/* <DropdownItem onClick={() => shareFn()}>
        <DropdownItemIcon iconSrc={EmailWhite} />
        {t('ShareByEmail')}
      </DropdownItem> */}
      <DropdownItem onClick={() => cancelFn()}>
        <DropdownItemIcon iconSrc={CancelWhite}/>
        {t('Cancel')}
      </DropdownItem>
    </StyledMDBDropdownMenu>
  </MDBDropdown>
);

const columns = [
  {
    id: 1,
    label: '',
    field: 'actions',
    sort: false,
    selected: true,
    default: true,
  },
  {
    id: 2,
    label: 'OrderNumber',
    field: 'orderNumber',
    sort: true,
    selected: true,
    default: true,
    disabled: true
  },
  {
    id: 3,
    label: 'CreatedOn',
    field: 'createdOn',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 4,
    label: 'PickupDate',
    field: 'pickupDate',
    sort: true,
    selected: true,
    default: true,
  },
  {
    id: 5,
    label: 'PickupAddress',
    field: 'pickupAddress',
    sort: true,
    selected: true,
    default: true
  },
  {
    id: 6,
    label: 'Status',
    field: 'status',
    sort: true,
    selected: true,
    default: true,
  },
];

const tabFilters = {
  'default': () => true,
  1: ({ status }) => CategoriesStatuses[PickupCategories.PendingPickups].includes(status),
  2: ({ status }) => CategoriesStatuses[PickupCategories.Completed].includes(status),
};

const defaultColumns = columns;

const NUMBER_OF_DAYS = 60;

const initialValues = {
  filterFromDate: formatDate(new Date((new Date()).getTime() - (NUMBER_OF_DAYS * 24 * 60 * 60 * 1000))),
  filterToDate: formatDate(new Date())
};

class ManagePickups extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filterOpen: false,
      customizeOpen: false,
      dataPerPage: 10,
      activeTab: 0,
      statusList: [],
      initialStatusList: [],
      // eslint-disable-next-line react/no-unused-state
      totalPages: [],
      prevFromDate: initialValues.filterFromDate,
      prevToDate: initialValues.filterToDate,
      // eslint-disable-next-line react/destructuring-assignment
      prevAccountNumbers: this.props.accountNumbers,
      columns: defaultColumns,
      displayCustomizeTableView: false,
      modalAction: null,
      modalIsOpen: false,
      modalEntityId: null,
      downloadOpen: false,
      statusFilter: () => true,
      searchValue: ''
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      accountNumbers
    } = this.props;
    dispatch(change(formName, 'filterAccountNumbers', accountNumbers));
    setTimeout(() => { this.fetchAllShipments(true); }, 200);
  }

  cancel(entityId) {
    this.openModal(entityId, 'cancel');
  }

  share(entityId) {
    this.openModal(entityId, 'share');
  }

  openModal(shipmentNumber, modalAction) {
    this.setState({ modalAction: modalAction });
    this.setState({ modalIsOpen: true });
    this.setState({ modalEntityId: shipmentNumber });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ modalEntityId: null });
    this.setState({ modalAction: null });
  }

  postShare(shipmentNumber) {
    this.props.valid && this.closeModal();
  }

  fetchAllShipments = async (init, filterFrom, filterTo, filterAccountNumbers) => {
    const {
      division,
      accountNumbers,
      userId
    } = this.props;
    axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.pickups}`, {
      params: {
        // numberOfDaysFromToday: 60,
        division: division,
        fromDate: filterFrom ? filterFrom : initialValues.filterFromDate,
        toDate: filterTo ? filterTo : initialValues.filterToDate
      },
      headers: {
        accountNumbers: filterAccountNumbers ? filterAccountNumbers : accountNumbers
      }
    }).then(res => {
      if (res.status === 200) {
        const { putReduxAllShipments } = this.props;
        const convertedData = this.convertDataFormat(res.data);
        putReduxAllShipments(convertedData);
        this.state.activeTab !== 3 && this.setState({ data: convertedData }, () => this.getTotalPages());
        init && this.getInitialStatusList();
        !init && this.applyFrontFilters();
      }
    });

    this.setState({ prevFromDate: filterFrom ? filterFrom : initialValues.filterFromDate });
    this.setState({ prevToDate: filterTo ? filterTo : initialValues.filterToDate });
    this.setState({ prevAccountNumbers: filterAccountNumbers ? filterAccountNumbers : accountNumbers });
  };

  getInitialStatusList = () => {
    const {
      allShipments,
      dispatch
    } = this.props;
    const uniqueStatus = allShipments.map(s => { return s.status; }).filter((value, index, self) => { return (self.indexOf(value) === index); });
    this.setState({
      statusList: uniqueStatus,
      initialStatusList: uniqueStatus
    });
    dispatch(change(formName, 'filterPickupStatus', uniqueStatus));
  };

  getStatusList = () => {
    const {
      allShipments,
      dispatch
    } = this.props;
    const uniqueStatus = allShipments.map(s => { return s.status; }).filter((value, index, self) => { return (self.indexOf(value) === index); });
    this.setState({ statusList: uniqueStatus });
    dispatch(change(formName, 'filterPickupStatus', uniqueStatus));
  };

  openBOL = ({
    division,
    orderNumber
  }) => {
    getPickupBol({
      division: division,
      orderNumber: orderNumber
    })
      .then(res => {
        if (res.status === 200) {
          openFileInNewTab({
            dataAsBase64: res.data.fileDataAsBase64,
            mimeType: res.data.mimeType,
            fileName: res.data.fileName
          });
        }
      });
  };

  downloadBOL = ({
    division,
    orderNumber
  }) => {
    getPickupBol({
      division: division,
      orderNumber: orderNumber
    })
      .then(res => {
        if (res.status === 200) {
          downloadFile({
            dataAsBase64: res.data.fileDataAsBase64,
            mimeType: res.data.mimeType,
            filename: res.data.fileName
          });
        }
      });
  };

  convertDataFormat = data => {
    const { division } = this.props;
    return data.map(each => ({
      orderNumber: each.orderNumber,
      createdOn: formatDisplayDate(each.createdOn),
      pickupDate: formatDisplayDate(each.pickupDate),
      pickupAddress: each.pickupAddress,
      status: each.status,
      actions: <Dropdown
        status={each.status}
        hasBOL={!!each.deliveryAddress && !!each.pickupAddress}
        openBOL={() => this.openBOL({
          division: division,
          orderNumber: each.orderNumber
        })}
        downloadBOL={() => this.downloadBOL({
          division: division,
          orderNumber: each.orderNumber
        })}
        entityId={each.orderNumber}
        division={division}
        cancelFn={() => this.cancel(each.orderNumber)}
        shareFn={() => this.share(each.orderNumber)}
      />
    }));
  };

  getTotalPages = () => {
    const {
      dataPerPage,
      data
    } = this.state;
    const totalPages = [];
    for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
      totalPages.push(i);
    }
    this.setState({ totalPages });
  };

  getSuggestions = value => {
    const searchValue = value.toLowerCase().trim();
    this.setState({ searchValue }, this.filterList);
  };

  filterRowByValue = value =>
    row => columns.some(
      ({ field }) => typeof row[field] === 'string' && row[field].toLowerCase().indexOf(value) !== -1
    );

  changeTab = tabIndex => {
    const statusFilter = tabFilters[tabIndex] ? tabFilters[tabIndex] : tabFilters['default'];
    this.setState({
      statusFilter,
      activeTab: tabIndex
    }, this.filterList);
  };

  applyFrontFilters = () => {
    const {
      allShipments,
      formData: { filterPickupStatus }
    } = this.props;
    let filteredData = [];
    filterPickupStatus && filterPickupStatus.forEach(status => {
      filteredData = filteredData.concat(allShipments.filter(s => s.status === status));
      filteredData = filteredData.filter((value, index, self) => { return (self.indexOf(value) === index); });
    });
    this.setState({ data: filteredData }, () => this.getTotalPages());
  };

  filterList = () => {
    const {
      statusFilter,
      searchValue,
      activeTab
    } = this.state;
    const {
      allShipments,
      allDrafts
    } = this.props;
    const filterBySearchInput = this.filterRowByValue(searchValue);
    const data = (activeTab === 3 ? allDrafts : allShipments)
      .filter(statusFilter)
      .filter(filterBySearchInput);
    this.setState({ data });
    this.getTotalPages();
  };

  handleSubmitFilter = () => {
    const {
      dispatch,
      formData: {
        filterFromDate,
        filterToDate,
        filterAccountNumbers
      }
    } = this.props;
    const {
      activeTab,
      prevFromDate,
      prevToDate,
      prevAccountNumbers
    } = this.state;
    dispatch(change(formName, 'searchByKey', ''));
    if (activeTab === 3 || filterFromDate !== prevFromDate || filterToDate !== prevToDate || filterAccountNumbers !== prevAccountNumbers) {
      this.fetchAllShipments(false, filterFromDate, filterToDate, filterAccountNumbers);
    } else {
      this.applyFrontFilters();
    }
    this.toggleFilter();
  };

  resetFilters = () => {
    // dispatch(change(formName,'filterShipmentType',null));
    const { dispatch } = this.props;
    dispatch(change(formName, 'searchByKey', ''));
    dispatch(change(formName, 'filterPickupStatus', this.state.initialStatusList));
    dispatch(change(formName, 'filterFromDate', initialValues.filterFromDate));
    dispatch(change(formName, 'filterToDate', initialValues.filterToDate));
    dispatch(change(formName, 'filterAccountNumbers', this.props.accountNumbers));

    this.fetchAllShipments(false, initialValues.filterFromDate, initialValues.filterToDate, this.props.accountNumbers);
  };

  toggleFilter = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    const { filterOpen } = this.state;
    this.setState({ filterOpen: !filterOpen });
  };

  updateTable = (newColumns) => {
    this.setState({ columns: newColumns });
  };

  toggleCustomizeTableView = () => {
    this.setState(prevState => ({ displayCustomizeTableView: !prevState.displayCustomizeTableView }));
  };

  handleCreateShipment = (e) => {
    e.preventDefault();
    const createShipmentUrl = `${homeUrlNoTrailingSlash()}${routes.createShipment}`;
    window.open(createShipmentUrl);
  };

  renderHeader = ({
    heading,
    description
  }) => {
    return (
      <Row style={{ margin: '45px 0' }}>
        <Column $tablet={8} $mobile={12}>
          <div>
            <H2>{heading}</H2>
            <PageDescription>{description}</PageDescription>
          </div>
        </Column>
        <Column $tablet={4} $mobile={12} style={{ textAlign: 'end' }}>
          <PrimaryButton className="active" iconRightArrow style={{ margin: '16px 0' }} onClick={() => window.location.href = routes.createPickup}>
            {t('RequestNewPickup')}
          </PrimaryButton>
          <SecondaryButton className="active" iconRightArrow onClick={() => this.handleDownloadReport()}>
            {t('DownloadReport')}
          </SecondaryButton>
        </Column>
      </Row>
    );
  };

  handleFilterFromDate = (date) => {
    const { dispatch } = this.props;
    const updatedFilterToDate = formatDate(new Date(date).getTime() + (NUMBER_OF_DAYS * 24 * 60 * 60 * 1000));
    dispatch(change(formName, 'filterToDate', updatedFilterToDate));

  };

  renderFilterSideBar = ({
    activeTab,
    statusList,
    accountNumbers,
    dispatchFn
  }) => {
    return (
      <SideBar close={this.toggleFilter}>
        <form>
          <PageTitle>{t('ApplyFilters')}</PageTitle>
          {activeTab === 0 &&
            (
              <>
                <CategoryTitle>{t('FilterByPickupStatus')}</CategoryTitle>
                <Checkboxes
                  name="filterPickupStatus"
                  type="checkboxes"
                  component={RenderField}
                  multiple
                  data={statusList}
                />
              </>)}
          <CategoryTitle>{t('FilterByDateCreated')}</CategoryTitle>
          <DateField
            name="filterFromDate"
            type="date"
            component={RenderField}
            className="col"
            label={t('FromDate')}
            validate={[isRequired, dateRangeValidate]}
            onChange={(date) => this.handleFilterFromDate(date)}
          />
          <DateField
            name="filterToDate"
            type="date"
            component={RenderField}
            className="col"
            label={t('ToDate')}
            validate={[isRequired, dateRangeValidate]}
          />
          <CategoryTitle>{t('FilterByBillingAccount')}</CategoryTitle>
          <Checkboxes
            name="filterAccountNumbers"
            type="checkboxes"
            component={RenderField}
            multiple
            data={accountNumbers}
          />

          {/* --- Buttons --- */}
          <SecondaryButton
            type="button"
            iconSrc={ResetOrange}
            mediumWidth
            style={{
              marginBottom: '8px',
              marginRight: '8px'
            }}
            onClick={() => this.resetFilters()}>
            {t('ResetFilters')}
          </SecondaryButton>
          <PrimaryButton
            iconRightArrow
            mediumWidth
            className="active"
            onClick={() => this.handleSubmitFilter()}>
            {t('ApplyFilters')}
          </PrimaryButton>
        </form>
      </SideBar>
    );
  };

  handleDownloadReport = () => {
    this.setState({ downloadOpen: true });
  };

  closeDownloadReport = () => {
    this.setState({ downloadOpen: false });
  };

  handleDownloadSubmit = async ({
    filterFromDate,
    filterToDate,
    filterAccountNumbers
  }) => {
    const { division } = this.props;
    await apiGetPickups(division, filterAccountNumbers, filterFromDate, filterToDate).then(res => {
      if (res.status === 200) {
        downloadReport(DownloadFileNames.PickupsReport, formatPickupsReport, res.data);
      }
    });
  };

  render() {
    const {
      rendering: {
        fields: {
          heading,
          description
        }
      },
      accountNumbers,
      dispatch,
      valid
    } = this.props;
    const {
      filterOpen,
      customizeOpen,
      activeTab,
      data,
      statusList,
      modalEntityId,
      modalAction,
      modalIsOpen,
      downloadOpen
    } = this.state;

    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <Close onClick={this.closeModal}/>
          <ModalBody className="justify-content-center">
            <ModalContent
              closeModal={this.closeModal}
              modalAction={modalAction}
              entityId={modalEntityId}
              postShare={e => {
                e.preventDefault();
                this.postShare(modalEntityId);
              }}
              valid={valid}/>
          </ModalBody>
        </Modal>
        {downloadOpen && <DownloadReportModal
          onClose={this.closeDownloadReport}
          onDownload={this.handleDownloadSubmit}
          title={t('DownloadPickupsReportTitle')}
          description={t('DownloadPickupsReportDescription')}
          accountNumbers={accountNumbers}
        />}

        {filterOpen && this.renderFilterSideBar({
          activeTab,
          statusList,
          accountNumbers,
          dispatchFn: dispatch
        })}

        {customizeOpen && <SideBar>
          test
        </SideBar>}

        {this.renderHeader({
          heading: heading.value,
          description: description.value
        })}

        <FilterContainer>
          <CategoryTabs className="row">
            <Category className={`col ${activeTab === 0 && 'active'}`} onClick={() => this.changeTab(0)}>{t('AllPickups')}</Category>
            <Category className={`col ${activeTab === 1 && 'active'}`} onClick={() => this.changeTab(1)}>{t('PendingPickups')}</Category>
            <Category className={`col ${activeTab === 2 && 'active'}`} onClick={() => this.changeTab(2)}>{t('Completed')}</Category>
          </CategoryTabs>
          <div className="row justify-content-md-center">
            <div className="col-md-9">
              <div className="col">
                <SearchWithFilter label={t('SearchPickupText')} getSuggestions={this.getSuggestions}/>
                <div className="row justify-content-between">
                  <FilterIcon className="col-md-3" onClick={this.toggleFilter}>
                    <CommonIcon iconSrc={FilterOrange_v2}/>
                    {t('ApplyFilters')}
                  </FilterIcon>
                </div>
              </div>
            </div>
          </div>
        </FilterContainer>

        <NoDataDecorator component={SortableTable} data={data} columns={this.state.columns}/>
        {this.state.displayCustomizeTableView && (
          <CustomizeTableView columns={columns} updateTable={this.updateTable} hideView={this.toggleCustomizeTableView}/>
        )}
      </>
    );
  }
}

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  division: state.profile.division,
  accountNumbers: state.profile.accountNumbers,
  userId: state.profile.userId,
  allShipments: state.user.allShipments,
});

const mdtp = dispatch => ({
  putReduxAllShipments: allShipments => dispatch(putReduxAllShipments(allShipments)),
});

ManagePickups = reduxForm({
  form: formName,
  initialValues: initialValues,
})(ManagePickups);

export default connect(mstp, mdtp)(ManagePickups);
