import { Field } from 'redux-form';
import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import PrimButton from '../ReactComponent/PrimaryButton';
import { P } from '../../theme/typographySitecore';

export const CommonPrimaryButton = styled(PrimButton)`  
  padding: 16px 56px;
  margin-bottom: 10px; 
  
  ${MediaQuery.medium`
    width: auto;
    margin-left: 16px;
    margin-bottom: 0;
  `}
`;

export const PrimaryButton = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 16px 56px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    order: 0;
    height: 52px;

    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        margin-bottom: 0;
        order: 1;
        top: -45px;
    `}

    &.submit, &.active {
        background-color: ${colors.BLACK};

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};
        }
    }
`;

export const SecondaryButton = styled.button`
    background-color: ${colors.WHITE};
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    padding: 16px 56px;
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    margin-left: 0;
    width: 100%;
    order: 1;
    cursor: pointer;
    height: 52px;

    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        order: 0;
    `}

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
`;

export const TitleGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 45px 0;
`;

export const Icon = styled.img`
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    width: 16px;
    height: 20px;
`;
export const FilterContainer = styled.div`
    width: 100%;
    background: ${colors.WHITE};
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 40px;
`;

export const FilterIcon = styled.div`
    font-size: 16px;
    line-height: 20px;
    margin-top: -28px;
    cursor: pointer;
`;

export const CategoryTabs = styled.div`
    margin-top: 76px;
    margin-bottom: 76px;
`;

export const CategoryTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.04em;
`;

export const Category = styled.div`
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin: 0 8px;
    min-height: 49px;
    vertical-align: middle;
    border-bottom: ${colors.COOL_GREY} 4px solid;
    cursor: pointer;
    &.active {
        border-color: ${colors.BRAND_ORANGE};
    }
`;

export const PageDescription = styled(P)`
    font-size: 18px;
    line-height: 24px;
`;

export const PageTitle = styled.div`
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;
`;

export const SmallDesc = styled.div`
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 18px;
`;

export const ErrorIcon = styled.img`
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    height: 38px;
    display: block;
    width: 100%;
`;

export const ModalTitle = styled.div`
    font-size: 2.5rem;
    padding-top: 34px;
    padding-bottom: 25px;
`;

export const Close = styled.div`
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
    &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #333;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;

export const ModalBody = styled.div`
    padding: 50px;
    font-size: 1.5rem;
    text-align: center;
`;

export const ButtonDiv = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 0;
`;

export const DateField = styled(Field)`
    margin-bottom: 10px;
    padding-bottom: 20px;
`;

export const StyledForm = styled.form`
    ${MediaQuery.medium`
        width:auto;
    `}
`;
