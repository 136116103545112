import axios from 'axios';
import { t } from 'i18next';
import { saveAs } from 'file-saver';
import { isValid, lightFormat } from 'date-fns';
import { endpoints } from './constants';
import {
  UnitsOfMeasure,
  Languages,
  CountryCodes,
  CountryNames,
  Locales,
  AMPM,
} from './enums';
import readCookie from './cookies';
import { isNullOrWhiteSpace } from './strings';
import { getCompaniesList, getCompanyInfo } from '../services/companies';
import { setAuthContent } from '../actions/accounts';

export const getMonthDayYear = (ddmmyear) => {
  if (!ddmmyear) {
    return 'N/A';
  }
  if (typeof ddmmyear !== 'string') {
    ddmmyear = `${ddmmyear}`;
  }
  // TODO:  getMonthDayYear fn is how the front-end will show the date
  const hasHour = ddmmyear.split('T')[1];
  const time = new Date(`${ddmmyear}${!hasHour ? 'T00:00:00' : ''}`);
  const year = time.getFullYear();
  const month = time.getMonth() + 1;
  const day = time.getDate();
  return (month && day && year === 1) ? '' : `${month}/${day}/${year}`;
};

export const formatDate = (date) => {
  // TODO: formatDate fn is for sending the data to the backend
  const d = new Date(date);
  const year = d.getFullYear();
  let month = `${(d.getMonth() + 1)}`;
  let day = `${d.getDate()}`;

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export const formatDisplayDate = (date) => {
  const result = isValid(new Date(date));
  if (result) {
    return lightFormat(new Date(date), 'MM-dd-yyyy');
  }
  return '';
};

export const getAddress = (address) => {
  if (!address) {
    return null;
  }

  const addressSubStrings = address.split(',');
  const streetAddress = addressSubStrings.splice(0, addressSubStrings.length - 3);
  const string = streetAddress.join(',');
  return string;
};

export const getZip = (address) => {
  if (!address) {
    return null;
  }

  const addressSubStrings = address.split(',');
  let string = addressSubStrings[addressSubStrings.length - 2].trim(); // TODO: change when switching back to Canada Post
  if (string.includes(' ')) {
    string = string.substring(string.indexOf(' ')).replace(/\s/g, '');
    return string;
  }
  return undefined;
};

export const getCity = (address) => {
  if (!address) {
    return null;
  }

  const addressSubStrings = address.split(',');
  return addressSubStrings[addressSubStrings.length - 3].trim();
};

export const getState = (address) => {
  if (!address) {
    return null;
  }

  const addressSubStrings = address.split(',');
  let string = addressSubStrings[addressSubStrings.length - 2].trim(); // TODO: change when switching back to Canada Post
  string = string.includes(' ') ? string.substring(0, string.indexOf(' ')) : string;
  return string;
};

export const getCountry = (address) => {
  if (!address) {
    return null;
  }

  const addressSubStrings = address.split(',');
  return addressSubStrings[addressSubStrings.length - 1]
    .trim()
    .substring(0, 1)
    .toUpperCase() === 'U'
    ? CountryCodes.US
    : CountryCodes.CANADA; // TODO: switching back to canada post, need to reformat
};

export const isAmericanAddress = (address) => {
  const countryCode = address.split(' ').pop().toUpperCase();
  return countryCode === CountryNames.US || countryCode === CountryCodes.US;
};

export const isCanadianAddress = (address) => {
  const countryCode = address.split(' ').pop().toUpperCase();
  return (
    countryCode === CountryNames.CANADA || countryCode === CountryCodes.CANADA
  );
};

export const validatePostal = async (postalCode, division) => axios
  .get(
    `${t('ApiEndPoint')}${t('AddressApiVirtualFolder')}${endpoints.address.addressValidate}/${division}`, {
      params: {
        // city: getCity(address),
        // provinceCode : getState(address),
        postalCode: postalCode.split(' ').join('')
      }
    })
  .then((res) => {
    if (res.status === 200) {
      if (res.data.length < 1) {
        return t('Could not find Postal/Zip Code in database');
      }

      return res.data;
    }
    return t('Could not find Postal/Zip Code in database');
  })
  .catch(() => t('Invalid Postal/Zip Code'));

export const getTotalWeight = (list = []) =>
  list
    .map((item) => item.itemWeight)
    .reduce((accum, curr) => Number(accum) + Number(curr || 0), 0);

const InchToFeetConversionConstant = 12;
const CentimeterToFeetConversionConstant = 30.48;
const MeterToFeetConversionConstant = 0.3048;
const DensityImperialToMetricConversionConstant = 2.20462;

const convertVolumeDimensionInchToFeet = (val) =>
  Number(val) / InchToFeetConversionConstant;
const convertVolumeDimensionCentimeterToFeet = (val) =>
  Number(val) / CentimeterToFeetConversionConstant;
const convertVolumeDimensionMetersToFeet = (val) =>
  Number(val) / MeterToFeetConversionConstant;
const convertVolumeDimensionFeetToFeet = (val) => Number(val);

const getVolumeFromInchToFeet = (curr) =>
  convertVolumeDimensionInchToFeet(curr.itemHeight) *
  convertVolumeDimensionInchToFeet(curr.itemWidth) *
  convertVolumeDimensionInchToFeet(curr.itemLength);

const getVolumeFromCentimeterToFeet = (curr) =>
  convertVolumeDimensionCentimeterToFeet(curr.itemHeight) *
  convertVolumeDimensionCentimeterToFeet(curr.itemWidth) *
  convertVolumeDimensionCentimeterToFeet(curr.itemLength);

const getVolumeFromMeterToFeet = (curr) =>
  convertVolumeDimensionMetersToFeet(curr.itemHeight) *
  convertVolumeDimensionMetersToFeet(curr.itemWidth) *
  convertVolumeDimensionMetersToFeet(curr.itemLength);

const getVolumeFromFeetToFeet = (curr) =>
  convertVolumeDimensionFeetToFeet(curr.itemHeight) *
  convertVolumeDimensionFeetToFeet(curr.itemWidth) *
  convertVolumeDimensionFeetToFeet(curr.itemLength);

const getTotalVolumeFromInchToFeet = (list = []) =>
  list.reduce((accum, curr) => Number(accum) + getVolumeFromInchToFeet(curr), 0);
const getTotalVolumeFromCentimeterToFeet = (list = []) =>
  list.reduce((accum, curr) => Number(accum) + getVolumeFromCentimeterToFeet(curr), 0);
const getTotalVolumeFromMeterToFeet = (list = []) =>
  list.reduce((accum, curr) => Number(accum) + getVolumeFromMeterToFeet(curr), 0);
const getTotalVolumeFromFeetToFeet = (list = []) =>
  list.reduce((accum, curr) => Number(accum) + getVolumeFromFeetToFeet(curr), 0);

const getTotalDensityFromInchToFeet = (list = []) =>
  getTotalWeight(list) / getTotalVolumeFromInchToFeet(list);
const getTotalDensityCentimeterToFeet = (list = []) =>
  (DensityImperialToMetricConversionConstant * getTotalWeight(list))
  / getTotalVolumeFromCentimeterToFeet(list);
const getTotalDensityMeterToFeet = (list = []) =>
  (DensityImperialToMetricConversionConstant * getTotalWeight(list))
  / getTotalVolumeFromMeterToFeet(list);
const getTotalDensityFromFeetToFeet = (list = []) =>
  getTotalWeight(list) / getTotalVolumeFromFeetToFeet(list);

export const getTotalVolume = (measurementType, list = []) => {
  switch (measurementType) {
    case UnitsOfMeasure.Metric:
      return getTotalVolumeFromCentimeterToFeet(list);
    case UnitsOfMeasure.Imperial:
      return getTotalVolumeFromInchToFeet(list);
    case UnitsOfMeasure.Inches:
      return getTotalVolumeFromInchToFeet(list);
    case UnitsOfMeasure.Feet:
      return getTotalVolumeFromFeetToFeet(list);
    case UnitsOfMeasure.Centimeters:
      return getTotalVolumeFromCentimeterToFeet(list);
    case UnitsOfMeasure.Meters:
      return getTotalVolumeFromMeterToFeet(list);
    default:
      return 0;
  }
};

export const getTotalDensity = (measurementType, list = []) => {
  switch (measurementType) {
    case UnitsOfMeasure.Metric:
      return getTotalDensityCentimeterToFeet(list);
    case UnitsOfMeasure.Imperial:
      return getTotalDensityFromInchToFeet(list);
    case UnitsOfMeasure.Inches:
      return getTotalDensityFromInchToFeet(list);
    case UnitsOfMeasure.Feet:
      return getTotalDensityFromFeetToFeet(list);
    case UnitsOfMeasure.Centimeters:
      return getTotalDensityCentimeterToFeet(list);
    case UnitsOfMeasure.Meters:
      return getTotalDensityMeterToFeet(list);
    default:
      return 0;
  }
};

export const anchor = (a = '') => `i${a ? a.replace(/[{}\s]/g, '-') : ''}`;
export const homeUrl = () =>
  (!runningOnBrowser()
    ? `${window.location.pathname.match(/^\/en|\/fr-ca|\//i)}`
    : '/');

export const homeUrlNoTrailingSlash = () => homeUrl().replace(/\/$/g, '');

export const getCurrentLocale = () => {
  const localeCookieValue = readCookie('internet#lang');

  if (!localeCookieValue) {
    return '';
  }

  return localeCookieValue;
};

export const getCurrentLanguage = () => {
  const langCookieValue = readCookie('internet#lang');
  if (!langCookieValue) {
    if (
      runningOnBrowser()
      && window.location
      && window.location.pathname
      && window.location.pathname.toLowerCase().includes(`/${Locales.French.toLowerCase()}/`)
    ) {
      return Languages.FR;
    }
  }

  if (langCookieValue === Locales.English) {
    return Languages.EN;
  }

  if (langCookieValue === Locales.French) {
    return Languages.FR;
  }

  return Languages.EN; // Default language / locale...
};

/**
 * checks if this is a non-root route by name
 * @param {String} routeName
 */
export const isRoute = (routeName) => {
  if (isNullOrWhiteSpace(routeName) || !runningOnBrowser()) {
    return false;
  }

  const reg = new RegExp(`(\\/en\\/?|\\/fr-ca\\/?|\\/)${routeName}\\b`, 'gi');
  return reg.test(window.location.pathname);
};

export const runningOnBrowser = () => typeof window === 'object';

export const getSitecoreStateAuthToken = () => {
  if (runningOnBrowser() && typeof document === 'object') {
    const state = JSON.parse(document.getElementById('__JSS_STATE__').innerText);
    return (
      state && state.sitecore.context.profileData.app_settings.service_apiKey
    );
  }
  return false;
};

export const isRoot = () => (runningOnBrowser()
  ? /^(\/en\/?|\/fr-ca\/?|\/)$/isgm.test(window.location.pathname)
  : false);

const sizeScales = {
  [UnitsOfMeasure.Metric]: ['cm', 'm'],
  [UnitsOfMeasure.Imperial]: ['inches', 'ft'],
  // to support old code
  [UnitsOfMeasure.Inches]: ['inches'],
  [UnitsOfMeasure.Feet]: ['ft.'],
  [UnitsOfMeasure.Centimeters]: ['cm'],
  [UnitsOfMeasure.Meters]: ['m'],
};

export const getSizeMeasurementAbbreviationString = (
  measurementType,
  scale = 0
) => (sizeScales[measurementType] ? sizeScales[measurementType][scale] : null);

export const getWeightMeasurementAbbreviationString = (measurementType) => {
  switch (measurementType) {
    case 'Metric':
      return 'kg';
    case 'Imperial':
      return 'lbs';
    case 'Inches':
      return 'lbs';
    case 'Feet':
      return 'lbs';
    case 'Centimeters':
      return 'kg';
    case 'Meters':
      return 'kg';
    default:
      return null;
  }
};
export const scrollToTop = () => window.scrollTo(0, 0);

export const openInNewTab = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export const NavToPageAndShowAddNewLineButton = (
  currentList,
  added,
  setAddedTrue,
) =>
  currentList
  && currentList.filter((item) => !item.completed).length === 0
  && added !== true
  && setAddedTrue();

const getFile = ({
  dataAsBase64,
  mimeType
}) => {
  const data = atob(dataAsBase64);
  const byteNumbers = new Array(data.length);
  for (let i = 0; i < data.length; i++) {
    byteNumbers[i] = data.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

export const downloadFile = ({
  dataAsBase64,
  mimeType,
  filename
}) => {
  const file = getFile({
    dataAsBase64,
    mimeType
  });
  saveAs(file, filename);
};

export const openFileInNewTab = ({
  dataAsBase64,
  mimeType,
  fileName
}) => {
  if (
    !runningOnBrowser()
    || isNullOrWhiteSpace(dataAsBase64)
    || isNullOrWhiteSpace(mimeType)
  ) {
    return;
  }

  const file = getFile({
    dataAsBase64,
    mimeType
  });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, fileName || 'file.pdf');
  } else {
    const fileUrl = URL.createObjectURL(file);
    window.open(fileUrl);
  }
};

export const downloadFileStr = (fileName, contentAsStr, mimeType) => {
  const file = new Blob([contentAsStr], { type: mimeType });
  saveAs(file, fileName);
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const littleCaseFirstLetter = (string) => string.charAt(0).toLowerCase() + string.slice(1);

export const capitalize = (value) => value && value.toUpperCase();

export const formatMoney = (amount, protectedAmount) => {
  if (protectedAmount) {
    return t('Protected');
  }

  if (amount <= 0) {
    return null;
  }
  const decimalCount = Math.abs(2);
  const decimal = '.';
  const thousands = ',';

  const negativeSign = amount < 0 ? '-' : '';

  const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount), 10).toString();
  const j = (i.length > 3) ? i.length % 3 : 0;

  // eslint-disable-next-line max-len
  return `$ ${negativeSign}${j ? i.substring(0, j) + thousands : ''}${i.substring(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}${decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : ''}`;
};

export const formatValuesto2Decimals = (amount) => parseFloat(Math.round(amount * 100) / 100).toFixed(2);

/**
 * Return an object with time split into parts (hours, minutes, AM/PM)
 * Currently used in Create Pickup/Shipment pages
 * @param date - Date
 * @returns {{hours: string, minutes: string, amOrPm: string}}
 */
export const getTimeObject = (date) => {
  const hours = (date.getHours() % 12).toString().padStart(2, '0');
  return {
    amOrPm: date.getHours() > 12 ? AMPM.PM : AMPM.AM,
    hours: hours ? hours : 12,
    minutes: date.getMinutes().toString().padStart(2, '0'),
  };
};

export const convertHex = (hex, opacity) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const result = `rgba(${r},${g},${b}${opacity ? `,${opacity / 100}` : ''})`;
  return result;
};

export const checkBillToAccountProtectedStatus = (
  dispatch,
  division,
  userId,
  billingAccounts,
) => {
  getCompaniesList(division, userId)
    .then((companiesListResponse) => {
      const { data } = companiesListResponse;
      return Promise.all(
        data.map((c) =>
          getCompanyInfo(c.companyId).then((res) => res.data.accounts),
        ),
      );
    })
    .then((accountsList) => {
      const flatAccountsList = accountsList.flat();
      const authContent = {
        billingAccounts: billingAccounts.map((a) => {
          a.protected =
            flatAccountsList
            && flatAccountsList.some(
              (acc) =>
                String(acc.accountNumber) === String(a.accountNumber)
                && acc.accountType === 'PA'
            );
          return a;
        }),
      };
      dispatch(setAuthContent(authContent));
    });
};

export const checkProtectedStatus = (
  division,
  userId,
  billingAccounts
) => {
  getCompaniesList(division, userId)
    .then((companiesListResponse) => {
      const { data } = companiesListResponse;
      return Promise.all(
        data.map((c) =>
          getCompanyInfo(c.companyId).then((res) => res.data.accounts),
        )
      );
    })
    .then((accountsList) => {
      const flatAccountsList = accountsList.flat();
      const authContent = {
        billingAccounts: billingAccounts.map((a) => {
          a.protected =
          flatAccountsList
          && flatAccountsList.some(
            (acc) =>
              String(acc.accountNumber) === String(a.accountNumber)
            && acc.accountType === 'PA'
          );
          return a;
        }),
      };
      return authContent;
    });
};
