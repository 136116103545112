import React from 'react';
import { t } from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import {
  CategoryTitle,
  ContentBoxLightGrey,
  Icon,
  LineButton,
  NarrowBlock,
  OffsetContainer,
  SubItem,
  TextProperty
} from '../css';
import PrimaryButton from '../../ReactComponent/PrimaryButton';
import { H3, P } from '../../../theme/typographySitecore';
import Edit from '../../../assets/icons/Edit.svg';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import { AddressBookText } from '../../ReactComponent/AddressBookEntry';
import ContainerList from '../../ReactComponent/Measurement/ContainerList';
import {
  additionalServices,
  containerDataByService,
  FORM_NAME,
  itemsByService,
  services as servicesListCreator
} from '../constants';
import { AddressTypes } from '../../../utils/enums';

export const Button = ({
  text,
  children,
  ...rest
}) => (
  <OffsetContainer>
    <PrimaryButton type="button" autoWidth {...rest}>
      {text ? <Text field={text} /> : children}
    </PrimaryButton>
  </OffsetContainer>
);

// eslint-disable-next-line react/no-multi-comp
export const FinalStep = ({
  data,
  moveToStep,
  isEditable,
  fields
}) => {
  if (!data) return null;
  const services = servicesListCreator(fields);
  const serviceId = services.indexOf(data.service);
  return (
    <>
      <CategoryTitle>
        {t('SctnQuoteAddressDetails')}
        {isEditable && (
          <LineButton onClick={() => moveToStep(0)}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step one" />
          </LineButton>
        )}
      </CategoryTitle>
      <Row>
        <Column $tablet={6} $mobile={12}>
          <NarrowBlock className="with-bg">
            <H3 field={t('ShipperDetails')} />
            <AddressBookText contactType={AddressTypes.SHIPPER} formData={data} />
          </NarrowBlock>
          <NarrowBlock className="with-bg">
            <H3 field={t('YourDetails')} />
            <TextBlock title={t('Name')} value={data.name} />
            <TextBlock title={t('Email')} value={data.email} />
          </NarrowBlock>
        </Column>
        <Column $tablet={6} $mobile={12}>
          <NarrowBlock className="with-bg">
            <H3 field={t('ConsigneeDetails')} />
            <AddressBookText contactType={AddressTypes.CONSIGNEE} formData={data} />
          </NarrowBlock>
          <NarrowBlock className="with-bg">
            <H3 field={t('SctnQuoteBillingInformation')} />
            <TextBlock
              title={t('SctnQuoteIntendedMethodOfPayment')}
              value={data.paymentMethod.name}
            />
            <TextBlock title={t('Currency')} value={data.currency} />
          </NarrowBlock>
        </Column>
      </Row>
      <CategoryTitle>
        {t('SctnQuoteServiceAndEquipment')}
        {isEditable && (
          <LineButton onClick={() => moveToStep(1)}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step one" />
          </LineButton>
        )}
      </CategoryTitle>
      <ContentBoxLightGrey>
        <H3 field={t('SctnQuoteBillingInformation')} />
        <Row>
          <Column $tablet={4} $mobile={12}>
            <TextBlock
              title={t('SctnQuoteModeService')}
              value={data.service.name}
            />
            <TextBlock
              title={t('SctnQuoteTypeOfEquipment')}
              value={data.equipmentType ? data.equipmentType.name : null}
            />
            <TextBlock title={t('Temperature')} value={data.temperature} />
            <TextBlock
              title={t('SctnQuoteGensetRequired')}
              value={data.gensetRequired}
            />
          </Column>
          <Column $tablet={4} $mobile={12}>
            <TextBlock
              title={t('SctnQuoteTypeOfDelivery')}
              value={data.deliveryType}
            />
            <TextBlock
              title={t('SctnQuoteFrequencyOfShipment')}
              value={data.frequencyOfShipment}
            />
            <TextBlock title={t('Chassis')} value={data.chassisSize} />
            <TextBlock
              title={t('SctnQuoteSelectTemperature')}
              value={data.temperatureUnit}
            />
            {data.incoterms && (
              <TextBlock title={t('Incoterms')} value={data.incoterms} />
            )}
          </Column>
        </Row>
      </ContentBoxLightGrey>
      <CategoryTitle>
        {t('SctnQuoteShipmentSize')}
        {isEditable && (
          <LineButton onClick={() => moveToStep(1)}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step one" />
          </LineButton>
        )}
      </CategoryTitle>
      <ContainerList
        type="readOnly"
        name="containers"
        formName={FORM_NAME}
        isContainerData={containerDataByService[serviceId]}
        isItem={itemsByService[serviceId]}
      />
      <CategoryTitle>
        {t('SctnQuoteAdditionalInformation')}
        {isEditable && (
          <LineButton onClick={() => moveToStep(2)}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step one" />
          </LineButton>
        )}
      </CategoryTitle>
      <ContentBoxLightGrey>
        <Row>
          <Column $tablet={6} $mobile={12}>
            <TextBlock
              title={t('SctnQuoteFullDescriptionOfCommodity')}
              value={data.commodityDescription}
            />
          </Column>
        </Row>
        <Row>
          <Column $tablet={6}>
            <H3 field={t('Value')} />
          </Column>
          <Column $tablet={6}>
            <H3 field={t('SctnQuoteCustomRequirements')} />
          </Column>
        </Row>
        <Row>
          <Column $tablet={2} $mobile={6}>
            <TextBlock
              title={t('SctnQuoteInvoiceValue')}
              value={data.invoiceValue}
            />
          </Column>
          {data.invoiceValue && (
            <Column $tablet={2} $mobile={6}>
              <TextBlock title={t('Currency')} value={data.currency} />
            </Column>
          )}
          <Column $tablet={2} $mobile={0} />
          <Column $tablet={6} $mobile={6}>
            <TextBlock title={t('SctnQuoteHsCode')} value={data.hsCode} />
          </Column>
        </Row>
        <Row>
          <Column $tablet={2} $mobile={6}>
            <TextBlock
              title={t('SctnQuoteDeclaredValue')}
              value={data.declaredValue}
            />
          </Column>
          {data.declaredValue && (
            <Column $tablet={2} $mobile={6}>
              <TextBlock title={t('Currency')} value={data.currency} />
            </Column>
          )}
          <Column $tablet={2} $mobile={0}/>
          {data.importType && (
            <Column $tablet={6} $mobile={6}>
              <TextBlock
                title={t('SctnQuoteTypeOfImport')}
                value={data.importType.name}
              />
            </Column>
          )}
        </Row>
      </ContentBoxLightGrey>
      <CategoryTitle>
        <div>{t('SctnQuoteAdditionalServices')}</div>
        {isEditable && (
          <LineButton onClick={() => moveToStep(2)}>
            {t('Edit')}
            <Icon className="icon right" src={Edit} alt="edit step two" />
          </LineButton>
        )}
      </CategoryTitle>
      <ContentBoxLightGrey>
        {data.additionalServices.map((service, i) => (
          <div key={i + service.name}>
            <H3 field={service.name} verticalOffset={20} />
            {service === additionalServices(fields)[2] && (
              <SubItem>
                <H3
                  field={t('SctnQuoteDangerousGoodsInformation')}
                  verticalOffset={20}
                />
                <P field={t('SctnQuoteUnNumber')} />
                <P field={data.unNumber} />
                <P field={t('SctnQuoteDescriptionHAZMAT')} />
                <P field={data.hazmat} />
                <P field={t('SctnQuoteSpecialInstructions')} />
                <P field={data.specialInstructions} />
              </SubItem>
            )}
          </div>
        ))}
      </ContentBoxLightGrey>
    </>
  );
};

// eslint-disable-next-line react/no-multi-comp
const TextBlock = ({
  title,
  value
}) => (
  <>
    {value ? (
      <TextProperty>
        <P field={title} />
        <P field={value} />
      </TextProperty>
    ) : null}
  </>
);
