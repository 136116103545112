import { PUT_REDUX_SERVICE_LEVEL_QUOTE, PUT_REDUX_SERVICE_LEVEL_CODE } from '../../reducers/constants';

export const putReduxServiceLevelQuotes = (serviceLevelQuotes) => ({
  type: PUT_REDUX_SERVICE_LEVEL_QUOTE,
  serviceLevelQuotes,
});

export const putReduxServiceLevelCode = (serviceLevelCode) => ({
  type: PUT_REDUX_SERVICE_LEVEL_CODE,
  serviceLevelCode,
});

