import { colors, zIndexes } from '../../theme/style-helpers';

const ModalBoxStyle = {
  overlay: {
    backgroundColor: colors.BLACK + colors.OPACITY_70_ALPHA_HEX_SUFFIX,
    zIndex: zIndexes.MODAL,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90em',
  },
};

export default ModalBoxStyle;
