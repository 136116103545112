import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { change } from 'redux-form';
import Delete from '../../../assets/icons/RemoveOrange.svg';
import Edit from '../../../assets/icons/Edit.svg';
import Cube from '../Cube';
import Row from '../Row';
import Column from '../Column';

import {
  AddedItemCard,
  EditButtonGroup,
  LineButton,
  TextProperty,
  Icon,
  CategoryTitle,
  BlockTitle
} from './css';
import { P } from '../../../theme/typography';

import {
  formatValuesto2Decimals,
  getSizeMeasurementAbbreviationString,
  getWeightMeasurementAbbreviationString,
  getTotalDensity
} from '../../../utils/func';

class MeasurementItemCard extends PureComponent {
  constructor(props) {
    super(props);
    this.formName = props.formName;
    this.listName = props.listName || 'measurementList'; // <- 'measurementList' is to support old codebase
  }

  editMeasurement = (index, dispatch) =>
    dispatch(change(this.formName, `${this.listName}[${index}].completed`, false));

  deleteMeasurement = (currentMeasurementList, index, dispatch) => {
    const newlist = [...currentMeasurementList];
    newlist.splice(index, 1);
    if (newlist.length === 0) {
      newlist.push({});
    }

    dispatch(change(this.formName, this.listName, newlist));
  };

  render() {
    const {
      measurementList,
      measurementType,
      dispatch,
      hideButtons,
      hideCube,
      order,
      currentMeasurementList,
      hideDangerousItem,
      hideTotalPieces,
      hideDescription,
      hideTotalWeight,
      showVolume,
      showDensity,
      USQuote,
      USShipment,
      quoteData,
      secondary,
      quote,
      hideEdit,
      isDangerousItemFlag
    } = this.props;
    if (currentMeasurementList) {
      currentMeasurementList.forEach((item, index) => {
        if (!isDangerousItemFlag && isDangerousItemFlag !== undefined) {
          if (item.itemDangerous && !isDangerousItemFlag) {
            dispatch(change(this.formName, `${this.listName}[${index}].completed`, false));
          }
        }
      });
    }
    const {
      editMeasurement,
      deleteMeasurement
    } = this;
    const sizeMeasurement = getSizeMeasurementAbbreviationString(measurementType);

    return (
      <>
        {quoteData && quoteData.items ?
          <>
            {quoteData.items.map((item, index) =>
              <AddedItemCard key={index} secondary={secondary}>
                <CategoryTitle>
                  {t('Item')}
                  {order ? ` ${Number(order + 1)}` : ` ${index + 1}`}
                </CategoryTitle>
                <Row>
                  <Column>
                    <TextProperty>
                      <P className="title">{t('Class')}</P>
                      <P>{measurementList[index].itemClass}</P>
                    </TextProperty>
                  </Column>
                  <Column>
                    <TextProperty>
                      <P className="title">{t('Weight of Item')}</P>
                      <P>
                        {measurementList[index].itemWeight}
                        {' lb'}
                      </P>
                    </TextProperty>
                  </Column>
                  <Column>
                    <TextProperty>
                      <P className="title">{t('ItemLength')}</P>
                      <P>
                        {measurementList[index].itemLength}
                        {' in'}
                      </P>
                    </TextProperty>
                  </Column>
                  <Column>
                    <TextProperty>
                      <P className="title">{t('ItemWidth')}</P>
                      <P>
                        {measurementList[index].itemWidth}
                        {' in'}
                      </P>
                    </TextProperty>
                  </Column>
                  <Column>
                    <TextProperty>
                      <P className="title">{t('ItemHeight')}</P>
                      <P>
                        {measurementList[index].itemHeight}
                        {' in'}
                      </P>
                    </TextProperty>
                  </Column>
                  <Column>
                    <TextProperty>
                      <P className="title">{t('Rate')}</P>
                      <P>
                        {'$ '}
                        {item.rate.toFixed(2)}
                        </P>
                    </TextProperty>
                  </Column>
                  <Column>
                    <TextProperty>
                      <P className="title">{t('Charge')}</P>
                      <P>
                        {'$ '}
                        {item.charge.toFixed(2)}
                        </P>
                    </TextProperty>
                  </Column>
                  <Column>
                    <TextProperty>
                      <P className="title">{t('FAK Class')}</P>
                      <P>{item.fakClass}</P>
                    </TextProperty>
                  </Column>
                </Row>
              </AddedItemCard>
            )}
          </>
          :
          <>
            {measurementList && measurementList.map((item, index) => (
              <AddedItemCard key={index} secondary={secondary}>
                {!hideButtons && (
                  <EditButtonGroup>
                    <LineButton
                      name="deleteMeasurement"
                      type="button"
                      onClick={() => deleteMeasurement(currentMeasurementList, order, dispatch)}>
                      <Icon className="icon" src={Delete} alt="delete"/>
                      {t('Delete')}
                    </LineButton>
                    {!hideEdit && <LineButton
                      name="editMeasurement"
                      type="button"
                      onClick={() => editMeasurement(order, dispatch)}>
                      <Icon className="icon" src={Edit} alt="edit"/>
                      {t('Edit')}
                    </LineButton>}
                  </EditButtonGroup>
                )}
                <CategoryTitle>
                  {t('Item')}
                  {order ? ` ${Number(order + 1)}` : ` ${index + 1}`}
                </CategoryTitle>
                <Row>
                  {USQuote ?
                    <>
                      <Column>
                        <TextProperty>
                          <P className="title">{t('Class')}</P>
                          <P>{item.itemClass}</P>
                        </TextProperty>
                      </Column>
                      <Column>
                        <TextProperty>
                          <P className="title">{t('TotalWeight')}</P>
                          <P>
                            {item.itemWeight}
                            {' lb'}
                          </P>
                        </TextProperty>
                      </Column>
                      <Column>
                        <TextProperty>
                          <P className="title">{t('ItemLength')}</P>
                          <P>
                            {item.itemLength}
                            {' in'}
                          </P>
                        </TextProperty>
                      </Column>
                      <Column>
                        <TextProperty>
                          <P className="title">{t('ItemWidth')}</P>
                          <P>
                            {item.itemWidth}
                            {' in'}
                          </P>
                        </TextProperty>
                      </Column>
                      <Column>
                        <TextProperty>
                          <P className="title">{t('ItemHeight')}</P>
                          <P>
                            {item.itemHeight}
                            {' in'}
                          </P>
                        </TextProperty>
                      </Column>
                    </> :
                    <>
                      <Column $tablet={hideButtons ? 6 : 3} $mobile={6}>
                        {USShipment &&
                          <>
                            <TextProperty>
                              <P className="title">{t('FreightClass')}</P>
                              <P>{item.itemFreightClass}</P>
                            </TextProperty>
                            <TextProperty>
                              <P className="title">{t('NMFC Code')}</P>
                              <P>{item.itemNMFC}</P>
                            </TextProperty>
                          </>}
                        <TextProperty>
                          <P className="title">{t('ItemLength')}</P>
                          <P>
                            {item.itemLength}
                            &nbsp;
                            {sizeMeasurement}
                          </P>
                        </TextProperty>
                        <TextProperty>
                          <P className="title">{t('ItemWidth')}</P>
                          <P>
                            {item.itemWidth}
                            &nbsp;
                            {sizeMeasurement}
                          </P>
                        </TextProperty>
                        <TextProperty>
                          <P className="title">{t('ItemHeight')}</P>
                          <P>
                            {item.itemHeight}
                            &nbsp;
                            {sizeMeasurement}
                          </P>
                        </TextProperty>
                        {(!hideDangerousItem || quote) && <TextProperty>
                          <P className="title">{t('DangerousGoods')}</P>
                          <P>{(item.itemDangerous) ? t('Yes') : t('No')}</P>
                        </TextProperty>}
                      </Column>
                      <Column $tablet={hideButtons ? 6 : 3} $mobile={6}>
                        {!hideTotalPieces && <TextProperty>
                          <P className="title">{t('TotalPiecesOfItem')}</P>
                          <P>{item.itemCount}</P>
                        </TextProperty>}
                        {!hideTotalWeight && <TextProperty>
                          <P className="title">{t('TotalWeightOfItem')}</P>
                          <P>
                            {item.itemWeight}
                            &nbsp;
                            {getWeightMeasurementAbbreviationString(measurementType)}
                          </P>
                        </TextProperty>}
                        {showVolume && <TextProperty>
                          <P className="title">{t('Volume of Shipment')}</P>
                          <P>
                            {formatValuesto2Decimals(item.itemVolume)}
                            {' cubic ft.'}
                          </P>
                        </TextProperty>}
                        {showDensity && <TextProperty>
                          <P className="title">{t('DensityOfShipment')}</P>
                          <P>
                            {formatValuesto2Decimals(item.itemDensity || getTotalDensity(measurementType, [item]))}
                            {' lbs/cubic ft.'}
                          </P>
                        </TextProperty>}
                        {!hideDescription && <TextProperty>
                          <P className="title">{t('Description')}</P>
                          <P>{item.itemDescription}</P>
                        </TextProperty>}
                      </Column>
                      {!hideCube && (
                        <Column $tablet={hideButtons ? 12 : 6}>
                          <Cube solid width={item.itemWidth} height={item.itemHeight} length={item.itemLength} measurementType={measurementType}/>
                        </Column>
                      )}
                    </>}
                </Row>
                {(!hideDangerousItem && item.itemDangerous) && (
                  <Row>
                    <hr width="100%"/>
                    <Column $mobile={12}>
                      <BlockTitle>{t('DangerousGoods')}</BlockTitle>
                    </Column>
                    <Column $tablet={4} $mobile={12}>
                      <TextProperty>
                        <P className="title">{t('UNNumber')}</P>
                        <P>{item.unNumber}</P>
                      </TextProperty>
                      <TextProperty>
                        <P className="title">{t('ShippingName')}</P>
                        <P>{item.shippingName}</P>
                      </TextProperty>
                      <TextProperty>
                        <P className="title">{t('TotalQuantity')}</P>
                        <P>
                          {item.totalQuantity}
                          &nbsp;
                          {item.totalQuantityUnits}
                        </P>
                      </TextProperty>
                    </Column>
                    <Column $tablet={4} $mobile={12}>
                      <TextProperty>
                        <P className="title">{t('NumberOfPackagesRequiringLabel')}</P>
                        <P>{item.numberPackRequireLabel}</P>
                      </TextProperty>
                    </Column>
                    <Column $tablet={4} $mobile={12}>
                      <TextProperty>
                        <P className="title">{t('PrimaryClass')}</P>
                        <P>{item.primeClass}</P>
                      </TextProperty>
                      <TextProperty>
                        <P className="title">{t('SecondaryClass')}</P>
                        <P>{item.secondClass}</P>
                      </TextProperty>
                      {item.packagingGroup &&
                        <TextProperty>
                          <P className="title">{t('PackagingGroup')}</P>
                          <P>{item.packagingGroup.name}</P>
                        </TextProperty>}
                    </Column>
                  </Row>
                )}
              </AddedItemCard>
            ))}
          </>}
      </>
    );
  }
}

MeasurementItemCard.propTypes = {
  formName: PropTypes.string,
  listName: PropTypes.string,
  measurementList: PropTypes.array,
  measurementType: PropTypes.string,
  dispatch: PropTypes.func,
  hideButtons: PropTypes.bool,
  hideCube: PropTypes.bool,
  order: PropTypes.number,
  currentMeasurementList: PropTypes.array,
  hideDangerousItem: PropTypes.bool,
  hideTotalPieces: PropTypes.bool,
  hideDescription: PropTypes.bool,
  hideTotalWeight: PropTypes.bool,
  showVolume: PropTypes.bool,
  showDensity: PropTypes.bool,
  USQuote: PropTypes.bool,
  USShipment: PropTypes.bool,
  quoteData: PropTypes.object,
  secondary: PropTypes.bool,
  quote: PropTypes.bool,
  hideEdit: PropTypes.bool,
  isDangerousItemFlag: PropTypes.bool
};

export default MeasurementItemCard;
