import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { t } from 'i18next';
import { ActionsContainer, FormContainer } from '../css';
import { Button, FinalStep } from './common';
import { styleTypes } from '../../ReactComponent/PrimaryButton';
import { FORM_NAME } from '../constants';
import { H1 } from '../../../theme/typographySitecore';
import submitApplicationForm from '../api';

let Step4 = (props) => {
  const {
    onNext,
    onBack,
    data
  } = props;

  const onSubmit = (e) => {
    e.preventDefault();
    submitApplicationForm(data);
    onNext(e);
  };

  return (
    <FormContainer>
      <form onSubmit={onSubmit}>
        <H1 field={t('SctnQuoteFinalizeQuote')} verticalOffset={40}/>
        <ActionsContainer>
          <Button type="submit" iconRightArrow>
            {t('SctnQuoteRequestQuote')}
          </Button>
        </ActionsContainer>
        <FinalStep {...props} isEditable/>
        <ActionsContainer>
          <Button
            type="button"
            styleType={styleTypes.SECONDARY}
            onClick={onBack}
            iconLeftArrow
          >
            {t('Back')}
          </Button>
          <Button type="submit" iconRightArrow>
            {t('SctnQuoteRequestQuote')}
          </Button>
        </ActionsContainer>
      </form>
    </FormContainer>
  );
};

Step4 = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: FORM_NAME,
})(Step4);

export default Step4 = connect((state) => {
  const data = getFormValues(FORM_NAME)(state);
  return {
    data,
  };
})(Step4);
