import React from 'react';
import Column from '../ReactComponent/Column';
import { P } from '../../theme/typographySitecore';
import {
  ButtonContainer,
  ContentContainer,
  HeaderContainer,
  HeaderTitle,
  TextContainer
} from './css';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import { routes } from '../../utils/constants';
import { activate } from '../../services/users';

const URL_PARAMS = {
  token: 'token',
  email: 'email'
};

class AccountActivation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountActivationSuccessful: true, // Since most cases will end in success, render success by default...
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get(URL_PARAMS.token);
    const userEmail = urlParams.get(URL_PARAMS.email);

    activate({
      token,
      userEmail
    })
      .then((res) => {
        if (res.data === false) { // Invalid token and / or email...
          this.setState({
            accountActivationSuccessful: false
          });
        }
      })
      .catch(() => {
        this.setState({
          accountActivationSuccessful: false
        });
      });
  }

  renderHeader = () => {
    const { fields } = this.props;
    return (
      <HeaderContainer>
        <Column $desktop={8}>
          <HeaderTitle field={fields.headerTitle}/>
        </Column>
      </HeaderContainer>
    );
  };

  renderContent = () => {
    const { fields } = this.props;
    const { accountActivationSuccessful } = this.state;

    return (
      <ContentContainer>
        <TextContainer $desktop={8}>
          <P field={
            accountActivationSuccessful ?
              fields.accountActivationSuccessMessage :
              fields.accountActivationFailureMessage
          }/>
        </TextContainer>
        <ButtonContainer $desktop={4}>
          <PrimaryButton iconRightArrow onClick={() => { window.location.href = routes.login; }}>
            {fields.buttonText.value}
          </PrimaryButton>
        </ButtonContainer>
      </ContentContainer>
    );
  };

  render() {
    return (
      <>
        {this.renderHeader()}
        {this.renderContent()}
      </>
    );
  }
}

export default AccountActivation;
