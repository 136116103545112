import { t } from 'i18next';
import { downloadFileStr, formatDisplayDate } from './func';
import { getDeliveryDate } from './dateTime';

export const formatQuotesReport = (data) => {
  // eslint-disable-next-line max-len
  const header = `${t('ReportHeaderAddressFromCity')},${t('ReportHeaderAddressFromProvinceCode')},${t('ReportHeaderAddressFromCountryCode')},${t('ReportHeaderAddressFromPostalCode')},${t('ReportHeaderAddressToCity')},${t('ReportHeaderAddressToProviceCode')},${t('ReportHeaderAddressToCountryCode')},${t('ReportHeaderAddressToPostalCode')},${t('ReportHeaderAmountValue')},${t('ReportHeaderAmountCurrencyCode')},${t('ReportHeaderCreateDate')},${t('ReportHeaderQuoteNumber')},${t('ReportHeaderQuoteType')},${t('ReportHeaderStatus')},${t('ReportHeaderPieces')},${t('ReportHeaderWeight')}`;
  return data.reduce((output, item) => {
    const {
      addressFrom,
      addressTo,
      amount,
      createDate,
      quoteNumber,
      quoteType,
      status,
      weight,
      pieces,
    } = item;
    // eslint-disable-next-line max-len
    return `${output}\r\n${addressFrom.city},${addressFrom.provinceCode},${addressFrom.countryCode},${addressFrom.postalCode},${addressTo.city},${addressTo.provinceCode},${addressTo.countryCode},${addressTo.postalCode},${amount.value},${amount.currencyCode},${formatDisplayDate(createDate)},${quoteNumber},${quoteType},${status},${pieces || ''},${weight.value}`;
  }, header);
};

export const formatInvoicesReport = (data) => {
  // eslint-disable-next-line max-len
  const header = `${t('ReportHeaderAccountNumber')},${t('ReportHeaderBalanceDue')},${t('ReportHeaderBalanceDueCurrencyCode')},${t('ReportHeaderCustomerName')},${t('ReportHeaderDueDate')},${t('ReportHeaderInvoiceAmount')},${t('ReportHeaderInvoiceAmountCurrencyCode')},${t('ReportHeaderInvoiceDate')},${t('ReportHeaderInvoiceNumber')}`;

  return data.reduce((output, item) => {
    const {
      accountNumber,
      balanceDue,
      customerName,
      dueDate,
      invoiceAmount,
      invoiceDate,
      invoiceNumber,
    } = item;
    // eslint-disable-next-line max-len
    return `${output}\r\n${accountNumber},${balanceDue.value},${balanceDue.currencyCode},${customerName},${formatDisplayDate(dueDate)},${invoiceAmount.value},${invoiceAmount.currencyCode},${formatDisplayDate(invoiceDate)},${invoiceNumber}`;
  }, header);
};

export const formatShipmentsReport = (data) => {
  // eslint-disable-next-line max-len
  const header = `${t('ReportHeaderBillOfLandingImage')},${t('ReportHeaderBillOfLandingNumber')},${t('ReportHeaderCharge')},${t('ReportHeaderDestinationTerminal')},${t('ReportHeaderAddressFromName')},${t('ReportHeaderAddressFromCity')},${t('ReportHeaderAddressFromProvinceCode')},${t('ReportHeaderAddressFromCountryCode')},${t('ReportHeaderAddressFromPostalCode')},${t('ReportHeaderImageAvailable')},${t('ReportHeaderOriginTerminal')},${t('ReportHeaderPieces')},${t('ReportHeaderPostalOfficeNumber')},${t('ProNumber')},${t('ReportHeaderProofOfDeliveryImage')},${t('ReportHeaderShipDate')},${t('ReportHeaderDeliveryDate')},${t('ReportHeaderStatus')},${t('ReportHeaderAddressToName')},${t('ReportHeaderAddressToCity')},${t('ReportHeaderAddressToProviceCode')},${t('ReportHeaderAddressToCountryCode')},${t('ReportHeaderAddressToPostalCode')},${t('ReportHeaderWeight')}`;

  return data.reduce((output, item) => {
    const {
      billOfLadingImage,
      bolNumber,
      charge,
      destinationTerminal,
      from,
      isImageAvailable,
      originTerminal,
      pieces,
      poNumber,
      probillNumber,
      proofOfDeliveryImage,
      shipDate,
      deliveryDate,
      deliveryDateType,
      status,
      to,
      weight,
    } = item;
    // eslint-disable-next-line max-len
    return `${output}\r\n${billOfLadingImage},${bolNumber},${charge.value},${destinationTerminal},${from.name},${from.city},${from.provinceCode},${from.countryCode},${from.postalCode},${isImageAvailable},${originTerminal},${pieces},${poNumber},${probillNumber},${proofOfDeliveryImage},${getDeliveryDate(
      {
        deliveryDate: shipDate,
        deliveryDateType: 'default',
      }
    )},${getDeliveryDate({
      deliveryDate,
      deliveryDateType,
    })},${status},${to.name},${to.city},${to.provinceCode},${to.countryCode},${to.postalCode},${weight.value}`;
  }, header);
};

export const formatPickupsReport = (data) => {
  // eslint-disable-next-line max-len
  const header = `${t('ReportHeaderCreateDate')},${t('ReportHeaderDeliveryAddress')},${t('OrderNumber')},${t('ReportHeaderPickupAddress')},${t('ReportHeaderPickupDate')},${t('ReportHeaderStatus')}`;
  return data.reduce((output, item) => {
    const {
      createdOn,
      deliveryAddress,
      orderNumber,
      pickupAddress,
      pickupDate,
      status,
    } = item;
    // eslint-disable-next-line max-len
    return `${output}\r\n${formatDisplayDate(createdOn)},"${deliveryAddress || ''}",${orderNumber},"${pickupAddress || ''}",${formatDisplayDate(pickupDate)},${status}`;
  }, header);
};

export const formatContactReports = (data) => {
  // eslint-disable-next-line max-len
  const header = `${t('Nickname')},${t('ContactName')},${t('ContactType')},${t('EmailAddress')},${t('CompanyName')},${t('Address1')},${t('Address2')},${t('City')},${t('ProvinceCode')}, ${t('CountryCode')},${t('PostalCode')}`;
  return data.reduce((output, item) => {
    const {
      nickname,
      contactName,
      contactType,
      emailAddress,
      companyName,
      address1,
      address2,
      city,
      provinceCode,
      countryCode,
      postalCode,
    } = item;
    // eslint-disable-next-line max-len
    return `${output}\n${nickname},"${contactName || ''}",${contactType || ''},"${emailAddress || ''}","${companyName || ''}","${address1 || ''}","${address2 || ''}","${city || ''}","${provinceCode || ''}", "${countryCode || ''}", "${postalCode || ''}`;
  }, header);
};

export const DownloadFileNames = {
  QuotesReport: 'quoteReport.csv',
  ShipmentsInProgressReport: 'shipmentsInProgressReport.csv',
  ShipmentsCompletedReport: 'shipmentsCompletedReport.csv',
  InvoicesReport: 'invoiceReport.csv',
  ShipmentsReport: 'shipmentsReport.csv',
  PickupsReport: 'pickupsReport.csv',
  ContactsReport: 'contactReport.csv',
};

export const mimeType = 'text/plain';

export const downloadReport = (fileName, formatFunction, data) => {
  if (fileName && formatFunction && data && data.length > 0) {
    const formattedData = formatFunction(data);
    downloadFileStr(fileName, formattedData, mimeType);
  }
};
