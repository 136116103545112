export const endpoints = {
  shipping: {
    accessorials: 'Accessorials',
    companies: 'Companies',
    feedback: 'Feedback',
    invoices: 'Invoices',
    permissions: 'Permissions',
    pickups: 'Pickups',
    quotes: 'Quotes',
    serviceLevel: 'Quotes/service-levels',
    shipments: 'Shipments',
    draftShipments: 'draft-shipments',
    shipmentDocuments: 'shipment-documents',
    submitClaims: 'claims',
    tariffs: 'Tariffs',
    users: 'Users',
    usQuote: 'Quotes/us',
    usQuoteRate: 'Quotes/us-rate',
    createAccountApi: 'Users/register',
    createSctnQuoteApi: 'Quotes/SCTN',
    transitTimeCalcApi: 'transit-times',
    contactUs: 'ContactUs',
    userPreferences: 'user-preferences',
  },
  address: {
    addressValidate: 'service-locations',
    contacts: 'Contacts',
    bulkImport: 'Contacts/bulk',
  },
  sitecore: {
    layoutService: '/sitecore/api/layout/render/jss',
    login: '/sitecore/api/ssc/internet/auth/1/login',
    logout: '/sitecore/api/ssc/internet/auth/1/logout',
    switchDivision: '/sitecore/api/ssc/internet/auth/1/switchuser',
    graphService: '/api/internet',
  },
};

export const routes = {
  login: '/login',
  createCompany: '/create-company',
  createAccount: '/create-account',
  createPickup: '/create-pickup',
  createQuote: '/create-quote',
  tansitTimes: './tansit-times',
  createShipment: '/create-shipment',
  createUser: '/create-user',
  userProfile: '/user-profile',
  createContact: '/create-contact',
  manageCompany: '/manage-companies',
  manageUsers: '/manage-users',
  manageContacts: '/manage-contacts',
  manageShipments: '/manage-shipments',
  manageQuotes: '/manage-quotes',
  manageInvoices: '/manage-invoices',
  managePickups: '/manage-pickups',
  viewShipment: '/view-shipment',
  viewShipmentTracking: '/view-shipment-tracking',
  viewUserDashboard: '/view-user-dashboard',
  createSctnQuote: '/create-sctn-quote',
  transitTimeCalculator: '/transit-times-calculator',
  trackShipments: '/track-shipments',
  viewQuote: '/view-quote',
  contactUs: '/contact-us',
  exchangeRate: '/exchange-rate',
  search: '/search',
  importExport: '/import-export',
  news: '/news',
  resetPassword: '/reset-password',
};
export const formNames = { // to be updated with formNames
  createQuote: 'CreateQuoteForm',
};
export const streetAddressExceptions = [// forms that do not require a street address
  formNames.createQuote,
];
// eslint-disable-next-line no-undef
export const constantAuthToken = process.env.REACT_APP_SERVER_API_KEY;
export const googleApiKey = 'AIzaSyCx4ec3bUgl-jWGeLJFsIi5MhR86tPE4pE';
export const googleMapUrl = `https://maps.googleapis.com/maps/api/js?&key=${googleApiKey}&libraries=places`;
// eslint-disable-next-line max-len
export const importTemplateFile = 'https://mc-2e3d9717-0b07-416d-9819-81490a-cd.azurewebsites.net/-/media/internet/data/media/files/Import_Contacts_Template-1.ashx';
