import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';

const base64 = (str) => btoa(str);

export const apiLogin = (updateState, username, password, division, updateError) => {
  const authpayload = base64(`${username}:${password}`);
  const url = endpoints.sitecore.login;
  axios({
    url,
    method: 'post',
    data: null,
    headers: {
      Authorization: `Basic ${authpayload}`,
      'dr-details': division,
    },
  })
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        updateState(res.data);
      } else {
        updateError(res);
      }
    }).catch((error) => {
      updateError(error.response);
    });
};

export const sendActivationEmail = (successCallBack, email) => axios({
  url: `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.users}/activate-email`,
  method: 'post',
  data: { emailAddress: email },
})
  .then((res) => {
    if (res.status === 200 || res.status === 204) {
      successCallBack();
    }
  });
