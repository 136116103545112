import styled, { css } from 'styled-components';
import { colors, MediaQuery, styleHelpers } from '../../theme/style-helpers';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';
import ArrowRightBlack from '../../assets/icons/ArrowRightBlack.svg';
import { P, H2, H1 } from '../../theme/typographySitecore';
import PrimaryButton from '../ReactComponent/PrimaryButton';
import Column from '../ReactComponent/Column';

export const ImgDiv = styled.div`
    overflow: hidden;
    position: relative;
    display: block;
    padding: 25px;
    width: 100%;
    min-height: 506px;

    &.text-at-bottom {
        padding-top: 50px;
    }

    &.hero-image {
        min-height: 750px;
        height: calc(100vh - 180px); // 180px being a hardcoded value for Navigation bar + first level links height, as of Oct 2, 2019...
        padding-top: 75px;

        ${styleHelpers.mobile`        
            padding-left: 25px;
            padding-top: 40px;
            margin-top:0px;
            margin-bottom: 20px;
            min-height: 0;
            // 66px being a hardcoded value for Navigation bar height, as it is rendered for iPhone 6/7/8(+), as of Oct 2, 2019...
            height: calc(100vh - 66px);
        `}
    }

    &.col {
        ${styleHelpers.mobile`
            flex-basis:auto;
        `}
    }

    &.first {
        ${styleHelpers.mobile`
        margin-top: 0;
    `}
    }

    &.last {
        ${styleHelpers.mobile`
            margin-bottom: 40px;
        `}
    }

    ${styleHelpers.mobile`
        margin-bottom: 16px;
        height: 250px;
    `}
`;

export const MultipanelImgDiv = styled(ImgDiv)`
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%), url('${(props) => props.$img}') no-repeat center center;
    background-size: cover;
    
    ${styleHelpers.mobile`
        min-height: 0;
        height: 250px;
    `}
`;

export const SinglePanelImgDiv = styled(ImgDiv)`
    background-image: url('${(props) => props.$img}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  
    ${styleHelpers.mobile`
        min-height: 0;
        height: 172px;
    `}
`;

export const HomeCopyStyle = styled.div`
    padding: 65px 0;

    ${styleHelpers.mobile`
        padding-top:0;
        padding-bottom:0;
        margin-top: 0;
    `}
`;

export const Button = styled.button`
    text-align: center;
    height: 52px;

    &.min-width {
        min-width: 300px;
    }

    ${styleHelpers.mobile`
        margin-bottom:25px;
    `}

    ${MediaQuery.medium`
        // width: auto;
        margin-bottom: 0;
        order: 1;
    `}

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowRightOrange});
        background-size: cover;

        ${styleHelpers.mobile`
            top:35%;
        `}
    }

    &.submit, &.active {
        background-color: ${colors.BLACK};

        .icon {
            background: url(${ArrowRightOrange});
        }

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};

            .icon {
                background: url(${ArrowRightBlack});
            }
        }
    }
`;

export const ButtonRow = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
`;

export const Video = styled.video`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
`;

export const HeroControlsContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 62.32%);
`;

export const HeroControlsSubContainer = styled.div`
    max-width: 1204px;
    margin: 0 auto;
    position: relative;
    padding: 25px;
    padding-top: 75px;
`;

export const HeroHeader = styled.div`
    color: ${colors.WHITE};
    margin-top: 30px;
    margin-left: 0;
    
    ${styleHelpers.mobile`
        margin-top: 0;
    `}
`;

export const CopyTextHeader = styled(H2)`
    margin-top: 20px;

    ${({ centerText }) => centerText && css`
        text-align: center;
    `}
`;

export const CopyTextContent = styled(P)`
    margin-top: 32px;

    ${({ centerText }) => centerText && css`
        text-align: center;
    `}
`;

export const OrangeArrowRight = styled.span`
    position: relative;
    top: 15px;
    transform: translateY(-50%);
    right: 0;
    margin: 0;
    height: 24px;
    width: 24px;
    background: url(${ArrowRightOrange});
    background-size: cover;
`;

export const ImageLink = styled.span`
    position: absolute;
    bottom: 25px;
    font-size: 2.5rem;
    line-height: 29px;

    a {
        color: ${colors.WHITE};
    }
`;

export const HeroLink = styled.span`
    font-size: 2rem;

    a {
        color: ${colors.WHITE};
        margin-bottom: 24px;

        ${styleHelpers.mobile`
          margin-bottom: 0;
        `}
    }
`;

export const Header = styled(H1)`
    font-weight: bold;
    width: 8px;
    margin-bottom: 64px;

    ${styleHelpers.mobile`
        margin-bottom: 0;
    `}
`;

export const RightButton = styled(PrimaryButton).attrs(() => ({
  type: 'button',
  iconRightArrow: true
}))`
    width: 210px;

    ${styleHelpers.mobile`
        width: 100%;
    `}
`;

export const LeftButton = styled(RightButton)`
    margin-right: 8px;

    ${styleHelpers.mobile`
        margin-right: initial;
        margin-bottom: 8px;
    `}
`;

export const LinksContainer = styled.div`
  margin-bottom: 128px;

    ${styleHelpers.mobile`
        margin-bottom: initial;
    `}
`;

export const HorizontalSectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    ${({ $imageFirst }) => $imageFirst && css`
        flex-direction: row-reverse;
    `}

    ${styleHelpers.mobile`
        flex-direction: column-reverse;
    `}
`;

export const TextSideContainer = styled.div`
  padding: 0 72px !important;

    ${styleHelpers.mobile`
        padding: 0 15px !important;
    `}
`;

export const ImageLinkDelimiter = styled.div`
    width: 72px;
    margin: 8px 0;
    border: 1px solid ${colors.BRAND_ORANGE};
`;

export const ImageLinkSummary = styled(P)`
    color: white;
`;

export const PaddinglessColumn = styled(Column)`
    padding: 0 !important;
`;

export const MultiPanelContainer = styled.div`
`;

export const MultiPanelTextContainer = styled.div`
    justify-content: center;
    margin-bottom: 85px;
    padding: 0 20%;

    ${styleHelpers.mobile`
        padding: 25px 0 !important;
        margin: 0;    
    `}
`;

export const DownArrowContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 24px;

    ${styleHelpers.mobile`    
        padding-bottom: 8px;
    `}
`;

export const DownArrow = styled.img.attrs(() => ({ src: ArrowRightOrange }))`
    height: 48px;
    width: 48px;
    transform: rotate(90deg);
    cursor: pointer;
  
    ${styleHelpers.mobile`   
        height: 24px;
        width: 24px;
    `}
`;
