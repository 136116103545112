import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import RenderField from '../RenderField';

class SearchWithFilter extends PureComponent {
  getSearchResults = (e) => {
    const { getSuggestions } = this.props;
    const searchKey = e.target.value;
    getSuggestions(searchKey);
  };

  render() {
    const { label } = this.props;

    return (
      <Field
        name="searchByKey"
        type="search"
        component={RenderField}
        label={label}
        onChange={this.getSearchResults}
      />
    );
  }
}

SearchWithFilter.propTypes = {
  getSuggestions: PropTypes.func,
  label: PropTypes.string,
};

export default SearchWithFilter;
