import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues, change } from 'redux-form';
import { t } from 'i18next';
import { connect } from 'react-redux';
import TabsBar from '../ReactComponent/TabsBar';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import AllLocations from './locations/AllLocations';
import {
  HeaderContainer,
  HeaderTitle,
  DropDownField,
  FormContainer,
  InputStrategy,
  Error
} from './css';
import RenderField from '../ReactComponent/RenderField';

export const locations = {
  allLocations: 'AllLocations',
  ltlLocations: 'LTLAndCommerceSolutionsNetwork',
  dedicatedLocations: 'DedicatedNetwork',
};

export const formName = 'TerminalLocationForm';

class TerminalLocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStrategy: locations.allLocations,
      data: [],
      cityData: [],
      countryData: [],
      error: '',
    };
  }

  processCSVData = (csvData) => {
    const textLine = csvData.toString().split('\\n');
    const headers = textLine[0].split(',');
    const objProcessArray = [];

    for (let i = 1; i < textLine.length; i++) {
      const seperatorData = textLine[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
      if (seperatorData.length === headers.length) {
        const data = {};
        for (let x = 0; x < seperatorData.length; x++) {
          const header = headers[x].replace(/ +/g, '');
          data[header] = seperatorData[x].replace(/['"]+/g, '');
        }
        data.id = i;
        objProcessArray.push(data);
      }
    }

    const stateArr = this.getddlValues(objProcessArray, 'state');
    const countryArr = this.getddlValues(objProcessArray, 'country');

    // Adding "All" in both Country and Province drop down

    this.setState({
      data: objProcessArray,
      cityData: stateArr,
      countryData: countryArr,

    });
  };

  componentDidMount() {
    const { fields, dispatch } = this.props;
    this.processCSVData(fields.allTerminalLocations.value);
    dispatch(change(formName, 'countries', 'All'));
    dispatch(change(formName, 'province', 'All'));
  }

  onTabChange = (locationsLocal) => {
    this.setState({
      currentStrategy: locationsLocal,
      error: ''
    });
  };

  getddlValues = (arrayData, fieldName) => arrayData.map((a) => a[fieldName])
    .reduce((unique, item) => {
      if (!unique.includes(item)) { // this serves as our `filter`
        unique.push(item);
      }
      return unique;
    }, ['All'].sort());

  getUniqueValue = (dataArr, filterField, fetchFiedlValue, fetchField) => dataArr.filter((eachItem) =>
    filterField && eachItem[filterField] && eachItem[filterField].indexOf(fetchFiedlValue) !== -1)
    .reduce((unique, eachItem) => {
      if (!unique.includes(eachItem[fetchField])) { // this serves as our `filter`
        unique.push(eachItem[fetchField]);
      }
      return unique;
    }, ['All']);

  onError = () => {
    this.setState({ error: `${t('Request returned an error')}` });
  };

  onCountryDropDownChange = (e) => {
    const { data } = this.state;
    const { dispatch } = this.props;

    if (e !== 'All') {
      dispatch(change(formName, 'province', 'All'));

      const cityobj = this.getUniqueValue(data, 'country', e, 'state');
      const cityArr = cityobj.map((item) => item);
      this.setState({ cityData: cityArr });
    } else {
      const cityArr = this.getUniqueValue(data, 'state', '', 'state');
      this.setState({ cityData: cityArr });
    }
  };

  renderHeader = () => {
    const { fields } = this.props;
    return (
      <HeaderContainer>
        <Column $desktop={8}>
          <HeaderTitle field={fields.headerTitle}/>
        </Column>
      </HeaderContainer>);
  };

  render() {
    const { formData } = this.props;
    const {
      data,
      cityData,
      countryData,
      currentStrategy,
      error
    } = this.state;

    return (
      <FormContainer>
        {this.renderHeader()}
        <Row>
          <Column $desktop={6} $tablet={6} $mobile={12}>
            <DropDownField
              name="countries"
              type="dropdown"
              data={countryData}
              component={RenderField}
              label={t('Country')}
              onChange={(e) => this.onCountryDropDownChange(e)}
            />
          </Column>
          <Column $desktop={6} $tablet={6} $mobile={12}>
            <DropDownField
              name="province"
              type="dropdown"
              data={cityData}
              component={RenderField}
              label={t('ProvinceState')}
            />
          </Column>
        </Row>
        <TabsBar
          current={currentStrategy}
          steps={Object.values(locations)}
          onTabChange={this.onTabChange}/>
        <InputStrategy>
          {currentStrategy === locations.allLocations
            && <AllLocations fields={formData} locationData={data} divisionName="All"/>}
          {currentStrategy === locations.ltlLocations
            && <AllLocations fields={formData} locationData={data} divisionName="FreightSameday"/>}
          {currentStrategy === locations.dedicatedLocations
            && <AllLocations fields={formData} locationData={data} divisionName="Dedicated"/>}
        </InputStrategy>
        {error && <Error>{error}</Error>}
      </FormContainer>
    );
  }
}

TerminalLocationForm.propTypes = {
  fields: PropTypes.shape({
    allTerminalLocations: PropTypes.shape({
      value: PropTypes.array,
    }),
    headerTitle: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  formData: PropTypes.object,
};

TerminalLocationForm = reduxForm({
  form: formName,
})(TerminalLocationForm);

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
});

export default connect(
  mstp,
)(TerminalLocationForm);
