import React from 'react';
import { Field } from 'redux-form';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { t } from 'i18next';
import { P } from '../../../theme/typography';
import RenderField from '../../ReactComponent/RenderField';
import { PrimaryButton, ButtonGroup, Icon } from '../css';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import { isRequired } from '../../../utils/validator';
import ArrowRightWhite from '../../../assets/icons/ArrowRightWhite.svg';
import { validnumberregex } from '../constants';

const Order = ({ fields, formData, valid }) => {
  const orderNumbers = valid && formData ? formData.orderNumbers.split(validnumberregex) : null;

  return (<>
         {fields.orderInstructions &&
            <Row>
                <Column $tablet={8} $mobile={12}>
                    <P><RichText field={fields.orderInstructions} /></P>
                </Column>
            </Row>}
         <Row>
             <Column $tablet={4} $mobile={12}>
                 <Field
                     name="orderNumbers"
                     type="textarea"
                     component={RenderField}
                     required
                     validate={[isRequired]}
                     label={t('OrderNumbers')}
                 />
                 {orderNumbers && <span className="added-numbers">{orderNumbers.length} {t('NumbersAdded')}</span>}
             </Column>
             <Column $tablet={8} $mobile={12}>
                 <ButtonGroup>
                     <PrimaryButton type="submit" className="submit">
                         <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                         {t('Track')}
                     </PrimaryButton>
                 </ButtonGroup>
             </Column>
           </Row>
     </>);
};

export default Order;
