import styled from 'styled-components';
import { H2 } from '../../../../theme/typographySitecore';
import Error from '../../../../assets/icons/Error.svg';
import { colors, MediaQuery } from '../../../../theme/style-helpers';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:  center;
  
  ${MediaQuery.medium`
    padding: 0 196px;           
  `}
`;

export const ErrorIconContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding-top: 3px;
  padding-right: 16px;
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorIcon = styled.img.attrs(() => ({ src: Error }))`
  min-width: 24px
`;

export const ErrorHeader = styled(H2)`
  color: ${colors.RED_ERROR};
`;
