import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';
import ArrowRightBlack from '../../assets/icons/ArrowRightBlack.svg';
import ArrowLeftOrange from '../../assets/icons/ArrowLeftOrange.svg';

export const FieldGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 24px 0 18px;

    .relative {
        position: relative;
    }

    .file-upload, .mobile-margin-bottom {

        ${MediaQuery.medium`
            margin-bottom: 0;
        `}

    }
`;
export const Icon = styled.img`
    margin-right: 10px;
`;
export const FormContainer = styled.div`
    width: 100%;
    max-width: 816px;
    padding: 0 12px;
    margin: 0 auto;

    .center-text-mobile {
        text-align: center;

        ${MediaQuery.medium`
            text-align: left;
        `}
    }

    .single {
        ${MediaQuery.medium`
            order: -1;
        `}
    }

    .date-picker {

        &::-webkit-calendar-picker-indicator,
        &::-webkit-inner-spin-button {
            display: none;
        }
    }
`;
export const SuccessMessageBox = styled.div`
    text-align: center;
    margin: 32px auto 100px;

    .success-image {
        margin-bottom: 32px;
    }

    .secondary-button {
        background-color: ${colors.WHITE};
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: normal;
        text-align: center;
        color: ${colors.BLACK};
        padding: 16px 56px;
        border: 1px solid ${colors.BLACK};
        border-radius: 3px;
        margin-left: 0;
        width: 100%;
        order: 1;
        cursor: pointer;

        &.min-width {
            min-width: 300px;
        }


        ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        order: 0;
    `}
        .icon-left {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 17px;
            margin: 0;
            height: 24px;
            width: 24px;
            background: url(${ArrowLeftOrange});
            background-size: cover;
        }

        .icon-right {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
            margin: 0;
            height: 24px;
            width: 24px;
            background: url(${ArrowRightOrange});
            background-size: cover;
        }

        &.no-border {
            border: none;
        }

        &:hover {
            border-color: ${colors.BRAND_ORANGE};
        }

        &.hidden {
            display: none;
        }
    }
`;
export const FormSummary = styled.div`
    margin: 32px 0 72px;
`;
export const PrimaryButton = styled.button`
    background-color: ${colors.DARK_GREY};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.COOL_GREY};
    padding: 16px 56px;
    border: 1px solid ${colors.DARK_GREY};
    border-radius: 3px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    order: 0;

    &.min-width {
        min-width: 300px;
    }

    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        margin-bottom: 0;
        order: 1;
    `}
    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowRightWhite});
        background-size: cover;
    }

    &.submit, &.active {
        background-color: ${colors.BLACK};

        .icon {
            background: url(${ArrowRightOrange});
        }

        &:hover {
            background-color: ${colors.BRAND_ORANGE};
            color: ${colors.BLACK};
            border-color: ${colors.BRAND_ORANGE};

            .icon {
                background: url(${ArrowRightBlack});
            }
        }
    }
`;
export const SecondaryButton = styled.button`
    background-color: ${colors.WHITE};
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    padding: 16px 56px;
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    margin-left: 0;
    width: 100%;
    order: 1;
    cursor: pointer;

    &.min-width {
        min-width: 300px;
    }


    ${MediaQuery.medium`
        width: auto;
        margin-left: 16px;
        order: 0;
    `}
    .icon-left {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowLeftOrange});
        background-size: cover;
    }

    .icon-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        margin: 0;
        height: 24px;
        width: 24px;
        background: url(${ArrowRightOrange});
        background-size: cover;
    }

    &.no-border {
        border: none;
    }

    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    &.hidden {
        display: none;
    }

`;
export const EditButton = styled.button.attrs(() => ({ type: 'button' }))`
    cursor: pointer;
    background-color: ${colors.WHITE};
    font-style: normal;
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    color: ${colors.BLACK};
    padding: 16px 56px;
    border: 1px solid ${colors.BLACK};
    border-radius: 3px;
    position: relative;
    margin: 0 auto 48px;
    display: block;

    ${MediaQuery.medium`
        right: 32px;
        top: 32px;
        position: absolute;
    `}
    &:hover {
        border-color: ${colors.BRAND_ORANGE};
    }

    .icon {
        position: absolute;
        top: 48%;
        transform: translateY(-52%);
        right: 10px;
        margin: 0;
    }
`;
export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-bottom: 72px;

    &.center {
        justify-content: center;
        margin: 36px auto;
    }

`;

export const ContentBoxLightGrey = styled.div`
    background-color: ${colors.LIGHT_GREY};
    padding: 32px;
    box-shadow: 0 32px 6px -30px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 100%;
    margin: 40px 0;
    position: relative;
    flex-wrap: wrap;

    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        flex-wrap: wrap;

        p {
            margin: 0;
        }

        > :nth-child(even) {
            background-color: ${colors.WHITE};
        }
    }

    h2, h3 {
        color: ${colors.DARK_GREY};
    }
`;

export const SummaryText = styled.h3`
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: ${colors.DARK_GREY};
    padding: 15px 56px 8px 16px;
    border: 0.5px solid ${colors.COOL_GREY};
    border-radius: 3px;
    margin-bottom: 38px;

    &.no-margin-bottom {
        margin-bottom: 0;
    }
`;

export const FileName = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    color: ${colors.LAND_GREEN};
    margin: 1rem 0;
    width: 100%;
    margin-bottom: 38px;

    &.inline {
        width: auto;
        margin: 0;
    }
`;

export const FileUploadContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding: 18px 8px;

    &.with-dot {
        margin-bottom: 18px;
        padding: 0;
        margin-top: 50px;

        &:after {
            content: '';
            width: calc(100% - 200px);
            background-image: linear-gradient(to right, ${colors.DARK_GREY} 33%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 6px 2px;
            background-repeat: repeat-x;
            height: 2px;
        }
    }

    &.with-background {
        background-color: ${colors.WHITE};
        border-bottom: 1px solid ${colors.COOL_GREY};

        p {
            margin: 0;
        }
    }
`;

export const FileUpload = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    .hidden-button {
        display: none;
    }
`;

export const FileUploadLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: ${colors.DARK_GREY};
    margin-bottom: 0;
    margin-left: 8px;
`;
export const TooltipContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
`;
