import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import {
  Field,
  getFormValues,
  reduxForm,
  reset
} from 'redux-form';
import putReduxDefaultSession from './actions';
import { routes } from '../../utils/constants';
import utils from '../../utils/utils';
import {
  FooterContainer,
  ForgotPasswordContainer,
  Form,
  FormButtonsContainer,
  FieldContainer,
  HeaderContainer,
  LinkButton,
  LinkButtonsContainer,
  LoginPageContainer,
  ContentContainer,
  PageImage,
  PageImageContainer,
  CreateAccountButton,
  LoginButton,
  LoginErrorLabel,
  ForgotPasswordSubContainer,
  CreateAccountButtonContainer,
  LoginErrorContainer, RedP
} from './css';
import { H1 } from '../../theme/typography';
import { P } from '../../theme/typographySitecore';
import RenderField from '../ReactComponent/RenderField';
import { isEmail, isRequired } from '../../utils/validator';
import Row from '../ReactComponent/Row';
import ForgotPasswordModal from './ForgotPasswordModal';
import ForgotPasswordSuccessModal from './ForgotPasswordSuccessModal';
import ForgotPasswordErrorModal from './ForgotPasswordErrorModal';
import ActivationEmailModal from './ActivationEmailModal';
import { Divisions } from '../../utils/enums';
import { fetchAuthContent } from '../../actions/accounts';
import { apiLogin, sendActivationEmail } from './api';
import FurtherActions from './constants';

const formName = 'LoginForm';
const LoginFormFields = {
  username: 'username',
  password: 'password',
  division: 'division'
};

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginErrorMessage: null,
      forgotPasswordModalIsOpen: false,
      forgotPasswordSuccessModalIsOpen: false,
      forgotPasswordErrorModalIsOpen: false,
      loginErrorFurtherActions: FurtherActions.NoFurtherActions,
      activationEmailModalIsOpen: false,
      selectedDivision: null,
    };
  }

  setLoginErrorMessage = (message) => {
    this.setState({ loginErrorMessage: message });
  };

  setForgotPasswordModalIsOpen = (isOpen) => {
    this.setState({ forgotPasswordModalIsOpen: isOpen });
  };

  setForgotPasswordSuccessModalIsOpen = (isOpen) => {
    this.setState({ forgotPasswordSuccessModalIsOpen: isOpen });
  };

  setForgotPasswordFailureModalIsOpen = (isOpen) => {
    this.setState({ forgotPasswordErrorModalIsOpen: isOpen });
  };

  onCreateAccount = () => {
    window.location.href = routes.createAccount;
    return window.location.href;
  };

  onLoginSuccess = () => {
    // eslint-disable-next-line no-shadow
    const { fetchAuthContent } = this.props;

    fetchAuthContent();
  };

  onLoginFailed = (err) => {
    const { fields } = this.props;

    let errorMessage = fields.logInErrorMessage.value;
    let furtherActions = FurtherActions.NoFurtherActions;
    if (err) {
      const { status } = err;
      if (status) {
        switch (status) {
          case 412:
            errorMessage = fields.logInAccountInactiveErrorMessage.value;
            furtherActions = FurtherActions.AccountInactive;
            break;
          case 406:
            errorMessage = fields.logInAccountEmailNotValidatedErrorMessage.value;
            furtherActions = FurtherActions.EmailNotValidated;
            break;
          default:
            errorMessage = fields.logInAccountEmailNotValidatedErrorMessage.value;
            furtherActions = FurtherActions.EmailNotValidated;
            break;
        }
      }
    }

    this.setState({
      loginErrorMessage: errorMessage,
      loginErrorFurtherActions: furtherActions
    });
  };

  handleSubmit = () => {
    // Clear loginErrorMessage...
    this.clearErrors();

    if (utils.isDisconnected) {
      // eslint-disable-next-line no-shadow
      const { putReduxDefaultSession } = this.props;
      putReduxDefaultSession();
      window.location.href = routes.viewUserDashboard;
    } else {
      const { formData } = this.props;

      const username = formData[LoginFormFields.username];
      const password = formData[LoginFormFields.password];

      // Division...
      let division;
      // To get around passing an object array in RenderField.data, :( , since RenderField has a bug in this case...
      switch (formData[LoginFormFields.division]) {
        case t('LtlTruckload'):
          division = Divisions.Freight.code;
          break;
        case t('CommerceSolutions'):
          division = Divisions.Sameday.code;
          break;
        default:
          break;
      }

      apiLogin(this.onLoginSuccess, username, password, division, this.onLoginFailed);
    }
  };

  renderHeader = () => {
    const { selectedDivision } = this.state;
    const { fields } = this.props;
    return (
      <HeaderContainer>
        <H1>{t('LogIn')}</H1>
        <P field={fields.logInSummary} />
        {selectedDivision && selectedDivision === t('LtlTruckload') && <RedP field={fields.freightForgotPassword} />}
      </HeaderContainer>
    );
  };

  openActivationEmailModal = () => {
    this.setState({ activationEmailModalIsOpen: true });
  };

  closeActivationEmailModal = () => {
    this.setState({ activationEmailModalIsOpen: false });
    this.clearErrors();
    this.props.dispatch(reset(formName));
  };

  activateEmail = (e) => {
    e.preventDefault();
    const { formData } = this.props;
    sendActivationEmail(this.openActivationEmailModal, formData[LoginFormFields.username]);
  };

  clearErrors = () => {
    this.setState({
      loginErrorMessage: null,
      loginErrorFurtherActions: FurtherActions.NoFurtherActions
    });
  };

  renderError = () => {
    if (this.state.loginErrorMessage) {
      const { loginErrorFurtherActions } = this.state;

      return <LoginErrorContainer>
        <LoginErrorLabel>
          {this.state.loginErrorMessage}
          {loginErrorFurtherActions === FurtherActions.EmailNotValidated &&
            <a href="#" onClick={(e) => this.activateEmail(e)}>{t('LoginActivateAccount')}</a>}
        </LoginErrorLabel>
      </LoginErrorContainer>;
    }

    return null;
  };

  renderForm = ({ isFormValid }) => {
    const { fields } = this.props;
    return (
      <Form>
        {this.renderError()}
        <Field
          name={LoginFormFields.division}
          type="dropdown"
          component={RenderField}
          label={t('SelectService')}
          data={[
            t('LtlTruckload'), // Freight
            t('CommerceSolutions') // Sameday
          ]}
          textField="text"
          valueField="value"
          required
          validate={[isRequired]}
          onChange={(division) => this.setState({ selectedDivision: division })}
        />

        <Row>
          <FieldContainer>
            <Field
              name={LoginFormFields.username}
              type="text"
              component={RenderField}
              label={t('EmailId')}
              required
              validate={[isRequired, isEmail]}
              onChange={this.clearErrors}
            />
          </FieldContainer>
          <FieldContainer>
            <Field
              name={LoginFormFields.password}
              type="password"
              component={RenderField}
              label={t('Password')}
              required
              validate={[isRequired]}
            />
          </FieldContainer>
        </Row>

        <ForgotPasswordContainer>
          <ForgotPasswordSubContainer onClick={() => this.setForgotPasswordModalIsOpen(true)}>
            <P field={fields.forgotPasswordText} />
          </ForgotPasswordSubContainer>
        </ForgotPasswordContainer>

        <FormButtonsContainer>
          <CreateAccountButtonContainer>
            <CreateAccountButton type="button" iconRightArrow onClick={this.onCreateAccount}>
              {t('CreateAccount')}
            </CreateAccountButton>
          </CreateAccountButtonContainer>
          <LoginButton type="button" iconRightArrow disabled={!isFormValid} onClick={this.handleSubmit}>
            {t('LogIn')}
          </LoginButton>
        </FormButtonsContainer>
      </Form>
    );
  };

  renderLinks = () => {
    const { fields } = this.props;

    const trackItToolLinkHref =
    fields?.commonLinks?.fields?.trackItToolLink?.value?.href || fields?.commonLinks[0]?.fields?.trackItToolLink?.value?.href;
    const trackItToolLinkText =
    fields?.commonLinks?.fields?.trackItToolLink?.value?.text || fields?.commonLinks[0]?.fields?.trackItToolLink?.value?.text;

    const dnrUsaLinkHref =
    fields?.commonLinks?.fields?.dnrUsaLink?.value?.href || fields?.commonLinks[0]?.fields?.dnrUsaLink?.value?.href;
    const dnrUsaLinkText =
    fields?.commonLinks?.fields?.dnrUsaLink?.value?.text || fields?.commonLinks[0]?.fields?.dnrUsaLink?.value?.text;

    return (
      <LinkButtonsContainer>
        <LinkButton iconRightArrow $autoWidth onClick={() => window.open(trackItToolLinkHref)}>
          {trackItToolLinkText}
        </LinkButton>
        <LinkButton iconRightArrow $autoWidth onClick={() => window.open(dnrUsaLinkHref)}>
          {dnrUsaLinkText}
        </LinkButton>
      </LinkButtonsContainer>
    );
  };

  renderFooter = () => {
    const { fields } = this.props;
    return (
      <FooterContainer>
        <P field={fields.logInSupportLinkText} />
      </FooterContainer>
    );
  };

  render() {
    const {
      forgotPasswordModalIsOpen,
      forgotPasswordSuccessModalIsOpen,
      activationEmailModalIsOpen,
      forgotPasswordErrorModalIsOpen
    } = this.state;
    const {
      fields,
      valid
    } = this.props;

    return (
      <LoginPageContainer>

        <ForgotPasswordModal
          isOpen={forgotPasswordModalIsOpen}
          onClose={() => this.setForgotPasswordModalIsOpen(false)}
          onSuccess={() => {
            this.setForgotPasswordModalIsOpen(false);
            this.setForgotPasswordSuccessModalIsOpen(true);
          }}
          onFailure={() => {
            this.setForgotPasswordModalIsOpen(false);
            this.setForgotPasswordFailureModalIsOpen(true);
          }}
          parentFields={fields}
        />

        <ForgotPasswordSuccessModal
          isOpen={forgotPasswordSuccessModalIsOpen}
          onClose={() => this.setForgotPasswordSuccessModalIsOpen(false)}
          parentFields={fields}
        />

        <ForgotPasswordErrorModal
          openSetForgotPasswordModal={() => {
            this.setForgotPasswordModalIsOpen(true);
            this.setForgotPasswordFailureModalIsOpen(false);
          }}
          isOpen={forgotPasswordErrorModalIsOpen}
          onClose={() => this.setForgotPasswordFailureModalIsOpen(false)}
          parentFields={fields}
        />

        <ActivationEmailModal
          isOpen={activationEmailModalIsOpen}
          onClose={() => this.closeActivationEmailModal()}
          parentFields={fields}
        />

        <ContentContainer $desktop={7}>
          {this.renderHeader()}
          {this.renderForm({ isFormValid: valid })}
          {this.renderLinks()}
          {this.renderFooter()}
        </ContentContainer>
        <PageImageContainer $desktop={5}>
          <PageImage src={fields.pageImage.value.src} />
        </PageImageContainer>

      </LoginPageContainer>
    );
  }
}

LoginForm = reduxForm({
  form: formName,
})(LoginForm);

const mapStateToProps = (state) => ({
  formData: getFormValues(formName)(state)
});

const mapDispatchToProps = (dispatch) => ({
  putReduxDefaultSession: () => dispatch(putReduxDefaultSession()),
  fetchAuthContent: () => dispatch(fetchAuthContent({
    isFrontEndAuth: false,
    navToDashboard: true,
    invokeLayout: true
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
