import React from 'react';
import { connect } from 'react-redux';
import putReduxGeneralSettings from './actions';
import utils from '../../utils/utils';
import c from '../../temp/config';
import { getCurrentLocale } from '../../utils/func';
import getLayoutResources from '../../services/layoutService';

class FetchGeneralSettingsImpl extends React.Component {
  componentDidMount() {
    this.fetchGeneralSettings();
  }

  fetchGeneralSettings = () => {
    const { generalSettings } = this.props;

    if (generalSettings && generalSettings.isSet) {
      return;
    }

    if (!utils.isDisconnected) {
      getLayoutResources({
        sitecoreApiKey: c.sitecoreApiKey,
        locale: getCurrentLocale()
      })
        .then((route) => {
          if (route.status === 200 && route.data && route.data.sitecore.context.profileData && route.data.sitecore.context.profileData.app_settings) {
            // eslint-disable-next-line camelcase
            const { service_apiKey } = route.data.sitecore.context.profileData.app_settings;

            const gs = {
              isSet: true,
              // eslint-disable-next-line camelcase
              service_apiKey
            };

            // eslint-disable-next-line no-shadow
            const { putReduxGeneralSettings } = this.props;
            putReduxGeneralSettings(gs);
          }
        });
    }
  };

  render = () => null;
}

const mapStateToProps = (state) => ({
  generalSettings: state.profile.generalSettings
});

const mapDispatchToProps = (dispatch) => ({
  putReduxGeneralSettings: (generalSettings) => dispatch(putReduxGeneralSettings(generalSettings)),
});

const FetchGeneralSettings = connect(mapStateToProps, mapDispatchToProps)(FetchGeneralSettingsImpl);

export default FetchGeneralSettings;
