import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { t } from 'i18next';
import { AddAnotherItemContainer, AddItemLabel } from '../common/css';
import Plus from '../../../../assets/icons/Plus.svg';
import MeasurementItem from '../Item';
import { types } from '../Container/constants';

const ItemList = (props) => {
  const { name } = props;
  return (<FieldArray props={props} name={name} component={Items} />);
};

ItemList.propTypes = {
  name: PropTypes.string,
};

// eslint-disable-next-line
const Items = ({
  fields, name, formName, parentCompleted, type, parentFields, containerId,
}) => {
  useEffect(() => {
    if (!fields.length) fields.push({});
  }, [fields]);

  const fieldsArray = fields.getAll();
  return (
    <>
      {fields.map((item, id) => (
        <MeasurementItem
          item={item}
          id={id}
          key={name + id}
          formName={formName}
          fields={fieldsArray}
          listName={name}
          type={type}
          parentFields={parentFields}
          containerId={containerId}
        />
      ))}

      {!parentCompleted
        && fieldsArray
        && type !== types.READ_ONLY
        && fieldsArray.every(({ completed }) => completed) && (
          <AddAnotherItemContainer
            className="with-dot"
            onClick={() => fields.push({})}
          >
            <img src={Plus} alt={t('SaveItem')} />
            <AddItemLabel>{t('AddAnotherItem')}</AddItemLabel>
          </AddAnotherItemContainer>
      )}
    </>
  );
};

Items.propTypes = {
  name: PropTypes.string,
  fields: PropTypes.object,
  formName: PropTypes.string,
  parentCompleted: PropTypes.bool,
  type: PropTypes.string,
  parentFields: PropTypes.object,
  containerId: PropTypes.string,
};

export default ItemList;
