import axios from 'axios';
import { t } from 'i18next';
import { endpoints } from '../../utils/constants';
import { isValidCanadaUSPostalCode } from '../../utils/validator';

export const fetchTransitTimeDetails = (formData) => {
  const destinationList = formData.measurementList.map((eachDestinationInformation) => ({
    city: eachDestinationInformation.itemDestinationCity,
    provinceCode: eachDestinationInformation.itemDestinationState,
    postalCode: eachDestinationInformation.itemDestinationPostal.replace(/\s/g, ''),
    countryCode: eachDestinationInformation.itemDestinationCountryCode,
  }));

  const formValueToApi = {
    division: formData.division,
    pickupDate: formData.pickUpDate,
    origin: {
      city: formData.originCity,
      provinceCode: formData.originProvince,
      postalCode: formData.originPostalCode.replace(/\s/g, ''),
      countryCode: formData.originCountryCode,
    },
    destinations: destinationList,
  };

  return new Promise((resolve, reject) => {
    if (formData) {
      axios.post(
        `${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.transitTimeCalcApi}`, formValueToApi).then((getResponse) => {
        if (getResponse.status === 200) {
          resolve(getResponse);
        } else {
          reject(getResponse.data);
        }
      })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

export const getServiceLocation = (divisionValue, postalCodeValue) => {
  const formValueToApi = {
    division: divisionValue,
    postalCode: postalCodeValue.replace(/\s/g, ''),
  };
  return new Promise((resolve, reject) => {
    if (!isValidCanadaUSPostalCode(postalCodeValue)) {
      axios.get(
        `${t('ApiEndPoint')}${t('AddressApiVirtualFolder')}${endpoints.address.addressValidate}/${formValueToApi.division}`,
        {
          params: {
            postalCode: formValueToApi.postalCode.replace(/\s/g, ''),
          },
        }).then((getResponse) => {
        if (getResponse.status === 200) {
          resolve(getResponse.data);
          // value: { cityName: location.cityName, countryCode: location.countryCode }
        } else {
          reject(getResponse);
        }
      })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

