import { PUT_REDUX_SHIPMENT_LIST, PUT_REDUX_SHIPMENT_HISTORY_LIST, PUT_REDUX_DRAFTS_LIST } from '../../reducers/constants';

export const putReduxAllShipments = (allShipments) => ({
  type: PUT_REDUX_SHIPMENT_LIST,
  allShipments,
});

export const putReduxAllShipmentsHistory = (allShipmentsHistory) => ({
  type: PUT_REDUX_SHIPMENT_HISTORY_LIST,
  allShipmentsHistory,
});

export const putReduxAllDrafts = (allDrafts) => ({
  type: PUT_REDUX_DRAFTS_LIST,
  allDrafts,
});
