import { phoneNumber as phoneNumberMask, digitOnly } from '../../utils/mask';
import {
  getAddress, getCity, getState, getZip, getCountry,
} from '../../utils/func';
import { AddressTypes } from '../../utils/enums';

export const getCompanyIdFromListByName = (companyName, companiesList) =>
  companiesList.filter((c) => c.companyName === companyName).map((c) => c.companyId).toString();
export const formDataToApi = (formData, companiesList) => {
  const {
    companyId,
    nickname,
    address,
    addressAdditional,
    phoneNumber,
    phoneNumberExt,
    contactName,
    companyName,
    email,
    contactType,
    rowVersion,
    division,
    userId,
  } = formData;
  let contactTypeCode;
  switch (contactType) {
    case AddressTypes.SHIPPER:
      contactTypeCode = 'SHP';
      break;
    case AddressTypes.CONSIGNEE:
      contactTypeCode = 'CON';
      break;
    case 'billing':
    case AddressTypes.SHARED:
      contactTypeCode = 'SHR';
      break;
    case AddressTypes.OTHER:
      contactTypeCode = 'OTR';
      break;
    default:
  }

  return {
    companyId: companiesList ? getCompanyIdFromListByName(companyId, companiesList) : companyId,
    contactType: contactTypeCode,
    contactName,
    nickname: nickname.name ? nickname.name : nickname,
    emailAddress: email,
    contactCompanyName: companyName,
    address1: getAddress(address),
    address2: addressAdditional,
    city: getCity(address),
    provinceCode: getState(address),
    countryCode: getCountry(address),
    postalCode: getZip(address),
    phoneNumber: digitOnly(phoneNumber),
    phoneNumberExtension: phoneNumberExt,
    rowVersion,
    division,
    userId,
  };
};

export const formApiToData = (data) => {
  const {
    nickname,
    contactName,
    companyName,
    emailAddress,
    phoneNumberExtension,
    address1,
    address2,
    city,
    provinceCode,
    countryCode,
    postalCode,
    rowVersion,
    contactId,
  } = data;
  return {
    nickname: {
      name: nickname,
      contactId
    },
    address: `${address1}, ${city}, ${provinceCode} ${postalCode}, ${countryCode}`,
    addressAdditional: address2,
    phoneNumber: phoneNumberMask(data.phoneNumber),
    phoneNumberExt: phoneNumberExtension,
    contactName,
    companyName,
    email: emailAddress,
    rowVersion,
  };
};
