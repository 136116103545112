import React, { useEffect, useState } from 'react';
import { change, Field } from 'redux-form';
import { t } from 'i18next';
import Column from '../../ReactComponent/Column';
import Row from '../../ReactComponent/Row';
import RenderField from '../../ReactComponent/RenderField';
import { isRequired, isInRange } from '../../../utils/validator';
import { currencyOnly } from '../../../utils/mask';
import { FormSummary, PageTitle, Icon } from '../css';
import { P } from '../../../theme/typography';
import PrimaryButton from '../../ReactComponent/PrimaryButton';
import ArrowLeftOrange from '../../../assets/icons/ArrowLeftOrange.svg';
import SecondaryButton from '../../ReactComponent/SecondaryButton';

import { Divisions } from '../../../utils/enums';

const oneTo4999 = isInRange(1, 4999.99);

const Step3 = ({
  formData = {}, step, accessorialsList = [], nextStep, prevStep, dispatch, formName, parentFields
}) => {
  const { measurementList, division } = formData;
  const [dangerousGoodFound, setDangerousGoodFound] = useState(false);
  const dangerCode = division === Divisions.Sameday.name ? 'HAZARD' : 'DANGER';

  useEffect(() => {
    if (!measurementList) {
      return;
    }

    // Determine if any of the measurement items have been flagged as having dangerous goods...
    setDangerousGoodFound(measurementList.some((element) => (element.itemDangerous === true)));
  }, [measurementList]);

  useEffect(() => {
    // Construct ACCESSORIAL value as being unselected...
    // Must be array w/ empty element, because of dependency on indeces. See ../api.js > accessorialsList()...
    const newDangerAccessorialValue = [''];

    // If the "DANGER" accessorial is included in the accessorialList and an item has dangerous goods,
    // ensure the "Dangerous Goods" control is selected in the UI...
    const dangerAccessorial = accessorialsList.find((element) => element.code === dangerCode);
    if (dangerAccessorial && dangerousGoodFound) {
      // Amend ACCESSORIAL value as being selected...
      newDangerAccessorialValue.push({
        name: dangerAccessorial.description,
        code: dangerAccessorial.code,
        accessorialId: dangerAccessorial.accessorialId
      });
    }

    // Set the DANGER accessorials value, in the store...
    dispatch(change(formName, `accessorials.${dangerCode}.selected`, newDangerAccessorialValue));
  }, [dangerousGoodFound, accessorialsList]);

  if (step === 2) {
    const { accessorials } = formData;

    return (
            <FormSummary>
                  <PrimaryButton iconRightArrowWhite smallerWidth rightButtonMargin type="button" onClick={nextStep} className="float-right">
                    {t('Next')}
                </PrimaryButton>

                <SecondaryButton type="button" smallerWidth onClick={prevStep} className="float-right">
                <Icon className="icon icon-left topLeftIcon" src={ArrowLeftOrange} alt="back" />
                        {t('Back')}
                </SecondaryButton>

                <PageTitle>{parentFields.step3PageTitle && parentFields.step3PageTitle.value}</PageTitle>
                <P>{parentFields.step3Description && parentFields.step3Description.value}</P>
                <Row>
                    {accessorialsList
                      .filter((acceptedList) =>
                        (acceptedList.code !== 'DISPOS' && acceptedList.code !== 'H1' && acceptedList.code !== 'H2' && acceptedList.code !== 'HW'))
                      .map((service, index) => (
                        <Column $tablet={6} $mobile={12} key={index}>
                            <Field
                                name={`accessorials.${service.code}.selected`}
                                type="checkboxes"
                                data={[{ name: service.description, code: service.code, accessorialId: service.accessorialId }]}
                                component={RenderField}
                                disabled={service.code === 'ADMIN' || service.code === dangerCode}
                                multiple
                                value={service.code}
                            />
                            <P>{t(`accessorialDesc${service.code}`)}</P>
                            {accessorials[service.code] && accessorials[service.code].selected.length > 1 && service.isValueRequired && (
                                <Row style={{ marginBottom: '40px' }}>
                                    <Column $tablet={8} $mobile={12}>
                                        <Field
                                            name={`accessorials.${service.code}.value`}
                                            type="single"
                                            label={t('amount')}
                                            required
                                            component={RenderField}
                                            validate={[isRequired, oneTo4999]}
                                            normalize={currencyOnly}
                                        />
                                    </Column>
                                    <Column $tablet={4} $mobile={12}>
                                        <Field
                                            name={`accessorials.${service.code}.currency`}
                                            type="dropdown"
                                            label={t('currency')}
                                            data={['CAD', 'USD']}
                                            required
                                            component={RenderField}
                                            validate={[isRequired]}
                                        />
                                    </Column>
                                </Row>
                            )}
                        </Column>
                      )
                      )}
                    <Column $tablet={12} $mobile={12}>
                        <P style={{ marginTop: '60px' }}><strong>{parentFields.step3Paragraph && parentFields.step3Paragraph.value}</strong></P>
                    </Column>
                </Row>
            </FormSummary>
    );
  }
  return null;
};

export default Step3;
