import styled from 'styled-components';
import { colors, MediaQuery } from '../../theme/style-helpers';
import ArrowRightOrange from '../../assets/icons/ArrowRightOrange.svg';

import PrimButton from '../ReactComponent/PrimaryButton';
import SecondaryButton from '../ReactComponent/SecondaryButton';

export const CommonPrimaryButton = styled(PrimButton)`    
  ${MediaQuery.medium`
    width: auto;
    margin-left: 0;
    margin-bottom: 0;
  `}
`;

export const CommonSecondaryButton = styled(SecondaryButton)`    
  ${MediaQuery.medium`
    width: auto;
    margin-left: 16px;
    margin-bottom: 0;
  `}
`;

export const SearchContainer = styled.div`
    width: 80%;
    margin: 10px auto;

    .summary{
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.04em;
        margin:20px 0;
        
        .keyword{
            color: ${colors.BRAND_ORANGE}
        }
    }

    .sort{
        ${MediaQuery.medium`
           width:250px;
           display:inline-block;
           float:right;
        `}
    }

    >form{
        margin:40px 0;
        >div:first-of-type{
            margin:24px 0 34px;
        }    
    }
`;

export const ResultItem = styled.div`
    margin:30px 0;
    min-height:100px;

    .title{
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        font-weight:500;
        text-decoration:underline;
        display:block;
    }

    .path{
        display:none;
        font-size:10px;
    }

    .description{
        font-size: 14px;
        line-height: 21px;
        margin-top:20px;
        display: block;
    }

    .viewMoreContainer{
        margin-top:20px;
        display:block;

        .viewMore{
            font-size: 14px;
            line-height: 17px;
            font-weight:500;
            text-decoration:none;  
            color: ${colors.BLACK};         
    
            .icon {
                background: url(${ArrowRightOrange});
                width: 20px;
                height: 26px;
                display: inline-block;
                margin-left: 0;
                vertical-align:middle
            }
        }
    }
    
`;

export const CenteredMessage = styled.div`
    width: 100%;
    height: 300px;
    text-align: center;
    vertical-align: middle;
    padding-top: 120px;

    &.text-align-left{
        text-align:left;
    }

    &.padding-40{
        padding-top: 40px;
    }

    &.padding-70{
        padding-top: 70px;
    }

    .icon-100{
        width:100px;
        height:100px;
    } 
    
    .red-message{
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        color: ${colors.RED_ERROR};
    }

    .bold-message{
        margin-top:40px;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
    }
`;

export const Icon = styled.img`
    margin-right: 10px;

    &.right {
        margin-right: 0;
        margin-left: 10px;
    }
`;
